import { client } from '../../../shared/graphql/ApolloClient';
import { ACTIVATE_BOT } from '../../../shared/graphql/mutations/bots/ActivateBot';
import { currentFloorVar } from '../../../shared/model/cache/Cache';

/**
 * Class that realizes api methods for bots
 * @class
 */
class BotsAPI {
    /**
     * Method for activating bot
     * @param {string} botId bot UUID
     * @returns {string} activated bot UUID
     */
    async activateBot(botId) {
        const options = {
            mutation: ACTIVATE_BOT,
            variables: {
                botId: botId,
                floorId: currentFloorVar().id,
                config: '{}',
            },
        };

        try {
            const botData = (await client.mutate(options)).data.activateBot;
            return botData.id;
        } catch (error) {
            throw new Error(error.message);
        }
    }
}

const botsAPI = new BotsAPI();
export default botsAPI;

import { InMemoryCache, makeVar } from '@apollo/client';
import {
    DEFAULT_POSITION,
    DEFAULT_SCALE,
} from '../../../widgets/Canvas/lib/CanvasDefaultValues';
import {
    DragTools,
    Tools,
} from '../../../pages/Constructor/TopBar/TopBarEntires/Tools/Constants/Tools';

import { VIEW_MODE } from '../../lib/utils/ModeDefaultValues';

/**
 * Reactive variable for current dragging item from menu
 *
 * @type {ReactiveVar<Object | null>}
 * @param {Object} menuItem
 * @param {String} menuItem.itemName
 * @param {String} menuItem.itemTypeId - map object type id
 */
export const currentMenuItemVar = makeVar(null);

/**
 * @typedef {import('../../../features/CanvasItem/api/MapObjectsAPI').MapObject} MapObject
 * @callback CanvasItemsVar
 * @param {MapObject[]} mapObjects
 * @return {MapObject[]} mapObjects
 */

/**
 * Reactive variable for all items on canvas
 *
 * @type {CanvasItemsVar}
 */
export const mapObjectsVar = makeVar([]);

/**
 * DEPRECATED!
 * TODO: move var to MapObjects class
 * Reactive variable for all walls on canvas
 *
 * @type {ReactiveVar<[]>} reactive variable with params described bellow
 * @param { lineString: [{x:number, y: number}],
 *          uniqueIdArray: [string],
 *          id: string,
 *          isVirtual: boolean,
 *          isClosed: boolean
 *        }
 */
export const canvasWallsVar = makeVar([]);

/**
 * Reactive variable for selected tool
 *
 * @type {ReactiveVar<Object | null>} Tools
 * @param {string} Tool constant
 */
export const currentToolVar = makeVar(Tools.none);

/**
 * Reactive variable for selected drag type tool
 * @type {ReactiveVar<Object | null>} Tools
 * @param {string} Tool constant
 */
export const currentDragTypeVar = makeVar(DragTools.cursor);

/**
 * @callback CurrentCanvasPointVar
 * @param {Point} currentPoint
 * @return {Point}
 */

/**
 * Variable for checking for the current point on the Canvas that is
 * under mouse cursor
 * @type {CurrentCanvasPointVar}
 */
export const currentCanvasPointVar = makeVar({ x: 0, y: 0 });

/**
 * Reactive variable for checking the closest corner point of walls.
 * @type {ReactiveVar<{point: Object | null, wallId: string | null}>}
 * @param {Object} Object {point, wallId}
 * @param {x: number, y: number} point
 * @param {string} wallId
 */
export const closestWallPointVar = makeVar({ point: null, wallId: null });

/**
 * Reactive variable for tracking current selected canvas item
 *
 * @type {ReactiveVar<String | null>}
 * @param {number} itemId
 */
export const currentMapObjectVar = makeVar(null);

/**
 * Temp variable for storing object settings modal state.
 *
 * @type {ReactiveVar<{isOpened: boolean}>}
 * @param {object} obj
 * @param {boolean} obj.isOpened is modal window with roles settings open
 * @param {boolean} obj.objectType type of object: zone, map object, etc.
 * @param {boolean} obj.objectUUID object UUID
 */
export const objectSettingsModalVar = makeVar({
    isOpened: false,
    objectType: null,
    objectUUID: '',
});

/**
 * @typedef {import('../../../features/GraphicSchema/lib/SchemaSpecifier').SchemaForm} SchemaForm
 */

/**
 * @typedef {{
 *  objectName: String,
 *  objectId: String,
 *  objectTypeName: String,
 *  position: Number
 *  schema: {
 *      objectTypeSchema: SchemaForm,
 *      objectExtendablePropsSchema: SchemaForm,
 *      objectBotPropsSchemas: SchemaForm[]
 *  },
 *  copy: () => GraphicSchema
 * }} GraphicSchema
 */

/**
 * Temp variable for storing current schema.
 * TODO modify or remove this var after removing mock schema.
 *
 * @type {import('@apollo/client').ReactiveVar<GraphicSchema>}
 */
export const graphicSchemaVar = makeVar(null);

/*
{
    objectName: 'Ноутбук',
    schema: {
        objectTypeSchema: SchemaSpecifier.parseSchemaAndSchemaImpl(
            MAP_OBJECT_TYPE_SCHEMA,
            MAP_OBJECT_TYPE_SCHEMA_IMPL
        ),
        objectExtendablePropsSchema: SchemaSpecifier.parseSchemaAndSchemaImpl(
            MAP_OBJECT_EXTENDABLE_SCHEMA,
            MAP_OBJECT_EXTENDABLE_SCHEMA_IMPL
        ),
        objectBotPropsSchemas: [
            SchemaSpecifier.parseSchemaAndSchemaImpl(
                MAP_OBJECT_BOT_SCHEMA,
                MAP_OBJECT_BOT_SCHEMA_IMPL
            ),
        ],
    },
    copy() {
        return {
            ...this,
            schema: {
                objectTypeSchema: this.schema.objectTypeSchema.copy(),
                objectExtendablePropsSchema:
                    this.schema.objectExtendablePropsSchema.copy(),
                objectBotPropsSchemas: this.schema.objectBotPropsSchemas.map(
                    (item) => item.copy()
                ),
            },
        };
    },
}
*/

/**
 * Reactive variable for tracking context menu on canvas
 *
 * @type {ReactiveVar<{isOpened: boolean, currentComponent: JSX.Element | null, isClosable: boolean, position: {x: number, y: number}, drawPosition: {x: number, y: number}}>}
 *
 * @param {JSX.Element} currentComponent context menu component
 * @param {boolean} isOpened
 * @param {boolean} isClosable can context menu be closed
 * @param {object} position position where mouse was clicked
 * @param {number} position.x
 * @param {number} position.y
 * @param {object} drawPosition position where context menu should be drawn (if position is outside screen)
 * @param {number} drawPosition.x
 * @param {number} drawPosition.y
 */
export const contextMenuVar = makeVar({
    currentComponent: null,
    isOpened: false,
    isClosable: true,
    position: {
        x: 0,
        y: 0,
    },
    drawPosition: {
        x: 0,
        y: 0,
    },
});

/**
 * Snapping to grid flag
 *
 * @type {ReactiveVar<boolean>}
 * @param {Boolean} isSnap
 */
export const snapToGridVar = makeVar(false);

/**
 * Reactive variable for stageVar
 *
 * @type {ReactiveVar<Object | null>}
 * @param {Object} stageRef.current
 */
export const stageVar = makeVar(null);

/**
 * Reactive variable for Konva Layer with Edges
 * is need to iterate layer shapes and select edges
 *
 * @type {ReactiveVar<Object | null>}
 * @param {Object} layerRef.current
 */
export const layerVar = makeVar(null);

/**
 * Reactive variable for grid state
 *
 * @type {ReactiveVar<boolean>}
 * @param {boolean} isEnabled true if enabled
 */
export const isGridEnabledVar = makeVar(false);

/**
 * Reactive variable for canvas scale
 *
 * @type {ReactiveVar<number>}
 * @param {number} scaleRatio (0..inf)
 */
export const stageScaleVar = makeVar(DEFAULT_SCALE);

/**
 * Reactive variable for stage position
 *
 * @type {ReactiveVar<{x: number, y: number}>}
 * @param {{x: number, y: number}} position
 */
export const stagePositionVar = makeVar(DEFAULT_POSITION);

/**
 * Reactive variable for selected wall segment
 *
 * @type {function}
 * @param {{
 *  type: string,
 *  object: object
 * }} object
 */
export const selectedCanvasObjectVar = makeVar(null);
/**
 * Reactive variable for stage borders size and is used to calculate size of global zone.
 *
 * @type {ReactiveVar<{top: number, left: number, bottom: number, right: number}>}
 * @param {{ left: number, right: number, top: number, bottom: number }} borders
 */
export const stageBordersSizeVar = makeVar({
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
});

/**
 * Reactive variable for changing mode
 *
 * @type {ReactiveVar<string>}
 * @param {String}
 */
export const modeVar = makeVar(VIEW_MODE);

/**
 * Variable for show EmailSender component, when recovery or registration form are send
 *
 * @type {ReactiveVar<boolean>}
 * @param {Boolean} isSendEmail
 */
export const isSendEmail = makeVar(false);

/**
 * Variable for user store
 *
 * @type {ReactiveVar<{additionalInformation: string, patronymic: string, isAuth: boolean, phoneNumber:
 *  string, surname: string, roles: *[], name: string, primaryEmail: string, id: string,
 *  login: string, photoLink: string}>}
 * @param {Object} currentUser current logged user
 * @param {String} currentUser.id user id
 * @param {String} currentUser.primaryEmail user email
 * @param {String} currentUser.additionalInformation info
 * @param {String} currentUser.login user login
 * @param {String} currentUser.name user name
 * @param {String} currentUser.surname user surname
 * @param {String} currentUser.patronymic user patronymic
 * @param {String} currentUser.phoneNumber user phone number
 * @param {Object[]} currentUser.roles user roles
 * @param {String} currentUser.roles.creatorId role creator id
 * @param {String} currentUser.roles.description role description
 * @param {String} currentUser.roles.id role id
 * @param {String} currentUser.roles.mapId role map id
 * @param {String} currentUser.roles.name role name
 * @param {Boolean} currentUser.roles.roleIssuer
 * @param {Boolean} currentUser.isAuth is user authorized
 *
 */
export const currentUserVar = makeVar({
    additionalInformation: '',
    id: '',
    login: '',
    name: '',
    patronymic: '',
    phoneNumber: '',
    primaryEmail: '',
    photoLink: '',
    roles: [],
    surname: '',
    isAuth: false,
});

export const initialProjectConst = {
    id: '',
    name: '',
    ownerId: '',
    description: '',
    accuracy: 2,
};

/**
 * Variable for current map in edit mode
 *
 * @type {ReactiveVar<{name: string, description: string, id: string, ownerId: string}>}
 * @param {Object} currentMap current map
 * @param {String} currentMap.id map id
 * @param {String} currentMap.name map name
 * @param {String} currentMap.ownerId id of owner of the map
 * @param {String} currentMap.description map description
 * @param {Number} currentMap.accuracy map accuracy | number of symbols after comma
 */
export const currentProjectVar = makeVar(initialProjectConst);

/**
 * Variable for all floors of current map
 *
 * @type {ReactiveVar<*[]>}
 * @param {Object[]} mapFloors floors of current map
 * @param {String} mapFloors.id floor id
 * @param {String} mapFloors.name floor id
 * @param {Number} mapFloors.number floor number
 */
export const floorsVar = makeVar([]);

/**
 * Variable for current floor of the map
 *
 * @type {ReactiveVar<{number: number, name: string, id: string}>}
 * @param {Object} currentFloor current map floor object
 * @param {String} currentFloor.id floor id
 * @param {Number} currentFloor.number floor number
 */
export const currentFloorVar = makeVar({
    id: '',
    name: '',
    number: 0,
});

/**
 * Variable for send repeat request query
 *
 * @type {ReactiveVar<{variables: Object | null, query: Function | null}>}
 * @param {Object} repeatSubmitHandle object for repeat request
 * @param {Object} repeatSubmitHandle.variables request variables
 * @param {Function} repeatSubmitHandle.query request query
 */
export const repeatSubmitHandlerVar = makeVar({
    variables: null,
    query: null,
});

/**
 * Variable for storing available wall types for project
 *
 * @type {ReactiveVar<{variables: Object | null, query: Function | null}>}
 * @param {Object[]} wallType
 * @param {String} wallType.creatorId
 * @param {Boolean} wallType.fixed
 * @param {String} wallType.id
 * @param {String} wallType.information
 * @param {Number} wallType.length
 * @param {Boolean} wallType.permeable
 * @param {String} wallType.projectId
 * @param {String} wallType.realPicture
 * @param {String} wallType.schematicPicture
 * @param {Number} wallType.width
 */
export const projectWallTypesVar = makeVar([]);

/**
 * Variable for storing global types groups
 */
export const projectGlobalTypesGroups = makeVar([]);

/**
 * Variable for storing ref.current on grid to cache it after redraw
 *
 * @type {ReactiveVar<{variables: Object | null} | null>}
 */
export const gridVar = makeVar(null);

/**
 * Apollo cache
 */
export const cache = new InMemoryCache();

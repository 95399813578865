import accessGroupsAPI from '../../../features/AccessGroup/api/AccessGroupsAPI';
import accessGroupsModerator from '../../../features/AccessGroup/model/AccessGroupsModerator';

/**
 * Method for initialize access groups.
 * This method receives AccessGroups object from server and fill AccessGroupsModerator.
 * @param {object[]} accessGroups
 * @param {string} currentAccessGroupId Access group UUID which user belongs
 */
export const initializeAccessGroups = (accessGroups, currentAccessGroupId) => {
    accessGroupsModerator.clear();

    accessGroups.forEach((group) => {
        accessGroupsModerator.addGroup(
            group.id,
            accessGroupsAPI.adaptData(group)
        );
    });

    accessGroupsModerator.currentAccessGroupId = currentAccessGroupId;
};

import { Point } from '../../../../shared/typedefs/Types';
import scalesConformer from '../../../ScalesConformer/model/ScalesConformer';

/** Interface to store parameters for temporary segment */
export interface TmpSegmentProps {
    /** Is angle fixed */
    fixAngle: boolean;

    /** Angle from x-axis (in degrees) */
    angle: number;

    /** Is length fixed */
    fixLength: boolean;

    /** Segment length */
    length: number;
}

/**
 * Calculate position for second segment point.
 * The function is needed to take into account the user-specified length
 * and angle of the segment when calculating the second coordinate
 * @param p1 first point position
 * @param p2 second point  position (e.g. click position)
 * @param props properties that need that must be taken into account
 * @returns coordinates for second point. If props is empty - returns p2 without changes
 */
export const calcCoordinateForSecondPoint = (
    p1: Point,
    p2: Point,
    props: TmpSegmentProps
): Point => {
    const { x: x1, y: y1 } = p1; // first point
    const { x: x2, y: y2 } = p2; // second point

    const l = scalesConformer.toPixels(props.length); // segment expected length
    const a = (props.angle * Math.PI) / 180; // expected angle (in radians)

    if (props.fixAngle && props.fixLength) {
        return {
            x: x1 + l * Math.cos(a),
            y: y1 + l * Math.sin(a),
        };
    }

    if (props.fixLength) {
        const d = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2); // segment length with current coordinates
        return {
            x: x1 + l * ((x2 - x1) / d),
            y: y1 + l * ((y2 - y1) / d),
        };
    }

    if (props.fixAngle) {
        /** Angle from 0 to 180 deg. Needed to choose snap to x or y coordinate */
        let checkAngle = Math.abs(props.angle);
        if (checkAngle > 180) {
            checkAngle -= 180;
        }
        if (
            (checkAngle >= 0 && checkAngle <= 60) ||
            (checkAngle >= 120 && checkAngle <= 180)
        ) {
            // snap to x
            const dx = x2 - x1;
            return {
                x: x2,
                y: y1 + dx * Math.tan(a),
            };
        } else {
            // snap to y
            const dy = y2 - y1;
            return {
                x: x1 + dy / Math.tan(a),
                y: y2,
            };
        }
    }

    return p2;
};

import { loadMapObjects } from '../../../features/MapObject/model/LoadMapObjects';
import { currentFloorVar } from '../../../shared/model/cache/Cache';
import { getProject } from '../api/GetProject';
import { loadFloor } from './LoadFloor';
import { initializeAccessGroups } from './InitializeAccessGroups';
import { initializeProject } from './InitializeProject';
import { initializeFloors } from './InitializeFloors';
import { initializeProjectTypes } from './InitializeProjectTypes';
import { initializeBots } from '../../../features/Bots/lib/InitializeBots';
import subscriptionsModerator from '../../../features/Subscriptions/api/SubscriptionsModerator';

export const loadProject = async (projectId, setLoading, setError) => {
    return getProject(projectId)
        .then((r) => {
            initializeProject(r.data.project);
            initializeAccessGroups(
                r.data.project.accessGroups,
                r.data.accessGroup.id
            );
            initializeFloors(r.data.project.floors);
            initializeProjectTypes(r.data.project.globalTypesGroups);
            initializeBots(r.data.project.bots);
        })
        .then(async () => {
            await loadFloor(currentFloorVar().id);
        })
        .then(async () => await loadMapObjects(currentFloorVar().id))
        .then(async () => await subscriptionsModerator.subscribeToProjectChanges())
        .catch((e) => {
            console.log(e);
            setError(e);
        })
        .finally(() => {
            setLoading(false);
        });
};

import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import plusIcon from '../../../shared/assets/images/icons/plus-black.svg';
import plusBlueIcon from '../../../shared/assets/images/icons/ConstructorPage/GraphicSchema/plus.svg';
import groupIcon from '../../../shared/assets/images/icons/ConstructorPage/ProjectSettings/group.svg';
import {
    ruleConverter,
    ruleConverterReversed,
} from '../../AccessGroup/model/AGConstants';
import { GIVE_ACCESS } from '../../../shared/graphql/mutations/accessRules/GiveAccess';
import accessGroupsModerator from '../../AccessGroup/model/AccessGroupsModerator';
import notifications from '../../HintSystem/model/Notifications';
import { ComboBox } from '../../../shared/ui/FormComponentsSmall/ComboBox';
import { Preloader } from '../../../shared/ui/Preloader';
import { SecondaryButton } from '../../../shared/ui/RolesSettingsComponents/Styles';
import { POpenSansRegular } from '../../../shared/ui/TextStyles';
import { InlineButton } from '../../../shared/ui/FormComponentsSmall/InlineButton';
import { ItemSelector } from '../../../shared/ui/ItemSelector/ItemSelector';

/**
 * @typedef {import('../../../shared/ui/ItemSelector/ItemSelector').SelectableItem} SelectableItem
 * @typedef {import('../../Wall/api/WallSegmentsAPI').AccessRuleFromServer} AccessRuleFromServer
 */

const ButtonIcon = styled.img`
    height: 12px;
    width: auto;
`;

const AddButton = styled(SecondaryButton)`
    align-self: center;

    @media (max-width: 400px) {
        align-self: start;
    }
`;

const NewRoleContainer = styled.div`
    align-self: center;
    display: flex;
    align-items: center;
    gap: 10px;
`;

const BorderedContainer = styled.div`
    padding: 5px 15px;
    display: flex;
    gap: 15px;
    align-items: center;
    border-radius: 7px;
    border: 0.5px solid #d7d7d7;

    // need to add scrollbar to combobox
    & .combobox-body {
        max-height: 65px;
    }
`;

const PCustom = styled(POpenSansRegular)`
    font-size: 14px;
`;

const PlusIcon = styled.img`
    width: 18px;
    height: auto;
    cursor: pointer;

    transition: transform 0.1s;
    ${(props) => (props.isRotate ? 'transform: rotate(45deg);' : '')}
`;

/* Constants for user loading state */
const DEFAULT_STATE = 0;
const LOADING_STATE = 1;

/**
 * Component for adding rule for access group
 * @component
 */
export const AddRuleBlock = ({ objectId, notShownGroups, onRuleAdd }) => {
    const [isActive, setIsActive] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [state, setState] = useState(DEFAULT_STATE);

    /** ComboBox access rules */
    const cbAccessRules = [ruleConverter.VIEW, ruleConverter.EDIT];
    const [selectedAccessRule, setSelectedAccessRule] = useState(
        cbAccessRules[0]
    );

    /** Mutation to add access rules to access group */
    const [giveAccess] = useMutation(GIVE_ACCESS);

    /**
     * Handler for group selector
     * @param {SelectableItem} elem
     */
    const handleSelectGroup = (item) => {
        setSelectedGroup(accessGroupsModerator.getGroup(item.id));
    };

    /**
     * Handler for combo box with access rules
     * @param {string} elem
     */
    const handleSelectAccessRule = (elem) => {
        setSelectedAccessRule(elem);
    };

    const handleAddClick = () => {
        if (selectedGroup) {
            handleAdd();
        } else {
            setIsActive(false);
        }
    };

    const groups = useMemo(() => {
        const userAG = accessGroupsModerator.getGroup(
            accessGroupsModerator.currentAccessGroupId
        );
        return userAG
            .descendants()
            .filter((groupId) => !notShownGroups.includes(groupId))
            .map((groupId) => accessGroupsModerator.getGroup(groupId))
            .filter((group) => !group.parentMirror)
            .map((group) => {
                return {
                    id: group.groupId,
                    title: group.name(),
                    description: group.description(),
                    icon: groupIcon,
                    iconHeight: '22px',
                    iconWidth: '22px',
                };
            });
    }, [notShownGroups]);

    /** Handler for adding rules to access group */
    const handleAdd = () => {
        setState(LOADING_STATE);
        giveAccess({
            variables: {
                type: 'Zone',
                itemId: objectId,
                ruleDraft: {
                    accessGroupId: selectedGroup.groupId,
                    accessRule: ruleConverterReversed[selectedAccessRule],
                },
            },
            onCompleted: async (data) => {
                onRuleAdd(data.giveAccess);
                setSelectedGroup(null);
                setState(DEFAULT_STATE);
                setIsActive(false);
            },
            onError: (error) => {
                setState(DEFAULT_STATE);
                console.log(error);
                notifications.setError(
                    'Не удалось выдать права',
                    error.message
                );
            },
        });
    };

    return (
        <>
            {isActive ? (
                <NewRoleContainer>
                    <BorderedContainer>
                        <PCustom>Группа:</PCustom>
                        <InlineButton onClick={() => setIsModalOpen(true)}>
                            {selectedGroup?.name() || 'Выбрать'}
                        </InlineButton>
                        <PCustom>Права:</PCustom>
                        <ComboBox
                            items={cbAccessRules}
                            selectedElem={selectedAccessRule}
                            handleSelect={handleSelectAccessRule}
                        />
                    </BorderedContainer>
                    {state === LOADING_STATE ? (
                        <Preloader loaderSize={30} />
                    ) : (
                        <PlusIcon
                            src={plusBlueIcon}
                            alt='add'
                            onClick={handleAddClick}
                            isRotate={!selectedGroup}
                        />
                    )}
                </NewRoleContainer>
            ) : (
                <AddButton onClick={() => setIsActive(true)}>
                    <ButtonIcon src={plusIcon} alt='+' />
                    Добавить
                </AddButton>
            )}

            <ItemSelector
                isOpened={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                zIndex={1002}
                items={groups}
                onSelect={handleSelectGroup}
            />
        </>
    );
};

AddRuleBlock.propTypes = {
    /** Object UUID */
    objectId: PropTypes.string,

    /** String array of groups names that doesn't need to show */
    notShownGroups: PropTypes.array,

    /**
     * Handler for result of adding access rule
     * @type {(rules: AccessRuleFromServer[]) => void}
     */
    onRuleAdd: PropTypes.func,
};

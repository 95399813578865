import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import {
    H1OpenSansSemiBold,
    POpenSansRegular,
} from '../../../shared/ui/TextStyles';
import { SearchBar } from '../../../shared/ui/RolesSettingsComponents/SearchBar';
import { AddRuleBlock } from './AddRuleBlock';
import {
    Button,
    TextButton,
} from '../../../shared/ui/RolesSettingsComponents/Styles';
import linkIcon from '../../../shared/assets/images/icons/link.svg';
import { Group } from './Group';
import { SCROLLBAR_STYLED } from '../../../shared/ui/ScrollBar';
import accessGroupsModerator from '../../AccessGroup/model/AccessGroupsModerator';
import { objectSettingsModalVar } from '../../../shared/model/cache/Cache';
import { OBJECT_TYPE } from '../ObjectModalConstants';
import zones from '../../Zone/model/Zones';
import zonesAPI from '../../Zone/api/ZonesAPI';
import { PreloaderWithText } from '../../../shared/ui/PreloaderWithText';
import {
    updateAccessRules,
    deleteAccessRules,
    initializeAccessRules,
} from '../model/AccessRulesHelpers';

/**
 * @typedef {import('../../AccessGroup/model/AccessGroup').AccessGroup} AccessGroup
 * @typedef {import('../../Wall/api/WallSegmentsAPI').AccessRuleFromServer} AccessRuleFromServer
 */

const Wrapper = styled.div`
    padding: 10px 30px 20px;
    width: min(744px, calc(100vw - 30px));
    height: fit-content;
    max-height: min(700px, calc(100vh - 80px));
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    box-sizing: border-box;
    overflow: hidden;
`;

const Title = styled.div`
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 30px;
`;

const ObjectName = styled(H1OpenSansSemiBold)`
    font-size: 24px;
    line-height: 32.68px;
    opacity: 1;
`;

const WindowTitle = styled(POpenSansRegular)`
    font-size: 24px;
    line-height: 27.24px;
`;

const GroupsContainer = styled.div`
    width: 100%;
    padding: 3px 5px 3px 0;
    display: flex;
    box-sizing: border-box;
    overflow: hidden;
`;

const GroupsBlock = styled.div`
    width: 100%;
    padding: 7px 5px 45px 10px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;

    overflow: auto;
    ${SCROLLBAR_STYLED}
`;

const FooterButtons = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 5px;
`;

const ButtonIcon = styled.img`
    height: 13px;
    width: auto;
`;

/**
 * Component represents body of object settings modal
 * @component
 */
export const ObjectSettingsModalBody = ({ onClose }) => {
    const [searchStr, setSearchStr] = useState('');
    const [loading, setLoading] = useState(true);
    const modalSettings = useReactiveVar(objectSettingsModalVar);
    const zonesVar = useReactiveVar(zones.zonesVar);
    useReactiveVar(accessGroupsModerator.groupsMapVar);

    /**
     * @type {[AccessRuleFromServer[], function]}
     */
    const [accessRules, setAccessGroups] = useState(null);

    /**
     * Handler for update query result
     * @param {AccessRuleFromServer[]} newRules 
     */
    const handleUpdateAccessRules = (newRules) => {
        setAccessGroups(updateAccessRules(accessRules || [], newRules));
    };

    /**
     * Handler for delete query result
     * @param {AccessRuleFromServer[]} deleteRules 
     */
    const handleDeleteAccessRules = (deleteRules) => {
        setAccessGroups(deleteAccessRules(accessRules || [], deleteRules));
    };

    useEffect(() => {
        const getAccessRulesForZone = async () => {
            const ar = await zonesAPI.getAccessRules(modalSettings.objectUUID);
            setAccessGroups(initializeAccessRules(ar));
            setLoading(false);
        };

        if (modalSettings.objectType === OBJECT_TYPE.zone) {
            getAccessRulesForZone();
        }
    }, [modalSettings]);

    /**
     * groups: Access groups for object
     *
     * groupsIds: id of all groups that have access rules for this object.
     * Need for AddRuleBlock to filter groups
     *
     * objectName: object name, showing in title
     * @type {[[group: AccessGroup, rule: string], string[], string]}
     */
    let [groups, groupsIds, objectName] = useMemo(() => {
        const userAG = accessGroupsModerator.getGroup(
            accessGroupsModerator.currentAccessGroupId
        );
        if (modalSettings.objectType === OBJECT_TYPE.zone) {
            const zone = zonesVar.get(modalSettings.objectUUID);
            const descendants = userAG.descendants();
            const grIdsTemp = [];
            const grTemp = accessRules
                ?.filter(
                    // user should see access rules only for descendant groups
                    (rule) => descendants.includes(rule.group.id)
                )
                .map((rule) => {
                    const gr = accessGroupsModerator.getGroup(rule.group.id);
                    grIdsTemp.push(gr.groupId);
                    return [gr, rule.rule];
                });
            return [grTemp, grIdsTemp, zone.props.name];
        } else {
            return [[], [], ''];
        }
    }, [modalSettings, zonesVar, accessRules]);

    if (searchStr !== '') {
        const lowerCaseSearch = searchStr.toLowerCase();
        groups = groups?.filter(([group]) =>
            group.name().toLowerCase().includes(lowerCaseSearch)
        );
    }

    /**
     * @param {React.ChangeEvent} e
     */
    const handleSearch = (e) => {
        setSearchStr(e.target.value);
    };

    return (
        <Wrapper>
            <Title>
                <ObjectName>{objectName}</ObjectName>
                <WindowTitle>Настройка доступа</WindowTitle>
            </Title>
            <SearchBar
                placeholder='Найти пользователя или группу'
                onChange={handleSearch}
                value={searchStr}
                autoFocus
            />
            <GroupsContainer>
                <GroupsBlock>
                    {loading ? (
                        <PreloaderWithText loaderSize={20} text='Загружаем' />
                    ) : groups?.length === 0 ? (
                        <POpenSansRegular style={{ alignSelf: 'center' }}>
                            Группы не найдены
                        </POpenSansRegular>
                    ) : (
                        groups?.map(([group, rule]) => {
                            return (
                                <Group
                                    key={group.groupId}
                                    group={group}
                                    rule={rule}
                                    objectId={modalSettings.objectUUID}
                                    onDeleteAccessRules={
                                        handleDeleteAccessRules
                                    }
                                    onUpdateAccessRules={
                                        handleUpdateAccessRules
                                    }
                                />
                            );
                        })
                    )}
                </GroupsBlock>
            </GroupsContainer>
            <AddRuleBlock
                objectId={modalSettings.objectUUID}
                notShownGroups={groupsIds}
                onRuleAdd={handleUpdateAccessRules}
            />
            <FooterButtons>
                <TextButton>
                    <ButtonIcon
                        src={linkIcon}
                        alt=''
                        style={{ marginBottom: '2px' }}
                    />
                    Копировать ссылку
                </TextButton>
                <Button onClick={onClose}>Готово</Button>
            </FooterButtons>
        </Wrapper>
    );
};

ObjectSettingsModalBody.propTypes = {
    /** Close handler */
    onClose: PropTypes.func,
};

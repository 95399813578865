import { DocumentNode, gql } from '@apollo/client';
import { ACCESS_RULE_FRAGMENT } from '../../fragments/accessRules/AccessRuleFragment';

/** Mutation to revoke access to object from access group */
export const REVOKE_ACCESS: DocumentNode = gql`
    ${ACCESS_RULE_FRAGMENT}
    mutation revokeAccess(
        $type: SecuredObjectType!,
        $ruleSelector: AccessRuleSelector!
    ) {
        revokeAccess(type: $type, ruleSelector: $ruleSelector) {
            ...AccessRuleFragment
        }
    }
`;

import React from 'react';
import PropTypes from 'prop-types';

import searchIcon from '../../../shared/assets/images/icons/search.svg';

import { Search, SearchImg, SearchInput } from './Styles';

/**
 * @callback setValue
 * @param {string} param0
 *
 * @param {setValue} setVal callback function for set state
 * @param {string} val current value of input
 * @returns { SearchBar } Search bar component with filtering ability of filtering cards of map
 */
export const SearchBar = ({ setVal, val }) => {
    const handleChange = (e) => {
        setVal(e.target.value);
    };
    return (
        <Search>
            <SearchInput
                type='search'
                value={val}
                onChange={handleChange}
                name='search'
                placeholder='Поиск по картам'
                autoComplete='off'
            />
            <SearchImg src={searchIcon} alt='search image' />
        </Search>
    );
};

SearchBar.propTypes = {
    setVal: PropTypes.func,
    val: PropTypes.string,
};

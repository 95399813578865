import { subtractTwoPoints } from '../../../../shared/lib/modules/math/Math';

/**
 * Function that generates square wall
 *
 * @param {object} firstClickPoint  point chosen for the start corner
 * @param {object} secondClickPoint diagonally opposite to point
 * @returns {Array} array of points
 */
export const createSquareWall = (firstClickPoint, secondClickPoint) => {
    const difference = subtractTwoPoints(firstClickPoint, secondClickPoint);
    const squareSide = Math.min(Math.abs(difference.x), Math.abs(difference.y));
    const xDirection = Math.sign(difference.x);
    const yDirection = Math.sign(difference.y);

    const point1 = { ...firstClickPoint };
    const point2 = {
        x: firstClickPoint.x + squareSide * xDirection,
        y: firstClickPoint.y,
    };
    const point3 = {
        x: point2.x,
        y: point2.y + squareSide * yDirection,
    };
    const point4 = {
        x: point1.x,
        y: point3.y,
    };

    return [point1, point2, point3, point4];
};

/**
 * Function that generates rectangle wall
 *
 * @param {object}  firstClickPoint first chosen point
 * @param {object}  secondClickPoint diagonally opposite to point
 * @returns {Array} array of segments
 */
export const createRectangleWall = (firstClickPoint, secondClickPoint) => {
    if (
        !firstClickPoint.x &&
        !firstClickPoint.y &&
        !secondClickPoint.x &&
        !secondClickPoint.y
    ) {
        return;
    }

    const point1 = { ...firstClickPoint };
    const point2 = {
        x: secondClickPoint.x,
        y: firstClickPoint.y,
    };
    const point3 = { ...secondClickPoint };
    const point4 = {
        x: firstClickPoint.x,
        y: secondClickPoint.y,
    };

    return [point1, point2, point3, point4];
};

import { DocumentNode, gql } from '@apollo/client';
import { ACCESS_GROUP_FRAGMENT } from '../../fragments/accessGroups/AccessGroupFragment';

/** Mutation to assign user to access group */
export const ASSIGN_USER_TO_ACCESS_GROUP: DocumentNode = gql`
    ${ACCESS_GROUP_FRAGMENT}
    mutation assignToGroup($userSelector: UserSelector!, $groupId: String!) {
        assignToGroup(userSelector: $userSelector, groupId: $groupId) {
            ...AccessGroupFragment
        }
    }
`;

import { graphicSchemaVar } from '../../../shared/model/cache/Cache';
import {
    activateMapinsForm,
    objectWithSchemaTypes,
} from '../../../shared/model/mapinsForm/activateMapinsForm';
import mapObjects from '../../MapObject/model/MapObjects';
import { TYPES } from '../../Subscriptions/api/SubscriptionsModerator';
import floorGraph from '../../Wall/model/FloorGraph';
import zones from '../../Zone/model/Zones';

/**
 * @file
 * This file store handlers for updating mapins schema for different objects
 */

/**
 * Function to update schema on map object
 * @param {object} item data from server
 */
export const updateSchemaOnObject = (item) => {
    switch (item.__typename) {
        case TYPES.MapObject:
            updateSchemaOnMapObject(item);
            break;

        case TYPES.Zone:
            updateSchemaOnZone(item);
            break;

        case TYPES.WallSegment:
            updateSchemaOnWallSegment(item);
            break;

        default:
            console.warn(
                `Subscription received unhandled MapinsSchema operation for type '${item.__typename}`
            );
            break;
    }
};

/**
 * Function to handle schema change on map object
 * @param {object} item data from server
 */
export const updateSchemaOnMapObject = (item) => {
    const mapObject = mapObjects.updateMapObjectSchema(
        item.id,
        item.data,
        item.schemaImpls
    );
    if (mapObject) {
        triggerSchemaRender(
            item.id,
            mapObject,
            objectWithSchemaTypes.mapObject
        );
    }
};

/**
 * Function to handle schema change on zone
 * @param {object} item data from server
 */
export const updateSchemaOnZone = (item) => {
    const zone = zones.updateSchema(item.id, item.schemaImpls);
    if (zone) {
        triggerSchemaRender(item.id, zone.props, objectWithSchemaTypes.zone);
    }
};

/**
 * Function to handle schema change on wall segment
 * @param {object} item data from server
 */
export const updateSchemaOnWallSegment = (item) => {
    const segment = floorGraph.segmentsModerator.updateSegmentSchema(
        item.startPoint.id,
        item.endPoint.id,
        item.data,
        item.schemaImpls
    );
    if (segment) {
        triggerSchemaRender(
            item.id,
            segment.getInfo(),
            objectWithSchemaTypes.wallSegment
        );
    }
};

/**
 * Function to triggers schema rerender, if schema already opened for item
 * @param {string} objectId UUID
 * @param {string} objectType
 */
const triggerSchemaRender = (objectId, object, objectType) => {
    const schema = graphicSchemaVar();
    if (schema?.objectId === objectId) {
        activateMapinsForm(object, objectType);
    }
};

import React from 'react';
import CommandManager from '../../../CommandManager/model/CommandManager';
import { deleteMapObjectCommand } from '../../../CommandManager/lib/commands/mapObject/DeleteMapObject';
import { closeContextMenu } from '../../model/CloseContextMenu';
import mapObjects from '../../../MapObject/model/MapObjects';
import { PointsForContextMenu } from '../styles/PointsForContextMenu';
import {
    activateMapinsForm,
    objectWithSchemaTypes,
} from '../../../../shared/model/mapinsForm/activateMapinsForm';
import rightBarModerator from '../../../../pages/Constructor/RightBar/model/RightBarModerator';
import { RightBarOperation } from '../../../../pages/Constructor/RightBar/model/RightBarOperations';
import { RBTeleportSettings } from '../../../../pages/Constructor/RightBar/ui/Operation/TeleportSettings/RBTeleportSettings';
import { currentToolVar } from '../../../../shared/model/cache/Cache';
import { Tools } from '../../../../pages/Constructor/TopBar/TopBarEntires/Tools/Constants/Tools';

export const MapObjectContextMenu = ({ currentItem }) => {
    const handleDelete = () => {
        closeContextMenu();
        CommandManager.do(deleteMapObjectCommand(currentItem));
    };

    const handleZIndexChange = (up = true) => {
        mapObjects.reorder(currentItem, up);
        closeContextMenu();
    };

    const handleActivateMapinsForm = () => {
        activateMapinsForm(currentItem, objectWithSchemaTypes.mapObject);
        closeContextMenu();
    };

    const menu = [
        {
            text: 'Открыть форму',
            onClick: handleActivateMapinsForm,
        },
        {
            text: 'Удалить',
            onClick: handleDelete,
        },
        {
            text: 'Переместить наверх',
            onClick: () => handleZIndexChange(true),
        },
        {
            text: 'Переместить вниз',
            onClick: () => handleZIndexChange(false),
        },
    ];

    // add movements for teleport items
    if (currentItem.teleport) {
        menu.splice(2, 0, {
            text: 'Перемещения',
            onClick: () => {
                mapObjects.setTeleportStart(currentItem.id);
                rightBarModerator.setOperation({
                    operationName: RightBarOperation.TELEPORT_SETTINGS,
                    customRightBar: true,
                    component: <RBTeleportSettings />,
                    onClose: () => {
                        mapObjects.clearTeleportCreationVar();
                        if (currentToolVar() === Tools.addTeleport) {
                            currentToolVar(Tools.none);
                        }
                    },
                });
                closeContextMenu();
            },
        });
    }

    return <PointsForContextMenu menu={menu} />;
};

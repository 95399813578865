import { gql } from '@apollo/client';

/**
 * Mutation for deleting projects floor by its id
 */
export const DELETE_FLOOR = gql`
    mutation deleteFloor($id: String!) {
        deleteFloor(id: $id) {
            id
        }
    }
`;

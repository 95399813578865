import { deepCopy } from '../../utils/common/DeepCopy';

/**
 * @typedef {import('../../../typedefs/Types').Point} Point
 */

export const LinesIntersectionType = {
    ZERO_LENGTH_LINE: 'ZERO_LENGTH_LINE',
    PARALLEL_LINES: 'PARALLEL_LINES',
    MATCHING_LINES: 'MATCHING_LINES',
    FAKE_POINT: 'FAKE_POINT',
    ONE_POINT: 'ONE_POINT',
};

/**
 * Function to convert radians to degrees
 * @param {number} rad radians
 * @return {number} degrees
 */
export const radToDeg = (rad) => {
    return (rad * 180) / Math.PI;
};

/**
 * Find distance between two points.
 * Also use to find line length by line start and end
 *
 * @param {Point} point1 first point/line start
 * @param {Point} point2 second point/line end
 * @returns {number} distance/length
 */
export const getDistanceBetweenPoints = (point1, point2) => {
    return Math.sqrt((point2.x - point1.x) ** 2 + (point2.y - point1.y) ** 2);
};

/**
 * Find distance between point and line.
 * It's the length of perpendicular from point to line
 *
 * @param {Point} point point
 * @param {Point} lineStart line start
 * @param {Point} lineEnd line end
 * @returns {number} distance
 */
export const getDistanceBetweenLinePoint = (point, lineStart, lineEnd) => {
    const [A, B, C] = getLineCoefficients(lineStart, lineEnd);
    return Math.abs(A * point.x + B * point.y + C) / Math.sqrt(A ** 2 + B ** 2);
};

/**
 * Find point, that is in the middle between two points.
 *
 * @param {Point} point1 first point
 * @param {Point} point2 second point
 * @returns {Point} middle point
 */
export const getMiddleBetweenPoints = (point1, point2) => {
    return {
        x: (point1.x + point2.x) / 2,
        y: (point1.y + point2.y) / 2,
    };
};

/**
 * Adds point1 coordinates to point2 coordinates
 *
 * @param {Point} point1 first point
 * @param {Point} point2 second point
 * @returns {Point} summation result point
 */
export const addTwoPoints = (point1, point2) => {
    return {
        x: point1.x + point2.x,
        y: point1.y + point2.y,
    };
};

/**
 * Subtracts point1 coordinates from point2 coordinates
 *
 * @param {Point} point1 subtrahend
 * @param {Point} point2 minuend point
 * @returns {Point} subtraction result point (point2 - point1)
 */
export const subtractTwoPoints = (point1, point2) => {
    return {
        x: point2.x - point1.x,
        y: point2.y - point1.y,
    };
};

/**
 * Function that finds the coefficients for the General equation of a straight
 * line (Ax + By + C = 0, function finds A, B and C).
 *
 * @param {Point} start - wall segment start
 * @param {Point} end - wall segment end
 * @returns {Array} array of line coefficients
 */
export const getLineCoefficients = (start, end) => {
    const A = end.y - start.y;
    const B = start.x - end.x;
    const C = (start.y - end.y) * end.x + (end.x - start.x) * end.y;

    return [A, B, C];
};

/**
 * Calculates line rotation in degrees
 *
 * @param {Point} start start of line
 * @param {Point} end end of line
 * @return {number} line rotation in degrees
 */
export const getLineRotation = (start, end) => {
    return Math.atan((end.y - start.y) / (end.x - start.x)) * 57.296;
};

/**
 * Reflects a point M relative to a point O
 *
 * @param {Point} reflected - point M to reflect
 * @param {Point} pivot - point O
 * @returns point with coordinates (x, y), where x = (2 * Ox - Mx), y = (2 * Oy - My)
 */
export const reflectPoint = (reflected, pivot) => {
    return {
        x: 2 * pivot.x - reflected.x,
        y: 2 * pivot.y - reflected.y,
    };
};

/**
 * Finds scalar multiplication of vectors
 *
 * @param {Point} vector1 first vector
 * @param {Point} vector2 second vector
 * @returns {Number} scalar multiplication
 */
export const multiplyVectorsScalarly = (vector1, vector2) => {
    return vector1.x * vector2.x + vector1.y * vector2.y;
};

/**
 * Multiplies vector by number
 *
 * @param {Point} vector vector
 * @param {Number} number number
 * @returns {Point} vector multiplied by number
 */
export const multiplyVectorByNumber = (vector, number) => {
    return {
        x: vector.x * number,
        y: vector.y * number,
    };
};

/**
 * Finds vector module
 *
 * @param {Point} vector vector
 * @returns {Number} vector module
 */
export const getVectorModule = (vector) => {
    return getDistanceBetweenPoints({ x: 0, y: 0 }, vector);
};

/**
 * Function to find cos(angle) between vectors
 *
 * @param {Point} vector1 {x: number, y: number}
 * @param {Point} vector2 {x: number, y: number}
 * @return {Number} cos of angle between vectors
 */
export const findCosAngleBetweenVectors = (vector1, vector2) => {
    return (
        multiplyVectorsScalarly(vector1, vector2) /
        (getVectorModule(vector1) * getVectorModule(vector2))
    );
};

/**
 * Function to find cos(angle) between vectors
 *
 * @param {Point} vector1 {x: number, y: number}
 * @param {Point} vector2 {x: number, y: number}
 * @return {Number} cos of angle between vectors
 */
export const findAngleBetweenVectors = (vector1, vector2) => {
    return Math.acos(findCosAngleBetweenVectors(vector1, vector2));
};

/**
 * Function to find angle between vector and line parallel to x-axis.
 * @param {Point} vector {x: number, y: number}
 * @return {Number} angle (in radians)
 */
export const findAngleBetweenVectorAndXAxis = (vector) => {
    return (Math.atan2(vector.y, vector.x) + 2 * Math.PI) % (2 * Math.PI);
};

/**
 * Function that determines whether the point is in the area
 * @param {Point[]} lineString
 * @param {Point} point
 * @return {Boolean} true if point is in the area
 */
export const areaContainsPoint = (lineString, point) => {
    let count = 0;
    for (let b = 0; b < lineString.length; b++) {
        let vertex1 = lineString[b];
        let vertex2 = lineString[(b + 1) % lineString.length];
        if (west(vertex1, vertex2, point.x, point.y)) ++count;
    }
    return count % 2;

    /**
     * @return {Boolean} true if (x,y) is west of the line segment connecting A and B
     */
    function west(A, B, x, y) {
        if (A.y <= B.y) {
            if (y <= A.y || y > B.y || (x >= A.x && x >= B.x)) {
                return false;
            } else if (x < A.x && x < B.x) {
                return true;
            } else {
                return (y - A.y) / (x - A.x) > (B.y - A.y) / (B.x - A.x);
            }
        } else {
            return west(B, A, x, y);
        }
    }
};

/**
 * Function that sorts array of points to make polygon from these points
 * @param {Point[]} points array of points
 * @return {Point[]} sorted array of points for polygon drawing
 */
export const polySort = (points) => {
    const squaredPolar = (point, centre) => {
        return {
            xp: Math.atan2(point.y - centre.y, point.x - centre.x),
            yp: (point.x - centre.x) ** 2 + (point.y - centre.y) ** 2, // Square of distance
        };
    };
    const sortedPoints = deepCopy(points);
    // Get "centre of mass"
    const centre = {
        x: sortedPoints.reduce((sum, p) => sum + p.x, 0) / sortedPoints.length,
        y: sortedPoints.reduce((sum, p) => sum + p.y, 0) / sortedPoints.length,
    };

    // Sort by polar angle and distance, centered at this centre of mass.
    for (let point of sortedPoints) {
        Object.assign(point, squaredPolar(point, centre));
    }
    sortedPoints.sort((a, b) => a.xp - b.xp || a.yp - b.yp);
    // Throw away the temporary polar coordinates
    for (let point of sortedPoints) {
        delete point.xp;
        delete point.yp;
    }
    return sortedPoints;
};

/**
 * Function checks if line is zero length
 * @param {Point[]} lineString
 * @returns {Boolean} true if line is zero length
 */
export const isLineZeroLength = (lineString) => {
    if (
        lineString[0].x === lineString[1].x &&
        lineString[0].y === lineString[1].y
    ) {
        return true;
    }
    return false;
};

/**
 * Function finds intersection between two lines
 * @param {Point[]} lineString1
 * @param {Point[]} lineString2
 * @returns {{type: LinesIntersectionType, point: {x: Number, y: Number}}}
 */
export const findLinesIntersection = (lineString1, lineString2) => {
    if (isLineZeroLength(lineString1) || isLineZeroLength(lineString2)) {
        return { type: LinesIntersectionType.ZERO_LENGTH_LINE };
    }

    const denominator =
        (lineString2[1].y - lineString2[0].y) *
            (lineString1[1].x - lineString1[0].x) -
        (lineString2[1].x - lineString2[0].x) *
            (lineString1[1].y - lineString1[0].y);

    const numA =
        (lineString2[1].x - lineString2[0].x) *
            (lineString1[0].y - lineString2[0].y) -
        (lineString2[1].y - lineString2[0].y) *
            (lineString1[0].x - lineString2[0].x);

    const numB =
        (lineString1[1].x - lineString1[0].x) *
            (lineString1[0].y - lineString2[0].y) -
        (lineString1[1].y - lineString1[0].y) *
            (lineString1[0].x - lineString2[0].x);

    // Lines are parallel
    if (denominator === 0) {
        if (numA === 0 && numB === 0) {
            return { type: LinesIntersectionType.MATCHING_LINES };
        }
        return { type: LinesIntersectionType.PARALLEL_LINES };
    }

    const rootA = numA / denominator;
    const rootB = numB / denominator;

    // Return a object with the x and y coordinates of the intersection
    let x = lineString1[0].x + rootA * (lineString1[1].x - lineString1[0].x);
    let y = lineString1[0].y + rootA * (lineString1[1].y - lineString1[0].y);

    // is the intersection along the segments
    if (rootA < 0 || rootA > 1 || rootB < 0 || rootB > 1) {
        return {
            type: LinesIntersectionType.FAKE_POINT,
            point: {
                x,
                y,
            },
        };
    }

    return {
        type: LinesIntersectionType.ONE_POINT,
        point: {
            x,
            y,
        },
    };
};

/**
 * Function checks if line intersects rect
 * @param {Point[]} line
 * @param {Point[][]} rect
 * @returns {Boolean} true if intersects, false otherwise
 */
export const areLineAndRectIntersected = (line, rect) => {
    for (const lineString of rect) {
        const intersectionType = findLinesIntersection(lineString, line).type;
        if (
            intersectionType === LinesIntersectionType.ONE_POINT ||
            intersectionType === LinesIntersectionType.MATCHING_LINES
        ) {
            return true;
        }
    }

    return false;
};

/**
 * Function checks if point is on one of two ends of line
 * @param {Point} point
 * @param {Point[]} line
 * @returns {{result: Boolean, pointIndex: Number}} result is true if point is on line end,
 * pointIndex is index of line point that equal argument point
 */
export const isPointOnLineEnd = (point, line) => {
    if (arePointsEqual(point, line[0])) {
        return {
            result: true,
            pointIndex: 0,
        };
    } else if (arePointsEqual(point, line[1])) {
        return {
            result: true,
            pointIndex: 1,
        };
    }
    return { result: false };
};

/**
 * Function for getting normal line to left or lower point of given line with certain length (normalLength)
 * @param {Point[]} lineString
 * @param {Number} normalLength
 */
export const getLineNormalByDistance = (lineString, normalLength) => {
    if (isLineVertical(lineString)) {
        const lowerLinePoint = getLowerLinePoint(lineString);

        return [
            {
                x: lowerLinePoint.x + normalLength,
                y: lowerLinePoint.y,
            },
            {
                ...lowerLinePoint,
            },
        ];
    }

    if (isLineHorizontal(lineString)) {
        const leftLinePoint = getLeftLinePoint(lineString);

        return [
            {
                x: leftLinePoint.x,
                y: leftLinePoint.y + normalLength,
            },
            { ...leftLinePoint },
        ];
    }

    /* For other random lines */

    /**
     * Use the left point for certainty
     */
    const leftLinePoint = getLeftLinePoint(lineString);
    const lineStringCopy = deepCopy(lineString);
    if (!arePointsEqual(lineStringCopy[0], leftLinePoint)) {
        lineStringCopy.reverse();
    }

    const newLine = expandLine(lineStringCopy, normalLength);
    const rotatedLine = rotateLine(newLine[0], newLine[1], Math.PI / 2);
    return rotatedLine.reverse();
};

/**
 * Function checks if points are equal or not
 * @param {Point} point1
 * @param {Point} point2
 * @returns {Boolean} true if points are equal
 */
export const arePointsEqual = (point1, point2) => {
    return point1?.x === point2?.x && point1?.y === point2?.y;
};

/**
 * Function for rotating the line
 * @param {Point} start
 * @param {Point} end
 */
export const rotateLine = (start, end, radians) => {
    return [
        { ...start },
        {
            x:
                (end.x - start.x) * Math.cos(radians) -
                (end.y - start.y) * Math.sin(radians) +
                start.x,
            y:
                (end.x - start.x) * Math.sin(radians) +
                (end.y - start.y) * Math.cos(radians) +
                start.y,
        },
    ];
};

/**
 * Function for getting left point of line
 * @param {Point[]} lineString
 * @returns {Point}
 */
export const getLeftLinePoint = (lineString) => {
    return {
        ...lineString[
            [lineString[0].x, lineString[1].x].findIndex(
                (item) => item === Math.min(lineString[0].x, lineString[1].x)
            )
        ],
    };
};

/**
 * Function for getting right point of line
 * @param {Point[]} lineString
 * @returns {Point}
 */
export const getRightLinePoint = (lineString) => {
    return {
        ...lineString[
            [lineString[0].x, lineString[1].x].findIndex(
                (item) => item === Math.max(lineString[0].x, lineString[1].x)
            )
        ],
    };
};

/**
 * Function for getting lower point of line
 * @param {Point[]} lineString
 * @returns {Point}
 */
export const getLowerLinePoint = (lineString) => {
    return {
        ...lineString[
            [lineString[0].y, lineString[1].y].findIndex(
                (item) => item === Math.max(lineString[0].y, lineString[1].y)
            )
        ],
    };
};

/**
 * Function for getting upper point of line
 * @param {Point[]} lineString
 * @returns {Point}
 */
export const getUpperLinePoint = (lineString) => {
    return {
        ...lineString[
            [lineString[0].y, lineString[1].y].findIndex(
                (item) => item === Math.min(lineString[0].y, lineString[1].y)
            )
        ],
    };
};

/**
 * Function for getting left or (if line if vertical) lower point of line
 * @param {Point[]} lineString
 * @returns {Point}
 */
export const getLeftOrLowerLinePoint = (lineString) => {
    if (!isLineVertical(lineString)) {
        return getLeftLinePoint(lineString);
    } else {
        return getLowerLinePoint(lineString);
    }
};

/**
 * Function for getting right or (if line if horizontal) upper point of line
 * @param {Point[]} lineString
 * @returns {Point}
 */
export const getRightOrUpperLinePoint = (lineString) => {
    if (!isLineVertical(lineString)) {
        return getRightLinePoint(lineString);
    } else {
        return getUpperLinePoint(lineString);
    }
};

/**
 * Function for expanding the line from first point in lineString to second
 * @param {Point[]} lineString
 * @param {Number} length
 * @returns {Point[]} expanded line
 */
export const expandLine = (lineString, length) => {
    if (isLineHorizontal(lineString)) {
        return [
            { ...lineString[0] },
            {
                x:
                    lineString[0].x +
                    length * Math.sign(lineString[1].x - lineString[0].x),
                y: lineString[1].y,
            },
        ];
    }

    if (isLineVertical(lineString)) {
        return [
            { ...lineString[0] },
            {
                x: lineString[1].x,
                y:
                    lineString[0].y +
                    length * Math.sign(lineString[1].y - lineString[0].y),
            },
        ];
    }

    /**
     * For other random lines
     */
    return [
        { ...lineString[0] },
        {
            x:
                lineString[0].x +
                ((lineString[1].x - lineString[0].x) * length) /
                    getDistanceBetweenPoints(lineString[0], lineString[1]),
            y:
                lineString[0].y +
                ((lineString[1].y - lineString[0].y) * length) /
                    getDistanceBetweenPoints(lineString[0], lineString[1]),
        },
    ];
};

/**
 * Function for moving line
 * It takes into account the inverted Y axis
 * @param {Point[]} lineString
 * @param {Number} dx
 * @param {Number} dy
 * @returns {Point[]}
 */
export const moveLine = (lineString, dx, dy) => {
    return lineString.map((item) => ({ x: item.x + dx, y: item.y - dy }));
};

/**
 * Function checks if line is vertical or not
 * @param {Point[]} lineString
 * @returns {Boolean} true if line is vertical
 */
export const isLineVertical = (lineString) => {
    return lineString[0].x === lineString[1].x;
};

/**
 * Function checks if line is horizontal or not
 * @param {Point[]} lineString
 * @returns {Boolean} true if line is horizontal
 */
export const isLineHorizontal = (lineString) => {
    return lineString[0].y === lineString[1].y;
};

/**
 * Function checks if point is to the left from anchor
 * @param {Point} point
 * @param {Point} anchor
 * @returns {Number} difference
 */
export const isPointToTheLeftFromAnother = (point, anchor) => {
    return anchor.x - point.x;
};

/**
 * Function checks if point is lower than anchor
 * @param {Point} point
 * @param {Point} anchor
 * @returns {Number} difference
 */
export const isPointLowerThanAnother = (point, anchor) => {
    return point.y - anchor.y;
};

/**
 * Function checks if point is on line
 * @param {Point} point
 * @param {Point[]} lineString
 * @returns {Boolean} true if point is on line, false otherwise
 */
export const isPointOnLine = (point, lineString) => {
    if (isLineVertical(lineString)) {
        return (
            point.x === lineString[0].x &&
            point.y > Math.min(lineString[0].y, lineString[1].y) &&
            point.y < Math.max(lineString[0].y, lineString[1].y)
        );
    }

    if (isLineHorizontal(lineString)) {
        return (
            point.y === lineString[0].y &&
            point.x > Math.min(lineString[0].x, lineString[1].x) &&
            point.x < Math.max(lineString[0].x, lineString[1].x)
        );
    }

    return (
        point.x > Math.min(lineString[0].x, lineString[1].x) &&
        point.x < Math.max(lineString[0].x, lineString[1].x) &&
        point.y > Math.min(lineString[0].y, lineString[1].y) &&
        point.y < Math.max(lineString[0].y, lineString[1].y)
    );
};

/**
 * Function for computing parallel vector with defined offset
 * @param {Point} start
 * @param {Point} end
 * @param {Number} shift offset distance
 * @returns {{left: Point[], right: Point[]}} two vectors with offset to base vector
 */
export const getOffsetLines = (start, end, shift) => {
    const [dx, dy] = [end.x - start.x, end.y - start.y];

    // normalizing
    const len = getDistanceBetweenPoints(start, end);
    const [udx, udy] = [dx / len, dy / len];

    let [px, py] = [-udy, udx];

    const [sx1, sy1] = [
        start.x + px * Math.abs(shift),
        start.y + py * Math.abs(shift),
    ];
    const [ex1, ey1] = [sx1 + dx, sy1 + dy];

    const [sx2, sy2] = [
        start.x - px * Math.abs(shift),
        start.y - py * Math.abs(shift),
    ];
    const [ex2, ey2] = [sx2 + dx, sy2 + dy];

    return {
        left: [
            { x: sx1, y: sy1 },
            { x: ex1, y: ey1 },
        ],
        right: [
            { x: sx2, y: sy2 },
            { x: ex2, y: ey2 },
        ],
    };
};

import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from "prop-types";

/**
 * Styled table component for extra link
 * Ex: registration, password recovery
 */
const Extra = styled.table`
    border-spacing: 0 4px;
    width: 322px;
    text-align: end;
`;

/**
 * Styled text
 */
const Text = styled.p`
    margin: 0;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 20px;
    text-align: start;

    opacity: 0.7;
`;

/**
 * Styled link
 */
const LinkTo = styled(Link)`
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;

    text-decoration-line: underline;

    color: #023aff;
`;

/**
 * Component to show extra links: text and link
 * @returns Table with extra links
 */
export const ExtraLinks = ({ style, items }) => {
    return (
        <Extra style={style}>
            <tbody>
                {items.map(({ text, name, to }, i) => (
                    <tr key={i}>
                        <td>
                            <Text>{text}</Text>
                        </td>
                        <td>
                            <LinkTo to={to}>{name}</LinkTo>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Extra>
    );
};

ExtraLinks.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),
};
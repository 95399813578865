import React from 'react';
import styled from 'styled-components';

import MapinsLogo from '../assets/images/icons/logo.svg';

/**
 * Styled logo img
 * Used for Mapins logo
 */
const LogoImg = styled.img`
    width: 97px;
    height: 95px;

    margin: 36px 0 0 15px;
`;

/**
 * Component to show Mapins logo
 * @returns { Logo } Component with Mapins logo img
 */
export const Logo = () => {
    return <LogoImg src={MapinsLogo} alt="Mapins logo" />;
};

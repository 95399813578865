import { gql } from '@apollo/client';

/**
 * Mutation for updating zone
 */
export const UPDATE_ZONE = gql`
    mutation updateZone($zoneDiff: ZoneDiff!) {
        updateZone(zoneDiff: $zoneDiff) {
            color
            id
            version
            name
            global
            accessRules {
                group {
                    id
                    name
                }
                rule
            }
            floor {
                id
            }
            wall {
                graph {
                    key
                }
            }
            schemaImpls {
                data
                id
                version
                schema {
                    id
                    schema
                    version
                }
            }
        }
    }
`;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-konva';

import { Tools } from '../../../../pages/Constructor/TopBar/TopBarEntires/Tools/Constants/Tools';

/**
 * Reactive variables
 */
import { currentToolVar } from '../../../../shared/model/cache/Cache';

/**
 * Wall logic
 */
import {
    WALL_LINE_CAP,
    WALL_LINE_JOIN,
    DASH_VALUE,
    WALL_EDGE_NAME,
    SELECTED_COLOR,
    MIN_HIT_STROKE_WIDTH,
} from '../../lib/helpers/WallDefaultValues';

/**
 * Import wall handlers
 */
import {
    handleClickEdge,
    handleMouseEnter,
    handleMouseLeave,
} from '../../lib/utils/WallHandlers';

import selector from '../../../Selector/model/Selector';
import { useReactiveVar } from '@apollo/client';
import { TransparentDraggableSegment } from './TransparentDraggableSegment';
// import { handleScrollSelect } from '../../../Selector/lib/utils/HandleScrollSelect';
import floorGraph from '../../model/FloorGraph';
import { RULE_EDIT, RULE_VIEW } from '../../../AccessGroup/model/AGConstants';
import { CURSORS } from '../../../../shared/lib/utils/common/CursorModerator';

/**
 * Component that represent edge on canvas
 *
 * @component
 * @example
 * <WallSegment
 *        wallId={wallId}
 *        points={points}
 *        color={color}
 *        strokeWidth={strokeWidth}
 *        visible={visible}
 *        isVirtual={isVirtual}
 *  />
 */
export const WallSegment = ({
    wallId,
    points,
    color,
    strokeWidth,
    isVirtual,
    accessRule = RULE_VIEW,
}) => {
    const [tmpPoints, setTmpPoints] = useState([]);

    useReactiveVar(selector.selectedEdgesVar);
    useReactiveVar(selector.selectedVerticesVar);

    const hovered = useReactiveVar(selector.isHoveredVar);

    useEffect(() => {
        setTmpPoints([...points]);
        return () => setTmpPoints([]);
    }, [points]);

    return (
        <>
            <Line
                id={wallId}
                listening={accessRule === RULE_EDIT}
                name={WALL_EDGE_NAME}
                graph={floorGraph}
                points={points}
                stroke={color}
                closed={!isVirtual}
                strokeWidth={1}
                fill={selector.isEdgeSelected(wallId) ? SELECTED_COLOR : color}
                lineJoin={WALL_LINE_JOIN}
                lineCap={WALL_LINE_CAP}
                onClick={(e) => handleClickEdge(e, wallId, floorGraph)}
                onMouseEnter={(e) => {
                    handleMouseEnter(CURSORS.POINTER);
                    selector.isHoveredVar(e.target.id());
                }}
                onMouseLeave={handleMouseLeave}
                dash={DASH_VALUE}
                dashEnabled={isVirtual}
                perfectDrawEnabled={false}
                shadowForStrokeEnabled={false}
                // TODO: restore logic of selecting by scroll
                // onWheel={handleScrollSelect}
                transformsEnabled={'position'}
                accessRule={accessRule}
                hitStrokeWidth={MIN_HIT_STROKE_WIDTH}
            />

            {/* It's a transparent line for handling dragging */}
            {currentToolVar() === Tools.none &&
                selector.isEdgeSelected(wallId) &&
                hovered === wallId && (
                    <TransparentDraggableSegment
                        wallId={wallId}
                        tmpPoints={tmpPoints}
                        strokeWidth={strokeWidth}
                        isVirtual={isVirtual}
                        clickEdge={(e) =>
                            handleClickEdge(e, wallId, floorGraph)
                        }
                        accessRule={accessRule}
                    />
                )}
        </>
    );
};

WallSegment.propTypes = {
    /**
     * Id of the wall
     */
    wallId: PropTypes.string,
    /**
     * Array of points
     */
    lineString: PropTypes.arrayOf(
        PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
            uniqueId: PropTypes.string,
        })
    ),
    /**
     * Color of the wall
     */
    color: PropTypes.string,
    /**
     * Wall width
     */
    strokeWidth: PropTypes.number,
    /**
     * Visibility for anchor points in tmp and created walls
     */
    visible: PropTypes.bool,

    /** Access rule for current user */
    accessRule: PropTypes.oneOf([RULE_VIEW, RULE_EDIT]),
};

import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { useRoutes } from '../shared/model/routes/routes';
import { GlobalStyle } from './ui/GlobalStyles';
import { VersionWatermark } from '../shared/ui/VersionWatermark';
import { HintsProvider } from '../features/HintSystem/ui/HintsProvider';
import notifications from '../features/HintSystem/model/Notifications';

/**
 * Main component, that is connected to index.js
 */
export const App = () => {
    const routes = useRoutes();
    return (
        <>
            <GlobalStyle />
            <BrowserRouter>{routes}</BrowserRouter>
            <VersionWatermark />
            <HintsProvider hintsInstance={notifications} />
        </>
    );
};

import { stageVar } from '../../../../../shared/model/cache/Cache';
import { openWallContextMenu } from '../../../../ContextMenu/model/OpenWallContextMenu';

/**
 * Function to handle context menu of wall and wall segment.
 *
 * @param {string} edgeId
 * @param {boolean} isVirtual
 */
export const handleContextMenu = (edgeId, isVirtual) => {
    if (!stageVar()) {
        return;
    }

    openWallContextMenu(
        { edgeId, isVirtual },
        stageVar().getRelativePointerPosition()
    );
};

import {
    closestWallPointVar,
    currentToolVar,
    projectWallTypesVar,
    stageVar,
    modeVar,
    graphicSchemaVar,
    currentCanvasPointVar,
} from '../../../../shared/model/cache/Cache';
import {
    Constraints,
    Tools,
} from '../../../../pages/Constructor/TopBar/TopBarEntires/Tools/Constants/Tools';
import floorGraph from '../../../../features/Wall/model/FloorGraph';
import routesModerator from '../../../../features/Route/model/RoutesModerator';
import rulerObject from '../../../../features/Ruler/model/Ruler';
import {
    EMBEDDED_OBJECT_NAME,
    WALL_CIRCLE_NAME,
    WALL_EDGE_NAME,
} from '../../../../features/Wall/lib/helpers/WallDefaultValues';
import { CREATING_MODE } from '../../../../shared/lib/utils/ModeDefaultValues';
import Selector from '../../../../features/Selector/model/Selector';
import { v4 as uuid } from 'uuid';
import Konva from 'konva';
import { LEFT_MOUSE_BUTTON } from '../CanvasDefaultValues';
import { LinkedSegment } from '../../../../features/Wall/model/SegmentsModerator';
import {
    ROUTE_EDGE_NAME,
    ROUTE_VERTEX_NAME,
} from '../../../../features/Route/lib/RouteDefaultValues';
import { arePointsEqual } from '../../../../shared/lib/modules/math/Math';
import zones from '../../../../features/Zone/model/Zones';
import { RULE_EDIT } from '../../../../features/AccessGroup/model/AGConstants';
import notifications from '../../../../features/HintSystem/model/Notifications';
import alertsModerator from '../../../../features/Alert/model/AlertsModerator';
import rightBarModerator from '../../../../pages/Constructor/RightBar/model/RightBarModerator';
import { calcCoordinateForSecondPoint } from '../../../../features/Wall/lib/helpers/CalculateCoordinateForSecondPoint';

/**
 * @typedef {import('../../../../shared/lib/modules/math/Math').Point} Point
 * @typedef {import('../../../../features/Wall/model/FloorGraph').FloorGraph} FloorGraph
 */

/**
 * Choosing first wall segment point and segment creation
 * @param {MouseEvent} e Mouse Click event
 */
export const handleStageClick = (e) => {
    if (e.evt.button !== LEFT_MOUSE_BUTTON) {
        return;
    }

    if (
        [Tools.rulerAny, Tools.rulerHorizontal, Tools.rulerVertical].includes(
            currentToolVar()
        )
    ) {
        handleClickWithRulerTool(e);
    } else if (currentToolVar() === Tools.wall) {
        handleClickWithWallTool(e);
    } else if (currentToolVar() === Tools.zone) {
        handleClickWithZoneTool();
    } else if (currentToolVar() === Tools.embeddedObject) {
        handleClickWithEmbeddedObjectTool(e);
    } else if (currentToolVar() === Tools.comment) {
        handleClickWithCommentTool();
    } else if (currentToolVar() === Constraints.route) {
        handleClickWithRouteTool(e);
    } else {
        floorGraph.cancelCreating();
        routesModerator.cancelCreating();
    }

    if (e.target.id() !== graphicSchemaVar()?.objectId) {
        graphicSchemaVar(null);
    }

    if (
        e.target.name() !== WALL_EDGE_NAME &&
        e.target.name() !== WALL_CIRCLE_NAME &&
        e.target.name() !== ROUTE_EDGE_NAME &&
        e.target.name() !== ROUTE_VERTEX_NAME &&
        e.target.name() !== EMBEDDED_OBJECT_NAME
    ) {
        Selector.clear();
    }
};

/**
 * Handler for creating ruler measurement
 * @param {MouseEvent} e
 */
const handleClickWithRulerTool = (e) => {
    if (rulerObject.isRulerExists()) {
        rulerObject.deleteRuler();
        return;
    }
    if (e.target.name() === WALL_CIRCLE_NAME) {
        rulerObject.selectCurrentPointFromVertex(e.target.x(), e.target.y());
    } else if (e.target.name() === WALL_EDGE_NAME) {
        rulerObject.selectCurrentPointFromEdge(e.target.points());
    } else {
        rulerObject.selectCurrentPointFromStage();
    }
};

const handleClickWithZoneTool = () => {
    if (zones.donorZoneVar()) {
        return;
    }
    zones.donorZoneVar(
        zones.getZoneIdUnderPoint(stageVar().getRelativePointerPosition())
    );
};

/**
 * Handler for creating embedded object
 * @param {MouseEvent} e
 */
const handleClickWithEmbeddedObjectTool = async (e) => {
    if (floorGraph.tmpEmbeddedObjectEdgeVar().length < 1) {
        if (
            e.target.name() === WALL_EDGE_NAME ||
            e.target.name() === WALL_CIRCLE_NAME ||
            closestWallPointVar().point
        ) {
            floorGraph.currentWallIdForEmbeddingWallSegment =
                e.target.name() === WALL_EDGE_NAME
                    ? e.target.id()
                    : closestWallPointVar().wallId;

            floorGraph.tmpEmbeddedObjectEdgeVar([
                {
                    ...closestWallPointVar().point,
                },
            ]);
        } else {
            // if click was on free space - then create embedded objects as usually wall segment
            handleClickWithWallTool(e);
            floorGraph.currentWallIdForEmbeddingWallSegment = null;
            floorGraph.tmpEmbeddedObjectEdgeVar([
                {
                    ...currentCanvasPointVar(),
                },
            ]);
        }
    } else if (
        floorGraph.tmpEmbeddedObjectEdgeVar().length === 1 &&
        !floorGraph.currentWallIdForEmbeddingWallSegment
    ) {
        // create embedded object not connected to any wall
        addPointToGraph(e, floorGraph);
    } else if (floorGraph.tmpEmbeddedObjectEdgeVar().length === 2) {
        if (floorGraph.currentWallIdForEmbeddingWallSegment == null) {
            // create embedded object not connected to any wall
            const position = floorGraph.tmpEmbeddedObjectEdgeVar()[1];
            addPointToGraph(e, floorGraph, position);
        } else {
            // creating embedded object
            floorGraph.createEmbeddedSegment({
                type: {
                    ...projectWallTypesVar().find(
                        (wallType) =>
                            wallType.id === rightBarModerator.selectedObject()
                    ),
                },
                isFetched: false,
            });
        }
    }
};

const handleClickWithCommentTool = () => {
    if (modeVar() === CREATING_MODE) {
        alertsModerator.openNewAlert(stageVar().getRelativePointerPosition());
    }
};

/**
 * Handler to add point when click event was caught.
 * @param {MouseEvent} e
 */
const handleClickWithWallTool = (e) => {
    if (
        !floorGraph.isWallCreationAvailableVar() ||
        !floorGraph.currentWallSegmentTypeVar()
    ) {
        return;
    }
    addPointToGraph(e, floorGraph);
};

/**
 * Handler to add route point when click event was caught.
 * @param {MouseEvent} e
 */
const handleClickWithRouteTool = (e) => {
    const currentRoute = routesModerator.getCurrentRoute();
    if (!currentRoute) {
        notifications.setError(
            'Маршрут не найден',
            'Попробуйте поставить новую точку. Если ошибка возникнет снова - попробуйте создать новый маршрут или выбрать уже существующий'
        );
        return;
    }
    const graph = currentRoute.graph;
    if (!graph || !graph.isWallCreationAvailableVar()) {
        return;
    }
    addPointToGraph(e, graph);
};

/**
 * Function that encapsulates logic of adding point to graph
 * @param {MouseEvent} e
 * @param {FloorGraph} graph FloorGraph or its heir
 * @param {{x: number, y: number} | undefined} position position of created point (pass this var to add point to fixed position)
 */
const addPointToGraph = (e, graph, position = undefined) => {
    /**
     *
     * @param {FloorGraph} graph
     * @param {Point} point
     */
    const getClosestWallPointLink = (graph, point) => {
        if (point) {
            const [pointId1, pointId2] = LinkedSegment.splitKey(
                closestWallPointVar().wallId
            );
            const segmentLink = graph.segmentsModerator.getSegment(
                pointId1,
                pointId2
            );

            if (
                arePointsEqual(
                    segmentLink?.firstPointLinkWrapper.getCoordinates(),
                    point
                )
            ) {
                return segmentLink.firstPointLinkWrapper;
            } else if (
                arePointsEqual(
                    segmentLink?.secondPointLinkWrapper.getCoordinates(),
                    point
                )
            ) {
                return segmentLink.secondPointLinkWrapper;
            }
        }
        return null;
    };

    const closestWallPointLink = getClosestWallPointLink(
        graph,
        closestWallPointVar()?.point
    );

    if (e.target instanceof Konva.Circle) {
        let value = graph.segmentsModerator
            .getPointLinkWrapper(e.target.id())
            ?.getInfo();

        // if clicked circle not belong to graph (e.g. temporary point)
        if (!value) {
            value = {
                accessRule: RULE_EDIT,
                adjacentPoints: [],
            };
        }

        graph.addPointToTmpEdge(e.target.id(), {
            ...value,
            coordinates: { ...e.target.position() },
        });
    } else if (closestWallPointLink) {
        graph.addPointToTmpEdge(closestWallPointLink.getId(), {
            ...closestWallPointLink.getInfo(),
        });
    } else {
        if (closestWallPointVar()?.wallId) {
            floorGraph.notToFindIntersectionForEdge.set(
                closestWallPointVar().wallId,
                { ...closestWallPointVar().point }
            );
        }

        let clickPosition = position
            ? position
            : closestWallPointVar()?.point ?? currentCanvasPointVar();

        const tmpEdge = graph.tmpEdgeVar();
        if (tmpEdge.size === 1) {
            // recalculate click position, depending on tmp segment props
            clickPosition = calcCoordinateForSecondPoint(
                [...tmpEdge.values()][0].coordinates,
                clickPosition,
                graph.tmpEdgeProps()
            );
        }

        graph.addPointToTmpEdge(uuid(), {
            coordinates: { ...clickPosition },
            adjacentPoints: [],
        });
    }
};

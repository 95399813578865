import React, { useState, useEffect } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';

import { HorizontalLogo } from '../../shared/ui/HorizontalLogo';
import { SearchBar } from './ui/SearchBar';
import { UserInfo } from './ui/UserInfo';
import { Navigation } from './ui/Navigation';
import { ProjectsGrid } from './ui/ProjectsGrid';

import { MainPageBody, MainPageContainer, MainPageHeader } from './ui/Styles';
import { DisclaimerModal } from './ui/DisclaimerModal';

import { Footer } from '../../shared/ui/Footer';

import { currentProjectVar, currentUserVar, initialProjectConst } from '../../shared/model/cache/Cache';
import { GET_USER_MAIN_MENU_INFO } from '../../shared/graphql/queries/users/GetUser';

import projectImg from '../../shared/assets/images/icons/MainPage/map-placeholder.svg';
import Loader from '../../shared/ui/Loader';
import ErrorMessage from '../../shared/ui/ErrorMessage';
import { mainPageTitle } from '../../shared/model/routes/staticRoutes';

/**
 * Component with temporary projects state for test
 * @returns { MainMenu } Component of page with user projects
 */
export const MainMenu = () => {
    const user = useReactiveVar(currentUserVar);

    const [projects, setProjects] = useState([]);
    const [availableProjects, setAvailableProjects] = useState([]);
    const [isYourProjects, setIsYourProjects] = useState(true);
    const [searchBarVal, setSearchBarVal] = useState('');

    const { loading, error } = useQuery(GET_USER_MAIN_MENU_INFO, {
        variables: {
            selector: {
                id: user.id,
            },
        },
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            currentUserVar({
                ...user,
                ...data.user,
            });
            setProjects([
                ...data.user.projects.map((project) => ({
                    id: project.id,
                    name: project.name,
                    description: project.description,
                    preview: projectImg,
                })),
            ]);
            setAvailableProjects([
                ...data.user.otherProjects.map((project) => ({
                    id: project.id,
                    name: project.name,
                    description: project.description,
                    preview: projectImg,
                })),
            ]);
        },
        onError: (error) => {
            // TODO: in the future add error showing
            console.log(error);
        },
    });

    useEffect(() => {
        document.title = mainPageTitle;
        currentProjectVar(initialProjectConst);
    }, []);

    return (
        <MainPageContainer>
            <DisclaimerModal />
            <MainPageHeader>
                <HorizontalLogo />
                <SearchBar setVal={setSearchBarVal} val={searchBarVal} />
                <UserInfo />
            </MainPageHeader>
            <MainPageBody>
                <Navigation
                    projects={projects}
                    setProjects={setProjects}
                    isYourProjects={isYourProjects}
                    setIsYourProjects={setIsYourProjects}
                />
                {loading && <Loader />}
                {error && <ErrorMessage errorMessage={error.message} />}
                {!loading && !error  && projects && isYourProjects && (
                    <ProjectsGrid
                        projects={projects.filter((project) =>
                            project.name
                                .toLowerCase()
                                .includes(searchBarVal.toLocaleLowerCase())
                        )}
                        setProjects={setProjects}
                        searchBarVal={searchBarVal}
                    />
                )}
                {!loading && !error && availableProjects && !isYourProjects && (
                    <ProjectsGrid
                        projects={availableProjects.filter((project) =>
                            project.name
                                .toLowerCase()
                                .includes(searchBarVal.toLocaleLowerCase())
                        )}
                        setProjects={setAvailableProjects}
                        searchBarVal={searchBarVal}
                    />
                )}
            </MainPageBody>

            <Footer />
        </MainPageContainer>
    );
};

import styled from 'styled-components';
import { getStylesForPosition } from './GetStylesForPosition';
import { HINT_POSITION } from '../../model/Constants';

export interface PositionedContainerProps {
    /** Container position */
    position: HINT_POSITION;

    /** Container horizontal offset */
    offsetX: number;

    /** Container vertical offset */
    offsetY: number;
}

/** Positioned container for hints */
export const PositionedContainer = styled.div<PositionedContainerProps>`
    position: fixed;
    ${(props) =>
        getStylesForPosition(props.position, props.offsetX, props.offsetY)}
    z-index: 999;
`;

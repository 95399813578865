import React from 'react';
import PropTypes from 'prop-types';
import {
    H1OpenSansBold,
    POpenSansRegular,
} from '../../../shared/ui/TextStyles';
import { DisclaimerOkButton, DisclaimerText, LinkTo, DisclaimerWrapper } from './Styles';

/**
 * Component with disclaimer content.
 * Disclaimer to notify user, that he/she uses dev page.
 * @component
 */
export const Disclaimer = ({ onClose = () => {} }) => {
    return (
        <DisclaimerWrapper>
            <H1OpenSansBold>Дисклеймер</H1OpenSansBold>
            <DisclaimerText>
                <POpenSansRegular
                    style={{ fontSize: '20px', lineHeight: '30px' }}
                >
                    Уважаемый пользователь, вы зашли на тестовую сборку сервиса
                    Mapins!
                    <br />
                    Данный ресурс не предназначен для полноценной работы
                    сервиса. <br />
                    <br />
                    Официальный сервис Mapins располагается по адресу:&nbsp;
                    <LinkTo
                        href='https://mapins.cloud'
                        style={{ color: '#023aff' }}
                    >
                        https://mapins.cloud
                    </LinkTo>
                    .
                    <br />
                    <br />
                    Продолжая действия на ресурсе&nbsp;
                    <LinkTo
                        href='https://dev.mapins.cloud'
                        style={{ color: '#000000' }}
                    >
                        https://dev.mapins.cloud
                    </LinkTo>
                    &nbsp;вы принимаете, что мы не несём ответственность за
                    некорректную работу сервиса и возможную утерю данных. Любой
                    представленный здесь функционал не связан с основным
                    сервисом и не обязан быть представленным на основном
                    ресурсе!
                </POpenSansRegular>
            </DisclaimerText>
            <DisclaimerOkButton onClick={onClose}> ОК </DisclaimerOkButton>
        </DisclaimerWrapper>
    );
};

Disclaimer.propTypes = {
    /** Handler for close event */
    onClose: PropTypes.func,
};

import { gql } from '@apollo/client';

/**
 * Query to get map by id
 */
export const GET_ZONE = gql`
    query zone($id: String!) {
        zone(id: $id) {
            color
            id
            name
            global
            accessRules {
                group {
                    id
                    name
                }
                rule
            }
            ... on Zone {
                id
                version
                parentZone {
                    id
                }
                schemaImpls {
                    data
                    id
                    version
                    schema {
                        id
                        schema
                        version
                    }
                }
            }
            floor {
                id
            }
            wall {
                graph {
                    key
                }
            }
        }
    }
`;

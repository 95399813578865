import { gql } from '@apollo/client';
import { ACCESS_RULE_FRAGMENT } from '../accessRules/AccessRuleFragment';

export const ROUTE_SEGMENT_FRAGMENT = gql`
    ${ACCESS_RULE_FRAGMENT}
    fragment RouteSegmentFragment on RouteSegment {
        id
        version
        startPoint {
            id
            coordinate {
                x
                y
            }
            accessRules {
                ...AccessRuleFragment
            }
        }
        endPoint {
            id
            coordinate {
                x
                y
            }
            accessRules {
                ...AccessRuleFragment
            }
        }
        accessRules {
            ...AccessRuleFragment
        }
        route {
            id
        }
    }
`;

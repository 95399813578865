import React, { memo, useState } from 'react';
import { Circle, Line } from 'react-konva';
import PropTypes from 'prop-types';

import {
    SELECTED_COLOR,
    WALL_CIRCLE_COLOR,
    WALL_CIRCLE_FILL,
    WALL_CIRCLE_NAME,
    WALL_CIRCLE_RADIUS,
    WALL_CIRCLE_STROKE_WIDTH,
    WALL_UNAVAILABLE_COLOR,
} from '../../lib/helpers/WallDefaultValues';
import { CREATING_MODE } from '../../../../shared/lib/utils/ModeDefaultValues';
import {
    handlePointDragEnd,
    handlePointEnter,
    handlePointMove,
    handlePointLeave,
    handlePointDragStart,
    handlePointClick,
} from '../../lib/utils/WallPointHandlers';
import { handleScrollSelect } from '../../../Selector/lib/utils/HandleScrollSelect';
import floorGraph from '../../model/FloorGraph';
import { RULE_EDIT } from '../../../AccessGroup/model/AGConstants';
import { modeVar, stageVar } from '../../../../shared/model/cache/Cache';
import { gridSnapper } from '../../lib/helpers';
import { useKeyDown } from '../../../../shared/model/hooks/UseKeyDown';
import { useKeyUp } from '../../../../shared/model/hooks/useKeyUp';

/** Grid stroke color */
const stroke = '#fda21a';

/**
 * Component that represents concrete vertex in canvas (wall corner point)
 * @component
 */
export const Vertex = memo(({ id, visible, isSelected = false, x, y, accessRule }) => {
    const fill =
        accessRule === RULE_EDIT && modeVar() === CREATING_MODE
            ? WALL_CIRCLE_FILL
            : WALL_UNAVAILABLE_COLOR;

    const [isShift, setIsShift] = useState(false);
    const [isDrag, setIsDrag] = useState(false);

    const handleSetDragStart = () => {
        setIsDrag(true);
    };

    const handleSetDragEnd = () => {
        setIsDrag(false);
    };

    const handleShiftDown = () => {
        if (isShift) {
            return;
        }
        setIsShift(true);
    };
    const handleShiftUp = () => {
        setIsShift(false);
        gridSnapper.isSnapToHorizontalLineVar(false);
        gridSnapper.isSnapToVerticalLineVar(false);
    };

    useKeyDown(handleShiftDown, ['ShiftLeft', 'ShiftRight']);
    useKeyUp(handleShiftUp, ['ShiftLeft', 'ShiftRight']);

    function handleDragBound(pos) {
        if (gridSnapper.isSnapToVerticalLineVar()) {
            return {
                x: this.absolutePosition().x,
                y: pos.y,
            };
        } else if (gridSnapper.isSnapToHorizontalLineVar()) {
            return {
                x: pos.x,
                y: this.absolutePosition().y,
            };
        }
        return {
            x: pos.x,
            y: pos.y,
        };
    }

    const formAuxiliaryLines = (horizontal = true) => {
        let firstPoint;
        let secondPoint;

        const width = stageVar().width() / stageVar().scale().x;
        const height = stageVar().height() / stageVar().scale().y;

        if (!floorGraph.dragStartPos) {
            return [];
        }

        if (horizontal) {
            firstPoint = {
                x: floorGraph.dragStartPos.x - width,
                y: floorGraph.dragStartPos.y,
            };
            secondPoint = {
                x: floorGraph.dragStartPos.x + width,
                y: floorGraph.dragStartPos.y,
            };
        } else {
            firstPoint = {
                x: floorGraph.dragStartPos.x,
                y: floorGraph.dragStartPos.y - height,
            };
            secondPoint = {
                x: floorGraph.dragStartPos.x,
                y: floorGraph.dragStartPos.y + height,
            };
        }

        return [...Object.values(firstPoint), ...Object.values(secondPoint)];
    };

    return (
        <>
            {isShift && isDrag && (
                <>
                    <Line
                        stroke={stroke}
                        strokeWidth={1}
                        points={formAuxiliaryLines(true)}
                    />
                    <Line
                        stroke={stroke}
                        strokeWidth={1}
                        points={formAuxiliaryLines(false)}
                    />
                </>
            )}
            <Circle
                listening={accessRule === RULE_EDIT}
                id={id}
                x={x}
                y={y}
                name={WALL_CIRCLE_NAME}
                radius={WALL_CIRCLE_RADIUS}
                fill={fill}
                pointFill={fill} // for handlePointLeave
                stroke={isSelected ? SELECTED_COLOR : WALL_CIRCLE_COLOR}
                strokeWidth={WALL_CIRCLE_STROKE_WIDTH}
                visible={visible}
                draggable={CREATING_MODE && accessRule === RULE_EDIT}
                onMouseEnter={handlePointEnter}
                onMouseLeave={handlePointLeave}
                onDragStart={(e) =>
                    handlePointDragStart(e, floorGraph, handleSetDragStart)
                }
                onDragEnd={(e) =>
                    handlePointDragEnd(e, floorGraph, handleSetDragEnd)
                }
                onDragMove={(e) => handlePointMove(e, floorGraph, isShift)}
                onMouseDown={(e) => {
                    floorGraph.setDragStartPos(e.target.position());
                }}
                dragBoundFunc={handleDragBound}
                onClick={(e) => handlePointClick(e, id, floorGraph)}
                shadowForStrokeEnabled={false}
                perfectDrawEnabled={false}
                transformsEnabled={'position'}
                onWheel={handleScrollSelect}
                accessRule={accessRule}
            />
        </>
    );
});

Vertex.propTypes = {
    id: PropTypes.string,
    visible: PropTypes.bool,
    isSelected: PropTypes.bool,
    x: PropTypes.number,
    y: PropTypes.number,
    accessRule: PropTypes.string,
};

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useReactiveVar } from '@apollo/client';
import handImg from '../../../../shared/assets/images/tools/ConstructorPage/hand.svg';
import cursorImg from '../../../../shared/assets/images/tools/ConstructorPage/cursor.svg';
import { currentDragTypeVar } from '../../../../shared/model/cache/Cache';
import { DragTools } from '../../TopBar/TopBarEntires/Tools/Constants/Tools';
import cursorModerator from '../../../../shared/lib/utils/common/CursorModerator';
import { DEFAULT_DRAG_TOOL } from '../../../../shared/lib/LocalStorageConstants';

const Container = styled.div`
    height: fit-content;
    margin-left: 30px;
    display: flex;
    justify-content: center;
    align-items: stretch;
    border-radius: 16.286px;
    border: 0.543px solid #e7e7e7;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background: white;

    z-index: 10;
`;

const SwitchButton = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;

    &.selected {
        background-color: #e1e8ff;
    }
`;

const LeftButton = styled(SwitchButton)`
    border-radius: 16.286px 0 0 16.286px;
    border-right: 0.543px solid #e7e7e7;
`;

const RightButton = styled(SwitchButton)`
    border-radius: 0 16.286px 16.286px 0;
`;

const Icon = styled.img`
    height: 31.4px;
    width: auto;
    padding: 2px 4px 2px 5px;
`;

const PointerIcon = styled(Icon)`
    padding-top: 0;
`;

/** Function to set hand drag tool */
const setHandDragTool = () => {
    currentDragTypeVar(DragTools.hand);
    localStorage.setItem(DEFAULT_DRAG_TOOL, DragTools.hand);
    cursorModerator.autoCalcAndChangeDefaultCursor();
};

/** Function to set pointer drag tool */
export const setPointerDragTool = () => {
    currentDragTypeVar(DragTools.cursor);
    localStorage.setItem(DEFAULT_DRAG_TOOL, DragTools.cursor);
    cursorModerator.autoCalcAndChangeDefaultCursor();
};

/**
 * Switcher for drag tool: hand or cursor
 * @component
 */
export const DragToolSwitcher = () => {
    const tool = useReactiveVar(currentDragTypeVar);

    useEffect(() => {
        // load drag type from local storage
        currentDragTypeVar(
            localStorage.getItem(DEFAULT_DRAG_TOOL) || DragTools.cursor
        );
    }, []);

    return (
        <Container>
            <LeftButton
                className={tool === DragTools.hand ? 'selected' : ''}
                onClick={setHandDragTool}
            >
                <Icon src={handImg} alt='hand' />
            </LeftButton>
            <RightButton
                className={tool === DragTools.cursor ? 'selected' : ''}
                onClick={setPointerDragTool}
            >
                <PointerIcon src={cursorImg} alt='cursor' />
            </RightButton>
        </Container>
    );
};

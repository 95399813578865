import { client } from '../../../shared/graphql/ApolloClient';
import { GET_PROJECT } from '../../../shared/graphql/queries/projects/GetProject';
import { currentUserVar } from '../../../shared/model/cache/Cache';

export const getProject = async (projectId) => {
    return client.query({
        query: GET_PROJECT,
        variables: {
            id: projectId,
            userId: currentUserVar().id,
        },
    });
};

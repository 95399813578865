import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router';
import PropTypes from 'prop-types';

import { isSendEmail } from '../cache/Cache';
import { emailSendTitle } from '../routes/staticRoutes';

/**
 * Require Send Email component
 * @param { Object } children content inside RequireSendEmail tag
 * @param { String } to path to navigate, if this page should not be shown without form submit
 * @returns Navigate to {to} if this page not available or children
 */
export const RequireSendEmail = ({ children, to }) => {
    const location = useLocation();

    useEffect(() => {
        document.title = emailSendTitle;
    }, []);

    if (!isSendEmail()) {
        return <Navigate to={to} state={{ from: location }} />;
    }

    return children;
};

RequireSendEmail.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.arrayOf(PropTypes.object),
    ]),
    to: PropTypes.string,
};

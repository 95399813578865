/** Default hint showtime */
export const HINT_DEFAULT_SHOW_TIME: number = 5000;

/** Hint text and title alignment */
export enum HINT_TEXT_ALIGNMENT {
    CENTER,
    RIGHT,
    LEFT,
}

/** Hint position relative to the screen */
export enum HINT_POSITION {
    topMiddle,
    topRight,
    topLeft,
    bottomMiddle,
    bottomRight,
    bottomLeft,
}

/** Some colors for hints */
export enum HINT_COLOR {
    BLACK = '#000000',
    WHITE = '#ffffff',
    INFO = '#023aff',
    SUCCESS = '#00940f',
    ERROR = '#ff0000',
}

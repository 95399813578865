import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import openIcon from '../../assets/images/icons/open-in-new-window.svg';
import { POpenSansRegular } from '../../ui/TextStyles';

const Container = styled.div`
    min-width: 121px;
    display: flex;
    align-items: center;
    padding: 7px 0;

    background-color: #e1e8ff;
    border-radius: 5px;

    cursor: pointer;

    &:hover {
        background-color: #b7c7fe;
    }
`;

const Title = styled(POpenSansRegular)`
    flex-grow: 1;
    font-size: 13px;
    line-height: 11.7px;
    margin-left: 14px;
    margin-right: 12px;
    padding: 2px 0;
    text-align: center;

    max-width: 160px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const Icon = styled.img`
    width: 13px;
    height: auto;
    margin-right: 9px;
`;

/**
 * Form component - inline button.
 * Use it when you need to show on button changeable info.
 * For example this type of button convenient to use for ItemSelector call
 */
export const InlineButton = ({ icon = openIcon, onClick, children }) => {
    return (
        <Container onClick={onClick}>
            <Title>{children}</Title>
            <Icon src={icon} alt='btn icon' />
        </Container>
    );
};

InlineButton.propTypes = {
    /** Icon src */
    icon: PropTypes.string,

    /** Click handler */
    onClick: PropTypes.func,

    /** React children */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

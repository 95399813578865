import constraintGroupsModerator from '../model/ConstraintGroupsModerator';
import { constraintGroupDataFromServerToCGData } from '../model/ConstraintGroupAdapter';
import { OPERATION } from '../../Subscriptions/api/SubscriptionsModerator';

/**
 * @typedef {import('../model/ConstraintGroupAdapter').ConstraintGroupDataFromServer} ConstraintGroupDataFromServer
 */

/**
 * Function to handle constraint group data received from subscription
 * @param {string} operation operation type (create, delete, update)
 * @param {ConstraintGroupDataFromServer} serverData constraint group data from server
 */
export const handleConstraintGroupSubscription = (operation, serverData) => {
    const groupData = constraintGroupDataFromServerToCGData(serverData);
    if (operation === OPERATION.CREATE) {
        constraintGroupsModerator.addConstraintGroup(groupData.id, groupData);
    } else if (operation === OPERATION.DELETE) {
        constraintGroupsModerator.removeConstrGrFromManager(groupData.id);
    } else if (operation === OPERATION.UPDATE) {
        const group = constraintGroupsModerator.getGroup(groupData.id);
        group?.updateProperties(groupData);
    }
};

import { useReactiveVar } from '@apollo/client';
import React from 'react';

import { GlobalZone } from './GlobalZone';

import zones from '../model/Zones';
import { Zone } from './Zone';

/**
 * Component that represent all the zones on canvas
 *
 * @component
 * @example
 * <CanvasZones />
 */
export const CanvasZones = () => {
    const zonesForRender = useReactiveVar(zones.zonesVar);
    return Array.from(zonesForRender).map(([_, zone]) => {
        return zone.props.global ? (
            <GlobalZone globalZone={zone} key={zone.props.id} />
        ) : (
            !zone.props.global && (
                <Zone
                    key={zone.props.id}
                    zoneId={zone.props.id}
                    zoneName={zone.props.name}
                    borders={zone.props.borders}
                    color={zone.props.color}
                    isStub={zone.props.isStub}
                    accessRule={zone.props.accessRule}
                />
            )
        );
    });
};

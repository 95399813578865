import React, { useRef, useEffect, useLayoutEffect, useCallback } from 'react';
import { Image, Transformer } from 'react-konva';
import useImage from 'use-image';
import PropTypes from 'prop-types';

import { closeContextMenu } from '../../ContextMenu/model/CloseContextMenu';
import {
    currentMapObjectVar,
    currentToolVar,
    modeVar,
    stageVar,
} from '../../../shared/model/cache/Cache';

import { Tools } from '../../../pages/Constructor/TopBar/TopBarEntires/Tools/Constants/Tools';
import commandManager from '../../CommandManager/model/CommandManager';
import { dragMapObjectCommand } from '../../CommandManager/lib/commands/mapObject/DragMapObject';
import { openMapObjectsContextMenu } from '../../ContextMenu/model/OpenMapObjectContextMenu';
import { transformMapObjectCommand } from '../../CommandManager/lib/commands/mapObject/TransformMapObject';

import cursorModerator from '../../../shared/lib/utils/common/CursorModerator';
import {
    isRectIntersectsAnyWall,
    transformingShapeToBoxLineStrings,
    konvaShapeToBoxLineStrings,
} from '../lib/IsMapObjectIntersectsAnyWall';
import zones from '../../Zone/model/Zones';
import { CREATING_MODE } from '../../../shared/lib/utils/ModeDefaultValues';
import scalesConformer from '../../ScalesConformer/model/ScalesConformer';
import { useReactiveVar } from '@apollo/client';
import { RULE_EDIT } from '../../AccessGroup/model/AGConstants';
import {
    activateMapinsForm,
    objectWithSchemaTypes,
} from '../../../shared/model/mapinsForm/activateMapinsForm';
import { findLeftUpperCorner } from '../lib/MapObjectCentering';
import mapObjects from '../model/MapObjects';
import rightBarModerator from '../../../pages/Constructor/RightBar/model/RightBarModerator';
import { RightBarOperation } from '../../../pages/Constructor/RightBar/model/RightBarOperations';

/**
 * Component represents image mapObject
 *
 * @component
 * @example
 * <URLImage mapObject={CURTAIN_MAP_OBJECT}  />
 */
export const URLImage = ({ mapObject, isSelected, fill }) => {
    // TODO: Maybe implement default missing image picture
    // if (!mapObject.pictureLink) {
    //     return <></>;
    // }
    const imageSrc = 'data:image/svg+xml;utf8,' + mapObject.image.src;
    const mode = useReactiveVar(modeVar);

    /**
     * Map objects image
     * @type {HTMLImageElement}
     */
    const [image] = useImage(imageSrc);

    const shapeRef = useRef(null);
    const transformerRef = useRef(null);

    /** Saves shape attributes. Use to restrict mapObject transform */
    const shapeAttrs = useRef();

    const handleDragEnd = () => {
        handleMapObjectsDragEnd(mapObject, shapeRef, shapeAttrs);
    };

    const handleDragMove = () => {
        if (
            isRectIntersectsAnyWall(
                konvaShapeToBoxLineStrings(shapeRef.current)
            )
        ) {
            cursorModerator.setNotAllowed();
        } else {
            cursorModerator.setGrabbing();
            shapeAttrs.current = { ...shapeRef.current.getAttrs() };
        }
    };

    /**
     * Set selected mapObject as transformer node
     */
    useEffect(() => {
        if (transformerRef.current === null) {
            closeContextMenu();
            return;
        }

        if (isSelected) {
            transformerRef.current.nodes([shapeRef.current]);
            transformerRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    /**
     * If drag zone with mapObjects, save new shape position
     */
    useEffect(() => {
        shapeAttrs.current = { ...shapeRef.current.getAttrs() };
    }, [mapObject.position.x, mapObject.position.y]);

    useLayoutEffect(() => {
        shapeRef.current.cache();
    }, [mapObject, image, isSelected, fill]);

    /**
     * Set context menu as opened
     */
    const openContextMenu = () => {
        if (stageVar()) {
            openMapObjectsContextMenu(
                mapObject,
                stageVar().getRelativePointerPosition()
            );
        }
    };

    /**
     * Save new mapObject parameters (size, angle) after transformation
     */
    const saveSize = () => {
        commandManager.do(transformMapObjectCommand(shapeRef, mapObject));
    };

    const handleDragStart = () => {
        cursorModerator.setGrabbing();
        closeContextMenu();
    };

    /**
     * Handler for click on map object
     * @param {MouseEvent} e
     */
    const handleClick = (e) => {
        if (e.evt.button === 0) {
            if (modeVar() === CREATING_MODE) {
                if (currentToolVar() === Tools.addTeleport) {
                    // select teleport destination
                    if (
                        mapObject.teleport &&
                        mapObject.id !==
                            mapObjects.teleportCreationVar().startMapObject.id
                    ) {
                        mapObjects.setTeleportDestination(mapObject.id);
                    }
                } else if (
                    rightBarModerator.operation()?.operationName ===
                    RightBarOperation.TELEPORT_SETTINGS
                ) {
                    // select teleport start
                    mapObjects.setTeleportStart(mapObject.id);
                } else {
                    // select map object
                    currentMapObjectVar(mapObject.id);
                }
            } else {
                activateMapinsForm(mapObject, objectWithSchemaTypes.mapObject);
            }
        }
    };

    const handleMouseOver = useCallback(() => {
        if (currentToolVar() === Tools.addTeleport) {
            if (mapObject.teleport) {
                cursorModerator.setPointer();
            } else {
                cursorModerator.setNotAllowed();
            }
        } else if (mode === CREATING_MODE) {
            cursorModerator.setGrab();
        } else {
            cursorModerator.setPointer();
        }
        // eslint-disable-next-line
    }, [mode]);

    const handleMouseLeave = () => {
        cursorModerator.setDefault();
    };

    const boundBoxFunc = (oldBox, newBox) => {
        if (
            !isRectIntersectsAnyWall(transformingShapeToBoxLineStrings(newBox))
        ) {
            return newBox;
        }
        return oldBox;
    };

    const imageLeftUpperCornerPos = findLeftUpperCorner(
        mapObject.position,
        mapObject.width,
        mapObject.height,
        mapObject.angle
    );

    return (
        <>
            <Image
                id={mapObject.id}
                image={image}
                ref={shapeRef}
                x={imageLeftUpperCornerPos.x}
                y={imageLeftUpperCornerPos.y}
                listening={mapObject.accessRule === RULE_EDIT}
                draggable={
                    currentToolVar() === Tools.none && mode === CREATING_MODE
                }
                height={scalesConformer.toPixels(mapObject.height)}
                width={scalesConformer.toPixels(mapObject.width)}
                rotation={mapObject.angle}
                key={mapObject.id}
                fill={fill}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDragMove={handleDragMove}
                onContextMenu={openContextMenu}
                onClick={handleClick}
                onTransformEnd={saveSize}
                onMouseOver={handleMouseOver}
                onMouseLeave={handleMouseLeave}
            />

            {isSelected && (
                <Transformer
                    ref={transformerRef}
                    rotationSnaps={[0, 45, 90, 135, 180, 225, 270, 315]}
                    boundBoxFunc={boundBoxFunc}
                />
            )}
        </>
    );
};

URLImage.propTypes = {
    /**
     * Canvas item
     */
    mapObject: PropTypes.object,
    /**
     * Is this mapObject selected now for transformation
     */
    isSelected: PropTypes.bool,
    /**
     * Fill color
     */
    fill: PropTypes.string,
};

const handleMapObjectsDragEnd = (mapObject, shapeRef, shapeAttrs) => {
    const zoneId = zones.getZoneIdUnderPoint(
        stageVar().getRelativePointerPosition()
    );
    if (
        !isRectIntersectsAnyWall(konvaShapeToBoxLineStrings(shapeRef.current))
    ) {
        cursorModerator.setGrab();
    } else {
        shapeRef.current.setAttrs({ ...shapeAttrs.current });
        cursorModerator.setDefault();
    }
    commandManager.do(
        dragMapObjectCommand(
            mapObject,
            shapeRef.current.getAbsolutePosition(stageVar()),
            zoneId || mapObject.zoneId
        )
    );
};

import { viewportToStageCoordinates } from '../../../../features/Zone/lib/utils/ViewportToStageCoordinates';
import { isRectIntersectsAnyWall } from '../../../../features/MapObject/lib/IsMapObjectIntersectsAnyWall';
import { defaultItemSize } from '../../../../features/CommandManager/lib/commands/mapObject/CreateMapObject';
/**
 * Handler to drop item only in zone
 * When you want to create item and drop it on canvas it validates that you can drop item only on zone
 * @param {object} e Drop Event
 * @param {number} scale
 */
export const handleDragOver = (e, scale) => {
    if (
        !isRectIntersectsAnyWall(
            pointerRect(
                viewportToStageCoordinates({ x: e.pageX, y: e.pageY }, scale)
            )
        )
    ) {
        e.preventDefault();
    }
};

const itemHalfSize = defaultItemSize / 2;

/**
 * Function that returns rect that surrounds pointer on default item size
 * @param {{x: Number, y: Number}} pointerPosition
 * @returns {[{x: Number, y: Number}, {x: Number, y: Number}][]}
 */
const pointerRect = (pointerPosition) => {
    return [
        [
            {
                x: pointerPosition.x - itemHalfSize,
                y: pointerPosition.y - itemHalfSize,
            },
            {
                x: pointerPosition.x + itemHalfSize,
                y: pointerPosition.y - itemHalfSize,
            },
        ],
        [
            {
                x: pointerPosition.x + itemHalfSize,
                y: pointerPosition.y - itemHalfSize,
            },
            {
                x: pointerPosition.x + itemHalfSize,
                y: pointerPosition.y + itemHalfSize,
            },
        ],
        [
            {
                x: pointerPosition.x + itemHalfSize,
                y: pointerPosition.y + itemHalfSize,
            },
            {
                x: pointerPosition.x - itemHalfSize,
                y: pointerPosition.y + itemHalfSize,
            },
        ],
        [
            {
                x: pointerPosition.x - itemHalfSize,
                y: pointerPosition.y + itemHalfSize,
            },
            {
                x: pointerPosition.x - itemHalfSize,
                y: pointerPosition.y - itemHalfSize,
            },
        ],
    ];
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { Arrow } from 'react-konva';
import {
    ROUTE_LINE_CAP,
    ROUTE_LINE_JOIN,
    ROUTE_EDGE_NAME,
    ROUTE_LINE_WIDTH,
    ROUTE_SELECTED_COLOR,
} from '../lib/RouteDefaultValues';
import {
    handleClickEdge,
    handleMouseEnter,
    handleMouseLeave,
} from '../../Wall/lib/utils/WallHandlers';
import selector from '../../Selector/model/Selector';
import { RouteGraph } from '../model/RouteGraph';
import { currentToolVar } from '../../../shared/model/cache/Cache';
import { Tools } from '../../../pages/Constructor/TopBar/TopBarEntires/Tools/Constants/Tools';
import { TransparentDraggableSegment } from '../../Wall/ui/WallSegments/TransparentDraggableSegment';
import { RULE_EDIT } from '../../AccessGroup/model/AGConstants';
import { CURSORS } from '../../../shared/lib/utils/common/CursorModerator';

/**
 * Component that represent route edge (segment) on canvas
 * @component
 */
export const Edge = ({
    edgeId,
    graph,
    points,
    color,
    isVirtual,
    onContextMenu,
    accessRule,
    ...props
}) => {
    const [tmpPoints, setTmpPoints] = useState([]);

    useReactiveVar(selector.selectedEdgesVar);
    useReactiveVar(selector.selectedVerticesVar);

    const hovered = useReactiveVar(selector.isHoveredVar);

    useEffect(() => {
        setTmpPoints([...points]);
        return () => setTmpPoints([]);
    }, [points]);

    return (
        <>
            <Arrow
                id={edgeId}
                name={ROUTE_EDGE_NAME}
                graph={graph}
                points={points}
                stroke={
                    selector.isEdgeSelected(edgeId)
                        ? ROUTE_SELECTED_COLOR
                        : color
                }
                strokeWidth={ROUTE_LINE_WIDTH}
                lineJoin={ROUTE_LINE_JOIN}
                lineCap={ROUTE_LINE_CAP}
                pointerWidth={16}
                onClick={(e) => handleClickEdge(e, edgeId, graph)}
                onMouseEnter={(e) => {
                    handleMouseEnter(
                        accessRule === RULE_EDIT
                            ? CURSORS.POINTER
                            : CURSORS.NOT_ALLOWED
                    );
                    selector.isHoveredVar(edgeId);
                }}
                onMouseLeave={handleMouseLeave}
                dashEnabled={isVirtual}
                fillEnabled={false}
                perfectDrawEnabled={false}
                shadowForStrokeEnabled={false}
                // onWheel={handleScrollSelect}
                transformsEnabled={'position'}
                tension={10}
                onContextMenu={onContextMenu}
                accessRule={accessRule}
                {...props}
            />

            {/* It's a transparent line for handling dragging */}
            {currentToolVar() === Tools.none &&
                selector.isEdgeSelected(edgeId) &&
                hovered === edgeId && (
                    <TransparentDraggableSegment
                        wallId={edgeId}
                        tmpPoints={tmpPoints}
                        strokeWidth={ROUTE_LINE_WIDTH}
                        isVirtual={isVirtual}
                        clickEdge={(e) => handleClickEdge(e, edgeId, graph)}
                        onContextMenu={onContextMenu}
                        accessRule={accessRule}
                    />
                )}
        </>
    );
};

Edge.propTypes = {
    /** Edge (segment) UUID */
    edgeId: PropTypes.string,

    /** RouteGraph */
    graph: PropTypes.instanceOf(RouteGraph),

    /** Array of points */
    lineString: PropTypes.arrayOf(
        PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
            uniqueId: PropTypes.string,
        })
    ),

    /** Route color */
    color: PropTypes.string,

    /** Visibility for anchor points in tmp and created walls */
    visible: PropTypes.bool,

    /** Callback function for opening context menu */
    onContextMenu: PropTypes.func,
};

import React, { useState } from 'react';
import styled from 'styled-components';
import {
    Button,
    SecondaryButton,
} from '../../../../../../shared/ui/RolesSettingsComponents/Styles';
import plusIcon from '../../../../../../shared/assets/images/icons/plus-black.svg';
import { currentToolVar } from '../../../../../../shared/model/cache/Cache';
import { Tools } from '../../../../TopBar/TopBarEntires/Tools/Constants/Tools';
import mapObjects from '../../../../../../features/MapObject/model/MapObjects';
import { POpenSansRegular } from '../../../../../../shared/ui/TextStyles';
import { TeleportItem } from './TeleportItem';
import { DivWithMargin, HorizontalLine } from './Styles';
import mapObjectsAPI from '../../../../../../features/MapObject/api/MapObjectsAPI';
import { PreloaderWithText } from '../../../../../../shared/ui/PreloaderWithText';

const AddButton = styled(SecondaryButton)`
    margin: 10px auto 0;

    & img {
        width: 12px;
        height: auto;
    }
`;

const ButtonBar = styled.div`
    display: flex;
    gap: 5px;
    margin-top: 10px;
`;

/** Component with logic to add new teleport to map object */
export const AddTeleport = ({
    startMapObject,
    destinationMapObject,
    destinationFloorName,
}) => {
    const [isButton, setIsButton] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const handleAddButtonClick = () => {
        setIsButton(false);
        currentToolVar(Tools.addTeleport);
    };

    const handleClear = () => {
        setIsButton(false);
        mapObjects.clearTeleportDestination();
    };

    const handleCancel = () => {
        setIsButton(true);
        currentToolVar(Tools.none);
    };

    const handleConfirm = async () => {
        setIsLoading(true);
        const result = await mapObjectsAPI.update({
            id: startMapObject.id,
            version: startMapObject.version,
            teleports: [destinationMapObject.id],
        });
        setIsLoading(false);
        if (result) {
            setIsButton(true);
            mapObjects.clearTeleportDestination();
            currentToolVar(Tools.none);
        }
    };

    return (
        <>
            {isButton ? (
                <AddButton onClick={handleAddButtonClick}>
                    <img src={plusIcon} alt='+' />
                    Добавить
                </AddButton>
            ) : (
                <>
                    <HorizontalLine />
                    <POpenSansRegular
                        style={{
                            fontWeight: 'bolder',
                            margin: '15px auto 5px',
                            textAlign: 'center',
                        }}
                    >
                        Добавление места
                    </POpenSansRegular>
                    {destinationMapObject ? (
                        <>
                            <TeleportItem
                                mapObject={destinationMapObject}
                                floorName={destinationFloorName}
                            />
                            {isLoading ? (
                                <PreloaderWithText
                                    loaderSize={25}
                                    text='Загрузка'
                                />
                            ) : (
                                <ButtonBar>
                                    <SecondaryButton onClick={handleClear}>
                                        Очистить
                                    </SecondaryButton>
                                    <Button onClick={handleConfirm}>
                                        Подтвердить
                                    </Button>
                                </ButtonBar>
                            )}
                        </>
                    ) : (
                        <DivWithMargin>
                            <POpenSansRegular>
                                Выберите на карте элемент места назначения. Вы
                                можете перемещаться между этажами
                            </POpenSansRegular>
                            <AddButton onClick={handleCancel}>Отмена</AddButton>
                        </DivWithMargin>
                    )}
                </>
            )}
        </>
    );
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { POpenSansRegular } from '../../../shared/ui/TextStyles';
import plusIcon from '../../../shared/assets/images/icons/ConstructorPage/GraphicSchema/plus.svg';
import { ComboBox } from '../../../shared/ui/FormComponentsSmall/ComboBox';
import { InputString } from '../../../shared/ui/FormComponentsSmall/InputString';
import notifications from '../../HintSystem/model/Notifications';

const PlusIcon = styled.img`
    width: 18px;
    height: auto;
    cursor: pointer;
`;

const NewPropertyContainer = styled.div`
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
`;

const BorderedContainer = styled.div`
    flex-grow: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 15px;
    padding: 7px 15px 6px;
    border: 0.5px solid #9e9e9e;
    border-radius: 7px;
`;

const KeyValueGroup = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;

    & > input {
        max-width: 127px;
    }
`;

/** Type of fields */
const fieldTypes = ['Строка', 'Число', 'Дата', 'Время'];

/**
 * Component represents form for adding new property with type selector
 * @component
 * @example
 * <NewExtendedProperty
 *     handleAdd={(propertyName, propertyType) => {
 *         console.log("New property " + propertyName + " (" + propertyType + ")");
 *     }}
 * />
 */
export const NewExtendedProperty = ({ handleAdd }) => {
    const [newPropertyValue, setNewPropertyValue] = useState('');
    const [selectedFieldType, setSelectedFieldType] = useState(fieldTypes[0]);

    /**
     * Handler for combo box select
     * @param {string} item - selected item
     */
    const handleSelectFieldType = (item) => {
        setSelectedFieldType(item);
    };

    /**
     * @param {React.ChangeEvent<HTMLInputElement>} e - event
     */
    const handleChangeNewPropertyName = (e) => {
        // TODO add validation for property name
        setNewPropertyValue(e.target.value);
    }

    const handleAddClick = () => {
        if(newPropertyValue.trim() !== '') {
            handleAdd(newPropertyValue, selectedFieldType);
        } else {
            notifications.setError('Внимание', 'Введите название поля');
        }
    };

    return (
        <NewPropertyContainer>
            <BorderedContainer>
                <KeyValueGroup>
                    <POpenSansRegular>Поле:</POpenSansRegular>
                    <InputString
                        value={newPropertyValue}
                        placeholder={"Название"}
                        onChange={handleChangeNewPropertyName}
                    />
                </KeyValueGroup>
                <KeyValueGroup>
                    <POpenSansRegular>Тип:</POpenSansRegular>
                    <ComboBox
                        items={fieldTypes}
                        selectedElem={selectedFieldType}
                        handleSelect={handleSelectFieldType}
                    />
                </KeyValueGroup>
            </BorderedContainer>
            <PlusIcon
                src={plusIcon}
                alt='add'
                onClick={handleAddClick}
            />
        </NewPropertyContainer>
    );
};

NewExtendedProperty.propTypes = {
    /** Handler for add button */
    handleAdd: PropTypes.func,
};

import React, { useEffect } from 'react';

import { FormContainer } from '../../shared/ui/FormContainer';
import { ExtraLinks } from '../../shared/ui/ExtraLinks';

import { AuthorizationForm } from './ui/AuthorizationForm';

import {
    loginPageTitle,
    registrationPage,
    requestRecoverPage,
} from '../../shared/model/routes/staticRoutes';

/**
 * Authorization component
 * @returns { AuthorizationContainer } Centralized authorization component
 */
export const Authorization = () => {
    useEffect(() => {
        document.title = loginPageTitle;
    }, []);

    return (
        <FormContainer title='Вход' style={{ margin: '37px 0 45px' }}>
            {/* Form for authorization */}
            <AuthorizationForm />

            {/* Extra links: registration and password recovery  */}
            <ExtraLinks
                items={[
                    {
                        text: 'Еще нет аккаунта?',
                        name: 'Регистрация',
                        to: registrationPage,
                    },
                    {
                        text: 'Забыли пароль?',
                        name: 'Восстановить',
                        to: requestRecoverPage,
                    },
                ]}
            />
        </FormContainer>
    );
};

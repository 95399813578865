import MapObjects from '../../../../MapObject/model/MapObjects';

/**
 * Command to delete map object
 *
 * @param {Object} currentMapObject canvas map object on which context menu was opened
 */
export const deleteMapObjectCommand = (currentMapObject) => {
    return {
        execute() {
            MapObjects.deleteMapObject(currentMapObject.id);
        },

        undo() {
            MapObjects.createMapObject(currentMapObject);
        },
    };
};

import cursorModerator from '../../../../shared/lib/utils/common/CursorModerator';
import { stagePositionVar } from '../../../../shared/model/cache/Cache';
import { updateStageSize } from './UpdateStageSize';

/**
 * Function to drag through out the canvas
 */
export const handleDragEnd = (e) => {
    stagePositionVar(e.currentTarget.position());
    updateStageSize();
    cursorModerator.setDefault();
};

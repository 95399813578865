import { useReactiveVar } from '@apollo/client';
import React, { useEffect } from 'react';

import {
    stageScaleVar,
    stageBordersSizeVar,
} from '../../../shared/model/cache/Cache';
import { Zone } from './Zone';

/**
 * @typedef {import('../model/GlobalZone').GlobalZone}  GlobalZoneClass
 */

/**
 * Component that represent global zone
 *
 * @component
 * @type {React.FC<{globalZone: GlobalZoneClass}>}
 */
export const GlobalZone = ({ globalZone }) => {
    const stageScale = useReactiveVar(stageScaleVar);
    const stageSize = useReactiveVar(stageBordersSizeVar);
    useEffect(() => {
        globalZone.expand();
    }, [globalZone, stageScale, stageSize]);
    return (
        <Zone
            zoneId={globalZone.props.id}
            zoneName={globalZone.props.name}
            borders={globalZone.props.borders}
            color={globalZone.props.color}
            isStub={globalZone.props.isStub}
            accessRule={globalZone.props.accessRule}
        />
    );
};

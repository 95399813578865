import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Stage, Layer } from 'react-konva';
import { useReactiveVar } from '@apollo/client';

import { CanvasGrid } from './CanvasGrid';

import {
    currentToolVar,
    stageVar,
    contextMenuVar,
    isGridEnabledVar,
    stageScaleVar,
    stagePositionVar,
    layerVar,
    modeVar,
    graphicSchemaVar,
    // gridVar,
} from '../../../shared/model/cache/Cache';

import { MapObjects } from '../../../features/MapObject/ui/MapObjects';
import {
    Constraints,
    Tools,
} from '../../../pages/Constructor/TopBar/TopBarEntires/Tools/Constants/Tools';

import {
    CANVAS_NAME,
    ENTER_KEY,
    ESC_KEY,
    SPACE_KEY,
} from '../lib/CanvasDefaultValues';

import { CanvasZones } from '../../../features/Zone/ui/CanvasZones';
import { CanvasContextMenu } from '../../../features/ContextMenu/ui/styles/CanvasContextMenu';
import { Routes } from '../../../features/Route/ui/Routes';
import { GraphicSchema } from '../../../features/GraphicSchema/GraphicSchema';

import { CanvasWallSegments } from '../../../features/Wall/ui/WallSegments/CanvasWallSegments';
import { TemporaryWallSegment } from '../../../features/Wall/ui/WallSegments/TemporaryWallSegment';
/**
 * import canvas handlers
 */
import { handleMouseScroll } from '../lib/utils/HandleMouseScroll';
import { handleStageClick } from '../lib/utils/HandleStageClick';
import { handleDragStart } from '../lib/utils/HandleDragStart';
import { handleDragEnd } from '../lib/utils/HandleDragEnd';
import { handleCancel } from '../lib/utils/HandleCancel';
import { handleMouseUp } from '../lib/utils/HandleMouseUp';
import { handleMouseDown } from '../lib/utils/HandleMouseDown';
import { handleItemDrop } from '../lib/utils/HandleItemDrop';
import { handleStageMouseMove } from '../lib/utils/HandleStageMouseMove';
import { handleDragOver } from '../lib/utils/HandleDragOver';
import { handleSpaceDown } from '../lib/utils/KeyboardHandlers/HandleSpaceDown';

import { WallPointAttractionArea } from '../../../features/Wall/ui/WallPointAttractionArea';
import { CanvasVertices } from '../../../features/Wall/ui/Vertices/CanvasVertices';

import floorGraph from '../../../features/Wall/model/FloorGraph';

import { Ruler } from '../../../features/Ruler/ui/Ruler';

import selector from '../../../features/Selector/model/Selector';
import { RectSelector } from '../../../features/Selector/ui/RectSelector';

import { CREATING_MODE, VIEW_MODE } from '../../../shared/lib/utils/ModeDefaultValues';
import { handleZoneCreation } from '../lib/utils/HandleZoneCreation';

import { useKeyDown } from '../../../shared/model/hooks/UseKeyDown';
import { ExtendedAlert } from '../../../features/Alert/ui/ExtendedAlert';
import { CollapsedAlerts } from '../../../features/Alert/ui/CollapsedAlerts';
import alertsModerator from '../../../features/Alert/model/AlertsModerator';
import cursorModerator, {
    CURSORS,
} from '../../../shared/lib/utils/common/CursorModerator';
import { useKeyUp } from '../../../shared/model/hooks/useKeyUp';

/**
 * Component that represents a canvas
 *
 * @component
 * @example
 * <Canvas width={WIDTH} height={HEIGHT} />
 */
export const Canvas = ({ width, height }) => {
    useReactiveVar(currentToolVar);

    const contextMenu = useReactiveVar(contextMenuVar);
    const isGridEnabled = useReactiveVar(isGridEnabledVar);
    const stageScale = useReactiveVar(stageScaleVar);
    const stagePosition = useReactiveVar(stagePositionVar);
    const alert = useReactiveVar(alertsModerator.currentAlertVar);
    const graphicSchema = useReactiveVar(graphicSchemaVar);

    useReactiveVar(selector.rectSelectorPointsVar);
    const tmpRectGraph = useReactiveVar(floorGraph.tmpRectGraphVar);
    const tmpEge = useReactiveVar(floorGraph.tmpEdgeVar);
    const tmpEmbedded = useReactiveVar(floorGraph.tmpEmbeddedObjectEdgeVar);

    const stageRef = useRef(null);
    const layerRef = useRef(null);
    const gridLayerRef = useRef(null);

    const [isSpaceButton, setIsSpaceButton] = useState(false);

    useEffect(() => {
        stageVar(stageRef.current);
        layerVar(layerRef.current);

        if (modeVar() === VIEW_MODE) {
            cursorModerator.changeDefaultCursor(CURSORS.GRAB);
        } else {
            cursorModerator.autoCalcAndChangeDefaultCursor();
        }

        return () => {
            stageVar(null);
            layerVar(null);
        };
    }, []);

    const handleSpaceDownEvent = () => {
        handleSpaceDown();

        if (isSpaceButton) {
            return;
        }
        setIsSpaceButton(true);
        cursorModerator.changeDefaultCursor(CURSORS.GRAB);
    };
    const handleSpaceUp = () => {
        setIsSpaceButton(false);
        cursorModerator.autoCalcAndChangeDefaultCursor();
    };

    useKeyDown(handleCancel, [ESC_KEY]);
    useKeyDown(handleZoneCreation, [ENTER_KEY]);
    useKeyDown(handleSpaceDownEvent, [SPACE_KEY]);

    useKeyUp(handleSpaceUp, [SPACE_KEY]);

    const stageMouseMove = (e) => {
        if (contextMenu.isOpened) {
            return;
        }
        handleStageMouseMove(e);
    };

    const isWallOrRectOrEmbeddingWallCreation = () => {
        return (
            (!!tmpRectGraph && tmpRectGraph.size >= 4) ||
            (!!tmpEge && tmpEge.size > 0) ||
            (!!tmpEmbedded && tmpEmbedded.length > 0)
        );
    };

    return (
        <>
            <div
                onDrop={handleItemDrop}
                onDragOver={(e) => handleDragOver(e, stageScale)}
            >
                <Stage
                    ref={stageRef}
                    name={CANVAS_NAME}
                    width={width}
                    height={height}
                    scaleX={stageScale}
                    scaleY={stageScale}
                    x={stagePosition.x}
                    y={stagePosition.y}
                    onWheel={(e) => handleMouseScroll(e, stageScale)}
                    draggable={true}
                    onDragStart={(e) => handleDragStart(e, isSpaceButton)}
                    onDragEnd={handleDragEnd}
                    onClick={handleStageClick}
                    onContextMenu={(e) => e.evt.preventDefault()}
                    onMouseMove={stageMouseMove}
                    onMouseUp={handleMouseUp}
                    onMouseDown={handleMouseDown}
                >
                    <Layer listening={!isSpaceButton}>
                        <CanvasZones />
                    </Layer>

                    {isGridEnabled && (
                        <Layer ref={gridLayerRef} listening={false}>
                            <CanvasGrid />
                        </Layer>
                    )}

                    <Layer
                        ref={layerRef}
                        listening={
                            modeVar() === CREATING_MODE && !isSpaceButton
                        }
                    >
                        <WallPointAttractionArea />

                        <CanvasWallSegments />

                        {isWallOrRectOrEmbeddingWallCreation() && (
                            <TemporaryWallSegment graph={floorGraph} />
                        )}

                        {modeVar() === CREATING_MODE &&
                            currentToolVar() !== Constraints.route && (
                                <CanvasVertices visible={true} />
                            )}

                        <Routes />

                        {!!selector.rectSelectorPointsVar() &&
                            selector.rectSelectorPointsVar().length > 1 && (
                                <RectSelector />
                            )}

                        {contextMenuVar().isOpened && <CanvasContextMenu />}

                        <CollapsedAlerts />
                        {alert && <ExtendedAlert />}
                    </Layer>

                    <Layer
                        listening={
                            modeVar() === CREATING_MODE && !isSpaceButton
                        }
                    >
                        {[
                            Tools.rulerAny,
                            Tools.rulerHorizontal,
                            Tools.rulerVertical,
                        ].includes(currentToolVar()) && <Ruler />}
                    </Layer>

                    <Layer listening={!isSpaceButton}>
                        <MapObjects />
                        {graphicSchema && (
                            <GraphicSchema
                                schema={graphicSchema}
                                key={'graphicSchema_' + graphicSchema.objectId}
                            />
                        )}
                    </Layer>
                </Stage>
            </div>
        </>
    );
};

Canvas.propTypes = {
    /**
     * Canvas width
     */
    width: PropTypes.number,

    /**
     * Canvas height
     */
    height: PropTypes.number,
};

import React from 'react';
import { Line } from 'react-konva';
import PropTypes from 'prop-types';
import {
    currentCanvasPointVar,
    currentToolVar,
    stageVar,
} from '../../../../shared/model/cache/Cache';
import {
    Constraints,
    Tools,
} from '../../../../pages/Constructor/TopBar/TopBarEntires/Tools/Constants/Tools';
import {
    TEMPORARY_WALL_COLOR,
    TEMPORARY_WALL_ID,
    TEMPORARY_WALL_INVALID_COLOR,
    TEMPORARY_WALL_STROKE_WIDTH,
} from '../../lib/helpers/WallDefaultValues';
import floorGraph, { FloorGraph } from '../../model/FloorGraph';
import { TemporaryEmbeddedSegment } from './TemporaryEmbeddedSegment';
import { useReactiveVar } from '@apollo/client';
import { LinkedSegment } from '../../model/SegmentsModerator';
import { RouteGraph } from '../../../Route/model/RouteGraph';
import { openWallCreationContextMenu } from '../../../ContextMenu/model/OpenWallContextMenu';
import { openRouteCreationContextMenu } from '../../../ContextMenu/model/OpenRouteContextMenu';
import { calcCoordinateForSecondPoint } from '../../lib/helpers/CalculateCoordinateForSecondPoint';

/**@type {[length_of_line_segments: number, gap: number]}*/
const DASH_VALUE_TMP_WALL = [25, 10];

/**
 * Component that represent temporary wall (while wall creation) on canvas
 *
 * @component
 * @example
 * <TemporaryWallSegment graph={floorGraph}/>
 */
export const TemporaryWallSegment = ({ graph = floorGraph }) => {
    const tmpEdge = useReactiveVar(graph.tmpEdgeVar);
    const tmpEdgeProps = useReactiveVar(graph.tmpEdgeProps);
    const tmpEmbeddedObjectEdge = useReactiveVar(
        graph.tmpEmbeddedObjectEdgeVar
    );
    const currentPoint = useReactiveVar(currentCanvasPointVar);

    let secondPoint = currentPoint;
    if (tmpEdge.size === 1) {
        secondPoint = calcCoordinateForSecondPoint([...tmpEdge.values()][0].coordinates, currentPoint, tmpEdgeProps);
    }

    /**
     * Get coordinates as @example [x1,y1,x2,y2...]
     * @returns {Number[]}
     */
    const getRectPoints = () => {
        const points = [
            ...Array.from(graph.tmpRectTypesVar())
                .map(([key, _]) => {
                    const [vertexId1, vertexId2] = LinkedSegment.splitKey(key);
                    return [
                        ...Object.values(
                            graph.tmpRectGraphVar().get(vertexId1)?.coordinates
                        ),
                        ...Object.values(
                            graph.tmpRectGraphVar().get(vertexId2)?.coordinates
                        ),
                    ];
                })
                .flat(),
        ];
        return points;
    };

    const openContextMenu = () => {
        const position = stageVar().getRelativePointerPosition();
        if (currentToolVar() === Tools.wall) {
            openWallCreationContextMenu(position);
        } else if (currentToolVar() === Constraints.route) {
            openRouteCreationContextMenu(position);
        }
    };

    return (
        <>
            {/** TEMPORARY RECTANGLE/SQUARE WALL */}
            {currentToolVar() === Tools.rectangleRoom && (
                <Line
                    points={getRectPoints()}
                    color={TEMPORARY_WALL_COLOR}
                    visible={true}
                    isVirtual={graph.currentWallSegmentTypeVar().virtual}
                    strokeWidth={TEMPORARY_WALL_STROKE_WIDTH}
                    stroke={
                        graph.isWallCreationAvailableVar()
                            ? TEMPORARY_WALL_COLOR
                            : TEMPORARY_WALL_INVALID_COLOR
                    }
                    closed
                />
            )}

            {/* temporary wall */}
            {tmpEdge.size > 0 && tmpEmbeddedObjectEdge.length === 0 && (
                <Line
                    id={TEMPORARY_WALL_ID}
                    points={[
                        ...Object.values(
                            tmpEdge.values().next()?.value?.coordinates
                        ),
                        ...Object.values(secondPoint),
                    ]}
                    stroke={
                        graph.isWallCreationAvailableVar()
                            ? TEMPORARY_WALL_COLOR
                            : TEMPORARY_WALL_INVALID_COLOR
                    }
                    strokeWidth={TEMPORARY_WALL_STROKE_WIDTH}
                    dash={DASH_VALUE_TMP_WALL}
                    dashEnabled={currentToolVar() === Tools.virtualWall}
                    onContextMenu={openContextMenu}
                />
            )}

            {/* temporary embedded object */}
            {currentToolVar() === Tools.embeddedObject &&
                tmpEmbeddedObjectEdge.length > 0 && (
                    <TemporaryEmbeddedSegment
                        tmpEmbeddedObjectEdge={tmpEmbeddedObjectEdge}
                        currentPoint={currentPoint}
                        secondPoint={secondPoint}
                    />
                )}
        </>
    );

    // );
};

TemporaryWallSegment.propTypes = {
    /** instance of FloorGraph */
    graph: PropTypes.oneOfType([
        PropTypes.instanceOf(FloorGraph),
        PropTypes.instanceOf(RouteGraph),
    ]),
};

import { ReactiveVar, makeVar } from '@apollo/client';
import { AlertData, AlertDraft, AlertTypes, CurrentAlertData } from './Types';
import alertsAPI from '../api/AlertsAPI';
import { Point } from '../../../shared/typedefs/Types';

/**
 * @class
 * Class to manage alerts:
 * 1. Store and manage all alerts for current floor
 * 2. Manage opened alert
 */
class AlertsModerator {
    alertsVar: ReactiveVar<Map<string, AlertData>>;
    currentAlertVar: ReactiveVar<CurrentAlertData | null>;

    constructor() {
        this.alertsVar = makeVar(new Map());
        this.currentAlertVar = makeVar<CurrentAlertData | null>(null);
    }

    /**
     * Get alert by id
     * @param alertId alert UUID
     */
    getAlert(alertId: string): AlertData | undefined {
        return this.alertsVar().get(alertId);
    }

    /**
     * Method for adding alert to moderator.
     * Note: this method add alert only locally. To create new alert - use create alert
     * @param alert props for new alert
     */
    addAlert(alert: AlertData) {
        this.alertsVar(new Map(this.alertsVar().set(alert.id, alert)));
    }

    /**
     * Method for adding several alerts to moderator.
     * This method works the same way, as add alert, but used for several adding.
     * Need to optimize project (trigger rerender only once for all pack of alerts)
     * @param alerts props for new alert
     */
    addAlerts(alerts: AlertData[]) {
        alerts.forEach((alert) => {
            this.alertsVar().set(alert.id, alert);
        });
        this.alertsVar(new Map(this.alertsVar()));
    }

    /**
     * Method for creating new alert.
     * Send query to create alert and then add it to moderator
     */
    async createAlert(alert: AlertDraft) {
        const newAlert = await alertsAPI.create(alert);
        if (newAlert) {
            this.addAlert(newAlert);
        }
    }

    /**
     * Method to delete alert from moderator.
     * Note: this method remove only from local storage. To fully delete alert use deleteAlert() method
     * @param alertId
     */
    removeAlertFromModerator(alertId: string) {
        const deleted = this.alertsVar().delete(alertId);
        if (deleted) {
            this.alertsVar(new Map(this.alertsVar()));
        }
    }

    /**
     * Method for deleting alert.
     * Send query to delete alert and then delete it from moderator
     * @param alertId alert UUID
     */
    async deleteAlert(alertId: string) {
        const deleted = await alertsAPI.delete(alertId);
        if (deleted) {
            this.removeAlertFromModerator(alertId);
        }
    }

    /**
     * Method to open existed alert
     * @param alertId alert UUID
     */
    openAlert(alertId: string) {
        const alert = this.getAlert(alertId);
        if (alert) {
            this.currentAlertVar({
                ...alert,
                isEdit: false,
            });
        }
    }

    /**
     * Method to open alert in create mode
     * @param position position of created alert
     */
    openNewAlert(position: Point) {
        this.currentAlertVar({
            id: 'tempAlert',
            type: AlertTypes.INFO,
            position: position,
            text: '',
            isEdit: true,
        });
    }

    /** Method to close opened alert */
    closeAlert() {
        this.currentAlertVar(null);
    }

    /** Clear all stored data */
    clear() {
        this.alertsVar(new Map());
        this.closeAlert();
    }
}

const alertsModerator = new AlertsModerator();
export default alertsModerator;

import { useEffect } from 'react';

// export const useOutsideClick = (ref, onClickOutFunc) => {
//     useEffect(() => {
//         if (ref.current) {
//             const onClick = ({ target }) =>
//                 !ref.current.contains(target) && onClickOutFunc();
//             document.addEventListener('click', onClick);
//             return () => document.removeEventListener('click', onClick);
//         }
//     }, [ref.current]);

/**
 * Function for checking if click is outside the element
 * 
 * @param {React.MutableRefObject} elem Ref to the element
 * @param {function} handler Function for handle outside click
 * @param {boolean} [attached=true] Is element visible
 */
export const useOutsideClick = (elem, handler, attached = true) => {
    useEffect(() => {
        if (!attached) return;

        const handleClick = (e) => {
            if (!elem.current) return;
            if (!elem.current.contains(e.target)) {
                handler();
            }
        };

        document.addEventListener('mousedown', handleClick);
        document.addEventListener('touchstart', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
            document.removeEventListener('touchstart', handleClick);
        };
    }, [elem, handler, attached]);
};

import { v4 as uuid } from 'uuid';
import { currentMenuItemVar } from '../../../../../shared/model/cache/Cache';
import mapObjects from '../../../../MapObject/model/MapObjects';
import { findCenter } from '../../../../MapObject/lib/MapObjectCentering';

/**
 * @typedef {import('../../../../../shared/typedefs/Types').Point} Point
 */

export const defaultItemSize = 50;

/**
 * Command to create a new map object on canvas
 *
 * @param {Point} position position of the new map object
 */
export const createMapObjectCommand = (position) => {
    const id = uuid();
    const centeredPosition = findCenter(
        position,
        currentMenuItemVar().width,
        currentMenuItemVar().length
    );

    return {
        execute() {
            mapObjects.createMapObject({
                id: id,
                name: currentMenuItemVar().name,
                width: currentMenuItemVar().width,
                height: currentMenuItemVar().length,
                type: currentMenuItemVar().typeId,
                position: centeredPosition,
            });
            currentMenuItemVar(null);
        },

        undo() {
            mapObjects.deleteMapObject(id);
        },
    };
};

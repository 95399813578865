import SchemaSpecifier from './SchemaSpecifier';

class GraphicSchema {
    constructor(objectId, objectName, objectTypeName, schema, position) {
        this.objectId = objectId;
        this.objectName = objectName;
        this.objectTypeName = objectTypeName;
        this.schema = schema;
        this.position = position;
    }
    
    /**
     * @typedef {{schemaId: string, schemaImplId:string, version: number}} additionalInfo
     */

    /**
     * Static method for creating new schema object for graphicSchemaVar
     * @param {String} objectId
     * @param {String} objectName
     * @param {String} objectTypeName
     * @param {[schemaString: String, schemaImplString: String]} typeSchemaStrings
     * @param {[schemaString: String, schemaImplString: String]} extendableSchemaStrings
     * @param {[schemaString: String, schemaImplString: String, additionalInfo][]} botSchemasStrings
     */
    static createSchema(
        objectId,
        objectName,
        objectTypeName,
        typeSchemaStrings,
        extendableSchemaStrings,
        botSchemasStrings,
        position
    ) {
        const emptyTypeField = {};
        const emptyExtendableField = {};
        const emptyBotField = {};
        const copy = function () {
            const copy = {
                ...this,
                fields: this.fields?.map((item) => {
                    const fieldCopy = {
                        ...item,
                    };

                    if (item.currentValue !== undefined) {
                        fieldCopy.currentValue = {
                            ...item.currentValue,
                        };
                    }

                    return fieldCopy;
                }) ?? [],
            };
            return copy;
        };
        const filter = function (callback) {
            const filtered = this.copy();
            filtered.fields.filter(callback);
            return filtered;
        };
        emptyTypeField.copy =
            emptyExtendableField.copy =
            emptyBotField.copy =
                copy;
        emptyExtendableField.filter = filter;
        const schema = {
            objectTypeSchema:
                typeSchemaStrings?.length === 2 &&
                typeSchemaStrings[0] &&
                typeSchemaStrings[1]
                    ? SchemaSpecifier.parseSchemaAndSchemaImpl(
                          typeSchemaStrings[0],
                          typeSchemaStrings[1]
                      )
                    : emptyTypeField,
            objectExtendablePropsSchema:
                extendableSchemaStrings?.length === 2 &&
                extendableSchemaStrings[0] &&
                extendableSchemaStrings[1]
                    ? SchemaSpecifier.parseSchemaAndSchemaImpl(
                          extendableSchemaStrings[0],
                          extendableSchemaStrings[1]
                      )
                    : emptyExtendableField,
            objectBotPropsSchemas:
                botSchemasStrings?.map((item) =>
                    item?.length === 3 && item
                        ? {
                              ...SchemaSpecifier.parseSchemaAndSchemaImpl(
                                  item[0],
                                  item[1]
                              ),
                              schemaId: item[2].schemaId,
                              schemaImplId: item[2].schemaImplId,
                              version: item[2].version,
                          }
                        : emptyBotField
                ) ?? [],
        };

        return new GraphicSchema(objectId, objectName, objectTypeName, schema, {
            ...position,
        });
    }

    copy() {
        return new GraphicSchema(
            this.objectId,
            this.objectName,
            this.objectTypeName,
            {
                objectTypeSchema: this.schema.objectTypeSchema.copy(),
                objectExtendablePropsSchema:
                    this.schema.objectExtendablePropsSchema.copy(),
                objectBotPropsSchemas: this.schema.objectBotPropsSchemas.map(
                    (item) => item.copy()
                ),
            },
            this.position
        );
    }
}

export default GraphicSchema;

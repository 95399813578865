import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useMutation } from '@apollo/client';

import { Form, UserLink } from './Styles';
import { Input } from '../../../shared/ui/FormComponents/Input';
import { PasswordInput } from '../../../shared/ui/FormComponents/PasswordInput';
import { Checkbox } from '../../../shared/ui/FormComponents/Checkbox';
import { Button } from '../../../shared/ui/FormComponents/Button';

import {
    isSendEmail,
    repeatSubmitHandlerVar,
} from '../../../shared/model/cache/Cache';
import { registrationAllowPage } from '../../../shared/model/routes/staticRoutes';
import { REGISTER_USER } from '../../../shared/graphql/mutations/users/RegisterUser';

import { validateRegistrationForm } from './ValidateRegistrationForm';
import { hintsForFields } from './Constants';
import { handleResponseError } from '../../../shared/lib/utils/auth/HandleResponseError';

/**
 * Registration form component with eye img component
 * @returns { } Empty component with Registration form and eye img
 */
export const RegistrationForm = () => {
    const [inputValues, setInputValues] = useState({
        login: '',
        email: '',
        password: '',
        controlPassword: '',
    });
    const [formValid, setFormValid] = useState(false);
    const [forceHint, setForceHint] = useState(false);

    // for setting messages for input
    const [inputValuesErrors, setInputValuesErrors] = useState({
        login: null,
        email: null,
        password: null,
        controlPassword: null,
    });

    // for setting color of inputs
    const [inputValuesStatus, setInputValuesStatus] = useState({
        login: 'hint',
        email: 'hint',
        password: 'hint',
        controlPassword: 'hint',
    });

    const [checkbox, setCheckbox] = useState(true);

    const navigate = useNavigate();

    /**
     * Use mutation returns tuple: [queryFunction, {data, isLoading, error}]
     * Three last vars were deleted because of warnings
     * Add them here if needed
     */
    const [registerUser] = useMutation(REGISTER_USER, {
        fetchPolicy: 'network-only',
    });

    /**
     * Function for validation login, email, password and second password
     * @param { email } param User email
     * @param { login } param User login
     * @param { password } param User password
     * @param { controlPassword } param User second password
     * @param { checkbox } param Is checkbox checked
     */
    const validate = ({
        email,
        login,
        password,
        controlPassword,
        checkbox,
    }) => {
        validateRegistrationForm(
            { email, login, password, controlPassword, checkbox },
            formValid,
            setFormValid,
            setInputValuesErrors,
            setInputValuesStatus
        );
    };

    /**
     * Run when sending the form
     * Now just show login and password
     * @param {*} e Needed for prevent default
     */
    const submitHandler = (e) => {
        e.preventDefault();

        validate({ ...inputValues, checkbox });
        setForceHint(true);

        if (formValid) {
            const variables = {
                user: {
                    email: inputValues.email,
                    login: inputValues.login,
                    password: inputValues.password,
                },
            };

            registerUser({ variables })
                .then((response) => {
                    // TODO change or remove for correct showing of errors in actual version
                    if (response.error) {
                        handleResponseError(
                            response.error.message,
                            setInputValuesErrors,
                            setInputValuesStatus
                        );
                    }
                    if (response.data.registerUser) {
                        isSendEmail(true);
                        repeatSubmitHandlerVar({
                            variables,
                            query: REGISTER_USER,
                        });
                        navigate(registrationAllowPage);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    /**
     * Inputs change handler
     * @param { currentTarget } param Element in which the event is handled
     */
    const changeHandler = ({ currentTarget }) => {
        const { value, name } = currentTarget;
        setInputValues((prev) => {
            const values = { ...prev, [name]: value };
            validate({ ...values, checkbox });
            return values;
        });
    };

    /**
     * Input focus handler
     * Set hint message
     * @param { currentTarget } param Element in which the event is handled
     */
    const onFocusInputHandler = ({ currentTarget }) => {
        const { value, name } = currentTarget;
        if (value === '') {
            setInputValuesErrors((prev) => ({
                ...prev,
                [name]: hintsForFields[name],
            }));
            setInputValuesStatus((prev) => ({ ...prev, [name]: 'hint' }));
        }
    };

    /**
     * Input blur handler
     * Set message, that field is required
     * @param { currentTarget } param Element in which the event is handled
     */
    const onBlurInputHandler = ({ currentTarget }) => {
        const { value, name } = currentTarget;
        if (value === '') {
            setInputValuesErrors((prev) => ({
                ...prev,
                [name]: 'Обязательное поле',
            }));
            setInputValuesStatus((prev) => ({ ...prev, [name]: 'error' }));
        }
    };

    /**
     * Checkbox change handler
     * @param { checked } param Is checkbox checked
     */
    const changeCheckboxHandler = (checked) => {
        setCheckbox(checked);
        validate({
            email: inputValues.email,
            login: inputValues.login,
            password: inputValues.password,
            controlPassword: inputValues.controlPassword,
            checkbox: checked,
        });
    };

    return (
        <Form onSubmit={submitHandler}>
            <Input
                name='email'
                value={inputValues.email}
                onChange={changeHandler}
                placeholder='Адрес эл. почты'
                hintMsgType={inputValuesStatus.email}
                hintMsg={inputValuesErrors.email}
                forceHint={forceHint}
                autoComplete='on'
                onFocus={onFocusInputHandler}
                onBlur={onBlurInputHandler}
            />
            <Input
                name='login'
                value={inputValues.login}
                onChange={changeHandler}
                placeholder='Придумайте логин'
                hintMsgType={inputValuesStatus.login}
                hintMsg={inputValuesErrors.login}
                forceHint={forceHint}
                autoComplete='off'
                onFocus={onFocusInputHandler}
                onBlur={onBlurInputHandler}
            />
            <PasswordInput
                name='password'
                value={inputValues.password}
                onChange={changeHandler}
                placeholder='Придумайте пароль'
                hintMsgType={inputValuesStatus.password}
                hintMsg={inputValuesErrors.password}
                forceHint={forceHint}
                autoComplete='off'
                onFocus={onFocusInputHandler}
                onBlur={onBlurInputHandler}
            />
            <PasswordInput
                name='controlPassword'
                value={inputValues.controlPassword}
                onChange={changeHandler}
                placeholder='Повторите пароль'
                hintMsgType={inputValuesStatus.controlPassword}
                hintMsg={inputValuesErrors.controlPassword}
                forceHint={forceHint}
                autoComplete='new-password'
                onFocus={onFocusInputHandler}
                onBlur={onBlurInputHandler}
            />
            <Checkbox
                name='terms'
                defaultChecked
                onClick={(e) => changeCheckboxHandler(e.target.checked)}
            >
                Я согласен на обработку моих персональных данных в соответствии
                с{' '}
                <UserLink href='https://dev.mapins.ru/legal/confidential'>
                    Условиями
                </UserLink>
            </Checkbox>
            <Button disabled={!checkbox}>Зарегистрироваться</Button>
        </Form>
    );
};

import { findCenter } from '../../../../MapObject/lib/MapObjectCentering';
import mapObjects from '../../../../MapObject/model/MapObjects';

/**
 * @typedef {import('../../../../../shared/typedefs/Types').Point} Point
 */

/**
 * Command to drag an item on the canvas
 *
 * @param {object} currentMapObject dragging canvas item
 * @param {Point} newPosition new position of the dragging canvas item
 */
export const dragMapObjectCommand = (currentMapObject, newPosition) => {
    const oldPosition = currentMapObject.position;
    const updatedPosition = findCenter(
        newPosition,
        currentMapObject.width,
        currentMapObject.height,
        currentMapObject.angle
    );

    return {
        execute() {
            mapObjects.updateMapObjectPosition(
                currentMapObject.id,
                updatedPosition
            );
        },

        undo() {
            mapObjects.updateMapObjectPosition(
                currentMapObject.id,
                oldPosition
            );
        },
    };
};

import React from 'react';
import { Modal } from '../ModalWindow/Modal';
import { ObjectSettingsModalBody } from './ui/ObjectSettingsModalBody';
import { useReactiveVar } from '@apollo/client';
import { objectSettingsModalVar } from '../../shared/model/cache/Cache';

/**
 * Component represents modal with object settings
 * @component
 */
export const ObjectSettingsModal = () => {
    const { isOpened } = useReactiveVar(objectSettingsModalVar);

    const handleClose = () => {
        objectSettingsModalVar({
            ...objectSettingsModalVar(),
            isOpened: false,
            objectType: null,
            objectUUID: '',
        });
    };

    return (
        <Modal isOpened={isOpened} isCloseIconShow={true} onClose={handleClose}>
            <ObjectSettingsModalBody onClose={handleClose} />
        </Modal>
    );
};

import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { ZoneList } from './ZonesInfo/ZoneList';
import { useState } from 'react';
import { MapStructureHeader } from './MapStructureHeader';
import { ConstraintsList } from './ConstraintsInfo/ConstraintsList';
import { SCROLLBAR_STYLED } from '../../../shared/ui/ScrollBar';

const LeftBarWrapper = styled.div`
    max-height: inherit;
    height: inherit;
    display: flex;
    flex-direction: column;
    background-color: white;
    border: ${(props) => (props.isOpened ? '0.5px solid #d7d7d7' : 'none')};
    width: ${(props) => (props.isOpened ? '370px' : '')};
`;

const MapStructureContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 8px 5px 8px 9px;

    overflow: auto;
    ${SCROLLBAR_STYLED}

    & > :last-child {
        flex-grow: 1;
    }

    // for hiding collapse icons
    & .collapseIcon {
        visibility: hidden;
    }

    // for showing collapse icons
    &:hover .collapseIcon {
        visibility: visible;
    }
`;

/**
 * Component that represents left bar of edit mode page. It contains list of canvas zones.
 *
 * @component
 */
export const LeftBar = ({ leftBarContainerRef }) => {
    const [isOpened, setIsOpened] = useState(false);

    /** Ref for scroll container */
    const containerRef = useRef();

    useEffect(() => {
        if (leftBarContainerRef.current) {
            if (isOpened) {
                leftBarContainerRef.current.style.height = '100%';
            } else {
                leftBarContainerRef.current.style.height = 'fit-content';
            }
        }
    }, [leftBarContainerRef, isOpened]);

    return (
        <LeftBarWrapper isOpened={isOpened}>
            <MapStructureHeader isOpened={isOpened} setIsOpened={setIsOpened} />
            {isOpened && (
                <MapStructureContent ref={containerRef}>
                    <ConstraintsList containerRef={containerRef}/>
                    <ZoneList />
                </MapStructureContent>
            )}
        </LeftBarWrapper>
    );
};

LeftBar.propTypes = {
    /**
     * ref to parent container
     */
    width: PropTypes.shape({ current: PropTypes.object }),
};

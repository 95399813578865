import { HINT_POSITION, HINT_TEXT_ALIGNMENT } from '../../../model/Constants';

/**
 * Calc position styles for notification container
 * @param {HINT_TEXT_ALIGNMENT} alignment
 * @returns {string} string with css styles
 */
export const getFlexAlignment = (alignment: HINT_TEXT_ALIGNMENT): string => {
    switch (alignment) {
        case HINT_TEXT_ALIGNMENT.RIGHT:
            return 'flex-end';
        case HINT_TEXT_ALIGNMENT.LEFT:
            return 'flex-start';
        case HINT_TEXT_ALIGNMENT.CENTER:
        default:
            return 'center';
    }
};

/**
 * Calc alignment style for hint provider container
 * @param {HINT_POSITION} alignment
 * @returns {string} value for 'align-items'
 */
export const getFlexAlignmentByHintPosition = (
    alignment: HINT_POSITION
): string => {
    switch (alignment) {
        case HINT_POSITION.topLeft:
        case HINT_POSITION.bottomLeft:
            return 'flex-start';
        case HINT_POSITION.topRight:
        case HINT_POSITION.bottomRight:
            return 'flex-end';
        case HINT_POSITION.topMiddle:
        case HINT_POSITION.bottomMiddle:
        default:
            return 'center';
    }
};

/**
 * Calc position styles for notification container
 * @param {HINT_TEXT_ALIGNMENT} alignment
 * @returns {string} string with css styles
 */
export const getTextAlignment = (alignment: HINT_TEXT_ALIGNMENT): string => {
    switch (alignment) {
        case HINT_TEXT_ALIGNMENT.RIGHT:
            return 'end';
        case HINT_TEXT_ALIGNMENT.LEFT:
            return 'start';
        case HINT_TEXT_ALIGNMENT.CENTER:
        default:
            return 'center';
    }
};

/**
 * Calc padding for title
 * @param {HINT_TEXT_ALIGNMENT} alignment
 * @returns {string} string with css padding
 */
export const getPaddingForTitle = (alignment: HINT_TEXT_ALIGNMENT): string => {
    switch (alignment) {
        case HINT_TEXT_ALIGNMENT.LEFT:
            return 'padding-right: 20px;';
        case HINT_TEXT_ALIGNMENT.RIGHT:
            return 'padding-left: 20px;';
        case HINT_TEXT_ALIGNMENT.CENTER:
        default:
            return 'padding-inline: 20px;';
    }
};

import { currentProjectVar } from '../../../shared/model/cache/Cache';

export const initializeProject = (projectData) => {
    currentProjectVar({
        id: projectData.id,
        name: projectData.name,
        ownerId: projectData.owner.id,
        description: projectData.description,
        accuracy: projectData.accuracy,
    });
};

import { DocumentNode, gql } from '@apollo/client';

/** 
 * Query to find users by search string.
 * Search string should be at least 3 symbols
 */
export const SEARCH_USERS: DocumentNode = gql`
    query searchUsers($searchStr: String) {
        users(startWith: $searchStr) {
            id
            name
            email
        }
    }
`;

import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const TabsWrapper = styled.ul`
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 4px;
    position: relative;
`;

const TabContainer = styled.li`
    list-style: none;
`;

const RadioInput = styled.input`
    display: none;

    &:checked + label {
        background: #ededed;
    }
`;

const TabLabel = styled.label`
    padding: 3px 8px;
    display: inline-block;
    font-size: 16px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    border-radius: 5px;
    transition: 0.25s background-color ease;
    cursor: pointer;

    :hover {
        background-color: #ededed;
    }
`;

/** Line that underlines selected tab */
const Line = styled.div<{
    width: number;
    left: number;
    top: number;
}>`
    position: absolute;
    height: 3px;
    background: #5076fd;
    width: ${({ width }) => width}px;
    top: ${({ top }) => top}px;
    left: ${({ left }) => left}px;
    transition: 0.25s ease;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
`;

export interface TabsComponentProps<T extends string> {
    /** List of tabs to show */
    tabList: T[];

    /** Selected tab */
    selectedTab: T;

    /**
     * Name for radio buttons group.
     * Better to set this property, if you don't want conflicts between different radio groups
     */
    groupName: string;

    /** Callback function for select */
    onSelect?: (tab: T) => void;

    /** CSS properties for tabs container */
    containerStyle?: React.CSSProperties;
}

/**
 * Component that represent tabs.
 * This component only for tab selector.
 * @component
 */
export const Tabs = <T extends string>({
    tabList = ['Example' as T],
    selectedTab = tabList[0],
    groupName = 'tabs',
    onSelect = () => {},
    containerStyle,
}: TabsComponentProps<T>) => {
    const ref = useRef<HTMLLabelElement>(null);
    const [lineProps, setLineProps] = useState({
        width: 30,
        offsetX: 0,
        offsetY: 40,
    });

    // This use effect needed to change props for line, that underlines selected tab
    useEffect(() => {
        if (ref.current) {
            setLineProps({
                width: ref.current.scrollWidth,
                offsetX: ref.current.offsetLeft,
                offsetY: ref.current.offsetTop + ref.current.scrollHeight - 3,
            });
        }
    }, [ref, selectedTab]);

    const handleSelect: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const newTab = e.target.value;
        onSelect(newTab as T);
    };

    return (
        <TabsWrapper style={containerStyle}>
            {tabList.map((tab) => {
                const isSelected = selectedTab === tab;
                return (
                    <TabContainer key={tab}>
                        <RadioInput
                            type='radio'
                            id={tab}
                            name={groupName}
                            value={tab}
                            checked={isSelected}
                            onChange={handleSelect}
                        />
                        <TabLabel
                            ref={isSelected ? ref : undefined}
                            htmlFor={tab}
                        >
                            {tab}
                        </TabLabel>
                    </TabContainer>
                );
            })}
            <Line
                width={lineProps.width}
                left={lineProps.offsetX}
                top={lineProps.offsetY}
            />
        </TabsWrapper>
    );
};

import React, { memo } from 'react';
import { Line } from 'react-konva';

import { openZoneContextMenu } from '../../ContextMenu/model/OpenZoneContextMenu';
import { currentToolVar, stageVar } from '../../../shared/model/cache/Cache';
import zones from '../model/Zones';
import ZoneClass from '../model/Zone';
import { RULE_EDIT } from '../../AccessGroup/model/AGConstants';
import {
    Constraints,
    Tools,
} from '../../../pages/Constructor/TopBar/TopBarEntires/Tools/Constants/Tools';
import { openWallCreationContextMenu } from '../../ContextMenu/model/OpenWallContextMenu';
import { openRouteCreationContextMenu } from '../../ContextMenu/model/OpenRouteContextMenu';

/**
 * @typedef {import('../../Wall/model/SegmentsModerator').PointLinkWrapper} PointLinkWrapper
 */

/**
 * Component that represent zone on canvas
 *
 * @component
 * @type {React.FC<{borders: PointLinkWrapper[], zoneId: string, zoneName: string, color: string, isStub: boolean, accessRule: string }>}
 */
export const Zone = memo(
    ({ borders, zoneId, zoneName, color, isStub, accessRule }) => {
        const openContextMenu = () => {
            if (stageVar()) {
                const position = stageVar().getRelativePointerPosition();
                if (currentToolVar() === Tools.wall) {
                    // wall tool context menu
                    openWallCreationContextMenu(position);
                } else if (currentToolVar() === Constraints.route) {
                    // route tool context menu
                    openRouteCreationContextMenu(position);
                } else {
                    // open zone context menu
                    const zoneId = zones.getZoneIdUnderPoint(
                        stageVar().getRelativePointerPosition()
                    );
                    zones.zonesMergeVar({
                        firstZoneId: zoneId, // set clicked zone
                        secondZoneId: null, // unset second zone
                        isTracking: true, // set tracking zone clicks
                    });
                    openZoneContextMenu(zoneId, position);
                }
            }
        };

        /**
         * Handler for zone click
         * Needed for merging zones
         * Setting up zonesMergeVar if it's necessary
         */
        const handleZoneClick = () => {
            const zonesMerge = zones.zonesMergeVar();
            if (
                zonesMerge.isTracking && // if tracking clicks is active
                zonesMerge.firstZoneId !== zoneId // first and second zones should be different
            ) {
                const zoneId = zones.getZoneIdUnderPoint(
                    stageVar().getRelativePointerPosition()
                );
                zones.zonesMergeVar({
                    ...zonesMerge,
                    secondZoneId: zoneId, // set clicked zone
                });
            }
        };

        return (
            <Line
                isZone={true}
                listening={!isStub && accessRule === RULE_EDIT}
                name={zoneName}
                points={ZoneClass.getBordersCoordinatesFlat(
                    borders.map((borderPointLink) => borderPointLink.id)
                )}
                fill={color}
                opacity={1.0}
                onContextMenu={openContextMenu}
                closed
                shadowForStrokeEnabled={false}
                perfectDrawEnabled={false}
                hitStrokeWidth={0}
                onClick={handleZoneClick}
            />
        );
    }
);

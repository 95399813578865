import React from 'react';
import { Group, Line, Text } from 'react-konva';
import { useReactiveVar } from '@apollo/client';

import RulerObject from '../model/Ruler';

import {
    getLeftOrLowerLinePoint,
    getRightOrUpperLinePoint,
    getLineRotation,
} from '../../../shared/lib/modules/math/Math';
import { getArrOfCoordinates } from '../../../shared/lib/utils/common/GetArrayOfCoordinates';
import ScalesConformer from '../../ScalesConformer/model/ScalesConformer';

/**
 * Component that represents the ruler
 *
 * @component
 */
export const Ruler = () => {
    const currentRuler = useReactiveVar(RulerObject.currentRulerVar);

    let rulerLine = [];
    let borders = {};
    let textPosition = {};

    if (currentRuler) {
        [rulerLine, borders, textPosition] =
            RulerObject.getBorderedLengthRulerLineStrings();
    }

    return (
        <Group>
            {currentRuler && (
                <>
                    <Line
                        points={getArrOfCoordinates(rulerLine)}
                        stroke={RulerObject.DEFAULT_COLOR}
                        strokeWidth={RulerObject.DEFAULT_WIDTH}
                    />
                    <Line
                        points={getArrOfCoordinates(borders.firstBorderLine)}
                        stroke={RulerObject.DEFAULT_COLOR}
                        strokeWidth={RulerObject.DEFAULT_WIDTH}
                    />
                    <Line
                        points={getArrOfCoordinates(borders.secondBorderLine)}
                        stroke={RulerObject.DEFAULT_COLOR}
                        strokeWidth={RulerObject.DEFAULT_WIDTH}
                    />
                    <Text
                        width={RulerObject.getLength()}
                        height={
                            RulerObject.DEFAULT_BORDERS_LENGTH -
                            RulerObject.DEFAULT_BORDERS_PADDING
                        }
                        fontSize={RulerObject.DEFAULT_TEXT_FONT_SIZE}
                        align={'center'}
                        verticalAlign={'bottom'}
                        text={`${ScalesConformer.toMetres(
                            RulerObject.getLength()
                        ).toFixed(2)} метров`}
                        x={textPosition.x}
                        y={textPosition.y}
                        rotation={getLineRotation(
                            getLeftOrLowerLinePoint(rulerLine),
                            getRightOrUpperLinePoint(rulerLine)
                        )}
                        fill={RulerObject.DEFAULT_COLOR}
                    />
                </>
            )}
        </Group>
    );
};

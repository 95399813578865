import { DocumentNode, gql } from '@apollo/client';

/**
 * Mutation for deleting alert by its id
 */
export const DELETE_ALERT: DocumentNode = gql`
    mutation deleteAttentionMarker($id: String!) {
        deleteAttentionMarker(id: $id) {
            id
        }
    }
`;

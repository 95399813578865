import React, { useState } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import styled from 'styled-components';
import { ItemsFolder } from './ItemsFolder/ItemsFolder';
import { GET_GLOBAL_TYPES_GROUPS } from '../../../../../../shared/graphql/queries/mapObjects/GetProjectTypesGroups';
import { POpenSansRegular } from '../../../../../../shared/ui/TextStyles';
import { Preloader } from '../../../../../../shared/ui/Preloader';
import notifications from '../../../../../../features/HintSystem/model/Notifications';
import rightBarModerator from '../../../model/RightBarModerator';
import { BASE_ITEMS, FIRE_SAFETY_ITEMS, SPECIAL_EQUIPMENT } from './Constants';
import { FoldersWrapper } from './Styles';

const InfoContainer = styled.div`
    margin: 0 20px;
    display: flex;
    align-items: center;
`;

/** Function that adapts map object types from server to project structure */
const adaptMapObjectsToItems = (mapObjectTypes) => {
    return mapObjectTypes.map((objType) => {
        return {
            typeId: objType.id,
            typeName: 'MapObjectType',
            ...objType,
        };
    });
};

/**
 * Find folder name in types and form folder than set it to state
 * @param  {object[]} typesGroups
 * @param  {...string} folderNames
 */
const findAndAddItemsFolders = (typesGroups, ...folderNames) => {
    const newFolders = [];

    folderNames.forEach((name) => {
        newFolders.push({
            name: name,
            items: adaptMapObjectsToItems(
                typesGroups.find((type) => type.name === name).mapObjectTypes
            ),
        });
    });

    return newFolders;
};

/**
 * Component that represents tab with map objects for right bar
 * @component
 */
export const MapObjectsTab = () => {
    useReactiveVar(rightBarModerator.openedFolders);
    const [projectObjectFolders, setProjectObjectFolders] = useState([]);

    /**
     * Function that adding items in folders
     * @param {Object[]} typesGroups[]
     */
    const handleAddMapObjectTypesFolders = (typesGroups) => {
        setProjectObjectFolders(
            findAndAddItemsFolders(
                typesGroups,
                BASE_ITEMS,
                SPECIAL_EQUIPMENT,
                FIRE_SAFETY_ITEMS
            )
        );
    };

    // query for adding map object types to base items
    const { loading: foldersLoading, error: foldersError } = useQuery(
        GET_GLOBAL_TYPES_GROUPS,
        {
            onCompleted: (data) => {
                handleAddMapObjectTypesFolders(data.typesGroups);
            },
            onError: (error) => {
                notifications.setError(
                    'Ошибка загрузки глобальных типов',
                    error
                );
            },
        }
    );

    return (
        <FoldersWrapper>
            {projectObjectFolders.map((folder) => (
                <ItemsFolder
                    folderName={folder.name}
                    items={folder.items}
                    key={folder.name}
                    folder={folder}
                />
            ))}
            {foldersLoading && (
                <InfoContainer>
                    <Preloader loaderSize={30} />
                    <POpenSansRegular>Данные загружаются...</POpenSansRegular>
                </InfoContainer>
            )}
            {foldersError && (
                <InfoContainer>
                    <POpenSansRegular>
                        Произошла ошибка загрузки данных. Пожалуйста,
                        перезагрузите страницу и попробуйте ещё раз
                    </POpenSansRegular>
                </InfoContainer>
            )}
        </FoldersWrapper>
    );
};

import { DocumentNode, gql } from '@apollo/client';
import { ACCESS_RULE_FRAGMENT } from '../../fragments/accessRules/AccessRuleFragment';

/** Mutation to give access to object to access group */
export const GIVE_ACCESS: DocumentNode = gql`
    ${ACCESS_RULE_FRAGMENT}
    mutation giveAccess(
        $type: SecuredObjectType!
        $itemId: String!
        $ruleDraft: AccessRuleDraft!
    ) {
        giveAccess(type: $type, itemId: $itemId, ruleDraft: $ruleDraft) {
            ...AccessRuleFragment
        }
    }
`;

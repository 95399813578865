import React from 'react';
import useImage from 'use-image';
import { Image } from 'react-konva';
import { getDistanceBetweenPoints } from '../../../../shared/lib/modules/math/Math';
import { LinkedSegment } from '../../model/SegmentsModerator';
import {
    handleClickEdge,
    handleContextMenu,
    handleMouseEnter,
    handleMouseLeave,
} from '../../lib/utils/WallHandlers';
import { RULE_EDIT } from '../../../AccessGroup/model/AGConstants';
import { TransparentDraggableSegment } from './TransparentDraggableSegment';
import { currentToolVar } from '../../../../shared/model/cache/Cache';
import selector from '../../../Selector/model/Selector';
import floorGraph from '../../model/FloorGraph';
import { useReactiveVar } from '@apollo/client';
import {
    EMBEDDED_OBJECT_NAME,
    SELECTED_COLOR,
} from '../../lib/helpers/WallDefaultValues';
import { CURSORS } from '../../../../shared/lib/utils/common/CursorModerator';
import { Tools } from '../../../../pages/Constructor/TopBar/TopBarEntires/Tools/Constants/Tools';
import scalesConformer from '../../../ScalesConformer/model/ScalesConformer';

/**
 * Component that represent embedded object on canvas
 * @component
 * @type {React.FC<{edgeId: String, edgeProps: import('../../model/SegmentsModerator').WallSegmentType, accessRule: String  }>}
 */
export const EmbeddedSegment = ({ edgeId, edgeProps, accessRule }) => {
    // TODO: Maybe implement default missing image picture
    // if(!edgeProps.schematicPicture)
    //     return;

    const imageSrc = 'data:image/svg+xml;utf8,' + edgeProps.image.src;
    const [image, status] = useImage(imageSrc);

    useReactiveVar(selector.selectedEdgesVar);
    useReactiveVar(selector.selectedVerticesVar);
    const hovered = useReactiveVar(selector.isHoveredVar);
    const isSelected = selector.isEdgeSelected(edgeId);

    // TODO need to draw mock object while "loaded"
    // TODO need to draw mock image/object if status "failed"
    if (status === 'failed') {
        console.error('image loading failed');
    }

    const [pointId1, pointId2] = LinkedSegment.splitKey(edgeId);
    const segment = floorGraph.segmentsModerator.getSegment(pointId1, pointId2);

    const v1Coordinates = segment.firstPointLinkWrapper.pointInfo.coordinates;
    const v2Coordinates = segment.secondPointLinkWrapper.pointInfo.coordinates;

    const distance = getDistanceBetweenPoints(v1Coordinates, v2Coordinates);

    const tmpPoints = floorGraph.formDrawableSegmentPolygon(segment);

    const openContextMenu = (e) => {
        e.target.preventDefault();
        handleContextMenu(edgeId, false);
    };

    // calculate angle to rotate embedded object
    let angle = Math.asin((v2Coordinates.y - v1Coordinates.y) / distance);
    angle = (angle * 180) / Math.PI; // radians to degrees
    if (v2Coordinates.x < v1Coordinates.x) {
        angle = 180 - angle;
    }

    const segmentWidth = scalesConformer.toPixels(
        segment.segmentInfo.width || segment.segmentInfo.type.width
    );
    const imageOffsetCoefficient =
        segment.segmentInfo.imageOffsetCoefficient ||
        segment.segmentInfo.type.imageOffsetCoefficient ||
        0;
    const offsetY = imageOffsetCoefficient * segmentWidth;

    return (
        status === 'loaded' && (
            <>
                <Image
                    id={edgeId}
                    key={edgeId}
                    image={image}
                    fill={isSelected ? SELECTED_COLOR : ''}
                    name={EMBEDDED_OBJECT_NAME}
                    x={v1Coordinates.x}
                    y={v1Coordinates.y}
                    offsetY={offsetY}
                    width={distance}
                    height={segmentWidth}
                    rotation={angle}
                    listening={accessRule === RULE_EDIT}
                    onClick={(e) => handleClickEdge(e, edgeId, floorGraph)}
                    onMouseEnter={(e) => {
                        handleMouseEnter(CURSORS.POINTER);
                        selector.isHoveredVar(e.target.id());
                    }}
                    onMouseLeave={handleMouseLeave}
                    onContextMenu={openContextMenu}
                    accessRule={accessRule}
                    graph={floorGraph}
                />

                {/* It's a transparent line for handling dragging */}
                {currentToolVar() === Tools.none &&
                    isSelected &&
                    hovered === edgeId && (
                        <TransparentDraggableSegment
                            wallId={edgeId}
                            tmpPoints={tmpPoints}
                            isVirtual={edgeProps.virtual}
                            clickEdge={(e) =>
                                handleClickEdge(e, edgeId, floorGraph)
                            }
                            accessRule={accessRule}
                        />
                    )}
            </>
        )
    );
};

import { AlertData, AlertDataFromServer } from './Types';

/**
 * Method to convert alert data from server to alert project structure
 * @param dataFromServer
 * @returns AlertData
 */
export const alertDataFromServerToAlertData = (
    dataFromServer: AlertDataFromServer
): AlertData => {
    return {
        id: dataFromServer.id,
        type: dataFromServer.type,
        position: dataFromServer.position,
        text: dataFromServer.message,
    };
};

import { addTwoPoints } from "../../modules/math/Math";

/**
 * Function for getting item box in form of array of lineString
 * @param {{
 *  width: Number,
 *  height: Number,
 *  position: {x: Number, y: Number}
 * }} item 
 * @returns {{
 *  x: Number,
 *  y: Number
 * }[][]}
 */
export const getItemBoxLineStrings = (item) => {
    const leftUpperCorner = {
        x: item.position.x,
        y: item.position.y,
    };
    const rightLowerCorner = addTwoPoints(leftUpperCorner, {
        x: item.width,
        y: item.height,
    });
    return [
        [
            leftUpperCorner,
            { ...leftUpperCorner, x: leftUpperCorner.x + item.width },
        ],
        [
            leftUpperCorner,
            { ...leftUpperCorner, y: leftUpperCorner.y + item.height },
        ],
        [
            { ...leftUpperCorner, x: leftUpperCorner.x + item.width },
            rightLowerCorner,
        ],
        [
            { ...leftUpperCorner, y: leftUpperCorner.y + item.height },
            rightLowerCorner,
        ],
    ];
};

import { gql } from '@apollo/client';

export const INSERT_WALL_SEGMENT = gql`
    mutation insertWallSegment(
        $baseSegmentSelector: VersionedSegmentSelector!
        $insertSegmentDraft: WallSegmentInPlaceDraft!
    ) {
        insertWallSegment(
            baseSegmentSelector: $baseSegmentSelector
            insertSegmentDraft: $insertSegmentDraft
        ) {
            createdSegments {
                version
                name
                endPoint {
                    coordinate {
                        x
                        y
                    }
                    id
                    accessRules {
                        group {
                            id
                        }
                        rule
                    }
                }
                id
                lengthRestriction
                startPoint {
                    coordinate {
                        x
                        y
                    }
                    id
                    accessRules {
                        group {
                            id
                        }
                        rule
                    }
                }
                width
                through
                secondZone {
                    id
                }
                firstZone {
                    id
                }
                schemaImpls {
                    data
                    id
                    version
                    schema {
                        id
                        schema
                        version
                    }
                }
                type {
                    id
                    length
                    width
                    scalable
                    through
                    virtual
                    imageOffsetCoefficient
                    image {
                        id
                        src
                        type
                    }
                    techImage {
                        id
                        src
                    }
                }
                accessRules {
                    group {
                        id
                    }
                    rule
                }
            }
            updatedSegments {
                version
                name
                endPoint {
                    coordinate {
                        x
                        y
                    }
                    id
                    accessRules {
                        group {
                            id
                        }
                        rule
                    }
                }
                id
                lengthRestriction
                startPoint {
                    coordinate {
                        x
                        y
                    }
                    id
                    accessRules {
                        group {
                            id
                        }
                        rule
                    }
                }
                width
                through
                secondZone {
                    id
                }
                firstZone {
                    id
                }
                schemaImpls {
                    data
                    id
                    version
                    schema {
                        id
                        schema
                        version
                    }
                }
                type {
                    id
                    length
                    width
                    scalable
                    through
                    virtual
                    imageOffsetCoefficient
                    image {
                        id
                        src
                    }
                    techImage {
                        id
                        src
                    }
                }
                accessRules {
                    group {
                        id
                    }
                    rule
                }
            }
            zones {
                id
                version
                shape {
                    outer {
                        id
                        coordinate {
                            x
                            y
                        }
                    }
                }
            }
        }
    }
`;

import validatorFactory from './ValidatorFactory';

const DEFAULT_SCHEMA_VERSION = '1.0.0';

export enum FormFieldTypes {
    input = 'input',
    select = 'select',
}

export enum StringFormats {
    date = 'Date',
    time = 'Time',
    string = 'string',
}

export enum SchemaFieldBaseTypes {
    number = 'Number',
    string = 'String',
    object = 'Object',
    user = 'User',
    role = 'Role',
}

export enum SchemaFieldAggregateTypes {
    enum = 'enum',
    list = 'list',
}

const INPUT_TYPES = [SchemaFieldBaseTypes.number, SchemaFieldBaseTypes.string];
const SELECT_TYPES = [
    SchemaFieldBaseTypes.object,
    SchemaFieldBaseTypes.role,
    SchemaFieldBaseTypes.user,
    SchemaFieldAggregateTypes.enum,
    SchemaFieldAggregateTypes.list,
];

type NumberValidator = {
    from: number;
    to: number;
    precision: number;
    step: number;
};

type StringValidator = {
    format?: StringFormats;
    limit: number;
};

type Validator = NumberValidator | StringValidator;

// Function may be useful in further development
// const isStringValidator = (validator: Validator) => {
//     if (typeof validator !== 'object') {
//         return false;
//     }

//     if (
//         !validator.hasOwnProperty('format') &&
//         !validator.hasOwnProperty('limit')
//     ) {
//         return false;
//     }

//     return true;
// };

enum NumberValidatorDefaults {
    from = 0.0,
    to = 100.0,
    precision = 2,
    step = 1.0,
}

enum StringValidatorDefaults {
    format = StringFormats.string,
    limit = 500,
}

type SchemaMetaInfo = {
    title?: string;
    description?: string;
    author?: string;
    // TODO: create type UUID
    id?: string;
};

type SchemaFieldMetaInfo = {
    str?: string;
    description?: string;
    num?: number;
};

type SchemaFieldSubtype = {
    typename: SchemaFieldBaseTypes;
};

type SchemaFieldType = {
    typename: SchemaFieldBaseTypes | SchemaFieldAggregateTypes;
    validator?: Validator;
    subtype?: SchemaFieldSubtype;
    enumSet?: Array<string>;
};

type SchemaField = {
    __metainf__?: SchemaFieldMetaInfo;
    type: SchemaFieldType;
    required: boolean;
    default?: number | string;
};

type SchemaFields = Record<string, SchemaField>;
type SchemaImplFields = Record<string, number | string>;

type Schema = {
    __metainf__?: SchemaMetaInfo;
    version: string;
    fields: SchemaFields;
};

type SchemaImpl = {
    __metainf__?: SchemaMetaInfo;
    version: string;
    fields: SchemaImplFields;
};

export interface Validate<T extends number | string = number | string> {
    (value: T): boolean;
}

export interface Format<T extends number | string = number | string> {
    (value: T): T;
}

type SchemaFormField<T extends number | string = number | string> = {
    type: FormFieldTypes;
    valueType: SchemaFieldBaseTypes | SchemaFieldAggregateTypes;
    subtype?: SchemaFieldBaseTypes;
    stringFormat?: StringFormats;
    enumSet?: Array<string>;
    isRequired: boolean;
    defaultValue?: T;
    currentValue?: {
        value: T;
        valueOf: () => T;
        toString: () => string;
        toSchemaFormat: () => T;
    };
    name: string;
    changeValidate: Validate;
    saveValidate: Validate;
    format: Format;
    validatorProps: Validator;
    key: string;
};

type SchemaImplFormField<T extends number | string = number | string> = {
    name: string;
    value: T;
};

export type SchemaForm = {
    __metainf__?: SchemaMetaInfo;
    version: string;
    fields: SchemaFormField[];
    schemaId?: string;
    schemaImplId?: string;
    copy(): SchemaForm;
    filter(
        callback: (
            item: SchemaFormField,
            index: number,
            arr: SchemaFormField[]
        ) => boolean
    ): SchemaForm;
};

type SchemaImplForm = {
    __metainf__?: SchemaMetaInfo;
    version: string;
    fields: SchemaImplFormField[];
};

/**
 * Method formats the string, bringing it to the date format
 * @param {String} string
 * @returns {String} string in date format
 */
export const formatDateStringToSchemaFormat = (string: string) => {
    const [year, month, day] = string.split('-');
    return `${day}.${month}.${year}`;
};

export const formatDateStringToInputFormat = (string: string) => {
    const [day, month, year] = string.split('.');
    return `${year}-${month}-${day}`;
};

/**
 * Method formats the string, bringing it to the time format
 * @param {String} string
 * @returns {String} string in time format
 */
export const formatTimeString = (string: string) => {
    const hour = string.substring(0, 2);
    const minute = string.substring(2, 4);

    if (string.length < 3) {
        return `${hour}:`;
    } else {
        return `${hour}:${minute}`;
    }
};

/**
 * @callback ValidateFunction
 * @param {(Number | String)} value
 * @return {Boolean}
 */

/**
 * @callback FormatFunction
 * @param {(Number | String)} value
 * @return {(Number | String)}
 */

/**
 * @callback ValidateNumberFunction
 * @param {(Number)} value
 * @return {Boolean}
 */

/**
 * @callback FormatNumberFunction
 * @param {(Number)} value
 * @return {(Number)}
 */

/**
 * @callback ValidateStringFunction
 * @param {(String)} value
 * @return {Boolean}
 */

/**
 * @callback FormatStringFunction
 * @param {(String)} value
 * @return {(String)}
 */

/**
 * @typedef {{
 *  from: Number,
 *  to: Number,
 *  step: Number,
 *  precision: Number,
 * }} ValidatorProps
 */

/**
 * @typedef {{
 *  type: String,
 *  valueType: String,
 *  stringFormat: String,
 *  enumSet: String[] | undefined,
 *  isRequired: Boolean,
 *  defaultValue: Number | String,
 *  name: String,
 *  changeValidate: ValidateFunction,
 *  saveValidate: ValidateFunction,
 *  validatorProps: ValidatorProps,
 *  format: FormatFunction,
 *  key: String,
 * }} SchemaFormField
 */

/**
 * @typedef {(SchemaFormField & {currentValue: {
 *  value: String | Number,
 *  toString: Function,
 *  toSchemaFormat: Function,
 * }} & {copy: () => FormField[]})} FormField
 */

class SchemaSpecifier {
    schemaParser: SchemaParser;
    schemaSerializer: SchemaSerializer;

    constructor() {
        this.schemaParser = new SchemaParser();
        this.schemaSerializer = new SchemaSerializer();
    }

    parseSchema(schemaString: string) {
        return this.schemaParser.parseSchema(schemaString);
    }

    parseSchemaImpl(schemaImplString: string) {
        return this.schemaParser.parseSchemaImpl(schemaImplString);
    }

    parseSchemaAndSchemaImpl(schemaString: string, schemaImplString: string) {
        return this.schemaParser.parseSchemaAndSchemaImpl(
            schemaString,
            schemaImplString
        );
    }

    serializeSchema(schema: SchemaForm) {
        return this.schemaSerializer.serializeSchema(schema);
    }

    serializeSchemaImpl(schema: SchemaImplForm) {
        return this.schemaSerializer.serializeSchemaImpl(schema);
    }

    serializeSchemaAndSchemaImpl(schema: SchemaForm) {
        return this.schemaSerializer.serializeSchemaAndSchemaImpl(schema);
    }
}

/**
 * This class helps to parse schemas and schemas implementations
 * @class
 */
class SchemaParser {
    /**
     * Method that parses only one schema
     * @param {String} schemaString string representation of the schema obtained by a query
     * @returns {SchemaFormField[]} array of field object \
     * `type` — represents type of form field. There is special enum for that `FORM_FIELD_TYPES` \
     * `valueType` — represents type of inputting value. (It may be useless, if so 'TODO: delete field `valueType`') \
     * `enumSet` — represents all available values for select element. If field is input element, instead of select, this field will be undefined \
     * `isRequired` — represents if the field is obligatory or not. If schema has no field `required`, this field will be `undefined` \
     * `defaultValue` — represents default value of field \
     * `name` — represents the name of the field in Russian, if possible. Use this field to show the user the name of the field \
     * `validate` — this function checks the correctness of the value according to the schema validator. If value is correct, this function returns true, otherwise false \
     * `format` — this function formats the value, if necessary, according to the schema validator. Returns formatted value
     */
    parseSchema(schemaString: string) {
        const schemaObject: Schema = JSON.parse(schemaString);
        const schemaForm: SchemaForm = {
            __metainf__: schemaObject.__metainf__,
            version: schemaObject.version,
            fields: [],
            copy() {
                return this;
            },
            filter(callback) {
                return this;
            },
        };

        const schemaObjectEntries = Object.entries(schemaObject.fields);

        for (const [curFieldName, curFieldValue] of schemaObjectEntries) {
            let field: Partial<SchemaFormField> = {};
            /**
             * Define form field type
             */
            if (
                INPUT_TYPES.includes(
                    curFieldValue.type.typename as SchemaFieldBaseTypes
                )
            ) {
                field.type = FormFieldTypes.input;
            } else if (SELECT_TYPES.includes(curFieldValue.type.typename)) {
                field.type = FormFieldTypes.select;
            }

            /**
             * Define type for input or select
             */
            field.valueType = curFieldValue.type.typename;
            if (
                curFieldValue.type.typename === SchemaFieldAggregateTypes.enum
            ) {
                field.subtype = curFieldValue.type?.subtype?.typename;
                /**
                 * Define enum set, if field type is enum
                 */
                field.enumSet = curFieldValue.type.enumSet;
            } else if (
                curFieldValue.type.typename === SchemaFieldAggregateTypes.list
            ) {
                field.subtype = curFieldValue.type?.subtype?.typename;
            }

            field.isRequired = curFieldValue.required;
            field.defaultValue = curFieldValue.default;

            field.key = curFieldName;

            /**
             * Define name for form field
             */
            field.name = curFieldValue.__metainf__?.str ?? curFieldName;

            /**
             * Define validator and formatter functions
             */
            let changeValidate: Validate = (value) => true;
            let saveValidate: Validate = (value) => true;
            let format: Format = (value) => value;
            let stringFormat: StringFormats;

            switch (curFieldValue.type.typename) {
                case SchemaFieldBaseTypes.number: {
                    [
                        changeValidate as Validate<number>,
                        field.validatorProps,
                        format as Format<number>,
                    ] = this.parseNumberValidatorAndFormatter(curFieldValue);
                    break;
                }

                case SchemaFieldBaseTypes.string: {
                    stringFormat =
                        (curFieldValue.type.validator as StringValidator)
                            ?.format ?? StringFormats.string;
                    const limit =
                        (curFieldValue.type.validator as StringValidator)
                            .limit ?? StringValidatorDefaults.limit;

                    field.validatorProps = {
                        format: stringFormat,
                        limit: limit,
                    };
                    field.stringFormat = stringFormat;

                    [
                        changeValidate as Validate<string>,
                        saveValidate as Validate<string>,
                    ] = validatorFactory.createStringValidator(limit);
                    if (stringFormat === StringFormats.date) {
                        field.defaultValue = formatDateStringToInputFormat(
                            (field.defaultValue as string) ?? ''
                        );
                    }
                    break;
                }

                default:
                    break;
            }

            field.changeValidate = changeValidate;
            field.saveValidate = saveValidate;
            field.format = format;

            schemaForm.fields.push(field as SchemaFormField);
        }

        return schemaForm;
    }

    /**
     * Method that parses only one schema implementation
     * @param {String | null} schemaImplString string representation of the schema implementation obtained by a query
     * @returns {{
     *  name: String,
     *  value: Number | String
     * }[]} array of field object \
     * `name` — represents the name of the field. The name can be in English, so it's better to use the name from the schema rather than from schema implementation. \
     * `value` — represents the current value of the field
     */
    parseSchemaImpl(schemaImplString: string | null) {
        if (!schemaImplString) {
            return null;
        }
        const schemaImplObject: SchemaImpl = JSON.parse(schemaImplString);

        const schemaImplForm: SchemaImplForm = {
            __metainf__: schemaImplObject.__metainf__,
            version: schemaImplObject.version,
            fields: [],
        };

        const schemaImplObjectEntries = Object.entries(schemaImplObject);

        for (const [curFieldName, curFieldValue] of schemaImplObjectEntries) {
            schemaImplForm.fields.push({
                name: curFieldName,
                value: curFieldValue.toString(),
            });
        }

        return schemaImplForm;
    }

    /**
     * Method that parses one schema and its implementation
     * @param {String} schemaString string representation of the schema obtained by a query
     * @param {String | null} schemaImplString string representation of the schema implementation obtained by a query
     * @return {FormField[]} array of field object \
     * Object SchemaFormField is extended with `currentValue` that contains current saved value of the field
     */
    parseSchemaAndSchemaImpl(
        schemaString: string,
        schemaImplString: string | null
    ) {
        const schemaForm: SchemaForm = this.parseSchema(schemaString);
        const schemaImplForm: SchemaImplForm | null =
            this.parseSchemaImpl(schemaImplString);

        for (const schemaField of schemaForm.fields) {
            const value = schemaImplForm?.fields.find(
                (field) =>
                    field.name === schemaField.key ||
                    field.name === schemaField.name
            )?.value;

            if (schemaField.stringFormat === StringFormats.date) {
                schemaField.currentValue = {
                    value: formatDateStringToInputFormat(
                        (value as string) ?? ''
                    ),
                    toString() {
                        return this.value.toString();
                    },
                    valueOf() {
                        return this.value;
                    },
                    toSchemaFormat() {
                        const [year, month, day] = (this.value as string).split(
                            '-'
                        );
                        return `${day}.${month}.${year}`;
                    },
                };

                continue;
            } else if (schemaField.stringFormat === StringFormats.time) {
                schemaField.currentValue = {
                    value: formatDateStringToInputFormat(
                        (value as string) ?? ''
                    ),
                    toString() {
                        return this.value.toString();
                    },
                    valueOf() {
                        return this.value;
                    },
                    toSchemaFormat() {
                        return this.value;
                    },
                };

                continue;
            }

            if (schemaField.valueType === SchemaFieldBaseTypes.number) {
                schemaField.currentValue = {
                    value: value ? +value : 0,
                    toString() {
                        return this.value.toString();
                    },
                    valueOf() {
                        return +this.value;
                    },
                    toSchemaFormat() {
                        return this.valueOf();
                    },
                };
            } else {
                schemaField.currentValue = {
                    value: value ?? '',
                    toString() {
                        return this.value.toString();
                    },
                    valueOf() {
                        return this.value;
                    },
                    toSchemaFormat() {
                        return this.valueOf();
                    },
                };
            }
        }

        schemaForm.copy = function () {
            const copy: SchemaForm = {
                ...this,
                fields:
                    this.fields?.map((item: SchemaFormField) => {
                        const fieldCopy: SchemaFormField = {
                            ...item,
                        };

                        if (item.currentValue !== undefined) {
                            fieldCopy.currentValue = {
                                ...item.currentValue,
                            };
                        }

                        return fieldCopy;
                    }) ?? [],
            };
            return copy;
        };

        schemaForm.filter = function (callback) {
            const filtered: SchemaForm = this.copy();
            filtered.fields.filter(callback);
            return filtered;
        };

        return schemaForm;
    }

    parseNumberValidatorAndFormatter(
        field: SchemaField
    ): [Validate<number>, NumberValidator, Format<number>] {
        let from =
            (field.type.validator as NumberValidator)?.from ??
            NumberValidatorDefaults.from;
        let to =
            (field.type.validator as NumberValidator)?.to ??
            NumberValidatorDefaults.to;
        let precision =
            (field.type.validator as NumberValidator)?.precision ??
            NumberValidatorDefaults.precision;
        let step =
            (field.type.validator as NumberValidator)?.step ??
            NumberValidatorDefaults.step;

        const validate: Validate<number> = (value) => {
            return +value >= from && +value <= to;
        };

        const validatorProps: NumberValidator = {
            from: from,
            to: to,
            precision: precision,
            step: step,
        };

        const format: Format<number> = (value) => {
            return +(+value).toFixed(precision);
        };

        return [validate, validatorProps, format];
    }
}

class SchemaSerializer {
    /**
     * Method serializes schema into string
     * @param {SchemaFormField[]} schema
     */
    serializeSchema(schema: SchemaForm) {
        const serializableSchema: Schema = {
            __metainf__: schema.__metainf__,
            version: schema.version ?? DEFAULT_SCHEMA_VERSION,
            fields: {},
        };

        for (const field of schema.fields) {
            let defaultValue = field.defaultValue;

            if (field.stringFormat === StringFormats.date) {
                defaultValue = formatDateStringToSchemaFormat(
                    String(field.defaultValue)
                );
            }

            serializableSchema.fields[field.key] = {
                type: {
                    typename: field.valueType,
                },
                required: field.isRequired,
                default: defaultValue,
            };

            if (
                field.valueType === SchemaFieldAggregateTypes.enum ||
                field.valueType === SchemaFieldAggregateTypes.list
            ) {
                serializableSchema.fields[field.key].type.subtype = {
                    typename: field.subtype as SchemaFieldBaseTypes,
                };
            }

            if (field.valueType === SchemaFieldAggregateTypes.enum) {
                serializableSchema.fields[field.key].type.enumSet =
                    field.enumSet;
            }

            serializableSchema.fields[field.key].type.validator =
                field.validatorProps;

            if (
                (
                    serializableSchema.fields[field.key].type
                        .validator as StringValidator
                )?.format === StringFormats.string
            ) {
                delete (
                    serializableSchema.fields[field.key].type
                        .validator as StringValidator
                ).format;
            }

            if (field.name !== field.key) {
                serializableSchema.fields[field.key].__metainf__ = {
                    str: field.name,
                };
            }
        }

        return JSON.stringify(serializableSchema);
    }

    /**
     * Method serializes schema implementation into string
     * @param {{
     *  name: String,
     *  value: Number | String
     * }[]} schema
     */
    serializeSchemaImpl(schema: SchemaImplForm) {
        const serializableSchemaImpl: SchemaImpl = {
            __metainf__: schema.__metainf__,
            version: schema.version ?? DEFAULT_SCHEMA_VERSION,
            fields: {},
        };

        for (const field of schema.fields) {
            serializableSchemaImpl.fields[field.name] = field.value;
        }

        return JSON.stringify(serializableSchemaImpl.fields);
    }

    /**
     * Method serializes schema and its implementation into strings
     * @param {FormField[]} schema
     */
    serializeSchemaAndSchemaImpl(
        schema: SchemaForm
    ): [serializedSchema: string, serializedSchemaImpl: string] {
        const serializedSchema = this.serializeSchema(schema);
        const serializedSchemaImpl = this.serializeSchemaImpl({
            __metainf__: schema.__metainf__,
            version: schema.version,
            fields: schema.fields.map((item) => ({
                name: item.key,
                value:
                    item.currentValue?.toSchemaFormat() ??
                    item.defaultValue ??
                    '',
            })),
        });

        return [serializedSchema, serializedSchemaImpl];
    }
}

const schemaSpecifier = new SchemaSpecifier();

export default schemaSpecifier;

import React, { useRef, useState } from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import styled from 'styled-components';

import bottomArrowIcon from '../../../../../shared/assets/images/icons/ConstructorPage/TopBar/arrow-bottom-small.svg';
import addFloorIcon from '../../../../../shared/assets/images/icons/ConstructorPage/TopBar/add-floor-plus.svg';
import { useOutsideClick } from '../../../../../shared/model/hooks/UseOutsideClick';
import { FloorList } from './FloorsList';
import { CREATE_FLOOR } from '../../../../../shared/graphql/mutations/floors/CreateFloor';
import {
    currentFloorVar,
    currentProjectVar,
    floorsVar,
    modeVar,
} from '../../../../../shared/model/cache/Cache';
import { CREATING_MODE } from '../../../../../shared/lib/utils/ModeDefaultValues';
import { loadFloor } from '../../../model/LoadFloor';
import { loadMapObjects } from '../../../../../features/MapObject/model/LoadMapObjects';

const Wrapper = styled.div`
    min-width: 220px;
    @media (max-width: 1200px) {
        min-width: 110px;
    }
`;

const SelectContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: fit-content;
    max-width: 210px;
    :hover {
        cursor: pointer;
    }
`;

const SelectHeader = styled.div`
    display: flex;
    align-items: center;
    padding: 11px 0;

    background-color: #f2f5ff;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    position: relative;
    z-index: 2;

    border-bottom-left-radius: ${(props) => (props.isOpened ? '0px' : '13px')};
    border-bottom-right-radius: ${(props) => (props.isOpened ? '0px' : '13px')};

    & > * {
        z-index: 3;
    }

    ::before {
        content: '';
        border-radius: 13px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #e1e8ff;
    }
`;

const SelectBody = styled.ul`
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    background: #f2f5ff;
    border-radius: 14px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    height: ${(props) => (props.isOpened ? 'auto' : '0')};
    max-height: 400px;
    overflow-x: hidden;
    ::-webkit-scrollbar {
        display: none;
    }
    transition: height 2s ease-in;
    *:last-child {
        border-bottom: transparent;
    }

    z-index: 1;
`;

const FloorName = styled.strong`
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin-left: 14px;
    margin-right: 12px;
`;

const CollapseIcon = styled.img`
    margin-right: 14px;
`;

const AddFloorButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border: transparent;
    background: #f2f5ff;
    :hover {
        cursor: pointer;
    }
`;

const AddFloorTextNIconContainer = styled.div`
    margin: 12px 0;
    display: flex;
`;

const AddFloorIcon = styled.img`
    background-color: #b7c7fe80;
    width: 7px;
    height: 7px;
    flex-shrink: 0;
    padding: 4px;
    border-radius: 11px;
    margin-right: 5px;
`;

const AddFloorText = styled.div`
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
`;

/**
 * Component that represents floor selector
 *
 * @component
 */
export const FloorSelector = () => {
    const [isOpened, setIsOpened] = useState(false);

    const componentRef = useRef();

    const currentFloor = useReactiveVar(currentFloorVar);

    const [createFloor] = useMutation(CREATE_FLOOR);

    useOutsideClick(componentRef, () => {
        setIsOpened(false);
    });

    const isMoreThanOneFloorOrCreatingMode = () => {
        return floorsVar().length > 1 || modeVar() === CREATING_MODE;
    };

    const handleOpen = () => {
        if (isMoreThanOneFloorOrCreatingMode()) {
            setIsOpened(!isOpened);
        }
    };

    const handleFloorClick = async (floor) => {
        currentFloorVar(floor);
        setIsOpened(false);
        await loadFloor(currentFloorVar().id).then(() => {
            loadMapObjects(currentFloorVar().id);
        });
    };

    const handleFloorAdd = () => {
        createFloor({
            variables: {
                floorDraft: {
                    projectId: currentProjectVar().id,
                    num: floorsVar().length + 1,
                },
            },
        })
            .then((response) => {
                floorsVar([
                    ...floorsVar(),
                    {
                        id: response.data.createFloor.id,
                        description: response.data.createFloor.description,
                        number: response.data.createFloor.num,
                    },
                ]);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    return (
        <Wrapper>
            <SelectContainer ref={componentRef}>
                <SelectHeader isOpened={isOpened} onClick={handleOpen}>
                    <FloorName>{`${currentFloor.number} этаж`}</FloorName>
                    {isMoreThanOneFloorOrCreatingMode() && (
                        <CollapseIcon src={bottomArrowIcon} alt='развернуть' />
                    )}
                </SelectHeader>
                <SelectBody isOpened={isOpened}>
                    <FloorList onFloorClick={handleFloorClick} />
                    {modeVar() === CREATING_MODE && (
                        <AddFloorButton onClick={handleFloorAdd}>
                            <AddFloorTextNIconContainer>
                                <AddFloorIcon src={addFloorIcon} alt='' />
                                <AddFloorText>Добавить</AddFloorText>
                            </AddFloorTextNIconContainer>
                        </AddFloorButton>
                    )}
                </SelectBody>
            </SelectContainer>
        </Wrapper>
    );
};

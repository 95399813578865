import React from 'react';
import { WallContextMenu } from '../ui/ContextMenus/WallContextMenu';
import { WallCreationContextMenu } from '../ui/ContextMenus/WallCreationContextMenu';
import { openContextMenu } from './OpenContextMenu';

/**
 * Function that call opening context menu function for the current wall
 *
 * @param wallProps
 * @param {object} position position of mouse click
 * @param {number} position.x
 * @param {number} position.y
 */
export const openWallContextMenu = (wallProps, position) => {
    openContextMenu(position, <WallContextMenu edgeId={wallProps.edgeId} />);
};

/**
 * Function that call opening context menu function for wall tool while wall in creating state
 * @param {object} position position of mouse click
 * @param {number} position.x
 * @param {number} position.y
 */
export const openWallCreationContextMenu = (position) => {
    openContextMenu(position, <WallCreationContextMenu />);
};

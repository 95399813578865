import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { ItemsFolder } from './ItemsFolder/ItemsFolder';
import { projectGlobalTypesGroups } from '../../../../../../shared/model/cache/Cache';
import rightBarModerator from '../../../model/RightBarModerator';
import {
    BASIC_EMBEDDED_OBJECTS_FOLDER_NAME,
    BASIC_WALLS_FOLDER_NAME,
} from './Constants';
import { AdviceText, FoldersWrapper } from './Styles';
import { WallSettings } from './WallSettings/WallSettings';
import { HorizontalLine } from '../../Operation/TeleportSettings/Styles';

/** Function search items for wall folders in global types */
const findWallFolders = (...foldersNames) => {
    const newFolders = [];

    foldersNames.forEach((name) => {
        newFolders.push({
            name: name,
            items: projectGlobalTypesGroups()
                .get(name)
                ?.wallSegmentTypes.map((wallSegmentType) => {
                    return {
                        ...wallSegmentType,
                        typeName: 'WallSegmentType',
                    };
                }),
        });
    });

    return newFolders;
};

/**
 * Component that represents tab with walls for right bar
 * @component
 */
export const WallsTab = () => {
    useReactiveVar(rightBarModerator.openedFolders);
    const [wallFolders, setWallFolders] = useState([]);

    useEffect(() => {
        setWallFolders(
            findWallFolders(
                BASIC_WALLS_FOLDER_NAME,
                BASIC_EMBEDDED_OBJECTS_FOLDER_NAME
            )
        );
    }, []);

    return (
        <>
            <HorizontalLine />
            <AdviceText style={{ margin: '10px 0 10px 10px' }}>
                Параметры нового сегмента
            </AdviceText>
            <WallSettings />
            <HorizontalLine />
            <AdviceText style={{ margin: '10px 0 5px 10px' }}>
                Тип сегмента
            </AdviceText>
            <FoldersWrapper>
                {wallFolders.map((folder) => (
                    <ItemsFolder
                        folderName={folder.name}
                        items={folder.items}
                        key={folder.name}
                        folder={folder}
                    />
                ))}
            </FoldersWrapper>
        </>
    );
};

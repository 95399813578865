import { stageVar } from '../../../../../shared/model/cache/Cache';
import cursorModerator from '../../../../../shared/lib/utils/common/CursorModerator';

/**
 * Handler to change cursor style on default
 */
export const handleMouseLeave = () => {
    if (stageVar()) {
        cursorModerator.setDefault();
    }
};

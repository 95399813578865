import { gql } from '@apollo/client';

export const ATTENTION_MARKER_FRAGMENT = gql`
    fragment AttentionMarkerFragment on AttentionMarker {
        id
        message
        position {
            x
            y
        }
        type
    }
`;

/** Enum with different devices types */
export enum DEVICE_TYPES {
    PC,
    MOBILE,
}

/** Function to calculate current device type */
const getDeviceType = (): DEVICE_TYPES => {
    if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
            navigator.userAgent
        )
    ) {
        return DEVICE_TYPES.MOBILE;
    } else {
        return DEVICE_TYPES.PC;
    }
};

/**
 * Const that stores device type (PC/mobile).
 * Type calculated once.
 */
export const CURRENT_DEVICE_TYPE: DEVICE_TYPES = getDeviceType();

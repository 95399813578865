import { getRuleForCurrentUser } from "../../AccessGroup/lib/GetRuleForCurrentUser";

/**
 * @file Store data structure descriptions for routes
 * and methods to convert one route data structure to another
 */

/**
 * @typedef {import('../../Wall/api/WallSegmentsAPI').AccessRuleFromServer} AccessRuleFromServer
 * @typedef {import('./RouteGraphWrapper').RouteGraphWrapper} RouteGraphWrapper
 */

/**
 * Describes route data structure, used in project.
 * Store only necessary data
 * @typedef {{
 *  id: string?,
 *  name: string?,
 *  description: string?,
 *  version: number?,
 *  color: string?,
 *  transparency: number?,
 *  accessRule: string?,
 *  constraintGroupId: string?,
 * }} RouteData
 */

/**
 * Route data received from server
 * @typedef {{
 *  id: string?,
 *  name: string?,
 *  description: string?,
 *  version: number?,
 *  color: string?,
 *  transparency: number?,
 *  accessRules: AccessRuleFromServer[]?,
 *  constraintGroup: {id: string},
 * }} RouteDataFromServer
 */

/**
 * Route difference data
 * @typedef {{
 *  id: string?,
 *  name: string?,
 *  description: string?,
 *  version: number?,
 *  color: string?,
 *  transparency: number?,
 *  groupId: string?,
 * }} RouteDiff
 */

/**
 * Method to convert instance of route graph wrapper to route project structure
 * @param {RouteGraphWrapper} routeGW
 * @returns {RouteData}
 */
export const routeGraphWrapperToRouteData = (routeGW) => {
    return {
        id: routeGW.routeId,
        name: routeGW.name(),
        description: routeGW.description(),
        version: routeGW.version(),
        color: routeGW.color(),
        transparency: routeGW.transparency(),
        accessRule: routeGW.accessRule,
        constraintGroupId: routeGW.constraintGroupId,
    };
};

/**
 * Method to convert route data from server to route project structure
 * @param {RouteDataFromServer} dataFromServer
 * @returns {RouteData}
 */
export const routeDataFromServerToRouteData = (dataFromServer) => {
    return {
        id: dataFromServer.id,
        name: dataFromServer.name,
        description: dataFromServer.description,
        version: dataFromServer.version,
        color: dataFromServer.color,
        transparency: dataFromServer.transparency,
        accessRule: getRuleForCurrentUser(dataFromServer.accessRules),
        constraintGroupId: dataFromServer.constraintGroup?.id,
    };
};

/**
 * Method to convert route data to RouteDiff
 * @param {RouteData} routeData
 * @returns {RouteDiff}
 */
export const routeDataToRouteDiff = (routeData) => {
    return {
        id: routeData.id,
        name: routeData.name,
        description: routeData.description,
        version: routeData.version,
        color: routeData.color,
        transparency: routeData.transparency,
    };
};

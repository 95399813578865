import styled from 'styled-components';
import { HINT_TEXT_ALIGNMENT } from '../../../model/Constants';
import { getPaddingForTitle, getTextAlignment } from './StylesHelpers';
import { SCROLLBAR_STYLED } from '../../../../../shared/ui/ScrollBar';

interface NotificationTitleProps {
    titleColor: string,
    textAlignment: HINT_TEXT_ALIGNMENT
}

export const Title = styled.h4<NotificationTitleProps>`
    ${(props) => getPaddingForTitle(props.textAlignment)}
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: ${(props) => getTextAlignment(props.textAlignment)};
    color: ${(props) => props.titleColor};
`;

interface NotificationTextProps {
    textColor: string,
    textAlignment: HINT_TEXT_ALIGNMENT
}

export const Text = styled.p<NotificationTextProps>`
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    text-align: ${(props) => getTextAlignment(props.textAlignment)};
    color: ${(props) => props.textColor};

    overflow-y: auto;
    ${SCROLLBAR_STYLED}
`;

interface NotificationCloseImgProps {
    /** Is close icon need to hide */
    isHidable: boolean,

    /** Notification text alignment (need to set position of close icon) */
    textAlignment: HINT_TEXT_ALIGNMENT
}

export const CloseImg = styled.img<NotificationCloseImgProps>`
    position: absolute;
    top: 15px;
    ${(props) =>
        props.textAlignment === HINT_TEXT_ALIGNMENT.RIGHT
            ? 'left: 15px;'
            : 'right: 15px;'}

    width: 13px;
    height: auto;
    padding: 1px;

    opacity: 0.7;
    ${(props) => (props.isHidable ? 'visibility: hidden;' : '')}

    cursor: pointer;
`;

import { currentFloorVar, floorsVar } from '../../../shared/model/cache/Cache';

export const initializeFloors = (floorsData) => {
    floorsVar(
        floorsData.map((floor) => ({
            id: floor.id,
            name: floor.description,
            number: floor.num,
        }))
    );
    const startFloor = floorsVar().find((floor) => floor.number === 1);
    currentFloorVar({
        id: startFloor.id,
        number: startFloor.number,
        name: startFloor.description,
    });
};

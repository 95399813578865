import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { createPortal } from 'react-dom';
import closeIcon from '../../shared/assets/images/icons/close.svg';

const ModalBackground = styled.div<{ zIndex: number }>`
    position: fixed;
    left: 0;
    top: 0;

    z-index: ${(props) => props.zIndex};

    width: 100vw;
    height: 100vh;

    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    box-sizing: border-box;

    animation: modalBGOpen 0.1s linear forwards;

    &.hide {
        animation: modalBGClose 0.2s linear forwards;
    }

    @keyframes modalBGOpen {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @keyframes modalBGClose {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
`;

interface ModalContainerProps {
    zIndex: number;

    /** Needed for settings additional padding for close icon */
    isCloseIconShow: boolean;
}

const ModalContainer = styled.div<ModalContainerProps>`
    min-width: 100px;
    min-height: 50px;

    position: absolute;
    top: 50%;
    left: 50%;
    z-index: ${(props) => props.zIndex + 1};
    transform: scale(0) translate(-50%, -50%);
    transform-origin: top left;

    padding: ${(props) => (props.isCloseIconShow ? '20' : '10')}px 0 10px;
    border-radius: 15px;
    box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.07);
    background: #ffffff;
    box-sizing: border-box;

    animation: modalBodyOpen 0.1s 0.05s linear forwards;

    &.hide {
        animation: modalBodyHide 0.2s linear forwards;
    }

    @keyframes modalBodyOpen {
        from {
            transform: scale(0) translate(-50%, -50%);
        }
        to {
            transform: scale(1) translate(-50%, -50%);
        }
    }

    @keyframes modalBodyHide {
        from {
            transform: scale(1) translate(-50%, -50%);
        }
        to {
            transform: scale(0) translate(-50%, -50%);
        }
    }
`;

const CloseImg = styled.img`
    position: fixed;
    right: 15px;
    top: 15px;

    width: 12px;
    height: auto;
    padding: 1px;

    cursor: pointer;
`;

const MODAL_ROOT_ELEMENT = document.getElementById('modal') as HTMLElement;

interface ModalProps {
    isOpened?: boolean;
    onClose?: () => void;
    isCloseIconShow?: boolean;
    zIndex?: number;
    children?: React.ReactNode;
}

/**
 * Component represents modal
 * @component
 */
export const Modal: React.FC<ModalProps> = ({
    isOpened = true,
    onClose = () => {},
    isCloseIconShow = false,
    zIndex = 1000,
    children,
}) => {
    /** Var need to delay modal closing for playing close animation */
    const [isCloseAnimation, setIsCloseAnimation] = useState(false);
    const className = isCloseAnimation ? 'hide' : '';

    /** Flag var to prevent first close animation */
    const [isAlreadyShown, setIsAlreadyShown] = useState(isOpened);

    /** Local copy isOpened need to fix animations behavior */
    const [isOpenedLocal, setIsOpenedLocal] = useState(isOpened);

    const handleClose = () => {
        setIsOpenedLocal(false);
        setIsCloseAnimation(true);
        setTimeout(() => setIsCloseAnimation(false), 200);
        if (onClose) {
            onClose();
        }
    };

    // use effect needed to add animation on modal open/close
    useEffect(() => {
        if (isOpened) {
            setIsOpenedLocal(true);
        } else {
            if (isAlreadyShown) {
                handleClose();
            } else {
                setIsAlreadyShown(true);
            }
        }
        // eslint-disable-next-line
    }, [isOpened]);

    if (!(isOpenedLocal || isCloseAnimation)) {
        return null;
    }

    return createPortal(
        <>
            <ModalBackground
                onClick={handleClose}
                zIndex={zIndex}
                className={className}
            />
            <ModalContainer
                zIndex={zIndex}
                isCloseIconShow={isCloseIconShow}
                className={className}
            >
                {children}
                {isCloseIconShow && (
                    <CloseImg
                        src={closeIcon}
                        alt='close'
                        onClick={handleClose}
                    />
                )}
            </ModalContainer>
        </>,
        MODAL_ROOT_ELEMENT
    );
};

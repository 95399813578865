import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";

/**
 * Styled title text
 */
const Text = styled.p`
    margin: 0;
    width: 260px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: -0.02em;

    color: #000000;
`;

/**
 * Component to show title
 * @param { children } param0 Title name
 * @param { ...props } param1 Attributes for tag p
 * @returns { Text } Component with title
 */
export const Title = ({ children, ...props }) => {
    return <Text {...props}>{children}</Text>;
};

Title.propTypes = {
    children: PropTypes.string,
    props: PropTypes.arrayOf(PropTypes.object),
};

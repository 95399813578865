import routesModerator from '../model/RoutesModerator';
import { routeDataFromServerToRouteData } from '../model/RouteAdapter';
import { OPERATION } from '../../Subscriptions/api/SubscriptionsModerator';
import { getRuleForCurrentUser } from '../../AccessGroup/lib/GetRuleForCurrentUser';

/**
 * @typedef {import('../model/RouteAdapter').RouteDataFromServer} RouteDataFromServer
 */

/**
 * Function to handle route data received from subscription
 * @param {string} operation operation type (create, delete, update)
 * @param {RouteDataFromServer} serverData route data from server
 */
export const handleRouteSubscription = (operation, serverData) => {
    const routeData = routeDataFromServerToRouteData(serverData);
    if (operation === OPERATION.CREATE) {
        routesModerator.addRoute(
            routeData.constraintGroupId,
            routeData.id,
            routeData
        );

        // handle segments
        serverData.segments?.forEach((segment) => {
            segment.route = { id: routeData.id };
            segment.accessRules = serverData.accessRules;
            handleRouteSegmentSubscription(operation, segment);
        });
    } else if (operation === OPERATION.DELETE) {
        routesModerator.removeRouteFromManager(routeData.id);
    } else if (operation === OPERATION.UPDATE) {
        const route = routesModerator.getRoute(routeData.id);
        route?.updateRouteProperties(routeData);
    }
};

/**
 * Function to handle route data received from subscription
 * @param {string} operation operation type (create, delete, update)
 * @param {RouteDataFromServer} serverData route data from server
 */
export const handleRouteSegmentSubscription = (operation, serverData) => {
    const route = routesModerator.getRoute(serverData.route.id);
    const graph = route.graph;
    if (operation === OPERATION.CREATE) {
        const accessRule = getRuleForCurrentUser(serverData.accessRules);
        const startPoint = serverData.startPoint;
        const endPoint = serverData.endPoint;
        graph.addVertexToGraph(startPoint.id, {
            coordinates: {
                x: startPoint.coordinate.x,
                y: startPoint.coordinate.y,
            },
            adjacentPoints: [],
            accessRule: accessRule,
        });
        graph.addVertexToGraph(endPoint.id, {
            coordinates: {
                x: endPoint.coordinate.x,
                y: endPoint.coordinate.y,
            },
            adjacentPoints: [],
            accessRule: accessRule,
        });
        graph.addSegment(
            { key: startPoint.id },
            { key: endPoint.id },
            {
                isFetched: true,
                accessRule: accessRule,
                version: serverData.version,
            },
            true
        );
        graph.rerender();
    } else if (operation === OPERATION.UPDATE) {
        const startPoint = serverData.startPoint;
        const endPoint = serverData.endPoint;
        graph.updateVertex(startPoint.id, startPoint.coordinate);
        graph.updateVertex(endPoint.id, endPoint.coordinate);
        graph.segmentsModerator.updateVersion(
            startPoint.id,
            endPoint.id,
            serverData.version
        );
        graph.rerender();
    }
};

import { DocumentNode, gql } from '@apollo/client';
import { ACCESS_RULE_FRAGMENT } from '../../fragments/accessRules/AccessRuleFragment';

/** Mutation to update object access rule */
export const UPDATE_ACCESS: DocumentNode = gql`
    ${ACCESS_RULE_FRAGMENT}
    mutation giveAccess(
        $type: SecuredObjectType!,
        $ruleSelector: AccessRuleSelector!,
        $access: Access!
    ) {
        updateAccess(type: $type, ruleSelector: $ruleSelector, access: $access) {
            ...AccessRuleFragment
        }
    }
`;

import React, { useEffect } from 'react';

import { FormContainer } from '../../shared/ui/FormContainer';
import { ExtraLinks } from '../../shared/ui/ExtraLinks';

import { RegistrationForm } from './ui/RegistrationForm';

import { loginPage, registrationPageTitle } from '../../shared/model/routes/staticRoutes';

/**
 * Registration component
 * @returns { RegistrationContainer } Centralized registration component
 */
export const Registration = () => {
    const extraLinksItems = [{
        text: "Уже есть аккаунт?",
        name: "Войти",
        to: loginPage
    }];

    useEffect(() => {
        document.title = registrationPageTitle;
    }, []);

    return (
        <FormContainer title="Регистрация" style={{ margin: '38px 0 38px' }}>
            <RegistrationForm />
            <ExtraLinks items={extraLinksItems} style={{ marginBottom: "20px" }}/>
        </FormContainer>
    );
};

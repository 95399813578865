import { client } from '../../../shared/graphql/ApolloClient';
import { GET_FLOOR } from '../../../shared/graphql/queries/floors/GetFloor';

export const getFloor = async (floorId) => {
    return client.query({
        query: GET_FLOOR,
        variables: {
            id: floorId,
        },
        fetchPolicy: 'network-only',
    });
};

import React, { useState } from 'react';
import styled from 'styled-components';
import { FormComponent } from './FormComponentWithHint';
import { InputStyled } from './Input';
import { inputStatus } from './FormConstants';

import EyeClosed from '../../assets/images/icons/eye-closed.svg';
import EyeOpened from '../../assets/images/icons/eye-opened.svg';

/**
 * Styled eye img
 * Is needed to change the type of password
 */
const EyeImg = styled.img`
    width: 33px;
    height: 21px;
    cursor: pointer;
`;

/**
 * Styled password input div
 * Needed for Input and Eye Img
 */
const PasswordInputStyled = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);

    & input {
        min-width: 289px;
    }

    &:has(input:focus) {
        outline: none;
        border-color: #023aff;
    }

    &:has(input.success) {
        border-color: #00940f;
    }

    &:has(input.error) {
        border-color: red;
    }

    &:has(input.warning) {
        border-color: yellow;
    }
`;

const passwordInputStyle = {
    border: 0,
    margin: 0,
};

/**
 * PasswordInput form component with validator message
 * @returns { JSX.Element } PasswordInput component for forms
 */
export const PasswordInput = ({
    name,
    value,
    placeholder,
    onChange,
    onFocus,
    forceHint = false,
    hintMsgType = inputStatus.hint,
    hintMsg,
    className,
    ...props
}) => {
    // component don't show message if it's not touched
    const [isTouched, setIsTouched] = useState(false);
    const [inputType, setInputType] = useState('password');

    let inputClassName = ''; // needed to specify input border color
    if ((isTouched || forceHint) && hintMsg)
        inputClassName =
            hintMsgType === inputStatus.error ||
            hintMsgType === inputStatus.warning ||
            hintMsgType === inputStatus.success
                ? hintMsgType
                : inputStatus.hint;

    /**
     * Change password type and eye img handler
     */
    const changePasswordTypeHandler = () => {
        if (inputType === 'password') {
            setInputType('text');
        } else {
            setInputType('password');
        }
    };

    /**
     * Input focus handler
     */
    const focusHandler = (e) => {
        if (!isTouched) setIsTouched(true);
        if (onFocus) onFocus(e);
    };

    return (
        <FormComponent
            hintMsgType={inputClassName}
            hintMsg={(isTouched || forceHint) && hintMsg}
            className={className}
        >
            <PasswordInputStyled>
                <InputStyled
                    name={name}
                    value={value}
                    type={inputType}
                    onChange={onChange}
                    onFocus={(e) => focusHandler(e)}
                    placeholder={placeholder}
                    style={passwordInputStyle}
                    className={inputClassName}
                    {...props}
                ></InputStyled>
                {value && (
                    <EyeImg
                        src={inputType === 'password' ? EyeClosed : EyeOpened}
                        onClick={() => changePasswordTypeHandler()}
                        alt='change password type'
                    />
                )}
            </PasswordInputStyled>
        </FormComponent>
    );
};

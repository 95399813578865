/*
 * Constants for graphQL operation
 */
export const LOG_IN_OPERATION: string = 'LogIn';
export const CREATE_SESSION_OPERATION: string = 'CreateSession';

/*
 * GraphQL errors
 */
export const INVALID_SESSION_TOKEN_CODE: string = 'session_token_prohibited';
export const INVALID_REFRESH_TOKEN_CODE: string = 'refresh_token_prohibited';

import React from 'react';
import styled from 'styled-components';
import { MascotContainer } from './Loader';
import mascotError from '../assets/images/icons/MainPage/mascotError.svg';
import {
    H2OpenSansSemiBold,
    H3OpenSansRegular,
    POpenSansRegular,
} from './TextStyles';

const MascotError = styled.img`
    margin-bottom: 15px;
    width: 100px;
    height: auto;
`;

const ErrorTextContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 50%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const HelpText = 'Обратитесь в службу поддержки: ';

/**
 * @returns {ErrorMessage} Component with error message
 */
const ErrorMessage = ({ errorMessage }) => {
    return (
        <MascotContainer>
            <MascotError src={mascotError} alt='mascot error' />
            <ErrorTextContainer>
                <H2OpenSansSemiBold>
                    Oops! Something went wrong
                </H2OpenSansSemiBold>
                <POpenSansRegular>
                    {HelpText}
                    <a href='mailto:support@mapins.cloud'>
                        support@mapins.cloud
                    </a>
                </POpenSansRegular>
                <br />
                <H3OpenSansRegular>{errorMessage}</H3OpenSansRegular>
            </ErrorTextContainer>
        </MascotContainer>
    );
};

export default ErrorMessage;

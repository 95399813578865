import { gql } from '@apollo/client';

/**
 * Mutation for deleting zone by its id
 */
export const MERGE_ZONE = gql`
    mutation mergeZone(
        $zoneSelector: VersionedZoneSelectorDto!
        $inheritableZoneSelector: VersionedZoneSelectorDto!
    ) {
        mergeZone(
            zoneSelector: $zoneSelector
            inheritableZoneSelector: $inheritableZoneSelector
        ) {
            updatedZone {
                color
                id
                version
                name
                global
                accessRules {
                    group {
                        id
                        name
                    }
                    rule
                }
                floor {
                    id
                }
                wall {
                    graph {
                        key
                    }
                }
            }
        }
    }
`;

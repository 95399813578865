import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Html } from 'react-konva-utils';
import { useReactiveVar } from '@apollo/client';

import {
    currentFloorVar,
    modeVar,
    stageScaleVar,
} from '../../../shared/model/cache/Cache';
import sendIcon from '../../../shared/assets/images/icons/send-button.svg';
import emojiIcon from '../../../shared/assets/images/icons/emoji.svg';
import yellowInfo from '../../../shared/assets/images/icons/yellow-info-warning.svg';
import info from '../../../shared/assets/images/icons/info-warning.svg';
import criticalInfo from '../../../shared/assets/images/icons/critical-warning.svg';
import closeIcon from '../../../shared/assets/images/icons/close.svg';
import binIcon from '../../../shared/assets/images/icons/MainPage/bin.svg';
import loaderImg from '../../../shared/assets/images/icons/preloader.svg';
import { useOutsideClick } from '../../../shared/model/hooks/UseOutsideClick';
import { POpenSansRegular } from '../../../shared/ui/TextStyles';
import { SCROLLBAR_STYLED } from '../../../shared/ui/ScrollBar';
import { AlertTypes, CurrentAlertData } from '../model/Types';
import alertsModerator from '../model/AlertsModerator';
import { alertTypeToColor } from '../lib/utils/AlertTypeToColor';
import { CREATING_MODE } from '../../../shared/lib/utils/ModeDefaultValues';
import { ConfirmDeleteModal } from './ConfirmDeleteModal';

interface AlertContainerProps {
    value: string;
    isEdit: boolean;
    type: AlertTypes;
    bgColor: string;
    bottom: number;
    left: number;
    scale: number;
}

const Container = styled.div<AlertContainerProps>`
    display: flex;
    flex-direction: ${(props) => (props.value ? 'column' : 'row')};
    align-items: ${(props) => (props.isEdit ? 'center' : 'flex-start')};
    padding: ${(props) =>
        props.value && props.isEdit
            ? '16px 12px 20px 12px'
            : !props.value && props.isEdit
            ? '16px 18px 20px 12px'
            : '10px 10px 15px 17px'};
    border: 0.5px solid
        ${(props) =>
            props.type === AlertTypes.CRITICAL ? '#d87c7c' : '#9e9e9e'};
    border-radius: 14px 14px 14px 0;
    box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.07);
    background-color: ${(props) => props.bgColor};
    color: ${(props) =>
        props.type === AlertTypes.CRITICAL ? '#ff0000' : '#000000'};
    position: absolute;
    bottom: ${(props) => props.bottom + 'px'};
    left: ${(props) => props.left + 'px'};
    transform: scale(${(props) => 1 / props.scale});
    transform-origin: bottom left;
    width: 246px;
    max-height: 300px;
    cursor: default;
`;

const TextArea = styled.textarea`
    border: 0.5px solid #c7c7c7;
    border-radius: 8px;
    width: 234px;
    min-height: 20px;
    max-height: 100px;
    padding: 7px 6px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 20px;
    opacity: 0.5;
    resize: none;
    flex-grow: 2;
    outline: none;
    overflow: auto;
    ${SCROLLBAR_STYLED}
`;

const ScrollableContainer = styled.div`
    width: 100%;
    height: 100%;
    word-wrap: break-word;
    overflow: auto;
    ${SCROLLBAR_STYLED}
`;

const Text = styled(POpenSansRegular)`
    font-size: 15px;
    padding-right: 7px;
    line-height: 20px;
    padding-bottom: 8px;
`;

const ButtonsBlock = styled.div<{ isValue: boolean }>`
    display: flex;
    justify-content: space-between;
    margin: ${(props) => (props.isValue ? '10px 0 0 0' : '0 0 0 -28px')};
    z-index: 11;
    width: ${(props) => (props.isValue ? '246px' : '22px')};
`;

interface ButtonProps {
    img: string;
    isVisible: boolean;
}

const Button = styled.button<ButtonProps>`
    background-image: url(${(props) => props.img});
    background-color: transparent;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    display: ${(props) => (props.isVisible ? 'block' : 'none')};
    width: 22px;
    height: 22px;
    border: none;
    cursor: pointer;
`;

/**
 * Component represent extended alert.
 * Used for view full alert text or create new alert.
 * @component
 */
export const ExtendedAlert: React.FC = () => {
    const alert = useReactiveVar(
        alertsModerator.currentAlertVar
    ) as CurrentAlertData;
    const scaleVar: number = useReactiveVar(stageScaleVar);
    const textAreaRef: React.RefObject<HTMLTextAreaElement> = useRef(null);
    const alertRef: React.RefObject<HTMLDivElement> = useRef(null);

    const [value, setValue] = useState(alert?.text);
    const [color, setColor] = useState(
        alertTypeToColor(alert?.type || AlertTypes.INFO)
    );
    const [type, setType] = useState(alert?.type || AlertTypes.INFO);
    const [isEdit] = useState(alert?.isEdit);
    const [isCreating, setIsCreating] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useOutsideClick(alertRef, () => {
        if (!isModalOpen) alertsModerator.closeAlert();
    });

    if (alert === null) {
        return null;
    }

    const handleInput = (e: any) => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = 'auto';
            textAreaRef.current.style.height = `${
                e.target.scrollHeight - 16
            }px`;
        }

        setValue(e.target.value);
    };

    const handleChangeType = (type: AlertTypes) => {
        setType(type);
        setColor(alertTypeToColor(type));
    };

    const handleSend = async () => {
        if (value) {
            setIsCreating(true);
            await alertsModerator.createAlert({
                type: type,
                position: alert.position,
                message: value,
                floorId: currentFloorVar().id,
            });
            setIsCreating(false);
        }
        alertsModerator.closeAlert();
    };

    const handleDeleteAlert = () => {
        setIsDeleting(true);
        alertsModerator.deleteAlert(alert.id).then(() => {
            alertsModerator.closeAlert();
            setIsDeleting(false);
        });
    };

    return (
        <Html>
            <Container
                scale={scaleVar}
                ref={alertRef}
                bgColor={color}
                value={value}
                isEdit={isEdit}
                bottom={-alert.position.y}
                left={alert.position.x}
                type={alert.type}
            >
                {isEdit ? (
                    <>
                        <TextArea
                            placeholder='Добавьте оповещение'
                            value={value}
                            rows={1}
                            onInput={handleInput}
                            ref={textAreaRef}
                        />
                        <ButtonsBlock isValue={!!value}>
                            <div style={{ display: 'flex', gap: '6px' }}>
                                <Button
                                    onClick={() =>
                                        handleChangeType(AlertTypes.INFO)
                                    }
                                    img={info}
                                    isVisible={!!value}
                                />
                                <Button
                                    onClick={() =>
                                        handleChangeType(AlertTypes.WARNING)
                                    }
                                    img={yellowInfo}
                                    isVisible={!!value}
                                />
                                <Button
                                    onClick={() =>
                                        handleChangeType(AlertTypes.CRITICAL)
                                    }
                                    img={criticalInfo}
                                    isVisible={!!value}
                                />
                            </div>
                            <div style={{ display: 'flex', gap: '6px' }}>
                                <Button img={emojiIcon} isVisible={!!value} />
                                <Button
                                    onClick={handleSend}
                                    img={isCreating ? loaderImg : sendIcon}
                                    isVisible={true}
                                />
                            </div>
                        </ButtonsBlock>
                    </>
                ) : (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                marginBottom: '3px',
                                gap: '6px',
                                width: '100%',
                            }}
                        >
                            <Button
                                onClick={() => setIsModalOpen(true)}
                                title='Удалить'
                                img={isDeleting ? loaderImg : binIcon}
                                style={{ width: '12px', height: '14px' }}
                                isVisible={modeVar() === CREATING_MODE}
                            />
                            <Button
                                onClick={() => alertsModerator.closeAlert()}
                                title='Закрыть'
                                img={closeIcon}
                                style={{
                                    width: '10px',
                                    height: '10px',
                                    paddingTop: '2px',
                                }}
                                isVisible={true}
                            />
                        </div>
                        <ScrollableContainer>
                            <Text>{value}</Text>
                        </ScrollableContainer>
                        <ConfirmDeleteModal
                            isOpened={isModalOpen}
                            onConfirm={handleDeleteAlert}
                            onClose={() => setIsModalOpen(false)}
                            isDeleting={isDeleting}
                        />
                    </>
                )}
            </Container>
        </Html>
    );
};

import { gql } from '@apollo/client';

/**
 * Mutation for creating schema impl
 */
export const CREATE_SCHEMA_IMPL = gql`
    mutation createMapinsSchemaImpl($schemaImplDraft: SchemaImplDraftDtoInput!) {
        createMapinsSchemaImpl(schemaImplDraft: $schemaImplDraft) {
            id
            schema {
                id
                schema
                version
            }
            data
            itemId
            version
        }
    }
`;

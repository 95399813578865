import { useReactiveVar } from '@apollo/client';
import React from 'react';

import Zones from '../../../../features/Zone/model/Zones';
import { ZoneInfo } from './ZoneInfo/ZoneInfo';
import { ListWrapper } from '../ui/Styles';
import { OpeningGroup } from '../ui/OpeningGroup/OpeningGroup';

/**
 * Component that represents list of current canvas zones
 *
 * @component
 */
export const ZoneList = () => {
    const canvasZones = useReactiveVar(Zones.zonesVar);

    return (
        <ListWrapper>
            <OpeningGroup title={'Зоны'} isEditable={false} isOpen={true}>
                {Array.from(canvasZones).map(([zoneId]) => (
                    <ZoneInfo zoneId={zoneId} key={zoneId}></ZoneInfo>
                ))}
            </OpeningGroup>
        </ListWrapper>
    );
};

import { makeVar } from '@apollo/client';
import { stageVar } from '../../../shared/model/cache/Cache';
import {
    expandLine,
    getLeftOrLowerLinePoint,
    getLineNormalByDistance,
    getRightOrUpperLinePoint,
    moveLine,
} from '../../../shared/lib/modules/math/Math';
import FloorGraph from '../../Wall/model/FloorGraph';
import { findAttractionPoint } from '../../Wall/lib/helpers/WallPointAttraction';

export const RULER_TYPE = {
    length_only: 'length_only',
    length_angle: 'length_angle',
};

export const MEASURE_TYPE = {
    any: 'any',
    vertical: 'vertical',
    horizontal: 'horizontal',
};

/**
 * Class that represents ruler tool store
 * @class
 */
class Ruler {
    DEFAULT_MARGIN = 38;
    DEFAULT_BORDERS_LENGTH = 43;
    DEFAULT_BORDERS_PADDING = 5;
    DEFAULT_COLOR = '#00000080';
    DEFAULT_WIDTH = 2;
    DEFAULT_TEXT_FONT_SIZE = 14;

    constructor() {
        this.currentPoint = null;
        this.type = null;
        this.measureType = MEASURE_TYPE.any;
        this.rulerWidth = 0;
        this.rulerHeight = 0;

        this.currentRulerVar = makeVar(null);
    }

    setMeasureType(measureType) {
        this.measureType = measureType;
    }

    /**
     * Method sets the current point of ruler
     * If ruler does not exists, current point is first point of future ruler
     * If ruler already exists, current point is dynamic point for angle measurement
     * @param {Object} point point
     * @param {Number} point.x x coordinate
     * @param {Number} point.y y coordinate
     */
    setCurrentPoint(point) {
        if (!this.currentPoint) {
            this.currentPoint = { ...point };
        }
    }

    selectCurrentPointFromStage() {
        if (!this.isCurrentPointSet()) {
            this.setCurrentPoint(stageVar().getRelativePointerPosition());
        } else {
            const point = stageVar().getRelativePointerPosition();
            if (this.measureType === MEASURE_TYPE.any) {
                this.createRuler(point);
            } else if (this.measureType === MEASURE_TYPE.horizontal) {
                this.createRuler({
                    ...point,
                    y: this.currentPoint.y,
                });
            } else if (this.measureType === MEASURE_TYPE.vertical) {
                this.createRuler({
                    ...point,
                    x: this.currentPoint.x,
                });
            }
        }
    }

    /**
     * Method selects current point from vertex
     * @param {Number} vertexX
     * @param {Number} vertexY
     */
    selectCurrentPointFromVertex(vertexX, vertexY) {
        if (!this.isCurrentPointSet()) {
            this.setCurrentPoint({
                x: vertexX,
                y: vertexY,
            });
        } else {
            if (this.measureType === MEASURE_TYPE.any) {
                this.createRuler({
                    x: vertexX,
                    y: vertexY,
                });
            } else if (this.measureType === MEASURE_TYPE.horizontal) {
                this.createRuler({
                    x: vertexX,
                    y: this.currentPoint.y,
                });
            } else if (this.measureType === MEASURE_TYPE.vertical) {
                this.createRuler({
                    x: this.currentPoint.x,
                    y: vertexY,
                });
            }
        }
    }

    /**
     * Method selects current point from edge via its points array ([x1, y1, x2, y2])
     * @param {Number[]} points
     */
    selectCurrentPointFromEdge(points) {
        if (!this.isCurrentPointSet()) {
            this.setCurrentPoint(
                findAttractionPoint(
                    [
                        { x: points[0], y: points[1] },
                        { x: points[2], y: points[3] },
                    ],
                    stageVar().getRelativePointerPosition(),
                    FloorGraph.currentWallSegmentTypeVar().width * 2,
                    null
                )
            );
        } else {
            const attractionPoint = findAttractionPoint(
                [
                    { x: points[0], y: points[1] },
                    { x: points[2], y: points[3] },
                ],
                stageVar().getRelativePointerPosition(),
                FloorGraph.currentWallSegmentTypeVar().width * 2,
                null
            );
            if (this.measureType === MEASURE_TYPE.any) {
                this.createRuler(attractionPoint);
            } else if (this.measureType === MEASURE_TYPE.horizontal) {
                this.createRuler({
                    ...attractionPoint,
                    y: this.currentPoint.y,
                });
            } else if (this.measureType === MEASURE_TYPE.vertical) {
                this.createRuler({
                    ...attractionPoint,
                    x: this.currentPoint.x,
                });
            }
        }
    }

    /**
     * Method for creating ruler in variable this.currentRuler
     * @param {Object} point point
     * @param {Number} point.x x coordinate
     * @param {Number} point.y y coordinate
     */
    createRuler(point) {
        if (this.isCurrentPointSet()) {
            this.type = RULER_TYPE.length_only;
            this.rulerWidth = Math.abs(this.currentPoint.x - point.x);
            this.rulerHeight = Math.abs(this.currentPoint.y - point.y);
            this.currentRulerVar({
                start: { ...this.currentPoint },
                end: { ...point },
            });
            this.currentPoint = null;
        }
    }

    /**
     * Method for getting ruler length
     * @returns {Number} measured length
     */
    getLength() {
        return Math.sqrt(this.rulerWidth ** 2 + this.rulerHeight ** 2);
    }

    enableAngleMeasurement() {
        this.type = RULER_TYPE.length_angle;
        this.currentRulerVar({ ...this.currentRulerVar(), anglePoint: null });
    }

    /**
     * Method for setting angle (third) point
     * @param {Object} point point
     * @param {Number} point.x x coordinate
     * @param {Number} point.y y coordinate
     */
    setAnglePoint(point) {
        if (this.type === RULER_TYPE.length_angle) {
            this.currentRulerVar({
                ...this.currentRulerVar(),
                anglePoint: { ...point },
            });
        }
    }

    /**
     * Method for getting array with coordinates of ruler line
     * @returns {Number[]} return coordinates array for ruler line [x1, y1, x2, y2]
     */
    getRulerPoints() {
        if (this.createRuler) {
            return [
                ...Object.values(this.currentRulerVar().start),
                ...Object.values(this.currentRulerVar().end),
            ];
        }
    }

    /**
     * Method for getting ruler line lineString
     * @returns {Object[]} ruler line lineString
     */
    getLengthRulerLineString() {
        return [
            { ...this.currentRulerVar().start },
            { ...this.currentRulerVar().end },
        ];
    }

    /**
     * Method for checking if ruler exists of not
     * @returns {Boolean} true if ruler already created with method createRuler
     */
    isRulerExists() {
        return this.currentRulerVar() !== null;
    }

    /**
     * Method for checking if first point of ruler set or not
     * @returns {Boolean} true if first point of ruler already set
     */
    isCurrentPointSet() {
        return !!this.currentPoint;
    }

    /**
     * Method for getting borders of ruler
     * @returns {{
     *  firstBorderLine: {x: Number, y: Number}[],
     *  secondBorderLine: {x: Number, y: Number}[],
     * }} borders
     */
    getBordersLines() {
        if (this.isRulerExists) {
            const rulerLine = this.getLengthRulerLineString();
            const firstBorder = expandLine(
                getLineNormalByDistance(
                    rulerLine,
                    this.DEFAULT_BORDERS_PADDING
                ),
                this.DEFAULT_BORDERS_LENGTH
            );
            const leftOrLowerRulerPoint = getLeftOrLowerLinePoint(rulerLine);
            const rightOrUpperRulerPoint = getRightOrUpperLinePoint(rulerLine);
            return {
                firstBorderLine: firstBorder,
                secondBorderLine: moveLine(
                    firstBorder,
                    -this.rulerWidth *
                        Math.sign(
                            leftOrLowerRulerPoint.x - rightOrUpperRulerPoint.x
                        ),
                    this.rulerHeight *
                        Math.sign(
                            leftOrLowerRulerPoint.y - rightOrUpperRulerPoint.y
                        )
                ),
            };
        }
        return null;
    }

    /**
     * Method for getting lineString of ruler line, borders and text position
     * @returns {Object[]} array with lineStrings of rulerLine, borders and textPosition
     */
    getBorderedLengthRulerLineStrings() {
        let rulerLine = this.getLengthRulerLineString();
        const borders = this.getBordersLines();
        const dx =
            getLeftOrLowerLinePoint(rulerLine).x - borders.firstBorderLine[1].x;
        const dy =
            borders.firstBorderLine[1].y - getLeftOrLowerLinePoint(rulerLine).y;
        rulerLine = moveLine(rulerLine, dx, dy);
        borders.firstBorderLine = moveLine(borders.firstBorderLine, dx, dy);
        borders.secondBorderLine = moveLine(borders.secondBorderLine, dx, dy);

        let textPosition = getLeftOrLowerLinePoint(rulerLine);
        if (borders.firstBorderLine[1].y < textPosition.y) {
            textPosition = borders.firstBorderLine[1];
        }

        if (borders.firstBorderLine[1].y === textPosition.y) {
            if (borders.firstBorderLine[1].x < textPosition.x) {
                textPosition = borders.firstBorderLine[1];
            }
        }

        return [rulerLine, borders, textPosition];
    }

    deleteRuler() {
        this.currentPoint = null;
        this.type = null;
        this.rulerWidth = 0;
        this.rulerHeight = 0;
        this.currentRulerVar(null);
    }
}

const ruler = new Ruler();
export default ruler;

import selector from '../../../../Selector/model/Selector';
import zones from '../../../../Zone/model/Zones';

/**
 * Handler updating wall segments positions locally
 * @param {Object} e
 */
export const handleDragMove = (e) => {
    const newPosition = { ...e.target.position() };

    // all selected edges grouped by graph
    const graphs = selector.tempMapOfEdgesGroupedByGraphs;

    // call update methods for all graphs
    graphs.forEach((edgesIds, graph) => {
        graph.updateWallSegments(newPosition, edgesIds);
        graph.rerender();
        selector.updateLastSelectedSegment();
    });

    zones.rerender();
};

import { gql } from '@apollo/client';
import { ACCESS_RULE_FRAGMENT } from '../accessRules/AccessRuleFragment';

export const MAP_OBJECT_FRAGMENT = gql`
    ${ACCESS_RULE_FRAGMENT}
    fragment MapObjectFragment on MapObject {
        id
        version
        angle
        name
        position: center {
            x
            y
        }
        level
        width
        height: length
        scalable
        teleport
        image {
            id
            src
            type
        }
        techImage {
            id
            src
            type
        }
        zone {
            id
        }
        accessRules {
            ...AccessRuleFragment
        }
        type {
            id
        }
    }
`;

import { Tools } from '../../../../../pages/Constructor/TopBar/TopBarEntires/Tools/Constants/Tools';
import { currentToolVar } from '../../../../../shared/model/cache/Cache';
import { RULE_EDIT } from '../../../../AccessGroup/model/AGConstants';
import { ROUTE_VERTEX_NAME } from '../../../../Route/lib/RouteDefaultValues';
import selector from '../../../../Selector/model/Selector';
import { WALL_CIRCLE_NAME } from '../../helpers/WallDefaultValues';

/**
 * @typedef {import('../../../model/FloorGraph').FloorGraph} FloorGraph
 * @typedef {import('../../../../Route/model/RouteGraph').RouteGraph} RouteGraph
 */

/**
 * Handler for click on point (vertex)
 * @param {object} e click event
 * @param {string} vertexId vertex UUID
 * @param {FloorGraph | RouteGraph} graph FloorGraph or its heir
 */
export const handlePointClick = (e, vertexId, graph) => {
    if (
        e.evt.button === 0 &&
        e.target.attrs.accessRule === RULE_EDIT &&
        (currentToolVar() === Tools.none || currentToolVar() === Tools.zone) &&
        (e.target.attrs.name === WALL_CIRCLE_NAME ||
            e.target.attrs.name === ROUTE_VERTEX_NAME)
    ) {
        selector.selectVertex(vertexId, graph);
    }
};

import { makeVar } from '@apollo/client';
import routesAPI from '../api/RoutesAPI';
import constraintGroupsModerator from '../../Constraints/model/ConstraintGroupsModerator';
import { RouteGraphWrapper } from './RouteGraphWrapper';
import accessGroupsModerator from '../../AccessGroup/model/AccessGroupsModerator';
import { Constraints } from '../../../pages/Constructor/TopBar/TopBarEntires/Tools/Constants/Tools';
import { Tools } from '../../../pages/Constructor/TopBar/TopBarEntires/Tools/Tools';
import { currentToolVar } from '../../../shared/model/cache/Cache';

/**
 * @typedef {import('./RouteGraphWrapper').RouteGraphWrapper} RouteGraphWrapper
 * @typedef {import('./RouteAdapter').RouteData} RouteData
 * @typedef {import('../../../shared/typedefs/typedefs').NullableStringReactiveVar} NullableStringReactiveVar
 */

/**
 * @callback RoutesMapVar
 * @param {Map<string, RouteGraphWrapper>}
 * @return {Map<string, RouteGraphWrapper>}
 */

/**
 * @class
 * This class is uses to store and manage all routes.
 * Use this class to add or delete routes.
 *
 * If you need to modify concrete route -> get its instance (e.g. with getRoute() method)
 * and modify using RouteGraphWrapper methods
 */
class RoutesModerator {
    constructor() {
        /**
         * Var for storing all routes.
         * Key - route UUID.
         * Value - Route wrapper object
         * @type {RoutesMapVar}
         */
        this.routesMapVar = makeVar(new Map());

        /**
         * UUID for current route
         * @type {NullableStringReactiveVar}
         */
        this.currentRouteIdVar = makeVar(null);

        /**
         * Store route UUID for modal to edit route properties.
         * If null, then modal should be closed
         * @type {NullableStringReactiveVar}
         */
        this.editModal = makeVar(null);
    }

    /**
     * Method for adding route to manager and creating RouteGraph instance for route
     * @param {string} constraintGroupId
     * @param {string} routeId
     * @param {RouteData} [properties={}] Extra properties
     * @returns {RouteGraphWrapper} Created route
     */
    addRoute(constraintGroupId, routeId, properties = {}) {
        const newRoute = new RouteGraphWrapper(
            constraintGroupId,
            routeId,
            properties
        );
        this.routesMapVar(new Map(this.routesMapVar().set(routeId, newRoute)));
        return newRoute;
    }

    /**
     * Method for creating new route.
     * Send query to create route and then add it to manager
     */
    async createRoute() {
        // create constraint group if there is none
        if (constraintGroupsModerator.defaultConstraintGroupId === null) {
            const groupName =
                'Построения для ' +
                accessGroupsModerator
                    .getGroup(accessGroupsModerator.currentAccessGroupId)
                    .name() +
                ' ' +
                new Date().toUTCString();
            await constraintGroupsModerator.createGroup(groupName);
        }

        let newRouteData = await routesAPI.createRoute(
            constraintGroupsModerator.defaultConstraintGroupId
        );
        if (newRouteData) {
            this.addRoute(
                constraintGroupsModerator.defaultConstraintGroupId,
                newRouteData.id,
                newRouteData
            ); // add route to manager
            this.currentRouteIdVar(newRouteData.id); // set new route as current
        }
    }

    /**
     * Method to delete route from manager.
     * Note: this method remove only from local storage. To fully delete route use deleteRoute() method
     * @param {string} routeId
     */
    removeRouteFromManager(routeId) {
        const deleted = this.routesMapVar().delete(routeId);
        if (deleted) {
            this.routesMapVar(new Map(this.routesMapVar()));
        }

        if (this.currentRouteIdVar() === routeId) {
            this.cancelCreating();
        }
    }

    /**
     * Method for deleting route.
     * Send query to delete route and then delete it from moderator
     * @param {string} routeId
     */
    async deleteRoute(routeId) {
        const route = this.getRoute(routeId);
        let id = await routesAPI.deleteRoute(routeId, route.version());
        if (id) {
            this.removeRouteFromManager(id);
        }
    }

    /**
     * Method to delete all routes from manager that belongs to constraint group.
     * Note: this method remove only from local storage. To fully delete route use deleteRoute() method
     * @param {string} constrGrId
     */
    removeRoutesByConstraintGroup(constrGrId) {
        const routesToDelete = [];

        // find all routes to delete
        this.routesMapVar().forEach((route) => {
            if (route.constraintGroupId === constrGrId) {
                routesToDelete.push(route.routeId);
            }
        });

        // delete routes from manager
        if (routesToDelete.length > 0) {
            routesToDelete.forEach((routeId) => {
                this.routesMapVar().delete(routeId);
            });
            this.routesMapVar(new Map(this.routesMapVar())); // update routes var
        }
    }

    /**
     * Method for getting route graph by route id
     * @param {string} routeId
     * @returns {RouteGraphWrapper | undefined} Route graph wrapper
     */
    getRoute(routeId) {
        return this.routesMapVar().get(routeId);
    }

    /**
     * Method for getting current route graph
     * @returns {RouteGraphWrapper | undefined} Route instance
     */
    getCurrentRoute() {
        return this.getRoute(this.currentRouteIdVar());
    }

    /**
     * Method for getting all routes belongs to constraint group.
     * @param {string} constraintGroupId Constraint group UUID
     * @returns {RouteGraphWrapper[]} Array with RouteGraph objects
     */
    getRoutesByConstraintGroup(constraintGroupId) {
        const groups = [];
        this.routesMapVar().forEach((route) => {
            if (route.constraintGroupId === constraintGroupId) {
                groups.push(route);
            }
        });
        return groups;
    }

    /**
     * Method that opens modal to edit route properties
     * @param {string} routeId route UUID
     */
    openEditModal(routeId) {
        this.editModal(routeId);
    }

    /** Method to close modal with editing route properties */
    closeEditModal() {
        this.editModal(null);
    }

    /**
     * Method for stopping creating route
     */
    cancelCreating() {
        if (currentToolVar() === Constraints.route) {
            currentToolVar(Tools.none);
        }

        if (this.currentRouteIdVar()) {
            this.getCurrentRoute().graph.cancelCreating();
            this.currentRouteIdVar(null);
        }
    }

    /** Method for cleaning all storing data */
    clear() {
        this.routesMapVar(new Map());
        this.currentRouteIdVar(null);
    }
}

const routesModerator = new RoutesModerator();
export default routesModerator;

import React from 'react';
import { openContextMenu } from './OpenContextMenu';
import { RouteContextMenu } from '../ui/ContextMenus/RouteContextMenu';
import { RouteCreationContextMenu } from '../ui/ContextMenus/RouteCreationContextMenu';

/**
 * Function that call opening context menu function for the route
 * @param {string} routeId route UUID
 *
 * @param {object} position position of mouse click
 * @param {number} position.x
 * @param {number} position.y
 */
export const openRouteContextMenu = (routeId, position) => {
    const component = <RouteContextMenu routeId={routeId} />;
    openContextMenu(position, component);
};

/**
 * Function that call opening context menu function for the route tool while route in creating state
 * @param {object} position position of mouse click
 * @param {number} position.x
 * @param {number} position.y
 */
export const openRouteCreationContextMenu = (position) => {
    openContextMenu(position, <RouteCreationContextMenu />);
};

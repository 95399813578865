import styled from 'styled-components';
import { POpenSansLight } from '../../../../../../shared/ui/TextStyles';

export const FoldersWrapper = styled.ul`
    background-color: white;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 3px;
`;

export const AdviceText = styled(POpenSansLight)`
    font-size: 12px;
    line-height: normal;
`;

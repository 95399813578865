import { makeVar } from '@apollo/client';
import { areaContainsPoint } from '../../../shared/lib/modules/math/Math';
import { GlobalZone } from './GlobalZone';
import temporaryZone from './TemporaryZone';
import zoneValidator from './ZoneValidator';
import selector from '../../Selector/model/Selector';
import Zone from './Zone';
import { v4 as uuid } from 'uuid';
import Konva from 'konva';
import { getUniqueNumber } from '../../../shared/lib/utils/common/GetUniqueNumber';
import notifications from '../../HintSystem/model/Notifications';
import zonesAPI from '../api/ZonesAPI';
import {
    currentToolVar,
    currentFloorVar,
} from '../../../shared/model/cache/Cache';
import floorGraph from '../../Wall/model/FloorGraph';
import { deepCopy } from '../../../shared/lib/utils/common/DeepCopy';
import { LinkedSegment } from '../../Wall/model/SegmentsModerator';
import { getRuleForCurrentUser } from '../../AccessGroup/lib/GetRuleForCurrentUser';
import { Tools } from '../../../pages/Constructor/TopBar/TopBarEntires/Tools/Constants/Tools';

/** Initial value for reactive var zonesMergeVar */
export const initialValueForZonesMergeVar = {
    isTracking: false,
    firstZoneId: null,
    secondZoneId: null,
};

const DEFAULT_ZONE_NAME = 'Zone';

/**
 * @typedef {String} id zone id
 */

/**
 * Reactive variable for storing zones
 * @callback ZonesVar
 * @param {Map<id, Zone | GlobalZone>} zones
 * @return {Map<id, Zone | GlobalZone>}
 */

/**
 * Reactive variable for merging zones
 * Needed for zones merge
 * @callback ZonesMergeVar
 * @param {{isTracking: Boolean, firstZoneId:id, secondZoneId: id}} param
 * @return {{isTracking: Boolean, firstZoneId:id, secondZoneId: id}}
 * @see initialValueForZonesMergeVar for data example
 */

/**
 * Reactive variable for storing donor zone id
 * @callback DonorZoneVar
 * @param {String} zoneId
 * @return {String} zoneId
 */

/**
 * @typedef {import('./Zone').AccessRuleFromServer} AccessRuleFromServer
 * @typedef {import('../../Wall/model/SegmentsModerator').PointLinkWrapper} PointLinkWrapper
 */

class Zones {
    constructor() {
        /**
         * @type {ZonesVar}
         */
        this.zonesVar = makeVar(new Map());

        /**
         * Variable for keeping global zone id
         * @type {String} Global Zone id
         */
        this.globalZoneId = null;

        /**
         * @type {ZonesMergeVar}
         */
        this.zonesMergeVar = makeVar(initialValueForZonesMergeVar);

        /**
         * @type {DonorZoneVar}
         */
        this.donorZoneVar = makeVar(null);
    }

    get(id) {
        if (!id) {
            return null;
        }
        return this.zonesVar().get(id);
    }

    /**
     *
     * @param {Map<id, Zone | GlobalZone>} zones
     */
    copy(zones) {
        /**
         * @type {Map<id, Zone | GlobalZone>}
         */
        const zonesMapCopy = new Map();
        for (const [id, zone] of zones.entries()) {
            zonesMapCopy.set(id, zone.copy());
        }
        return zonesMapCopy;
    }

    updateVersion(zoneId, newVersion) {
        const zoneForUpdate = this.zonesVar().get(zoneId);
        if (zoneForUpdate && !!newVersion) {
            zoneForUpdate.updateVersion(newVersion);
            return true;
        }
        return false;
    }

    getVersion(zoneId) {
        return this.zonesVar().get(zoneId).getVersion();
    }

    rerender() {
        for (let [, zone] of this.zonesVar().entries()) {
            zone.rerender();
        }
        this.zonesVar(new Map(this.zonesVar()));
    }

    /**
     * Method to add pointLink to zone borders
     * @param {String} zoneId
     * @param {PointLinkWrapper} newBorderPointLink
     * @returns {PointLinkWrapper[]}
     */
    addBorderPoint(zoneId, newBorderPointLink) {
        if (this.isBorderHasPoint(zoneId, newBorderPointLink)) {
            return;
        }
        const newZoneBorders = [...this.zonesVar().get(zoneId).props.borders];
        newZoneBorders.push(newBorderPointLink);
        this.zonesVar().get(zoneId).props.borders =
            Zone.sortZoneBorders(newZoneBorders);

        return newZoneBorders;
    }

    isBorderHasPoint(zoneId, pointLink) {
        const zone = this.zonesVar().get(zoneId);
        if (!zone || !zone.props.borders) {
            return false;
        }
        return Zone.getArrayOfPointsIds(zone.props.borders).includes(
            pointLink.getId()
        );
    }

    /**
     * updating zone border if segment on that zone has been
     * leaned on was changed
     * @param {import('./Zone').ZoneProps} zonesForUpdate
     */
    updateZoneBorder(zonesForUpdate) {
        if (zonesForUpdate.length === 0) {
            return;
        }

        zonesForUpdate.forEach((zone) => {
            if (zone) {
                this.updateVersion(zone.id, zone.version);
                zone.shape.outer.forEach((borderPoint) => {
                    const pointLink =
                        floorGraph.segmentsModerator.getPointLinkWrapper(
                            borderPoint.id
                        );
                    if (pointLink) {
                        zones.addBorderPoint(zone.id, pointLink);
                    }
                });
            }
        });
    }

    /**
     * Method for creating new zone based on selected edges
     * @returns id of created zone or undefined
     */
    addZoneFromSelector() {
        const zoneVertices = new Set();
        const wallSegmentSelectors = new Set();
        [...selector.selectedEdgesVar()].forEach(([edge]) => {
            const [id1, id2] = LinkedSegment.splitKey(edge);
            zoneVertices.add(
                floorGraph.segmentsModerator.getPointLinkWrapper(id1)
            );
            zoneVertices.add(
                floorGraph.segmentsModerator.getPointLinkWrapper(id2)
            );
            wallSegmentSelectors.add({
                id: floorGraph.segmentsModerator.getSegment(id1, id2).getId(),
                version: floorGraph.segmentsModerator
                    .getSegment(id1, id2)
                    .getVersion(),
            });
        });
        let zoneId;
        let zoneCutProps;

        // zone creation and validation methods are based on TemporaryZone,
        // so it is necessary to create new TemporaryZone object via tmpZonePointsIdsVar
        temporaryZone.tmpZonePointLinksVar(zoneVertices);
        if (zoneValidator.validateZone()) {
            ({ createdZoneId: zoneId, zoneCutProps } = this.create([
                ...temporaryZone.tmpZonePointLinksVar(),
            ]));
            zoneCutProps.wallSegmentSelectors = [...wallSegmentSelectors];
        } else {
            temporaryZone.finishCreating(); // clear temporary zone
            return { createdZoneId: null, zoneCutProps: null };
        }
        selector.clear();
        currentToolVar(Tools.none);
        return { createdZoneId: zoneId, zoneCutProps };
    }

    /**
     * @param {Array<PointLink>} borders array of points ids
     * @returns {String} id of created zone
     */
    create(borders) {
        if (borders.length < 3) {
            return;
        }

        const zonesNames = Array.from(this.zonesVar()).map(([_, value]) => {
            return { name: value.props.name };
        });
        const zoneNumber = getUniqueNumber(zonesNames, DEFAULT_ZONE_NAME);

        const zoneProps = {
            id: uuid(),
            borders: borders,
            color: Konva.Util.getRandomColor(),
            description: '',
            isStub: false,
            name:
                zoneNumber > 0
                    ? `${DEFAULT_ZONE_NAME}(${zoneNumber})`
                    : DEFAULT_ZONE_NAME,
            visible: true,
        };

        const donorZoneId = this.donorZoneVar();

        const zoneCutProps = {
            donorZoneSelector: {
                id: donorZoneId,
                version: this.getVersion(donorZoneId),
            },
            newZoneDraft: {
                color: zoneProps.color,
                name: zoneProps.name,
            },
            wallSegmentSelectors: [],
        };

        this.add(zoneProps);
        return { createdZoneId: zoneProps.id, zoneCutProps };
    }

    /**
     *
     * @param {Point[]} points
     */
    areAllPointsInZone(points) {
        let zoneId;

        for (const point of points) {
            const zoneIdForPoint = this.getZoneIdUnderPoint(point);
            if (!zoneId) {
                zoneId = zoneIdForPoint;
                continue;
            }

            if (zoneId !== zoneIdForPoint) {
                return null;
            }
        }
        return zoneId;
    }

    /**
     * Method for saving cut zone to the back
     * @param {String} oldZoneId
     * @param {import('../api/ZonesAPI').ZoneCutProps} zoneCutProps
     */
    saveCut(oldZoneId, zoneCutProps) {
        return zonesAPI.cut(zoneCutProps).then((response) => {
            const borders = this.formBorders(response.newZone.wall.graph);
            const newZoneProps = { ...response.newZone, borders };
            this.update(oldZoneId, newZoneProps, newZoneProps.id);
            this.updateVersion(
                response.donorZone.id,
                response.donorZone.version
            );
            response.newZone.wallSegments.forEach((segment) => {
                floorGraph.segmentsModerator.updateVersion(
                    segment.startPoint.id,
                    segment.endPoint.id,
                    segment.version
                );
            });
            return response;
        });
    }

    /**
     *
     * @param {import('../api/ZonesAPI').ZoneDiff} zoneDiff
     */
    saveUpdate(zoneDiff) {
        const loadFloor =
            require('../../../pages/Constructor/model/LoadFloor').loadFloor;

        zonesAPI
            .update(zoneDiff)
            .then((res) => {
                zones.updateVersion(res.id, res.version);
            })
            .catch(async (error) => {
                notifications.setError(
                    'Не удалось обновить зону',
                    error.message
                );
                await loadFloor(currentFloorVar().id);
            });
    }

    /**
     *
     * @param {VersionedZoneSelectorDto} srcZoneSelector
     * @param {VersionedZoneSelectorDto} destZoneSelector
     */
    saveMerge(srcZoneSelector, destZoneSelector) {
        const loadFloor =
            require('../../../pages/Constructor/model/LoadFloor').loadFloor;

        zonesAPI
            .merge(srcZoneSelector, destZoneSelector)
            .catch(async (error) => {
                console.log(error);
                notifications.setError(
                    'Не удалось объединить зоны',
                    error.message
                );
                await loadFloor(currentFloorVar().id);
            });
    }

    /**
     * Method to add new zone to store using passed props
     * @param {import('./Zone').ZoneProps} zoneProps
     * @param {import('../api/ZonesAPI').ZoneCutProps} zoneCutProps
     */
    add(zoneProps) {
        zoneProps.borders = Zone.sortZoneBorders(zoneProps.borders);

        this.zonesVar(
            new Map(this.zonesVar().set(zoneProps.id, new Zone(zoneProps)))
        );
    }

    /**
     * Method to update some zone fields
     * @param {String} zoneId
     * @param {import('./Zone').ZoneProps} newZoneProps some of zone type props
     */
    update(zoneId, newZoneProps, newZoneId = null) {
        if (newZoneId) {
            const updatingZone = deepCopy(this.zonesVar().get(zoneId));
            newZoneProps = {
                ...updatingZone.props,
                ...newZoneProps,
            };

            this.zonesVar().delete(zoneId);
            this.zonesVar(
                new Map(
                    this.zonesVar().set(newZoneProps.id, new Zone(newZoneProps))
                )
            );
            return;
        }
        const updatingZone = this.zonesVar().get(zoneId);
        updatingZone.props = {
            ...updatingZone.props,
            ...newZoneProps,
        };

        this.zonesVar(new Map(this.zonesVar().set(zoneId, updatingZone)));
    }

    /**
     * Method to update Mapins schema
     * @param {string} zoneId zone UUID
     * @param {MapinsSchemaImpl[]} schemaImpls
     * @return {Zone | null} Zone or null if zone doesn't exists
     */
    updateSchema(zoneId, schemaImpls) {
        const zone = this.get(zoneId);
        if (zone) {
            const newProps = {
                schemaImpls: deepCopy(schemaImpls),
            };
            this.update(zoneId, newProps);
            return zone;
        }
        return null;
    }

    /**
     * Set fetched zones to store
     * @param {import('./Zone').ZoneProps[]} zonePropsArray
     */
    initialize(zonePropsArray) {
        const initZones = [];
        for (let zoneProps of zonePropsArray) {
            const borders = this.formBorders(zoneProps.wall.graph);
            zoneProps = {
                ...zoneProps,
                isStub: zoneProps.__typename === 'ZoneStub',
                borders: borders,
                visible: true,
                accessRule: getRuleForCurrentUser(zoneProps.accessRules),
            };
            if (zoneProps.global) {
                this.globalZoneId = zoneProps.id;
                initZones.unshift([
                    this.globalZoneId,
                    GlobalZone.createInitialGlobalZone(zoneProps),
                ]);
            } else {
                initZones.push([zoneProps.id, new Zone(zoneProps)]);
            }
        }

        this.zonesVar(new Map(initZones));
    }

    /**
     *
     * @param {{key:id}[]} graph
     * @returns {PointLinkWrapper[]}
     */
    formBorders(graph) {
        return Zone.sortZoneBorders(
            graph.map((point) => {
                return floorGraph.segmentsModerator.getPointLinkWrapper(
                    point.key
                );
            })
        );
    }

    /**
     * Method that merges one zone to another
     * @param {uuid} srcZoneId zone that is being merged
     * @param {uuid} destZoneId zone that expands with srcZone
     */
    merge(srcZoneId, destZoneId) {
        // TODO change logic of merging one zone with global zone
        if (srcZoneId === this.globalZoneId) {
            notifications.setError(
                'Ошибка',
                'Нельзя объединять глобальную зону'
            );
            return;
        }

        if (destZoneId === this.globalZoneId) {
            this.zonesVar().delete(srcZoneId); // delete merged zone
            return;
        }

        const srcZone = this.zonesVar().get(srcZoneId);
        const destZone = this.zonesVar().get(destZoneId);

        srcZone.props.borders.forEach((point) =>
            destZone.props.borders.push(point)
        ); // Add points of srcZone to destZone
        destZone.props.borders = Zone.sortZoneBorders(destZone.props.borders); // Sort points to make polygon

        this.zonesVar().delete(srcZoneId); // delete merged zone
        this.update(destZoneId, { ...destZone.props });
    }

    /**
     * @returns GlobalZone - current instance of GlobalZone class
     */
    getGlobalZone() {
        return this.zonesVar().get(this.globalZoneId);
    }

    /**
     * Gives zone over which the cursor(or any dot) is or returns null if no zone under the cursor
     * @param {{x: number, y: number}} pointerPosition position of pointer in stage coordinate system
     * @returns null or zone id
     */
    getZoneIdUnderPoint(pointerPosition) {
        let zoneUnderPointId;

        for (const zone of this.zonesVar().values()) {
            if (
                areaContainsPoint(
                    Zone.getBordersCoordinatesLineString(zone.props.borders),
                    {
                        ...pointerPosition,
                    }
                )
            ) {
                zoneUnderPointId = zone.props.id;
            }
        }

        return zoneUnderPointId === undefined
            ? this.globalZoneId
            : zoneUnderPointId;
    }

    /**
     * This method deletes vertex from zone borders and replace it by new vertex.
     * New vertex could already been in zone border.
     * @param {uuid} zoneId Zone ID
     * @param {uuid} deleteVertexId ID of vertex, that should be deleted from zone borders
     * @param {uuid} newVertexId ID of vertex, that should be added to zone borders
     */
    replaceVertexInZoneBorder(zoneId, deleteVertexId, newVertexId) {
        const zone = this.zonesVar().get(zoneId);

        let newZoneBorders = zone.props.borders.filter(
            (elem) => elem.getId() !== deleteVertexId
        ); // remove deleted vertex from zone borders
        if (!Zone.getArrayOfPointsIds(newZoneBorders).includes(newVertexId)) {
            const newPointLink =
                floorGraph.segmentsModerator.getPointLinkWrapper(newVertexId);
            newZoneBorders = [...newZoneBorders, newPointLink]; // add new vertex to zone borders
        }

        if (newZoneBorders.length <= 2) {
            // if zone has less than 3 vertices, this zone should be deleted
            this.zonesVar().delete(zoneId);
            this.zonesVar(new Map(this.zonesVar().entries())); // update link
            return;
        }

        newZoneBorders = Zone.sortZoneBorders(
            // sorting zone borders
            newZoneBorders
        );

        this.update(zoneId, {
            ...zone.props,
            borders: newZoneBorders,
        });
    }

    clear() {
        this.zonesVar(new Map());
        temporaryZone.tmpZonePointLinksVar(new Set());
    }
}

const zones = new Zones();
export default zones;

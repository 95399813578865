import { gql } from '@apollo/client';
import { ROUTE_FRAGMENT } from '../../fragments/routes/RouteFragment';
import { CONSTRAINT_GROUP_FRAGMENT } from '../../fragments/constraintGroups/ConstraintGroupFragment';
import { ATTENTION_MARKER_FRAGMENT } from '../../fragments/attentionMarkers/AttentionMarkerFragment';

export const GET_FLOOR = gql`
    ${CONSTRAINT_GROUP_FRAGMENT}
    ${ROUTE_FRAGMENT}
    ${ATTENTION_MARKER_FRAGMENT}
    query floor($id: String!) {
        floor(id: $id) {
            id
            project {
                id
            }
            description
            num
            zones {
                color
                id
                name
                global
                accessRules {
                    ...AccessRuleFragment
                    group {
                        name
                    }
                }
                ... on Zone {
                    version
                    schemaImpls {
                        data
                        id
                        version
                        schema {
                            id
                            schema
                            version
                        }
                    }
                }
                wall {
                    graph {
                        key
                    }
                }
            }
            wall {
                graph {
                    key
                    value {
                        adjacentPoints
                        point {
                            id
                            coordinate {
                                x
                                y
                            }
                            accessRules {
                                ...AccessRuleFragment
                            }
                        }
                    }
                }
                types {
                    key
                    value {
                        id
                        version
                        lengthRestriction
                        width
                        through
                        endPoint {
                            id
                        }
                        startPoint {
                            id
                        }
                        secondZone {
                            id
                        }
                        firstZone {
                            id
                        }
                        data {
                            data
                            schema {
                                schema
                            }
                        }
                        schemaImpls {
                            data
                            id
                            version
                            schema {
                                id
                                schema
                                version
                            }
                        }
                        name
                        imageOffsetCoefficient
                        type {
                            id
                            length
                            width
                            scalable
                            through
                            virtual
                            name
                            description
                            image {
                                id
                                src
                                type
                            }
                            techImage {
                                id
                                src
                                type
                            }
                        }
                        accessRules {
                            ...AccessRuleFragment
                        }
                    }
                }
            }

            attentionMarkers {
                ...AttentionMarkerFragment
            }

            constraintGroups {
                ...ConstraintGroupFragment
                routes {
                    ...RouteFragment
                    graph {
                        points {
                            key
                            value {
                                coordinate {
                                    x
                                    y
                                }
                            }
                        }
                    }
                    segments {
                        id
                        version
                        startPoint {
                            id
                            coordinate {
                                x
                                y
                            }
                        }
                        endPoint {
                            id
                            coordinate {
                                x
                                y
                            }
                        }
                    }
                }
            }
        }
    }
`;

import React, { useRef } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { Tool } from './Tool';
import { currentToolVar } from '../../../../../shared/model/cache/Cache';
import { useReactiveVar } from '@apollo/client';

export const APPEAR_DIRECTIONS = {
    down: 'down',
    up: 'up',
    left: 'left',
    right: 'right',
};

const DropDownIconWrapper = styled.div`
    pointer-events: auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) =>
        props.isSelected ? `rgba(225, 232, 255, 0.5)` : `transparent`};
    border: solid 1px
        ${(props) => (props.isSelected ? '#5076FD' : `transparent`)};
    height: 100%;
`;

const DropDownWrapper = styled.div`
    position: absolute;
    ${(props) => {
        switch (props.appearDirection) {
            case APPEAR_DIRECTIONS.up:
                return 'transform: translateY(-50%);';
            case APPEAR_DIRECTIONS.down:
                return 'transform: translateY(50%)';
            case APPEAR_DIRECTIONS.left:
                return 'transform: translateX(-50%)';
            case APPEAR_DIRECTIONS.right:
                return 'transform: translateX(50%)';
            default: return '';
        }
    }};
    background-color: white;
    visibility: ${(props) => (props.isHovered ? 'visible' : 'hidden')};
    padding: 18px 6px;
    border-radius: 45px;
    box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.07);
`;

const TalkBubble = styled.div`
    width: 100%;
    height: 100%;
    position: relative;

    ::before {
        content: '';
        background-color: white;
        position: absolute;
        width: 12px;
        height: 12px;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 85%) rotate(45deg);
    }
`;

const ClickableContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    :hover {
        cursor: pointer;
    }
    pointer-events: auto;
    padding: 0;
`;

const ToolDropDownIcon = styled.img`
    position: relative;
    width: ${(props) => props.width};
    padding: 6px;

    ::before {
        content: '';
        background-color: green;
        position: absolute;
        width: 12px;
        height: 12px;
        top: 0;
        left: 50%;
        transform: translate(-50%);
    }
`;

const ToolDropDownIconWrapper = styled.div`
    position: relative;

    ::before {
        content: '';
        border-left: 2px solid gray;
        border-top: 2px solid gray;
        position: absolute;
        width: 4px;
        height: 4px;
        top: -10%;
        left: 50%;
        transform: translate(-50%) rotate(45deg);
    }
`;

/**
 * @component
 * @param {object} props
 * @param {object} props.toolProps props for tool
 * @param {string} props.toolProps.mainIconAlt alt for main icon
 * @param {string} props.toolProps.mainIconSrc main icon image src
 * @param {object[]} props.toolProps.tools tool in dropdown
 * @param {string} props.toolProps.width main icon image width for css: "??px" or ??%"
 * @param {string} [appearDirection=APPEAR_DIRECTIONS.down] props.appearDirection direction to dropdown appear
 * @param {object} props.dropDownStyle styles for dropdown window
 * @returns
 */
export const ToolDropDown = ({
    toolProps,
    appearDirection = APPEAR_DIRECTIONS.down,
    dropDownStyle,
}) => {
    const [isHovered, setHovered] = useState(false);
    const currentTool = useReactiveVar(currentToolVar);
    const timerRef = useRef(null);

    /** Is selected one of dropdown tools */
    const isSelected = toolProps.tools.find(
        (tool) => tool.name === currentTool
    );

    const showDropDown = () => {
        if (isHovered) {
            keepDropDownVisible();
        } else {
            setHovered(true);
        }
    };

    const hideDropDown = () => {
        timerRef.current = setTimeout(() => setHovered(false), 100);
    };

    const keepDropDownVisible = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
    };

    return (
        <DropDownIconWrapper
            onMouseEnter={showDropDown}
            onMouseLeave={hideDropDown}
            isSelected={isSelected}
            width={toolProps.width}
        >
            <ToolDropDownIconWrapper>
                <ToolDropDownIcon
                    src={toolProps.mainIconSrc}
                    alt={toolProps.mainIconAlt}
                    width={toolProps.width}
                />
            </ToolDropDownIconWrapper>
            <DropDownWrapper
                isHovered={isHovered}
                appearDirection={appearDirection}
                onMouseEnter={keepDropDownVisible}
                style={dropDownStyle}
            >
                <TalkBubble>
                    {toolProps.tools.map((tool) => (
                        <ClickableContainer key={tool.src}>
                            <Tool toolProps={tool} />
                        </ClickableContainer>
                    ))}
                </TalkBubble>
            </DropDownWrapper>
        </DropDownIconWrapper>
    );
};

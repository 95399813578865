import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Item } from './Item';

const ItemsContainer = styled.ul`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 9px;
    padding: 0;
`;

/**
 * Component that represents container for canvas items in folder
 *
 * @component
 */
export const FolderContent = ({ items, folder }) => {
    return (
        <ItemsContainer>
            {items?.map((item) => (
                // TODO remove item.src after adding furniture from server
                <Item item={item} key={item.id} folder={folder} />
            ))}
        </ItemsContainer>
    );
};
FolderContent.propTypes = {
    /**
     * array of canvas items
     */
    items: PropTypes.arrayOf(
        PropTypes.shape({
            zoneId: PropTypes.string,
            angle: PropTypes.number,
            height: PropTypes.number,
            width: PropTypes.number,
            itemName: PropTypes.string,
            pictureLink: PropTypes.string,
            position: PropTypes.shape({
                x: PropTypes.number,
                y: PropTypes.number,
            }),
        })
    ),
};

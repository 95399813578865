import { deepCopy } from '../../../shared/lib/utils/common/DeepCopy';
import { v4 as uuid } from 'uuid';
import {
    addTwoPoints,
    arePointsEqual,
    findAngleBetweenVectorAndXAxis,
    findAngleBetweenVectors,
    findLinesIntersection,
    getOffsetLines,
    isPointOnLine,
    subtractTwoPoints,
} from '../../../shared/lib/modules/math/Math';
import zones from '../../Zone/model/Zones';
import { RULE_EDIT } from '../../AccessGroup/model/AGConstants';
import notifications from '../../HintSystem/model/Notifications';
import scalesConformer from '../../ScalesConformer/model/ScalesConformer';

/**
 * @typedef {import("./FloorGraph").Point} Point
 * @typedef {import("./FloorGraph").GraphPointValue} GraphPointValue
 * @typedef {import("./FloorGraph").WallSegmentType} WallSegmentType
 */

/**
 * This class is special for implement auto update segment points Ids by link on single point ref JS object
 * @class
 */
export class PointLinkWrapper {
    /**
     * @constructor
     * @param {String} id - The any string id of point
     * @param {Boolean} isFetched - Is the current point Id the real UUID from Backend
     * @param {GraphPointValue} pointInfo
     */
    constructor(id, isFetched = false, pointInfo = null, isVisible = true) {
        this.id = id;
        this.isFetched = isFetched;
        this.pointInfo = pointInfo;
        this.isVisible = isVisible;
    }

    /**
     *
     * @param {PointLinkWrapper} pointLinkWrapper
     */
    static createByPointLinkWrapper(pointLinkWrapper) {
        const copyOfPointLinkWrapper = deepCopy(pointLinkWrapper);
        return new PointLinkWrapper(
            copyOfPointLinkWrapper.id,
            copyOfPointLinkWrapper.isFetched,
            copyOfPointLinkWrapper.pointInfo
        );
    }

    getVisibility() {
        return this.isVisible;
    }

    /**
     * Method to get array of coordinates [x,y]
     * @returns [x1,y1]
     */
    getFlatCoordinates() {
        return Object.values(this.pointInfo.coordinates);
    }

    getInfo() {
        return this.pointInfo;
    }

    getCoordinates() {
        return { ...this.pointInfo.coordinates };
    }

    /**
     * @returns {string} AccessRule
     */
    getAccessRule() {
        return this.pointInfo.accessRule;
    }

    getId() {
        return this.id;
    }

    getAdjacentPoints() {
        return [...this.pointInfo.adjacentPoints];
    }
}

/**
 * @class
 */
class PointOffsets {
    /**
     * @type {Point}
     */
    left;
    /**
     * @type {Point}
     */
    right;

    /**
     * @constructor
     * @param {Point | null} left
     * @param {Point | null} right
     */
    constructor(left = null, right = null) {
        this.left = left;
        this.right = right;
    }
}

/**
 * Inner class of LinkedSegment
 * @class
 */
class SegmentMetaInf {
    /**
     * @type {PointOffsets}
     */
    startOffsets;
    /**
     * @type {PointOffsets}
     */
    endOffsets;

    /**
     * @constructor
     * @param {PointOffsets} startOffsets
     * @param {PointOffsets} endOffsets
     */
    constructor(
        startOffsets = new PointOffsets(),
        endOffsets = new PointOffsets()
    ) {
        this.startOffsets = startOffsets;
        this.endOffsets = endOffsets;
    }

    /**
     * @param {LinkedSegment} segmentLink
     * @return {Point[]}
     */
    getShape(segmentLink) {
        if (
            this.#isOffsetNull(this.startOffsets) ||
            this.#isOffsetNull(this.endOffsets)
        ) {
            return null;
        }

        const firstPoint = segmentLink.firstPointLinkWrapper.getCoordinates();
        const secondPoint = segmentLink.secondPointLinkWrapper.getCoordinates();

        return [
            this.startOffsets.left,
            firstPoint,
            this.startOffsets.right,
            this.endOffsets.right,
            secondPoint,
            this.endOffsets.left,
        ];
    }

    #isOffsetNull(offset) {
        return !offset?.left || !offset?.right;
    }

    /**
     * Erase all info for point (left/right offset points)
     */
    erasePointInfo() {
        this.startOffsets = new PointOffsets();
        this.endOffsets = new PointOffsets();
    }

    /**
     * Setter of left offset point
     * auto defining start or end point
     * @param {String} pointId is a point relative to which we consider
     * @param {Point} offsetPoint
     */
    putLeftOffsetPoint(point, offsetPoint, startPoint) {
        if (arePointsEqual(point, startPoint)) {
            this.startOffsets.left = offsetPoint;
        } else {
            this.endOffsets.right = offsetPoint;
        }
    }

    /**
     * Setter of right offset point
     * auto defining start or end point of segment
     * @param {Point} point is a point relative to which we consider
     * @param {Point} offsetPoint
     */
    putRightOffsetPoint(point, offsetPoint, endPoint) {
        if (arePointsEqual(point, endPoint)) {
            this.startOffsets.right = offsetPoint;
        } else {
            this.endOffsets.left = offsetPoint;
        }
    }
}

/**
 * Class for aggregate the segments information and provide synchronous points update
 * @class
 */
export class LinkedSegment {
    /**
     * @constructor
     * @param {PointLinkWrapper} firstPointLinkWrapper - the start segment point (start of the vector)
     * @param {PointLinkWrapper} secondPointLinkWrapper - the end segment point (end of the vector)
     */
    constructor(
        firstPointLinkWrapper,
        secondPointLinkWrapper,
        segmentInfo = null,
        metaInfo = new SegmentMetaInf()
    ) {
        if (
            !(
                firstPointLinkWrapper instanceof PointLinkWrapper &&
                secondPointLinkWrapper instanceof PointLinkWrapper
            )
        ) {
            throw Error(
                'Invalid type of point link. It should be the PointLink type'
            );
        }

        this.firstPointLinkWrapper = firstPointLinkWrapper;
        this.secondPointLinkWrapper = secondPointLinkWrapper;
        this.segmentInfo = segmentInfo;
        this.metaInfo = metaInfo;
    }

    /**
     *
     * @param {LinkedSegment} linkedSegment
     */
    static createByLinkedSegment(linkedSegment) {
        return new LinkedSegment(
            PointLinkWrapper.createByPointLinkWrapper(
                linkedSegment.firstPointLinkWrapper
            ),
            PointLinkWrapper.createByPointLinkWrapper(
                linkedSegment.secondPointLinkWrapper
            ),
            deepCopy(linkedSegment.segmentInfo)
        );
    }

    isSegmentEmbedded() {
        return this.segmentInfo.type.image.type === 'SVG';
    }

    getInfo() {
        return this.segmentInfo;
    }

    getVersion() {
        return this.segmentInfo.version;
    }

    getId() {
        return this.segmentInfo.id;
    }

    getType() {
        return deepCopy(this.segmentInfo.type);
    }

    /**
     * @return {String} - the string identifier for segment which not depends on the vector direction
     */
    getKey() {
        if (this.firstPointLinkWrapper.id < this.secondPointLinkWrapper.id)
            return (
                this.firstPointLinkWrapper.id.toString() +
                ',' +
                this.secondPointLinkWrapper.id.toString()
            );
        else
            return (
                this.secondPointLinkWrapper.id.toString() +
                ',' +
                this.firstPointLinkWrapper.id.toString()
            );
    }

    /**
     * @returns {[Point, Point]} lineString of segment
     */
    getLineString() {
        return [
            this.firstPointLinkWrapper.getCoordinates(),
            this.secondPointLinkWrapper.getCoordinates(),
        ];
    }

    /**
     * @return {String} - the possible string identifier for segment from their points IDs
     */
    static getKey(pointId1, pointId2) {
        if (pointId1 < pointId2) {
            return pointId1.toString() + ',' + pointId2.toString();
        } else {
            return pointId2.toString() + ',' + pointId1.toString();
        }
    }

    /**
     * Method to get array of point ids from which segment key is created
     * @param {String} key
     * @returns {String[]}
     */
    static splitKey(key) {
        return key.split(',');
    }

    /**
     *
     * @param {Number} newVersion
     */
    updateVersion(newVersion) {
        this.segmentInfo.version = newVersion;
    }
}

/**
 * Special class for auto perform segments info manipulation and correct
 * linking for providing synchronously update of point IDs
 * @class
 */
export class SegmentsModerator {
    constructor() {
        /**
         * Map of string to id to link object with immutable self representation in map
         * @type {Map<String, PointLinkWrapper>}
         */
        this._pointsLinks = new Map();
        /**
         * Map of PointLinks to list of Segments which use the key link
         * @type {Map<PointLinkWrapper, LinkedSegment[]>}
         */
        this._pointSegments = new Map();
        /**
         * Map of LinkedSegment.key identifiers to real LinkedSegment objects
         * for searching by segment string point ids
         * @type {Map<LinkedSegment.key, LinkedSegment> }
         */
        this._segmentsMap = new Map();
    }

    /**
     * @param {string} wallSegmentId uuid
     * @returns {LinkedSegment}
     */
    getSegmentById(wallSegmentId) {
        for (let segment of this.getAllLinkedSegments()) {
            if (segment.getId() === wallSegmentId) {
                return segment;
            }
        }
        return null;
    }

    /**
     * Method for getting shape of segment if it exists or compute shape and return it
     * @param {LinkedSegment} segmentLink
     */
    getShape(segmentLink) {
        return (
            segmentLink.metaInfo.getShape(segmentLink) ??
            this.#computeAndGetSegmentShape(segmentLink)
        );
    }

    /**
     * Compute join shape for each point and return created shape of segment
     * @param {LinkedSegment} segmentLink
     * @returns {Point[]}
     */
    #computeAndGetSegmentShape(segmentLink) {
        try {
            this.#computePointJoinShape(
                segmentLink.firstPointLinkWrapper,
                this.getConnectedToPointSegments(
                    segmentLink.firstPointLinkWrapper
                ),
                segmentLink
            );
            this.#computePointJoinShape(
                segmentLink.secondPointLinkWrapper,
                this.getConnectedToPointSegments(
                    segmentLink.secondPointLinkWrapper
                ),
                segmentLink
            );
            return segmentLink.metaInfo.getShape(segmentLink);
        } catch (error) {
            console.error(
                `[Rerender error]: can't compute point shape between renders`
            );
        }
    }

    /**
     *
     * @param {PointLinkWrapper} jPoint
     * @param {LinkedSegment[]} connectedSegments
     * @param {LinkedSegment} segmentLink
     */
    #computePointJoinShape(jPoint, connectedSegments, segmentLink) {
        /**
         * vectors that translated to start {x:0, y:0}
         * with correct direction from the start
         */
        const transformedVectors = connectedSegments.map((segment) => {
            const pointsDiff = subtractTwoPoints(
                jPoint.getCoordinates(),
                segment.firstPointLinkWrapper.getCoordinates()
            );
            if (pointsDiff.x === 0 && pointsDiff.y === 0) {
                return Object.freeze({
                    segment,
                    vector: subtractTwoPoints(
                        jPoint.getCoordinates(),
                        segment.secondPointLinkWrapper.getCoordinates()
                    ),
                });
            } else {
                return Object.freeze({ segment, vector: pointsDiff });
            }
        });

        /**
         * segments and their angles
         */
        const segmentsPrepared = transformedVectors.map(
            ({ segment, vector }) => {
                return Object.freeze({
                    segment: segment,
                    vector: vector,
                    angle: findAngleBetweenVectorAndXAxis(vector),
                });
            }
        );

        segmentsPrepared.sort((a, b) => a.angle - b.angle);

        const segmentsOffsets = segmentsPrepared.map((info) => {
            return getOffsetLines(
                { x: 0, y: 0 },
                info.vector,
                scalesConformer.toPixels(info.segment.getType().width) / 2
            );
        });

        const joinOffsetPoints = segmentsOffsets.map((offsets) => {
            return new PointOffsets(offsets.left[0], offsets.right[0]);
        });

        if (connectedSegments.length > 1) {
            for (const [i, segmentInfo] of segmentsPrepared.entries()) {
                const segmentOffset = segmentsOffsets[i];

                const neighborSegmentInfo =
                    segmentsPrepared[(i + 1) % segmentsOffsets.length];
                const neighborOffsets =
                    segmentsOffsets[(i + 1) % segmentsOffsets.length];

                const angleBetweenLines = findAngleBetweenVectors(
                    segmentInfo.vector,
                    neighborSegmentInfo.vector
                );

                // universal condition: min angle between origin line of segmentInfo[i] to neighborSegment
                const condAngle =
                    angleBetweenLines > Math.PI / 2
                        ? Math.PI - angleBetweenLines
                        : angleBetweenLines;

                if (
                    (condAngle < Math.PI / 10 &&
                        angleBetweenLines < Math.PI / 2 &&
                        // corner case when difference between sorting angles are more than PI
                        // it's case when lines drops to start calculation point of angles
                        ((Math.abs(
                            segmentInfo.angle - neighborSegmentInfo.angle
                        ) > Math.PI &&
                            segmentInfo.angle - neighborSegmentInfo.angle <
                                0) ||
                            (Math.abs(
                                segmentInfo.angle - neighborSegmentInfo.angle
                            ) < Math.PI &&
                                segmentInfo.angle - neighborSegmentInfo.angle >
                                    0))) ||
                    condAngle < 1 / 10 ||
                    neighborSegmentInfo.segment.isSegmentEmbedded() ||
                    segmentInfo.segment.isSegmentEmbedded()
                ) {
                    continue;
                }

                // sorting inverse to clockwise
                const intersection = findLinesIntersection(
                    neighborOffsets.right,
                    segmentOffset.left
                ).point;

                joinOffsetPoints[(i + 1) % segmentsOffsets.length].right =
                    intersection;
                joinOffsetPoints[i].left = intersection;
            }
        }

        for (const [i, points] of joinOffsetPoints.entries()) {
            const leftPoint = points.left;
            const rightPoint = points.right;
            const segment = segmentsPrepared[i].segment;

            // translate coordinates system to join and rotate to correct direction
            // сегмента (не нулевая точка после вычитания join point из всех точек сегмента)
            const leftOffsetPoint = addTwoPoints(
                leftPoint,
                jPoint.getCoordinates()
            );
            const rightOffsetPoint = addTwoPoints(
                rightPoint,
                jPoint.getCoordinates()
            );

            segment.metaInfo.putLeftOffsetPoint(
                jPoint.getCoordinates(),
                leftOffsetPoint,
                segment.firstPointLinkWrapper.getCoordinates()
            );
            segment.metaInfo.putRightOffsetPoint(
                jPoint.getCoordinates(),
                rightOffsetPoint,
                segment.firstPointLinkWrapper.getCoordinates()
            );
        }
    }

    /**
     *
     * @param {String} pointId
     */
    deletePoint(pointId) {
        Array.from(this._segmentsMap).forEach(([segmentKey, _]) => {
            const [pointId1, pointId2] = LinkedSegment.splitKey(segmentKey);
            if ([pointId1, pointId2].includes(pointId)) {
                this.deleteSegment(pointId1, pointId2);
            }
        });
    }

    /**
     *
     * @param {PointLinkWrapper} pointLink
     * @returns {LinkedSegment[]}
     */
    getConnectedToPointSegments(pointLink) {
        return this._pointSegments.get(pointLink);
    }

    /**
     * @returns {Iterator<LinkedSegment.key>} keys of segments
     */
    getSegmentsKeys() {
        return deepCopy(this._segmentsMap).keys();
    }

    /**
     * @param {String} pointId1
     * @param {String} pointId2
     * @returns {Boolean} isFetched
     */
    getSegmentIsFetched(pointId1, pointId2) {
        return this.getSegment(pointId1, pointId2).segmentInfo.isFetched;
    }

    /**
     * Synchronously change the point ID in all segments
     * @param {String} oldPointId
     * @param {String} newPointId
     * @param {Boolean} newIdIsFetched
     */
    updatePointId(oldPointId, newPointId, newIdIsFetched = false) {
        if (!this._pointsLinks.has(oldPointId)) return;

        const link = this._pointsLinks.get(oldPointId);

        // delete segments with old id
        this._pointSegments
            .get(link)
            .forEach((segment) => this._segmentsMap.delete(segment.getKey()));

        // change point link to new
        link.id = newPointId;
        link.isFetched = newIdIsFetched;
        this._pointsLinks.delete(oldPointId);
        this._pointsLinks.set(newPointId, link);

        // add segments with changed point link
        this._pointSegments
            .get(link)
            .forEach((segment) =>
                this._segmentsMap.set(segment.getKey(), segment)
            );
    }

    /**
     * @param {String} pointId
     * @param {GraphPointValue} newPointInfo
     */
    updatePointInfo(pointId, newPointInfo) {
        if (!this._pointsLinks.has(pointId)) return;
        this.getPointLinkWrapper(pointId).pointInfo = newPointInfo;
        if (newPointInfo.hasOwnProperty('coordinates')) {
            this.eraseShapeForSegmentsConnectedToPoint(
                this.getPointLinkWrapper(pointId)
            );
        }
    }

    /**
     * @param {String} id
     * @returns {PointLinkWrapper} new PointLink if the link with selected id was not found
     */
    getPointLinkWrapper(id) {
        if (this._pointsLinks.has(id)) {
            return this._pointsLinks.get(id);
        }
        return null;
    }

    /**
     *
     * @param {String} id
     * @param {GraphPointValue} pointInfo
     * @returns {PointLinkWrapper | null} link to point
     */
    createPointLinkWrapper(
        id,
        pointInfo = null,
        isFetched = false,
        isVisible = true
    ) {
        if (!this._pointsLinks.has(id)) {
            const link = new PointLinkWrapper(
                id,
                isFetched,
                pointInfo,
                isVisible
            );
            this._pointsLinks.set(id, link);
            return link;
        }
        return null;
    }

    /**
     * @param {String} pointId1
     * @param {String} pointId2
     * @returns {LinkedSegment | null} with Key of both set points Ids or null if it does not exist
     */
    getSegment(pointId1, pointId2) {
        return this._segmentsMap.get(LinkedSegment.getKey(pointId1, pointId2));
    }

    /**
     * Method for leading coordinates to defined accuracy
     * @param {PointLinkWrapper} pointLink
     * @param {Number} accuracy
     */
    leadToAccuracy(pointLink, accuracy) {
        const coordinates = pointLink.getCoordinates();
        pointLink.pointInfo.coordinates = {
            x: +coordinates.x.toFixed(accuracy),
            y: +coordinates.y.toFixed(accuracy),
        };
    }

    /**
     * Method for leading point coordinates x,y to accuracy
     * @param {Point} coordinates
     * @param {number} accuracy
     * @returns {Point} point coordinates of that are fixed to accuracy
     */
    leadToAccuracyCoordinates(coordinates, accuracy) {
        return {
            x: +coordinates.x.toFixed(accuracy),
            y: +coordinates.y.toFixed(accuracy),
        };
    }

    /**
     * Create the LinkedSegment and link it with other if they have the same adjoin point IDs
     * @param {{id: String, pointInfo: GraphPointValue}} startPoint
     * @param {{id: String, pointInfo: GraphPointValue}} endPoint
     * @param {{id: String, type: WallSegmentType, accessRule: string}} segmentInfo
     */
    createSegment(startPoint, endPoint, segmentInfo = null, isFetched = false) {
        if (startPoint.id === endPoint.id) {
            return;
        }

        let link1 = this.getPointLinkWrapper(startPoint.id);
        let link2 = this.getPointLinkWrapper(endPoint.id);

        if (!link1) {
            link1 = this.createPointLinkWrapper(
                startPoint.id,
                startPoint.pointInfo,
                isFetched
            );
        }
        if (!link2) {
            link2 = this.createPointLinkWrapper(
                endPoint.id,
                endPoint.pointInfo,
                isFetched
            );
        }

        segmentInfo.isFetched = isFetched;
        const segment = new LinkedSegment(link1, link2, segmentInfo);

        /**
         * Method for storing segment that is linked with point
         * or create and store if no such point exists
         * @param {PointLinkWrapper} pointLink
         * @param {LinkedSegment} segment
         */
        const storePointSegment = (pointLink, segment) => {
            if (
                this._pointSegments.has(pointLink) &&
                !this._pointSegments
                    .get(pointLink)
                    .find(
                        (segmentLink) =>
                            (segmentLink.firstPointLinkWrapper.id ===
                                link1.id &&
                                segmentLink.secondPointLinkWrapper.id ===
                                    link2.id) ||
                            (segmentLink.firstPointLinkWrapper.id ===
                                link2.id &&
                                segmentLink.secondPointLinkWrapper.id ===
                                    link1.id)
                    )
            ) {
                this._pointSegments.get(pointLink).push(segment);
            } else {
                this._pointSegments.set(pointLink, [segment]);
            }
        };

        /**
         * Method for storing segment and
         * also it links points by adding ids to adjacentPoints for each other
         * @param {PointLinkWrapper} pointLink1
         * @param {PointLinkWrapper} pointLink2
         * @param {LinkedSegment} segment
         */
        const storeSegment = (pointLink1, pointLink2, segment) => {
            this._segmentsMap.set(
                LinkedSegment.getKey(pointLink1.getId(), pointLink2.getId()),
                segment
            );

            if (!pointLink1.getAdjacentPoints().includes(pointLink2.getId())) {
                pointLink1.pointInfo.adjacentPoints.push(pointLink2.getId());
            }
            if (!pointLink2.getAdjacentPoints().includes(pointLink1.getId())) {
                pointLink2.pointInfo.adjacentPoints.push(pointLink1.getId());
            }
        };

        storePointSegment(link1, segment);
        storePointSegment(link2, segment);
        storeSegment(link1, link2, segment);

        return segment;
    }

    /**
     * Method for updating segments positions on move vector
     * @param {Point} moveVector
     * @param {string[]} edgesIds edges UUIDs that need to be updated
     */
    updateSegmentsOnMoveVector(moveVector, edgesIds) {
        const dragVertexKeys = [
            ...new Set(
                edgesIds
                    .map((selectedEdgeId) => {
                        return LinkedSegment.splitKey(selectedEdgeId);
                    })
                    .flat()
            ),
        ];

        dragVertexKeys.forEach((id) => {
            const pointLink = this.getPointLinkWrapper(id);

            this.updatePointInfo(id, {
                ...pointLink.getInfo(),
                coordinates: {
                    x: pointLink.getCoordinates().x + moveVector.x,
                    y: pointLink.getCoordinates().y + moveVector.y,
                },
            });

            this.eraseShapeForSegmentsConnectedToPoint(pointLink);
        });
    }

    /**
     *
     * @param {String} startPointId
     * @param {String} secondPointId
     * @param {Number} newVersion
     */
    updateVersion(startPointId, secondPointId, newVersion) {
        const segment = this.getSegment(startPointId, secondPointId);

        if (segment && segment.getVersion() < newVersion) {
            segment.updateVersion(newVersion);
            return true;
        }
        return false;
    }

    /**
     * Update segment info and return updated linked segment
     * @param {String} startPointId
     * @param {String} endPointId
     * @param {{id: String, isFetched: Boolean, type: WallSegmentType}} newSegmentInfo
     * @returns {LinkedSegment}
     */
    updateSegmentInfo(startPointId, endPointId, newSegmentInfo) {
        if (startPointId === endPointId) {
            return;
        }

        const linkedSegment = this.getSegment(startPointId, endPointId);
        if (linkedSegment) {
            linkedSegment.segmentInfo = newSegmentInfo;
        }

        return linkedSegment;
    }

    /**
     * Update segment type and return updated linked segment
     * @param {String} startPointId
     * @param {String} endPointId
     * @param {WallSegmentType} newSegmentType
     * @returns
     */
    updateSegmentType(startPointId, endPointId, newSegmentType) {
        if (startPointId === endPointId) {
            return;
        }

        const linkedSegment = this.getSegment(startPointId, endPointId);
        if (linkedSegment) {
            linkedSegment.segmentInfo.type = newSegmentType;
            this.eraseShapeForSegmentsConnectedToPoint(
                linkedSegment.firstPointLinkWrapper
            );
            this.eraseShapeForSegmentsConnectedToPoint(
                linkedSegment.secondPointLinkWrapper
            );
        }

        return linkedSegment;
    }

    /**
     * Method to update mapins schema for segment
     * @param {String} startPointId
     * @param {String} endPointId
     * @param {{data: string, version: number, schema: object}} data user data on mapins schema
     * @param {MapinsSchemaImpls[]} schemaImpls schema implementations
     * @returns {LinkedSegment | null} LinkedSegment or null if segment doesn't exists
     */
    updateSegmentSchema(startPointId, endPointId, data, schemaImpls) {
        const segment = this.getSegment(startPointId, endPointId);
        if (segment) {
            const newSegmentInfo = {
                ...segment.segmentInfo,
                data: deepCopy(data),
                schemaImpls: deepCopy(schemaImpls),
            };
            this.updateSegmentInfo(startPointId, endPointId, newSegmentInfo);
            return segment;
        }
        return null;
    }

    /**
     * Method for getting iterator for segment map
     * @returns {Iterator<[LinkedSegment.key, LinkedSegment]>} Iterator segments map entries
     */
    getSegmentsMapEntries() {
        return new Map(this._segmentsMap).entries();
    }

    /**
     * Method for getting iterator for all Linked Segments
     * @returns {Iterator<LinkedSegment>} Iterator LinkedSegments values
     */
    getAllLinkedSegments() {
        return new Map(this._segmentsMap).values();
    }

    /**
     * @returns {Iterator<PointLinkWrapper>} points links values
     */
    getAllPointsLinks() {
        return new Map(this._pointsLinks).values();
    }

    /**
     * Delete LinkedSegment from track by current moderator
     * @param {String} pointId1
     * @param {String} pointId2
     */
    deleteSegment(pointId1, pointId2) {
        if (pointId1 === pointId2) {
            return;
        }
        const segment = this._segmentsMap.get(
            LinkedSegment.getKey(pointId1, pointId2)
        );
        if (segment === null) return;

        const link1 = this._pointsLinks.get(pointId1);
        const link2 = this._pointsLinks.get(pointId2);

        const pointsGarbageCollecting = (link) => {
            let pointSegments = this._pointSegments.get(link);

            // remove segment from segments list
            pointSegments.splice(pointSegments.indexOf(segment), 1);

            // if no segments ref a point delete the point
            if (pointSegments.length === 0) {
                this._pointsLinks.delete(link.id);
                this._pointSegments.delete(link);
            }
        };
        pointsGarbageCollecting(link1);
        pointsGarbageCollecting(link2);

        // deleting segment between two points should be followed with
        // removing first point from second and second from first from there adjacent points
        if (link1) {
            link1
                .getInfo()
                .adjacentPoints.splice(
                    link1.getAdjacentPoints().indexOf(pointId2),
                    1
                );
            this.eraseShapeForSegmentsConnectedToPoint(link1);
        }

        if (link2) {
            link2
                .getInfo()
                .adjacentPoints.splice(
                    link2.getAdjacentPoints().indexOf(pointId1),
                    1
                );
            this.eraseShapeForSegmentsConnectedToPoint(link2);
        }

        this._segmentsMap.delete(LinkedSegment.getKey(pointId1, pointId2));
    }

    /**
     * Method for erasing shape info for recompute shape
     * @param {PointLinkWrapper} pointLink
     */
    eraseShapeForSegmentsConnectedToPoint(pointLink) {
        this.getConnectedToPointSegments(pointLink)?.forEach((segment) => {
            segment.metaInfo.erasePointInfo();
        });
    }

    /**
     * Method for wall division
     * @param {Point} clickPosition point
     * @param {String} donorSegmentKey key of edge for divide
     * @returns {{newPoint: PointLinkWrapper, createdSegments: LinkedSegment[], updatedSegments: LinkedSegment[]} | null}
     * id of point of wall division
     */
    divideSegment(clickPosition, donorSegmentKey) {
        if (!clickPosition || !donorSegmentKey) {
            return null;
        }

        /**
         * @type {LinkedSegment[]}
         */
        const createdSegments = [];

        const [pointId1, pointId2] = LinkedSegment.splitKey(donorSegmentKey);

        /**
         * @type {LinkedSegment}
         */
        const dividingSegment = this.copySegment(
            this.getSegment(pointId1, pointId2)
        );

        // User can divide wall only if he has access rules
        if (dividingSegment.segmentInfo.accessRule !== RULE_EDIT) {
            notifications.setError(
                'Ошибка',
                'У вас нет прав на деление этой стены'
            );
            return null; // TODO need to catch errors
        }

        /**
         * add new vertex and connect with other vertices
         * delete prev connections between two last vertices
         */
        const newPointLink = this.createPointLinkWrapper(uuid(), {
            coordinates: clickPosition,
            adjacentPoints: [pointId1, pointId2],
            accessRule: RULE_EDIT,
        });

        const newPointInfo1 = {
            ...dividingSegment.firstPointLinkWrapper.getInfo(),
            adjacentPoints: [
                ...dividingSegment.firstPointLinkWrapper
                    .getInfo()
                    .adjacentPoints.filter(
                        (id) =>
                            id !==
                            dividingSegment.secondPointLinkWrapper.getId()
                    ),
                newPointLink.id,
            ],
        };

        const newPointInfo2 = {
            ...dividingSegment.secondPointLinkWrapper.getInfo(),
            adjacentPoints: [
                ...dividingSegment.secondPointLinkWrapper
                    .getInfo()
                    .adjacentPoints.filter(
                        (id) =>
                            id !== dividingSegment.firstPointLinkWrapper.getId()
                    ),
                newPointLink.id,
            ],
        };

        this.deleteSegment(pointId1, pointId2);
        createdSegments.push(
            this.createSegment(
                {
                    id: dividingSegment.firstPointLinkWrapper.getId(),
                    pointInfo: newPointInfo1,
                },
                { id: newPointLink.id, pointInfo: newPointLink.getInfo() },
                {
                    type: dividingSegment.getType(),
                    isFetched: false,
                    accessRule: dividingSegment.getInfo().accessRule,
                }
            ),
            this.createSegment(
                { id: newPointLink.id, pointInfo: newPointLink.getInfo() },
                {
                    id: dividingSegment.secondPointLinkWrapper.getId(),
                    pointInfo: newPointInfo2,
                },
                {
                    type: dividingSegment.getType(),
                    isFetched: false,
                    accessRule: dividingSegment.getInfo().accessRule,
                }
            )
        );

        return {
            newPoint: newPointLink,
            createdSegments,
            updatedSegments: [dividingSegment],
        };
    }

    /**
     * Function to form array of points (lineString) for old functions
     * @param {String} key uuid key of the edge
     * @returns {Point[]}
     */
    getSegmentLineString(key) {
        const [pointId1, pointId2] = LinkedSegment.splitKey(key);

        return [
            this.getPointLinkWrapper(pointId1).getCoordinates(),
            this.getPointLinkWrapper(pointId2).getCoordinates(),
        ];
    }

    /**
     * Method that cut segment from donor's segment
     * @param {Point} point1
     * @param {Point} point2
     * @param {String} donorWallKey
     * @returns {LinkedSegment}
     */
    cutSegment(point1, point2, donorWallKey) {
        const [donorWallPointId1, donorWallPointId2] =
            LinkedSegment.splitKey(donorWallKey);

        const donorSegment = this.getSegment(
            donorWallPointId1,
            donorWallPointId2
        );

        let firstPointId = '';
        let secondPointId = '';

        if (
            arePointsEqual(
                point1,
                donorSegment.firstPointLinkWrapper.getCoordinates()
            )
        ) {
            firstPointId = donorWallPointId1;
        } else if (
            arePointsEqual(
                point1,
                donorSegment.secondPointLinkWrapper.getCoordinates()
            )
        ) {
            firstPointId = donorWallPointId2;
        } else {
            const { newPoint } = this.divideSegment(point1, donorWallKey);
            firstPointId = newPoint.id;
        }

        if (
            isPointOnLine(
                point2,
                this.getSegmentLineString(
                    `${firstPointId},${donorWallPointId1}`
                )
            )
        ) {
            const { newPoint } = this.divideSegment(
                point2,
                `${firstPointId},${donorWallPointId1}`
            );
            secondPointId = newPoint.id;
        } else {
            const { newPoint } = this.divideSegment(
                point2,
                `${firstPointId},${donorWallPointId2}`
            );
            secondPointId = newPoint.id;
        }

        return this.getSegment(firstPointId, secondPointId);
    }

    copyPoint(pointLink) {
        return PointLinkWrapper.createByPointLinkWrapper(pointLink);
    }

    copySegment(segmentLink) {
        return LinkedSegment.createByLinkedSegment(segmentLink);
    }

    /**
     * Method for merge two vertices. SrcVertex will be merged into destVertex.
     * Merging includes: deleting edge (wall) between vertices, reconnect edges (walls) and update zone.
     * After executing this function, srcVertex will be deleted
     *
     * @param {String} srcVertexId uuid of src vertex
     * @param {String} destVertexId uuid of destination vertex
     */
    collapseSegment(srcVertexId, destVertexId) {
        // if wall between two merging vertex exists, this wall should be deleted
        this.deleteSegment(srcVertexId, destVertexId);
        const srcPointLink = this.getPointLinkWrapper(srcVertexId);
        const destPointLink = this.getPointLinkWrapper(destVertexId);

        if (srcPointLink && destPointLink) {
            this.reconnectSegmentsToPoint(srcPointLink, destPointLink);
        } else if (srcPointLink && !destPointLink) {
            this.updatePointId(srcVertexId, destVertexId);
        }
    }

    /**
     * Method for reconnecting all segments connected to srcPoint to destPoint
     * @param {PointLinkWrapper} srcPointLink
     * @param {PointLinkWrapper} destPointLink
     * @returns {{
     *              segment: LinkedSegment,
     *              newFirstPoint: PointLinkWrapper,
     *              newSecondPoint: PointLinkWrapper,
     *           }}
     */
    reconnectSegmentsToPoint(srcPointLink, destPointLink) {
        const segmentsToUpdate = [];
        // remove srcPointId from adjacentPoints of connected to srcPoint points
        srcPointLink.getInfo().adjacentPoints.forEach((pointId) => {
            const adjPoints =
                this.getPointLinkWrapper(pointId)?.getInfo().adjacentPoints;

            if (adjPoints) {
                adjPoints.splice(adjPoints.indexOf(srcPointLink.getId()), 1);
            }
        });

        const copyOfConnectedToSrcPointSegments =
            this.getConnectedToPointSegments(srcPointLink).map((segment) => {
                return this.copySegment(segment);
            });

        copyOfConnectedToSrcPointSegments.forEach((segment) => {
            this.deleteSegment(
                segment.firstPointLinkWrapper.getId(),
                segment.secondPointLinkWrapper.getId()
            );
        });

        copyOfConnectedToSrcPointSegments.forEach((segment) => {
            if (
                segment.firstPointLinkWrapper.getId() === srcPointLink.getId()
            ) {
                segmentsToUpdate.push({
                    segment: this.copySegment(segment),
                    newFirstPoint: destPointLink,
                    newSecondPoint: segment.secondPointLinkWrapper,
                });
                segment.firstPointLinkWrapper = destPointLink;
            } else if (
                segment.secondPointLinkWrapper.getId() === srcPointLink.getId()
            ) {
                segmentsToUpdate.push({
                    segment: this.copySegment(segment),
                    newFirstPoint: segment.firstPointLinkWrapper,
                    newSecondPoint: destPointLink,
                });
                segment.secondPointLinkWrapper = destPointLink;
            }

            this.createSegment(
                {
                    id: segment.firstPointLinkWrapper.getId(),
                    pointInfo: segment.firstPointLinkWrapper.getInfo(),
                },
                {
                    id: segment.secondPointLinkWrapper.getId(),
                    pointInfo: segment.secondPointLinkWrapper.getInfo(),
                },
                segment.getInfo(),
                true
            );
        });

        // if zones has src vertex as its border, this zone should be modified
        zones.zonesVar().forEach((zone, zoneId) => {
            if (zone.props.borders.includes(srcPointLink.getId())) {
                zones.replaceVertexInZoneBorder(
                    zoneId,
                    srcPointLink.getId(),
                    destPointLink.getId()
                );
            }
        });

        return segmentsToUpdate.length > 0 ? segmentsToUpdate : null;
    }

    /**
     * Merge points that haven't got segment between and
     * segments are not overlapping after merge
     * @param {String} srcVertexId uuid of src vertex
     * @param {String} destVertexId uuid of destination vertex
     */
    mergePointsOfUnconnectedSegments(srcVertexId, destVertexId) {
        const srcPointLink = this.getPointLinkWrapper(srcVertexId);
        const destPointLink = this.getPointLinkWrapper(destVertexId);
        if (srcPointLink && destPointLink) {
            const segmentsToUpdate = this.reconnectSegmentsToPoint(
                srcPointLink,
                destPointLink
            );
            this.eraseShapeForSegmentsConnectedToPoint(destPointLink);
            return segmentsToUpdate;
        }
        return null;
    }

    /**
     * Get segments that are overlapping
     * (need for deleting segments that overlaps while operation of merging points)
     * @param {String} srcVertexId uuid of src vertex
     * @param {String} destVertexId uuid of destination vertex
     * @returns {LinkedSegment[] | null}
     */
    getOverlappingSegments(srcVertexId, destVertexId) {
        const srcAdjIds =
            this.getPointLinkWrapper(srcVertexId).getAdjacentPoints();
        const destAdjIds =
            this.getPointLinkWrapper(destVertexId).getAdjacentPoints();

        const adjIntersections = destAdjIds.filter((adjPId) =>
            srcAdjIds.includes(adjPId)
        );

        const overlappingSegments = [];

        for (const pId of adjIntersections) {
            const overlappingSegment = this.getSegment(pId, srcVertexId);
            if (overlappingSegment) {
                overlappingSegments.push(overlappingSegment);
            }
        }
        return overlappingSegments.length > 0 ? overlappingSegments : null;
    }

    clearAll() {
        this._segmentsMap.clear();
        this._pointSegments.clear();
        this._pointsLinks.clear();
    }
}

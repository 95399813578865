import floorGraph from '../../../Wall/model/FloorGraph';
import selector from '../../model/Selector';

export const handleScrollSelect = (e) => {
    e.evt.preventDefault();

    if (e.evt.altKey || e.evt.metaKey) {
        selector.selectByKeyboardKeyAndScroll(e, floorGraph);
    }
};

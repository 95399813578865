import React from 'react';
import { useReactiveVar } from '@apollo/client';
import routesModerator from '../model/RoutesModerator';
import { CREATING_MODE } from '../../../shared/lib/utils/ModeDefaultValues';
import { currentToolVar, modeVar } from '../../../shared/model/cache/Cache';
import { Tools } from '../../../pages/Constructor/TopBar/TopBarEntires/Tools/Constants/Tools';
import { Route } from './Route';

/**
 * Component that represents all routes
 * @component
 */
export const Routes = () => {
    const routesMap = useReactiveVar(routesModerator.routesMapVar);

    // vertices should show only in create mode
    // vertices should be hidden while wall tool is active
    const isVerticesShow =
        modeVar() === CREATING_MODE &&
        currentToolVar() !== Tools.wall &&
        currentToolVar() !== Tools.rectangleRoom;

    return Array.from(routesMap, ([id, route]) => (
        <Route key={id} route={route} isVerticesShow={isVerticesShow} />
    ));
};

import { gql } from '@apollo/client';

export const UPDATE_WALL_SEGMENT = gql`
    mutation updateWallSegment($wallSegmentDiff: WallSegmentDiff!) {
        updateWallSegment(wallSegmentDiff: $wallSegmentDiff) {
            updatedSegments {
                name
                version
                endPoint {
                    coordinate {
                        x
                        y
                    }
                    id
                    accessRules {
                        group {
                            id
                        }
                        rule
                    }
                }
                id
                lengthRestriction
                startPoint {
                    coordinate {
                        x
                        y
                    }
                    id
                    accessRules {
                        group {
                            id
                        }
                        rule
                    }
                }
                width
                through
                secondZone {
                    id
                }
                firstZone {
                    id
                }
                type {
                    id
                    length
                    width
                    scalable
                    through
                    virtual
                    imageOffsetCoefficient
                    image {
                        id
                        src
                        type
                    }
                    techImage {
                        id
                        src
                        type
                    }
                }
                accessRules {
                    group {
                        id
                    }
                    rule
                }
            }
            zones {
                id
                version
                shape {
                    outer {
                        id
                    }
                }
            }
        }
    }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import routesModerator from '../model/RoutesModerator';
import { RouteSegments } from './RouteSegments';
import { RouteVertices } from './RouteVertices';
import { TemporaryWallSegment } from '../../Wall/ui/WallSegments/TemporaryWallSegment';
import { RouteGraphWrapper } from '../model/RouteGraphWrapper';

/**
 * Component that represents one concrete route.
 * Create for rote segments, vertices, etc.
 * @component
 */
export const Route = ({ route, isVerticesShow }) => {
    const currentRouteId = routesModerator.currentRouteIdVar();
    const tmpEdge = useReactiveVar(route.graph.tmpEdgeVar);

    return (
        <>
            <RouteSegments route={route} />
            {!!tmpEdge && tmpEdge.size > 0 && (
                <TemporaryWallSegment graph={route.graph} />
            )}
            {isVerticesShow &&
                // hide all vertices except active route (if active route set)
                (!currentRouteId || currentRouteId === route.graph.routeId) && (
                    <RouteVertices
                        graph={route.graph}
                        accessRule={route.accessRule}
                    />
                )}
        </>
    );
};

Route.propTypes = {
    /** Route instance */
    route: PropTypes.instanceOf(RouteGraphWrapper),

    /** Need to show vertices or not */
    isVerticesShow: PropTypes.bool,
};

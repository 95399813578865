import selector from '../../../../Selector/model/Selector';

/**
 * Method to save start position of edge
 * @param {Object} e event of the konva node
 */
export const handleDragStart = (e) => {
    const graphs = selector.groupEdgesByGraphs();
    graphs.forEach((_, graph) => {
        graph.edgePrevPos = { ...e.target.position() };
        graph.dragStartPos = { ...e.target.position() };
    });
};

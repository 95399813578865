import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import defaultIcon from '../../../../../../shared/assets/images/icons/folder.svg';
import eyeCloseIcon from '../../../../../../shared/assets/images/icons/eye-closed.svg';
import eyeOpenIcon from '../../../../../../shared/assets/images/icons/eye-opened.svg';
import collapseRightArrowIcon from '../../../../../../shared/assets/images/icons/ConstructorPage/LeftBar/collapse-zone-right-arrow.svg';
import collapseDownArrowIcon from '../../../../../../shared/assets/images/icons/ConstructorPage/LeftBar/collapse-zone-down-arrow.svg';
import zoneOptionsIcon from '../../../../../../shared/assets/images/icons/ConstructorPage/LeftBar/options-three-dots.svg';
import { HeaderEditInput } from './HeaderEditInput';

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 31px;
    border-radius: 9px;
    border: 0.5px solid transparent;
    z-index: 2;

    :hover {
        border: 0.5px solid #023aff;
    }

    &:hover .eyeIcon {
        visibility: visible;
    }
`;

const CollapseIcon = styled.img`
    width: 7px;
    padding-left: 5px;
    padding-right: 10px;
    cursor: pointer;
`;

const GroupIcon = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 8px;
    cursor: pointer;
`;

const GroupName = styled.span`
    font-family: 'Open Sans';
    font-style: normal;
    color: rgba(0, 0, 0, 0.8);
    font-size: 16px;
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
`;

const EyeIcon = styled.img`
    width: 17px;
    height: 11px;
    margin-left: 4px;
    visibility: ${(props) => (props.eyeOpened ? 'hidden' : 'visible')};
    cursor: pointer;
`;

const OptionsIcon = styled.img`
    width: 3px;
    padding-left: 10px;
    padding-right: 5px;
    cursor: pointer;
`;

/**
 * Component that represents OpeningGroup header.
 * Contains title (text), eye and collapse icons
 * @component
 */
export const OpeningGroupHeader = ({
    title = 'Группа без названия',
    iconSrc = defaultIcon,
    isItemsCollapsed = false,
    handleCollapseClick = () => {},
    isEditable = true,
    onTitleEditFinish = () => {},
    optionsRef,
    onOptionsClick = () => {},
}) => {
    const [eyeOpened, setEyeOpened] = useState(true);
    const [isTitleEditing, setIsTitleEditing] = useState(false);

    const handleEyeClick = () => {
        setEyeOpened(!eyeOpened);
    };

    const handleTitleEdit = () => {
        setIsTitleEditing(true);
    };

    return (
        <HeaderContainer>
            <CollapseIcon
                src={
                    isItemsCollapsed
                        ? collapseDownArrowIcon
                        : collapseRightArrowIcon
                }
                alt='развернуть'
                className='collapseIcon'
                onClick={handleCollapseClick}
            />

            <GroupIcon src={iconSrc} alt='' onClick={handleCollapseClick} />

            {isEditable && isTitleEditing ? (
                <HeaderEditInput
                    title={title}
                    setIsTitleEditing={setIsTitleEditing}
                    onTitleEditFinish={onTitleEditFinish}
                />
            ) : (
                <GroupName
                    title={title}
                    onClick={handleCollapseClick}
                    onDoubleClick={handleTitleEdit}
                >
                    {title}
                </GroupName>
            )}

            {isEditable && (
                <>
                    <EyeIcon
                        className='eyeIcon'
                        src={eyeOpened ? eyeOpenIcon : eyeCloseIcon}
                        title='показать/скрыть'
                        alt='показать/скрыть'
                        eyeOpened={eyeOpened}
                        onClick={handleEyeClick}
                    />
                    <OptionsIcon
                        src={zoneOptionsIcon}
                        alt={'настройки'}
                        onClick={onOptionsClick}
                        ref={optionsRef}
                    />
                </>
            )}
        </HeaderContainer>
    );
};

OpeningGroupHeader.propTypes = {
    /** Showing title */
    title: PropTypes.string,

    /** Src path for group img */
    iconSrc: PropTypes.string,

    /** Clicked on collapse flag state */
    isItemsCollapsed: PropTypes.bool,

    /** Set clicked on collapse flag state */
    handleCollapseClick: PropTypes.func,

    /** Is group name available to edit */
    isEditable: PropTypes.bool,

    /**
     * Callback function for finish editing group title
     * @type {(newTitle: string) => void}
     */
    onTitleEditFinish: PropTypes.func,

    /** Ref for options img */
    optionsRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),

    /**
     * Callback function for click on context menu
     * @type {() => void}
     */
    onOptionsClick: PropTypes.func,
};

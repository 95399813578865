import { SESSION_TOKEN } from '../../../shared/lib/LocalStorageConstants';
import { client } from '../../../shared/graphql/ApolloClient';
import { CREATE_MAP_OBJECT } from '../../../shared/graphql/mutations/mapObjects/CreateMapObject';
import { DELETE_MAP_OBJECT } from '../../../shared/graphql/mutations/mapObjects/DeleteMapObject';
import { UPDATE_MAP_OBJECT } from '../../../shared/graphql/mutations/mapObjects/UpdateMapObject';
import { GET_MAP_OBJECTS } from '../../../shared/graphql/queries/mapObjects/GetMapObjects';
import { FLOOR_OBJECT_UPDATES } from '../../../shared/graphql/subscriptions/FloorObjectsSubscription';
import { currentFloorVar } from '../../../shared/model/cache/Cache';
import { getRuleForCurrentUser } from '../../AccessGroup/lib/GetRuleForCurrentUser';
import notifications from '../../HintSystem/model/Notifications';

/**
 * @typedef {import('../../../shared/typedefs/Types').Point} Point
 * @typedef {import('../model/Types').MapObjectType} MapObjectType
 * @typedef {import('../model/Types').MapObjectData} MapObject
 * @typedef {import('../model/Types').MapObjectDraft} MapObjectDraft
 * @typedef {import('../model/Types').MapObjectDiff} MapObjectDiff
 */

/**
 * @typedef {{id: String, version: Number}} VersionedMapObject
 */

/**
 * Class that realizes api methods for map objects
 * @class
 */
class MapObjectsAPI {
    mapObjectsUpdatesSubscription;

    /**
     * Method that adapt object received from server to project format
     * @param {object} data Data from server
     * @returns {MapObject}
     */
    adaptData(data) {
        data = { ...data };
        if (data.accessRules) {
            data.accessRule = getRuleForCurrentUser(data.accessRules);
            delete data.accessRules;
        }
        return data;
    }

    /**
     * Method for fetching map objects for floor
     * @param {String} floorId - floor UUID
     * @returns {MapObject[] | null} Array of mapObjects | null
     */
    async get(floorId) {
        const options = {
            query: GET_MAP_OBJECTS,
            variables: {
                floorId: floorId,
            },
            fetchPolicy: 'network-only',
        };

        let mapObjects = null;
        try {
            mapObjects = (await client.query(options)).data.mapObjects;
            mapObjects = mapObjects.filter(
                (obj) => obj.__typename === 'MapObject'
            );
            mapObjects = mapObjects.map((obj) => this.adaptData(obj));
        } catch (error) {
            notifications.setError(
                'Не удалось получить объекты карты',
                error.message
            );
            console.log(error);
        }

        return mapObjects;
    }

    /**
     * Method for creating new map object
     * @param {MapObjectDraft} mapObject
     * @returns {MapObject | null}
     */
    async create(mapObject) {
        const options = {
            mutation: CREATE_MAP_OBJECT,
            variables: {
                mapObject: {
                    center: {
                        x: mapObject.position.x,
                        y: mapObject.position.y,
                    },
                    name: mapObject.name,
                    width: mapObject.width,
                    length: mapObject.height,
                    floorId: currentFloorVar().id,
                    typeId: mapObject.type,
                },
            },
        };

        let newMapObject = null;
        try {
            newMapObject = (await client.mutate(options)).data.createMapObject;
            newMapObject = this.adaptData(newMapObject);
        } catch (error) {
            notifications.setError('Объект карты не создан', error.message);
            console.log(error);
        }

        return newMapObject;
    }

    /**
     * Method for deleting map object
     * @param {VersionedMapObject} mapObjectVersioned - UUID of map object
     * @returns {VersionedMapObject} id of deleted object | null
     */
    async delete(mapObjectVersioned) {
        const options = {
            mutation: DELETE_MAP_OBJECT,
            variables: {
                id: mapObjectVersioned.id,
                version: mapObjectVersioned.version,
            },
        };

        let result = null;
        try {
            result = (await client.mutate(options)).data.deleteMapObject;
        } catch (error) {
            notifications.setError('Объект карты не удалён', error.message);
            console.log(error);
        }

        return result;
    }

    /**
     * Common method for updating map object
     * @param {MapObjectDiff} mapObjectDiff - variables according to docs
     * @returns {MapObject | null}
     */
    async update(mapObjectDiff) {
        const options = {
            mutation: UPDATE_MAP_OBJECT,
            variables: {
                mapObjectDiff: { ...mapObjectDiff },
            },
        };

        let newMapObject = null;
        try {
            newMapObject = (await client.mutate(options)).data.updateMapObject;
            newMapObject = this.adaptData(newMapObject);
        } catch (error) {
            notifications.setError('Объект карты не изменён', error.message);
            console.log(error);
        }

        return newMapObject;
    }

    /**
     * Method for updating map object position
     * @param {VersionedMapObject} mapObjectVersioned - UUID of map object
     * @param {Point} newPosition - new coordinates
     * @returns {MapObject | null}
     */
    async updatePosition(mapObjectVersioned, newPosition) {
        let newMapObject = null;
        try {
            newMapObject = await this.update({
                id: mapObjectVersioned.id,
                version: mapObjectVersioned.version,
                center: {
                    x: newPosition.x,
                    y: newPosition.y,
                },
            });
            newMapObject = this.adaptData(newMapObject);
        } catch (error) {
            notifications.setError('Объект карты не перемещён', error.message);
            console.log(error);
        }

        return newMapObject;
    }

    /**
     * Method for updating map object scale and size
     * @param {VersionedMapObject} mapObjectVersioned - UUID of map object
     * @param {Number} width
     * @param {Number} length
     * @param {Number} angle
     * @param {Point} position
     * @returns {MapObject | null}
     */
    async updateTransformation(
        mapObjectVersioned,
        width,
        length,
        angle,
        position
    ) {
        let newMapObject = null;

        try {
            newMapObject = await this.update({
                id: mapObjectVersioned.id,
                version: mapObjectVersioned.version,
                angle: angle,
                width: width,
                length: length,
                center: {
                    x: position.x,
                    y: position.y,
                },
            });
            newMapObject = this.adaptData(newMapObject);
        } catch (error) {
            notifications.setError(
                'Объект карты не трансформирован',
                error.message
            );
            console.log(error);
        }

        return newMapObject;
    }

    // TODO: rewrite according to new api
    async subscribeToMapObjectsUpdates() {
        const observer = client.subscribe({
            query: FLOOR_OBJECT_UPDATES,
            variables: {
                subscriptionItem: {
                    floorId: currentFloorVar().id,
                    token: localStorage.getItem(SESSION_TOKEN),
                },
            },
        });

        // TODO: remove log, add saving data to store
        this.mapObjectsUpdatesSubscription = observer.subscribe(({ data }) => {
            console.log(data);
        });
    }
}

const mapObjectsAPI = new MapObjectsAPI();
export default mapObjectsAPI;

import React, { useEffect, useRef } from 'react';
import { Rect } from 'react-konva';
import { SELECTED_COLOR } from '../../Wall/lib/helpers/WallDefaultValues';
import selector from '../model/Selector';

import { subtractTwoPoints } from '../../../shared/lib/modules/math/Math';

/**
 * Rect that is used in select tool for selecting edges that are inside it.
 * @component
 */
export const RectSelector = () => {
    const diff = subtractTwoPoints(
        selector.rectSelectorPointsVar()[0],
        selector.rectSelectorPointsVar()[1]
    );

    const rectSelectorRef = useRef(null);

    useEffect(() => {
        selector.rectSelectorVar(rectSelectorRef.current);

        return () => {
            selector.rectSelectorVar(null);
        };
    }, []);

    return (
        <Rect
            stroke={'blue'}
            strokeWidth={2.5}
            ref={rectSelectorRef}
            x={selector.rectSelectorPointsVar()[0].x}
            y={selector.rectSelectorPointsVar()[0].y}
            width={diff.x}
            height={diff.y}
            opacity={0.1}
            dash={[10, 15]}
            fill={SELECTED_COLOR}
            shadowEnabled={false}
        />
    );
};

import React, { useState } from 'react';
import styled from 'styled-components';
import { FormComponent } from './FormComponentWithHint';
import { inputStatus } from './FormConstants';

/**
 * Styled input
 */
export const InputStyled = styled.input`
    flex: 1;
    min-width: 322px;

    border: 0;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.7);
    padding: 4px 0;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;

    color: #000000;
    opacity: 0.7;

    &:focus {
        outline: none;
        border-color: #023aff;
    }

    &:focus::placeholder {
        color: transparent;
    }

    &.success {
        border-color: #00940f;
    }

    &.error {
        border-color: red;
    }

    &.warning {
        border-color: yellow;
    }
`;

/**
 * Input form component with validator message
 * @returns { JSX.Element } Input component for forms
 */
export const Input = ({
    name,
    value,
    type = 'text',
    placeholder,
    onChange,
    onFocus,
    forceHint = false,
    hintMsgType = inputStatus.hint,
    hintMsg,
    className,
    ...props
}) => {
    // component don't show message if it's not touched
    const [isTouched, setIsTouched] = useState(false);

    /**
     * Input focus handler
     */
    const focusHandler = (e) => {
        if (!isTouched) setIsTouched(true);
        if (onFocus) onFocus(e);
    };

    let inputClassName = ''; // needed to specify input border color
    if ((isTouched || forceHint) && hintMsg)
        inputClassName =
            hintMsgType === inputStatus.error ||
            hintMsgType === inputStatus.warning ||
            hintMsgType === inputStatus.success
                ? hintMsgType
                : inputStatus.hint;

    return (
        <FormComponent
            hintMsgType={inputClassName}
            hintMsg={(isTouched || forceHint) && hintMsg}
            className={className}
        >
            <InputStyled
                name={name}
                value={value}
                type={type}
                placeholder={placeholder}
                onChange={onChange}
                onFocus={(e) => focusHandler(e)}
                className={inputClassName}
                {...props}
            ></InputStyled>
        </FormComponent>
    );
};

import { useCallback, useState } from "react";

/**
 * Custom ref callback hook.
 * Use it instead of useRef, when you need to trigger component render after ref change.
 * @param {any} [initialValue=null]
 * 
 * @returns {[value: any, refCallback: function]} [value, refCallback]
 * 
 * @example
 * const Component = () => {
 *     const [value, ref] = useRefCallback();
 * 
 *     useEffect(()=> {
 *         // do smth with value
 *     }, [value]);
 * 
 *     return (
 *         <div ref={ref}>
 *             element
 *         </div>
 *     );
 * };
 */
export const useRefCallback = (initialValue = null) => {
    const [value, setValue] = useState(initialValue);
    const refCallback = useCallback((node) => {
        if (node !== null) {
            setValue(node);
        }
    }, []);
    return [value, refCallback];
};
import rightBarModerator from '../../../../pages/Constructor/RightBar/model/RightBarModerator';
import { RightBarOperation } from '../../../../pages/Constructor/RightBar/model/RightBarOperations';
import { RBSegmentProperties } from '../../../../pages/Constructor/RightBar/ui/Operation/SegmentProperties/RBSegmentProperties';
import selector from '../../../Selector/model/Selector';

/**
 * Function that opens right bar with segment properties
 * @param {string} segmentId UUID of segment for which need to open properties
 */
export const openSegmentProperties = (segmentId) => {
    // open segment props only if right bar doesn't have operation now
    const operation = rightBarModerator.operation();
    if (
        !operation ||
        operation.operationName === RightBarOperation.SEGMENT_PROPERTIES
    ) {
        selector.addLastSelectedSegment(segmentId);
        rightBarModerator.setOperation({
            operationName: RightBarOperation.SEGMENT_PROPERTIES,
            customRightBar: true,
            component: <RBSegmentProperties />,
        });
    }
};

/** Function that closes right bar with segment properties (if it open) */
export const closeSegmentProperties = () => {
    if (
        rightBarModerator.operation()?.operationName ===
        RightBarOperation.SEGMENT_PROPERTIES
    ) {
        selector.removeLastSelectedSegment();
        rightBarModerator.clearOperation();
    }
};

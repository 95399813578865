import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Logo } from './Logo';
import { Title } from './Title';

/**
 * Styled container
 * This is needed to make the Body centralized
 */
const Container = styled.div`
    width: 100%;
    height: 100%;

    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
`;

/**
 * Styled body
 */
const Body = styled.div`
    width: 440px;
    min-height: 585px;

    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;

/**
 * Component to centralized container for form
 * @returns centralized container for form
 */
export const FormContainer = ({ children, title, ...props }) => {
    return (
        <Container>
            <Body>
                <Logo />
                <Title {...props}>{title}</Title>
                {children}
            </Body>
        </Container>
    );
};

FormContainer.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.arrayOf(PropTypes.object),
    ]),
    title: PropTypes.string,
    props: PropTypes.arrayOf(PropTypes.object),
};

import {
    stagePositionVar,
    stageScaleVar,
    stageVar,
} from '../../../../shared/model/cache/Cache';
import { updateStageSize } from './UpdateStageSize';

/**
 * This function scaling stage from center
 * @param {number} newScale
 */
export const scaleCanvasFromCenter = (newScale) => {
    const oldScale = stageScaleVar();

    const stagePositionX = stagePositionVar().x / oldScale;
    const stagePositionY = stagePositionVar().y / oldScale;

    const oldStageWidth = stageVar().width() / oldScale;
    const oldStageHeight = stageVar().height() / oldScale;

    const newStageWidth = stageVar().width() / newScale;
    const newStageHeight = stageVar().height() / newScale;

    const newStagePosition = {
        x: (stagePositionX - (oldStageWidth - newStageWidth) / 2) * newScale,
        y: (stagePositionY - (oldStageHeight - newStageHeight) / 2) * newScale,
    };

    stagePositionVar(newStagePosition);
    stageScaleVar(newScale);
    updateStageSize();
};

import { DragTools } from '../../../../pages/Constructor/TopBar/TopBarEntires/Tools/Constants/Tools';
import { currentDragTypeVar, stageVar } from '../../../model/cache/Cache';

/** Describes cursors available types */
export const CURSORS = {
    NOT_ALLOWED: 'not-allowed',
    DEFAULT: 'default',
    POINTER: 'pointer',
    GRAB: 'grab',
    GRABBING: 'grabbing',
};

/**
 * Class to manage cursor.
 * Use this class methods to change cursor
 * @class
 */
class CursorModerator {
    constructor() {
        /**
         * Var store default cursor value
         * @type {string}
         */
        this.defaultCursor = CURSORS.DEFAULT;
    }

    /**
     * Method to change default cursor
     * @param {string} cursor on of CURSORS
     */
    changeDefaultCursor(cursor = CURSORS.DEFAULT) {
        this.defaultCursor = cursor;
        this.setDefault();
    }

    /**
     * Method to auto calculate default cursor, based on drag type var.
     * Use this method, if you change default cursor and need to return base default
     * @see currentDragTypeVar
     */
    autoCalcAndChangeDefaultCursor() {
        this.defaultCursor =
            currentDragTypeVar() === DragTools.hand
                ? CURSORS.GRAB
                : CURSORS.DEFAULT;
        this.setDefault();
    }

    /**
     * Set default cursor.
     * @see this.defaultCursor
     */
    setDefault() {
        this.#setCursor(this.defaultCursor);
    }

    /** Set pointer cursor */
    setPointer() {
        this.#setCursor(CURSORS.POINTER);
    }

    /** Set not allowed cursor */
    setNotAllowed() {
        this.#setCursor(CURSORS.NOT_ALLOWED);
    }

    /** Set grab cursor */
    setGrab() {
        this.#setCursor(CURSORS.GRAB);
    }

    /** Set grabbing cursor */
    setGrabbing() {
        this.#setCursor(CURSORS.GRABBING);
    }

    /**
     * Sets custom cursor.
     * Avoid to use this method. It's better to call method for concrete cursor
     * @param {string} cursorType one of CURSORS
     */
    setByValue(cursorType) {
        this.#setCursor(
            Object.values(CURSORS).includes(cursorType)
                ? cursorType
                : CURSORS.DEFAULT
        );
    }

    /**
     * Set cursor for stage
     * @param {string} cursorName
     */
    #setCursor(cursorName) {
        stageVar().container().style.cursor = cursorName;
    }
}

const cursorModerator = new CursorModerator();
export default cursorModerator;

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { Vertex } from './Vertex';
import { RouteGraph } from '../model/RouteGraph';
import {
    ROUTE_CIRCLE_FILL,
    ROUTE_UNAVAILABLE_COLOR,
} from '../lib/RouteDefaultValues';
import { RULE_EDIT } from '../../AccessGroup/model/AGConstants';
import selector from '../../Selector/model/Selector';

/**
 * Component that represents all route graph vertices on canvas
 * @component
 */
export const RouteVertices = memo(({ graph, accessRule }) => {
    useReactiveVar(selector.selectedVerticesVar);
    const points = useReactiveVar(graph.drawPointsVar);
    const fill =
        accessRule === RULE_EDIT ? ROUTE_CIRCLE_FILL : ROUTE_UNAVAILABLE_COLOR;

    return points.map((pointLink) => {
        const id = pointLink.getId();
        const coordinates = pointLink.getCoordinates();
        return (
            <Vertex
                id={id}
                key={id}
                x={coordinates.x}
                y={coordinates.y}
                graph={graph}
                fill={fill}
                accessRule={accessRule}
                isSelected={selector.isVertexSelected(id)}
            />
        );
    });
});

RouteVertices.propTypes = {
    /** Route graph */
    graph: PropTypes.instanceOf(RouteGraph),

    /** Route access rule for current user */
    accessRule: PropTypes.string,
};

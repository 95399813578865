import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import dotsImg from '../../../shared/assets/images/icons/dots.svg';
import pencilImg from '../../../shared/assets/images/icons/pencil.svg';
import binImg from '../../../shared/assets/images/icons/MainPage/bin.svg';
import loaderImg from '../../../shared/assets/images/icons/preloader.svg';

import {
    POpenSansLight,
    POpenSansRegular,
} from '../../../shared/ui/TextStyles';
import { DotsImage, ProjectContainer } from './Styles';

import { projectPage } from '../../../shared/model/routes/staticRoutes';

import { device } from '../../../shared/ui/MediaSizes';
import { DELETE_PROJECT } from '../../../shared/graphql/mutations/projects/DeleteProject';
import {
    currentProjectVar,
    stageScaleVar,
} from '../../../shared/model/cache/Cache';
import { ProjectInfoModal } from '../../Constructor/TopBar/TopBarEntires/ProjectInfoModal';
import { DEFAULT_SCALE } from '../../../widgets/Canvas/lib/CanvasDefaultValues';
import { Modal } from '../../../features/ModalWindow/Modal';
import {
    CM_DIRECTIONS,
    ContextMenu,
} from '../../../features/ContextMenu/ui/styles/ContextMenu';

const ProjectsTitleAndDescription = styled.div`
    background-color: white;
`;

/**
 * @param {number} props.count - count of user's projects
 */
const POpenSansRegularStyled = styled(POpenSansRegular)`
    margin: ${(props) => (props.count < 3 ? '29px 0 0 18px' : '14px 0 0 11px')};
    opacity: 0.8;
    line-height: ${(props) => props.count > 3 && '16px'};
    font-size: ${(props) => props.count > 3 && '14px'};

    @media ${device.laptopL} {
        line-height: 22px;
        font-size: 16px;
        margin-top: 20px;
    }

    @media ${device.laptop} {
        line-height: 16px;
        font-size: 14px;
        margin: 14px 0 0 11px;
    }
`;

/**
 * @param {number} props.count - count of user's projects
 */
const POpenSansLightStyled = styled(POpenSansLight)`
    margin: ${(props) => (props.count < 3 ? '0 0 8px 18px' : '0 0 8px 11px')};
    opacity: 0.8;
    line-height: ${(props) => props.count > 3 && '16px'};
    font-size: ${(props) => props.count > 3 && '14px'};

    @media ${device.laptopL} {
        line-height: 22px;
        font-size: 16px;
    }
`;

/**
 * Card of project component
 *
 * @param {string} name name of project
 * @param {string} edited time when project has been edited
 * @param {string} preview source string to preview image of project
 * @param {string} id id of project
 * @param {number} count count of user's projects
 * @returns { Project } card of project component
 */
export const Project = ({
    id,
    name,
    description,
    edited,
    preview,
    count,
    onDelete,
}) => {
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false); // for delete query

    const editBlockRef = useRef(null);
    const contextMenuPos = {
        x: editBlockRef.current?.offsetLeft + 25,
        y: editBlockRef.current?.offsetTop - 5,
    };

    const navigate = useNavigate();

    const [deleteProject] = useMutation(DELETE_PROJECT);

    const handleDotsClick = (e) => {
        setIsMenuVisible(!isMenuVisible);
        if (e) {
            // stop propagation need to prevent opening project constructor
            e.stopPropagation();
        }
    };

    const handleProjectClick = () => {
        stageScaleVar(DEFAULT_SCALE);
        navigate(`${projectPage}/${id}`);
    };

    const handleEditClick = () => {
        currentProjectVar({
            id,
            name,
            description,
        });
        setIsModalOpen(true);
        closeContextMenu();
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const closeContextMenu = () => {
        setIsMenuVisible(false);
    };

    const handleDeleteClick = () => {
        setIsDeleting(true);
        deleteProject({
            variables: {
                id: id,
            },
        })
            .then((response) => {
                if (response.data) {
                    onDelete(id);
                }
            })
            .catch((error) => console.log(error))
            .finally(() => {
                closeContextMenu();
                setIsDeleting(false);
            });
    };

    const menu = [
        {
            key: 'edit',
            text: 'Редактировать',
            icon: pencilImg,
            onClick: handleEditClick,
        },
        {
            key: 'delete',
            text: 'Удалить',
            icon: isDeleting ? loaderImg : binImg,
            onClick: handleDeleteClick,
        },
    ];

    return (
        <>
            <ProjectContainer
                count={count}
                backgroundImg={preview}
                onClick={handleProjectClick}
            >
                <DotsImage
                    src={dotsImg}
                    alt='three dots pict'
                    onClick={handleDotsClick}
                    ref={editBlockRef}
                />

                <ProjectsTitleAndDescription>
                    <POpenSansRegularStyled count={count}>
                        {name}
                    </POpenSansRegularStyled>

                    <POpenSansLightStyled count={count}>
                        Последнее редактирование: {edited}
                    </POpenSansLightStyled>
                </ProjectsTitleAndDescription>
                <div style={{ zIndex: '10', cursor: 'default' }}>
                    <ContextMenu
                        menu={menu}
                        isOpened={isMenuVisible}
                        position={contextMenuPos}
                        directionX={CM_DIRECTIONS.RIGHT}
                        onClose={() => setIsMenuVisible(false)}
                    />
                </div>
            </ProjectContainer>
            <Modal
                isOpened={isModalOpen}
                onClose={closeModal}
                isCloseIconShow={true}
            >
                <ProjectInfoModal onClose={closeModal} />
            </Modal>
        </>
    );
};

Project.propTypes = {
    title: PropTypes.string,
    id: PropTypes.string,
    edited: PropTypes.string,
    description: PropTypes.string,
    preview: PropTypes.string,
    count: PropTypes.number,
};

import { DocumentNode, gql } from '@apollo/client';
import { ATTENTION_MARKER_FRAGMENT } from '../../fragments/attentionMarkers/AttentionMarkerFragment';

/**
 * Mutation for new alert creation
 */
export const CREATE_ALERT: DocumentNode = gql`
    ${ATTENTION_MARKER_FRAGMENT}
    mutation createAttentionMarker(
        $attentionMarkerDraft: AttentionMarkerDraftDto!
    ) {
        createAttentionMarker(attentionMarkerDraft: $attentionMarkerDraft) {
            ...AttentionMarkerFragment
        }
    }
`;

import { makeVar } from '@apollo/client';
import { client } from '../../../shared/graphql/ApolloClient';
import { DELETE_CONSTRAINT_GROUP } from '../../../shared/graphql/mutations/constraintGroups/DeleteConstraintGroup';
import { UPDATE_CONSTRAINT_GROUP } from '../../../shared/graphql/mutations/constraintGroups/UpdateConstraintGroup';
import { QUERY_STATES } from '../../AccessGroup/api/AccessGroupsAPI';
import {
    constraintGroupDataFromServerToCGData,
    constraintGroupDataToCGDiff,
} from '../model/ConstraintGroupAdapter';
import { CREATE_CONSTRAINT_GROUP } from '../../../shared/graphql/mutations/constraintGroups/CreateConstraintGroup';
import { currentFloorVar } from '../../../shared/model/cache/Cache';

/**
 * @typedef {import('../model/ConstraintGroup').ConstraintGroupData} ConstraintGroupData
 */

/**
 * Class that realizes api methods for constraint groups
 * @class
 */
class ConstraintGroupsAPI {
    constructor() {
        /**
         * Var for storing loading state for deleting constraint group
         * @type {number}
         */
        this.groupDeletingState = makeVar(QUERY_STATES.IDLE);
    }

    /**
     * Method that calls mutation to create constraint group
     * @param {string} floorId floor UUID
     * @returns {ConstraintGroupData} updated values
     */
    async createGroup(name) {
        const options = {
            mutation: CREATE_CONSTRAINT_GROUP,
            variables: {
                constraintGroupDraft: {
                    floorId: currentFloorVar().id,
                    name: name,
                },
            },
        };

        try {
            const data = (await client.mutate(options)).data
                .createConstraintGroup;
            return constraintGroupDataFromServerToCGData(data);
        } catch (error) {
            throw new Error(error.message);
        }
    }

    /**
     * Method that calls mutation to update constraint group
     * @param {ConstraintGroupData} groupData group properties: name, description, etc.
     * @returns {ConstraintGroupData} updated values
     */
    async updateGroup(groupData) {
        const options = {
            mutation: UPDATE_CONSTRAINT_GROUP,
            variables: {
                constraintGroupDiff: constraintGroupDataToCGDiff(groupData),
            },
        };

        try {
            const data = (await client.mutate(options)).data
                .updateConstraintGroup;
            return constraintGroupDataFromServerToCGData(data);
        } catch (error) {
            throw new Error(error.message);
        }
    }

    /**
     * Method that calls mutation to delete constraint group
     * @param {string} groupId constraint group UUID
     * @param {number} version constraint group version
     * @returns {number} UUID of deleted group
     */
    async deleteGroup(groupId, version) {
        this.groupDeletingState(QUERY_STATES.LOADING);
        const options = {
            mutation: DELETE_CONSTRAINT_GROUP,
            variables: {
                id: groupId,
                version: version,
            },
        };

        try {
            const id = (await client.mutate(options)).data.deleteConstraintGroup
                .id;
            this.groupDeletingState(QUERY_STATES.SUCCESS);
            return id;
        } catch (error) {
            this.groupDeletingState(QUERY_STATES.ERROR);
            throw new Error(error.message);
        }
    }
}

const constraintGroupsAPI = new ConstraintGroupsAPI();
export default constraintGroupsAPI;

import { gql } from '@apollo/client';

export const DELETE_WALL_SEGMENT = gql`
    mutation deleteWallSegment($selector: VersionedSegmentSelector!) {
        deleteWallSegment(selector: $selector) {
            id
            version
        }
    }
`;

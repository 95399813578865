import { makeVar } from '@apollo/client';
import { client } from '../../../shared/graphql/ApolloClient';
import { CREATE_ROUTE } from '../../../shared/graphql/mutations/routes/CreateRoute';
import { CREATE_ROUTE_SEGMENTS } from '../../../shared/graphql/mutations/routes/CreateRouteSegments';
import { DELETE_ROUTE } from '../../../shared/graphql/mutations/routes/DeleteRoute';
import { DELETE_ROUTE_SEGMENT } from '../../../shared/graphql/mutations/routes/DeleteRouteSegment';
import { UPDATE_ROUTE } from '../../../shared/graphql/mutations/routes/UpdateRoute';
import { UPDATE_ROUTE_SEGMENTS_POS } from '../../../shared/graphql/mutations/routes/UpdateRouteSegments';
import { currentFloorVar } from '../../../shared/model/cache/Cache';
import { QUERY_STATES } from '../../AccessGroup/api/AccessGroupsAPI';
import notifications from '../../HintSystem/model/Notifications';
import { ROUTE_DEFAULT_COLOR } from '../lib/RouteDefaultValues';
import {
    routeDataFromServerToRouteData,
    routeDataToRouteDiff,
} from '../model/RouteAdapter';

/**
 * @typedef {import('../../Wall/api/WallSegmentsAPI').WallSegmentDraft} WallSegmentDraft
 * @typedef {import('../../Wall/api/WallSegmentsAPI').VirtualPoint} VirtualPoint
 * @typedef {import('../../Wall/api/WallSegmentsAPI').MoveWallItemsOperation} MoveWallItemsOperation
 * @typedef {import('../model/RouteGraphWrapper').RouteData} RouteData
 */

/**
 * Class that realizes api methods for routes
 * @class
 */
class RoutesAPI {
    constructor() {
        /**
         * Var for storing loading state for updating route
         * @type {number}
         */
        this.routeUpdatingState = makeVar(QUERY_STATES.IDLE);

        /**
         * Var for storing loading state for deleting route
         * @type {number}
         */
        this.routeDeletingState = makeVar(QUERY_STATES.IDLE);
    }

    /**
     * Method for creating new route
     * @param {string} constraintGroupId
     * @returns {RouteData} created route data
     */
    async createRoute(constraintGroupId) {
        let routeData = null;
        const options = {
            mutation: CREATE_ROUTE,
            variables: {
                routeDraft: {
                    constraintGroupId: constraintGroupId,
                    name: 'Маршрут ' + new Date().toUTCString(), // TODO add correct name
                    color: ROUTE_DEFAULT_COLOR,
                },
            },
        };

        try {
            const data = (await client.mutate(options)).data.createRoute;
            routeData = routeDataFromServerToRouteData(data);
        } catch (error) {
            notifications.setError('Не удалось создать маршрут', error.message);
            console.error(error);
        }

        return routeData;
    }

    /**
     * Method that calls mutation to update route
     * @param {RouteData} routeData route properties: name, color, etc.
     * @returns {RouteData} updated values
     */
    async updateRoute(routeData) {
        let routeUpdated = null;
        this.routeUpdatingState(QUERY_STATES.LOADING);
        const options = {
            mutation: UPDATE_ROUTE,
            variables: {
                routeDiff: routeDataToRouteDiff(routeData),
            },
        };

        try {
            const data = (await client.mutate(options)).data.updateRoute;
            routeUpdated = routeDataFromServerToRouteData(data);
            this.routeUpdatingState(QUERY_STATES.SUCCESS);
        } catch (error) {
            this.routeUpdatingState(QUERY_STATES.ERROR);
            notifications.setError(
                'Не удалось обновить маршрут',
                error.message
            );
            console.error(error);
        }

        return routeUpdated;
    }

    /**
     * Method to delete route
     * @param {string} routeId
     * @param {number} version route current version
     * @returns {string} UUID of deleted route
     */
    async deleteRoute(routeId, version) {
        this.routeDeletingState(QUERY_STATES.LOADING);
        let deletedId = null;
        const options = {
            mutation: DELETE_ROUTE,
            variables: {
                id: routeId,
                version: version,
            },
        };

        try {
            deletedId = (await client.mutate(options)).data.deleteRoute.id;
            this.routeDeletingState(QUERY_STATES.SUCCESS);
        } catch (error) {
            notifications.setError('Не удалось удалить маршрут', error.message);
            console.error(error);
            this.routeDeletingState(QUERY_STATES.ERROR);
        }

        return deletedId;
    }

    /**
     * Method that calls api mutation for route segments creation
     * @param {WallSegmentDraft[]} segments
     * @param {VirtualPoint[]} points
     * @returns {[WallSegment[], Map<String, String>]} WallSegments and map
     * that translates local point ids to backend points ids
     */
    async createRouteSegments(segments, points) {
        let walls = null;
        let pointsTranslateMap = null;
        const options = {
            mutation: CREATE_ROUTE_SEGMENTS,
            variables: {
                floorId: currentFloorVar().id,
                segments: segments,
                points: points.map((p) => ({
                    id: p.id,
                    coordinate: p.coordinate,
                })),
            },
        };

        try {
            const result = await client.mutate(options);
            walls = result.data.createRouteSegments;
            pointsTranslateMap = new Map(
                Object.entries(result.extensions.pointsTranslateMap)
            );
        } catch (error) {
            throw new Error(error.message);
        }

        return [walls, pointsTranslateMap];
    }

    /**
     * Method that calls mutation to update route segments
     * @param {MoveWallItemsOperation | null} moveWallItems
     * @returns {WallSegment[]}
     */
    async updateSegmentsPositions(moveWallItems) {
        let edges = null;
        const options = {
            mutation: UPDATE_ROUTE_SEGMENTS_POS,
            variables: {
                moveVector: moveWallItems.moveVector,
                routePointsIds: moveWallItems.wallPointsIds,
                routeSegmentSelectors: moveWallItems.wallSegmentSelectors,
            },
        };

        try {
            edges = (await client.mutate(options)).data.updateRouteSegments;
        } catch (error) {
            throw new Error(error.message);
        }

        return edges;
    }

    /**
     * Method that calls mutation to delete wall segment
     * @param {SegmentSelector} selector
     * @returns {String} id
     */
    async deleteSegment(selector) {
        const options = {
            mutation: DELETE_ROUTE_SEGMENT,
            variables: {
                selector: selector,
            },
        };

        try {
            return (await client.mutate(options)).data.deleteRouteSegment;
        } catch (error) {
            throw new Error(error.message);
        }
    }
}

const routesAPI = new RoutesAPI();
export default routesAPI;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PersonCard } from '../ui/PersonCard';
import { SecondaryButton } from '../../../../../../../shared/ui/RolesSettingsComponents/Styles';
import { useMutation } from '@apollo/client';
import { ASSIGN_USER_TO_ACCESS_GROUP } from '../../../../../../../shared/graphql/mutations/accessGroups/AssignToGroup';
import { Preloader } from '../../../../../../../shared/ui/Preloader';
import notifications from '../../../../../../../features/HintSystem/model/Notifications';
import accessGroupsModerator from '../../../../../../../features/AccessGroup/model/AccessGroupsModerator';
import { POpenSansLight } from '../../../../../../../shared/ui/TextStyles';
import { useMutationDeleteMember } from '../../../../../../../features/AccessGroup/lib/hooks/useMutationDeleteMember';

const Person = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    margin: 4px 0 4px 5px;
`;

const Column = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

/* Constants for user loading state */
const DEFAULT_STATE = 0;
const LOADING_STATE = 1;
const IN_GROUP_STATE = 2;
const UP_IN_GROUP_HIERARCHY_STATE = 3;
const LOW_IN_GROUP_HIERARCHY_STATE = 4;

/**
 * Component represents concrete user for users list
 * @component
 */
export const UserCard = ({ groupId, user }) => {
    // TODO remake this way to find is user in project
    // Temporary decision to check if user already in one of project group
    const userAccessGroup = accessGroupsModerator.findUserGroup(user.userId);

    const isGroupLower = userAccessGroup
        ? accessGroupsModerator.isGroupDescendant(
              userAccessGroup.groupId,
              accessGroupsModerator.currentAccessGroupId
          )
        : false;

    let initState = DEFAULT_STATE;
    if (userAccessGroup) {
        if (isGroupLower) {
            initState =
                userAccessGroup.groupId === groupId
                    ? IN_GROUP_STATE
                    : LOW_IN_GROUP_HIERARCHY_STATE;
        } else {
            initState = UP_IN_GROUP_HIERARCHY_STATE;
        }
    }
    const [state, setState] = useState(initState);

    /**
     * Handler to catch error on adding user to access group
     * @param {*} error
     * @param {number} newState
     */
    const handleMutationError = (error, newState) => {
        notifications.setError('Не удалось выполнить запрос', error);
        console.error('Не удалось выполнить запрос');
        console.error(error);
        setState(newState);
    };

    /**
     * Handler for successfully user adding to access group
     * @param {*} accessGroup
     * @param {number} newState
     */
    const handleMutationComplete = (accessGroup, newState) => {
        setState(newState);
        accessGroupsModerator
            .getGroup(accessGroup.id)
            .replaceMembers(accessGroup.members);
    };

    /** Mutation to add user to group */
    const [addUser] = useMutation(ASSIGN_USER_TO_ACCESS_GROUP);

    /** Mutation to delete user from group */
    const deleteUser = useMutationDeleteMember({
        userId: user.userId,
        groupId: groupId,
        onCompleted: () => setState(DEFAULT_STATE),
        onError: () => setState(IN_GROUP_STATE),
    });

    /** Mutation to delete user from group (for reassign method) */
    const deleteUserForReassign = useMutationDeleteMember({
        userId: user.userId,
        groupId: userAccessGroup?.groupId,
        onCompleted: () => {
            setState(LOADING_STATE);
            handleAdd();
        },
        onError: () => setState(IN_GROUP_STATE),
    });

    /** Handler for adding user to access group */
    const handleAdd = () => {
        setState(LOADING_STATE);
        addUser({
            variables: {
                userSelector: {
                    id: user.userId,
                },
                groupId: groupId,
            },
            onCompleted: (data) =>
                handleMutationComplete(data.assignToGroup, IN_GROUP_STATE),
            onError: (error) => handleMutationError(error, DEFAULT_STATE),
        });
    };

    /** Handler for deleting user to access group */
    const handleDelete = () => {
        setState(LOADING_STATE);
        deleteUser();
    };

    /** Handler for reassign user from one to another access group */
    const handleReassign = () => {
        setState(LOADING_STATE);
        deleteUserForReassign();
    };

    return (
        <Person>
            <Column>
                <PersonCard title={user.name} description={user.email} />
            </Column>
            {state === DEFAULT_STATE && (
                <SecondaryButton onClick={handleAdd}>Добавить</SecondaryButton>
            )}
            {state === LOADING_STATE && <Preloader loaderSize={30} />}
            {state === IN_GROUP_STATE && (
                <SecondaryButton onClick={handleDelete}>
                    Удалить
                </SecondaryButton>
            )}
            {state === LOW_IN_GROUP_HIERARCHY_STATE && (
                <SecondaryButton
                    title={
                        'Пользователь уже состоит в группе ' +
                        userAccessGroup?.name()
                    }
                    onClick={handleReassign}
                >
                    Переназначить
                </SecondaryButton>
            )}
            {state === UP_IN_GROUP_HIERARCHY_STATE && (
                <POpenSansLight>Уже в проекте</POpenSansLight>
            )}
        </Person>
    );
};

UserCard.propTypes = {
    /** User object */
    user: PropTypes.shape({
        userId: PropTypes.string,
        name: PropTypes.string,
        email: PropTypes.string,
    }),

    /** Access group UUID */
    groupId: PropTypes.string,
};

import React, { Fragment } from 'react';
import styled from 'styled-components';

import { toolsList } from './Constants/Tools';
import { Tool } from './Tool';
import { BotActivator } from './Bots/BotActivator';

/** Vertical line */
const Line = styled.div`
    width: 1px;
    height: 24px;
    background: #8d8d8d;
    margin-right: 10px;
`;

/**
 * Component that contains tools
 *
 * @component
 */
export const Tools = () => {
    return (
        <>
            {toolsList.map((toolGroup, index) => (
                <Fragment key={index}>
                    {toolGroup.map((tool) => (
                        <Tool toolProps={tool} key={tool.src} />
                    ))}
                    {/* Add line to separate tool groups */}
                    {index < toolsList.length - 1 && <Line />}
                </Fragment>
            ))}
            <BotActivator />
        </>
    );
};

const logInErrorsMessages = {
    authorizationErrorCode: 'authorization-error',
    noSuchEntityError: 'no-result',
};

const logInErrorsMessagesRus = {
    [logInErrorsMessages.authorizationErrorCode]: 'Неверный логин или пароль!',
    [logInErrorsMessages.noSuchEntityError]: 'Неверный логин или пароль!',
};

export { logInErrorsMessages, logInErrorsMessagesRus };

import { gql } from '@apollo/client';

export const UPDATE_USER = gql`
    mutation updateUser($updateUserData: UserDiff!){
        updateUser(userDiff: $updateUserData) {
            id
            login
            name
            email
            phone
        }
    }
`;

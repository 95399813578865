import React from 'react';
import PropTypes from 'prop-types';

// cloud has 30px paddings
// cloud has additional 10px paddings for shadow
// cloud has protrusion at the bottom in the center
// protrusion size: {width: 37px, height: 14.5px}

export const SVG_CLOUD_PADDING_SIZE = 30;
export const SVG_CLOUD_SHADOW_SIZE = 10;
export const SVG_CLOUD_PROTRUSION_WIDTH = 37;
export const SVG_CLOUD_PROTRUSION_HEIGHT = 14.5;

/**
 * Component that draws cloud svg img.
 * 
 * NB: There is a document on google drive with a more detailed description that could help with editing.
 * 
 * @component
 */
export const CloudSvg = ({contentWidth, contentHeight}) => {
    const halfContentWidth = (contentWidth - SVG_CLOUD_PROTRUSION_WIDTH) / -2; // bottom part of width

    /** Path for cloud border */
    const path = "m 10 40 c 0 -20 10 -30 30 -30 h "
        + contentWidth + " c 20 0 30 10 30 30 v "
        + contentHeight + " c 0 20 -10 30 -30 30 h "
        + halfContentWidth + " c -6 0 -10.5 2.5 -13.5 7 l -5 7.5 l -5 -7.5 c -3 -4.5 -7 -7 -13.5 -7 h "
        + halfContentWidth + " c -20 0 -30 -10 -30 -30 z";

    const paddings = (SVG_CLOUD_PADDING_SIZE + SVG_CLOUD_SHADOW_SIZE) * 2;

    const svgWidth = String(contentWidth + paddings);
    const svgHeight = String(contentHeight + paddings + SVG_CLOUD_PROTRUSION_HEIGHT);
    const svgViewBox = "0 0 " + svgWidth + " " + svgHeight;
    
    return (
        <svg width={svgWidth} height={svgHeight} viewBox={svgViewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_2735_13440)">
                <path d={path} stroke="#9E9E9E" strokeWidth="0.5" fill="white" />
            </g>
            <defs>
                <filter id="filter0_d_2735_13440" x="0" y="0" width={svgWidth} height={svgHeight} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="8.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2735_13440" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2735_13440" result="shape" />
                </filter>
            </defs>
        </svg>
    );
};

CloudSvg.propTypes = {
    /** Content width for cloud */
    contentWidth: PropTypes.number,

    /** Content height for cloud */
    contentHeight: PropTypes.number,
};

import {
    emailRegex,
    loginRegex,
    passwordRegex,
    requiredCapitalLetterRegex,
    requiredLowerCaseLetterRegex,
    requiredNumberRegex,
    requiredPasswordSymbolRegex
} from "../../../shared/lib/utils/regex/regex";

const REQUIRED_FIELD = "Обязательное поле";

/**
 * Function for validation login, email, password, control password and terms checkbox from Registration form
 * @param {Object} data - Data for validation
 * @param {string} data.email
 * @param {string} data.login
 * @param {string} data.password
 * @param {string} data.controlPassword
 * @param {boolean} data.checkbox
 * @param {boolean} formValid - form validation flag
 * @param {function} setFormValid - useState function
 * @param {function} setInputValuesErrors - useState function
 * @param {function} setInputValuesStatus - useState function
 */
export const validateRegistrationForm = (
    { email, login, password, controlPassword, checkbox },
    formValid,
    setFormValid,
    setInputValuesErrors,
    setInputValuesStatus
) => {
    // vars for hint messages
    let msgEmail = null;
    let msgLogin = null;
    let msgPass = null;
    let msgControlPass = null;

    // vars for statuses of messages
    let msgStatusEmail = "error";
    let msgStatusLogin = "error";
    let msgStatusPass = "error";
    let msgStatusControlPass = "error";

    if (email === "") msgEmail = REQUIRED_FIELD;
    else if (!email.match(emailRegex)) msgEmail = "Введите корректный адрес эл. почты";
    else msgStatusEmail = "hint";

    if (login === "") msgLogin = REQUIRED_FIELD;
    else if (login.length < 3) msgLogin = "Слишком короткий логин";
    else if (login.length > 30) msgLogin = "Слишком длинный логин";
    else if (!login.match(loginRegex)) msgLogin = "Логин содержит запрещённые символы";
    else msgStatusLogin = "hint";


    if (password === "") msgPass = REQUIRED_FIELD;
    else if (password.length < 8) msgPass = "Слишком короткий пароль";
    else if (password.length > 128) msgPass = "Слишком длинный пароль";
    else if (!password.match(requiredCapitalLetterRegex)) msgPass = "Требуется заглавная буква";
    else if (!password.match(requiredLowerCaseLetterRegex)) msgPass = "Требуется строчная буква";
    else if (!password.match(requiredNumberRegex)) msgPass = "Требуется цифра";
    else if (!password.match(requiredPasswordSymbolRegex)) msgPass = "Требуется символ !?+-=_@#$%^&*";
    else if (!password.match(passwordRegex)) msgPass = "Пароль содержит запрещённые символы";
    else {
        msgPass = "Отличный пароль!";
        msgStatusPass = "success";
    }

    if (controlPassword === "") msgControlPass = REQUIRED_FIELD;
    else if (password !== controlPassword) msgControlPass = "Пароли не совпадают :(";
    else msgStatusControlPass = "hint";

    setInputValuesErrors({ // set errors if them exists
        email: msgEmail,
        login: msgLogin,
        password: msgPass,
        controlPassword: msgControlPass,
    });

    setInputValuesStatus({ // set statuses of messages
        email: msgStatusEmail,
        login: msgStatusLogin,
        password: msgStatusPass,
        controlPassword: msgStatusControlPass,
    });

    const isValid = checkbox
        && (msgStatusEmail !== "error") && (msgStatusLogin !== "error")
        && (msgStatusPass !== "error") && (msgStatusControlPass !== "error");

    if (isValid !== formValid) {
        setFormValid(isValid);
    }
};
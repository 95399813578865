import styled from 'styled-components';
import PropTypes from 'prop-types';

const POpenSansLight = styled.p`
    font-family: 'Open Sans';
    font-style: normal;
    margin: 0;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
`;

const POpenSansRegular = styled.p`
    font-family: 'Open Sans';
    font-style: normal;
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
`;

/**
 * Styled h1 tag
 */
const H1OpenSansSemiBold = styled.h1`
    margin: 0;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;

    opacity: 0.7;
`;

const H2OpenSansSemiBold = styled.h2`
    margin: 0;
    padding: 0 20px;
    text-align: center;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 41px;

    opacity: 0.7;
`;

const H3OpenSansRegular = styled.h3`
    font-family: 'Open Sans';
    font-style: normal;
    margin: 0;
    font-weight: 400;
    font-size: ${({ fontSize }) => fontSize ?? '16px'};
    line-height: ${({ lineHeight }) => lineHeight ?? '22px'};
`;

H3OpenSansRegular.propTypes = {
    /**
     * Font size in css style, for example, "16px" or "20%" and etc.
     */
    fontSize: PropTypes.string,
    /**
     * Line height in css style, for example, "16px" or "20%" and etc.
     */
    lineHeight: PropTypes.string,
};

const H1OpenSansBold = styled.h1`
    font-family: 'Open Sans';
    font-style: normal;
    margin: 0;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
`;

const PInterRegular = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin: 0px;
`;

const PHoverInterRegular = styled.span`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin: 0px;
    color: rgba(0, 0, 0, 0.7);
    &:hover {
        cursor: pointer;
        color: rgba(0, 0, 0, 1);
    }
`;

const PHoverInterLink = styled(PInterRegular)`
    color: #0239ff;
    &:hover {
        cursor: pointer;
        color: #5076fd;
    }
`;

export {
    POpenSansLight,
    POpenSansRegular,
    H1OpenSansSemiBold,
    H1OpenSansBold,
    H2OpenSansSemiBold,
    H3OpenSansRegular,
    PInterRegular,
    PHoverInterRegular,
    PHoverInterLink,
};

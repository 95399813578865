import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    POpenSansLight,
    POpenSansRegular,
} from '../../../shared/ui/TextStyles';

const DefaultPropertiesContainer = styled.div`
    display: flex;
    gap: 3px;
`;

/**
 * @typedef {import('../lib/SchemaParser.js').FormField} FormField
 */

/**
 * Component represents type for map object.
 * Type is default immutable properties
 * @component
 * @param {{
 *  fields: FormField[]
 * }}
 */
export const DefaultProperties = ({ fields }) => {
    const getCheckMarkFromBoolean = (value) => (value ? '[V]' : '[ ]');

    return (
        <>
            {fields.map((field) => (
                <DefaultPropertiesContainer key={field.key}>
                    <POpenSansLight>{field.name}:</POpenSansLight>
                    <POpenSansRegular>
                        {field.valueType === 'Boolean'
                            ? getCheckMarkFromBoolean(
                                  field.currentValue.toSchemaFormat() ??
                                      field.defaultValue
                              )
                            : field.currentValue.toSchemaFormat() ??
                              field.defaultValue}
                    </POpenSansRegular>
                </DefaultPropertiesContainer>
            ))}
        </>
    );
};

DefaultProperties.propTypes = {
    /** Values for schema */
    fields: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string,
            valueType: PropTypes.string.isRequired,
            enumSet: PropTypes.oneOf([
                PropTypes.arrayOf(PropTypes.string),
                PropTypes.undefined,
            ]),
            isRequired: PropTypes.bool,
            defaultValue: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string,
            ]).isRequired,
            name: PropTypes.string,
            validate: PropTypes.func,
            format: PropTypes.func,
            key: PropTypes.string,
            currentValue: PropTypes.shape({
                value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
                    .isRequired,
                toString: PropTypes.func,
                toSchemaFormat: PropTypes.func,
            }),
        })
    ).isRequired,
};

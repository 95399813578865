import { LinkedSegment } from '../../../features/Wall/model/SegmentsModerator';

/**
 * CustomMap class that is extended from js Map and is need for graph edges
 * as it implements classic Map methods with associative checks
 * @template TKey, TValue
 * @class
 * @extends Map
 */
export class CustomMap extends Map {
    constructor(customMap) {
        super();

        if (customMap === undefined) {
            return;
        }

        for (const elem of customMap) {
            this.set(LinkedSegment.splitKey(elem[0].toString()), elem[1]);
        }
    }

    /**
     *
     * @param {TKey} key
     * @returns {TValue}
     */
    get([val1, val2]) {
        if (this.has([val1, val2])) {
            return (
                super.get([val1, val2].toString()) ||
                super.get([val2, val1].toString())
            );
        }
        return undefined;
    }

    /**
     *
     * @param {TKey} key
     * @returns {Boolean}
     */
    has([val1, val2]) {
        return (
            super.has([val1, val2].toString()) ||
            super.has([val2, val1].toString())
        );
    }

    set([val1, val2], value) {
        if (super.has([val1, val2].toString())) {
            super.set([val1, val2].toString(), value);
        } else if (super.has([val2, val1].toString())) {
            super.set([val2, val1].toString(), value);
        } else {
            super.set([val1, val2].toString(), value);
        }
        return this;
    }

    delete([val1, val2]) {
        super.delete([val1, val2].toString()) ||
            super.delete([val2, val1].toString());
    }

    jsonToMap(jsonObject) {
        return new CustomMap(JSON.parse(jsonObject).entries());
    }

    mapToJson() {}
}

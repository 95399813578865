import { DocumentNode, gql } from '@apollo/client';
import { ROUTE_FRAGMENT } from '../../fragments/routes/RouteFragment';

export const UPDATE_ROUTE: DocumentNode = gql`
    ${ROUTE_FRAGMENT}
    mutation updateRoute($routeDiff: RouteDiff!) {
        updateRoute(routeDiff: $routeDiff) {
            ...RouteFragment
        }
    }
`;

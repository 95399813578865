import React from 'react';
import styled from 'styled-components';
import plusIcon from '../../../../../../shared/assets/images/icons/plus.svg';

const TemplateButton = styled.button`
    display: flex;
    align-items: center;
    padding: 11px 14px;
    gap: 12px;
    border-radius: 13px;
    border-color: transparent;
    font-style: normal;
    color: #ffffff;
    cursor: pointer;
`;

const OperationButtonStyled = styled(TemplateButton)`
    background: #5075fd;
    margin-inline: auto;
    color: white;
    width: fit-content;
    height: 42px;

    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: 'left';

    :hover {
        background: #6988f8;
    }
`;

export const OperationButton = ({ text, clickHandler, disabled }) => {
    return (
        <OperationButtonStyled onClick={clickHandler} disabled={disabled}>
            <img
                style={{ height: '15px', width: '15px' }}
                src={plusIcon}
                alt='add'
            />
            {text}
        </OperationButtonStyled>
    );
};

import floorGraph from '../../../../Wall/model/FloorGraph';
import { LinkedSegment } from '../../../../Wall/model/SegmentsModerator';

/**
 * Command to change wall segment type
 * @param {String} edgeId
 * @returns
 */
export const changeEdgeTypeCommand = (edgeId, typeChangingTo) => {
    const [pointId1, pointId2] = LinkedSegment.splitKey(edgeId);

    const cachedEdge = {
        key: edgeId,
        type: floorGraph.segmentsModerator
            .getSegment(pointId1, pointId2)
            .getType(),
    };

    return {
        execute() {
            floorGraph.changeSegmentType(edgeId, typeChangingTo);
        },

        undo() {
            floorGraph.changeSegmentType(cachedEdge.key, cachedEdge.type);
        },
    };
};

import { client } from '../../../shared/graphql/ApolloClient';
import { CREATE_SCHEMA_IMPL } from '../../../shared/graphql/mutations/schemas/CreateSchemaImpl';
import { UPDATE_SCHEMA_IMPL } from '../../../shared/graphql/mutations/schemas/UpdateSchemaImpl';
import notifications from '../../HintSystem/model/Notifications';

enum ObjectTypesEnum {
    MapObject = 'MapObject',
    Project = 'Project',
    User = 'User',
    WallSegment = 'WallSegment',
    Zone = 'Zone',
}

type SchemaImplDiffType = {
    data: string;
    id: string;
    version: number;
};

type SchemaImplDraftDtoInputType = {
    data: String;
    itemId: String;
    itemType: ObjectTypesEnum;
};

/**
 * Class that realizes api methods for map objects
 * @class
 */
class SchemaImplAPI {
    async get() {}

    async create(schemaImplDraft: SchemaImplDraftDtoInputType) {
        const options = {
            mutation: CREATE_SCHEMA_IMPL,
            variables: {
                schemaImplDraft,
            },
        };

        try {
            return (await client.mutate(options)).data.createMapinsSchemaImpl;
        } catch (error: string | any) {
            notifications.setError('Не удалось создать схему', error.message);
            console.log(error);
        }
    }

    async update(
        objectType: ObjectTypesEnum,
        schemaImplDiff: SchemaImplDiffType
    ) {
        const options = {
            mutation: UPDATE_SCHEMA_IMPL,
            variables: {
                objType: objectType,
                schemaImplDiff,
            },
        };

        try {
            return (await client.mutate(options)).data.updateMapinsSchemaImpl;
        } catch (error: string | any) {
            notifications.setError('Не удалось создать схему', error.message);
            console.log(error);
        }
    }

    async delete() {}
}

const schemaImplAPI = new SchemaImplAPI();
export default schemaImplAPI;

import { gql } from '@apollo/client';

/**
 * Query for global types groups.
 * Type groups unite map object types
 */
export const GET_GLOBAL_TYPES_GROUPS = gql`
    query typesGroups {
        typesGroups {
            id
            version
            name
            mapObjectTypes {
                id
                version
                name
                description
                length
                width
                scalable
                level
                image {
                    id
                    src
                    type
                }
            }
        }
    }
`;

import { useMutation } from '@apollo/client';
import { REMOVE_USER_FROM_ACCESS_GROUP } from '../../../../shared/graphql/mutations/accessGroups/RemoveFromGroup';
import notifications from '../../../HintSystem/model/Notifications';
import accessGroupsModerator from '../../model/AccessGroupsModerator';

/**
 * Custom hook to encapsulate mutation, that deletes user from access group
 * @param {object} properties
 * @param {string} properties.userId UUID of user to delete
 * @param {string} properties.groupId UUID of group from where need to delete user
 * @param {(data: object) => void} properties.onCompleted function that will be called after successfully mutation execution
 * @param {(error: object) => void} properties.onError function that will be called if an error occurs
 * @returns {() => void} function that you need call when you need to delete user
 */
export const useMutationDeleteMember = ({
    userId,
    groupId,
    onCompleted,
    onError,
}) => {
    /** Mutation to delete user from group */
    const [mutation] = useMutation(REMOVE_USER_FROM_ACCESS_GROUP);

    return () => {
        mutation({
            variables: {
                userSelector: {
                    id: userId,
                },
                groupId: groupId,
            },
            onCompleted: (data) => {
                const accessGroup = data.deleteFromGroup;
                accessGroupsModerator
                    .getGroup(accessGroup.id)
                    .replaceMembers(accessGroup.members);
                if (onCompleted) {
                    onCompleted(accessGroup);
                }
            },
            onError: (error) => {
                notifications.setError('Не удалось удалить пользователя', error);
                console.error('Не удалось удалить пользователя');
                console.error(error);
                if (onError) {
                    onError(error);
                }
            },
        });
    };
};

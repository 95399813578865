import Zone from './Zone';
import {
    stageBordersSizeVar,
    stageScaleVar,
    stageVar,
} from '../../../shared/model/cache/Cache';
import { createRectangleWall } from '../../Wall/lib/helpers';
import { v4 as uuid } from 'uuid';
import floorGraph from '../../Wall/model/FloorGraph';

/**
 * @class GlobalZone
 * @extends {Zone}
 */
export class GlobalZone extends Zone {
    /**
     * Additional border size for global zone to ensure that the global zone covers a whole screen
     */
    ZONE_BORDER_EXPANSION_PX = 100;

    /**
     * @param {import('./Zone').ZoneProps} zoneProps
     */
    static createInitialGlobalZone(zoneProps) {
        const initialGlobalZone = new GlobalZone(zoneProps);
        /**
         * @type {import('../../../shared/lib/modules/math/Math').Point[]}
         */
        const points = createRectangleWall(
            { x: 0, y: 0 },
            { x: window.innerWidth, y: window.innerHeight }
        );
        /**
         * @type {PointLinkWrapper[]}
         */
        const borders = points.map((point) => {
            return floorGraph.addVertexToGraph(
                uuid(),
                {
                    coordinates: point,
                    adjacentPoints: [],
                },
                false,
                false
            );
        });

        initialGlobalZone.props.borders = borders;
        return initialGlobalZone;
    }

    copy() {
        return new GlobalZone(this.props);
    }

    /**
     * Method to expand global zone size according to current stage scale and position
     */
    expand() {
        if (!stageVar() || !stageBordersSizeVar() || !stageScaleVar()) {
            return;
        }
        const transformStageSize = (stageBorder) => {
            return (
                stageBorder / stageScaleVar() + this.ZONE_BORDER_EXPANSION_PX
            );
        };
        const newPointObjArr = [];
        newPointObjArr.push({
            pointLink: this.props.borders[0],
            newPoint: {
                x: -transformStageSize(stageBordersSizeVar().left),
                y: -transformStageSize(stageBordersSizeVar().top),
            },
        });
        newPointObjArr.push({
            pointLink: this.props.borders[1],
            newPoint: {
                x: transformStageSize(
                    stageBordersSizeVar().right + stageVar().width()
                ),
                y: -transformStageSize(stageBordersSizeVar().top),
            },
        });
        newPointObjArr.push({
            pointLink: this.props.borders[2],
            newPoint: {
                x: transformStageSize(
                    stageBordersSizeVar().right + stageVar().width()
                ),
                y: transformStageSize(
                    stageBordersSizeVar().bottom + stageVar().height()
                ),
            },
        });
        newPointObjArr.push({
            pointLink: this.props.borders[3],
            newPoint: {
                x: -transformStageSize(stageBordersSizeVar().left),
                y: transformStageSize(
                    stageBordersSizeVar().bottom + stageVar().height()
                ),
            },
        });

        floorGraph.updateMultipleVertices(newPointObjArr);
        this.rerender();
    }
}

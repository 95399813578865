import { botDataFromServerToBotData } from '../model/BotAdapter';
import botsModerator from '../model/BotsModerator';

/**
 * @typedef {import('../model/BotAdapter').BotDataFromServer} BotDataFromServer
 */

/**
 * Method for initialize bots.
 * This method receives constraintGroups object from server and fill BotsModerator.
 * @param {BotDataFromServer[]} serverData
 */
export const initializeBots = (serverData) => {
    botsModerator.clear();

    serverData.forEach((bot) => {
        const botData = botDataFromServerToBotData(bot);

        // add bot to bots moderator
        botsModerator.addBot(botData.id, botData);
    });
};

import { DocumentNode, gql } from '@apollo/client';
import { ACCESS_GROUP_FRAGMENT } from '../../fragments/accessGroups/AccessGroupFragment';

export const UPDATE_ACCESS_GROUP: DocumentNode = gql`
    ${ACCESS_GROUP_FRAGMENT}
    mutation updateAccessGroup($accessDiff: AccessGroupDiff!) {
        updateAccessGroup(accessDiff: $accessDiff) {
            ...AccessGroupFragment
        }
    }
`;

import React, { useState } from 'react';
import botImg from '../../../../../../shared/assets/images/tools/ConstructorPage/auto-route.svg';
import loadImg from '../../../../../../shared/assets/images/icons/preloader.svg';
import botsModerator from '../../../../../../features/Bots/model/BotsModerator';
import botsAPI from '../../../../../../features/Bots/api/BotsAPI';
import { ToolImage } from '../Constants/Tools';

/**
 * Component that represent bot activation button
 * @component
 */
export const BotActivator = () => {
    const [isActive, setIsActive] = useState(false);

    const activateBots = async () => {
        setIsActive(true);
        const botsIds = botsModerator.botsMapVar().keys();
        for (const botId of botsIds) {
            await botsAPI.activateBot(botId);
        }
        setIsActive(false);
    };

    return (
        <ToolImage
            src={isActive ? loadImg : botImg}
            alt='activate bot'
            title='Запустить ботов'
            onClick={activateBots}
            active={isActive}
        />
    );
};

import React from "react";
// import circle from "../../../assets/images/icons/circle.svg"
// import circle from "./ExamplePhoto.png"
import circle from '../../../shared/assets/images/icons/circle-in-settings.svg'
import { Label, PhotoBlockStyled, CommentWithPhoto, Avatar, EditIcon, Square } from "./Styles"
import editPhoto from "../../../shared/assets/images/icons/edit-pen.svg";

/**
 * Block for working with photo
 * @component
 */
export const PhotoBlock = () => {

    /**
     * Handler for edit button
     */
    const onEditPhotoButtonClick = () => {
        console.log("edit button clicked");
    }

    return (
        <PhotoBlockStyled>
            <Label>Фотография</Label>
            <CommentWithPhoto>
                <p>Добавьте фото в аккаунт, чтобы другим пользователям было проще вас узнать</p>
                <div className="photo-circle">
                    <Avatar src={circle} alt="avatar"/>
                    <Square className="edit" onClick={onEditPhotoButtonClick}/>
                    <EditIcon className="edit" onClick={onEditPhotoButtonClick}>
                        <img src={editPhoto} alt="edit"/>
                    </EditIcon>
                </div>
            </CommentWithPhoto>
        </PhotoBlockStyled>       
    );
};
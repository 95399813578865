import { DocumentNode, gql } from '@apollo/client';

/**
 * Query to get zone access rules
 */
export const GET_ZONE_ACCESS_RULES: DocumentNode = gql`
    query zone($id: String!) {
        zone(id: $id) {
            accessRules {
                group {
                    id
                    name
                }
                rule
            }
        }
    }
`;

import React, { useState } from 'react';
import styled from 'styled-components';

import loopPlusIcon from '../../../../shared/assets/images/icons/ConstructorPage/ZoomRegulator/loop-plus.svg';
import loopMinusIcon from '../../../../shared/assets/images/icons/ConstructorPage/ZoomRegulator/loop-minus.svg';
import { useReactiveVar } from '@apollo/client';
import { stageScaleVar } from '../../../../shared/model/cache/Cache';
import {
    MAX_SCALE,
    MIN_SCALE,
} from '../../../../widgets/Canvas/lib/CanvasDefaultValues';
import { ZoomRegulatorInput } from './ZoomRegulatorInput';
import { scaleCanvasFromCenter } from '../../../../widgets/Canvas/lib/utils/CenterStagePositionAfterScale';

const Container = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;
    background: rgba(232, 232, 232, 0.5);
    border-radius: 60px;
    z-index: 10;
    user-select: none;
    pointer-events: none;
`;

const ClickableContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    :hover {
        cursor: pointer;
    }
    pointer-events: auto;
`;

const LoopPlusIcon = styled.img`
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
`;
const LoopMinusIcon = styled.img`
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
`;

const ZoomPercent = styled.div`
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    width: 42px;
    margin-left: 14px;
    margin-right: 14px;
    cursor: text;
    pointer-events: auto;
`;

/**
 * Component that represents zoom regulator window
 *
 * @component
 */
export const ZoomRegulator = () => {
    const [isEditing, setIsEditing] = useState(false);

    const scale = useReactiveVar(stageScaleVar);

    // this handlers should be overwritten
    const handlePlusScale = () => {
        const newScale = Math.min(MAX_SCALE, scale + 0.01);
        scaleCanvasFromCenter(newScale);
    };

    const handleMinusScale = () => {
        const newScale = Math.max(MIN_SCALE, scale - 0.01);
        scaleCanvasFromCenter(newScale);
    };

    return (
        <Container>
            {isEditing ? (
                <ZoomRegulatorInput
                    closeInputField={() => setIsEditing(false)}
                />
            ) : (
                <>
                    <ClickableContainer onClick={handlePlusScale}>
                        <LoopPlusIcon
                            src={loopPlusIcon}
                            alt={'scale up'}
                            draggable={false}
                        />
                    </ClickableContainer>
                    <ZoomPercent onClick={() => setIsEditing(true)}>{`${(
                        scale * 100
                    ).toFixed(0)}%`}</ZoomPercent>
                    <ClickableContainer onClick={handleMinusScale}>
                        <LoopMinusIcon
                            src={loopMinusIcon}
                            alt={'scale down'}
                            draggable={false}
                        />
                    </ClickableContainer>
                </>
            )}
        </Container>
    );
};

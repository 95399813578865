import { client } from '../../../graphql/ApolloClient';

/**
 * Function send query
 * Needed for repeat query request
 * @param {object} variables object with query variables
 * @param {function} query body query
 */
export const repeatSubmit = (variables, query) => {
    client
        .query({ query: query, variables: variables, fetchPolicy: 'network-only' })
        .catch((error) => {
            console.log(error);
        });
};

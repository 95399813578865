import React from 'react';
import { useReactiveVar } from '@apollo/client';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
    currentMenuItemVar,
    currentToolVar,
    projectWallTypesVar,
} from '../../../../../../../../shared/model/cache/Cache';
import { Tools } from '../../../../../../TopBar/TopBarEntires/Tools/Constants/Tools';
import DefaultIcon from '../../../../../../../../shared/assets/images/icons/ConstructorPage/RightBar/Walls/wall2.svg';
import floorGraph from '../../../../../../../../features/Wall/model/FloorGraph';
import {
    BASIC_WALLS_FOLDER_NAME,
    BASIC_EMBEDDED_OBJECTS_FOLDER_NAME,
} from '../../Constants';
import constructorPageHints from '../../../../../../../../features/HintSystem/model/ConstructorPageHints';
import rightBarModerator from '../../../../../model/RightBarModerator';

const ItemContainer = styled.li`
    width: 78px;
    height: 76px;
    margin: 3px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    border: ${(props) =>
        props.isCurrentSelected
            ? '1px solid #023AFF'
            : '1px solid transparent'};
    :hover {
        border: 0.5px solid #023aff;
        cursor: ${(props) =>
            props.item.id && props.item.typeName === 'WallSegmentType'
                ? 'pointer'
                : ''};
    }
`;

const ItemImg = styled.img`
    width: 45px;
    max-height: 45px;
    margin: 0;
    :hover {
        cursor: ${(props) =>
            props.typeName === 'MapObjectType' ? 'grab' : 'pointer'};
    }
`;

const ItemName = styled.div`
    width: 63px;

    text-align: center;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    font-size: 14px;
    line-height: 22px;
    color: #000000;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const ItemImageContainer = styled.div`
    display: flex;
    width: 45px;
    height: 45px;
    align-items: center;
`;

/** Function to finish wall creation */
export const finishWallCreation = () => {
    floorGraph.currentWallSegmentTypeVar(null);
    rightBarModerator.selectedObject(null);
    currentToolVar(Tools.none);
    floorGraph.cancelCreating();
    floorGraph.finishCreatingRect();
};

/**
 * Component that represents canvas item in folder of items
 *
 * @component
 */
export const Item = ({ item, folder }) => {
    const currentTool = useReactiveVar(currentToolVar);
    const selectedObject = useReactiveVar(rightBarModerator.selectedObject);

    const wallWidthCoef = 20;

    /**
     * Function for generating dynamic images for walls with different width
     * @param {number} lineWidth
     * @param {boolean} dash
     * @returns {string} svg
     */
    const generateImage = (lineWidth, dash) => {
        const finalWidth = lineWidth * wallWidthCoef;
        const centerY = 45 / 2 - finalWidth / 2;
        const svgCode = `<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45">
        <line x1="0" y1="${centerY}" x2="45" y2="${centerY}" fill="none" stroke="black" stroke-width="${finalWidth}" stroke-dasharray="${
            dash ? '4' : 'none'
        }" />
        <rect y="${centerY}" width="45" height="${finalWidth}" fill="${
            !dash ? 'black' : 'none'
        }" />
        </svg>`;
        const svgDataURL = `data:image/svg+xml,${encodeURIComponent(svgCode)}`;
        return svgDataURL;
    };

    /**
     * Function that generates handler for item
     * @return OnClick handler for item if handler is needed
     */
    const generateItemClickHandler = () => {
        // if wall, then return handler for wall
        // TODO: way of wall segment insertion should be according to item.scalable
        if (folder.name === BASIC_WALLS_FOLDER_NAME) {
            return () => {
                if (
                    (currentTool === Tools.wall ||
                        currentTool !== Tools.rectangleRoom) &&
                    selectedObject === item.id
                ) {
                    finishWallCreation();
                    return;
                }

                floorGraph.currentWallSegmentTypeVar(item);
                rightBarModerator.selectedObject(item.id);
                // check that now isn't executed any operation
                if (rightBarModerator.operation() !== null) {
                    return;
                }
                if (
                    currentTool !== Tools.rectangleRoom &&
                    currentTool !== Tools.wall
                ) {
                    currentToolVar(Tools.wall); // switch to wall tool
                    constructorPageHints.setInfoHint(
                        'Построение стен',
                        'Нажмите левой кнопкой мыши, чтобы начать построение. Нажмите esc, чтобы завершить построение.'
                    );
                }
            };
        }
        // else return handler for embedded object
        else if (folder.name === BASIC_EMBEDDED_OBJECTS_FOLDER_NAME) {
            return () => {
                if (
                    currentTool === Tools.embeddedObject &&
                    selectedObject === item.id
                ) {
                    floorGraph.currentWallSegmentTypeVar(null);
                    rightBarModerator.selectedObject(null);
                    currentToolVar(Tools.none);
                    return;
                }

                floorGraph.currentWallSegmentTypeVar(item);
                rightBarModerator.selectedObject(item.id);
                // check that now isn't executed any operation
                if (rightBarModerator.operation() !== null) {
                    return;
                }
                if (currentTool !== Tools.embeddedObject) {
                    currentToolVar(Tools.embeddedObject); // switch to embedded obj tool

                    // set fixed length for segment
                    const objectLength =
                        projectWallTypesVar().find(
                            (wallType) => wallType.id === item.id
                        )?.length ?? 5; // TODO: handle hardcoded length
                    floorGraph.tmpEdgeProps({
                        ...floorGraph.tmpEdgeProps(),
                        length: objectLength,
                        fixLength: true,
                    });
                }
            };
        }
    };

    const getItemImage = () => {
        if (item.src) {
            return item.src;
        } else if (item.image.src) {
            // TODO: here should be universal decision for showing COLOR_FILLED image source
            if (item.image.src === 'black' || item.image.src === 'gray') {
                return generateImage(
                    item.width ? item.width : 0.1,
                    !item.width
                );
            } else {
                return `data:image/svg+xml;utf8,${item.image.src}`;
            }
        } else {
            return DefaultIcon;
        }
    };

    const handleDragStart = () => {
        currentMenuItemVar({
            ...item,
            itemName: item.name,
            itemTypeId: item.typeId,
        });
    };

    return (
        <ItemContainer
            isCurrentSelected={item.id === selectedObject}
            title={item.description || item.name}
            // only need to select the walls and embedded objects
            item={item}
            onClick={generateItemClickHandler()}
        >
            <ItemImageContainer>
                <ItemImg
                    src={getItemImage()}
                    alt={item.name}
                    onDragStart={handleDragStart}
                    typeName={item.typeName}
                    draggable={
                        currentTool !== Tools.wall &&
                        currentTool !== Tools.rectangleRoom &&
                        currentTool !== Tools.embeddedObject &&
                        item.typeName === 'MapObjectType'
                    }
                />
            </ItemImageContainer>
            {/* TODO: remove `item.value` when realize item saving */}
            <ItemName>{item.information || item.name || item.value}</ItemName>
        </ItemContainer>
    );
};

Item.propTypes = {
    /**
     * canvas item type
     */
    item: PropTypes.shape({
        zoneId: PropTypes.string,
        angle: PropTypes.number,
        height: PropTypes.number,
        width: PropTypes.number,
        itemName: PropTypes.string,
        pictureLink: PropTypes.string,
        position: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
        }),
    }),
};

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import accessGroupsModerator from '../../../../../../../features/AccessGroup/model/AccessGroupsModerator';
import { useReactiveVar } from '@apollo/client';
import accessGroupsAPI, {
    QUERY_STATES,
} from '../../../../../../../features/AccessGroup/api/AccessGroupsAPI';
import { AccessGroup } from '../../../../../../../features/AccessGroup/model/AccessGroup';
import { AccessGroupModal } from './AccessGroupModal';

/**
 * Component represents UI for search and adding new user
 * @component
 */
export const AddAccessGroupModal = ({ parentGroup, onClose }) => {
    const queryState = useReactiveVar(accessGroupsAPI.groupCreatingState);

    useEffect(() => {
        accessGroupsAPI.groupCreatingState(QUERY_STATES.IDLE);
    }, []);

    useEffect(() => {
        if (queryState === QUERY_STATES.SUCCESS) {
            accessGroupsAPI.groupCreatingState(QUERY_STATES.IDLE);
            onClose();
        }
        // eslint-disable-next-line
    }, [queryState]);

    /** Handler for create button click */
    const handleCreate = (form) => {
        accessGroupsModerator.createGroup(form);
    };

    const formInitial = {
        name: '',
        description: '',
        parentGroupId:
            accessGroupsModerator.currentAccessGroupId === parentGroup.groupId
                ? null
                : parentGroup.groupId,
        parentMirror: false,
        floorCreationAvailable: false,
        notificationCreationAvailable: false,
    };

    return (
        <AccessGroupModal
            formInitial={formInitial}
            parentGroup={parentGroup}
            title='Создать группу доступа'
            submitButtonText='Создать'
            isError={queryState === QUERY_STATES.ERROR}
            isLoading={queryState === QUERY_STATES.LOADING}
            onClose={onClose}
            onSubmit={handleCreate}
        />
    );
};

AddAccessGroupModal.propTypes = {
    /** Parent access group */
    parentGroup: PropTypes.instanceOf(AccessGroup),

    /** Modal close callback */
    onClose: PropTypes.func,
};

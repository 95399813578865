import {
    currentToolVar,
    stageVar,
} from '../../../../../shared/model/cache/Cache';
import { closeContextMenu } from '../../../../ContextMenu/model/CloseContextMenu';
import { Tools } from '../../../../../pages/Constructor/TopBar/TopBarEntires/Tools/Constants/Tools';
import FloorGraph from '../../../model/FloorGraph';
import zones from '../../../../Zone/model/Zones';
import { gridSnapper } from '../../helpers';
import selector from '../../../../Selector/model/Selector';

/**
 * @typedef {import('../../../model/FloorGraph').FloorGraph} FloorGraph
 */

/**
 * Handler to move point of the wall with static other ones.
 * @param {MouseEvent} e target event
 * @param {FloorGraph} graph FloorGraph or its heir
 */
export const handlePointMove = (e, graph, isShiftPressed) => {
    closeContextMenu();
    currentToolVar(Tools.none);

    const pointId = e.target.id();
    let newPos = e.target.position();

    if (isShiftPressed) {
        newPos = gridSnapper.snapToVerticalHorizontalLines(
            graph.dragStartPos,
            stageVar().getRelativePointerPosition()
        );

        e.target.position({
            x: newPos.x,
            y: newPos.y,
        });
    }

    graph.updateVertex(pointId, newPos);
    selector.updateLastSelectedSegment();

    if (graph === FloorGraph) {
        zones.rerender();
    }
};

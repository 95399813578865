import { contextMenuVar } from '../../../shared/model/cache/Cache';

/**
 * Function that set properties to `contextMenuVar` to open the context menu
 *
 * @param {object} position position of mouse click
 * @param {number} position.x
 * @param {number} position.y
 *
 * @param {JSX.Element} contextMenuComponent component with context menu
 */
export const openContextMenu = (position, contextMenuComponent) => {
    const contextMenu = contextMenuVar();
    if (contextMenu.isOpened && !contextMenu.isClosable) {
        // cancel opening new context menu if already opened menu couldn't be closed
        return;
    }

    contextMenuVar({
        position: position,
        drawPosition: { ...position },
        isOpened: true,
        isClosable: true,
        currentComponent: contextMenuComponent,
    });
};

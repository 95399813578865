import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import downArrow from '../../../shared/assets/images/icons/down-arrow.svg';
import searchLoop from '../../../shared/assets/images/icons/search.svg';
import { useState } from 'react';

const MapStructureHeaderContainer = styled.div`
    display: flex;
    height: 42px;
    align-items: center;
    background-color: white;
    margin-left: ${(props) => (props.isOpened ? '0px' : '40px')};
    padding-right: 13px;
    padding-left: ${(props) => (props.isOpened ? '53px' : '13px')};
    border: 0.5px solid #d7d7d7;
    border-radius: 0px 0px 9px 9px;
    transition: 0.1s;
    overflow: hidden;
`;

const MapStructureText = styled.span`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin-right: auto;
`;

const CollapseIcon = styled.img`
    width: 19px;
    height: 19px;
    :hover {
        cursor: pointer;
    }
    transform: ${(props) => (props.isOpened ? 'rotate(0)' : 'rotate(-90deg)')};
    transition: 0.2s;
`;

const SearchIcon = styled.img`
    margin-right: 12px;
    visibility: ${(props) => (props.isOpened ? 'visible' : 'hidden')};
    :hover {
        cursor: pointer;
    }
`;

const SearchInput = styled.input`
    min-width: 100px;
    max-width: 166px;
    padding: 0;
    border: none;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.8);
    background-color: transparent;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #000000;

    :focus {
        outline: none;
    }
`;

/**
 * Component that represents header of LeftBar. It contains search input.
 *
 * @component
 */
export const MapStructureHeader = ({ isOpened, setIsOpened }) => {
    const [isSearching, setIsSearching] = useState(false);

    const handleCollapseClick = () => {
        setIsOpened(!isOpened);
        setIsSearching(false);
    };

    const handleSearchClick = () => {
        setIsSearching(true);
    };

    return (
        <MapStructureHeaderContainer isOpened={isOpened}>
            {isSearching ? (
                <SearchInput placeholder='Поиск по каталогу' />
            ) : (
                <MapStructureText>{'Структура карты'}</MapStructureText>
            )}

            <SearchIcon
                src={searchLoop}
                alt='LeftBar'
                isOpened={isOpened}
                onClick={handleSearchClick}
            ></SearchIcon>
            <CollapseIcon
                src={downArrow}
                alt='LeftBar'
                isOpened={isOpened}
                onClick={handleCollapseClick}
            />
        </MapStructureHeaderContainer>
    );
};

MapStructureHeader.propTypes = {
    /**
     * component opened state
     */
    isOpened: PropTypes.bool,
    /**
     * set component opened state
     */
    setIsOpened: PropTypes.func,
};

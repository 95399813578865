import React from 'react';
import styled from 'styled-components';
import { Tool } from '../../TopBar/TopBarEntires/Tools/Tool';
import {
    ToolIconType,
    bottomToolsList,
} from '../../TopBar/TopBarEntires/Tools/Constants/Tools';
import {
    APPEAR_DIRECTIONS,
    ToolDropDown,
} from '../../TopBar/TopBarEntires/Tools/ToolDropDown';

const Container = styled.div`
    margin-right: 30px;
    padding: 6px 32px;
    width: fit-content;
    display: flex;
    order: -1;
    align-items: center;
    background: rgba(255, 255, 255, 1);
    border-radius: 45px;
    z-index: 10;
    user-select: none;
    pointer-events: none;
    box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.07);
    gap: 5px;
`;

const ClickableContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    :hover {
        cursor: pointer;
    }
    pointer-events: auto;
    padding: 0;
`;

/**
 * Component for comment ruler and magnet tools
 *
 * @component
 */
export const BottomTools = () => {
    return (
        <Container>
            {bottomToolsList.map((tool) => {
                if (tool.type === ToolIconType.tool) {
                    return (
                        <ClickableContainer key={tool.src}>
                            <Tool toolProps={tool} />
                        </ClickableContainer>
                    );
                } else if (tool.type === ToolIconType.dropDownTool) {
                    return (
                        <ToolDropDown
                            key={tool.name}
                            toolProps={tool}
                            appearDirection={APPEAR_DIRECTIONS.up}
                            dropDownStyle={{marginBottom: "75px"}}
                        />
                    );
                }

                return null;
            })}
        </Container>
    );
};

import { gql } from '@apollo/client';
import { MAP_OBJECT_FRAGMENT } from '../../fragments/mapObjects/MapObjectFragment';

/**
 * Mutation for updating map object (e.g. table, chair and so on)
 */
export const UPDATE_MAP_OBJECT = gql`
    ${MAP_OBJECT_FRAGMENT}
    mutation updateMapObject($mapObjectDiff: MapObjectDiff!) {
        updateMapObject(mapObjectDiff: $mapObjectDiff) {
            ...MapObjectFragment
            data {
                data
                schema {
                    schema
                }
            }
            type {
                id
                data {
                    data
                    schema {
                        schema
                    }
                }
            }
            schemaImpls {
                data
                id
                version
                schema {
                    id
                    schema
                    version
                }
            }
        }
    }
`;

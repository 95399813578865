import React from 'react';
import { H2OpenSansSemiBold } from './TextStyles';
import { ReactComponent as MascotLoad } from '../assets/images/icons/MainPage/mascotLoad.svg';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const loadingText = keyframes`
    0% {
        content: ""
    }
    
    25% {
        content: "."
    }
    
    50% {
        content: ".."
    }
    
    75% {
        content: "..."
    }
`;

const AnimatedMascotLoader = styled(MascotLoad)`
    margin-bottom: 15px;
    width: 100px;
    height: auto;

    .spinner {
        animation: ${rotate} 1.5s linear infinite;
        transform-origin: 50px 39px;
    }
`;

const AnimatedLoaderText = styled(H2OpenSansSemiBold)`
    :after {
        content: '';
        position: absolute;
        animation: ${loadingText} 1.5s linear infinite;
    }
`;

export const MascotContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

/**
 * @returns {Loader} Component with loader
 */
const Loader = () => {
    return (
        <MascotContainer>
            <AnimatedMascotLoader alt='mascot loading' />
            <AnimatedLoaderText>Загружаемся</AnimatedLoaderText>
        </MascotContainer>
    );
};

export default Loader;

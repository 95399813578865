import React, { useEffect, useState } from 'react';
import { HintItem } from '../model/HintsModerator';

/**
 * Component that represents hint element
 * @component
 */
export const Hint: React.FC<{ hint: HintItem }> = ({ hint }) => {
    const [startExitAnimation, setStartExitAnimation] = useState(false);

    // effect for closing hint after a specified time
    useEffect(() => {
        if (hint) {
            const timerToStartExitAnimation = setTimeout(() => {
                setStartExitAnimation(true);
            }, hint.showTime);

            const timerToClose = setTimeout(() => {
                hint.onClose();
            }, hint.showTime + hint.delayBeforeClose);

            return () => {
                clearTimeout(timerToStartExitAnimation);
                clearTimeout(timerToClose);
            };
        }
    }, [hint]);

    const HintComponent = (hint.element as JSX.Element).type;
    const props = (hint.element as JSX.Element).props;

    return (
        hint && (
            <HintComponent {...props} startExitAnimation={startExitAnimation} />
        )
    );
};

import React from 'react';
import styled from 'styled-components';

/**
 * Footer styled
 */
const FooterStyled = styled.footer`
    width: min (100%, 100vw);
    margin: 0;
    padding: 15px 39px 5px;
    background: #f5f5f5;

    @media (max-width: 900px) {
        padding: 15px 39px 28px;
    }
`;

/**
 * Container for centering footer
 */
const CenteredFooter = styled.div`
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
`;

/**
 * Container for columns in footer
 */
const Column = styled.div`
    margin: 0 43px 0 0;

    @media (max-width: 800px) {
        margin-right: 0;
    }
`;

/**
 * Row block in footer
 */
const Row = styled.div`
    margin: 0 0 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    opacity: 0.7;

    &.first {
        opacity: 0.9;
    }

    & a {
        text-decoration: none;
        color: inherit;
    }

    @media (max-width: 900px) {
        margin-bottom: 6px;

        &.first {
            margin-bottom: 10px;
        }
    }
`;

const Policies = styled.div`
    display: flex;
`;

/**
 * Footer
 * @component
 */
export const Footer = () => {
    return (
        <FooterStyled>
            <CenteredFooter>
                <Column>
                    <Row className='first'>
                        ООО “МАПИНС”, 2022&nbsp;-&nbsp;2023{' '}
                    </Row>
                    {/* <Policies> */}
                    <Row>
                        Есть вопросы?{' '}
                        <a href='mailto:support@mapins.cloud'>
                            support@mapins.cloud
                        </a>
                    </Row>
                </Column>
                <Policies>
                    <Row>
                        <a href='https://dev.mapins.cloud/legal/confidential/'>
                            Политика конфиденциальности
                        </a>
                    </Row>
                    <Row style={{ marginLeft: '40px' }}>
                        <a href='https://dev.mapins.cloud/legal/rules/'>
                            Пользовательское соглашение
                        </a>
                    </Row>
                </Policies>
                {/* </Column> */}
            </CenteredFooter>
        </FooterStyled>
    );
};

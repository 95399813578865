import React from 'react';
import { v4 as uuid } from 'uuid';
import { HintsModerator } from './HintsModerator';
import { BaseNotification } from '../ui/BaseNotification/BaseNotification';
import { HINT_COLOR, HINT_POSITION, HINT_TEXT_ALIGNMENT } from './Constants';
import { BASE_NOTIFICATION_ANIMATION_DURATION } from '../ui/BaseNotification/Styles/NotificationContainer';
import { BaseNotificationProps } from '../ui/BaseNotification/Types';

/**
 * @class
 * Class that stores methods to create notifications
 */
class Notifications extends HintsModerator {
    constructor() {
        super({
            position: HINT_POSITION.bottomLeft,
        });
    }

    /**
     * Method to set custom notification
     * @param {BaseNotificationProps} props props for notification
     */
    setCustomNotification(props: BaseNotificationProps = {}) {
        const id = uuid();
        const onClose = () => {
            if (props.onClose) {
                props.onClose();
            }
            this.deleteHint(id);
        };
        this.addHint(
            <BaseNotification
                {...props}
                containerPosition={this.hintsPosition}
                onClose={onClose}
            />,
            { id, delayBeforeClose: BASE_NOTIFICATION_ANIMATION_DURATION }
        );
    }

    /**
     * Method to set info notification
     * @param title
     * @param text
     */
    setNotification(title: string, text: string) {
        this.setCustomNotification({
            title: title,
            titleColor: HINT_COLOR.INFO,
            text: text,
            textAlignment: HINT_TEXT_ALIGNMENT.LEFT,
        });
    }

    /**
     * Method to set error notification
     * @param title
     * @param text
     */
    setError(title: string, text: string) {
        this.setCustomNotification({
            title: title,
            titleColor: HINT_COLOR.ERROR,
            text: text,
            textAlignment: HINT_TEXT_ALIGNMENT.LEFT,
        });
    }

    /**
     * Method to set error notification
     * @param title
     * @param text
     */
    setSuccess(title: string, text: string) {
        this.setCustomNotification({
            title: title,
            titleColor: HINT_COLOR.SUCCESS,
            text: text,
            textAlignment: HINT_TEXT_ALIGNMENT.LEFT,
        });
    }
}

const notifications = new Notifications();
export default notifications;

import { findCenter } from '../../../../MapObject/lib/MapObjectCentering';
import mapObjects from '../../../../MapObject/model/MapObjects';
import scalesConformer from '../../../../ScalesConformer/model/ScalesConformer';

/**
 * Command to save transformation of item
 *
 * @param {object} shapeRef node ref
 * @param {object} item canvas item
 */
export const transformMapObjectCommand = (shapeRef, item) => {
    changeNodeScaleToSize(shapeRef);

    const node = getNodeStateSnapshot(shapeRef);
    const updatedPosition = findCenter(
        node.position,
        scalesConformer.toMetres(node.width),
        scalesConformer.toMetres(node.height),
        node.angle
    );

    return {
        execute() {
            mapObjects.updateMapObjectTransformation(
                item.id,
                scalesConformer.toMetres(node.width),
                scalesConformer.toMetres(node.height),
                node.angle,
                updatedPosition,
            );
        },

        undo() {
            mapObjects.updateMapObjectTransformation(
                item.id,
                scalesConformer.toMetres(item.width),
                scalesConformer.toMetres(item.height),
                item.angle,
                item.position,
            );
        },
    };
};

/**
 * function that converts node's scale to size and sets its scale to 1
 *
 * @param {object} shapeRef node ref
 */
const changeNodeScaleToSize = (shapeRef) => {
    const node = shapeRef.current;

    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    node.scaleX(1);
    node.scaleY(1);

    node.width(Math.max(5, node.width() * scaleX));
    node.height(Math.max(5, node.height() * scaleY));
};

/**
 * function that returns current node parameters
 *
 * @param {object} shapeRef node ref
 * @return {object} object that stores current node's position, size and angle
 */
const getNodeStateSnapshot = (shapeRef) => {
    const node = shapeRef.current;
    return {
        position: {
            x: node.x(),
            y: node.y(),
        },
        width: node.width(),
        height: node.height(),
        angle: node.rotation(),
    };
};

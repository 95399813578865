import React from 'react';
import styled from 'styled-components';

const InputStyled = styled.input`
    border: solid #023AFF 0.5px;
    border-radius: 4px;
    padding: 2px 4px 1px;

    :disabled {
        border-color: #D7D7D7;
    }
`;

/**
 * Form component - basic input
 * @component
 */
export const Input = (props) => {
    return (
        <InputStyled {...props} />
    );
};

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    POpenSansLight,
    POpenSansRegular,
} from '../../../../shared/ui/TextStyles';

export const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    row-gap: 7px;
`;

const MenuPoint = styled.div`
    width: 100%;
    padding: 3px 10px;
    box-sizing: border-box;

    display: flex;
    align-items: center;

    cursor: pointer;
    white-space: nowrap;
    gap: 10px;

    &:hover {
        background-color: #ededed;
    }
`;

const Icon = styled.img`
    margin-left: 5px;
    width: 22px;
    height: 22px;
`;

/**
 * Handler for menu point click.
 * Need to check if handler exists. If exists - call it
 * @param {(key: string) => void} handler menu point click handler
 * @param {string} key menu point key
 */
const handlePointClick = (handler, key) => {
    if (handler) {
        handler(key);
    } else {
        console.warn("Add handler for context menu element '" + key + "'");
    }
};

/**
 * Component realize points for context menu.
 * In other words component realize list with custom elements
 * for base context menu style
 * @component
 *
 * @example
 * const menu = [{
 *     key: '1',
 *     text: 'Point 1',
 *     hint: 'ctrl+x',
 *     icon: 'icon src',
 *     onClick: (key) => console.log('clicked', key), // 'clicked 1'
 * }, {
 *     text: 'Point 2',
 *     onClick: (key) => console.log('clicked', key), // 'clicked Point 2'
 * }];
 *
 * return <PointsForContextMenu menu={menu} />
 */
export const PointsForContextMenu = ({ menu }) => {
    return (
        <Container>
            {menu.map((element) => (
                <MenuPoint
                    key={element.key || element.text}
                    onClick={() =>
                        handlePointClick(
                            element.onClick,
                            element.text || element.key
                        )
                    }
                >
                    {element.icon && <Icon src={element.icon} alt='' />}
                    <POpenSansRegular>{element.text}</POpenSansRegular>
                    <POpenSansLight>{element.hint}</POpenSansLight>
                </MenuPoint>
            ))}
        </Container>
    );
};

PointsForContextMenu.propTypes = {
    menu: PropTypes.arrayOf(
        PropTypes.shape({
            /** Menu point key */
            key: PropTypes.string,

            /** Text on menu point */
            text: PropTypes.string,

            /** Hint on menu point (shows after text) */
            hint: PropTypes.string,

            /** Icon src */
            icon: PropTypes.string,

            /**
             * Click handler
             * @type {(key: string) => void}
             */
            onClick: PropTypes.func,
        })
    ),
};

import styled from 'styled-components';

export const HorizontalLine = styled.hr`
    margin: 5px auto;
    width: 100%;
`;

export const DivWithMargin = styled.div`
    margin-inline: 5px;
`;

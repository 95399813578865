/* Paths to pages */
const registrationPage: string = '/registration';
const loginPage: string = '/login';
const projectPage: string = '/project';
const requestRecoverPage: string = '/recover/request';
const recoverPage: string = '/recover';
const recoverAllowPage: string = '/recover/allow';
const registrationAllowPage: string = '/registration/allow';
const mainPage: string = '/main';
const userSettingsPage: string = '/settings';

/* Pages titles */
const registrationPageTitle: string = 'Регистрация · Mapins';
const loginPageTitle: string = 'Добро пожаловать · Mapins';
const projectPageTitle: string = ' · Mapins';
const requestRecoverPageTitle: string = 'Восстановление пароля · Mapins';
const emailSendTitle: string = 'Письмо отправлено · Mapins'; // registrationAllowPage + recoverPage + recoverAllowPage
const mainPageTitle: string = 'Главная · Mapins';
const userSettingsPageTitle: string = 'Управление аккаунтом · Mapins';

export {
    registrationPage,
    loginPage,
    projectPage,
    requestRecoverPage,
    recoverPage,
    recoverAllowPage,
    registrationAllowPage,
    mainPage,
    userSettingsPage,
    registrationPageTitle,
    loginPageTitle,
    projectPageTitle,
    requestRecoverPageTitle,
    emailSendTitle,
    mainPageTitle,
    userSettingsPageTitle,
};

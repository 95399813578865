import { gql } from '@apollo/client';

/**
 * Mutation for deleting project by its id
 */
export const DELETE_PROJECT = gql`
    mutation deleteProject($id: String!) {
        deleteProject(id: $id) {
            id
        }
    }
`;

import { useEffect } from 'react';
import { stageVar } from '../cache/Cache';

/**
 * Function to reverse scale
 *
 * @param {number} value
 * @param {number} scale
 * @returns Unscaled value (value divided by scale)
 */
const unScale = (value, scale) => {
    return value / scale;
};

/**
 * Hook that provides grid usage in canvas
 *
 * @param {object} stage
 * @param {number} stepSize
 * @param {object} groupRef
 * @return {Array} width, height, grid
 */
export const useGrid = (stepSize, groupRef) => {
    useEffect(() => {
        if (groupRef.current) {
            groupRef.current.clip({
                x: viewRect.left,
                y: viewRect.top,
                width: viewRect.right - viewRect.left,
                height: viewRect.bottom - viewRect.top,
            });
        }
    });

    if (!stageVar()) {
        return [];
    }

    const scale = stageVar().scale().x;

    /**
     * Stage area rectangle
     * @type {object}
     */
    let stageRect = {
        left: 0,
        top: 0,
        right: stageVar().width(),
        bottom: stageVar().height(),
        offset: {
            x: unScale(stageVar().position().x, scale),
            y: unScale(stageVar().position().y, scale),
        },
    };

    /**
     * Canvas view area rectangle
     * @type {object}
     */
    let viewRect = {
        left: -stageRect.offset.x,
        top: -stageRect.offset.y,
        right: unScale(stageRect.width, scale) - stageRect.offset.x,
        bottom: unScale(stageRect.height, scale) - stageRect.offset.y,
    };

    let gridOffset = {
        x:
            Math.ceil(unScale(stageVar().position().x, scale) / stepSize) *
            stepSize,
        y:
            Math.ceil(unScale(stageVar().position().y, scale) / stepSize) *
            stepSize,
    };

    /**
     * Grid area rectangle
     * @type {object}
     */
    let gridRect = {
        left: -gridOffset.x,
        top: -gridOffset.y,
        right: unScale(stageVar().width(), scale) - gridOffset.x + stepSize,
        bottom: unScale(stageVar().height(), scale) - gridOffset.y + stepSize,
    };

    let gridFullRect = {
        left: Math.min(stageRect.left, gridRect.left),
        top: Math.min(stageRect.top, gridRect.top),
        right: Math.max(stageRect.right, gridRect.right),
        bottom: Math.max(stageRect.bottom, gridRect.bottom),
    };

    /**
     * Grid width
     * @type {number}
     */
    const xSize = gridFullRect.right - gridFullRect.left;

    /**
     * Grid height
     * @type {number}
     */
    const ySize = gridFullRect.bottom - gridFullRect.top;

    const xSteps = Math.round(xSize / stepSize);
    const ySteps = Math.round(ySize / stepSize);

    let verticalLines = [];
    let horizontalLines = [];

    for (let i = 0; i <= xSteps; ++i) {
        verticalLines.push({
            x: gridFullRect.left + i * stepSize,
            y: gridFullRect.top,
        });
    }

    for (let i = 0; i <= ySteps; i++) {
        horizontalLines.push({
            x: gridFullRect.left,
            y: gridFullRect.top + i * stepSize,
        });
    }

    let grid = {
        vertical: verticalLines,
        horizontal: horizontalLines,
    };

    return [xSize, ySize, grid];
};

import React from 'react';
import PropTypes from 'prop-types';

import Email from '../../../shared/assets/images/icons/email-2.svg';

import { EmailImg, MessageBody, MessageTitle, MessageText } from './Styles';

/**
 * Message component
 * @param { String } message message text
 * @returns MessageBody with message info
 */
export const Message = ({ message }) => {
    return (
        <MessageBody>
            <EmailImg src={Email}></EmailImg>
            <MessageTitle>Готово!</MessageTitle>
            <MessageText>{message}</MessageText>
        </MessageBody>
    );
};

Message.propTypes = {
    message: PropTypes.string,
};

import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { currentProjectVar } from '../../../../../shared/model/cache/Cache';
import { UPDATE_PROJECT } from '../../../../../shared/graphql/mutations/projects/UpdateProject';
import {
    Button,
    Input,
} from '../../../../../shared/ui/RolesSettingsComponents/Styles';
import { SCROLLBAR_STYLED } from '../../../../../shared/ui/ScrollBar';
import { AccuracyInput } from './AccuracyInput';

const Form = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    overflow: auto;
    ${SCROLLBAR_STYLED}
`;

const InputCustom = styled(Input)`
    width: calc(100% - 10px);
    min-width: 1px;
    max-width: 290px;
    padding: 4px;
    border-radius: 4px;
`;

const Label = styled.label`
    font-weight: 300;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 10px;
`;

export const TextArea = styled.textarea`
    width: calc(100% - 12px);
    min-height: 58px;
    max-height: 350px;

    border: 0.5px solid #d7d7d7;
    border-radius: 4px;
    padding: 4px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    resize: vertical;
    ${SCROLLBAR_STYLED}

    &:focus {
        outline: none;
        border-color: #023aff;
    }
`;

const FooterButtons = styled.div`
    margin: 34px 0 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 5px;
`;

/**
 * Component represents tab with map settings
 * @component
 */
export const MapSettingsTab = ({ onClose }) => {
    const [updateProject] = useMutation(UPDATE_PROJECT);
    const [form, setForm] = useState({
        name: currentProjectVar().name,
        id: currentProjectVar().id,
        description: currentProjectVar().description,
        // lastModified: new Date().toISOString().slice(0, -1),
        accuracy: currentProjectVar().accuracy,
    });

    const changeHandle = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const saveHandle = (e) => {
        e.preventDefault();
        updateProject({
            variables: {
                projectDiff: {
                    ...form,
                },
            },
        })
            .then((response) => {
                if (response.data) {
                    currentProjectVar({
                        id: response.data.updateProject.id,
                        name: response.data.updateProject.name,
                        ownerId: response.data.updateProject.ownerId,
                        description: response.data.updateProject.description,
                        accuracy: response.data.updateProject.accuracy,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                if (onClose) {
                    onClose();
                }
            });
    };

    return (
        <Form onSubmit={saveHandle}>
            <Label htmlFor='name'>Название</Label>
            <InputCustom
                onKeyDown={(e) => e.stopPropagation()}
                onChange={changeHandle}
                name='name'
                id='name'
                value={form.name}
                style={{ marginBottom: '20px' }}
            />

            <Label htmlFor='description'>Описание</Label>
            <TextArea
                onKeyDown={(e) => e.stopPropagation()}
                onChange={changeHandle}
                name='description'
                id='description'
                value={form.description}
            />
            <FooterButtons>
                <AccuracyInput value={form.accuracy} onChange={changeHandle} />
                <Button>Сохранить</Button>
            </FooterButtons>
        </Form>
    );
};

MapSettingsTab.propTypes = {
    /** Close handler */
    onClose: PropTypes.func,
};

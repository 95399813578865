import {
    closestWallPointVar,
    currentToolVar,
} from '../../../../shared/model/cache/Cache';

import cursorModerator from '../../../../shared/lib/utils/common/CursorModerator';
import { Tools } from '../../../../pages/Constructor/TopBar/TopBarEntires/Tools/Constants/Tools';
import selector from '../../../../features/Selector/model/Selector';
import floorGraph from '../../../../features/Wall/model/FloorGraph';
import routesModerator from '../../../../features/Route/model/RoutesModerator';
import zones from '../../../../features/Zone/model/Zones';
import rightBarModerator from '../../../../pages/Constructor/RightBar/model/RightBarModerator';

/**
 * Cancel all tool actions and clear reactive vars
 *
 * @description Temporary Functionality!
 */
export const handleCancel = () => {
    currentToolVar(Tools.none);
    rightBarModerator.clearOperation();
    rightBarModerator.selectedObject(null);

    floorGraph.cancelCreating();
    floorGraph.tmpEmbeddedObjectEdgeVar([]);
    floorGraph.finishCreatingRect();
    selector.clear();
    routesModerator.cancelCreating();
    zones.donorZoneVar(null);
    closestWallPointVar(null);

    cursorModerator.setDefault();
};

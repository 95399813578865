import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useReactiveVar } from '@apollo/client';
import { stageScaleVar } from '../../../../shared/model/cache/Cache';
import {
    MAX_SCALE,
    MIN_SCALE,
} from '../../../../widgets/Canvas/lib/CanvasDefaultValues';
import { useOutsideClick } from '../../../../shared/model/hooks/UseOutsideClick';
import { scaleCanvasFromCenter } from '../../../../widgets/Canvas/lib/utils/CenterStagePositionAfterScale';

const ZoomRegulatorInputContainer = styled.div`
    margin: 12px 14px;
    display: flex;
    align-items: center;
`;

const ZoomPercentInput = styled.input`
    max-width: 42px;
    padding: 2px 0 0 0;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    border: none;
    outline: none;
    background: none;
    pointer-events: auto;
`;

/**
 * Component with input for setting scale
 * @component 
 */
export const ZoomRegulatorInput = ({ closeInputField }) => {
    const scale = useReactiveVar(stageScaleVar);

    const [newScale, setNewScale] = useState(Math.round(scale * 100));
    const zoomInputRef = useRef(null);

    /** Function that closes input */
    const close = () => {
        let newValue = newScale / 100;
        if (newValue > MAX_SCALE) {
            newValue = MAX_SCALE;
        } else if (newValue < MIN_SCALE) {
            newValue = MIN_SCALE;
        }
        scaleCanvasFromCenter(newValue);
        closeInputField();
    };

    useOutsideClick(zoomInputRef, close);

    const handleChange = (e) => {
        let value = e.target.value;

        if (value !== '') {
            value = Number.parseInt(value);
            if (isNaN(value)) {
                value = newScale;
            }
        }

        setNewScale(value);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            close();
        }
    };

    return (
        <ZoomRegulatorInputContainer>
            <ZoomPercentInput
                ref={zoomInputRef}
                value={newScale}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                autoFocus
            />
            %
        </ZoomRegulatorInputContainer>
    );
};

ZoomRegulatorInput.propTypes = {
    /**
     * Function for switching input field to div
     */
    closeInputField: PropTypes.func,
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal } from '../../../features/ModalWindow/Modal';
import { SecondaryButton } from '../RolesSettingsComponents/Styles';
import { ItemCardWithButton } from './ItemCardWithButton';
import { SCROLLBAR_STYLED } from '../ScrollBar';
import { H3OpenSansRegular, POpenSansLight } from '../TextStyles';
import { SearchBar } from '../RolesSettingsComponents/SearchBar';

/**
 * @typedef {{
 *  id: string?,
 *  title: string,
 *  description: string,
 *  icon: string?,
 *  iconWidth: string?,
 *  iconHeight: string?,
 * }} SelectableItem
 */

const Wrapper = styled.div`
    margin: 0 15px 15px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 15px;

    width: min(500px, calc(100vw - 60px));

    max-height: calc(100dvh - 150px);
    overflow: auto;
    ${SCROLLBAR_STYLED}
`;

const Title = styled(H3OpenSansRegular)`
    font-weight: 600;
    align-self: center;
`;

const ScrollContainer = styled.div`
    max-width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;

    padding: 0 10px;
    overflow-y: auto;
    overflow-x: hidden;
    ${SCROLLBAR_STYLED}
`;

const CenterText = styled(POpenSansLight)`
    margin: 15px 10px;
    align-self: center;
`;

const Footer = styled.footer`
    padding: 15px 10px 0 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    border-top: 0.5px solid #d9d9d9;
`;

/**
 * Component that represents modal window with list of selectable elements
 * @component
 */
export const ItemSelector = ({
    items = [],
    onSelect,
    isOpened = true,
    zIndex = 1000,
    onClose,
}) => {
    const [searchStr, setSearchStr] = useState('');

    if (searchStr !== '') {
        const lowerCaseSearch = searchStr.toLowerCase();
        items = items.filter(({ title }) =>
            title.toLowerCase().includes(lowerCaseSearch)
        );
    }

    /**
     * @param {React.ChangeEvent} e
     */
    const handleSearch = (e) => {
        setSearchStr(e.target.value);
    };

    /**
     * Select handler
     * @param {SelectableItem} item 
     */
    const handleSelect = (item) => {
        if(onSelect) {
            onSelect(item);
        }
        onClose();
    };

    return (
        <Modal
            isOpened={isOpened}
            isCloseIconShow={true}
            onClose={onClose}
            zIndex={zIndex}
        >
            <Wrapper>
                <Title>Выбор группы доступа</Title>
                <SearchBar
                    placeholder='Начните вводить'
                    onChange={handleSearch}
                    value={searchStr}
                    autoFocus
                />
                <ScrollContainer>
                    {items.length === 0 && (
                        <CenterText>Группы не найдены</CenterText>
                    )}
                    {items.map((item) => (
                        <ItemCardWithButton
                            key={item.id || item.title}
                            title={item.title}
                            description={item.description}
                            icon={item.icon}
                            iconWidth={item.iconWidth}
                            iconHeight={item.iconHeight}
                            buttonOnClick={() => handleSelect(item)}
                        />
                    ))}
                </ScrollContainer>
                <Footer>
                    <SecondaryButton onClick={onClose}>Отмена</SecondaryButton>
                </Footer>
            </Wrapper>
        </Modal>
    );
};

ItemSelector.propTypes = {
    /**
     * Array of showing items
     * @type {SelectableItem}
     */
    items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        icon: PropTypes.string,
        iconWidth: PropTypes.string,
        iconHeight: PropTypes.string,
    })),

    /**
     * Select handler
     * @type {(item: SelectableItem) => void}
     */
    onSelect: PropTypes.func,

    /** Is modal opened */
    isOpened: PropTypes.bool,

    /** Handler for close event */
    onClose: PropTypes.func,

    /** Custom z-index needed to realize nested modals */
    zIndex: PropTypes.number,
};

import { client } from '../../../shared/graphql/ApolloClient';
import { currentFloorVar } from '../../../shared/model/cache/Cache';
import { CREATE_WALL_SEGMENTS } from '../../../shared/graphql/mutations/walls/CreateWallSegments';
import { UPDATE_WALL_SEGMENT } from '../../../shared/graphql/mutations/walls/UpdateWallSegment';
import { DELETE_WALL_SEGMENT } from '../../../shared/graphql/mutations/walls/DeleteWallSegment';
import {
    MERGE_WALL_POINTS,
    UPDATE_WALL_SEGMENTS_INFO,
    UPDATE_WALL_SEGMENTS_POS,
} from '../../../shared/graphql/mutations/walls/UpdateWallSegments';
import { SPLIT_WALL_SEGMENT } from '../../../shared/graphql/mutations/walls/SplitWallSegment';
import { COLLAPSE_WALL_SEGMENT } from '../../../shared/graphql/mutations/walls/CollapseWallSegment';
import { getRuleForCurrentUser } from '../../AccessGroup/lib/GetRuleForCurrentUser';
import { INSERT_WALL_SEGMENT } from '../../../shared/graphql/mutations/walls/InsertWallSegment';

/**
 * @typedef {import('../../../shared/lib/modules/math/Math').Point} Point
 * @typedef {import('../../Zone/model/Zone').ZoneProps} Zone
 */

/**
 * @typedef {{id: String, coordinate: Point}} WallPoint
 */

/**
 * Describe short access rule data structure
 * @typedef {{
 * group: {id: string, name: string?},
 * rule: string
 * }} AccessRuleFromServer
 */

/**
 * @typedef {{
 * data: DataSchemaImplInPlaceDtoInput
 * description: String
 * endPoint: WallPointInPlace
 * imageId: String
 * lengthRestriction: Float
 * name: String
 * selector: VersionedSegmentSelector!
 * startPoint: WallPointInPlace
 * techImageId: String
 * through: Boolean
 * transparency: Float
 * typeId: String
 * width: Float
 * version: Number
 * }} WallSegmentDiff
 */

/**
 * @typedef {{
 *  startPointId: String,
 *  endPointId: String,
 *  typeId: String
 * }} WallSegmentDraft
 *
 * @typedef {{coordinate: Point, id: String}} VirtualPoint
 */

/**
 * @typedef {{id: String, src: String, type: String}} ImageSource
 */

/**
 * @typedef {{
 *  scalable: Boolean,
 *  id: String,
 *  length: Number,
 *  through: Boolean,
 *  image: ImageSource,
 *  imageOffsetCoefficient: Number,
 *  width: Number,
 *  name: String,
 *  description: String,
 *  virtual: Boolean
 * }} WallSegmentType
 */

/**
 * @typedef {{
 *  description: String,
 *  endPoint: {coordinate: Point, id: String, accessRules: AccessRuleFromServer[]},
 *  id: String,
 *  version: Number,
 *  lengthRestriction: Float,
 *  name: String,
 *  startPoint: {coordinate: Point, id: Strings, accessRules: AccessRuleFromServer[]},
 *  width: Float,
 *  through: Boolean,
 *  image: ImageSource,
 *  imageOffsetCoefficient: Number,
 *  accessRules: [AccessRule]!,
 *  techImage: ImageSource,
 *  secondZone: Zone,
 *  firstZone: Zone,
 *  type: WallSegmentType,
 *  schemaImpls: [MapinsSchemaImpl]
 * }} WallSegment
 */

/**
 * @typedef {{firstPointId: String, secondPointId: String}} SegmentPoints
 */

/**
 * @typedef { {id: String, points: SegmentPoints, version: Number}} VersionedSegmentSelector
 */

/**
 * @typedef {{
 *  moveVector: Point,
 *  wallPointsIds: String[],
 *  wallSegmentSelectors: VersionedSegmentSelector[]
 * }} MoveWallItemsOperation
 */

/**
 * @typedef {{
 *  endPoint: {id: String, coordinates: Point }
 *  startPoint: {id: String, coordinates: Point }
 *  typeId: String
 * }} WallSegmentInPlaceDraft
 */

/**
 * Class that realizes api methods for wall segments
 * @class
 */
class WallSegmentsAPI {
    wallUpdatesSubscription;

    /**
     *
     * @param {WallSegment} responseWallSegment
     */
    adaptSegment(responseWallSegment) {
        return {
            id: responseWallSegment.id,
            name: responseWallSegment?.name,
            type: responseWallSegment.type,
            isFetched: true,
            accessRule: getRuleForCurrentUser(responseWallSegment.accessRules),
            version: responseWallSegment.version,
        };
    }

    /**
     * Method that calls api mutation for wall segments creation
     * @param {WallSegmentDraft[]} segments
     * @param {VirtualPoint[]} points
     * @param {Boolean} [cutOnIntersect=false]
     * @returns {[WallSegment[], Map<String, String>]} WallSegments and map
     * that translates local point ids to backend points ids
     */
    async create(segments, points, cutOnIntersect = false) {
        const options = {
            mutation: CREATE_WALL_SEGMENTS,
            variables: {
                floorId: currentFloorVar().id,
                segments,
                points: points.map((p) => ({
                    id: p.id,
                    coordinate: p.coordinate,
                })),
                cutOnIntersect,
            },
        };

        try {
            const result = await client.mutate(options);
            const walls = [
                ...result.data.createWallSegments.createdSegments,
                ...result.data.createWallSegments.updatedSegments,
            ];
            const pointsTranslateMap = new Map(
                Object.entries(result.extensions.pointsTranslateMap)
            );
            const zones = result.data.createWallSegments.zones;

            return [walls, pointsTranslateMap, zones];
        } catch (error) {
            throw new Error(error.message);
        }
    }

    /**
     * Method that calls mutation to update wall segment
     * @param {WallSegmentDiff} wallSegmentDiff
     * @returns {WallSegment[]}
     */
    async update(wallSegmentDiff) {
        const options = {
            mutation: UPDATE_WALL_SEGMENT,
            variables: {
                wallSegmentDiff: {
                    ...wallSegmentDiff,
                },
            },
        };

        try {
            return (await client.mutate(options)).data.updateWallSegment
                .updatedSegments;
        } catch (error) {
            throw new Error(error.message);
        }
    }

    /**
     * Method that calls mutation to update wall segments
     * @param {MoveWallItemsOperation | null} moveWallItems
     * @returns {WallSegment[]}
     */
    async updateSegmentsPositions(moveWallItems) {
        const options = {
            mutation: UPDATE_WALL_SEGMENTS_POS,
            variables: {
                moveWallItems: moveWallItems,
            },
        };

        try {
            return (await client.mutate(options)).data.updateWallSegments
                .updatedSegments;
        } catch (error) {
            throw new Error(error.message);
        }
    }

    /**
     * Method that calls mutation to update wall segments
     * @param {WallSegmentDiff[] | null} wallSegmentDiffs
     * @returns {WallSegment[]}
     */
    async updateSegmentsInfo(wallSegmentDiffs) {
        const options = {
            mutation: UPDATE_WALL_SEGMENTS_INFO,
            variables: {
                wallSegmentDiffs: wallSegmentDiffs,
            },
        };

        try {
            return (await client.mutate(options)).data.updateWallSegments
                .updatedSegments;
        } catch (error) {
            throw new Error(error.message);
        }
    }

    async mergeUnconnectedPoints(basePointId, mergingPointId) {
        const options = {
            mutation: MERGE_WALL_POINTS,
            variables: {
                basePointId,
                mergingPointId,
            },
        };

        try {
            return (await client.mutate(options)).data.mergeWallPoints
                .updatedSegments;
        } catch (error) {
            throw new Error(error.message);
        }
    }

    /**
     * Method that calls mutation to delete wall segment
     * @param {VersionedSegmentSelector} selector
     * @returns {{id: String, version: Number}}
     */
    async delete(selector) {
        const options = {
            mutation: DELETE_WALL_SEGMENT,
            variables: {
                selector: selector,
            },
        };

        try {
            return (await client.mutate(options)).data.deleteWallSegment;
        } catch (error) {
            throw new Error(error.message);
        }
    }

    /**
     * Method that calls mutation to split wall segment in point
     * @param {VersionedSegmentSelector} selector
     * @param {Point} splitPoint
     * @returns {[WallSegment[], Zone[]]}
     */
    async split(selector, splitPoint) {
        const options = {
            mutation: SPLIT_WALL_SEGMENT,
            variables: {
                segmentSelector: selector,
                splitPoint: splitPoint,
            },
        };

        try {
            const response = (await client.mutate(options)).data
                .splitWallSegment;
            const walls = [
                ...response.createdSegments,
                ...response.updatedSegments,
            ];
            const zones = response.zones;

            return [walls, zones];
        } catch (error) {
            throw new Error(error.message);
        }
    }

    /**
     * Method that calls mutation to merge two segments
     * @param {VersionedSegmentSelector} segmentSelector
     * @param {{id: String, coordinate: Point}} savePointSelector
     * @returns {{walls: WallSegment[], zones: Zone[]}
     */
    async collapse(segmentSelector, savePointSelector) {
        const options = {
            mutation: COLLAPSE_WALL_SEGMENT,
            variables: {
                segmentSelector,
                savePointSelector,
            },
        };

        try {
            const response = (await client.mutate(options)).data
                .collapseWallSegment;
            const walls = [
                ...response.createdSegments,
                ...response.updatedSegments,
            ];
            const zones = response.zones;

            return { walls, zones };
        } catch (error) {
            throw new Error(error.message);
        }
    }

    /**
     * Method that calls mutation to split wall segment in point
     * @param {VersionedSegmentSelector} selector
     * @param {WallSegmentInPlaceDraft} insertSegmentDraft
     * @returns {[WallSegment[], Zone[]]}
     */
    async insert(selector, insertSegmentDraft) {
        const options = {
            mutation: INSERT_WALL_SEGMENT,
            variables: {
                baseSegmentSelector: selector,
                insertSegmentDraft: insertSegmentDraft,
            },
        };

        try {
            const response = (await client.mutate(options)).data
                .insertWallSegment;
            const walls = [
                ...response.createdSegments,
                ...response.updatedSegments,
            ];
            const zones = response.zones;

            return [walls, zones];
        } catch (error) {
            throw new Error(error.message);
        }
    }

    // TODO: rewrite for new api
    // async subscribeToWallSegmentsUpdates() {
    //     const observer = client.subscribe({
    //         query: WALL_SEGMENTS_UPDATES,
    //         variables: {
    //             subscriptionItem: {
    //                 floorId: currentFloorVar().id,
    //                 token: localStorage.getItem(SESSION_TOKEN),
    //             },
    //         },
    //     });

    //     // TODO: remove log, add saving data to store
    //     // check why it's calling twice
    //     this.wallUpdatesSubscription = observer.subscribe(({ data }) => {
    //         console.log(data);
    //     });
    // }
}

const wallSegmentsApi = new WallSegmentsAPI();
export default wallSegmentsApi;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SecondaryButton } from '../RolesSettingsComponents/Styles';
import { POpenSansLight, POpenSansRegular } from '../TextStyles';
import circleIcon from '../../assets/images/icons/circle-in-settings.svg';

const Container = styled.div`
    flex-grow: 1;
    display: flex;
    gap: 10px;
    align-items: center;
`;

const Photo = styled.img`
    height: ${(props) => props.iconWidth};
    width: ${(props) => props.iconHeight};
`;

const TitleAndDesc = styled.div`
    overflow: hidden;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

const Title = styled(POpenSansRegular)`
    font-size: 14px;
    line-height: 19.07px;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const Description = styled(POpenSansLight)`
    font-size: 11px;
    line-height: 10.8px;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

/**
 * Component with short object info card and button
 * @component
 */
export const ItemCardWithButton = ({
    title,
    description,
    icon = circleIcon,
    iconWidth = 'auto',
    iconHeight = '30px',
    buttonText = 'Выбрать',
    buttonOnClick,
}) => {
    return (
        <Container>
            <Photo
                src={icon}
                alt='card photo'
                iconWidth={iconWidth}
                iconHeight={iconHeight}
            />
            <TitleAndDesc>
                <Title>{title}</Title>
                <Description>{description}</Description>
            </TitleAndDesc>
            <SecondaryButton
                style={{ marginLeft: '10px' }}
                onClick={buttonOnClick}
            >
                {buttonText}
            </SecondaryButton>
        </Container>
    );
};

ItemCardWithButton.propTypes = {
    /** Card title */
    title: PropTypes.string,

    /** Card description */
    description: PropTypes.string,

    /** Icon img src */
    icon: PropTypes.string,

    /** Icon width css value (30px, auto, etc) */
    iconWidth: PropTypes.string,

    /** Icon height css value (30px, auto, etc) */
    iconHeight: PropTypes.string,

    /** Text for button */
    buttonText: PropTypes.string,

    /** Button click handler */
    buttonOnClick: PropTypes.func,
};

import { gql } from '@apollo/client';
import { ACCESS_RULE_FRAGMENT } from '../accessRules/AccessRuleFragment';

export const ROUTE_FRAGMENT = gql`
    ${ACCESS_RULE_FRAGMENT}
    fragment RouteFragment on Route {
        id
        version
        name
        description
        color
        transparency
        accessRules {
            ...AccessRuleFragment
        }
    }
`;

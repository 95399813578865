import { DocumentNode, gql } from '@apollo/client';
import { CONSTRAINT_GROUP_FRAGMENT } from '../../fragments/constraintGroups/ConstraintGroupFragment';

export const CREATE_CONSTRAINT_GROUP: DocumentNode = gql`
    ${CONSTRAINT_GROUP_FRAGMENT}
    mutation createConstraintGroup(
        $constraintGroupDraft: ConstraintGroupDraft!
    ) {
        createConstraintGroup(constraintGroupDraft: $constraintGroupDraft) {
            ...ConstraintGroupFragment
        }
    }
`;

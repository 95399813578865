import { makeVar } from '@apollo/client';

class TemporaryZone {
    constructor() {
        this.tmpZonePointLinksVar = makeVar(new Set());
    }

    /**
     * Method to clean tmpZonePointsIdsVar
     */
    finishCreating() {
        this.tmpZonePointLinksVar(new Set());
    }
}

const temporaryZone = new TemporaryZone();
export default temporaryZone;

import React from 'react';
import PropTypes from 'prop-types';
import binIcon from '../../../../shared/assets/images/icons/ConstructorPage/bin.svg';
import addAccessIcon from '../../../../shared/assets/images/icons/ConstructorPage/add-access.svg';
import openMapinsFormIcon from '../../../../shared/assets/images/icons/open-mapins-form.svg';
import editIcon from '../../../../shared/assets/images/icons/ConstructorPage/pen.svg';
import { PointsForContextMenu } from '../styles/PointsForContextMenu';

/**
 * Default menu of zone context menu
 * @component
 */
export const ZoneDefaultContextMenu = ({
    handleActivateMapinsForm,
    handleOpenAccessClick,
    handleEditButtonClick,
    handleMergeButtonClick,
}) => {
    const menu = [
        {
            text: 'Открыть форму',
            icon: openMapinsFormIcon,
            onClick: handleActivateMapinsForm,
        },
        {
            text: 'Открыть доступ пользователю',
            icon: addAccessIcon,
            onClick: handleOpenAccessClick,
        },
        {
            text: 'Переименовать',
            icon: editIcon,
            onClick: handleEditButtonClick,
        },
        {
            text: 'Объединить',
            icon: binIcon,
            onClick: handleMergeButtonClick,
        },
    ];

    return <PointsForContextMenu menu={menu} />;
};

ZoneDefaultContextMenu.propTypes = {
    /** Handler for zone access settings button click */
    handleOpenAccessClick: PropTypes.func,

    /** Handler for zone edit button click */
    handleEditButtonClick: PropTypes.func,

    /** Handler for zone merge button click */
    handleMergeButtonClick: PropTypes.func,
};

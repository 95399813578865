import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Html } from 'react-konva-utils';
import PropTypes from 'prop-types';
import {
    graphicSchemaVar,
    modeVar,
    stageScaleVar,
} from '../../shared/model/cache/Cache';
import { useRefCallback } from '../../shared/model/hooks/UseRefCallback';
import {
    CloudSvg,
    SVG_CLOUD_PADDING_SIZE,
    SVG_CLOUD_PROTRUSION_HEIGHT,
    SVG_CLOUD_SHADOW_SIZE,
} from './ui/CloudSvg';
import { DefaultProperties } from './ui/DefaultProperties';
import { ExtendedProperties } from './ui/ExtendedProperties';
import { BotProperties } from './ui/BotProperties';
import {
    PHoverInterLink,
    PHoverInterRegular,
} from '../../shared/ui/TextStyles';
import {
    addNewExtendedProperty,
    changeBotProperty,
    changeExtendedProperty,
    deleteExtendedProperty,
} from './model/SchemaEditHelpers';
import * as Styled from './ui/styles/GraphicSchemaStyles';
import { NewExtendedProperty } from './ui/NewExtendedProperty';
import notifications from '../HintSystem/model/Notifications';
import { CREATING_MODE } from '../../shared/lib/utils/ModeDefaultValues';
import { objectWithSchemaTypes } from '../../shared/model/mapinsForm/activateMapinsForm';
import { schemaSaveDefiner } from './lib/SchemaApiService';

/**
 * @typedef {import('../../shared/model/cache/Cache').GraphicSchema} GraphicSchema
 */

const extendablePropertiesContextDefaultValue = {
    fields: [],
    isEditMode: false,
    onChange: () => undefined,
    onDelete: () => undefined,
};

export const ExtendablePropertiesContext = React.createContext(
    extendablePropertiesContextDefaultValue
);

export const BotPropertiesContext = React.createContext({
    isEditMode: false,
    onChange: () => undefined,
});

/**
 * Component represents graphic schema for objects
 * @component
 */
export const GraphicSchema = ({ schema }) => {
    const scale = useReactiveVar(stageScaleVar);
    const [isEditMode, setIsEditMode] = useState(false);

    /**
     * @type {[
     *  schema: import('../../shared/model/cache/Cache').GraphicSchema,
     * ]}
     */
    const [schemaObject, setSchemaObject] = useState(schema.copy());
    const schemaObjectRef = useRef(schemaObject);

    // set subscription to schema change
    useEffect(() => {
        if (schema) {
            setSchemaObject(schema?.copy());
        }
    }, [schema]);

    /** Position for content wrapper */
    const [position, setPosition] = useState({
        x: schemaObject.position.x,
        y: schemaObject.position.y - 500, // 500px is needed to animate first time from top
    });

    /** Var for storing content size */
    const [contentSize, setContentSize] = useState({ width: 0, height: 0 });

    /** Value and ref for content wrapper, needed for calculate cloud size */
    const [contentWrapperValue, contentWrapperRef] = useRefCallback();

    /** Function that changes cloud size and position */
    const changeCloud = useCallback(() => {
        if (contentWrapperValue) {
            setContentSize({
                width:
                    contentWrapperValue.offsetWidth - Styled.PADDING_FOR_SCROLL,
                height: contentWrapperValue.offsetHeight,
            });

            setPosition({
                // for X it is necessary to shift half of cloud
                x:
                    schemaObject.position.x -
                    contentWrapperValue.offsetWidth / 2 -
                    SVG_CLOUD_PADDING_SIZE -
                    SVG_CLOUD_SHADOW_SIZE,

                // for Y it is necessary to shift full cloud without one shadow
                y:
                    schemaObject.position.y -
                    contentWrapperValue.offsetHeight -
                    SVG_CLOUD_PROTRUSION_HEIGHT -
                    SVG_CLOUD_PADDING_SIZE * 2 -
                    SVG_CLOUD_SHADOW_SIZE * 2 +
                    SVG_CLOUD_SHADOW_SIZE / scale,
            });
        }
    }, [
        contentWrapperValue,
        scale,
        schemaObject.position.x,
        schemaObject.position.y,
    ]);

    useEffect(() => {
        schemaObjectRef.current = schemaObject;
        changeCloud();
    }, [schemaObject, changeCloud]);

    // use effect for setting mutation observer
    useEffect(() => {
        // When inside component changes layout
        // (for example, was opened/closed subcategory)
        // wrapper component will not change and wrapper component will not rerender,
        // so we need MutationObserver to identify wrapper component changes
        // and recalculate component position
        const observer = new MutationObserver(changeCloud);

        // add wrapper element to the observer
        if (contentWrapperValue) {
            observer.observe(contentWrapperValue, {
                subtree: true,
                childList: true,
            });
        }

        return () => {
            observer.disconnect();
        };
    }, [changeCloud, contentWrapperValue]);

    /** Handler for 'save' button. Save schema current state */
    const handleSave = () => {
        if (
            schemaObject.schema.objectExtendablePropsSchema.fields.every(
                (item) => item.saveValidate(item.currentValue.value)
            ) &&
            schemaObject.schema.objectBotPropsSchemas.every((schemaImpl) =>
                schemaImpl.fields.every((item) =>
                    item.saveValidate(item.currentValue.value)
                )
            )
        ) {
            setIsEditMode(false);

            schemaSaveDefiner(schemaObject);
            graphicSchemaVar(schemaObject.copy());
        } else {
            notifications.setError(
                'Ошибка',
                'Некоторые поля не соответствуют ограничениям'
            );
        }
    };

    /** Handler for 'cancel' button. Returns schema to initial state */
    const handleCancel = () => {
        setIsEditMode(false);
        setSchemaObject(schema.copy());
    };

    /**
     * Handler that changes value of extended properties for schema
     * @param {string} propertyKey name of property to change
     * @param {string} value new property value
     */
    const handleChangeExtendedProperties = (propertyKey, value) => {
        setSchemaObject(
            changeExtendedProperty(schemaObjectRef, propertyKey, value)
        );
    };

    /**
     *
     * @param {String} schemaImplId
     * @returns {callback}
     */
    const handleChangeBotProperties = (schemaImplId) => {
        return (propertyKey, value) => {
            setSchemaObject(
                changeBotProperty(
                    schemaObjectRef,
                    schemaImplId,
                    propertyKey,
                    value
                )
            );
        };
    };

    /**
     * Handler for adding new property to extended properties
     * @param {string} propertyName
     * @param {string} propertyType
     */
    const handleAddNewExtendedProperty = (propertyName, propertyType) => {
        // TODO: realize adding extended properties for wall segments and zones
        if (
            schemaObjectRef.current.objectTypeName !==
            objectWithSchemaTypes.mapObject
        ) {
            return;
        }
        const result = addNewExtendedProperty(
            schemaObjectRef,
            propertyName,
            propertyType
        );
        if (result) {
            setSchemaObject(result);
        } else {
            notifications.setError(
                'Ошибка',
                'Поле с таким названием уже существует'
            );
        }
    };

    /**
     * Handler for deleting property from schema extended properties
     * @param {string} propertyName
     */
    const handleDeleteExtendedProperty = (propertyName) => {
        setSchemaObject(deleteExtendedProperty(schemaObjectRef, propertyName));
    };

    return (
        <Html>
            <Styled.UIFormContainer
                position={position}
                scale={scale}
                id='container'
            >
                <Styled.ContentWrapper
                    ref={contentWrapperRef}
                    id='content_wrapper'
                >
                    <Styled.TitleContainer>
                        <Styled.ObjectName fontSize='20px' lineHeight='27px'>
                            {schemaObject.objectName}
                        </Styled.ObjectName>
                        {modeVar() === CREATING_MODE && (
                            // TODO: realize data scheme editing for wall segments, zones

                            <Styled.ButtonBar>
                                {isEditMode ? (
                                    <>
                                        <PHoverInterRegular
                                            onClick={handleCancel}
                                        >
                                            Отмена
                                        </PHoverInterRegular>
                                        <PHoverInterLink onClick={handleSave}>
                                            Сохранить
                                        </PHoverInterLink>
                                    </>
                                ) : (
                                    <PHoverInterLink
                                        onClick={() => setIsEditMode(true)}
                                    >
                                        Редактировать
                                    </PHoverInterLink>
                                )}
                            </Styled.ButtonBar>
                        )}
                    </Styled.TitleContainer>
                    <Styled.FieldsWrapper>
                        <DefaultProperties
                            fields={schemaObject.schema.objectTypeSchema.fields}
                        />
                        {schemaObject.objectTypeName ===
                            objectWithSchemaTypes.mapObject && (
                            <ExtendablePropertiesContext.Provider
                                value={{
                                    ...extendablePropertiesContextDefaultValue,
                                    fields: schemaObject.schema
                                        .objectExtendablePropsSchema.fields,
                                    isEditMode: isEditMode,
                                    onChange: handleChangeExtendedProperties,
                                    onDelete: handleDeleteExtendedProperty,
                                }}
                            >
                                <ExtendedProperties />
                            </ExtendablePropertiesContext.Provider>
                        )}

                        {schemaObject.schema.objectBotPropsSchemas.map(
                            (botPropertiesSchema, index) => (
                                <BotPropertiesContext.Provider
                                    key={index + 'provider'}
                                    value={{
                                        isEditMode: isEditMode,
                                        onChange: handleChangeBotProperties(
                                            botPropertiesSchema.schemaImplId
                                        ),
                                    }}
                                >
                                    <BotProperties
                                        key={index}
                                        name={
                                            botPropertiesSchema?.__metainf__
                                                ?.title
                                        }
                                        fields={botPropertiesSchema.fields}
                                    />
                                </BotPropertiesContext.Provider>
                            )
                        )}
                    </Styled.FieldsWrapper>
                    {isEditMode &&
                        schemaObject.objectTypeName ===
                            objectWithSchemaTypes.mapObject && (
                            <NewExtendedProperty
                                handleAdd={handleAddNewExtendedProperty}
                            />
                        )}
                </Styled.ContentWrapper>
                <Styled.CloudSvgContainer>
                    <CloudSvg
                        contentWidth={contentSize.width}
                        contentHeight={contentSize.height}
                    />
                </Styled.CloudSvgContainer>
            </Styled.UIFormContainer>
        </Html>
    );
};

GraphicSchema.propTypes = {
    /** 
     * Mapins schema
     * @type {GraphicSchema}
     */
    schema: PropTypes.object,
};

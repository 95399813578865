/**
 * @file Store data structure descriptions for constraint groups (CG)
 * and methods to convert one CG data structure to another
 */

/**
 * @typedef {import('./ConstraintGroup').ConstraintGroup} ConstraintGroup
 * @typedef {import('../../Route/model/RouteAdapter').RouteDataFromServer} RouteDataFromServer
 */

/**
 * Describes CG data structure, used in project.
 * Store only necessary data
 * @typedef {{
 *  id: string?,
 *  name: string?,
 *  description: string?,
 *  version: number?,
 * }} ConstraintGroupData
 */

/**
 * CG data received from server
 * @typedef {{
 *  id: string?,
 *  name: string?,
 *  description: string?,
 *  version: number?,
 *  routes: RouteDataFromServer[],
 * }} ConstraintGroupDataFromServer
 */

/**
 * CG difference data
 * @typedef {{
 *  id: string?,
 *  name: string?,
 *  description: string?,
 *  version: number?,
 * }} ConstraintGroupDiff
 */

/**
 * Method to convert instance of constraint group to CG project structure
 * @param {ConstraintGroup} constrGroup
 * @returns {ConstraintGroupData}
 */
export const constraintGroupToCGData = (constrGroup) => {
    return {
        id: constrGroup.groupId,
        name: constrGroup.name(),
        description: constrGroup.description(),
        version: constrGroup.version(),
    };
};

/**
 * Method to convert CG data from server to CG project structure
 * @param {ConstraintGroupDataFromServer} dataFromServer
 * @returns {ConstraintGroupData}
 */
export const constraintGroupDataFromServerToCGData = (dataFromServer) => {
    return {
        id: dataFromServer.id,
        name: dataFromServer.name,
        description: dataFromServer.description,
        version: dataFromServer.version,
    };
};

/**
 * Method to convert CG data from server to CG project structure
 * @param {ConstraintGroupDataFromServer} dataFromServer
 * @returns {ConstraintGroupData}
 */
export const constraintGroupDataToCGDiff = (constrGroupData) => {
    return {
        id: constrGroupData.id,
        name: constrGroupData.name,
        description: constrGroupData.description,
        version: constrGroupData.version,
    };
};

import { DocumentNode, gql } from '@apollo/client';

export const DELETE_CONSTRAINT_GROUP: DocumentNode = gql`
    mutation deleteConstraintGroup($id: String!, $version: Int!) {
        deleteConstraintGroup(id: $id, version: $version) {
            id
            version
        }
    }
`;

import { makeVar } from '@apollo/client';
import { ConstraintGroup } from './ConstraintGroup';
import constraintGroupsAPI from '../api/ConstraintGroupsAPI';
import routesModerator from '../../Route/model/RoutesModerator';
import { currentToolVar } from '../../../shared/model/cache/Cache';
import {
    Constraints,
    Tools,
} from '../../../pages/Constructor/TopBar/TopBarEntires/Tools/Constants/Tools';

/**
 * @typedef {import('./ConstraintGroup').ConstraintGroup} ConstraintGroup
 * @typedef {import('./ConstraintGroup').ConstraintGroupData} ConstraintGroupData
 */

/**
 * @class
 * Class that stores and manages all constraint groups.
 * Use this class to delete or create new constraint group
 *
 * For modifying concrete constraint group -> get it instance
 * and modify using ConstraintGroup class methods
 */
class ConstraintGroupsModerator {
    constructor() {
        /**
         * UUID of default constraint group.
         * Default constraint group uses for creating new routes
         * @type {string | null}
         */
        this.defaultConstraintGroupId = null;

        /**
         * Var for storing all constraint groups.
         * Key - constraint group UUID.
         * Value - ConstraintGroup object
         * @type {Map<string, ConstraintGroup>}
         */
        this.constraintGroupsVar = makeVar(new Map());
    }

    /**
     * Method for getting constraint group by id
     * @param {string} groupId
     * @returns {ConstraintGroup | undefined}
     */
    getGroup(groupId) {
        return this.constraintGroupsVar().get(groupId);
    }

    /**
     * Method for adding constraint group to moderator.
     * Note: this method adds group only locally. To create new constraint group - use createGroup
     * @param {string} constraintGroupId Constraint group UUID
     * @param {ConstraintGroupData} [properties={}] Extra properties
     * @returns
     */
    addConstraintGroup(constraintGroupId, properties = {}) {
        const newConstraintGroup = new ConstraintGroup(
            constraintGroupId,
            properties
        );
        this.constraintGroupsVar(
            new Map(
                this.constraintGroupsVar().set(
                    constraintGroupId,
                    newConstraintGroup
                )
            )
        );
        return newConstraintGroup;
    }

    /**
     * Method for creating new route.
     * Send query to create route and then add it to manager
     * @param {string} name
     */
    async createGroup(name) {
        let newGroupData = await constraintGroupsAPI.createGroup(name);
        if (newGroupData) {
            this.addConstraintGroup(newGroupData.id, newGroupData); // add group to manager

            // set new group as default if there aren't default group
            if (this.defaultConstraintGroupId === null) {
                this.setDefaultGroup(newGroupData.id);
            }
        }
    }

    /**
     * Method to delete constraint group from manager.
     * Note: this method remove only from local storage. To fully delete use deleteConstrGr() method
     *
     * Also method removes all children routes.
     * @param {string} groupId
     */
    removeConstrGrFromManager(groupId) {
        const deleted = this.constraintGroupsVar().delete(groupId);
        if (deleted) {
            // remove all children routes
            routesModerator.removeRoutesByConstraintGroup(groupId);

            this.constraintGroupsVar(new Map(this.constraintGroupsVar()));

            if (this.defaultConstraintGroupId === groupId) {
                this.setDefaultGroup();
            }
        }
        this.cancelCreating();
    }

    /**
     * Method for deleting constraint group.
     * Send query to delete constraint group and then delete it from moderator
     * @param {string} groupId
     */
    async deleteConstrGr(groupId) {
        const group = this.getGroup(groupId);
        let id = await constraintGroupsAPI.deleteGroup(
            groupId,
            group.version()
        );
        if (id) {
            this.removeConstrGrFromManager(id);
        }
    }

    /**
     * Set default constraint group.
     * If groupId property not set - chooses automatically first available.
     * If group couldn't be set - clear default group 
     * @param {string} [groupId=] group UUID
     */
    setDefaultGroup(groupId) {
        if (!groupId) {
            // auto choose constraint group
            groupId = this.constraintGroupsVar().keys().next().value;
        }
        if (groupId && this.getGroup(groupId)) {
            this.defaultConstraintGroupId = groupId;
        } else {
            this.clearDefaultGroup();
        }
    }

    /** Method to clear default constraint group var */
    clearDefaultGroup() {
        this.defaultConstraintGroupId = null;
    }

    /** Method for stopping creating constraints (e.g. routes) */
    cancelCreating() {
        if (currentToolVar() === Constraints.route) {
            currentToolVar(Tools.none);
        }
    }

    /** Method for cleaning all storing data */
    clear() {
        this.defaultConstraintGroupId = null;
        this.constraintGroupsVar(new Map());
    }
}

const constraintGroupsModerator = new ConstraintGroupsModerator();
export default constraintGroupsModerator;

import { gql } from '@apollo/client';

export const CREATE_WALL_SEGMENTS = gql`
    mutation createWallSegments(
        $floorId: String!
        $segments: [WallSegmentDraft]!
        $points: [VirtualPoint]
        $cutOnIntersect: Boolean
    ) {
        createWallSegments(
            floorId: $floorId
            segments: $segments
            points: $points
            cutOnIntersect: $cutOnIntersect
            onFailureIgnoreSegmentCreation: true
        ) {
            createdSegments {
                name
                version
                endPoint {
                    coordinate {
                        x
                        y
                    }
                    id
                    accessRules {
                        group {
                            id
                        }
                        rule
                    }
                }
                id
                lengthRestriction
                startPoint {
                    coordinate {
                        x
                        y
                    }
                    id
                    accessRules {
                        group {
                            id
                        }
                        rule
                    }
                }
                width
                through
                secondZone {
                    id
                }
                firstZone {
                    id
                }
                schemaImpls {
                    data
                    id
                    version
                    schema {
                        id
                        schema
                        version
                    }
                }
                type {
                    id
                    length
                    width
                    scalable
                    through
                    virtual
                    name
                    description
                    imageOffsetCoefficient
                    image {
                        id
                        src
                        type
                    }
                    techImage {
                        id
                        src
                        type
                    }
                }
                accessRules {
                    group {
                        id
                    }
                    rule
                }
            }
            updatedSegments {
                name
                version
                endPoint {
                    coordinate {
                        x
                        y
                    }
                    id
                    accessRules {
                        group {
                            id
                        }
                        rule
                    }
                }
                id
                lengthRestriction
                startPoint {
                    coordinate {
                        x
                        y
                    }
                    id
                    accessRules {
                        group {
                            id
                        }
                        rule
                    }
                }
                width
                through
                secondZone {
                    id
                }
                firstZone {
                    id
                }
                schemaImpls {
                    data
                    id
                    version
                    schema {
                        id
                        schema
                        version
                    }
                }
                type {
                    id
                    length
                    width
                    scalable
                    through
                    virtual
                    name
                    description
                    imageOffsetCoefficient
                    image {
                        id
                        src
                        type
                    }
                    techImage {
                        id
                        src
                    }
                }
                accessRules {
                    group {
                        id
                    }
                    rule
                }
            }
            zones {
                id
                version
                shape {
                    outer {
                        id
                        coordinate {
                            x
                            y
                        }
                    }
                }
            }
        }
    }
`;

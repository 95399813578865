import { DragTools } from '../../../../pages/Constructor/TopBar/TopBarEntires/Tools/Constants/Tools';
import { VIEW_MODE } from '../../../../shared/lib/utils/ModeDefaultValues';
import cursorModerator from '../../../../shared/lib/utils/common/CursorModerator';
import { currentDragTypeVar, modeVar } from '../../../../shared/model/cache/Cache';
import {
    CANVAS_NAME,
    LEFT_MOUSE_BUTTON,
    WHEEL_MOUSE_BUTTON,
} from '../CanvasDefaultValues';

/**
 * Function to start drag on canvas
 * @param {object} e event
 * @param {boolean} isSpaceButton flag: is space button hold down
 * @returns 
 */
export const handleDragStart = (e, isSpaceButton) => {
    /** Is target - canvas */
    const isCanvas = e.target.name() === CANVAS_NAME;

    /**
     * Is cursor set as draggable.
     * Draggable cursor - hand.
     * Or you need to hold down the space button
     */
    const isCursorDraggable =
        currentDragTypeVar() === DragTools.hand ||
        (isSpaceButton && currentDragTypeVar() === DragTools.cursor);

    /** If true - need to drag. If false - need to prevent drag */
    const needToDrag =
        modeVar() === VIEW_MODE ||
        (isCursorDraggable && e.evt.button === LEFT_MOUSE_BUTTON && isCanvas) ||
        (e.evt.buttons === WHEEL_MOUSE_BUTTON && isCanvas) ||
        !isCanvas;

    // prevent drag
    if (!needToDrag) {
        e.target.stopDrag();
        return;
    }

    cursorModerator.setGrabbing();
};

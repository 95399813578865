import styled from 'styled-components';

/**
 * Styled password input div
 * Needed for Input and Eye Img
 */
const PasswordInput = styled.div`
    width: 322px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
    margin-bottom: 17px;
`;

/**
 * Styled form input
 */
const Input = styled.input`
    flex: 1;
    width: 322px;

    margin: 19px 0 17px;
    border: 0;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.7);
    padding: 4px 0;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;

    color: #000000;
    opacity: 0.7;

    &:focus {
        outline: none;
        border-bottom: 0.5px solid #023aff;
    }

    &:focus::placeholder {
        color: transparent;
    }
`;

/**
 * Styled form button
 */
const Button = styled.button`
    width: 322px;

    margin: 13px 0 8px;
    padding: 11px 0;

    background: #023aff;
    border-radius: 13px;
    border: 0;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;

    &:enabled:hover {
        cursor: pointer;
        background: #5076fd;
    }

    &:disabled {
        color: #000000;
        background: #d9d9d9;
    }
`;

/**
 * Styled eye img
 * Is needed to change the type of password
 */
const EyeImg = styled.img`
    width: 33px;
    height: 21px;
`;

/**
 * Styled timer text p
 */
const Timer = styled.p`
    margin: 0;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    text-decoration-line: underline;

    color: #d9d9d9;
`;

/**
 * Styled additional information p
 */
const Additional = styled.p`
    margin: 0 0 4px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    text-decoration-line: underline;

    cursor: pointer;
    display: none;

    color: #023aff;
`;

/**
 * Styled message body div
 */
const MessageBody = styled.div`
    margin: 95px 0 19px;

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 372px;
    height: 169px;

    background: #e1e8ff;
    border-radius: 15px;
`;

/**
 * Styled email img for message
 */
const EmailImg = styled.img`
    margin-top: 14px;

    width: 31px;
    height: 31px;
`;

/**
 * Styled message title h1
 */
const MessageTitle = styled.h1`
    margin: 11px 0 9px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;

    color: #000000;
`;

/**
 * Styled message text p
 */
const MessageText = styled.p`
    width: 293px;
    margin: 0px;

    word-wrap: break-word;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
`;

export {
    PasswordInput,
    Input,
    Button,
    EyeImg,
    Timer,
    Additional,
    MessageBody,
    EmailImg,
    MessageTitle,
    MessageText,
};

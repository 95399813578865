import scalesConformer from '../../ScalesConformer/model/ScalesConformer';
import { Point } from '../api/MapObjectsAPI';

export const findCenter = (
    leftUpperCornerPos: Point,
    width: number,
    height: number,
    angle: number = 0
) => {
    const W = scalesConformer.toPixels(width);
    const H = scalesConformer.toPixels(height);
    const theta = Math.PI * angle / 180;

    const centeredPos = {
        x: leftUpperCornerPos.x + (W / 2) * Math.cos(theta) - (H / 2) * Math.sin(theta),
        y: leftUpperCornerPos.y + (W / 2) * Math.sin(theta) + (H / 2) * Math.cos(theta)
    };
    return centeredPos;
};

export const findLeftUpperCorner = (
    centerPos: Point,
    width: number,
    height: number,
    angle: number = 0
) => {
    const W = scalesConformer.toPixels(width);
    const H = scalesConformer.toPixels(height);
    const theta = Math.PI * angle / 180;

    const leftUpperPos = {
        x: centerPos.x - (W / 2) * Math.cos(theta) + (H / 2) * Math.sin(theta),
        y: centerPos.y - (W / 2) * Math.sin(theta) - (H / 2) * Math.cos(theta)
    };
    return leftUpperPos;
};

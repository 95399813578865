import routesModerator from '../../../../../features/Route/model/RoutesModerator';
import floorGraph from '../../../../../features/Wall/model/FloorGraph';
import { Constraints, Tools } from '../../../../../pages/Constructor/TopBar/TopBarEntires/Tools/Constants/Tools';
import { currentToolVar } from '../../../../../shared/model/cache/Cache';

/**
 * Handler for keyboard space button pressed down
 */
export const handleSpaceDown = () => {
    const currentTool = currentToolVar();
    if (currentTool === Constraints.route) {
        // break connection to previous route vertex
        const route = routesModerator.getCurrentRoute();
        route.graph.tmpEdgeVar(new Map());
    } else if (currentTool === Tools.wall) {
        // break connection to previous wall vertex
        floorGraph.tmpEdgeVar(new Map());
    }
};

import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import arrowRightIcon from '../../../../../../shared/assets/images/icons/ConstructorPage/ProjectSettings/triangle-right.svg';
import arrowDownIcon from '../../../../../../shared/assets/images/icons/ConstructorPage/ProjectSettings/triangle-down.svg';
import groupIcon from '../../../../../../shared/assets/images/icons/ConstructorPage/ProjectSettings/group.svg';
import plusIcon from '../../../../../../shared/assets/images/icons/ConstructorPage/ProjectSettings/plus-blue.svg';
import dotsIcon from '../../../../../../shared/assets/images/icons/dots-vertical.svg';
import {
    POpenSansLight,
    POpenSansRegular,
} from '../../../../../../shared/ui/TextStyles';
import { Modal } from '../../../../../../features/ModalWindow/Modal';
import { AddUserModal } from './AddUserModal/AddUserModal';
import {
    OptionsImg,
    SecondaryButton,
} from '../../../../../../shared/ui/RolesSettingsComponents/Styles';
import { AccessGroup } from '../../../../../../features/AccessGroup/model/AccessGroup';
import { AddAccessGroupModal } from './AccessGroupModal/AddAccessGroupModal';
import {
    CM_DIRECTIONS,
    ContextMenu,
} from '../../../../../../features/ContextMenu/ui/styles/ContextMenu';
import accessGroupsModerator from '../../../../../../features/AccessGroup/model/AccessGroupsModerator';
import preloaderImg from '../../../../../../shared/assets/images/icons/preloader.svg';
import { EditAccessGroupModal } from './AccessGroupModal/EditAccessGroupModal';

const Wrapper = styled.div`
    ${(props) => (props.isRoot ? '' : 'padding-left: 20px;')}
`;

const GroupRow = styled.div`
    padding: 6px 2px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 8px;
`;

const Column = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`;

const ClickableColumn = styled(Column)`
    cursor: pointer;
    min-width: 200px;
    max-width: calc(100% - 20px);
`;

const GroupName = styled(POpenSansRegular)`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const CountNumber = styled(POpenSansLight)`
    background: #e1e8ff;
    padding: 4px 5px;
    border-radius: 12px;
`;

const ChildrenContainer = styled.div`
    display: flex;
    flex-direction: column;
    ${(props) =>
        props.isRoot
            ? ''
            : 'margin-left: 2px; border-left: solid 2px #d9d9d9cc;'}
`;

const AddButton = styled(SecondaryButton)`
    margin: 4px 0 0 10px;
    padding: 6.5px 10px;
    color: #023aff;
    background-color: #ffffff;
    font-weight: 600;
    width: fit-content;

    &:enabled:hover {
        background: #e1e8ff;
    }
`;

/**
 * Component represents one group of users for roles tab
 * @component
 */
export const Group = ({ group, isRoot = false, containerRef, children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
    const [isAddGroupModalOpen, setIsAddGroupModalOpen] = useState(false);
    const [isEditGroupModalOpen, setIsEditGroupModalOpen] = useState(false);

    const [isDeleting, setIsDeleting] = useState(false);

    const optionsRef = useRef(); // ref for options button (need to open context menu)
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const contextMenuPos = {
        x: optionsRef.current?.offsetLeft + 16,
        y: optionsRef.current?.offsetTop - containerRef.current?.scrollTop - 5,
    };

    const closeModals = () => {
        setIsAddUserModalOpen(false);
        setIsAddGroupModalOpen(false);
        setIsEditGroupModalOpen(false);
    };

    const contextMenu = [
        {
            text: 'Редактировать группу',
            onClick: () => {
                setIsEditGroupModalOpen(true);
                setIsMenuOpened(false);
            },
        },
        {
            text: 'Удалить',
            icon: isDeleting ? preloaderImg : null,
            onClick: async () => {
                setIsDeleting(true);
                await accessGroupsModerator.deleteGroup(group.groupId);
                setIsDeleting(false);
            },
        },
    ];

    return (
        <Wrapper isRoot={isRoot}>
            {!isRoot && (
                <GroupRow>
                    <ClickableColumn onClick={() => setIsOpen(!isOpen)}>
                        <img
                            src={isOpen ? arrowDownIcon : arrowRightIcon}
                            alt={isOpen ? '▼' : '►'}
                        />
                        <img src={groupIcon} alt='group' />
                        <GroupName>{group.name()}</GroupName>
                        <CountNumber>
                            {group.members().length >= 100
                                ? '99+'
                                : group.members().length}
                        </CountNumber>
                    </ClickableColumn>
                    <Column>
                        <OptionsImg
                            src={dotsIcon}
                            alt='⋮'
                            onClick={() => setIsMenuOpened(true)}
                            ref={optionsRef}
                        />
                        <ContextMenu
                            position={contextMenuPos}
                            isOpened={isMenuOpened}
                            menu={contextMenu}
                            onClose={() => setIsMenuOpened(false)}
                            directionX={CM_DIRECTIONS.RIGHT}
                        />
                    </Column>
                </GroupRow>
            )}
            {(isRoot || isOpen) && (
                <ChildrenContainer isRoot={isRoot}>
                    {children}
                    <AddButton onClick={() => setIsAddGroupModalOpen(true)}>
                        <img src={plusIcon} alt='+' />
                        Добавить группу
                    </AddButton>
                    {!isRoot && (
                        <AddButton onClick={() => setIsAddUserModalOpen(true)}>
                            <img src={plusIcon} alt='+' />
                            Добавить пользователя
                        </AddButton>
                    )}
                </ChildrenContainer>
            )}

            <Modal
                isOpened={isAddUserModalOpen}
                onClose={closeModals}
                isCloseIconShow={true}
                zIndex={1001}
            >
                <AddUserModal
                    groupName={group.name()}
                    groupId={group.groupId}
                    onClose={closeModals}
                />
            </Modal>

            <Modal
                isOpened={isAddGroupModalOpen}
                onClose={closeModals}
                isCloseIconShow={true}
                zIndex={1001}
            >
                <AddAccessGroupModal
                    parentGroup={group}
                    onClose={closeModals}
                />
            </Modal>

            <Modal
                isOpened={isEditGroupModalOpen}
                onClose={closeModals}
                isCloseIconShow={true}
                zIndex={1001}
            >
                <EditAccessGroupModal group={group} onClose={closeModals} />
            </Modal>
        </Wrapper>
    );
};

Group.propTypes = {
    /** group description */
    group: PropTypes.instanceOf(AccessGroup),

    /** For root component show only children groups */
    isRoot: PropTypes.bool,

    /** Reference to scroll container */
    containerRef: PropTypes.object,

    /** React children */
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
};

import { Point } from '../../../shared/typedefs/Types';

/** Available alerts types */
export enum AlertTypes {
    CRITICAL = 'CRITICAL',
    INFO = 'INFO',
    WARNING = 'WARNING',
}

/** Describes alert data structure, used in project */
export interface AlertData {
    id: string;
    type: AlertTypes;
    position: Point;
    text: string;
}

/** Describes alert data for current opened alert */
export interface CurrentAlertData extends AlertData {
    isEdit: boolean;
}

/** Alert data received from server */
export interface AlertDataFromServer {
    id: string;
    type: AlertTypes;
    position: Point;
    message: string;
}

/** Draft to create new alert */
export interface AlertDraft {
    type: AlertTypes;
    position: Point;
    message: string;
    floorId: string;
}

import { openZoneContextMenu } from '../../../../features/ContextMenu/model/OpenZoneContextMenu';
import { ZONE_MENU_EDIT } from '../../../../features/ContextMenu/ui/ContextMenus/ZoneContextMenu';
import notifications from '../../../../features/HintSystem/model/Notifications';
import selector from '../../../../features/Selector/model/Selector';
import temporaryZone from '../../../../features/Zone/model/TemporaryZone';
import zones from '../../../../features/Zone/model/Zones';
import rightBarModerator from '../../../../pages/Constructor/RightBar/model/RightBarModerator';
import { Tools } from '../../../../pages/Constructor/TopBar/TopBarEntires/Tools/Constants/Tools';
import { loadFloor } from '../../../../pages/Constructor/model/LoadFloor';
import {
    currentFloorVar,
    currentToolVar,
} from '../../../../shared/model/cache/Cache';

/**
 * Finish zone creation based on selected edges
 */
export const handleZoneCreation = () => {
    if (currentToolVar() === Tools.zone && zones.donorZoneVar()) {
        if (selector.selectedEdgesVar().size > 0) {
            const { createdZoneId, zoneCutProps } = zones.addZoneFromSelector();
            // open zone edit context menu, if zone creation was successful
            if (createdZoneId) {
                // context menu position wil be the same, as first border vertex
                const position = [
                    ...temporaryZone.tmpZonePointLinksVar(),
                ][0].getCoordinates();
                temporaryZone.finishCreating();

                zones
                    .saveCut(createdZoneId, zoneCutProps)
                    .then((res) => {
                        openZoneContextMenu(
                            res.newZone.id,
                            position,
                            ZONE_MENU_EDIT
                        );
                    })
                    .catch(async (error) => {
                        notifications.setError('Зона не создана', error);
                        currentToolVar(Tools.none);
                        await loadFloor(currentFloorVar().id);
                    });
            }
        }
    }
    zones.donorZoneVar(null);
    rightBarModerator.clearOperation();
};

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import arrowUpIcon from '../../../../../shared/assets/images/icons/ConstructorPage/ProjectSettings/triangle-gray-up.svg';
import arrowDownIcon from '../../../../../shared/assets/images/icons/ConstructorPage/ProjectSettings/triangle-gray-down.svg';
import { POpenSansLight } from '../../../../../shared/ui/TextStyles';

const Container = styled.div`
    display: flex;
    box-sizing: border-box;
    align-items: center;
`;

const Text = styled(POpenSansLight)`
    font-size: 15px;
    margin-right: 13px;
`;

const InputNumber = styled.input`
    margin-right: 6px;
    padding: 2px 4px 1px;
    width: 35px;

    border: solid #023aff 0.5px;
    border-radius: 4px;
    text-align: center;

    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

const Buttons = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;

    & > img {
        width: 12px;
        height: auto;
        cursor: pointer;
    }
`;

/**
 * Component represents accuracy input
 * @component
 */
export const AccuracyInput = ({
    name = 'accuracy',
    value,
    onChange,
}) => {
    /**
     * Change handler
     * @param {string|number} newValue
     */
    const handleChange = (newValue) => {
        newValue = Math.min(3, Math.max(1, newValue));

        // call onChange handler and emulate event object
        if (onChange) {
            onChange({
                target: {
                    name: name,
                    value: newValue,
                },
            });
        }
    };

    return (
        <Container>
            <Text>Точность карты</Text>
            <InputNumber
                value={1 / (10 ** value)}
                type='number'
                name={name}
                // onChange={onChange}
                readOnly
            />
            <Buttons>
                <img
                    src={arrowUpIcon}
                    alt='▲'
                    onClick={() => handleChange(value + 1)}
                />
                <img
                    src={arrowDownIcon}
                    alt='▼'
                    onClick={() => handleChange(value - 1)}
                />
            </Buttons>
        </Container>
    );
};

AccuracyInput.propTypes = {
    /** Name for input field */
    name: PropTypes.string,

    /** Input shown value */
    value: PropTypes.number,

    /** Handler for change event */
    onChange: PropTypes.func,
};

import { distanceFromPointToWall } from './DistanceFromPointToWall';
import { getDistanceBetweenPoints } from '../../../../shared/lib/modules/math/Math';
import FloorGraph from '../../model/FloorGraph';
import { currentCanvasPointVar } from '../../../../shared/model/cache/Cache';

export const TMP_WALL_ID = 'tmp_wall_id';

/**
 * Function to find Wall Corner point closest to current point
 *
 * @param {Number} distance radius of point in which func find closest
 * @param {FloorGraph} graph floor graph or its heir
 * @returns found point and wallId to which this point belongs
 */
export const getClosestWallPoint = (distance, graph = FloorGraph) => {
    if (!currentCanvasPointVar()) {
        return null;
    }

    let wallId = null;
    let attractionPoint = null;
    let minDistance = distance;
    let minDistanceToConcreteWall;

    graph.drawSegmentsVar().forEach((wallSegment) => {
        const key = wallSegment.getKey();
        const lineString = [...graph.getLineStringOfEdge(key)];

        attractionPoint = findAttractionPoint(
            lineString,
            currentCanvasPointVar(),
            minDistance,
            attractionPoint
        );

        if (!!attractionPoint) {
            minDistanceToConcreteWall = getDistanceBetweenPoints(
                attractionPoint,
                currentCanvasPointVar()
            );
        }

        if (minDistanceToConcreteWall < minDistance) {
            minDistance = minDistanceToConcreteWall;
            wallId = key;
        }
    });

    return { wallId: wallId, point: attractionPoint };
};

/**
 * Function to find the closest point to the wall
 *
 * @returns attraction point
 */
export const findAttractionPoint = (
    lineString,
    currentPosition,
    minDistance,
    attractionPoint = null
) => {
    let result;

    for (let i = 0; i < lineString.length - 1; i++) {
        result = distanceFromPointToWall(
            currentPosition,
            lineString[i],
            lineString[i + 1]
        );

        if (result.distance < minDistance) {
            minDistance = result.distance;
            attractionPoint = { ...result.perpendicularPoint };
        }
    }

    return attractionPoint;
};

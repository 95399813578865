import React from 'react';
import styled from 'styled-components';
import { Preloader } from './Preloader';
import { POpenSansRegular } from './TextStyles';

interface PreloaderWithTextWrapperProps {
    /** Container should occupy the entire space */
    fullSpace: boolean;
}

const Wrapper = styled.div<PreloaderWithTextWrapperProps>`
    ${(props) =>
        props.fullSpace
            ? `
                width: 100%;    
                justify-content: center;
            `
            : ``}
    display: flex;
    gap: 10px;
    align-items: center;
`;

const SecondaryText = styled(POpenSansRegular)`
    font-size: 14px;
    color: #696969;
    margin-right: 10px;
`;

interface PreloaderWithTextProps {
    /**
     * Preloader img width and height.
     * If value <= 0 then height=100%.
     * Default value is -1.
     */
    loaderSize?: number;

    /** Text to show near loading img */
    text?: string;

    /** Preloader should occupy the entire space */
    fullSpace?: boolean;

    /** Styles for text */
    textStyle?: React.CSSProperties;
}

/**
 * Component with loading img and text.
 * @component
 */
export const PreloaderWithText = ({
    loaderSize,
    text = '',
    fullSpace = true,
    textStyle = {},
}: PreloaderWithTextProps) => {
    return (
        <Wrapper fullSpace={fullSpace}>
            <Preloader loaderSize={loaderSize} />
            <SecondaryText style={textStyle}>{text}</SecondaryText>
        </Wrapper>
    );
};

import { makeVar } from '@apollo/client';
import {
    findCosAngleBetweenVectors,
    subtractTwoPoints,
} from '../../../../shared/lib/modules/math/Math';

/**
 * Approximately 22.5 degrees
 */
const DEFAULT_SNAP_COS_ANGLE = Math.cos((22.5 * Math.PI) / 180);

class GridSnapper {
    constructor() {
        this.SNAP_COS_ANGLE = DEFAULT_SNAP_COS_ANGLE;
        this.isSnapToHorizontalLineVar = makeVar(false);
        this.isSnapToVerticalLineVar = makeVar(false);
    }

    /**
     * Method to snap wall to vertical/horizontal lines.
     *
     * @param {Point} startPoint {x: number, y: number}
     * @param {Point} currentPoint {x: number, y: number}
     * @return {Point} snapped point or current
     */
    snapToVerticalHorizontalLines(startPoint, currentPoint) {
        /**
         * vector from fixed point to current
         */
        const vector = subtractTwoPoints(startPoint, currentPoint);
        const vector1 = {
            x: 0,
            y: 1,
        };
        const vector2 = {
            x: 1,
            y: 0,
        };

        const cosAngleWithY = Math.abs(
            findCosAngleBetweenVectors(vector, vector1)
        );
        const cosAngleWithX = Math.abs(
            findCosAngleBetweenVectors(vector, vector2)
        );

        if (cosAngleWithY >= cosAngleWithX) {
            this.isSnapToVerticalLineVar(true);
            this.isSnapToHorizontalLineVar(false);

            return {
                x: startPoint.x,
                y: currentPoint.y,
            };
        } else {
            this.isSnapToHorizontalLineVar(true);
            this.isSnapToVerticalLineVar(false);

            return {
                x: currentPoint.x,
                y: startPoint.y,
            };
        }
    }

    /**
     * Function to snap wall to vertical/horizontal lines.
     *
     * @param {Point} point1 {x: number, y: number}
     * @param {Point} point2 {x: number, y: number}
     * @return {Point} snapped point or current
     */
    snapToGrid(
        point1,
        point2,
        onlyVerticalHorizontal = false,
        snapCosAngle = this.SNAP_COS_ANGLE
    ) {
        /**
         * vector from fixed point to current
         */
        const vector = subtractTwoPoints(point1, point2);

        if (this.isSnapToVerticalLine(vector, snapCosAngle)) {
            return { x: point1.x, y: point2.y };
        }

        if (
            this.isSnapToHorizontalLine(vector, snapCosAngle) &&
            !onlyVerticalHorizontal
        ) {
            return { x: point2.x, y: point1.y };
        }

        if (this.isSnapToDiagonalLine(vector, snapCosAngle)) {
            const rate = (Math.abs(vector.x) + Math.abs(vector.y)) / 2;
            return {
                x: point1.x + rate * Math.sign(vector.x),
                y: point1.y + rate * Math.sign(vector.y),
            };
        }

        return point2;
    }

    /**
     * Functions check for snapping/not snapping to vertical line
     *
     * @param {Point} vector {x: number, y: number}
     * @return {Boolean} Boolean value if it should be snapped
     */
    isSnapToVerticalLine(vector, snapCosAngle) {
        /**
         * vector parallel to vertical lines
         */
        const vector2 = {
            x: 0,
            y: 1,
        };

        return (
            Math.abs(findCosAngleBetweenVectors(vector, vector2)) >=
            snapCosAngle
        );
    }

    /**
     * Functions check for snapping/not snapping to diagonal lines
     *
     * @param {Point} vector {x: number, y: number}
     * @return {Boolean} Boolean value if it should be snapped
     */
    isSnapToDiagonalLine(vector, snapCosAngle) {
        /**
         * vectors parallel to diagonal lines
         */
        const firstDiagonal = {
            x: 1,
            y: 1,
        };
        const secondDiagonal = {
            x: 1,
            y: -1,
        };

        return (
            Math.abs(findCosAngleBetweenVectors(vector, firstDiagonal)) >=
                snapCosAngle ||
            Math.abs(findCosAngleBetweenVectors(vector, secondDiagonal)) >=
                snapCosAngle
        );
    }

    /**
     * Functions check for snapping/not snapping to horizontal line
     *
     * @param {Point} vector {x: number, y: number}
     * @return {Boolean} Boolean value if it should be snapped
     */
    isSnapToHorizontalLine(vector, snapCosAngle) {
        /**
         * vector parallel to horizontal lines
         */
        const vector2 = {
            x: 1,
            y: 0,
        };

        return (
            Math.abs(findCosAngleBetweenVectors(vector, vector2)) >=
            snapCosAngle
        );
    }
}

const gridSnapper = new GridSnapper();
export default gridSnapper;

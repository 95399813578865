import styled from 'styled-components';

export const ListWrapper = styled.div`
    background-color: white;
    padding: 0 20px 0 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const ListNItemsContainer = styled.div`
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    user-select: none;
`;

export const ChildrenContainer = styled.ul`
    padding-left: 33px;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
    FormFieldTypes,
    SchemaFieldAggregateTypes,
    StringFormats,
} from '../lib/SchemaSpecifier';
import { Input } from '../../../shared/ui/FormComponentsSmall/Input';
import { ComboBox } from '../../../shared/ui/FormComponentsSmall/ComboBox';

/**
 * @typedef {import('../lib/SchemaSpecifier').SchemaFormField} SchemaFormField
 */

/**
 * Component that returns input (or another object) for user interaction
 * @component
 * @param {{
 *  field: SchemaFormField
 * }}
 */
export const PropertyInEditMode = ({ field, context }) => {
    const { onChange } = useContext(context);

    const handleChange = (e) => {
        if (
            field.stringFormat === StringFormats.date ||
            field.stringFormat === StringFormats.time
        ) {
            return onChange(field.key, e.target.value);
        }

        const formatted = field.format(e.target.value);
        if (field.changeValidate(formatted)) {
            onChange(field.key, e.target.value);
        }
    };

    const handleSelect = (value) => {
        const formatted = field.format(value);
        if (field.changeValidate(formatted)) {
            onChange(field.key, value);
        }
    };

    switch (field.type) {
        case FormFieldTypes.input: {
            if (
                field.stringFormat === StringFormats.date ||
                field.stringFormat === StringFormats.time
            ) {
                return (
                    <Input
                        type={field.stringFormat}
                        value={field.currentValue}
                        onChange={handleChange}
                    />
                );
            }
            return <Input value={field.currentValue} onChange={handleChange} />;
        }

        case FormFieldTypes.select: {
            if (field.valueType === SchemaFieldAggregateTypes.enum) {
                return (
                    <ComboBox
                        items={field.enumSet}
                        selectedElem={field.currentValue.value}
                        handleSelect={handleSelect}
                    />
                );
            }
            break;
        }

        // case 'String': {
        //     // if validator have property format
        //     if (
        //         propertyDescription.validator &&
        //         propertyDescription.validator.format
        //     ) {
        //         if (propertyDescription.validator.format === 'time') {
        //             return (
        //                 <InputTime
        //                     name={propertyName}
        //                     value={value}
        //                     onChange={handleInputChange}
        //                 />
        //             );
        //         } else if (propertyDescription.validator.format === 'date') {
        //             // convert 'dd.mm.yyyy' to 'yyyy-mm-dd'
        //             let correctDate = value.split('.');
        //             correctDate =
        //                 correctDate[2] +
        //                 '-' +
        //                 correctDate[1] +
        //                 '-' +
        //                 correctDate[0];

        //             return (
        //                 <InputDate
        //                     name={propertyName}
        //                     value={correctDate}
        //                     onChange={handleInputChange}
        //                 />
        //             );
        //         }
        //     }

        //     return (
        //         <InputString
        //             name={propertyName}
        //             value={value}
        //             limit={
        //                 propertyDescription.validator &&
        //                 propertyDescription.validator.limit
        //             }
        //             onChange={handleInputChange}
        //             required
        //         />
        //     );
        // }

        default: {
            return <>Unsupported field</>;
        }
    }
};

PropertyInEditMode.propTypes = {
    /** part of schema with property description */
    propertyDescription: PropTypes.shape({
        __metainf__: PropTypes.object,

        /** validators for property (depends on type) */
        validator: PropTypes.shape({
            limit: PropTypes.number, // only for strings
            formal: PropTypes.string, // only for strings

            from: PropTypes.number, // only for numbers
            to: PropTypes.number, // only for numbers
            precision: PropTypes.number, // only for numbers
            step: PropTypes.number, // only for numbers
        }),

        /** property type */
        type: PropTypes.string,

        /** is property required */
        required: PropTypes.bool,

        /** default value for property */
        default: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
};

export const SCALE_RATIO = 1.1;
export const DEFAULT_SCALE = 1;
export const MAX_SCALE = 10.0;
export const MIN_SCALE = 0.1;
export const DEFAULT_POSITION = {
    x: 0,
    y: 0,
};
export const INITIAL_STATE = {
    scale: DEFAULT_SCALE,
    position: DEFAULT_POSITION,
    isGridOn: true,
    currentPoint: {
        x: 0,
        y: 0,
    },
    lineString: [],
    zonePointsObject: {},
    uniqueIdArray: [],
};
export const FIND_POINT_RADIUS = 40;
export const MIN_SQUARE_DIAG_LENGTH = 60;
export const CLOSEST_WALL_CIRCLE_FILL = '#5076FD80';
export const CLOSEST_WALL_CIRCLE_OPACITY = 0.5;

export const ESC_KEY = 'Escape';
export const ENTER_KEY = 'Enter';
export const SPACE_KEY = 'Space';
export const LEFT_MOUSE_BUTTON = 0;
export const WHEEL_MOUSE_BUTTON = 4;
export const CANVAS_NAME = 'canvas'
/* Route constants */
export const ROUTE_EDGE_NAME: string = 'route_edge';
export const ROUTE_VERTEX_NAME: string = 'route_vertex';
export const ROUTE_DEFAULT_COLOR: string = '#37DD01';
export const ROUTE_SELECTED_COLOR: string = '#5076FD';
export const ROUTE_UNAVAILABLE_COLOR: string = '#7D7D7D'; // color if user have only VIEW access rule
export const ROUTE_DEFAULT_OPACITY: number = 1;

/* Route points constants */
export const ROUTE_CIRCLE_FILL: string = 'white';
export const ROUTE_CIRCLE_COLOR: string = 'black';

/* Route line constants */
export const ROUTE_LINE_CAP: string = 'round';
export const ROUTE_LINE_JOIN: string = 'butt';
export const ROUTE_LINE_WIDTH: number = 6;

/* Route temporary constants */
export const ROUTE_TEMPORARY_COLOR: string = 'gray';

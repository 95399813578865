import { DocumentNode, gql } from '@apollo/client';
import { CONSTRAINT_GROUP_FRAGMENT } from '../../fragments/constraintGroups/ConstraintGroupFragment';

export const UPDATE_CONSTRAINT_GROUP: DocumentNode = gql`
    ${CONSTRAINT_GROUP_FRAGMENT}
    mutation updateConstraintGroup($constraintGroupDiff: ConstraintGroupDiff!) {
        updateConstraintGroup(constraintGroupDiff: $constraintGroupDiff) {
            ...ConstraintGroupFragment
        }
    }
`;

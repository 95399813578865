import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    H3OpenSansRegular,
    POpenSansRegular,
} from '../../../../../../../shared/ui/TextStyles';
import {
    Button,
    SecondaryButton,
} from '../../../../../../../shared/ui/RolesSettingsComponents/Styles';
import { Input } from '../../../../../../../shared/ui/FormComponentsSmall/Input';
import { Preloader } from '../../../../../../../shared/ui/Preloader';
import { AccessGroup } from '../../../../../../../features/AccessGroup/model/AccessGroup';
import { CheckboxGroup } from './CheckboxGroup';

/**
 * @typedef {import('../../../../../../../features/AccessGroup/api/AccessGroupsAPI').AccessGroupDraft} AccessGroupDraft
 */

const Wrapper = styled.div`
    padding: 5px 20px 10px;
    width: min(536px, calc(100vw - 30px));
    height: fit-content;
    max-height: min(700px, calc(100vh - 80px));

    display: flex;
    flex-direction: column;

    overflow: hidden;

    position: relative;
`;

const WindowTitle = styled(H3OpenSansRegular)`
    font-weight: 600;
    align-self: center;
    margin-bottom: 15px;
`;

const InputsWrapper = styled.div`
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const InputWithLabel = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const Footer = styled.div`
    margin: 0;
    padding: 15px 0 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
`;

const ParentGroup = styled(POpenSansRegular)`
    margin-left: 19px;
    color: #696969;
`;

const ErrorMsg = styled(POpenSansRegular)`
    color: red;
`;

/**
 * Component represents UI for search and adding new user
 * @component
 */
export const AccessGroupModal = ({
    formInitial,
    parentGroup,
    title,
    submitButtonText,
    isLoading,
    isError,
    onClose,
    onSubmit,
}) => {
    /**
     * @type {[AccessGroupDraft, function]}
     */
    const [form, setForm] = useState({ ...formInitial });

    /**
     * Handler for form change
     * @param {React.ChangeEvent} e
     */
    const handleChange = (e) => {
        const value =
            e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setForm({
            ...form,
            [e.target.name]: value,
        });
    };

    /**
     * Handler for parent mirror checkbox
     * @param {React.ChangeEvent} e
     */
    const handleParentMirrorChange = (e) => {
        if (e.target.checked) {
            setForm({
                ...form,
                parentMirror: true,
                floorCreationAvailable: parentGroup.floorCreationAvailable,
                notificationCreationAvailable:
                    parentGroup.notificationCreationAvailable,
            });
        } else {
            handleChange(e);
        }
    };

    return (
        <Wrapper>
            <WindowTitle>{title}</WindowTitle>
            <InputsWrapper>
                <InputWithLabel>
                    <POpenSansRegular>Название: </POpenSansRegular>
                    <Input
                        name='name'
                        value={form.name}
                        autoFocus
                        onChange={handleChange}
                    />
                </InputWithLabel>
                <InputWithLabel>
                    <POpenSansRegular>Описание: </POpenSansRegular>
                    <Input
                        name='description'
                        value={form.description}
                        onChange={handleChange}
                    />
                </InputWithLabel>
                <CheckboxGroup
                    name='parentMirror'
                    checked={form.parentMirror}
                    onChange={handleParentMirrorChange}
                    text='Копировать права родительской группы'
                />
                <ParentGroup>
                    Родительская группа: {parentGroup.name()}
                </ParentGroup>
                <CheckboxGroup
                    name='floorCreationAvailable'
                    checked={form.floorCreationAvailable}
                    onChange={handleChange}
                    text='Право создавать этажи'
                    disabled={
                        !parentGroup.floorCreationAvailable || form.parentMirror
                    }
                    labelTitle={
                        !parentGroup.floorCreationAvailable
                            ? 'У вас нет на это прав'
                            : undefined
                    }
                />
                <CheckboxGroup
                    name='notificationCreationAvailable'
                    checked={form.notificationCreationAvailable}
                    onChange={handleChange}
                    text='Право создавать локальные оповещения'
                    disabled={
                        !parentGroup.notificationCreationAvailable ||
                        form.parentMirror
                    }
                    labelTitle={
                        !parentGroup.notificationCreationAvailable
                            ? 'У вас нет на это прав'
                            : undefined
                    }
                />
                {isError && <ErrorMsg>Возникла ошибка при создании</ErrorMsg>}
            </InputsWrapper>
            <Footer>
                {isLoading && <Preloader loaderSize={32} />}
                <SecondaryButton onClick={onClose}>Отмена</SecondaryButton>
                <Button
                    onClick={() => onSubmit(form)}
                    disabled={form.name === ''}
                >
                    {submitButtonText}
                </Button>
            </Footer>
        </Wrapper>
    );
};

AccessGroupModal.propTypes = {
    /** Access group */
    parentGroup: PropTypes.instanceOf(AccessGroup),

    /** Modal close callback */
    onClose: PropTypes.func,
};

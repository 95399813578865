import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { POpenSansLight } from '../../../shared/ui/TextStyles';

const Wrapper = styled.div`
    display: flex;
    gap: 5px;
`;

const PStyled = styled(POpenSansLight)`
    font-size: 13px;
    opacity: 80%;
`;

/**
 * Component represents one group of users
 * @component
 */
export const Breadcrumbs = ({ pathArr }) => {
    return (
        <Wrapper>
            {pathArr.map((elem, i) => {
                return (
                    <Fragment key={elem}>
                        <PStyled>{elem}</PStyled>
                        {i !== pathArr.length && <PStyled>&gt;</PStyled>}
                    </Fragment>
                );
            })}
        </Wrapper>
    );
};

Breadcrumbs.propTypes = {
    /** Path to show */
    pathArr: PropTypes.arrayOf(PropTypes.string),
};

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import circleIcon from '../../../../../../../shared/assets/images/icons/circle-in-settings.svg';
import {
    POpenSansLight,
    POpenSansRegular,
} from '../../../../../../../shared/ui/TextStyles';

const Container = styled.div`
    width: calc(100% - 20px);
    display: flex;
    gap: 10px;
    align-items: center;
    box-sizing: border-box;
`;

const Photo = styled.img`
    width: 26px;
    height: auto;
`;

const TitleAndDesc = styled.div`
    overflow: hidden;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

const Title = styled(POpenSansRegular)`
    font-size: 14px;
    line-height: 19.07px;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const Description = styled(POpenSansLight)`
    font-size: 11px;
    line-height: 10.8px;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

/**
 * Component with short user info card
 * @component
 */
export const PersonCard = ({ title, description }) => {
    return (
        <Container>
            <Photo src={circleIcon} alt='card photo' />
            <TitleAndDesc>
                <Title>{title}</Title>
                <Description>{description}</Description>
            </TitleAndDesc>
        </Container>
    );
};

PersonCard.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
};

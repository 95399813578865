import React, { useRef, useState } from 'react';

import circle from '../../../shared/assets/images/icons/circle.svg';
import accountSettings from '../../../shared/assets/images/icons/MainPage/account-settings.svg';
import logout from '../../../shared/assets/images/icons/logout.svg';

import { useOutsideClick } from '../../../shared/model/hooks/UseOutsideClick';

import {
    loginPage,
    userSettingsPage,
} from '../../../shared/model/routes/staticRoutes';

import {
    UserInfoIcon,
    Info,
    UserAvatar,
    UserInfoAvatar,
    UserInfoContainer,
    UserInfoBlock,
    UserInfoMenu,
    LinkToSettingsPage,
} from './Styles';
import {
    POpenSansLight,
    POpenSansRegular,
} from '../../../shared/ui/TextStyles';
import { useNavigate } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { currentUserVar } from '../../../shared/model/cache/Cache';

/**
 * Component with user logo, name and email
 * @returns { UserInfo } Component with user info
 */
export const UserInfo = () => {
    const [isUserInfoVisible, setIsUserInfoVisible] = useState(false);
    const user = useReactiveVar(currentUserVar);

    const useInfoDivRef = useRef();
    const navigate = useNavigate();
    const handleClick = () => {
        setIsUserInfoVisible(!isUserInfoVisible);
    };

    useOutsideClick(useInfoDivRef, handleClick, isUserInfoVisible);

    return (
        <>
            <UserInfoContainer onClick={handleClick}>
                <UserAvatar src={user.icon || circle} alt='user' />
                <Info>
                    <POpenSansRegular>{user.login}</POpenSansRegular>
                    <POpenSansRegular style={{ opacity: '0.7' }}>
                        {user.email}
                    </POpenSansRegular>
                </Info>
            </UserInfoContainer>

            <UserInfoBlock
                ref={useInfoDivRef}
                style={
                    isUserInfoVisible
                        ? { display: 'block' }
                        : { display: 'none' }
                }
            >
                <UserInfoAvatar
                    src={user.icon || circle}
                    alt='circle user pict'
                />
                <POpenSansRegular style={{ textAlign: 'center' }}>
                    {user.login}
                </POpenSansRegular>
                <POpenSansLight style={{ textAlign: 'center' }}>
                    {user.email}
                </POpenSansLight>
                <UserInfoMenu style={{ marginTop: '41px' }}>
                    <UserInfoIcon
                        src={accountSettings}
                        alt='account settings pict'
                    />
                    <LinkToSettingsPage to={userSettingsPage}>
                        Настройки аккаунта
                    </LinkToSettingsPage>
                </UserInfoMenu>
                <UserInfoMenu style={{ marginTop: '11px' }}>
                    <UserInfoIcon src={logout} alt='logout pict' />
                    <POpenSansRegular
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            localStorage.clear();
                            currentUserVar({});
                            navigate(loginPage);
                        }}
                    >
                        Выход
                    </POpenSansRegular>
                </UserInfoMenu>
            </UserInfoBlock>
        </>
    );
};

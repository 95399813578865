/*
 * File to store names for vars in local storage
 */

/*
 * Constants for tokens
 * Used for storing in local storage
 */
export const REFRESH_TOKEN: string = 'RefreshToken';
export const SESSION_TOKEN: string = 'SessionToken';

/** Is disclaimer for dev page shown */
export const IS_DISCLAIMER_SHOWN = 'isDisclaimerShown';

/** Default drag tool */
export const DEFAULT_DRAG_TOOL: string = 'defaultDragTool';
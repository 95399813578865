import { AlertTypes } from '../../model/Types';

/**
 * Function to convert alert type to color
 * @param type alert type
 * @returns alert color
 */
export const alertTypeToColor = (type: AlertTypes): string => {
    switch (type) {
        case AlertTypes.CRITICAL:
            return '#ffecec';
        case AlertTypes.WARNING:
            return '#fcffd5';
        case AlertTypes.INFO:
        default:
            return '#ffffff';
    }
};

import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Circle, Line } from 'react-konva';
import {
    WALL_CIRCLE_RADIUS,
    WALL_CIRCLE_STROKE_WIDTH,
} from '../../Wall/lib/helpers/WallDefaultValues';
import {
    ROUTE_CIRCLE_COLOR,
    ROUTE_SELECTED_COLOR,
    ROUTE_VERTEX_NAME,
} from '../lib/RouteDefaultValues';
import {
    handlePointClick,
    handlePointDragEnd,
    handlePointDragStart,
    handlePointEnter,
    handlePointLeave,
    handlePointMove,
} from '../../Wall/lib/utils/WallPointHandlers';
import { RouteGraph } from '../model/RouteGraph';
import { RULE_EDIT } from '../../AccessGroup/model/AGConstants';
import { stageVar } from '../../../shared/model/cache/Cache';
import { gridSnapper } from '../../Wall/lib/helpers';
import { useKeyDown } from '../../../shared/model/hooks/UseKeyDown';
import { useKeyUp } from '../../../shared/model/hooks/useKeyUp';

/** Grid stroke color */
const stroke = '#fda21a';

/**
 * Component that represents concrete vertex in canvas (route point)
 * @component
 */
export const Vertex = memo(({ id, visible, isSelected = false, x, y, graph, fill, accessRule }) => {
    const [isShift, setIsShift] = useState(false);
    const [isDrag, setIsDrag] = useState(false);

    const handleSetDragStart = () => {
        setIsDrag(true);
    };

    const handleSetDragEnd = () => {
        setIsDrag(false);
    };

    const handleShiftDown = () => {
        if (isShift) {
            return;
        }
        setIsShift(true);
    };
    const handleShiftUp = () => {
        setIsShift(false);
        gridSnapper.isSnapToHorizontalLineVar(false);
        gridSnapper.isSnapToVerticalLineVar(false);
    };

    useKeyDown(handleShiftDown, ['ShiftLeft']);
    useKeyUp(handleShiftUp, ['ShiftLeft']);

    function handleDragBound(pos) {
        if (gridSnapper.isSnapToVerticalLineVar()) {
            return {
                x: this.absolutePosition().x,
                y: pos.y,
            };
        } else if (gridSnapper.isSnapToHorizontalLineVar()) {
            return {
                x: pos.x,
                y: this.absolutePosition().y,
            };
        }
        return {
            x: pos.x,
            y: pos.y,
        };
    }

    const formAuxiliaryLines = (horizontal = true) => {
        let firstPoint;
        let secondPoint;

        const width = stageVar().width() / stageVar().scale().x;
        const height = stageVar().height() / stageVar().scale().y;

        if (!graph.dragStartPos) {
            return [];
        }

        if (horizontal) {
            firstPoint = {
                x: graph.dragStartPos.x - width,
                y: graph.dragStartPos.y,
            };
            secondPoint = {
                x: graph.dragStartPos.x + width,
                y: graph.dragStartPos.y,
            };
        } else {
            firstPoint = {
                x: graph.dragStartPos.x,
                y: graph.dragStartPos.y - height,
            };
            secondPoint = {
                x: graph.dragStartPos.x,
                y: graph.dragStartPos.y + height,
            };
        }

        return [...Object.values(firstPoint), ...Object.values(secondPoint)];
    };

    return (
        <>
            {isShift && isDrag && (
                <>
                    <Line
                        stroke={stroke}
                        strokeWidth={1}
                        points={formAuxiliaryLines(false)}
                    />
                    <Line
                        stroke={stroke}
                        strokeWidth={1}
                        points={formAuxiliaryLines(true)}
                    />
                </>
            )}

            <Circle
                id={id}
                x={x}
                y={y}
                name={ROUTE_VERTEX_NAME}
                radius={WALL_CIRCLE_RADIUS}
                fill={fill}
                pointFill={fill} // for handlePointLeave
                stroke={
                    isSelected
                        ? ROUTE_SELECTED_COLOR
                        : ROUTE_CIRCLE_COLOR
                }
                strokeWidth={WALL_CIRCLE_STROKE_WIDTH}
                visible={visible}
                draggable={accessRule === RULE_EDIT}
                onMouseEnter={handlePointEnter}
                onMouseLeave={handlePointLeave}
                onDragStart={(e) =>
                    handlePointDragStart(e, graph, handleSetDragStart)
                }
                onDragEnd={(e) =>
                    handlePointDragEnd(e, graph, handleSetDragEnd)
                }
                onDragMove={(e) => handlePointMove(e, graph, isShift)}
                onMouseDown={(e) => {
                    graph.setDragStartPos(e.target.position());
                }}
                onClick={(e) => handlePointClick(e, id, graph)}
                dragBoundFunc={handleDragBound}
                shadowForStrokeEnabled={false}
                perfectDrawEnabled={false}
                transformsEnabled={'position'}
                // onWheel={handleScrollSelect}
                accessRule={accessRule}
            />
        </>
    );
});

Vertex.propTypes = {
    /** Vertex UUID */
    id: PropTypes.string,

    /** Is vertex visible */
    visible: PropTypes.bool,

    /** Is vertex selected */
    isSelected: PropTypes.bool,

    /** x coordinate */
    x: PropTypes.number,

    /** y coordinate */
    y: PropTypes.number,

    /** Route graph */
    graph: PropTypes.instanceOf(RouteGraph),

    /** Vertex point fill color */
    fill: PropTypes.string,

    /** Route access rule for current user */
    accessRule: PropTypes.string,
};

/** Describe available operations for right bar */
export enum RightBarOperation {
    CHANGE_SEGMENT_TYPE = 'Смена типа сегмента',
    SEGMENT_PROPERTIES = 'Свойства сегмента',
    ZONE_CREATION = 'Настройка зон',
    TELEPORT_SETTINGS = 'Перемещения',
}

/** Tabs for default right bar */
export enum DefaultRBTabs {
    WALL_SEGMENTS = 'Сегменты',
    MAP_OBJECTS = 'Объекты'
}

/** Array with tab names for default right bar */
export const DefaultRBTabsName: DefaultRBTabs[] = Object.values(DefaultRBTabs);

import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { H1OpenSansBold } from '../../../../shared/ui/TextStyles';
import { currentProjectVar } from '../../../../shared/model/cache/Cache';
import { UPDATE_PROJECT } from '../../../../shared/graphql/mutations/projects/UpdateProject';

/**
 * Styled body for modal content
 */
const ModalBody = styled.div`
    width: 667px;
    height: 544px;

    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 27px 70px 40px 84px;
`;

const Label = styled.label`
    font-weight: 300;
    font-size: 15px;
    line-height: 20px;
`;

const Input = styled.input`
    flex: 1;
    min-width: 243px;

    border: 0.5px solid #000000;
    border-radius: 4px;
    padding: 4px;
    margin-top: 16px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;

    color: #000000;

    &:focus {
        outline: none;
        border-color: #023aff;
    }
`;

const TextArea = styled.textarea`
    min-width: 513px;
    height: 203px;

    border: 0.5px solid #000000;
    border-radius: 4px;
    padding: 4px;
    margin-top: 16px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;

    color: #000000;
    resize: none;
    &:focus {
        outline: none;
        border-color: #023aff;
    }
`;

const Button = styled.button`
    min-width: 127px;

    margin: 34px auto 0;
    padding: 6.5px 26.5px;

    background: #023aff;
    border-radius: 9px;
    border: 0;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;

    &:enabled:hover {
        cursor: pointer;
        background: #5076fd;
    }

    &:disabled {
        background: #d9d9d9;
        color: #000000;
    }
`;

/**
 * Component form for changing info of project
 * @returns { ProjectInfoModal } Modal component with ability to change name and description of project
 */
export const ProjectInfoModal = ({onClose = () => {}}) => {
    const [updateProject] = useMutation(UPDATE_PROJECT);
    const [form, setForm] = useState({
        name: currentProjectVar().name,
        description: currentProjectVar().description,
        // TODO: add after api changes
        // lastModified: new Date().toISOString().slice(0, -1),
    });

    const changeHandle = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const saveHandle = (e) => {
        e.preventDefault();
        updateProject({
            variables: {
                projectDiff: {
                    id: currentProjectVar().id,
                    ...form,
                },
            },
        })
            .then((response) => {
                if (response.data) {
                    currentProjectVar({
                        id: response.data.updateProject.id,
                        name: response.data.updateProject.name,
                        ownerId: response.data.updateProject.owner.id,
                        description: response.data.updateProject.description,
                        accuracy: response.data.updateProject.accuracy,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => onClose());
    };

    return (
        <ModalBody>
            <H1OpenSansBold style={{ marginTop: '45px' }}>
                Настройка карты
            </H1OpenSansBold>

            <Form onSubmit={saveHandle}>
                <Label htmlFor='name'>Название</Label>
                <Input
                    onKeyDown={(e) => e.stopPropagation()}
                    onChange={changeHandle}
                    name='name'
                    id='name'
                    value={form.name}
                />

                <Label style={{ marginTop: '22px' }} htmlFor='description'>
                    Описание
                </Label>
                <TextArea
                    onKeyDown={(e) => e.stopPropagation()}
                    onChange={changeHandle}
                    name='description'
                    id='description'
                    value={form.description}
                />
                <Button>Сохранить</Button>
            </Form>
        </ModalBody>
    );
};

ProjectInfoModal.propTypes = {
    /**
     * Custom close handler
     * @type {() => void}
     */
    onClose: PropTypes.func,
};

import { contextMenuVar } from '../../../shared/model/cache/Cache';

/**
 * Function that closes context menu.
 * Before closing checking isClosable param in contextMenuVar.
 * If you need close context menu without checking isClosable - set param force=true
 *
 * @param {boolean} [force=false] If force param set, context menu will be closed anyway
 */
export const closeContextMenu = (force = false) => {
    if (force || contextMenuVar().isClosable !== false) {
        contextMenuVar({
            isOpened: false,
            isClosable: true,
            currentComponent: null,
            position: {
                x: 0,
                y: 0,
            },
            drawPosition: {
                x: 0,
                y: 0,
            },
        });
    }
};

import { gql } from '@apollo/client';

/**
 * Mutation for deleting map object by its id
 */
export const DELETE_MAP_OBJECT = gql`
    mutation deleteMapObject($id: String!, $version: Int!) {
        deleteMapObject(id: $id, version: $version) {
            id
            version
        }
    }
`;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { POpenSansRegular } from '../../../../../../../shared/ui/TextStyles';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;

const CheckBox = styled.input`
    margin: 0;
    width: 14px;
    height: 14px;
    border: 0.5px solid #000000;
    border-radius: 4px;
    accent-color: #023aff;
    cursor: pointer;
`;

const Label = styled.label`
    cursor: pointer;
`;

/**
 * Component represents checkbox with label
 * @component
 */
export const CheckboxGroup = ({
    text,
    name,
    checked = false,
    disabled = false,
    labelTitle,
    onChange,
}) => {
    return (
        <Wrapper>
            <CheckBox
                type={'checkbox'}
                id={'AGM_' + name}
                name={name}
                checked={checked}
                disabled={disabled}
                onChange={onChange}
            />
            <Label htmlFor={'AGM_' + name} title={labelTitle}>
                <POpenSansRegular style={disabled ? { color: 'gray' } : {}}>
                    {text}
                </POpenSansRegular>
            </Label>
        </Wrapper>
    );
};

CheckboxGroup.propTypes = {
    /** Label text */
    text: PropTypes.string,

    /** Checkbox name property */
    name: PropTypes.string,

    /** Checkbox checked property */
    checked: PropTypes.bool,

    /** Is checkbox disabled */
    disabled: PropTypes.bool,

    /** Label title (show on hover) */
    labelTitle: PropTypes.string,

    /** On change handler */
    onChange: PropTypes.func,
};

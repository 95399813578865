import React from 'react';
import styled from 'styled-components';
import { useReactiveVar } from '@apollo/client';
import { Hint } from './Hint';
import { HintsModerator } from '../model/HintsModerator';
import { PositionedContainer, PositionedContainerProps } from '../styles/Containers/PositionedContainer';
import { getFlexAlignmentByHintPosition } from './BaseNotification/Styles/StylesHelpers';

const HintsContainer = styled(PositionedContainer)<PositionedContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: ${(props) => getFlexAlignmentByHintPosition(props.position)};
    gap: 10px;
`;

/**
 * Component that represents container for hints
 * @component
 */
export const HintsProvider: React.FC<{ hintsInstance: HintsModerator }> = ({ hintsInstance }) => {
    const hints = useReactiveVar(hintsInstance.hintsVar);
    return (
        <HintsContainer
            position={hintsInstance.hintsPosition}
            offsetX={hintsInstance.offsetX}
            offsetY={hintsInstance.offsetY}
        >
            {hints.map((hint) => (
                <Hint key={hint.id} hint={hint} />
            ))}
        </HintsContainer>
    );
};

import { gql } from '@apollo/client';
import { MAP_OBJECT_FRAGMENT } from '../../fragments/mapObjects/MapObjectFragment';

export const GET_MAP_OBJECTS = gql`
    ${MAP_OBJECT_FRAGMENT}
    query mapObjects($floorId: String) {
        mapObjects(floorId: $floorId) {
            ... on MapObject {
                ...MapObjectFragment
                data {
                    data
                    schema {
                        schema
                    }
                }
                type {
                    id
                    data {
                        data
                        schema {
                            schema
                        }
                    }
                }
                schemaImpls {
                    data
                    id
                    version
                    schema {
                        id
                        schema
                        version
                    }
                }
            }
        }
    }
`;

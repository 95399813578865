import {
    addTwoPoints,
    getDistanceBetweenPoints,
    multiplyVectorByNumber,
    multiplyVectorsScalarly,
    subtractTwoPoints
} from "../../../../shared/lib/modules/math/Math";

/**
 * Function to find projection of point (intersection of 
 * line and perpendicular from point on line) on wall segment
 * and coordinates of this point.
 * 
 * Principle of working:
 * 1) Rule of vectors multiplication: vectorA * vectorB = |vectorA|*|vectorB|* cos(alfa) 
 * 2) projectionOnVectorB = |vectorA| * cos(alfa) =  vectorA * vectorB / |vectorB|
 * 3) Make shift from start of the vectorB on projectionVector params.
 * 
 * @param {{x: Number, y: Number}} currentPosition 
 * @param {{x: Number, y: Number}} point1 
 * @param {{x: Number, y: Number}} point2 
 * @returns 
 */
export const distanceFromPointToWall = (currentPosition, point1, point2) => {
    let vectorFromCurrentToPoint1 = subtractTwoPoints(
        point1,
        currentPosition
    );

    let vectorSegment = subtractTwoPoints(point1, point2);
  
    let scalarMultiplication = multiplyVectorsScalarly(
        vectorFromCurrentToPoint1,
        vectorSegment
    );

    let segmentModule = vectorSegment.x ** 2 + vectorSegment.y ** 2;

    let vectorProjection = -1;
    if (scalarMultiplication !== 0) {
        vectorProjection = scalarMultiplication / segmentModule;
    }
  
    let perpendicularPoint;
  
    if (vectorProjection < 0) {
        perpendicularPoint = {
            x: point1.x,
            y: point1.y
        }
    } else if (vectorProjection > 1) {
        perpendicularPoint = {
            x: point2.x,
            y: point2.y
        }
    } else {
        perpendicularPoint = addTwoPoints(
            point1,
            multiplyVectorByNumber(vectorSegment, vectorProjection)
        );
    }

    return ({
        perpendicularPoint: perpendicularPoint,
        distance: getDistanceBetweenPoints(currentPosition, perpendicularPoint),
    });
  }
/* Access rules */
export const RULE_VIEW: string = 'VIEW';
export const RULE_EDIT: string = 'EDIT';

export type AccessRuleType = 'VIEW' | 'EDIT';

export const ruleConverter = {
    'VIEW': 'Просмотр',
    'EDIT': 'Редактирование',
};

export const ruleConverterReversed = {
    'Просмотр': 'VIEW',
    'Редактирование': 'EDIT',
};

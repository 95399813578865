import {
    currentDragTypeVar,
    currentToolVar,
    stageVar,
} from '../../../../shared/model/cache/Cache';
import { getDistanceBetweenPoints } from '../../../../shared/lib/modules/math/Math';
import selector from '../../../../features/Selector/model/Selector';
import {
    DragTools,
    Tools,
} from '../../../../pages/Constructor/TopBar/TopBarEntires/Tools/Constants/Tools';
import floorGraph from '../../../../features/Wall/model/FloorGraph';
import { MIN_SQUARE_DIAG_LENGTH } from '../CanvasDefaultValues';
import rightBarModerator from '../../../../pages/Constructor/RightBar/model/RightBarModerator';

/**
 * Create rectangle wall on mouse up
 */
export const handleMouseUp = () => {
    if (
        currentToolVar() === Tools.rectangleRoom &&
        floorGraph.tmpRectGraphVar().size > 0
    ) {
        createRect();
        currentToolVar(Tools.none);
    }

    if (
        (currentToolVar() === Tools.selector ||
            currentDragTypeVar() === DragTools.cursor) &&
        !!selector.rectSelectorPointsVar() &&
        selector.rectSelectorPointsVar().length === 2
    ) {
        selector.selectFromRectArea();
        currentToolVar(Tools.none);
    }
};

/**
 * Function to create square/rect wall and zone inside
 */
const createRect = async () => {
    if (
        getDistanceBetweenPoints(
            floorGraph.tmpRectGraphVar().values().next().value.coordinates,
            { ...stageVar().getRelativePointerPosition() }
        ) >= MIN_SQUARE_DIAG_LENGTH
    ) {
        const { segments, points, intersectionPointsLinks } =
            floorGraph.createRectWall();
        floorGraph.createSegmentsAndUpdateOld(
            segments,
            points,
            true,
            intersectionPointsLinks
        );
    }
    floorGraph.finishCreatingRect();
    rightBarModerator.selectedObject(null);
};

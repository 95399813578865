import { v4 as uuid } from 'uuid';

import {
    currentDragTypeVar,
    currentMapObjectVar,
    currentToolVar,
    modeVar,
    stageVar,
} from '../../../../shared/model/cache/Cache';
import {
    DragTools,
    Tools,
} from '../../../../pages/Constructor/TopBar/TopBarEntires/Tools/Constants/Tools';
import floorGraph from '../../../../features/Wall/model/FloorGraph';
import selector from '../../../../features/Selector/model/Selector';
import { LEFT_MOUSE_BUTTON } from '../CanvasDefaultValues';
import { RULE_EDIT } from '../../../../features/AccessGroup/model/AGConstants';
import zones from '../../../../features/Zone/model/Zones';
import { VIEW_MODE } from '../../../../shared/lib/utils/ModeDefaultValues';

/**
 * Set up canvas point for rectangle and square room tools
 *
 * @param {MouseEvent} e Mouse down event
 */
export const handleMouseDown = (e) => {
    checkClickOutsideItem(e);

    if (e.evt.button !== LEFT_MOUSE_BUTTON || modeVar() === VIEW_MODE) {
        return;
    }

    // start create rectangle room
    if (currentToolVar() === Tools.rectangleRoom) {
        floorGraph.tmpRectGraphVar().set(uuid(), {
            coordinates: { ...stageVar().getRelativePointerPosition() },
            adjacentPoints: [],
            accessRule: RULE_EDIT,
        });
    }

    // start select
    if (
        currentToolVar() === Tools.selector ||
        (currentToolVar() === Tools.none &&
            currentDragTypeVar() === DragTools.cursor &&
            e.target.name() === zones.getGlobalZone().props.name)
    ) {
        selector.rectSelectorPointsVar([
            stageVar().getRelativePointerPosition(),
        ]);
    }
};

/**
 * Function to check if the click was on stage to
 * turn off transformer(if this click outside item).
 */
const checkClickOutsideItem = (e) => {
    if (e.target === e.target.getStage() || e.target.attrs.isZone) {
        if (currentMapObjectVar() !== null) {
            currentMapObjectVar(null);
        }
    }
};

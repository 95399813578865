import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import {
    CONSTRUCTOR_MODE_HINT_ANIMATION_DURATION,
    HintContainer,
} from './Styles/HintContainer';
import { HintText } from './Styles/HintText';
import { HintImg } from './Styles/HintImg';
import { ConstructorModeHintProps } from './Types';

/**
 * Component for constructor mode hint (edit/view)
 * @component
 */
export const ConstructorModeHint: React.FC<ConstructorModeHintProps> = ({
    hintText,
    iconSrc,
    iconAlt = '',
    startExitAnimation = false,
}: ConstructorModeHintProps) => {
    /** Need to prevent warning and help CSSTransition to find node */
    const hintRef = useRef(null);

    return (
        <CSSTransition
            in={!startExitAnimation}
            timeout={CONSTRUCTOR_MODE_HINT_ANIMATION_DURATION}
            classNames='ConstructorModeHint-anim'
            nodeRef={hintRef}
        >
            <HintContainer ref={hintRef} className='ConstructorModeHint-anim'>
                {iconSrc && <HintImg src={iconSrc} alt={iconAlt} />}
                <HintText>{hintText}</HintText>
            </HintContainer>
        </CSSTransition>
    );
};

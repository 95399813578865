import React from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { Edge } from './Edge';
import { getDistanceBetweenPoints } from '../../../shared/lib/modules/math/Math';
import {
    ROUTE_DEFAULT_COLOR,
    ROUTE_DEFAULT_OPACITY,
    ROUTE_TEMPORARY_COLOR,
    ROUTE_UNAVAILABLE_COLOR,
} from '../lib/RouteDefaultValues';
import { RouteGraphWrapper } from '../model/RouteGraphWrapper';
import { openRouteContextMenu } from '../../ContextMenu/model/OpenRouteContextMenu';
import { modeVar, stageVar } from '../../../shared/model/cache/Cache';
import { CREATING_MODE } from '../../../shared/lib/utils/ModeDefaultValues';
import { RULE_VIEW } from '../../AccessGroup/model/AGConstants';

/**
 * @component Component that represent all route segments on canvas
 */
export const RouteSegments = ({ route }) => {
    const routeSegments = useReactiveVar(route.graph.drawSegmentsVar);
    let color = useReactiveVar(route.color) || ROUTE_DEFAULT_COLOR;
    let opacity =
        1 - useReactiveVar(route.transparency) || ROUTE_DEFAULT_OPACITY;

    if (route.accessRule === RULE_VIEW && modeVar() === CREATING_MODE) {
        color = ROUTE_UNAVAILABLE_COLOR;
        opacity = 1;
    }

    /** Handler for opening context menu for route */
    const openContextMenu = () => {
        if (stageVar()) {
            openRouteContextMenu(
                route.routeId,
                stageVar().getRelativePointerPosition()
            );
        }
    };

    return routeSegments.map((segmentLink) => {
        const key = segmentLink.getKey();
        const value = segmentLink.getInfo();

        const newEdges = [];

        const v1 = segmentLink.firstPointLinkWrapper.pointInfo.coordinates;
        const v2 = segmentLink.secondPointLinkWrapper.pointInfo.coordinates;
        const distance = getDistanceBetweenPoints(v1, v2);

        const edgesCount = Math.min(
            Math.floor(distance / (window.innerWidth / 5)) + 2,
            6
        );

        let curX = v1.x,
            curY = v1.y;
        const dx = (v2.x - v1.x) / edgesCount;
        const dy = (v2.y - v1.y) / edgesCount;

        for (let i = 0; i < edgesCount; i++) {
            newEdges.push([curX, curY]);
            curX += dx;
            curY += dy;
        }
        newEdges.push([v2.x, v2.y]);

        // Need to add several arrowhead to the edge.
        // So the solution is to divide one edge to several parts.
        // Each part of the edge adding to body variable
        const body = [];

        for (let i = 0; i < edgesCount; i++) {
            body.push(
                <Edge
                    key={key + i}
                    edgeId={key}
                    graph={route.graph}
                    points={[...newEdges[i], ...newEdges[i + 1]]}
                    pointerAtEnding={i !== edgesCount - 1}
                    color={value.isFetched ? color : ROUTE_TEMPORARY_COLOR}
                    opacity={opacity}
                    isVirtual={value?.type?.virtual}
                    onContextMenu={openContextMenu}
                    accessRule={route.accessRule}
                />
            );
        }

        return body;
    });
};

RouteSegments.propTypes = {
    route: PropTypes.instanceOf(RouteGraphWrapper),
};

import { makeVar } from '@apollo/client';
import { client } from '../../../shared/graphql/ApolloClient';
import { CREATE_ACCESS_GROUP } from '../../../shared/graphql/mutations/accessGroups/CreateAccessGroup';
import { UPDATE_ACCESS_GROUP } from '../../../shared/graphql/mutations/accessGroups/UpdateAccessGroup';
import { DELETE_ACCESS_GROUP } from '../../../shared/graphql/mutations/accessGroups/DeleteAccessGroup';
import { currentProjectVar } from '../../../shared/model/cache/Cache';
import notifications from '../../HintSystem/model/Notifications';

/**
 * @typedef {import('../model/AccessGroup').AccessGroupData} AccessGroupData
 * @typedef {import('../../../shared/typedefs/typedefs').NumberReactiveVar} NumberReactiveVar
 */

/**
 * Describes access group draft to create new group
 * @typedef {{
 *    name: string,
 *    description: string?,
 *    parentGroupId: string | null?,
 *    parentMirror: boolean?,
 *    floorCreationAvailable: boolean?,
 *    notificationCreationAvailable: boolean?
 * }} AccessGroupDraft
 */

/** Object for storing query states */
export const QUERY_STATES = {
    IDLE: 1,
    LOADING: 2,
    SUCCESS: 3,
    ERROR: 4,
};

/**
 * Class that realizes api methods for access groups
 * @class
 */
class AccessGroupsAPI {
    constructor() {
        /**
         * Var for storing loading state for creating group
         * @type {number}
         */
        this.groupCreatingState = makeVar(QUERY_STATES.IDLE);

        /**
         * Var for storing state for change group mutation
         * @type {number}
         */
        this.groupUpdatingState = makeVar(QUERY_STATES.IDLE);
    }

    /**
     * Method for creating new access group
     * @param {AccessGroupDraft} accessGroupDraft - Group properties
     * @returns {AccessGroupData[] | null} created access group data
     */
    async createGroup({
        name = '',
        description = '',
        parentGroupId = null,
        parentMirror = false,
        floorCreationAvailable = false,
        notificationCreationAvailable = false,
    }) {
        this.groupCreatingState(QUERY_STATES.LOADING);
        const options = {
            mutation: CREATE_ACCESS_GROUP,
            variables: {
                accessGroupDraft: {
                    projectId: currentProjectVar().id,
                    name: name,
                    description: description,
                    parentGroupId: parentGroupId,
                    parentMirror: parentMirror,
                    floorCreationAvailable: floorCreationAvailable,
                    notificationCreationAvailable:
                        notificationCreationAvailable,
                },
            },
        };

        try {
            const groupsData = (await client.mutate(options)).data
                .createAccessGroup;
            this.groupCreatingState(QUERY_STATES.SUCCESS);
            return groupsData.map((group) => this.adaptData(group));
        } catch (error) {
            notifications.setError('Не удалось создать группу', error.message);
            this.groupCreatingState(QUERY_STATES.ERROR);
            console.log(error);
        }

        return null;
    }

    /**
     * Method for updating access group
     * @param {string} groupId - Group UUID
     * @param {AccessGroupDraft} accessGroupDraft - Group properties
     * @returns {AccessGroupData[] | null} created access group data
     */
    async updateGroup(groupId, {
        name = '',
        description = '',
        parentMirror = false,
        floorCreationAvailable = false,
        notificationCreationAvailable = false,
    }) {
        this.groupUpdatingState(QUERY_STATES.LOADING);
        const options = {
            mutation: UPDATE_ACCESS_GROUP,
            variables: {
                accessDiff: {
                    id: groupId,
                    name: name,
                    description: description,
                    parentMirror: parentMirror,
                    floorCreationAvailable: floorCreationAvailable,
                    notificationCreationAvailable:
                        notificationCreationAvailable,
                },
            },
        };

        try {
            const groupsData = (await client.mutate(options)).data
                .updateAccessGroup;
            this.groupUpdatingState(QUERY_STATES.SUCCESS);
            return groupsData.map((group) => this.adaptData(group));
        } catch (error) {
            notifications.setError('Не удалось обновить группу', error.message);
            this.groupUpdatingState(QUERY_STATES.ERROR);
            console.log(error);
        }

        return null;
    }

    /**
     * Method that adapt object received from server to project format
     * @param {object} data Data from server
     * @returns {AccessGroupData}
     */
    adaptData(data) {
        return {
            id: data.id,
            name: data.name,
            description: data.description,
            parentMirror: data.parentMirror,
            floorCreationAvailable: data.floorCreationAvailable,
            notificationCreationAvailable: data.notificationCreationAvailable,
            parent: data.parent === null ? null : data.parent.id,
            childGroups: data.childGroups.map((group) => group.id),
            descendants: data.descendants.map((group) => group.id),
            members: [...data.members],
        };
    }

    /**
     * Method for deleting access group
     * @param {string} id group UUID
     * @returns {string[] | null} deleted access groups UUID
     */
    async deleteGroup(id) {
        let groupIds = null;
        const options = {
            mutation: DELETE_ACCESS_GROUP,
            variables: { id: id },
        };

        try {
            groupIds = (
                await client.mutate(options)
            ).data.deleteAccessGroup.map((gr) => gr.id);
        } catch (error) {
            notifications.setError('Не удалось удалить группу', error.message);
            console.log(error);
        }

        return groupIds;
    }
}

const accessGroupsAPI = new AccessGroupsAPI();
export default accessGroupsAPI;

import React, { useState } from "react";
import styled from "styled-components";
import homeIcon from "../../../shared/assets/images/icons/home-door.svg"
import homeIconWhite from "../../../shared/assets/images/icons/home-door-white.svg"
import { HorizontalLogo } from "../../../shared/ui/HorizontalLogo";
import { useNavigate } from "react-router";
import { mainPage } from "../../../shared/model/routes/staticRoutes";

/**
 * Header styled
 */
const HeaderStyled = styled.header`
    width: calc(100% - 40px); // 40px for padding
    max-width: 1400px;
    margin: 24px auto;
    padding: 14px 20px;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
	vertical-align: center;
    align-items: center;
    gap: 20px;

    position: sticky;
    top: 0;
    z-index: 2;
    background-color: white;

    & > img {
        height: 57.42;
        width: auto;
    }
`;

/**
 * Home button styled
 */
const HomeButton = styled.button`
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;

    display: flex;
    align-items: center;
    gap: 12px;
    padding: 11px 21px;

    border: 0;
    background: #E1E8FF;
    border-radius: 13px;

    &:hover {
        background: #5076FD;
        color: white;
    }

    &:active {
        background: #D9D9D9;
        color: black;
    }

    & > img {
        width: 21px;
        height: auto;
    }
`;

/**
 * Header with home button
 * @component
 */
export const Header = () => {

    // vars for changing icon on HomeButton
    const [isHomeButtonHovered, setIsHomeButtonHovered] = useState(false);
    const [isHomeButtonClicked, setIsHomeButtonClicked] = useState(false);

    const navigate = useNavigate();

    return (
        <HeaderStyled>
            <HorizontalLogo />
            <HomeButton
                onMouseOver={() => setIsHomeButtonHovered(true)}
                onMouseOut={() => {setIsHomeButtonHovered(false); setIsHomeButtonClicked(false);}}
                onMouseDown={() => setIsHomeButtonClicked(true)}
                onMouseUp={() => setIsHomeButtonClicked(false)}
                onClick={() => {navigate(mainPage)}}
            >
                <img 
                    alt="home" 
                    src={(isHomeButtonHovered && !isHomeButtonClicked) ? homeIconWhite : homeIcon}
                />
                На главную
            </HomeButton>
        </HeaderStyled>
    );
};
import React from 'react';
import styled from 'styled-components';
import { useReactiveVar } from '@apollo/client';
import { isGridEnabledVar } from '../../../../shared/model/cache/Cache';

const Container = styled.div`
    display: flex;
    justify-content: center;
    padding: 7.4px 3px;
    margin-left: 30px;

    background: rgba(255, 255, 255, 0.5);
    border: 0.5px solid #e7e7e7;
    box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.07);
    border-radius: 18px;

    width: fit-content;
    z-index: 10;
`;

const ClickableContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    gap: 10px;
    padding: 0 8px;
`;

const Button = styled.input`
    position: relative;

    width: 26px;
    height: 14px;
    margin: 0;
    margin-top: 3px;

    vertical-align: top;

    background: #ffffff;
    border: 1px solid #bbc1e1;
    border-radius: 30px;
    outline: none;
    cursor: pointer;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);

    &::after {
        content: '';

        position: absolute;
        left: 1px;
        top: 0;

        width: 12px;
        height: 12px;
        background-color: #989898;
        border-radius: 50%;

        transform: translateX(0);

        transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);
    }

    &:checked::after {
        transform: translateX(calc(100% - 2px));
        background-color: #023aff;
    }
`;

/**
 * Switcher for grid
 * @component
 */
export const GridSwitcher = () => {
    const isGridEnabled = useReactiveVar(isGridEnabledVar);
    const handleGridEnable = () => {
        isGridEnabledVar(!isGridEnabledVar());
    };
    return (
        <Container>
            <ClickableContainer>
                <label htmlFor='toggle-button'>Сетка</label>
                <Button
                    checked={isGridEnabled}
                    onChange={handleGridEnable}
                    type='checkbox'
                    id='toggle-button'
                />
            </ClickableContainer>
        </Container>
    );
};

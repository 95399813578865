import { DocumentNode, gql } from '@apollo/client';
import { ROUTE_FRAGMENT } from '../../fragments/routes/RouteFragment';

export const CREATE_ROUTE: DocumentNode = gql`
    ${ROUTE_FRAGMENT}
    mutation createRoute($routeDraft: RouteDraft!) {
        createRoute(routeDraftDto: $routeDraft) {
            ...RouteFragment
        }
    }
`;

import {
    graphicSchemaVar,
    stagePositionVar,
    stageScaleVar,
    stageVar,
} from '../cache/Cache';
import graphicSchema from '../../../features/GraphicSchema/lib/Schema';
import scalesConformer from '../../../features/ScalesConformer/model/ScalesConformer';

export const objectWithSchemaTypes = {
    mapObject: 'MapObject',
    zone: 'Zone',
    wallSegment: 'WallSegment',
};

/**
 * Method to activate mapins form
 * @param {Zone | MapObject | WallSegment} objectWithSchema
 * @param {String} type
 */
export const activateMapinsForm = (objectWithSchema, type) => {
    const activations = {
        [objectWithSchemaTypes.mapObject]: () =>
            activateMapObjectForm(
                objectWithSchema,
                objectWithSchemaTypes.mapObject
            ),
        [objectWithSchemaTypes.zone]: () =>
            activateFormInPos(objectWithSchema, objectWithSchemaTypes.zone),
        [objectWithSchemaTypes.wallSegment]: () =>
            activateFormInPos(
                objectWithSchema,
                objectWithSchemaTypes.wallSegment
            ),
    };

    /** Default handler. Need to prevent error on incorrect type */
    const defaultActivation = () => {
        console.warn(
            `You try to activate mapins form, but the type "${type}" doesn't have a handler`
        );
    };

    return (activations[type] || defaultActivation)();
};

/**
 *
 * @param {MapObject} objectWithSchema
 * @param {String} objectTypeName
 */
const activateMapObjectForm = (objectWithSchema, objectTypeName) => {
    // TODO: remake according to current zoom
    stageScaleVar(1);
    stagePositionVar({
        x: stageVar().width() / 2 - objectWithSchema.position.x,
        y: stageVar().height() / 2 - objectWithSchema.position.y,
    });

    let botSchemas = objectWithSchema?.schemaImpls?.map((schemaImpl) => {
        const additionalInfo = {
            version: schemaImpl?.version,
            schemaId: schemaImpl?.schema?.id,
            schemaImplId: schemaImpl?.id,
        };
        return [schemaImpl?.schema?.schema, schemaImpl?.data, additionalInfo];
    });

    const coordinatesOfSchema = {
        x: objectWithSchema.position.x + 10,
        y: objectWithSchema.position.y - scalesConformer.toPixels(objectWithSchema.height / 2) - 5,
    };

    graphicSchemaVar(
        graphicSchema.createSchema(
            objectWithSchema.id,
            objectWithSchema.name,
            objectTypeName,
            undefined,
            [
                objectWithSchema?.data?.schema?.schema,
                objectWithSchema?.data?.data,
            ],
            botSchemas,
            coordinatesOfSchema
        )
    );
};

/**
 * @param {Zone | import('../../graphql/mutations/walls/UpdateWallSegments').WallSegmentDiff} objectWithSchema
 * @param {String} objectTypeName
 */
const activateFormInPos = (objectWithSchema, objectTypeName) => {
    let position;

    if (!graphicSchemaVar()) {
        position = stageVar().getRelativePointerPosition();
    } else {
        position = {
            x: graphicSchemaVar().position.x,
            y: graphicSchemaVar().position.y + 10,
        };
    }

    stageScaleVar(1);
    stagePositionVar({
        x: stageVar().width() / 2 - position.x,
        y: stageVar().height() / 2 - position.y,
    });

    let botSchemas = objectWithSchema?.schemaImpls?.map((schemaImpl) => {
        const additionalInfo = {
            version: schemaImpl?.version,
            schemaId: schemaImpl?.schema?.id,
            schemaImplId: schemaImpl?.id,
        };
        return [schemaImpl?.schema?.schema, schemaImpl?.data, additionalInfo];
    });

    graphicSchemaVar(
        graphicSchema.createSchema(
            objectWithSchema.id,
            objectWithSchema.name,
            objectTypeName,
            undefined,
            [
                objectWithSchema?.data?.schema?.schema,
                objectWithSchema?.data?.data,
            ],
            botSchemas,
            {
                x: position.x,
                y: position.y - 10,
            }
        )
    );
};

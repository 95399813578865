import React from 'react';
import { ZONE_MENU_DEFAULT, ZoneContextMenu } from '../ui/ContextMenus/ZoneContextMenu';
import { openContextMenu } from './OpenContextMenu';

/**
 * Function that call opening context menu function for the current zone
 * @param {uuid} zoneId 
 * 
 * @param {object} position position of mouse click
 * @param {number} position.x
 * @param {number} position.y
 * 
 * @param {string} contextMenuType type of context menu
 */
export const openZoneContextMenu = (
    zoneId,
    position,
    contextMenuType = ZONE_MENU_DEFAULT
) => {
    const component = (
        <ZoneContextMenu zoneId={zoneId} contextMenuType={contextMenuType} />
    );

    openContextMenu(position, component);
};

import React, { useState } from 'react';
import styled from 'styled-components';

import downArrow from '../../../../shared/assets/images/icons/down-arrow.svg';
import crossImg from '../../../../shared/assets/images/icons/ConstructorPage/RightBar/cross-in-circle.svg';
import searchLoop from '../../../../shared/assets/images/icons/search.svg';
import rightBarModerator from '../model/RightBarModerator';

const HeaderContainer = styled.div<{ isOpened: boolean }>`
    display: flex;
    height: 42px;
    align-items: center;
    background-color: white;
    padding: ${({ isOpened }) => (isOpened ? '0 40px' : '0 12px')};
    margin-right: ${({ isOpened }) => (isOpened ? '0px' : '40px')};
    border: 0.5px solid #d7d7d7;
    border-radius: 0 0 9px 9px;
    transition: 0.1s;
    overflow: hidden;
`;

const HeaderText = styled.span`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin-right: auto;
`;

const CollapseIcon = styled.img<{ isOpened: boolean }>`
    width: 19px;
    height: 19px;
    :hover {
        cursor: pointer;
    }
    transform: ${({ isOpened }) => (isOpened ? 'rotate(0)' : 'rotate(-90deg)')};
    transition: 0.2s;
`;

const SearchIcon = styled.img<{ isOpened: boolean }>`
    margin-right: 12px;
    visibility: ${({ isOpened }) => (isOpened ? 'visible' : 'hidden')};
    :hover {
        cursor: pointer;
    }
`;

const SearchInput = styled.input`
    min-width: 100px;
    max-width: 166px;
    padding: 0;
    margin-right: auto;
    border: none;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.8);
    background-color: transparent;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #000000;

    :focus {
        outline: none;
    }
`;

interface RightBarHeaderProps {
    /** Is right bar opened */
    isOpened: boolean;

    /** Title for right bar */
    text?: string;
}

/**
 * Component that represents header for RightBar. It contains search input.
 *
 * @component
 */
export const RightBarHeader = ({
    isOpened,
    text = 'Каталог объектов',
}: RightBarHeaderProps) => {
    const [isSearching, setIsSearching] = useState(false);
    const operation = rightBarModerator.operation();

    const handleCollapseClick = () => {
        setIsSearching(false);
        if (operation !== null) {
            rightBarModerator.clearOperation();
        } else if (rightBarModerator.isOpened()) {
            rightBarModerator.close();
        } else {
            rightBarModerator.open();
        }
    };

    const handleSearchClick = () => {
        setIsSearching(true);
    };

    return (
        <HeaderContainer isOpened={isOpened}>
            {isSearching ? (
                <SearchInput placeholder='Поиск по каталогу' />
            ) : (
                <HeaderText>{text}</HeaderText>
            )}

            {!operation && (
                <SearchIcon
                    src={searchLoop}
                    alt='search'
                    isOpened={isOpened}
                    onClick={handleSearchClick}
                />
            )}
            <CollapseIcon
                src={operation ? crossImg : downArrow}
                alt={operation ? '×' : '▼'}
                isOpened={isOpened}
                onClick={handleCollapseClick}
            />
        </HeaderContainer>
    );
};

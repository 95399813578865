import { DocumentNode, gql } from '@apollo/client';
import { ACCESS_GROUP_FRAGMENT } from '../../fragments/accessGroups/AccessGroupFragment';

/** Mutation to remove user from access group */
export const REMOVE_USER_FROM_ACCESS_GROUP: DocumentNode = gql`
    ${ACCESS_GROUP_FRAGMENT}
    mutation deleteFromGroup($userSelector: UserSelector!, $groupId: String!) {
        deleteFromGroup(userSelector: $userSelector, groupId: $groupId) {
            ...AccessGroupFragment
        }
    }
`;

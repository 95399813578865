import React from 'react';
import PropTypes from 'prop-types';
import { OpeningGroup } from '../../ui/OpeningGroup/OpeningGroup';
import zones from '../../../../../features/Zone/model/Zones';
import zoneIcon from '../../../../../shared/assets/images/icons/ConstructorPage/LeftBar/zone-little.svg';
import { mapObjectsVar } from '../../../../../shared/model/cache/Cache';
import { useReactiveVar } from '@apollo/client';
import { Item } from '../../ui/OpeningGroup/Items/Item';
import commandManager from '../../../../../features/CommandManager/model/CommandManager';
import { changeZonePropertiesCommand } from '../../../../../features/CommandManager/lib/commands/zone/ChangeZoneProperties';

/**
 * Component that represents information about zone.
 * Show zone name and zone child elements
 * @component
 */
export const ZoneInfo = ({ zoneId }) => {
    const mapObjects = useReactiveVar(mapObjectsVar).filter(
        (item) => item.zone.id === zoneId
    );

    /**
     * Handler for zone name change
     * @param {string} newZoneName
     */
    const handleZoneNameChanged = (newZoneName) => {
        commandManager.do(
            changeZonePropertiesCommand(zoneId, {
                name: newZoneName,
            })
        );
        zones.saveUpdate({
            id: zoneId,
            name: newZoneName,
            version: zones.getVersion(zoneId),
        });
    };

    return (
        <OpeningGroup
            title={zones.zonesVar().get(zoneId).props.name}
            iconSrc={zoneIcon}
            isOpen={true}
            onTitleEditFinish={handleZoneNameChanged}
        >
            {mapObjects.map((item, i) => (
                <Item title={item.name || item.id} key={item.id + i} />
            ))}
        </OpeningGroup>
    );
};

ZoneInfo.propTypes = {
    /**
     * canvas zone id
     */
    zoneId: PropTypes.string,
};

import React from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { Group } from './Group';
import accessGroupsModerator from '../../../../../../features/AccessGroup/model/AccessGroupsModerator';
import { Member } from './ui/Member';

/**
 * Component represents tab with roles settings
 * @component
 */
export const ChildGroups = ({ rootGroupId, containerRef }) => {
    const rootGroup = accessGroupsModerator.getGroup(rootGroupId);
    const groups = useReactiveVar(rootGroup.childGroups).map((groupId) =>
        accessGroupsModerator.getGroup(groupId)
    );

    const members = useReactiveVar(rootGroup.members);

    const isRoot = rootGroupId === accessGroupsModerator.currentAccessGroupId;

    return (
        <Group group={rootGroup} isRoot={isRoot} containerRef={containerRef}>
            {!isRoot &&
                members.map((member) => (
                    <Member
                        key={member.id}
                        member={member}
                        groupId={rootGroupId}
                        containerRef={containerRef}
                    />
                ))}
            {groups.map((group) => {
                return (
                    <ChildGroups
                        key={group.groupId}
                        rootGroupId={group.groupId}
                        containerRef={containerRef}
                    />
                );
            })}
        </Group>
    );
};

ChildGroups.propTypes = {
    /** UUID of parent group */
    rootGroupId: PropTypes.string,

    /** Reference to scroll container */
    containerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
};

import {
    emailRegex,
    loginRegex,
    onlyCyrillicAndLatinRegex,
    onlyNumbersRegex,
    passwordRegex,
    requiredCapitalLetterRegex,
    requiredLowerCaseLetterRegex,
    requiredNumberRegex,
    requiredPasswordSymbolRegex
} from "../../../../shared/lib/utils/regex/regex";

const REQUIRED_FIELD = "Обязательное поле";

/**
 * Function for validation general data on user settings page
 * @param {Object} data - Data for validation
 * @param {string} data.name
 * @param {string} data.login
 * @param {string} data.email
 * @param {string} data.phone
 * @param {boolean} formValid - form validation flag
 * @param {function} setFormValid - useState function
 * @param {function} setInputValuesErrors - useState function
 * @param {function} setInputValuesStatus - useState function
 */
export const validateGeneralInformationForm = (
    { name, login, email, phone },
    formValid,
    setFormValid,
    setInputValuesErrors,
    setInputValuesStatus
) => {
    // vars for hint messages
    let hintName = null;
    let hintLogin = null;
    let hintEmail = null;
    let hintPhone = null;

    // vars for statuses of messages
    let hintTypeName = "error";
    let hintTypeLogin = "error";
    let hintTypeEmail = "error";
    let hintTypePhone = "error";

    if (name === "") hintName = REQUIRED_FIELD;
    else if (!name.match(onlyCyrillicAndLatinRegex)) hintName = "Имя не должно содержать цифр и других специальных символов";
    else hintTypeName = "hint";

    if (login === "") hintLogin = REQUIRED_FIELD;
    else if (login.length < 3) hintLogin = "Слишком короткий логин";
    else if (login.length > 30) hintLogin = "Слишком длинный логин";
    else if (!login.match(loginRegex)) hintLogin = "Логин содержит запрещённые символы";
    else hintTypeLogin = "hint";

    if (email === "") hintEmail = REQUIRED_FIELD;
    else if (!email.match(emailRegex)) hintEmail = "Введите корректный адрес эл. почты";
    else hintTypeEmail = "hint";

    if (phone === "") hintPhone = REQUIRED_FIELD;
    else if (phone.match(/.*\s.*/)) hintPhone = "Номер телефона не должен содержать пробелов";
    else if (phone.match(/^\+7.*$/)) hintPhone = "Используйте 8 вместо +7";
    else if (phone.match(/.*[-+().].*/)) hintPhone = "Номер телефона не должен содержать специальных символов";
    else if (!phone.match(onlyNumbersRegex)) hintPhone = "Номер телефона должен состоять только из цифр";
    else if (phone.length !== 11) hintPhone = "Длина номера 11 цифр";
    else hintTypePhone = "hint";

    setInputValuesErrors({ // set hints
        name: hintName,
        login: hintLogin,
        email: hintEmail,
        phone: hintPhone,
    });

    setInputValuesStatus({ // set statuses of hints
        name: hintTypeName,
        login: hintTypeLogin,
        email: hintTypeEmail,
        phone: hintTypePhone,
    });

    const isValid = (hintTypeName !== "error") && (hintTypeLogin !== "error")
        && (hintTypeEmail !== "error") && (hintTypePhone !== "error");

    if (isValid !== formValid) {
        setFormValid(isValid);
    }
};

/**
 * Function for validation password change data
 * @param {Object} data - Data for validation
 * @param {string} data.password - Current password
 * @param {string} data.newPassword - New password
 * @param {string} data.newPasswordControl - Control password
 * @param {boolean} formValid - form validation flag
 * @param {function} setFormValid - useState function
 * @param {function} setInputValuesErrors - useState function
 * @param {function} setInputValuesStatus - useState function
 */
export const validatePasswordChangeForm = (
    { password, newPassword, newPasswordControl },
    formValid,
    setFormValid,
    setInputValuesErrors,
    setInputValuesStatus
) => {
    // vars for hint messages
    let hintPassword = null;
    let hintNewPassword = null;
    let hintNewPasswordControl = null;

    // vars for statuses of messages
    let hintTypePassword = "error";
    let hintTypeNewPassword = "error";
    let hintTypeNewPasswordControl = "error";

    if (password === "") hintPassword = REQUIRED_FIELD;
    else hintTypePassword = "hint";

    if (newPassword === "") hintNewPassword = REQUIRED_FIELD;
    else if (newPassword.length < 8) hintNewPassword = "Слишком короткий пароль";
    else if (newPassword.length > 128) hintNewPassword = "Слишком длинный пароль";
    else if (!newPassword.match(requiredCapitalLetterRegex)) hintNewPassword = "Требуется заглавная буква";
    else if (!newPassword.match(requiredLowerCaseLetterRegex)) hintNewPassword = "Требуется строчная буква";
    else if (!newPassword.match(requiredNumberRegex)) hintNewPassword = "Требуется цифра";
    else if (!newPassword.match(requiredPasswordSymbolRegex)) hintNewPassword = "Требуется символ !?+-=_@#$%^&*";
    else if (!newPassword.match(passwordRegex)) hintNewPassword = "Пароль содержит запрещённые символы";
    else {
        hintNewPassword = "Отличный пароль!";
        hintTypeNewPassword = "success";
    }

    if (newPasswordControl === "") hintNewPasswordControl = REQUIRED_FIELD;
    else if (newPassword !== newPasswordControl) hintNewPasswordControl = "Пароли не совпадают :(";
    else hintTypeNewPasswordControl = "hint";

    setInputValuesErrors({ // set hints
        password: hintPassword,
        newPassword: hintNewPassword,
        newPasswordControl: hintNewPasswordControl
    });

    setInputValuesStatus({ // set statuses of hints
        password: hintTypePassword,
        newPassword: hintTypeNewPassword,
        newPasswordControl: hintTypeNewPasswordControl
    });

    const isValid = (hintTypePassword !== "error")
        && (hintTypeNewPassword !== "error")
        && (hintTypeNewPasswordControl !== "error");

    if (isValid !== formValid) {
        setFormValid(isValid);
    }
};
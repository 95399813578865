import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { useNavigate } from 'react-router';

import editEndIcon from '../../../shared/assets/images/icons/edit-end.svg';
import shareIcon from '../../../shared/assets/images/icons/share.svg';
import homeIcon from '../../../shared/assets/images/icons/home-door.svg';
import settings from '../../../shared/assets/images/icons/settings.svg';
import { POpenSansRegular } from '../../../shared/ui/TextStyles';
import { FloorSelector } from './TopBarEntires/FloorSelector/FloorSelector';
import { Tools } from './TopBarEntires/Tools/Tools';
import {
    currentMapObjectVar,
    currentProjectVar,
    modeVar,
} from '../../../shared/model/cache/Cache';
import { ProjectSettingsModal } from './TopBarEntires/ProjectSettingsModal/ProjectSettingsModal';
import {
    CREATING_MODE,
    VIEW_MODE,
} from '../../../shared/lib/utils/ModeDefaultValues';
import { mainPage } from '../../../shared/model/routes/staticRoutes';
import { Modal } from '../../../features/ModalWindow/Modal';
import cursorModerator, { CURSORS } from '../../../shared/lib/utils/common/CursorModerator';
import rightBarModerator from '../RightBar/model/RightBarModerator';

const TopBarContainer = styled.div`
    width: calc(100% - 80px); // 80px for paddings
    height: 100%;
    padding: 0 40px;
    background: white;
    display: flex;
    gap: 10px;
    align-items: center;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
    user-select: none;
`;

const TemplateButton = styled.button`
    display: flex;
    align-items: center;
    padding: 11px 14px;
    gap: 12px;
    border-radius: 13px;
    border-color: transparent;
    font-style: normal;
    color: #ffffff;
    cursor: pointer;
`;

const EditButton = styled(TemplateButton)`
    background: #ff5555;
    margin-left: 30px;
    :hover {
        background: #ff8080;
    }
`;

const ShareButton = styled(TemplateButton)`
    background: #0239ff;
    margin-left: 10px;
    :hover {
        background: #5076fd;
    }
`;

const IconButton = styled.img`
    padding: 11px 10px;
    width: 22px;
    height: 21px;
    background: #d1dcff;
    border-radius: 13px;
    :hover {
        background-color: #b7c7fe;
        cursor: pointer;
    }
`;

/**
 * Component that represents top bar of editing mode. It contains tools, floor selector.
 *
 * @component
 */
export const TopBar = ({ mode = VIEW_MODE }) => {
    const [isProjectInfoShow, setIsProjectInfoShow] = useState(false);

    const navigate = useNavigate();
    const currentProject = useReactiveVar(currentProjectVar);
    const changeProjectInfoHandle = () => {
        setIsProjectInfoShow(true);
    };

    const handleEndEditButtonClick = () => {
        modeVar(VIEW_MODE); // set view mode
        currentMapObjectVar(null); // clear selected map object
        rightBarModerator.clear(); // clear right bar var to initial state
        cursorModerator.changeDefaultCursor(CURSORS.GRAB); // set cursor
    };

    const handleHomeButtonClick = () => {
        navigate(mainPage);
    };

    return (
        <TopBarContainer>
            {/* Button for navigating to home -- Main menu */}
            <IconButton
                src={homeIcon}
                alt='home'
                onClick={handleHomeButtonClick}
            />
            <IconButton
                src={settings}
                alt='settings'
                onClick={changeProjectInfoHandle}
            />

            <FloorSelector />

            {mode === CREATING_MODE && <Tools />}

            <Modal
                isOpened={isProjectInfoShow}
                isCloseIconShow={true}
                onClose={() => setIsProjectInfoShow(false)}
            >
                <ProjectSettingsModal
                    onClose={() => setIsProjectInfoShow(false)}
                />
            </Modal>

            <POpenSansRegular
                style={{ cursor: 'pointer', flexGrow: 3, textAlign: 'center' }}
                onDoubleClick={changeProjectInfoHandle}
            >
                {mode === VIEW_MODE &&
                    (currentProject.name.toUpperCase() ||
                        'Название карты'.toUpperCase())}
            </POpenSansRegular>
            {mode === VIEW_MODE ? (
                <ShareButton>
                    <img src={shareIcon} alt='share' />
                    Поделиться
                </ShareButton>
            ) : (
                <EditButton onClick={handleEndEditButtonClick}>
                    <img src={editEndIcon} alt='edit' />
                    Завершить редактирование
                </EditButton>
            )}
        </TopBarContainer>
    );
};

TopBar.propTypes = {
    mode: PropTypes.string,
};

import React from 'react';
import styled from 'styled-components';

/** Styled container for checkbox */
const Container = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;

    // remove pointer if checkbox hidden
    &:has(input[style*='display: none;']) {
        cursor: unset;
    }
`;

/** Styled checkbox */
const CheckboxStyled = styled.input`
    width: 15px;
    height: 15px;
    margin: 1px 7px 0 0;
    border: 0.5px solid #000000;
    border-radius: 3px;
    accent-color: #023aff;
    cursor: pointer;
`;

type CheckboxProps = {
    children: React.ReactNode;
} & React.InputHTMLAttributes<HTMLInputElement>;

/**
 * Form component - checkbox
 * @component
 * @example
 * <Checkbox>Label</Checkbox>
 */
export const Checkbox = ({ children, ...props }: CheckboxProps) => {
    return (
        <Container>
            <CheckboxStyled type={'checkbox'} {...props} />
            {children}
        </Container>
    );
};

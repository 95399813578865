import { RULE_VIEW } from '../model/AGConstants';
import accessGroupsModerator from '../model/AccessGroupsModerator';

/**
 * @typedef {import('../../Wall/api/WallSegmentsAPI').AccessRuleFromServer} AccessRuleFromServer
 */

/**
 * Function for finding access rule for current user
 * @param {AccessRuleFromServer[]} accessRules
 * @returns {string} user role: VIEW/EDIT
 */
export const getRuleForCurrentUser = (accessRules) => {
    return (
        accessRules.find(
            (rule) =>
                rule.group.id === accessGroupsModerator.currentAccessGroupId
        )?.rule || RULE_VIEW
    );
};

import { DocumentNode, gql } from '@apollo/client';
import { ROUTE_SEGMENT_FRAGMENT } from '../../fragments/routes/RouteSegmentFragment';

export const CREATE_ROUTE_SEGMENTS: DocumentNode = gql`
    ${ROUTE_SEGMENT_FRAGMENT}
    mutation createRouteSegments(
        $floorId: String!
        $segments: [RouteSegmentDraft]!
        $points: [VirtualPoint]
    ) {
        createRouteSegments(
            floorId: $floorId
            routeSegmentDrafts: $segments
            points: $points
        ) {
            ...RouteSegmentFragment
        }
    }
`;

import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { H3OpenSansRegular } from '../../../../../shared/ui/TextStyles';
import { MapSettingsTab } from './MapSettingsTab';
import { RolesTab } from './RolesTab/RolesTab';
import { UserRole } from '../../../../../shared/ui/RolesSettingsComponents/UserRole';

export const MAP_SETTINGS_TAB = 'Map settings tab';
export const ROLES_TAB = 'Roles tab';

/** Styled body for modal content */
const ModalBody = styled.div`
    width: min(840px, calc(100vw - 30px));
    height: fit-content;
    max-height: min(710px, calc(100vh - 40px));
    padding: 20px 80px 20px;

    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    overflow: auto;
`;

const TitleBar = styled.div`
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const SwitchHeadersContainer = styled.div`
    display: flex;
    gap: 10px;
`;

const H3OpenSansStyled = styled(H3OpenSansRegular)`
    font-size: 20px;
    font-weight: ${(props) => (props.isSelected ? 600 : 400)};
    cursor: pointer;
`;

const TabContent = styled.div`
    flex: 1;
    display: flex;
    overflow: hidden;
`;

/**
 * Component represents modal window with project info and setting
 * @component
 */
export const ProjectSettingsModal = ({ tab = MAP_SETTINGS_TAB, onClose }) => {
    const [selectedTab, setSelectedTab] = useState(tab);

    return (
        <ModalBody>
            <TitleBar>
                <SwitchHeadersContainer>
                    <H3OpenSansStyled
                        isSelected={selectedTab === MAP_SETTINGS_TAB}
                        onClick={() => setSelectedTab(MAP_SETTINGS_TAB)}
                    >
                        Настройка карты
                    </H3OpenSansStyled>
                    <H3OpenSansStyled
                        isSelected={selectedTab === ROLES_TAB}
                        onClick={() => setSelectedTab(ROLES_TAB)}
                    >
                        Редактирование ролей
                    </H3OpenSansStyled>
                </SwitchHeadersContainer>
                <UserRole />
            </TitleBar>
            <TabContent>
                {selectedTab === MAP_SETTINGS_TAB && (
                    <MapSettingsTab onClose={onClose} />
                )}
                {selectedTab === ROLES_TAB && <RolesTab onClose={onClose} />}
            </TabContent>
        </ModalBody>
    );
};

ProjectSettingsModal.propTypes = {
    tab: PropTypes.oneOf([MAP_SETTINGS_TAB, ROLES_TAB]),

    /** Close handler */
    onClose: PropTypes.func,
};

import styled from 'styled-components';

import rectangleWall from '../../../../../../shared/assets/images/tools/ConstructorPage/rectangle-wall.svg';
import ruler from '../../../../../../shared/assets/images/tools/ConstructorPage/ruler.svg';
import rulerHorizontal from '../../../../../../shared/assets/images/tools/ConstructorPage/ruler-horizontal.svg';
import rulerVertical from '../../../../../../shared/assets/images/tools/ConstructorPage/ruler-vertical.svg';
import rulerAny from '../../../../../../shared/assets/images/tools/ConstructorPage/ruler-any.svg';
import wall from '../../../../../../shared/assets/images/tools/ConstructorPage/create-wall.svg';
import mapObject from '../../../../../../shared/assets/images/tools/ConstructorPage/add-map-object.svg';
import comment from '../../../../../../shared/assets/images/tools/ConstructorPage/add-note.svg';
import addRoute from '../../../../../../shared/assets/images/tools/ConstructorPage/add-route.svg';
import zone from '../../../../../../shared/assets/images/tools/ConstructorPage/zone.svg';
// import selector from '../../../../../../shared/assets/images/tools/ConstructorPage/select2.svg';
// import magnet from '../../../../../../shared/assets/images/tools/ConstructorPage/magnet.svg';
// import addSize from '../../../../../../shared/assets/images/tools/ConstructorPage/add-size.svg';
// import mergeZones from '../../../../../../shared/assets/images/tools/ConstructorPage/merge-zones.svg';

/**
 * Constant object that represents tool types
 */
export enum Tools {
    wall = 'wall',
    squareRoom = 'squareRoom',
    rectangleRoom = 'rectangleRoom',
    addMapObject = 'addMapObject',
    mergeZones = 'mergeZones',
    ruler = 'ruler',
    rulerAny = 'ruler',
    rulerHorizontal = 'rulerHorizontal',
    rulerVertical = 'rulerVertical',
    zone = 'zone',
    virtualWall = 'virtualWall',
    magnet = 'magnet',
    selector = 'selector',
    embeddedObject = 'embeddedObject',
    comment = 'comment',
    addTeleport = 'addTeleport',
    none = 'none',
}

/**
 * Constant object that represents drag tool types
 */
export enum DragTools {
    hand = 'hand',
    cursor = 'cursor',
}

export enum Constraints {
    dimension = 'dimension',
    route = 'route',
}

export enum ToolIconType {
    tool = 'tool',
    dropDownTool = 'dropDownTool',
}

/**
 * Styled tool img tag
 */
export const ToolImage = styled.img<{ active: boolean }>`
    background-color: ${(props) =>
        props.active ? '#E1E8FF80' : 'transparent'};
    border: solid 1px ${(props) => (props.active ? '#5076FD' : 'transparent')};
    height: 32px;
    padding: 6px;
    cursor: pointer;
`;

/** Interface that describes object for tool from toolbar */
export interface ToolFromList {
    /** Tool name */
    name: Tools | Constraints;

    /** Tooltip for tool */
    alt: string;

    /** Tool name */
    title: string;

    /** Image src */
    src: string;
}

/** List with groups of tools for toolbar */
export const toolsList: ToolFromList[][] = [
    [
        {
            name: Tools.wall,
            alt: 'стена',
            title: 'создание стены',
            src: wall,
        },
        {
            name: Tools.rectangleRoom,
            alt: 'прямоугольная стены',
            title: 'создание прямоугольной стены',
            src: rectangleWall,
        },
        {
            name: Tools.addMapObject,
            alt: 'добавить объект',
            title: 'добавить объект',
            src: mapObject,
        },
    ],
    [
        {
            name: Tools.zone,
            alt: 'зона',
            title: 'создание зоны',
            src: zone,
        },
        // {
        //     name: Tools.mergeZones,
        //     alt: 'объединение зон',
        //     title: 'объединение зон',
        //     src: mergeZones,
        // },
        // {
        //     name: Tools.selector,
        //     alt: 'инструмент выделения',
        //     title: 'выделение стен',
        //     src: selector,
        // },
    ],
    [
        {
            name: Constraints.route,
            alt: 'инструмент построения маршрута',
            title: 'построить маршрут',
            src: addRoute,
        },
        // {
        //     name: Constraints.dimension,
        //     alt: 'инструмент создания размера',
        //     title: 'добавить размер',
        //     src: addSize,
        // },
    ],
];

export const bottomToolsList = [
    {
        type: ToolIconType.tool,
        name: Tools.comment,
        alt: 'комментарии',
        title: 'добавление комментариев',
        src: comment,
        width: '29px',
    },
    // {
    //     type: ToolIconType.tool,
    //     name: Tools.magnet,
    //     alt: 'магнит',
    //     title: 'магнит',
    //     src: magnet,
    //     width: '24px',
    // },
    {
        type: ToolIconType.dropDownTool,
        name: Tools.ruler,
        mainIconSrc: ruler,
        mainIconAlt: 'линейки',
        width: '40px',
        tools: [
            {
                type: ToolIconType.tool,
                name: Tools.rulerHorizontal,
                alt: 'линейка',
                title: 'измерение длин линейкой по горизонтали',
                src: rulerHorizontal,
                width: '40px',
            },
            {
                type: ToolIconType.tool,
                name: Tools.rulerVertical,
                alt: 'линейка',
                title: 'измерение длин линейкой по вертикали',
                src: rulerVertical,
                width: '40px',
            },
            {
                type: ToolIconType.tool,
                name: Tools.rulerAny,
                alt: 'линейка',
                title: 'измерение длин линейкой по всем направлениям',
                src: rulerAny,
                width: '40px',
            },
        ],
    },
];

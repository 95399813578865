import { makeVar } from '@apollo/client';
import { Bot } from './Bot';

/**
 * @typedef {import('./Bot').Bot} Bot
 * @typedef {import('./BotAdapter').BotData} BotData
 */

/**
 * @callback BotsMapVar
 * @param {Map<string, Bot>}
 * @return {Map<string, Bot>}
 */

/**
 * @class
 * Class that stored all bots connected to project.
 * Use this class to add or delete bots.
 *
 * If you need to modify concrete bot -> get its instance (e.g. with getBot() method)
 * and modify using Bot methods
 */
class BotModerator {
    constructor() {
        /**
         * Var for storing all bots.
         * Key - bot UUID.
         * Value - bot instance
         * @type {BotsMapVar}
         */
        this.botsMapVar = makeVar(new Map());
    }

    /**
     * Method for getting bot by its id
     * @param {string} botId bot UUID
     * @returns {Bot} bot instance
     */
    getBot(botId) {
        return this.botsMapVar().get(botId);
    }

    /**
     * Method for adding bot to manager and creating Bot instance
     * @param {string} botId bot UUID
     * @param {BotData} [properties={}] extra properties
     * @returns {Bot} created bot
     */
    addBot(botId, properties = {}) {
        const newBot = new Bot(botId, properties);
        this.botsMapVar(new Map(this.botsMapVar().set(botId, newBot)));
        return newBot;
    }

    /** Method for cleaning all storing data */
    clear() {
        this.botsMapVar(new Map());
    }
}

const botsModerator = new BotModerator();
export default botsModerator;

import { gql } from '@apollo/client';

export const CREATE_PROJECT = gql`
    mutation createProject($projectDraft: ProjectDraft!) {
        createProject(projectDraft: $projectDraft) {
            id
            name
            description
        }
    }
`;

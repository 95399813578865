import React from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import routesModerator from '../../../../../features/Route/model/RoutesModerator';
import { RouteAsItem } from './RouteAsItem';

/**
 * Component that represents list of routes, that belongs to concrete constraint group
 * @component
 */
export const RoutesList = ({ groupId, containerRef }) => {
    useReactiveVar(routesModerator.routesMapVar);
    const routes = routesModerator.getRoutesByConstraintGroup(groupId);

    return routes.map((route) => (
        <RouteAsItem
            key={route.routeId}
            route={route}
            containerRef={containerRef}
        />
    ));
};

RoutesList.propTypes = {
    /** Constraint group UUID */
    groupId: PropTypes.string,

    /** Reference to scroll container */
    containerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
};

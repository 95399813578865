import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { PointsForContextMenu } from '../styles/PointsForContextMenu';
import { closeContextMenu } from '../../model/CloseContextMenu';
import { QUERY_STATES } from '../../../AccessGroup/api/AccessGroupsAPI';
import loaderImg from '../../../../shared/assets/images/icons/preloader.svg';
import constraintGroupsAPI from '../../../Constraints/api/ConstraintGroupsAPI';
import constraintGroupsModerator from '../../../Constraints/model/ConstraintGroupsModerator';

/**
 * Constraint group context menu
 * @component
 */
export const ConstraintGroupContextMenu = ({
    constrGroupId,
    onClose = () => {},
}) => {
    const deleteState = useReactiveVar(constraintGroupsAPI.groupDeletingState);

    useState(() => {
        if (deleteState !== QUERY_STATES.IDLE) {
            constraintGroupsAPI.groupDeletingState(QUERY_STATES.IDLE);
        }
    }, []);

    /** Function to close context menu */
    const closeCM = () => {
        onClose();
        closeContextMenu();
    };

    /** Handler for 'delete' click */
    const handleDelete = async () => {
        await constraintGroupsModerator.deleteConstrGr(constrGroupId);
        closeCM();
    };

    const menu = [
        {
            text: 'Удалить',
            onClick: handleDelete,
            icon: deleteState === QUERY_STATES.LOADING ? loaderImg : undefined,
        },
    ];

    return <PointsForContextMenu menu={menu} />;
};

ConstraintGroupContextMenu.propTypes = {
    /** Constraint group UUID */
    constrGroupId: PropTypes.string,

    /**
     * Custom close handler
     * @type {() => void}
     */
    onClose: PropTypes.func,
};

const { contextMenuVar, stageScaleVar, stagePositionVar } = require("../../../shared/model/cache/Cache");

/** Minimal distance between browser window borders and context menu element in pixels */
const DISTANCE_FROM_CONTEXT_MENU_TO_WINDOW_BORDERS = 15;

const DISTANCE_FROM_CONTEXT_MENU_TO_BOTTOM_TOOLS = 100;

/**
 * Function for change context menu position if it is outside the window.
 * 
 * @param {HTMLElement} component HTML element with context menu
 * 
 * @param {object} contextMenu Value of contextMenuVar()
 * @param {JSX.Element} contextMenu.currentComponent
 * @param {boolean} contextMenu.isOpened
 * @param {boolean} contextMenu.isClosable
 * @param {object} contextMenu.position
 * @param {number} contextMenu.position.x
 * @param {number} contextMenu.position.y
 * @param {object} contextMenu.drawPosition
 * @param {number} contextMenu.drawPosition.x
 * @param {number} contextMenu.drawPosition.y
 * @see contextMenuVar
 */
export const changeContextMenuPosition = (component, contextMenu) => {
    if (!component || !contextMenu.currentComponent) {
        return;
    }

    const elemWidth = component.offsetWidth;
    const elemHeight = component.offsetHeight;
    const { x: mouseClickX, y: mouseClickY } = contextMenu.position;
    const headerHeight = document.getElementById('topBarContainer').offsetHeight;

    const windowWidth = Math.max(
        document.body.scrollWidth,
        document.body.offsetWidth,
        document.body.clientWidth,
        document.documentElement.scrollWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
    );

    const windowHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.body.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight,
        document.documentElement.clientHeight
    );

    // y coordinate of top screen border
    const topBorder = -stagePositionVar().y / stageScaleVar()
        + DISTANCE_FROM_CONTEXT_MENU_TO_WINDOW_BORDERS;

    // x coordinate of left screen border
    const leftBorder = -stagePositionVar().x / stageScaleVar()
        + DISTANCE_FROM_CONTEXT_MENU_TO_WINDOW_BORDERS;

    let newY, newX; // new context menu coordinates
    
    // calculate new y coordinate for context menu
    if (mouseClickY < topBorder) {
        // set the context menu at the top of the screen
        newY = topBorder;
    } else {
        // calculates the lowest possible context menu coordinate
        newY = (
            windowHeight - headerHeight - stagePositionVar().y - elemHeight
            - DISTANCE_FROM_CONTEXT_MENU_TO_WINDOW_BORDERS - DISTANCE_FROM_CONTEXT_MENU_TO_BOTTOM_TOOLS
        ) / stageScaleVar();

        // if current Y coordinate is higher, than choose current coordinate
        if (mouseClickY < newY) {
            newY = mouseClickY;
        }
    }

    // calculate new x coordinate for context menu
    if (mouseClickX < leftBorder) {
        // set the context menu at the left of the screen
        newX = leftBorder;
    } else {
        // calculates the rightmost available context menu coordinate
        newX = (
            windowWidth - stagePositionVar().x - elemWidth
            - DISTANCE_FROM_CONTEXT_MENU_TO_WINDOW_BORDERS
        ) / stageScaleVar();

        // if current x coordinate is more to the left, than choose current coordinate
        if (mouseClickX < newX) {
            newX = mouseClickX;
        }
    }

    if (newY !== contextMenu.drawPosition.y || newX !== contextMenu.drawPosition.x) {
        contextMenuVar({
            ...contextMenuVar(),
            drawPosition: {
                x: newX,
                y: newY,
            },
        });
    }
};

import React from 'react';
import packageJson from '../../../package.json';
import styled from 'styled-components';

const StyleVersionWatermark = styled.span`
    bottom: 10px;
    left: 10px;
    color: gray;
    position: fixed;
    z-index: 999;
`;

export const VersionWatermark = () => {
    return (
        <StyleVersionWatermark>v{packageJson.version}</StyleVersionWatermark>
    );
};

import { client } from '../../../shared/graphql/ApolloClient';
import { PROJECT_CHANGES_UPDATES } from '../../../shared/graphql/subscriptions/ProjectChangesSubscription';
import { SESSION_TOKEN } from '../../../shared/lib/LocalStorageConstants';
import {
    currentFloorVar,
    currentProjectVar,
} from '../../../shared/model/cache/Cache';
import alertsModerator from '../../Alert/model/AlertsModerator';
import { updateAlertBySubscription } from '../../Alert/model/UpdateAlertBySubscription';
import { handleConstraintGroupSubscription } from '../../Constraints/api/SubscriptionHandler';
import constraintGroupsModerator from '../../Constraints/model/ConstraintGroupsModerator';
import {
    updateSchemaOnMapObject,
    updateSchemaOnObject,
} from '../../GraphicSchema/lib/UpdateSchemaOnObject';
import {
    handleRouteSegmentSubscription,
    handleRouteSubscription,
} from '../../Route/api/SubscriptionHandler';
import routesModerator from '../../Route/model/RoutesModerator';

/** Dictionary with subscription operations types */
export const OPERATION = {
    CREATE: 'Create',
    UPDATE: 'Update',
    DELETE: 'Delete',
};

/** Describe types comes from subscription */
export const TYPES = {
    VersionedItem: 'VersionedItem',
    Item: 'Item',
    WallSegment: 'WallSegment',
    MapObject: 'MapObject',
    Zone: 'Zone',
    ConstraintGroup: 'ConstraintGroup',
    Route: 'Route',
    RouteSegment: 'RouteSegment',
    AttentionMarker: 'AttentionMarker',
    MapinsSchemaImpl: 'MapinsSchemaImpl',
};

/**
 * Function to delete income object
 * @param {string} operation operation type (create, delete, update)
 * @param {{id: string, typename: string}} serverData data from server
 */
const handleDelete = (operation, serverData) => {
    if (operation !== OPERATION.DELETE) {
        return; // handler only for delete operation
    }

    switch (serverData.typename) {
        case TYPES.Route:
            routesModerator.removeRouteFromManager(serverData.id);
            break;

        case TYPES.ConstraintGroup:
            constraintGroupsModerator.removeConstrGrFromManager(serverData.id);
            break;

        case TYPES.AttentionMarker:
            alertsModerator.removeAlertFromModerator(serverData.id);
            break;

        default:
            console.warn(
                `Subscription received unhandled delete operation for type '${serverData.typename}'`
            );
            break;
    }
};

/**
 * @class
 * Class to manage subscriptions and handle data that comes from subscription
 */
class SubscriptionsModerator {
    /** Var to store project subscription */
    projectSubscription;

    /**
     * Method to make subscription for project
     */
    async subscribeToProjectChanges() {
        const observer = client.subscribe({
            query: PROJECT_CHANGES_UPDATES,
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'network-only',
            variables: {
                floorId: currentFloorVar().id,
                token: localStorage.getItem(SESSION_TOKEN),
                projectId: currentProjectVar().id,
            },
        });

        // add handler for incoming data
        this.projectSubscription = observer.subscribe(({ data }) => {
            const operation = data.projectChanges.operation;
            const item = data.projectChanges.item;
            switch (item.__typename) {
                case TYPES.Item:
                case TYPES.VersionedItem:
                    handleDelete(operation, item);
                    break;

                case TYPES.MapObject:
                    updateSchemaOnMapObject(item);
                    break;

                case TYPES.ConstraintGroup:
                    handleConstraintGroupSubscription(operation, item);
                    break;

                case TYPES.Route:
                    handleRouteSubscription(operation, item);
                    break;

                case TYPES.RouteSegment:
                    handleRouteSegmentSubscription(operation, item);
                    break;

                case TYPES.AttentionMarker:
                    updateAlertBySubscription(operation, item);
                    break;

                case TYPES.MapinsSchemaImpl: {
                    updateSchemaOnObject(item.item);
                    break;
                }

                default:
                    console.warn(
                        `Subscription received unhandled '${operation}' operation for type '${item.__typename}'`
                    );
                    break;
            }
        });
    }

    /**
     * Method to clear stored data
     */
    clear() {
        if (this.projectSubscription) {
            this.projectSubscription.unsubscribe();
        }
    }
}

const subscriptionsModerator = new SubscriptionsModerator();
export default subscriptionsModerator;

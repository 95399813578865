import { makeVar } from '@apollo/client';
import { botToBotData } from './BotAdapter';

/**
 * @typedef {import('../../../shared/typedefs/typedefs').StringReactiveVar} StringReactiveVar
 * @typedef {import('./BotAdapter').BotData} BotData
 */

/**
 * @class
 * Bot is a class for interaction with one concrete bot.
 * Use this class to modify bot properties such as: name, description, etc.
 */
export class Bot {
    /**
     * @constructor
     * @param {string} id bot uuid
     * @param {BotData} extra bot properties
     */
    constructor(id, { name = '', description = '' }) {
        /**
         * Bot UUID
         * @type {string}
         */
        this.id = id;

        /**
         * Bot name
         * @type {StringReactiveVar}
         */
        (this.name = makeVar(name));

        /**
         * Bot description
         * @type {StringReactiveVar}
         */
        this.description = makeVar(description);
    }

    /**
     * Method to get all stored bot properties
     * @returns {BotData}
     */
    getBotData() {
        return botToBotData(this);
    }
}

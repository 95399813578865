import {
    FormFieldTypes,
    SchemaFieldBaseTypes,
    StringFormats,
} from '../lib/SchemaSpecifier';
import ValidatorFactory from '../lib/ValidatorFactory';

/**
 * Function for deleting property from schema extended properties
 * @param {import('../../../shared/model/cache/Cache').GraphicSchema} schema
 * @param {string} propertyName
 * @return {object} edited schema
 */
export const deleteExtendedProperty = (schemaRef, propertyName) => {
    const newSchema = schemaRef.current.copy();
    newSchema.schema.objectExtendablePropsSchema.fields =
        newSchema.schema.objectExtendablePropsSchema.fields.filter(
            (item) => item.key !== propertyName
        );
    return newSchema;
};

/**
 * Function for changing property value for schema extended properties
 * @param {import('../../../shared/model/cache/Cache').GraphicSchema} schema
 * @param {string} propertyKey
 * @param {string} value new property value
 * @return {object} edited schema
 */
export const changeExtendedProperty = (schemaRef, propertyKey, value) => {
    const newSchema = schemaRef.current.copy();
    const field = newSchema.schema.objectExtendablePropsSchema.fields.find(
        (item) => item.key === propertyKey
    );
    field.currentValue.value = value;
    return newSchema;
};

/**
 * Function for changing property value for schema bot properties
 * @param {import('../../../shared/model/cache/Cache').GraphicSchema} schema
 * @param {string} schemaImplId
 * @param {string} propertyKey
 * @param {string} value new property value
 * @return {object} edited schema
 */
export const changeBotProperty = (
    schemaRef,
    schemaImplId,
    propertyKey,
    value
) => {
    const newSchema = schemaRef.current.copy();
    const schemaImpl = newSchema.schema.objectBotPropsSchemas.find(
        (item) => item.schemaImplId === schemaImplId
    );
    const field = schemaImpl.fields.find((item) => item.key === propertyKey);
    field.currentValue.value = value;
    return newSchema;
};

const fieldTypes = new Map([
    ['Строка', SchemaFieldBaseTypes.string],
    ['Дата', SchemaFieldBaseTypes.string],
    ['Время', SchemaFieldBaseTypes.string],
    ['Число', SchemaFieldBaseTypes.number],
]);

/**
 * Function for adding new property to schema extended properties
 * @param {import('../../../shared/model/cache/Cache').GraphicSchema} schema
 * @param {string} propertyName
 * @param {string} propertyType
 * @return {object | null} schema with new property or null if property already exists
 */
export const addNewExtendedProperty = (
    schemaRef,
    propertyName,
    propertyType
) => {
    if (
        !schemaRef.current.schema.objectExtendablePropsSchema.fields.find(
            (item) => item.name === propertyName
        )
    ) {
        const newSchema = schemaRef.current.copy();

        const [changeValidate, saveValidate] =
            ValidatorFactory.createStringValidator();

        const stringFormat =
            propertyType === 'Дата'
                ? StringFormats.date
                : propertyType === 'Время'
                ? StringFormats.time
                : StringFormats.string;

        let validatorProps = null;
        let defaultValue = null;
        let currentValue = null;
        if (fieldTypes.get(propertyType) === SchemaFieldBaseTypes.string) {
            defaultValue = '';
            let toSchemaFormat = (value) => {
                return value;
            };
            if (stringFormat === StringFormats.date) {
                defaultValue = '2000-01-01';
                toSchemaFormat = (value) => {
                    const [year, month, day] = value.split('-');
                    return `${day}.${month}.${year}`;
                };
            } else if (stringFormat === StringFormats.time) {
                defaultValue = '00:00';
            }
            currentValue = {
                value: defaultValue,
                toString() {
                    return this.value;
                },
                toSchemaFormat() {
                    return toSchemaFormat(this.value);
                },
            };
            validatorProps = {
                limit: 500,
                format: stringFormat,
            };
        } else if (
            fieldTypes.get(propertyType) === SchemaFieldBaseTypes.number
        ) {
            defaultValue = 0;
            currentValue = {
                value: defaultValue,
                toString() {
                    return `${this.value}`;
                },
                valueOf() {
                    return this.value;
                },
                toSchemaFormat() {
                    return Number(this.value);
                },
            };
            validatorProps = {
                from: 0.0,
                to: 100.0,
                precision: 2,
                step: 1.0,
            };
        }

        newSchema.schema.objectExtendablePropsSchema.fields.push({
            changeValidate: changeValidate,
            saveValidate: saveValidate,
            enumSet: [],
            format: (value) => value,
            isRequired: true,
            key: propertyName,
            name: propertyName,
            stringFormat:
                fieldTypes.get(propertyType) === SchemaFieldBaseTypes.string
                    ? stringFormat
                    : null,
            valueType: fieldTypes.get(propertyType),
            type: FormFieldTypes.input,
            validatorProps: validatorProps,
            defaultValue: defaultValue,
            currentValue: currentValue,
        });

        return newSchema;
    } else {
        return null;
    }
};

import { gql } from '@apollo/client';

export const CONSTRAINT_GROUP_FRAGMENT = gql`
    fragment ConstraintGroupFragment on ConstraintGroup {
        id
        version
        name
        description
    }
`;

import { UPDATE_ZONE } from '../../../shared/graphql/mutations/zones/UpdateZone';
import { CUT_ZONE } from '../../../shared/graphql/mutations/zones/CutZone';
import { GET_ZONE } from '../../../shared/graphql/queries/zones/GetZone';
import { client } from '../../../shared/graphql/ApolloClient';
import { MERGE_ZONE } from '../../../shared/graphql/mutations/zones/MergeZone';
import { GET_ZONE_ACCESS_RULES } from '../../../shared/graphql/queries/zones/GetZoneAccessRules';

/**
 * @typedef {import('../../Wall/api/WallSegmentsAPI').VersionedSegmentSelector} VersionedSegmentSelector
 * @typedef {import('../../Wall/api/WallSegmentsAPI').WallSegment} WallSegment
 */

/**
 * @typedef {{
 *  color: String,
 *  name:String
 * }} ZoneDraft
 */

/**
 * @typedef {{
 *  color: String!,
 *  id: String!,
 *  name: String!,
 *  global: Boolean!,
 *  accessRules: [AccessRule]!,
 *  wallSegments: [WallSegment]!,
 *  parentZone: Zone,
 *  mapObjects: [MapObject]!,
 *  floor: Floor!,
 *  wall: Wall!,
 *  schemaImpls: [MapinsSchemaImpl]!,
 * }} Zone
 */

/**
 * @typedef {{
 *  donorZoneSelector: VersionedZoneSelectorDto,
 *  newZoneDraft: ZoneDraft,
 *  wallSegmentsSelectors: VersionedSegmentSelector[]
 * }} ZoneCutProps
 */

/**
 * @typedef {{
 *  id: String,
 *  version: number
 * }} VersionedZoneSelectorDto
 */

/**
 * @typedef {{id: String, color: String, name: String, version: NumberF}} ZoneDiff
 */

/**
 * @class
 */
class ZonesAPI {
    /**
     * @param {String} id of zone
     */
    async get(id) {
        let zone = null;
        const options = {
            query: GET_ZONE,
            variables: {
                id: id,
            },
        };

        try {
            zone = (await client.query(options)).data.zone;
        } catch (error) {
            throw new Error(error.message);
        }

        return zone;
    }

    /**
     * Method to get access rules for zone
     * @param {String} id of zone
     * @returns {}
     */
    async getAccessRules(id) {
        let zone = null;
        const options = {
            query: GET_ZONE_ACCESS_RULES,
            variables: {
                id: id,
            },
            fetchPolicy: 'network-only',
        };

        try {
            zone = (await client.query(options)).data.zone.accessRules;
        } catch (error) {
            throw new Error(error.message);
        }

        return zone;
    }

    /**
     *
     * @param {VersionedZoneSelectorDto} id
     * @param {VersionedZoneSelectorDto} inheritableZoneId
     */
    async merge(zoneSelector, inheritableZoneSelector) {
        let zone = null;
        const options = {
            mutation: MERGE_ZONE,
            variables: {
                zoneSelector,
                inheritableZoneSelector,
            },
        };

        try {
            zone = (await client.mutate(options)).data.mergeZone.updatedZone;
        } catch (error) {
            throw new Error(error.message);
        }

        return zone;
    }

    /**
     * Method creates zone cutting from donor zone
     * @param {ZoneCutProps} zoneCutProps
     * @returns {Zone}
     */
    async cut(zoneCutProps) {
        const options = {
            mutation: CUT_ZONE,
            variables: zoneCutProps,
        };

        try {
            const response = (await client.mutate(options)).data.cutZone;
            return { newZone: response.newZone, donorZone: response.baseZone };
        } catch (error) {
            throw new Error(error.message);
        }
    }

    /**
     * @param {ZoneDiff} zoneDiff
     */
    async update(zoneDiff) {
        let zone = null;
        const options = {
            mutation: UPDATE_ZONE,
            variables: {
                zoneDiff: zoneDiff,
            },
        };

        try {
            zone = (await client.mutate(options)).data.updateZone;
        } catch (error) {
            throw new Error(error.message);
        }

        return zone;
    }
}

const zonesAPI = new ZonesAPI();
export default zonesAPI;

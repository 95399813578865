import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useOutsideClick } from '../../../../../../shared/model/hooks/UseOutsideClick';

const TitleInput = styled.input`
    height: 80%;
    width: 100%;
    border: none;
    background-color: transparent;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #2f2f2f;
    overflow: hidden;
    white-space: nowrap;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.8);
    :focus {
        outline: none;
    }
`;

/**
 * Component that replaces OpeningGroup title part with input
 * @component
 */
export const HeaderEditInput = ({
    title,
    setIsTitleEditing,
    onTitleEditFinish,
}) => {
    const [inputText, setInputText] = useState(title);

    const componentRef = useRef();

    useOutsideClick(componentRef, () => {
        if (onTitleEditFinish) {
            onTitleEditFinish(inputText);
        }
        setIsTitleEditing(false);
    });

    const handleEditTitle = (e) => {
        setInputText(e.target.value);
    };

    return (
        <TitleInput
            value={inputText}
            onChange={handleEditTitle}
            ref={componentRef}
        />
    );
};

HeaderEditInput.propTypes = {
    /** Title to edit */
    title: PropTypes.string,

    /** Sets state - user is editing */
    setIsTitleEditing: PropTypes.func,

    /**
     * Callback function for finish editing group title
     * @type {(newTitle: string) => void}
     */
    onTitleEditFinish: PropTypes.func,
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import arrowDownImg from './../../../shared/assets/images/icons/ConstructorPage/GraphicSchema/arrow-down.svg';
import arrowUpImg from './../../../shared/assets/images/icons/ConstructorPage/GraphicSchema/arrow-up.svg';
import { H3OpenSansRegular } from '../../../shared/ui/TextStyles';
import { PropertiesGrid } from './PropertiesGrid';

const BotPropertiesWrapper = styled.div`
    margin-top: 20px;
`;

const Title = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 21px;
    cursor: pointer;
`;

const ArrowImg = styled.img`
    width: 8.64px;
    height: auto;
    cursor: pointer;
`;

const HrStyled = styled.hr`
    margin: 0;
`;

/**
 * @param {boolean} isEditMode
 */
const BotPropertiesContainer = styled.div`
    margin-top: 10px;
    margin-right: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 5px;
    align-items: center;
`;

/**
 * Component represents properties for graphic schema received from bot
 * @component
 */
export const BotProperties = ({ name, fields }) => {
    const [isOpened, setIsOpened] = useState(true);

    return (
        <BotPropertiesWrapper>
            <Title onClick={() => setIsOpened(!isOpened)}>
                <H3OpenSansRegular>{name ? name : 'Bot Properties'}</H3OpenSansRegular>
                <ArrowImg
                    src={isOpened ? arrowDownImg : arrowUpImg}
                    alt={isOpened ? 'hide' : 'open'}
                />
            </Title>
            <HrStyled />
            {isOpened && (
                <BotPropertiesContainer>
                    <PropertiesGrid fields={fields} />
                </BotPropertiesContainer>
            )}
        </BotPropertiesWrapper>
    );
};

BotProperties.propTypes = {
    /** Values for schema */
    schema: PropTypes.shape({
        // key and value that are displayed in the component
        data: PropTypes.objectOf(
            PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        ),
        id: PropTypes.string,
        itemId: PropTypes.string,

        schema: PropTypes.object, // here is a description of the schema
    }),

    /** is schema in edit mode */
    isEditMode: PropTypes.bool,

    /** function for handle change property */
    handleChange: PropTypes.func,
};

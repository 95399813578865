import React, { Fragment, useCallback, useContext } from 'react';
import styled from 'styled-components';
import deleteIcon from './../../../shared/assets/images/icons/ConstructorPage/GraphicSchema/delete.svg';
import {
    POpenSansLight,
    POpenSansRegular,
} from '../../../shared/ui/TextStyles';
import { PropertyInEditMode } from './PropertyInEditMode';
import { ExtendablePropertiesContext } from '../GraphicSchema';

const DeleteIcon = styled.img`
    margin-inline: 9px 21px;
    width: 11px;
    height: auto;
    cursor: pointer;
`;

const PropertyName = POpenSansLight;
const NonEditablePropertyValue = POpenSansRegular;

/**
 * @typedef {import('../lib/SchemaSpecifier').SchemaFormField} SchemaFormField
 */

const PropertyValue = ({ field, onDelete, isEditMode }) => {
    const onDeleteCallback = useCallback(() => {
        onDelete.bind({}, field.key);
    }, [field.key, onDelete]);

    return (
        <>
            {isEditMode ? (
                <>
                    <PropertyInEditMode
                        field={field}
                        context={ExtendablePropertiesContext}
                    />
                    <DeleteIcon
                        src={deleteIcon}
                        alt='delete'
                        onClick={onDeleteCallback}
                    />
                </>
            ) : (
                <NonEditablePropertyValue>
                    {field.currentValue.toSchemaFormat()}
                </NonEditablePropertyValue>
            )}
        </>
    );
};

/**
 * Component that represents grid with editable properties
 * @component
 * @param {{
 *  fields: SchemaFormField[],
 *  isEditMode: Boolean,
 *  handleChange: Function,
 *  isDeleteIconNeed: Boolean,
 *  handleDelete: Function,
 * }}
 */
export const EditablePropertiesGrid = () => {
    const { fields, isEditMode, onDelete } = useContext(
        ExtendablePropertiesContext
    );

    return fields?.map((field) => (
        <Fragment key={field.key}>
            <PropertyName>{field.name}:</PropertyName>
            <PropertyValue
                field={field}
                onDelete={onDelete}
                isEditMode={isEditMode}
            />
        </Fragment>
    ));
};

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
    currentFloorVar,
    floorsVar,
    modeVar,
} from '../../../../../shared/model/cache/Cache';
import { useMutation, useReactiveVar } from '@apollo/client';
import removeFloorIcon from '../../../../../shared/assets/images/icons/ConstructorPage/TopBar/remove-floor-minus.svg';
import { CREATING_MODE } from '../../../../../shared/lib/utils/ModeDefaultValues';
import { DELETE_FLOOR } from '../../../../../shared/graphql/mutations/floors/DeleteFloor';

const SelectItem = styled.li`
    padding: 0;
    margin: 0;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 5px;
    padding-left: 14px;
    border-bottom: 0.5px solid #6c6c6c;
    display: flex;
    align-items: center;

    :hover {
        background-color: #e1e8ff;
    }
`;

const RemoveFloorIcon = styled.img`
    background-color: #feb7bb80;
    width: 7px;
    height: 7px;
    flex-shrink: 0;
    padding: 4px;
    border-radius: 11px;
    margin-left: 10px;
`;

export const FloorList = ({ onFloorClick }) => {
    const floors = useReactiveVar(floorsVar);

    const [deleteFloor] = useMutation(DELETE_FLOOR);

    const handleDelete = (e) => {
        e.stopPropagation();

        deleteFloor({
            variables: {
                id: e.target.id,
            },
        })
            .then((response) => {
                // TODO: realize in future success message notification
                floorsVar([
                    ...floorsVar().filter(
                        (floor) => floor.id !== response.data.deleteFloor.id
                    ),
                ]);
                currentFloorVar({ ...floorsVar()[0] });
            })
            .catch((error) => {
                // TODO: realize in future error message notification
                console.log(error);
            });
    };

    return (
        <>
            {floors.map((floor) => {
                return floor.id === currentFloorVar().id ? null : (
                    <SelectItem
                        onClick={() => onFloorClick(floor)}
                        key={floor.id}
                    >
                        {floor.name || `${floor.number} этаж`}
                        {modeVar() === CREATING_MODE && (
                            <RemoveFloorIcon
                                id={floor.id}
                                src={removeFloorIcon}
                                alt='remove floor'
                                onClick={handleDelete}
                            />
                        )}
                    </SelectItem>
                );
            })}
        </>
    );
};

FloorList.propTypes = {
    /**
     * array of floor type
     */
    floors: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            number: PropTypes.number,
        })
    ),
    /**
     * state for current floor
     */
    currentFloor: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        number: PropTypes.number,
    }),
    /**
     * state for selecting floor
     */
    handleFloorSelect: PropTypes.func,
};

import { makeVar } from '@apollo/client';
import { deepCopy } from '../../../shared/lib/utils/common/DeepCopy';
import { currentFloorVar, mapObjectsVar } from '../../../shared/model/cache/Cache';
import mapObjectsAPI from '../api/MapObjectsAPI';

/**
 * @typedef {import('./Types').MapObjectData} MapObject
 * @typedef {import('./Types').MapObjectDraft} MapObjectDraft
 * @typedef {import('../../../shared/typedefs/Types').Point} Point
 */

/**
 * @callback TeleportCreationVar
 * @param {{startMapObject: MapObject?, destinationMapObject: MapObject?, startFloorName: string?, destinationFloorName: string?}} argument
 * @return {{startMapObject: MapObject?, destinationMapObject: MapObject?, startFloorName: string?, destinationFloorName: string?}}
 */

/**
 * @class MapObjects is a class for storing and interact with map objects
 */
class MapObjects {
    // TODO add constructor and place in this class reactive vars: mapObjectsVar, currentMenuItemVar
    // constructor() {
    //     this.mapObjectsVar = makeVar(new Map());
    // }

    constructor() {
        /**
         * Var stores ids of two objects, which should become a teleport.
         * @type {TeleportCreationVar}
         */
        this.teleportCreationVar = makeVar({
            startMapObject: null,
            startFloorName: null,
            destinationMapObject: null,
            destinationFloorName: null,
        });
    }

    adaptData(data) {
        return data.map((mapObject) => {
            const schemaImpls = mapObject?.schemaImpls?.map((schemaImpl) => {
                return { ...schemaImpl };
            });
            return {
                ...mapObject,
                schemaImpls: schemaImpls,
            };
        });
    }

    clear() {
        mapObjectsVar([]);
        this.clearTeleportCreationVar();
    }

    /**
     * Method that sets new values to reactive var with canvas items
     * @param {MapObject[]} mapObjects
     */
    setMapObjects(mapObjects) {
        mapObjectsVar([...mapObjects]);
    }

    /**
     * Get map object by its id
     * @param {string} mapObjectId UUID
     * @returns {MapObject}
     */
    get(mapObjectId) {
        return mapObjectsVar().find((mapObj) => {
            return mapObj.id === mapObjectId;
        });
    }

    getVersion(mapObjectId) {
        return this.get(mapObjectId).version;
    }

    /**
     * Method to reorder list of map objects
     * if up = true -- move mapObject to the end of list
     * else -- to the start
     * @param {MapObject} mapObject
     * @param {Boolean} [up=true]
     */
    reorder(mapObject, up = true) {
        if (up) {
            mapObjectsVar([
                ...mapObjectsVar().filter((item) => item.id !== mapObject.id),
                mapObject,
            ]);
        } else {
            mapObjectsVar([
                mapObject,
                ...mapObjectsVar().filter((item) => item.id !== mapObject.id),
            ]);
        }
    }

    /**
     * Method that creates new map object
     * @param {MapObjectDraft} newMapObject
     */
    async createMapObject(newMapObject) {
        mapObjectsAPI.create(newMapObject).then((newObj) => {
            mapObjectsVar([...mapObjectsVar(), { ...newObj }]);
        });
    }

    /**
     * Method that deletes map object
     * @param {String} mapObjectId - UUID of map object
     */
    async deleteMapObject(mapObjectId) {
        mapObjectsAPI
            .delete({ id: mapObjectId, version: this.getVersion(mapObjectId) })
            .then((result) => {
                if (result) {
                    mapObjectsVar(
                        mapObjectsVar().filter((item) => item.id !== result.id)
                    );
                }
            });
    }

    /**
     * Method for updating properties of map object
     * @param {String} mapObjectId - UUID of updated map object
     * @param {MapObject} newMapObject - object with new properties
     */
    updateMapObject(mapObjectId, newMapObject) {
        mapObjectsVar([
            ...mapObjectsVar().filter((item) => item.id !== mapObjectId),
            deepCopy(newMapObject),
        ]);
    }

    /**
     * Method that sends query to update map object position
     * @param {String} mapObjectId - UUID of map object
     * @param {Point} newPosition
     */
    async updateMapObjectPosition(mapObjectId, newPosition) {
        mapObjectsAPI
            .updatePosition(
                {
                    id: mapObjectId,
                    version: mapObjects.getVersion(mapObjectId),
                },
                newPosition
            )
            .then((newObj) => {
                this.updateMapObject(mapObjectId, newObj);
            });
    }

    /**
     * Method that sends query to update map object scale and size
     * @param {String} mapObjectId - UUID of map object
     * @param {Number} width
     * @param {Number} length
     * @param {Number} angle
     * @param {Point} position
     */
    async updateMapObjectTransformation(
        mapObjectId,
        width,
        length,
        angle,
        position
    ) {
        mapObjectsAPI
            .updateTransformation(
                {
                    id: mapObjectId,
                    version: mapObjects.getVersion(mapObjectId),
                },
                width,
                length,
                angle,
                position
            )
            .then((newObj) => {
                this.updateMapObject(mapObjectId, newObj);
            });
    }

    /**
     * Method to update Mapins schema
     * @param {String} mapObjectId UUID of updated map object
     * @param {Object} schemaData
     * @param {MapinsSchemaImpl[]} schemaImpls
     * @return {MapObject | null} Map object or null if map object doesn't exists
     */
    updateMapObjectSchema(mapObjectId, schemaData, schemaImpls) {
        const mapObject = this.get(mapObjectId);
        if (mapObject) {
            mapObject.data = deepCopy(schemaData);
            mapObject.schemaImpls = deepCopy(schemaImpls);
            this.updateMapObject(mapObjectId, mapObject);
            return mapObject;
        }
        return null;
    }

    /**
     * Set start map object and clear destination for teleport var
     * @param {String} id map object UUID
     */
    setTeleportStart(id) {
        this.teleportCreationVar({
            startMapObject: deepCopy(this.get(id)),
            startFloorName: 'Этаж ' + currentFloorVar().number,
            destinationMapObject: null,
            destinationFloorName: null,
        });
    }

    /**
     * Set destination map object for teleport var
     * @param {String} id map object UUID
     */
    setTeleportDestination(id) {
        this.teleportCreationVar({
            ...this.teleportCreationVar(),
            destinationMapObject: deepCopy(this.get(id)),
            destinationFloorName: 'Этаж ' + currentFloorVar().number,
        });
    }

    /** Method to clear teleport destination object for teleport var */
    clearTeleportDestination() {
        this.teleportCreationVar({
            ...this.teleportCreationVar(),
            destinationMapObject: null,
            destinationFloorName: null,
        });
    }

    /** Clear teleport creation var */
    clearTeleportCreationVar() {
        this.teleportCreationVar({
            startMapObject: null,
            startFloorName: null,
            destinationMapObject: null,
            destinationFloorName: null,
        });
    }
}

const mapObjects = new MapObjects();
export default mapObjects;

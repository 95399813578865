import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { POpenSansRegular } from '../TextStyles';
import accessGroupsModerator from '../../../features/AccessGroup/model/AccessGroupsModerator';

const UserRoleStyled = styled(POpenSansRegular)`
    margin: 0;
    padding: 2px 10px;
    background: #e1e8ff;
    border-radius: 6px;

    font-size: 20px;
    line-height: 27.24px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
`;

/**
 * Component represents current user role for map
 * @component
 */
export const UserRole = ({ role }) => {
    if (!role) {
        role =
            accessGroupsModerator
                ?.getGroup(accessGroupsModerator.currentAccessGroupId)
                ?.name() || '';
    }
    return <UserRoleStyled title={role}>{role}</UserRoleStyled>;
};

UserRole.propTypes = {
    /** Showing role name */
    role: PropTypes.string,
};

import React from 'react';
import styled from 'styled-components';
import { FormComponent } from './FormComponentWithHint';
import { inputStatus } from './FormConstants';

/**
 * Styled container for checkbox
 */
const Container = styled.label`
    display: flex;
    justify-content: flex-start;
`;

/**
 * Styled checkbox
 */
const CheckboxStyled = styled.input`
    width: 20px;
    height: 20px;
    margin: 1px 11px 0 0;
    border: 0.5px solid #000000;
    border-radius: 3px;
    accent-color: #023aff;
    cursor: pointer;
`;

/**
 * Styled label for checkbox
 */
const LabelStyled = styled.label`
    margin: 0;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16.8px;
    text-align: left;
    opacity: 0.7;
    cursor: pointer;
`;

/**
 * Checkbox component with validator message
 * @returns { JSX.Element } Checkbox component for forms
 */
export const Checkbox = ({
    name,
    hintMsgType = inputStatus.hint,
    hintMsg,
    children,
    className,
    ...props
}) => {
    return (
        <FormComponent
            hintMsg={hintMsg}
            hintMsgType={hintMsgType}
            className={className}
        >
            <Container>
                <CheckboxStyled
                    id={name}
                    type={'checkbox'}
                    name={name}
                    {...props}
                ></CheckboxStyled>
                <LabelStyled htmlFor={name}>{children}</LabelStyled>
            </Container>
        </FormComponent>
    );
};

import React from 'react';
import styled from 'styled-components';
import preloader from '../assets/images/icons/preloader.svg';

const PreloaderStyled = styled.img<{ loaderSize: number }>`
    height: ${({ loaderSize }) =>
        loaderSize > 0 ? loaderSize + 'px' : '100%'};
    width: auto;
`;

interface PreloaderProps {
    /**
     * Preloader width and height.
     * If value <= 0 then height=100%.
     * Default value is -1.
     */
    loaderSize?: number;
}

/**
 * Component with loading img.
 * @component
 */
export const Preloader = ({ loaderSize = -1 }: PreloaderProps) => {
    return (
        <PreloaderStyled
            src={preloader}
            alt='preloader'
            loaderSize={loaderSize}
        />
    );
};

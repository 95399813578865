import { loadFloor } from '../../../../../pages/Constructor/model/LoadFloor';
import { subtractTwoPoints } from '../../../../../shared/lib/modules/math/Math';
import { currentFloorVar } from '../../../../../shared/model/cache/Cache';
import notifications from '../../../../HintSystem/model/Notifications';
import selector from '../../../../Selector/model/Selector';
import wallSegmentsApi from '../../../api/WallSegmentsAPI';
import routesAPI from '../../../../Route/api/RoutesAPI';
import floorGraph from '../../../model/FloorGraph';

/**
 * Handler for saving updated wall segments after drag end
 */
export const handleDragEnd = (e) => {
    // all selected edges grouped by graph
    const graphs = selector.tempMapOfEdgesGroupedByGraphs;

    // call update methods for all graphs
    graphs.forEach((edgesIds, graph) => {
        /**
         * @type {import('../../../api/WallSegmentsAPI').MoveWallItemsOperation}
         */
        const moveWallItems = {
            moveVector: { x: null, y: null },
            wallSegmentSelectors: [],
        };

        const moveVector = subtractTwoPoints(
            { ...graph.dragStartPos },
            { ...e.target.position() }
        );

        moveWallItems.moveVector = moveVector;

        edgesIds.forEach((selectedEdgeId) => {
            const [firstPointId, secondPointId] = selectedEdgeId.split(',');

            moveWallItems.wallSegmentSelectors.push({
                points: {
                    firstPointId: firstPointId,
                    secondPointId: secondPointId,
                },
                version: graph.segmentsModerator
                    .getSegment(firstPointId, secondPointId)
                    .getVersion(),
            });
        });

        // TODO encapsulate logic to FloorGraph and override it in RouteGraph
        // -> same refactor need in handlePointDragEnd
        if (graph === floorGraph) {
            wallSegmentsApi
                .updateSegmentsPositions(moveWallItems)
                .then((res) => {
                    res?.forEach((segment) => {
                        graph.segmentsModerator.updateVersion(
                            segment.startPoint.id,
                            segment.endPoint.id,
                            segment.version
                        );
                    });
                })
                .catch(async (error) => {
                    notifications.setError(
                        'Не удалось сохранить изменения',
                        error.message
                    );
                    await loadFloor(currentFloorVar().id);
                    console.log(error);
                });
        } else {
            routesAPI
                .updateSegmentsPositions(moveWallItems)
                .then((res) => {
                    res?.forEach((segment) => {
                        graph.segmentsModerator.updateVersion(
                            segment.startPoint.id,
                            segment.endPoint.id,
                            segment.version
                        );
                    });
                })
                .catch(async (error) => {
                    notifications.setError(
                        'Не удалось сохранить изменения',
                        error.message
                    );
                    await loadFloor(currentFloorVar().id);
                    console.log(error);
                });
        }
    });

    selector.tempMapOfEdgesGroupedByGraphs = null; // clean temp variable
};

import React, { WheelEventHandler } from 'react';
import { Html } from 'react-konva-utils';
import { useReactiveVar } from '@apollo/client';
import styled from 'styled-components';
import { stageScaleVar } from '../../../shared/model/cache/Cache';
import yellowInfo from '../../../shared/assets/images/icons/yellow-info-warning.svg';
import info from '../../../shared/assets/images/icons/info-warning.svg';
import criticalInfo from '../../../shared/assets/images/icons/critical-warning.svg';
import { handleMouseScroll } from '../../../widgets/Canvas/lib/utils/HandleMouseScroll';
import alertsModerator from '../model/AlertsModerator';
import { AlertTypes } from '../model/Types';

interface CommentProps {
    bottom: number;
    left: number;
    scale: number;
}

const Comment = styled.div<CommentProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    cursor: pointer;
    background: #ffffff;
    border: 0.5px solid #9e9e9e;
    box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.07);
    border-radius: 16px 16px 16px 0;
    position: absolute;
    bottom: ${(props) => props.bottom + 'px'};
    left: ${(props) => props.left + 'px'};
    transform: scale(${(props) => 1 / props.scale});
    transform-origin: bottom left;
`;

const AlertImg = styled.img`
    width: 20px;
    height: 20px;
`;

/**
 * Function to define icon by alert type
 * @param type alert type
 * @returns alert icon src
 */
const getAlertIcon = (type: AlertTypes): string => {
    return type === AlertTypes.INFO
        ? info
        : type === AlertTypes.WARNING
        ? yellowInfo
        : criticalInfo;
};

/**
 * Component to create all alerts on canvas
 * @component
 */
export const CollapsedAlerts: React.FC = () => {
    const canvasComments = useReactiveVar(alertsModerator.alertsVar);
    const scaleVar: number = useReactiveVar(stageScaleVar);

    /**
     * Scroll handler
     * @param e Konva synthetic event
     */
    const handleScroll: WheelEventHandler<HTMLDivElement> = (e) => {
        // TODO handle ctrl+scroll (prevent interface resize)
        const event = { evt: e }; // emulate base event
        handleMouseScroll(event, scaleVar);
    };

    return (
        <Html>
            {Array.from(canvasComments).map(([alertId, alert]) => (
                <Comment
                    scale={scaleVar}
                    draggable={false}
                    key={alertId}
                    bottom={-alert.position.y}
                    left={alert.position.x}
                    onClick={() => alertsModerator.openAlert(alertId)}
                    onWheel={handleScroll}
                >
                    <AlertImg
                        src={getAlertIcon(alert.type)}
                        draggable={false}
                        alt='alert icon'
                    />
                </Comment>
            ))}
        </Html>
    );
};

import React from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { ListWrapper } from '../ui/Styles';
import { OpeningGroup } from '../ui/OpeningGroup/OpeningGroup';
import constraintGroupsModerator from '../../../../features/Constraints/model/ConstraintGroupsModerator';
import { ConstraintGroupFolder } from './ConstraintGroupFolder';

/**
 * Component that represents list of constraint groups
 * @component
 */
export const ConstraintsList = ({ containerRef }) => {
    const constraintGroups = useReactiveVar(
        constraintGroupsModerator.constraintGroupsVar
    );

    return (
        <ListWrapper>
            <OpeningGroup title={'Построения'} isEditable={false} isOpen={true}>
                {Array.from(constraintGroups, ([id, constrGroup]) => (
                    <ConstraintGroupFolder
                        key={id}
                        constrGroup={constrGroup}
                        containerRef={containerRef}
                    />
                ))}
            </OpeningGroup>
        </ListWrapper>
    );
};

ConstraintsList.propTypes = {
    /** Reference to scroll container */
    containerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
};

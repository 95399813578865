import { useReactiveVar } from '@apollo/client';
import React, { useEffect } from 'react';
import { Html } from 'react-konva-utils';
import { useOutsideClick } from '../../../../shared/model/hooks/UseOutsideClick';
import { useRefCallback } from '../../../../shared/model/hooks/UseRefCallback';

import { contextMenuVar, modeVar, stagePositionVar, stageScaleVar } from '../../../../shared/model/cache/Cache';
import { CREATING_MODE } from '../../../../shared/lib/utils/ModeDefaultValues';
import { closeContextMenu } from '../../model/CloseContextMenu';
import { changeContextMenuPosition } from '../../model/ChangeContextMenuPosition';

import { StyledContextMenu } from '../../styles/UIKit';
import { useKeyDown } from '../../../../shared/model/hooks/UseKeyDown';
import { ESC_KEY } from '../../../../widgets/Canvas/lib/CanvasDefaultValues';

/**
 * Component that represents a context menu for canvas elements
 * @component
 */
export const CanvasContextMenu = () => {
    const contextMenu = useReactiveVar(contextMenuVar);
    const scale = useReactiveVar(stageScaleVar);
    const stagePosition = useReactiveVar(stagePositionVar);
    const [wrapperComponentValue, wrapperComponentCallbackRef] = useRefCallback();

    // useEffect for changing context menu position if menu is out of browser window
    useEffect(() => {
        changeContextMenuPosition(wrapperComponentValue, contextMenu);

        // When inside context menu component changes layout
        // (for example, was switched submenu type)
        // wrapper component will not change and wrapper component will not rerender,
        // so we need MutationObserver to identify wrapper component changes
        // and recalculate context menu position
        const observer = new MutationObserver(() => {
            changeContextMenuPosition(wrapperComponentValue, contextMenu);
        });

        // add context menu element to the observer
        if (wrapperComponentValue) {
            observer.observe(wrapperComponentValue, { childList: true });
        }

        return () => {
            observer.disconnect();
        };
    }, [wrapperComponentValue, contextMenu, scale, stagePosition.x, stagePosition.y]);

    // first param is object with wrapperComponent value for imitating ref object
    useOutsideClick({ current: wrapperComponentValue }, () => {
        closeContextMenu();
    });

    useKeyDown(closeContextMenu, [ESC_KEY]);

    if (!contextMenu.isOpened) {
        return;
    }

    // clear context menu after switching mode
    if(modeVar() !== CREATING_MODE) {
        closeContextMenu(true);
        return;
    }

    return (
        <Html>
            <StyledContextMenu
                position={contextMenu.drawPosition}
                ref={wrapperComponentCallbackRef}
                scale={scale}
            >
                {contextMenu.currentComponent}
            </StyledContextMenu>
        </Html>
    );
};

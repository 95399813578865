import { css } from 'styled-components';

/**
 * Prepared styles for scrollbar.
 * Use it with styled components.
 *
 * @example
 * const BigContainer = styled.div`
 *     // your styles
 *     ${SCROLLBAR_STYLED}
 * `;
 */
export const SCROLLBAR_STYLED = css`
    scrollbar-gutter: stable;

    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: white;
        border-radius: 4px;
    }

    ::-webkit-scrollbar-track {
        background-color: #efefef;
        border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #d9d9d9;
        border-radius: 4px;
    }

    @supports not selector(::-webkit-scrollbar) {
        & {
            scrollbar-color: #d9d9d9 #efefef;
            scrollbar-width: thin;
        }
    }
`;

import { gql } from '@apollo/client';

/**
 * Mutation for zone creation
 */
export const CUT_ZONE = gql`
    mutation cutZone(
        $donorZoneSelector: VersionedZoneSelectorDto!
        $newZoneDraft: ZoneDraft!
        $wallSegmentSelectors: [VersionedSegmentSelector]!
    ) {
        cutZone(
            donorZoneSelector: $donorZoneSelector
            newZoneDraft: $newZoneDraft
            wallSegmentSelectors: $wallSegmentSelectors
        ) {
            newZone {
                color
                id
                version
                name
                global
                accessRules {
                    group {
                        id
                        name
                    }
                    rule
                }
                floor {
                    id
                }
                wall {
                    graph {
                        key
                    }
                }
                wallSegments {
                    startPoint {
                        id
                    }
                    endPoint {
                        id
                    }
                    version
                }
            }
            baseZone {
                id
                version
            }
        }
    }
`;

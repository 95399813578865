import styled from 'styled-components';

export const Input = styled.input`
    width: calc(100% - 28px);
    min-width: 243px;

    border: 0.5px solid #d7d7d7;
    border-radius: 8px;
    padding: 13px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #000000;

    &:focus {
        outline: none;
        border-color: #023aff;
    }
`;

export const Button = styled.button`
    padding: 6.5px 26.5px;

    background: #023aff;
    border-radius: 9px;
    border: 0;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;

    display: flex;
    align-items: center;
    gap: 10px;

    &:enabled:hover {
        cursor: pointer;
        background: #5076fd;
    }

    &:disabled {
        background: #d9d9d9;
        color: #000000;
    }
`;

export const SecondaryButton = styled(Button)`
    background: #e1e8ff;
    color: #000000;

    &:enabled:hover {
        cursor: pointer;
        background: #b7c7fe;
    }
`;

export const RedButton = styled(Button)`
    background: #ff5555;

    &:enabled:hover {
        cursor: pointer;
        background: #ff8080;
    }
`;

export const TextButton = styled.p`
    margin: 0;
    display: flex;
    align-items: center;
    gap: 10px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

export const OptionsImg = styled.img`
    height: 13px;
    width: auto;
    padding: 0 3px;
    cursor: pointer;
`;

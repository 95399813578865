import React from 'react';
import { useReactiveVar } from '@apollo/client';
import floorGraph from '../../../../../../../features/Wall/model/FloorGraph';
import { SegmentPropertiesUI } from '../../../../../../../shared/ui/RightBarComponents/SegmentProperties/SegmentPropertiesUI';

/**
 * Component that represents settings for creating new segment
 * @component
 */
export const WallSettings = () => {
    const props = useReactiveVar(floorGraph.tmpEdgeProps);

    const handleChange = (e) => {
        floorGraph.tmpEdgeProps({
            ...props,
            [e.target.name]:
                e.target.type === 'checkbox'
                    ? e.target.checked
                    : e.target.value,
        });
    };

    return <SegmentPropertiesUI segmentProps={props} onChange={handleChange} />;
};

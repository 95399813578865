import { makeVar } from '@apollo/client';
import { AccessGroup } from './AccessGroup';
import accessGroupsAPI from '../api/AccessGroupsAPI';

/**
 * @typedef {import('./AccessGroup').AccessGroup} AccessGroup
 * @typedef {import('./AccessGroup').AccessGroupData} AccessGroupData
 * @typedef {import('../api/AccessGroupsAPI').AccessGroupDraft} AccessGroupDraft
 */

/**
 * @callback GroupsMapVar
 * @param {Map<string, AccessGroup>}
 * @return {Map<string, AccessGroup>}
 */

/**
 * @class
 * This class is uses to store and manage all access groups.
 * Use this class to add or delete access group.
 *
 * If you need to modify concrete group -> get its instance (e.g. with getGroup() method)
 * and modify using AccessGroup methods
 */
class AccessGroupsModerator {
    constructor() {
        /**
         * Var for storing all access groups.
         * Key - access group UUID.
         * Value - access group object
         * @type {GroupsMapVar}
         */
        this.groupsMapVar = makeVar(new Map());

        /**
         * Variable for storing UUID of current user group
         * @type {string | null}
         */
        this.currentAccessGroupId = null;
    }

    /**
     * Method for adding access group to manager and creating AccessGroup instance for group.
     * Note: this method add group only to local storage. To create group on server side - use createGroup() method
     * @param {string} groupId
     * @param {AccessGroupData} [properties={}] Extra properties
     * @returns {AccessGroup} Created route
     */
    addGroup(groupId, properties = {}) {
        const newGroup = new AccessGroup(groupId, properties);
        this.groupsMapVar(new Map(this.groupsMapVar().set(groupId, newGroup)));
        return newGroup;
    }

    /**
     * Method for removing access group from manager.
     * Note: this method remove only from local storage. To fully delete group use deleteGroup() method
     * @param {string} groupId
     */
    removeGroup(groupId) {
        const deleted = this.groupsMapVar().delete(groupId);
        if (deleted) {
            this.groupsMapVar(new Map(this.groupsMapVar()));
        }
    }

    /**
     * Method for creating new access group.
     * Send query to create access group and then add it to manager
     * @param {AccessGroupDraft} groupDraft group properties
     */
    async createGroup(groupDraft) {
        const newGroupsData = await accessGroupsAPI.createGroup(groupDraft);
        if (newGroupsData) {
            newGroupsData.forEach((group) => {
                this.addGroup(group.id, group); // add group to manager

                // add new child group to parent
                if (group.parent) {
                    this.getGroup(group.parent).addChildGroup(group.id);
                }

                // add new group to all parents as a new descendants
                let parentId = group.parent;
                while (parentId) {
                    const parentGr = this.getGroup(parentId);
                    parentGr.addDescendant(group.id);
                    parentId = parentGr.parent;
                }
            });
        }
    }

    /**
     * Method for call mutation to update group.
     * And modify existed group after receiving result.
     * @param {AccessGroupDraft} groupDraft group properties
     */
    async updateGroup(groupId, groupDraft) {
        const data = await accessGroupsAPI.updateGroup(groupId, groupDraft);
        data?.forEach((updatedGroup) => {
            const group = this.getGroup(updatedGroup.id);
            group.update(updatedGroup);
        });
    }

    /**
     * Method for deleting access group.
     * Send query to delete access group and then delete it from manager
     */
    async deleteGroup(groupId) {
        let parentId = this.getGroup(groupId).parent;
        const data = await accessGroupsAPI.deleteGroup(groupId);
        if (data) {
            const parentGroup = this.getGroup(parentId);
            parentGroup.removeChildGroup(groupId); // remove group from parent's children

            // remove group from all parent's descendants
            while (parentId) {
                const parentGr = this.getGroup(parentId);
                parentGr.removeDescendant(groupId);
                parentId = parentGr.parent;
            }

            this.removeGroup(data); // remove group from manager
        }
    }

    /**
     * Method for getting access group by its id
     * @param {string} groupId
     * @returns {AccessGroup} Access group instance
     */
    getGroup(groupId) {
        return this.groupsMapVar().get(groupId);
    }

    /**
     * Method that try to find access group which user is assigned.
     * If user don't assign to any project groups -> null
     * @param {string} userId user UUID
     * @returns {AccessGroup | null}
     */
    findUserGroup(userId) {
        const groups = Array.from(
            accessGroupsModerator.groupsMapVar(),
            ([gid, group]) => group
        );
        for (let i = 0; i < groups.length; i++) {
            if (groups[i].members().find((user) => user.id === userId)) {
                return groups[i];
            }
        }
        return null;
    }

    /**
     * Method to check is group descendant
     * (is checking group lower in the access group hierarchy)
     * @param {string} checkGroupId UUID of group that need to check
     * @param {string} parentGroupId UUID of group from where need to start search
     * @return {boolean}
     */
    isGroupDescendant(checkGroupId, parentGroupId) {
        const parentGroup = this.getGroup(parentGroupId);
        const descendants = parentGroup.descendants();
        for (let i = 0; i < descendants.length; i++) {
            if (descendants[i] === checkGroupId) {
                return true;
            }
        }
        return false;
    }

    /** Method for cleaning all storing data */
    clear() {
        this.groupsMapVar(new Map());
        this.currentAccessGroupId = null;
    }
}

const accessGroupsModerator = new AccessGroupsModerator();
export default accessGroupsModerator;

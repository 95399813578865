import { useReactiveVar } from '@apollo/client';
import React from 'react';
import styled from 'styled-components';
import zones, { initialValueForZonesMergeVar } from '../../../Zone/model/Zones';
import { closeContextMenu } from '../../model/CloseContextMenu';
import {
    StyledButton,
    StyledInput,
    StyledLabel,
    StyleZoneContextContainer,
} from '../../styles/UIKit';

const ZoneContextContainer = styled(StyleZoneContextContainer)`
    min-width: 279px;
`;

/**
 * Zone merge context menu
 * @component
 */
export const ZoneMergeContextMenu = () => {
    const clickedZones = useReactiveVar(zones.zonesMergeVar);

    const zone1 = zones.zonesVar().get(clickedZones.firstZoneId);
    const zone2 = zones.zonesVar().get(clickedZones.secondZoneId);

    /** Handler for cancel button */
    const handleCancel = () => {
        zones.zonesMergeVar(initialValueForZonesMergeVar);
        closeContextMenu(true);
    };

    /** Handler for save button */
    const handleSave = () => {
        const firstZoneVersion = zones.getVersion(clickedZones.firstZoneId);
        const secondZoneVersion = zones.getVersion(clickedZones.secondZoneId);

        zones.merge(clickedZones.firstZoneId, clickedZones.secondZoneId);
        zones.zonesMergeVar(initialValueForZonesMergeVar); // clear zones merge var to initial state
        closeContextMenu(true);

        zones.saveMerge(
            {
                id: clickedZones.firstZoneId,
                version: firstZoneVersion,
            },
            {
                id: clickedZones.secondZoneId,
                version: secondZoneVersion,
            }
        );
    };

    return (
        <ZoneContextContainer>
            <StyledLabel>Объединяемые зоны</StyledLabel>

            <StyledLabel>
                Зона 1:
                <StyledInput
                    type='text'
                    name='name'
                    value={
                        (zone1 && zone1.props.name) ||
                        clickedZones.firstZoneId ||
                        ''
                    }
                    readOnly
                />
            </StyledLabel>

            <StyledLabel>
                Зона 2:
                <StyledInput
                    type='text'
                    name='name2'
                    readOnly
                    value={
                        clickedZones.secondZoneId
                            ? zone2.props.name || clickedZones.secondZoneId
                            : 'Нажмите на зону'
                    }
                />
            </StyledLabel>

            <StyledButton
                onClick={handleSave}
                disabled={
                    !(clickedZones.firstZoneId && clickedZones.secondZoneId)
                }
            >
                Сохранить
            </StyledButton>
            <StyledButton onClick={handleCancel}>Отмена</StyledButton>
        </ZoneContextContainer>
    );
};

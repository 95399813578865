import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ChildrenContainer, ListNItemsContainer } from '../Styles';
import { OpeningGroupHeader } from './Header/OpeningGroupHeader';

/**
 * Component that represents information about constraint group.
 * It is wrapper for a constraint group header and its items
 * @component
 */
export const OpeningGroup = ({
    title,
    iconSrc,
    isEditable,
    isOpen = false,
    onTitleEditFinish = () => {},
    optionsRef,
    onOptionsClick = () => {},
    children,
}) => {
    const [isItemCollapsed, setIsItemCollapsed] = useState(isOpen);

    const handleCollapseClick = () => {
        setIsItemCollapsed(!isItemCollapsed);
    };

    return (
        <ListNItemsContainer>
            <OpeningGroupHeader
                isItemsCollapsed={isItemCollapsed}
                handleCollapseClick={handleCollapseClick}
                title={title}
                iconSrc={iconSrc}
                isEditable={isEditable}
                onTitleEditFinish={onTitleEditFinish}
                optionsRef={optionsRef}
                onOptionsClick={onOptionsClick}
            />
            {isItemCollapsed && children && (
                <ChildrenContainer>{children}</ChildrenContainer>
            )}
        </ListNItemsContainer>
    );
};

OpeningGroup.propTypes = {
    /** Showing title */
    title: PropTypes.string,

    /** Src path for group img */
    iconSrc: PropTypes.string,

    /** Is group name available to edit */
    isEditable: PropTypes.bool,

    /** Need to open group on first render */
    isOpen: PropTypes.bool,

    /**
     * Callback function for finish editing group title
     * @type {(newTitle: string) => void}
     */
    onTitleEditFinish: PropTypes.func,

    /** Ref for options img */
    optionsRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),

    /**
     * Callback function for click on context menu
     * @type {() => void}
     */
    onOptionsClick: PropTypes.func,

    /** React children */
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
};

import React from 'react';
import styled from 'styled-components';
import { useReactiveVar } from '@apollo/client';
import zones from '../../../../../../features/Zone/model/Zones';
import { OperationButton } from './OperationButton';
import { handleZoneCreation } from '../../../../../../widgets/Canvas/lib/utils/HandleZoneCreation';

const AdditionalOperationInfo = styled.span`
    margin: 0 5px 5px 40px;
`;

/**
 * Component represents custom right bar (RB) for creating new zone
 * @component
 */
export const RBZoneCreation = ({ operation }) => {
    const donorZoneId = useReactiveVar(zones.donorZoneVar);
    return (
        <>
            <AdditionalOperationInfo>
                Выделенная зона: {zones.get(donorZoneId)?.props?.name ?? ''}
            </AdditionalOperationInfo>
            <OperationButton
                text='Создать зону'
                clickHandler={handleZoneCreation}
                disabled={!zones.donorZoneVar()}
            />
        </>
    );
};

import {
    mapObjectsVar,
    closestWallPointVar,
    currentToolVar,
    snapToGridVar,
    stageVar,
    currentDragTypeVar,
    currentCanvasPointVar,
} from '../../../../shared/model/cache/Cache';
import { areLineAndRectIntersected } from '../../../../shared/lib/modules/math/Math';
import { getItemBoxLineStrings } from '../../../../shared/lib/utils/items/GetItemBox';
import selector from '../../../../features/Selector/model/Selector';
import {
    Constraints,
    DragTools,
    Tools,
} from '../../../../pages/Constructor/TopBar/TopBarEntires/Tools/Constants/Tools';
import floorGraph from '../../../../features/Wall/model/FloorGraph';
import routesModerator from '../../../../features/Route/model/RoutesModerator';
import { WALL_CIRCLE_NAME } from '../../../../features/Wall/lib/helpers/WallDefaultValues';
import { getClosestWallPoint } from '../../../../features/Wall/lib/helpers/WallPointAttraction';
import { FIND_POINT_RADIUS } from '../CanvasDefaultValues';
import { gridSnapper } from '../../../../features/Wall/lib/helpers';

/**
 * @typedef {import('../../../../features/Wall/model/FloorGraph').FloorGraph} FloorGraph
 */

/**
 * Saving current cursor pointer position with validations :
 * 1) snapping to horizontal/vertical lines
 * 2) saving closest to wall point
 */
export const handleStageMouseMove = (e) => {
    if (
        currentToolVar() === Tools.wall ||
        currentToolVar() === Tools.embeddedObject ||
        currentToolVar() === Constraints.route
    ) {
        const graph =
            currentToolVar() === Constraints.route
                ? routesModerator.getCurrentRoute()?.graph
                : floorGraph;
        if (graph) {
            setClosestToWallPoint(e, graph);
            snapToGridVar(
                (currentToolVar() === Tools.wall ||
                    currentToolVar() === Constraints.route) &&
                    e.evt.shiftKey
            );
            setMousePointerPosition(graph);
            setWallCreationAvailability(graph);
        } else {
            setMousePointerPosition();
        }
    }

    if (
        currentToolVar() === Tools.rectangleRoom &&
        floorGraph.tmpRectGraphVar().size > 0
    ) {
        setMousePointerPosition(floorGraph);

        const fixedPoint = floorGraph.tmpRectGraphVar().values().next()
            .value.coordinates;

        floorGraph.createTmpRectWall(
            fixedPoint,
            currentCanvasPointVar(),
            'Rect'
        );
    }

    if (
        (currentToolVar() === Tools.selector ||
            currentDragTypeVar() === DragTools.cursor) &&
        !!selector.rectSelectorPointsVar() &&
        selector.rectSelectorPointsVar().length > 0
    ) {
        selector.rectSelectorPointsVar([
            { ...selector.rectSelectorPointsVar()[0] },
            { ...stageVar().getRelativePointerPosition() },
        ]);
    }
};

/**
 * Function that checks the position of mouse cursor and
 * if it's close to wall, set closest to wall point in var.
 */
const setClosestToWallPoint = (e, graph) => {
    let closestWallPoint = getClosestWallPoint(FIND_POINT_RADIUS, graph);
    if (!closestWallPoint) {
        return;
    }
    if (e.target.attrs.name === WALL_CIRCLE_NAME) {
        closestWallPointVar({
            ...closestWallPoint,
            point: { ...e.target.position() },
        });
    } else {
        closestWallPointVar({ ...closestWallPoint });
    }
};

/**
 * Validate snapping to horizontal/vertical lines
 * and add snapped point if validation is TRUE
 * @param {FloorGraph} graph FloorGraph or its heir
 */
const setSnappedPoint = (graph) => {
    const snappedPoint = gridSnapper.snapToGrid(
        graph.tmpEdgeVar().values().next().value.coordinates,
        stageVar().getRelativePointerPosition()
    );

    currentCanvasPointVar({ ...snappedPoint });
};

/**
 * Validate and set pointer position.
 * @param {FloorGraph} graph FloorGraph or its heir
 */
const setMousePointerPosition = (graph) => {
    if (graph?.tmpEdgeVar().size > 0 && snapToGridVar()) {
        setSnappedPoint(graph);
    } else {
        currentCanvasPointVar({
            ...stageVar().getRelativePointerPosition(),
        });
    }
};

/**
 * @param {FloorGraph} graph FloorGraph or its heir
 */
const setWallCreationAvailability = (graph) => {
    let hasIntersection = false;
    const tmpPoint = graph.tmpEdgeVar().values().next().value;
    if (!tmpPoint) {
        return;
    }
    for (const mapObject of mapObjectsVar()) {
        const itemBoxLineString = getItemBoxLineStrings(mapObject);

        hasIntersection = areLineAndRectIntersected(
            [tmpPoint.coordinates, currentCanvasPointVar()],
            itemBoxLineString
        );

        if (hasIntersection) {
            graph.isWallCreationAvailableVar(false);
            break;
        }
    }

    if (!hasIntersection && !graph.isWallCreationAvailableVar()) {
        graph.isWallCreationAvailableVar(true);
    }
};

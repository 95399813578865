import { DocumentNode, gql } from '@apollo/client';

export const DELETE_ROUTE_SEGMENT: DocumentNode = gql`
    mutation deleteRouteSegment($selector: VersionedSegmentSelector!) {
        deleteRouteSegment(selector: $selector) {
            id
            version
        }
    }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { Input } from './Input';

/**
 * Form component - input for string
 * @component
 */
export const InputString = ({ limit = 500, ...props }) => {
    return (
        <Input
            type='text'
            maxLength={String(limit)}
            {...props}
        />
    );
};

InputString.propTypes = {
    /** max length of input */
    limit: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

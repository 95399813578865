import mapinsText from '../assets/images/icons/mapins-text.svg';
import mapinsLogo from '../assets/images/icons/mapins-logo.svg';

import React from 'react'

/**
 * 
 * @returns { HorizontalLogo } Component with horizontal mapins logo
 */
export const HorizontalLogo = () => {
  return (
    <div style={{ height: '58px', width: '195px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <img src={mapinsLogo} alt='mapins logo' />
      <img style={{ marginTop: '10px' }} src={mapinsText} alt='mapins text' />
    </div>
  )
}

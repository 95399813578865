import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import plusImg from '../../../shared/assets/images/icons/plus.svg';

import { CREATE_PROJECT } from '../../../shared/graphql/mutations/projects/CreateProject';
import { AddButton } from './Styles';
import { DEFAULT_PROJECT_NAME } from './Constants';
import projectImg from '../../../shared/assets/images/icons/MainPage/map-placeholder.svg';
import { useNavigate } from 'react-router';
import { projectPage } from '../../../shared/model/routes/staticRoutes';
import { getUniqueNumber } from '../../../shared/lib/utils/common/GetUniqueNumber';

/**
 * Component for creating new project
 * @param {Object[]} projects array of projects info
 * @param {function} setProjects function for changing parent's state with projects
 * @returns { CreateButton } Button for creating new project
 */
export const CreateButton = ({ projects, setProjects }) => {
    const [createProject] = useMutation(CREATE_PROJECT);
    const navigate = useNavigate();

    const handleClick = () => {
        const projectNumber = getUniqueNumber(projects, DEFAULT_PROJECT_NAME);
        createProject({
            variables: {
                projectDraft: {
                    name:
                        projectNumber > 0
                            ? `${DEFAULT_PROJECT_NAME}(${projectNumber})`
                            : DEFAULT_PROJECT_NAME,
                },
            },
        })
            .then((response) => {
                if (response.data) {
                    setProjects([
                        ...projects,
                        {
                            id: response.data.createProject.id,
                            name: response.data.createProject.name,
                            description:
                                response.data.createProject.description,
                            preview: projectImg,
                        },
                    ]);
                    navigate(`${projectPage}/${response.data.createProject.id}`);
                }
            })
            .catch((error) => {
                alert(error);
            });
    };
    return (
        <AddButton onClick={handleClick}>
            <img
                src={plusImg}
                alt='plus pict'
                style={{
                    width: '15px',
                    height: '15px',
                    marginRight: '17px',
                }}
            />
            Создать карту
        </AddButton>
    );
};

AddButton.propTypes = {
    projects: PropTypes.arrayOf(PropTypes.object),
    setProjects: PropTypes.func,
};

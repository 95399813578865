import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import arrowDownImg from '../../assets/images/icons/ConstructorPage/GraphicSchema/arrow-down.svg';
import arrowUpImg from '../../assets/images/icons/ConstructorPage/GraphicSchema/arrow-up.svg';
import tickImg from '../../assets/images/icons/ConstructorPage/GraphicSchema/tick.svg';
import { POpenSansRegular } from '../../ui/TextStyles';
import { useOutsideClick } from '../../model/hooks/UseOutsideClick';
import { SCROLLBAR_STYLED } from '../ScrollBar';

const Container = styled.div`
    min-width: 121px;
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;
`;

/**
 * @param {boolean} isOpened - is combo box opened
 */
const Header = styled.div`
    display: flex;
    align-items: center;
    padding: 7px 0;

    background-color: ${(props) => (props.isOpened ? '#E1E8FF' : '#E1E8FF')};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: ${(props) => (props.isOpened ? '0px' : '5px')};
    border-bottom-right-radius: ${(props) => (props.isOpened ? '0px' : '5px')};
`;

const Title = styled(POpenSansRegular)`
    flex-grow: 1;
    font-size: 13px;
    line-height: 11.7px;
    margin-left: 14px;
    margin-right: 12px;
    text-align: center;
`;

const CollapseIcon = styled.img`
    width: 8.6px;
    height: auto;
    margin-right: 9px;
`;

/**
 * @param {boolean} isOpened - is combo box opened
 */
const SelectBody = styled.ul`
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    background: #f2f5ff;
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    height: ${(props) => (props.isOpened ? 'auto' : '0')};
    max-height: 180px;
    overflow-x: hidden;
    transition: height 2s ease-in;

    overflow-y: auto;
    ${SCROLLBAR_STYLED}

    z-index: 1;
`;

const SelectItem = styled.li`
    position: relative;
    margin: 0;
    padding: 5px 16px 3px 3px;
    width: calc(100% - 19px); // 19px for paddings
    border-top: 0.5px solid rgba(108, 108, 108, 0.8);

    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;

    &:hover {
        background-color: #E1E8FF;
    }
`;

const TickIcon = styled.img`
    width: 9px;
    height: auto;
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
`;

/**
 * Form component - combo box.
 * Component with dropdown list for selection.
 * 
 * @component
 * 
 * @example
 * const MyComponent = () => {
 *     // specify items to select
 *     const comboBoxItems = ['January', 'February', 'March'];
 * 
 *     // specify selected item
 *     const [selectedElem, setSelectedElem] = useState('January');
 *     
 *     // create callback handler for select
 *     const handleSelect = (elem) => {
 *         console.log('selected elem ', elem);
 *         setSelectedElem(elem);
 *     };
 *     
 *     return (
 *         <ComboBox
 *             items={comboBoxItems}
 *             selectedElem={selectedElem}
 *             handleSelect={handleSelect}
 *         />
 *     );
 * }
 */
export const ComboBox = ({ items = [], selectedElem = '', handleSelect }) => {
    const [isOpened, setIsOpened] = useState(false);

    const containerRef = useRef(null);

    useOutsideClick(containerRef, () => {
        setIsOpened(false);
    });

    const handleOpen = () => {
        setIsOpened(!isOpened);
    };

    /**
     * Handler for item clicks.
     * @param {string} itemKey - key of clicked item
     */
    const handleItemClick = (itemKey) => {
        if (handleSelect) {
            handleSelect(itemKey); // execute user callback function
        }
        setIsOpened(false);
    };

    return (
        <Container ref={containerRef}>
            <Header isOpened={isOpened} onClick={handleOpen}>
                <Title>{selectedElem}</Title>
                <CollapseIcon
                    src={isOpened ? arrowUpImg : arrowDownImg}
                    alt={isOpened ? 'свернуть' : 'развернуть'}
                />
            </Header>
            <SelectBody isOpened={isOpened} className='combobox-body'>
                {items.map((elem) => (
                    <SelectItem key={elem} onClick={() => handleItemClick(elem)}>
                        {elem}
                        {elem === selectedElem && <TickIcon src={tickImg} alt='✓' />}
                    </SelectItem>
                ))}
            </SelectBody>
        </Container>
    );
};

ComboBox.propTypes = {
    /**
     * Items is an array of objects with fields: key and value
     * @example items=['January', 'February', 'March']
     */
    items: PropTypes.arrayOf(PropTypes.string),

    /** Key of selected object */
    selectedElem: PropTypes.string,

    /**
     * Function that executes after item select.
     * @example handleSelect = (elem) => { console.log('selected elem ', elem) };
     */
    handleSelect: PropTypes.func,
};

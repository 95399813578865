import floorGraph from '../../../features/Wall/model/FloorGraph';
import {
    projectGlobalTypesGroups,
    projectWallTypesVar,
} from '../../../shared/model/cache/Cache';

export const initializeProjectTypes = (projectTypes) => {
    // clear wallTypes
    projectWallTypesVar([]);
    // load actual from server
    projectTypes.forEach((group) => {
        if (group.wallSegmentTypes.length > 0) {
            projectWallTypesVar([
                ...projectWallTypesVar(),
                ...group?.wallSegmentTypes.map((wallType) => ({
                    scalable: wallType.scalable,
                    id: wallType.id,
                    length: wallType.length,
                    through: wallType.through,
                    image: { ...wallType.image },
                    width: wallType.width,
                    name: wallType.name,
                    description: wallType.description,
                    virtual: wallType.virtual,
                })),
            ]);
        }
    });

    const globalTypesGroups = new Map();
    projectTypes.forEach((globalTypeGroup) => {
        globalTypesGroups.set(globalTypeGroup.name, globalTypeGroup);
    });
    projectGlobalTypesGroups(globalTypesGroups);

    // set default wall
    floorGraph.currentWallSegmentTypeVar(
        projectWallTypesVar().find((type) => type.name === 'Несущая стена 1м')
    );
};

import { useEffect } from 'react';

/**
 * Function for checking if wheel event was outside the element
 * and call handler function
 * 
 * @param {React.MutableRefObject} elem Ref to the element
 * @param {function} handler Function for handle outside click
 * @param {boolean} [attached=true] Is element visible
 */
export const useOutsideWheel = (elem, handler, attached = true) => {
    useEffect(() => {
        if (!attached) return;

        const handleClick = (e) => {
            if (!elem.current) return;
            if (!elem.current.contains(e.target)) {
                handler();
            }
        };
        document.addEventListener('wheel', handleClick);

        return () => {
            document.removeEventListener('wheel', handleClick);
        };
    }, [elem, handler, attached]);
};

import React from 'react';
import PropTypes from 'prop-types';

import arrowImg from '../../../shared/assets/images/icons/arrow.svg';
import mascotSadImg from '../../../shared/assets/images/icons/MainPage/mascotSad.svg';

import { Project } from './Project';
import { CreateButton } from './CreateButton';

import {
    H1OpenSansSemiBold,
    POpenSansLight,
    H2OpenSansSemiBold,
} from '../../../shared/ui/TextStyles';
import {
    Projects,
    ProjectsGridContainer,
    NoProjectsBlock,
    NoProjectsBlockImg,
} from './Styles';

/**
 * Component with grid of projects
 * @param {Object[]} projects array of projects info
 * @param {function} setProjects function for changing parent's state with projects
 * @returns { ProjectsGrid } Grid of projects component
 */
export const ProjectsGrid = ({ projects, setProjects, searchBarVal }) => {
    const handleDeleteProject = (projectId) => {
        setProjects(
            projects.filter((project) => {
                return project.id !== projectId;
            })
        );
    };

    return (
        <ProjectsGridContainer>
            <H1OpenSansSemiBold>Мои карты</H1OpenSansSemiBold>
            <POpenSansLight style={{ margin: '5px 0 0 5px', opacity: 0.7 }}>
                Недавно редактированные
                <img
                    src={arrowImg}
                    alt='arrow to open list'
                    style={{ marginLeft: '6px', marginTop: '2px' }}
                />
            </POpenSansLight>

            {!projects.length && (
                <NoProjectsBlock>
                    <NoProjectsBlockImg src={mascotSadImg} alt='mascot sad' />
                    {searchBarVal === '' ? (
                        <>
                            <H2OpenSansSemiBold
                                style={{ marginBottom: '37px' }}
                            >
                                У вас пока нет карт.
                                <br /> Чтобы создать свою первую карту нажмите
                                кнопку
                            </H2OpenSansSemiBold>
                            <CreateButton
                                projects={projects}
                                setProjects={setProjects}
                            />
                        </>
                    ) : (
                        <H2OpenSansSemiBold style={{ marginBottom: '37px' }}>
                            Карта не найдена.
                        </H2OpenSansSemiBold>
                    )}
                </NoProjectsBlock>
            )}

            <Projects count={projects.length}>
                {projects.map((project) => (
                    <Project
                        count={projects.length}
                        key={project.id}
                        id={project.id}
                        name={project.name}
                        description={project.description}
                        edited={project.edited}
                        preview={project.preview}
                        onDelete={handleDeleteProject}
                    />
                ))}
            </Projects>
        </ProjectsGridContainer>
    );
};

ProjectsGrid.propTypes = {
    projects: PropTypes.arrayOf(PropTypes.object),
    setProjects: PropTypes.func,
};

import React from 'react';
import styled from 'styled-components';
import { Modal } from '../../ModalWindow/Modal';
import { POpenSansRegular } from '../../../shared/ui/TextStyles';
import {
    RedButton,
    SecondaryButton,
} from '../../../shared/ui/RolesSettingsComponents/Styles';
import { Preloader } from '../../../shared/ui/Preloader';

const Container = styled.div`
    padding: 15px 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
`;

const ButtonBar = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 7px;
    align-self: flex-end;
`;

interface ConfirmDeleteModalProps {
    isOpened: boolean;
    isDeleting: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

export const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({
    isOpened,
    isDeleting,
    onClose,
    onConfirm,
}) => {
    return (
        <Modal isOpened={isOpened} isCloseIconShow={true} onClose={onClose}>
            <Container>
                <POpenSansRegular>
                    Вы уверены, что хотите удалить комментарий?
                </POpenSansRegular>
                <ButtonBar>
                    {isDeleting && <Preloader loaderSize={30} />}
                    <RedButton style={{}} onClick={onConfirm}>
                        удалить
                    </RedButton>
                    <SecondaryButton onClick={onClose}>отмена</SecondaryButton>
                </ButtonBar>
            </Container>
        </Modal>
    );
};

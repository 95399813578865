import React, { useState } from 'react';
import { ZoneEditContextMenu } from './ZoneEditContextMenu';
import { ZoneDefaultContextMenu } from './ZoneDefaultContextMenu';
import { ZoneMergeContextMenu } from './ZoneMergeContextMenu';
import {
    objectSettingsModalVar,
    contextMenuVar,
} from '../../../../shared/model/cache/Cache';
import { closeContextMenu } from '../../model/CloseContextMenu';
import { OBJECT_TYPE } from '../../../ObjectSettingsModal/ObjectModalConstants';
import { activateMapinsForm, objectWithSchemaTypes } from '../../../../shared/model/mapinsForm/activateMapinsForm';
import zones from '../../../Zone/model/Zones';

// constants defining submenu types
export const ZONE_MENU_DEFAULT = 'ZONE_MENU_DEFAULT';
export const ZONE_MENU_EDIT = 'ZONE_MENU_EDIT';
export const ZONE_MENU_MERGE = 'ZONE_MENU_MERGE';

/**
 * Component for zone context menu
 * Chooses which zone context menu show to user
 * @component
 */
export const ZoneContextMenu = ({
    zoneId,
    contextMenuType = ZONE_MENU_DEFAULT,
}) => {
    const [menuType, setMenuType] = useState(contextMenuType);

    switch (menuType) {
        case ZONE_MENU_EDIT:
            return <ZoneEditContextMenu zoneId={zoneId} />;

        case ZONE_MENU_MERGE:
            return <ZoneMergeContextMenu />;

        case ZONE_MENU_DEFAULT:
        default:
            /** Handler for zone access button */
            const handleOpenAccessClick = () => {
                closeContextMenu();
                objectSettingsModalVar({
                    ...objectSettingsModalVar(),
                    isOpened: true,
                    objectType: OBJECT_TYPE.zone,
                    objectUUID: zoneId,
                });
            };

            /** Handler for edit button */
            const handleEditButtonClick = () => {
                setMenuType(ZONE_MENU_EDIT);
            };

            /** Handler for merge button */
            const handleMergeButtonClick = () => {
                contextMenuVar({
                    // set context menu as not closable
                    ...contextMenuVar(),
                    isClosable: false,
                });
                setMenuType(ZONE_MENU_MERGE);
            };

            const handleActivateMapinsForm = () => {
                activateMapinsForm(zones.get(zoneId).props, objectWithSchemaTypes.zone);
                closeContextMenu();
            };

            return (
                <ZoneDefaultContextMenu
                    handleActivateMapinsForm={handleActivateMapinsForm}
                    handleOpenAccessClick={handleOpenAccessClick}
                    handleEditButtonClick={handleEditButtonClick}
                    handleMergeButtonClick={handleMergeButtonClick}
                />
            );
    }
};

import styled from 'styled-components';

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 7px;
    max-width: 322px;
`;

/**
 * Styled registration component link
 */
const UserLink = styled.a`
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16.8px;
    text-align: left;
    opacity: 0.7;
    text-decoration: none;

    color: #000000;
    opacity: 0.7;
`;

/**
 * Styled container
 * This is needed to make the AuthorizationBody centralized
 */
const RegistrationContainer = styled.div`
    width: 100%;
    height: 100%;

    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
`;

/**
 * Styled Registration body
 */
const RegistrationBody = styled.div`
    width: 440px;

    padding: 22px 0 58px;

    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);

    display: flex;
    flex-direction: column;
    align-items: center;
`;

export { UserLink, Form, RegistrationContainer, RegistrationBody };

import { gql } from '@apollo/client';

export const CREATE_FLOOR = gql`
    mutation createFloor($floorDraft: FloorDraft!) {
        createFloor(floorDraft: $floorDraft) {
            id
            project {
                id
            }
            description
            num
        }
    }
`;

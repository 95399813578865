import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { device } from '../../../shared/ui/MediaSizes';

/**
 * Styled page container
 */
const MainPageContainer = styled.div`
    max-width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
`;

/**
 * Styled header
 */
const MainPageHeader = styled.div`
    padding: 40px 34px 0 40px;

    display: flex;
    flex-direction: row;
    align-items: center;
`;

/**
 * Styled block with search input and search image
 */
const Search = styled.form`
    width: 683px;
    height: 41px;

    margin-top: 5px;
    margin-left: 34px;
    border-radius: 12px;
    padding: 0 13px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: #efefef;
`;

/**
 * Styled search input
 */
const SearchInput = styled.input`
    width: 683px;

    border: 0;
    padding: 4px 0;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;

    background-color: #efefef;

    &:focus {
        outline: none;
        border-bottom: 0.5px solid #023aff;
    }

    &:focus::placeholder {
        color: transparent;
    }

    ::-webkit-search-decoration,
    ::-webkit-search-cancel-button,
    ::-webkit-search-results-button,
    ::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }
`;

/**
 * Styled search image
 */
const SearchImg = styled.img`
    width: 18px;
    height: 18px;
`;

/**
 * Styled container for user info
 */
const UserInfoContainer = styled.div`
    margin-left: auto;

    display: flex;
    align-items: center;

    cursor: pointer;
`;

/**
 * Styled img for user avatar
 */
const UserAvatar = styled.img`
    width: 60px;
    height: 60px;
`;

/**
 * Styled div with user info
 */
const Info = styled.div`
    display: flex;
    flex-direction: column;
    line-height: 22px;
    margin-left: 13px;
`;

/**
 * Styled container for user info popup
 */
const UserInfoBlock = styled.div`
    position: absolute;
    right: 34px;
    top: 110px;
    z-index: 100;

    width: 276px;
    height: 301px;

    border-radius: 16px;

    display: flex;
    flex-direction: column;

    background: #ffffff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.19);
`;

/**
 * Styled img for user avatar in popup
 */
const UserInfoAvatar = styled.img`
    width: 77px;
    height: 74px;

    margin: 24px auto 11px;

    display: block;
    float: none;
`;

/**
 * Styled div with user settings menu
 */
const UserInfoMenu = styled.div`
    margin: 0 36px;

    display: flex;
    align-items: base-line;
    flex-direction: row;
    justify-content: start;
`;

/**
 * Styled img for user settings menu icons
 */
const UserInfoIcon = styled.img`
    width: 16px;
    height: 16px;

    margin-top: 4px;
    margin-right: 11px;
`;

/**
 * Styled body
 */
const MainPageBody = styled.div`
    padding: 0 0 0 40px;

    flex: 1;
    overflow: hidden;

    display: flex;
`;

/**
 * Styled block container
 */
const NavContainer = styled.div`
    display: inline-flex;
    flex-direction: column;

    margin-top: 67px;
`;

/**
 * Styled add button
 */
const AddButton = styled.button`
    width: 184px;

    margin-bottom: 20px;
    border: 0;
    border-radius: 13px;
    padding: 11px 16px 11px 21px;

    display: flex;
    align-items: center;

    background: #023aff;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;

    &:enabled:hover {
        cursor: pointer;
        background: #5076fd;
    }

    &:disabled {
        background: #d9d9d9;
        color: #000000;
    }
`;

/**
 * Styled button
 */
const Button = styled.button`
    width: 172px;
    padding: 4px 42px 4px 17px;

    background-color: #e3e3e3;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    border-radius: 9px;
    border: 0;
    cursor: pointer;

    &:hover {
        background-color: #8ca6ff;
    }
`;

const ButtonAvailableProjects = styled(Button)`
    background-color: #ffffff;
    margin-top: 16px;
    &:hover {
        background-color: #e1e8ff;
    }
`;

/**
 * Styled block container
 */
const ProjectsGridContainer = styled.div`
    width: 100%;

    margin-top: 59px;
    padding: 0 0 0 38px;

    display: flex;
    flex-direction: column;
`;

/**
 * Styled grid block
 * @param {number} props.count - count of user's projects
 */
const Projects = styled.div`
    margin-top: 60px;
    padding-left: 5px;
    padding-bottom: 5px;

    display: grid;
    gap: 52px;
    grid-template-columns: ${(props) =>
        props.count < 3 ? '634px 634px' : '391px 391px 391px'};

    overflow-y: auto;

    @media ${device.laptopL} {
        grid-template-columns: 522px 522px;
    }

    @media ${device.laptop} {
        grid-template-columns: 391px 391px;
    }
`;

/**
 * Styled container of page
 * @param {number} props.count - count of user's projects
 * @param {string} props.backgroundImg - path to background image
 */
const ProjectContainer = styled.div`
    min-height: ${(props) => (props.count < 3 ? '389px' : '240px')};
    border-radius: 15px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-image: url('${(props) => props.backgroundImg}');
    background-repeat: no-repeat;
    background-position: center;
    background-clip: border-box;
    background-color: #f5f5f5;
    background-size: cover;

    &:hover {
        cursor: pointer;
        background-color: #e3e3e3;
    }

    @media ${device.laptopL} {
        min-height: 320px;
    }

    @media ${device.laptop} {
        min-height: 240px;
    }
`;

/**
 * Styled 3 dots image
 */
const DotsImage = styled.img`
    padding: 5px;
    position: absolute;
    z-index: 5;
    top: 15px;
    right: 15px;

    &:hover {
        cursor: pointer;
        background-color: #f5f5f5;
        border-radius: 16px;
    }
`;

/**
 * Styled footer
 */
const MainPageFooter = styled.div`
    padding: 20px 40px;

    background: #f5f5f5;
`;

/**
 * Styled table for footer info
 */
const FooterTable = styled.table`
    margin: -10px -87px;
    border-spacing: 87px 10px;
`;

/**
 * Styled wrapper for disclaimer modal content
 */
const DisclaimerWrapper = styled.div`
    width: calc(100vw - 40px);
    max-width: 1045px;

    padding: 51px 101px 29px;

    display: flex;
    flex-direction: column;
    align-items: center;

    box-sizing: border-box;

    @media (max-width: 950px) {
        padding: 31px 30px 20px;
    }
`;

/**
 * Styled div for text in disclaimer
 */
const DisclaimerText = styled.div`
    margin: 45px 0;

    text-align: left;
`;

/**
 * Styled button to close disclaimer
 */
const DisclaimerOkButton = styled(Button)`
    width: 295px;
    height: 44px;

    background: #023aff;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    padding: 0;

    &:hover {
        cursor: pointer;
        background: #5076fd;
    }
`;

/**
 * Styled a for disclaimer
 */
const LinkTo = styled.a`
    font-weight: 600;
`;

const LinkToSettingsPage = styled(Link)`
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-decoration: none;
    &:visited {
        color: #000000;
    }
`;

const NoProjectsBlock = styled.div`
    width: 594px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const NoProjectsBlockImg = styled.img`
    margin-bottom: 15px;
    width: 100px;
    height: auto;
`;

export {
    MainPageContainer,
    MainPageHeader,
    Search,
    SearchInput,
    SearchImg,
    UserInfoContainer,
    UserAvatar,
    Info,
    UserInfoBlock,
    UserInfoAvatar,
    UserInfoMenu,
    UserInfoIcon,
    MainPageBody,
    NavContainer,
    AddButton,
    Button,
    ProjectsGridContainer,
    Projects,
    ProjectContainer,
    DotsImage,
    MainPageFooter,
    FooterTable,
    DisclaimerWrapper,
    DisclaimerText,
    LinkTo,
    LinkToSettingsPage,
    ButtonAvailableProjects,
    NoProjectsBlock,
    NoProjectsBlockImg,
    DisclaimerOkButton,
};

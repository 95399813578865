import {
    logInErrorsMessages,
    logInErrorsMessagesRus,
} from '../../../../pages/LogIn/ui/Constants';
import {
    registrationErrorsMessages,
    registrationErrorsMessagesRus,
} from '../../../../pages/Registration/ui/Constants';

/**
 * TODO change or remove for correct showing of errors in actual version
 *
 * Function to show error hints for input values in registration and login forms
 * @param {String} errorCode Message that we get from response
 * @param {React.Dispatch<React.SetStateAction<>} setInputValuesErrors
 * @param {React.Dispatch<React.SetStateAction<>} setInputValuesStatus
 */
export const handleResponseError = (
    errorCode,
    setInputValuesErrors,
    setInputValuesStatus
) => {
    switch (errorCode) {
        case registrationErrorsMessages.existingLoginError:
            setInputValuesErrors((prev) => ({
                ...prev,
                login: registrationErrorsMessagesRus[errorCode],
            }));
            setInputValuesStatus((prev) => ({
                ...prev,
                login: 'error',
            }));
            break;
        case registrationErrorsMessages.existingEmailError:
            setInputValuesErrors((prev) => ({
                ...prev,
                email: registrationErrorsMessagesRus[errorCode],
            }));
            setInputValuesStatus((prev) => ({
                ...prev,
                email: 'error',
            }));
            break;
        case registrationErrorsMessages.invalidCharactersLoginError:
            setInputValuesErrors((prev) => ({
                ...prev,
                login: registrationErrorsMessagesRus[errorCode],
            }));
            setInputValuesStatus((prev) => ({
                ...prev,
                login: 'error',
            }));
            break;
        case logInErrorsMessages.authorizationErrorCode:
            setInputValuesErrors((prev) => ({
                ...prev,
                login: logInErrorsMessagesRus[errorCode],
            }));
            setInputValuesStatus((prev) => ({
                ...prev,
                login: 'error',
            }));
            break;

        case logInErrorsMessages.noSuchEntityError:
            setInputValuesErrors((prev) => ({
                ...prev,
                login: logInErrorsMessagesRus[errorCode],
            }));
            setInputValuesStatus((prev) => ({
                ...prev,
                login: 'error',
            }));
            break;
        default:
            throw new Error('Error:', errorCode);
    }
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    StyledButton,
    StyledInput,
    StyledLabel,
    StyleZoneContextContainer,
} from '../../styles/UIKit';
import commandManager from '../../../CommandManager/model/CommandManager';
import { changeZonePropertiesCommand } from '../../../CommandManager/lib/commands/zone/ChangeZoneProperties';
import { HexColorPicker } from 'react-colorful';
import { closeContextMenu } from '../../model/CloseContextMenu';
import zones from '../../../Zone/model/Zones';

/**
 * Zone edit context menu
 * @component
 */
export const ZoneEditContextMenu = ({ zoneId }) => {
    const zone = zones.zonesVar().get(zoneId);

    const [newColor, setNewColor] = useState(zone.props.color);

    const [form, setForm] = useState({
        name: zone.props.name || '',
        description: zone.props.description || '',
    });

    /** Handler for color picker change */
    const changeColor = (e) => {
        setNewColor(e);
    };

    /** Handler for inputs change */
    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    /** Handler for save button */
    const handleSave = () => {
        closeContextMenu();
        commandManager.do(
            changeZonePropertiesCommand(zoneId, {
                name: form.name,
                description: form.description,
                color: newColor,
            })
        );
        zones.saveUpdate({
            id: zoneId,
            name: form.name,
            color: newColor,
            version: zones.getVersion(zoneId),
        });
    };

    return (
        <StyleZoneContextContainer>
            <StyledLabel>
                Название:
                <StyledInput
                    type='text'
                    name='name'
                    value={form.name}
                    onChange={handleChange}
                />
            </StyledLabel>

            <StyledLabel>
                Описание:
                <StyledInput
                    type='text'
                    name='description'
                    value={form.description}
                    onChange={handleChange}
                />
            </StyledLabel>

            <div>
                Текущий цвет:
                <br />
                {newColor}
            </div>

            <HexColorPicker color={newColor} onChange={changeColor} />

            <StyledButton onClick={handleSave}>Сохранить</StyledButton>
        </StyleZoneContextContainer>
    );
};

ZoneEditContextMenu.propTypes = {
    /** zone uuid */
    zoneId: PropTypes.string,
};

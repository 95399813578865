import React, { useEffect } from 'react';
import { Circle, Line } from 'react-konva';
import PropTypes from 'prop-types';

import floorGraph from '../../model/FloorGraph';
import {
    TEMPORARY_EMBEDDED_OBJECT_CIRCLE_COLOR,
    TEMPORARY_EMBEDDED_OBJECT_LINE_COLOR,
    TEMPORARY_WALL_ID,
    TEMPORARY_WALL_STROKE_WIDTH,
    WALL_CIRCLE_RADIUS,
} from '../../lib/helpers/WallDefaultValues';
import { findAttractionPoint } from '../../lib/helpers/WallPointAttraction';
import {
    arePointsEqual,
    expandLine,
    isPointOnLine,
} from '../../../../shared/lib/modules/math/Math';
import { getArrOfCoordinates } from '../../../../shared/lib/utils/common/GetArrayOfCoordinates';
import { projectWallTypesVar } from '../../../../shared/model/cache/Cache';
import rightBarModerator from '../../../../pages/Constructor/RightBar/model/RightBarModerator';

const MAXIMUM_DISTANCE_TO_WALL = 100;

/**
 * @component represents helping line showed on the wall segment while embedding
 */
export const TemporaryEmbeddedSegment = ({
    tmpEmbeddedObjectEdge,
    currentPoint,
    secondPoint,
}) => {
    useEffect(() => {
        // handle embedded object not connected to any wall
        if (!floorGraph.currentWallIdForEmbeddingWallSegment) {
            // for segments, that not connected to any wall as second coordinate takes secondPoint
            return;
        }

        // TODO: hardcoded length
        const objectLength =
            projectWallTypesVar().find(
                (wallType) => wallType.id === rightBarModerator.selectedObject()
            )?.length ?? 75;

        if (
            floorGraph.getEdgeLength(
                floorGraph.currentWallIdForEmbeddingWallSegment
            ) < objectLength
        ) {
            floorGraph.tmpEmbeddedObjectEdgeVar([]);
        } else {
            const pointOnWall = findAttractionPoint(
                floorGraph.getLineStringOfEdge(
                    floorGraph.currentWallIdForEmbeddingWallSegment
                ),
                currentPoint,
                MAXIMUM_DISTANCE_TO_WALL,
                null
            );

            if (pointOnWall) {
                const newClosingPoint = expandLine(
                    [tmpEmbeddedObjectEdge[0], pointOnWall],
                    objectLength
                )[1];

                if (
                    isPointOnLine(
                        newClosingPoint,
                        floorGraph.getLineStringOfEdge(
                            floorGraph.currentWallIdForEmbeddingWallSegment
                        )
                    ) &&
                    !arePointsEqual(
                        floorGraph.tmpEmbeddedObjectEdgeVar()[0],
                        newClosingPoint
                    )
                ) {
                    floorGraph.tmpEmbeddedObjectEdgeVar([
                        tmpEmbeddedObjectEdge[0],
                        newClosingPoint,
                    ]);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tmpEmbeddedObjectEdge.length, currentPoint]);

    // for segments, that not connected to any wall as second coordinate takes secondPoint
    if (!floorGraph.currentWallIdForEmbeddingWallSegment) {
        tmpEmbeddedObjectEdge = [tmpEmbeddedObjectEdge[0], secondPoint];
    }

    return (
        <>
            <Circle
                x={tmpEmbeddedObjectEdge[0].x}
                y={tmpEmbeddedObjectEdge[0].y}
                radius={WALL_CIRCLE_RADIUS}
                fill={TEMPORARY_EMBEDDED_OBJECT_CIRCLE_COLOR}
            />
            {tmpEmbeddedObjectEdge.length > 1 && (
                <>
                    <Circle
                        x={tmpEmbeddedObjectEdge[1].x}
                        y={tmpEmbeddedObjectEdge[1].y}
                        radius={WALL_CIRCLE_RADIUS}
                        fill={TEMPORARY_EMBEDDED_OBJECT_CIRCLE_COLOR}
                    />
                    <Line
                        id={TEMPORARY_WALL_ID}
                        points={getArrOfCoordinates(tmpEmbeddedObjectEdge)}
                        stroke={TEMPORARY_EMBEDDED_OBJECT_LINE_COLOR}
                        strokeWidth={TEMPORARY_WALL_STROKE_WIDTH}
                    />
                </>
            )}
        </>
    );
};

TemporaryEmbeddedSegment.propTypes = {
    tmpEmbeddedObjectEdge: PropTypes.arrayOf(
        PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
        })
    ),

    currentPoint: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
    }),

    secondPoint: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
    }),
};

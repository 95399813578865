import React, { memo } from 'react';
import { useReactiveVar } from '@apollo/client';
import PropTypes from 'prop-types';
import { Vertex } from './Vertex';
import floorGraph from '../../model/FloorGraph';
import selector from '../../../Selector/model/Selector';

/**
 * Component that represents vertices of graph in canvas
 * @component
 * @example <CanvasVertices visible={true} />
 */
export const CanvasVertices = memo(({ visible }) => {
    const graph = useReactiveVar(floorGraph.drawPointsVar);
    useReactiveVar(selector.selectedVerticesVar);

    return graph.map((pointLink) => {
        const id = pointLink.getId();
        const coordinates = pointLink.getCoordinates();
        const accessRule = pointLink.getAccessRule();
        return (
            <Vertex
                id={id}
                key={id}
                x={coordinates.x}
                y={coordinates.y}
                visible={visible && pointLink.getVisibility()}
                accessRule={accessRule}
                isSelected={selector.isVertexSelected(id)}
            />
        );
    });
});

CanvasVertices.propTypes = {
    visible: PropTypes.bool,
};

import { gql } from '@apollo/client';

export const ACCESS_GROUP_FRAGMENT = gql`
    fragment AccessGroupFragment on AccessGroup {
        id
        name
        description
        parentMirror
        floorCreationAvailable
        notificationCreationAvailable
        parent {
            id
        }
        childGroups {
            id
        }
        descendants {
            id
        }
        members {
            ... on User {
                id
                name
                surname
                email
            }
            ... on Bot {
                id
                name
                description
            }
        }
    }
`;

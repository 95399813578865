import React from 'react';

import folderImg from '../../../shared/assets/images/icons/folder.svg';

import { Button, ButtonAvailableProjects, NavContainer } from './Styles';

import { CreateButton } from './CreateButton';

/**
 * @param {Object[]} projects array of projects info
 * @param {function} setProjects function for changing parent's state with projects
 * @returns { Navigation } Component with different buttons
 */
export const Navigation = ({ projects, setProjects, isYourProjects, setIsYourProjects }) => {
    return (
        <NavContainer>
            <CreateButton projects={projects} setProjects={setProjects} />

            <Button 
                onClick={() => setIsYourProjects(!isYourProjects)}
                style={{backgroundColor: isYourProjects ? '#e1e8ff' : '#ffffff'}}
            >
                <img
                    src={folderImg}
                    alt='folder pict'
                    style={{
                        width: '14px',
                        height: '12px',
                        marginRight: '13px',
                    }}
                />
                Мои карты
            </Button>

            <ButtonAvailableProjects 
                onClick={() => setIsYourProjects(!isYourProjects)}
                style={{backgroundColor: !isYourProjects ? '#e1e8ff' : '#ffffff'}}
            >
                <img
                    src={folderImg}
                    alt='folder pict'
                    style={{
                        width: '14px',
                        height: '12px',
                        marginRight: '13px',
                    }}
                />
                Доступные
            </ButtonAvailableProjects>
        </NavContainer>
    );
};

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import searchIcon from '../../assets/images/icons/search.svg';
import { Input } from './Styles';

const SearchContainer = styled.div`
    position: relative;
    width: 100%;
    box-sizing: border-box;
`;

const SearchImg = styled.img`
    height: 21px;
    width: auto;
    position: absolute;
    right: 10px;
    top: 50%;
    translateY: -50%;
    transform: translateY(calc(-50% + 1px));
`;

/**
 * Component represents search bar input
 * @component
 */
export const SearchBar = ({ placeholder, ...props }) => {

    return (
        <SearchContainer>
            <Input
                onKeyDown={(e) => e.stopPropagation()}
                placeholder={placeholder}
                name='SearchBar'
                autoComplete='true'
                {...props}
            />
            <SearchImg src={searchIcon} alt='search' />
        </SearchContainer>
    );
};

SearchBar.propTypes = {
    placeholder: PropTypes.string,
};

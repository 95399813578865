import { stageVar } from '../../../../../shared/model/cache/Cache';
import cursorModerator, {
    CURSORS,
} from '../../../../../shared/lib/utils/common/CursorModerator';

/**
 * Handler to change cursor style when point on object
 * we can interact with
 * @param {string} [cursor='pointer']
 */
export const handleMouseEnter = (cursor = CURSORS.POINTER) => {
    if (stageVar()) {
        cursorModerator.setByValue(cursor);
    }
};

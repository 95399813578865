/**
 * Sorts the pointLinks by the x coordinates of the corresponding vertices
 * If order is positive or zero, sorts in ascending order, if negative, sorts in descending order.
 * @param {PointLinkWrapper[]} array array of pointLinks
 * @param {Number} order
 * @returns {PointLinkWrapper[]}
 */
export const sortVertexIdsByX = (array, order) => {
    order = Math.sign(order) || 1;
    return array
        .slice()
        .sort((a, b) => order * (a.getCoordinates().x - b.getCoordinates().x));
};

/**
 * Sorts the pointLinks by the y coordinates of the corresponding vertices
 * If order is positive or zero, sorts in ascending order, if negative, sorts in descending order.
 * @param {PointLinkWrapper[]} array array of pointLinks
 * @param {Number} order
 * @returns {PointLinkWrapper[]}
 */
export const sortVertexIdsByY = (array, order) => {
    order = Math.sign(order) || 1;
    return array
        .slice()
        .sort((a, b) => -order * (a.getCoordinates().y - b.getCoordinates().y));
};

import floorGraph from '../../../features/Wall/model/FloorGraph';
import zones from '../../../features/Zone/model/Zones';
import { getFloor } from '../api/GetFloor';
import { initializeConstraints } from './InitializeConstraints';
import { initializeAlerts } from '../../../features/Alert/lib/utils/InitializeAlerts';

export const loadFloor = async (floorId) => {
    return getFloor(floorId)
        .then((r) => {
            floorGraph.initialize({
                graph: r.data.floor.wall.graph,
                types: r.data.floor.wall.types,
            });
            initializeAlerts(r.data.floor.attentionMarkers);
            zones.initialize(r.data.floor.zones);
            initializeConstraints(r.data.floor.constraintGroups);
        })
        .finally(() => {
            floorGraph.rerender();
        })
        .catch((e) => {
            throw new Error(e.message);
        });
};

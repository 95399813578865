import { gql } from '@apollo/client';

export const COLLAPSE_WALL_SEGMENT = gql`
    mutation collapseWallSegment(
        $segmentSelector: VersionedSegmentSelector!
        $savePointSelector: WallPointInPlace!
    ) {
        collapseWallSegment(
            segmentSelector: $segmentSelector
            savePointSelector: $savePointSelector
        ) {
            createdSegments {
                version
                endPoint {
                    coordinate {
                        x
                        y
                    }
                    id
                }
                id
                lengthRestriction
                startPoint {
                    coordinate {
                        x
                        y
                    }
                    id
                }
                width
                through
                secondZone {
                    id
                }
                firstZone {
                    id
                }
                type {
                    id
                    length
                    width
                    scalable
                    through
                    virtual
                    imageOffsetCoefficient
                    image {
                        id
                        src
                        type
                    }
                    techImage {
                        id
                        src
                        type
                    }
                }
            }
            updatedSegments {
                version
                endPoint {
                    coordinate {
                        x
                        y
                    }
                    id
                }
                id
                lengthRestriction
                startPoint {
                    coordinate {
                        x
                        y
                    }
                    id
                }
                width
                through
                secondZone {
                    id
                }
                firstZone {
                    id
                }
                type {
                    id
                    length
                    width
                    scalable
                    through
                    virtual
                    imageOffsetCoefficient
                    image {
                        id
                        src
                        type
                    }
                    techImage {
                        id
                        src
                        type
                    }
                }
            }
            zones {
                id
                version
                shape {
                    outer {
                        id
                        coordinate {
                            x
                            y
                        }
                    }
                }
            }
        }
    }
`;

import React from 'react';
import { Circle } from 'react-konva';
import {
    closestWallPointVar,
    currentToolVar,
} from '../../../shared/model/cache/Cache';
import { useReactiveVar } from '@apollo/client';

import {
    CLOSEST_WALL_CIRCLE_FILL,
    CLOSEST_WALL_CIRCLE_OPACITY,
    FIND_POINT_RADIUS,
} from '../../../widgets/Canvas/lib/CanvasDefaultValues';
import { Tools } from '../../../pages/Constructor/TopBar/TopBarEntires/Tools/Constants/Tools';
import FloorGraph from '../model/FloorGraph';

/**
 * Represents points radius on the wall for which cursor is snapped
 * @component
 * @example
 *  <WallPointAttractionArea />
 */
export const WallPointAttractionArea = () => {
    const closestWallPoint = useReactiveVar(closestWallPointVar);

    const shouldAreaRender = () => {
        const isClosestWallPointSet =
            closestWallPoint && closestWallPoint.point;

        const isCurrentToolAcceptable =
            currentToolVar() === Tools.wall ||
            (currentToolVar() === Tools.embeddedObject &&
                FloorGraph.tmpEmbeddedObjectEdgeVar().length < 1);

        const isAnyWallExists = FloorGraph.drawSegmentsVar().length > 0;

        return (
            isClosestWallPointSet && isCurrentToolAcceptable && isAnyWallExists
        );
    };

    return (
        shouldAreaRender() && (
            <Circle
                x={closestWallPoint.point.x}
                y={closestWallPoint.point.y}
                radius={FIND_POINT_RADIUS}
                fill={CLOSEST_WALL_CIRCLE_FILL}
                opacity={CLOSEST_WALL_CIRCLE_OPACITY}
                listening={false}
                shadowForStrokeEnabled={false}
                perfectDrawEnabled={false}
            />
        )
    );
};

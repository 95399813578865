import { gql } from '@apollo/client';

export const UPDATE_PROJECT = gql`
    mutation updateProject($projectDiff: ProjectDiff!) {
        updateProject(projectDiff: $projectDiff) {
            id
            name
            owner {
                id
            }
            description
            accuracy
        }
    }
`;

import React from 'react';
import styled from 'styled-components';
import { inputStatus } from './FormConstants';

/**
 * Styled container for form component
 */
const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0;
    margin-bottom: 17px;
`;

/**
 * Styled error message
 */
export const ValidateMsg = styled.div`
    color: black;
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: 0em;
    white-space: nowrap;

    height: 0;
    width: 0;
    position: relative;

    &.success {
        color: #00940F;
    }

    &.error {
        color: red;
    }

    &.warning {
        color: yellow;
    }
`;

/**
 * Wrapper for form component, that adds a validation message
 * Use this as wrapper for form component
 * Example: <FormComponent><Input/></FormComponent>
 * @returns { JSX.Element } Input component for forms
 */
export const FormComponent = ({ children, hintMsgType = inputStatus.hint, hintMsg, className }) => {

    // needed to specify validate message color
    // hintmsg - css class for validation message
    // also every msg has it own css class: hint (default), error, warning, success
    const validateMsgClassName = 'hintmsg ' +
        (
            (hintMsgType === inputStatus.error)
            || (hintMsgType === inputStatus.warning)
            || (hintMsgType === inputStatus.success)
        ) ? hintMsgType : inputStatus.hint;

    return( 
        <Container className={className}>
            <span>{children}</span>
            <ValidateMsg className={validateMsgClassName}>
                {hintMsg}
            </ValidateMsg>
        </Container>
    );
};

import React from 'react';
import styled from 'styled-components';
import { SCROLLBAR_STYLED } from '../../../../shared/ui/ScrollBar';
import { DefaultRightBar } from './DefaultRightBar/DefaultRightBar';
import { RightBarOperationProps } from '../model/RightBarModerator';

const RightBarBodyContainer = styled.div<{ isOpened: boolean }>`
    background-color: white;
    padding: 0;
    margin: 3px;
    max-height: ${({ isOpened }) => (isOpened ? ' 100%' : '0px')};
    box-sizing: border-box;
    overflow: auto;
    ${SCROLLBAR_STYLED}

    &:hover .collapseIcon {
        opacity: 100%;
    }
`;

interface RightBarBodyProps {
    /** Is right bar opened */
    isOpened: boolean;

    /** Operation for right bar */
    operation: RightBarOperationProps;
}

/**
 * Component that represents container for right bar
 * @component
 */
export const RightBarBody = ({ isOpened, operation }: RightBarBodyProps) => {
    return (
        <RightBarBodyContainer isOpened={isOpened}>
            {operation !== null &&
            operation.customRightBar &&
            operation.component ? (
                operation.component
            ) : (
                <DefaultRightBar />
            )}
        </RightBarBodyContainer>
    );
};

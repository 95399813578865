import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useReactiveVar } from '@apollo/client';

import commandManager from '../../../CommandManager/model/CommandManager';
import { closeContextMenu } from '../../model/CloseContextMenu';
import floorGraph, { FloorGraph } from '../../../Wall/model/FloorGraph';
import { changeEdgeTypeCommand } from '../../../CommandManager/lib/commands/wall/ChangeEdgeType';
import {
    contextMenuVar,
    currentFloorVar,
    stageVar,
} from '../../../../shared/model/cache/Cache';
import {
    BASIC_EMBEDDED_OBJECTS_FOLDER_NAME,
    BASIC_WALLS_FOLDER_NAME,
} from '../../../../pages/Constructor/RightBar/ui/DefaultRightBar/Tabs/Constants';
import constructorPageHints from '../../../HintSystem/model/ConstructorPageHints';
import notifications from '../../../HintSystem/model/Notifications';
import wallSegmentsApi from '../../../Wall/api/WallSegmentsAPI';
import { findAttractionPoint } from '../../../Wall/lib/helpers/WallPointAttraction';
import { LinkedSegment } from '../../../Wall/model/SegmentsModerator';
import { loadFloor } from '../../../../pages/Constructor/model/LoadFloor';
import zones from '../../../Zone/model/Zones';
import scalesConformer from '../../../ScalesConformer/model/ScalesConformer';
import selector from '../../../Selector/model/Selector';
import { PointsForContextMenu } from '../styles/PointsForContextMenu';
import {
    activateMapinsForm,
    objectWithSchemaTypes,
} from '../../../../shared/model/mapinsForm/activateMapinsForm';
import rightBarModerator from '../../../../pages/Constructor/RightBar/model/RightBarModerator';
import { RightBarOperation } from '../../../../pages/Constructor/RightBar/model/RightBarOperations';

const ChangeTypeContextMenuTitle = styled.div`
    white-space: nowrap;
    box-sizing: border-box;
    padding: 0 10px 5px 10px;
    margin-bottom: 5px;
    border-bottom: 1px solid gray;
`;

const ChangeTypeContextMenuContainer = styled.div`
    width: 'fit-content';
`;

/**
 * Component that represents a context menu for wall
 *
 * @component
 * @example
 * <WallContextMenu edgeId={edgeId} />
 */
export const WallContextMenu = ({ edgeId }) => {
    const [pointId1, pointId2] = LinkedSegment.splitKey(edgeId);
    const operation = useReactiveVar(rightBarModerator.operation);
    useReactiveVar(rightBarModerator.selectedObject);

    const edge = {
        key: edgeId,
        type: floorGraph.segmentsModerator
            .getSegment(pointId1, pointId2)
            .getType(),
    };

    const handleChangeTypeCancel = () => {
        closeContextMenu(true);
        rightBarModerator.clearOperation();
    };

    const handleChangeTypeStart = () => {
        constructorPageHints.setInfoHint(
            'Подсказка',
            'Выберите тип сегмента в правом меню'
        );

        contextMenuVar({
            ...contextMenuVar(),
            isClosable: false,
        });

        rightBarModerator.openFolders(
            BASIC_WALLS_FOLDER_NAME,
            BASIC_EMBEDDED_OBJECTS_FOLDER_NAME
        );
        rightBarModerator.selectedObject(edge.type.id);
        rightBarModerator.setOperation({
            operationName: RightBarOperation.CHANGE_SEGMENT_TYPE,
            customRightBar: false,
            onClose: () => closeContextMenu(true),
        });
    };

    const handleChangeTypeConfirm = () => {
        closeContextMenu(true);
        rightBarModerator.clearOperation();
        rightBarModerator.selectedObject(null);

        commandManager.do(
            changeEdgeTypeCommand(
                edge.key,
                floorGraph.currentWallSegmentTypeVar()
            )
        );

        try {
            wallSegmentsApi
                .update({
                    selector: {
                        points: {
                            firstPointId: pointId1,
                            secondPointId: pointId2,
                        },
                    },
                    version: floorGraph.segmentsModerator
                        .getSegment(pointId1, pointId2)
                        .getVersion(),
                    typeId: floorGraph.currentWallSegmentTypeVar().id,
                })
                .then((res) => {
                    res?.forEach((segment) => {
                        floorGraph.segmentsModerator.updateVersion(
                            segment.startPoint.id,
                            segment.endPoint.id,
                            segment.version
                        );
                    });
                    floorGraph.rerender();
                });
        } catch (error) {
            notifications.setError('Сегмент не изменён', error.message);
        }
    };

    const handleDivide = async () => {
        const splitPoint = findAttractionPoint(
            floorGraph.getLineStringOfEdge(edgeId),
            stageVar().getRelativePointerPosition(),
            +Number(scalesConformer.toPixels(edge.type.width)) / 2 + 1
        );
        const segmentVersion = floorGraph.segmentsModerator
            .getSegment(pointId1, pointId2)
            .getVersion();

        const resultOfDividing = floorGraph.segmentsModerator.divideSegment(
            splitPoint,
            edgeId
        );

        if (resultOfDividing) {
            closeContextMenu(true);

            await wallSegmentsApi
                .split(
                    {
                        points: {
                            firstPointId: pointId1,
                            secondPointId: pointId2,
                        },
                        version: segmentVersion,
                    },
                    splitPoint
                )
                .then((response) => {
                    const [walls, updatedZones] = response;
                    if (response) {
                        selector.clear();
                        floorGraph.replaceAfterFetch(
                            [resultOfDividing.newPoint],
                            walls
                        );
                        zones.updateZoneBorder(updatedZones);
                        floorGraph.rerender();
                        zones.rerender();
                    }
                })
                .catch(async (error) => {
                    notifications.setError(
                        'Сегмент не разделён',
                        error.message
                    );
                    await loadFloor(currentFloorVar().id);
                });
        } else {
            notifications.setError('Ошибка', 'Не удалось разделить сегмент');
        }
    };

    const handleDelete = () => {
        const segmentVersion = floorGraph.segmentsModerator
            .getSegment(pointId1, pointId2)
            .getVersion();

        floorGraph.segmentsModerator.deleteSegment(pointId1, pointId2);
        floorGraph.rerender();
        closeContextMenu(true);

        wallSegmentsApi
            .delete({
                points: {
                    firstPointId: pointId1,
                    secondPointId: pointId2,
                },
                version: segmentVersion,
            })
            .catch(async (error) => {
                notifications.setError(
                    'Не удалось удалить сегмент',
                    error.message
                );
                await loadFloor(currentFloorVar().id);
            });
    };

    const handleDeleteSelected = async () => {
        if (selector.selectedEdgesVar().size > 0) {
            const selectedSegmentsLinks = [];

            for (const [key, graph] of selector.selectedEdgesVar().entries()) {
                if (Object.getPrototypeOf(graph) === FloorGraph.prototype) {
                    const [id1, id2] = LinkedSegment.splitKey(key);
                    selectedSegmentsLinks.push(
                        graph.segmentsModerator.getSegment(id1, id2)
                    );
                }
            }
            await floorGraph.removeSegments(selectedSegmentsLinks).then(() => {
                floorGraph.rerender();
                closeContextMenu(true);
            });
        }
    };

    const handleActivateMapinsForm = () => {
        activateMapinsForm(
            floorGraph.segmentsModerator
                .getSegment(pointId1, pointId2)
                .getInfo(),
            objectWithSchemaTypes.wallSegment
        );
        closeContextMenu();
    };

    const typeChangeMenu = [
        {
            text: 'Подтвердить',
            onClick: handleChangeTypeConfirm,
        },
        {
            text: 'Отменить',
            onClick: handleChangeTypeCancel,
        },
    ];

    const mainMenu = [
        {
            text: 'Открыть форму',
            onClick: handleActivateMapinsForm,
        },
        {
            text: 'Сменить тип сегмента',
            onClick: handleChangeTypeStart,
        },
        {
            text: 'Разделить сегмент',
            onClick: handleDivide,
        },
        {
            text: 'Удалить сегмент',
            onClick: handleDelete,
        },
        {
            text: 'Удалить выделенные сегменты',
            onClick: handleDeleteSelected,
        },
    ];

    return (
        <>
            {operation?.operationName ===
            RightBarOperation.CHANGE_SEGMENT_TYPE ? (
                <ChangeTypeContextMenuContainer>
                    <ChangeTypeContextMenuTitle>
                        {floorGraph.currentWallSegmentTypeVar().name}{' '}
                    </ChangeTypeContextMenuTitle>
                    <PointsForContextMenu menu={typeChangeMenu} />
                </ChangeTypeContextMenuContainer>
            ) : (
                <PointsForContextMenu menu={mainMenu} />
            )}
        </>
    );
};
WallContextMenu.propTypes = {
    /**
     * id of clicked wall
     */
    wallId: PropTypes.string,

    /**
     * true if wall is virtual
     */
    isVirtual: PropTypes.bool,
};

import React from 'react';
import routesModerator from '../../../Route/model/RoutesModerator';
import { PointsForContextMenu } from '../styles/PointsForContextMenu';
import { closeContextMenu } from '../../model/CloseContextMenu';
import { currentToolVar } from '../../../../shared/model/cache/Cache';
import { Tools } from '../../../../pages/Constructor/TopBar/TopBarEntires/Tools/Constants/Tools';

/**
 * Route context menu
 * @component
 */
export const RouteCreationContextMenu = () => {
    const handleCancelRouteCreation = () => {
        const route = routesModerator.getCurrentRoute();
        route.graph.cancelCreating();
        routesModerator.cancelCreating();
        currentToolVar(Tools.none);
        closeContextMenu();
    };

    const handleResetBeginningOfSegment = () => {
        const route = routesModerator.getCurrentRoute();
        route.graph.tmpEdgeVar(new Map());
        closeContextMenu();
    };

    const menu = [
        {
            text: 'Сбросить начало сегмента',
            onClick: handleResetBeginningOfSegment,
        },
        {
            text: 'Завершить построение',
            onClick: handleCancelRouteCreation,
        },
        {
            text: 'Закрыть меню',
            onClick: closeContextMenu,
        },
    ];

    return <PointsForContextMenu menu={menu} />;
};

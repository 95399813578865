import { makeVar } from '@apollo/client';
import { layerVar } from '../../../shared/model/cache/Cache';
import {
    EMBEDDED_OBJECT_NAME,
    WALL_CIRCLE_NAME,
    WALL_EDGE_NAME,
} from '../../Wall/lib/helpers/WallDefaultValues';
import { ROUTE_EDGE_NAME } from '../../Route/lib/RouteDefaultValues';
import { LinkedSegment } from '../../Wall/model/SegmentsModerator';
import {
    closeSegmentProperties,
    openSegmentProperties,
} from '../../Wall/lib/helpers/OpenSegmentProperties';
import floorGraph from '../../Wall/model/FloorGraph';

/**
 * @typedef {import('../../Wall/model/FloorGraph').FloorGraph} FloorGraph
 * @typedef {import('../../Route/model/RouteGraph').RouteGraph} RouteGraph
 */

/**
 * Reactive var for storing selected edges:
 * key - edge UUID,
 * value - FloorGraph instance
 * @callback SelectedEdges
 * @param {Map<string, FloorGraph | RouteGraph>} map
 * @return {Map<string, FloorGraph | RouteGraph>}
 */

/**
 * Reactive var for storing selected vertices:
 * key - vertex UUID,
 * value - FloorGraph instance
 * @callback SelectedVertices
 * @param {Map<string, FloorGraph | RouteGraph>}
 * @return {Map<string, FloorGraph | RouteGraph>}
 */

/**
 * Reactive var for last selected segment:
 * @callback LastSelectedSegment
 * @param {LinkedSegment}
 * @return {LinkedSegment}
 */

/**
 * Class that represents selected walls state and methods of interaction
 */
class Selector {
    constructor() {
        /**
         * Reactive variable for storing selectedEdges
         * @returns {SelectedEdges} selected edges map
         */
        this.selectedEdgesVar = makeVar(new Map());

        /**
         * Reactive variable for storing selectedVerticesIds
         * @returns {SelectedVertices} selected vertices map
         */
        this.selectedVerticesVar = makeVar(new Map());

        /**
         * Reactive variable for storing diagonal points for creating rect (clickedPos and currentMousePointerPos)
         * @returns {Array<Point>} points of rect
         */
        this.rectSelectorPointsVar = makeVar(null);

        /**
         * Reactive variable for storing hovered edge
         * (is used for showing only one hovered edge)
         * @returns {String} hovered edge
         */
        this.isHoveredVar = makeVar(null);

        /**
         * Reactive variable for storing rectSelectorRef.current
         * @returns {Object} RectSelector
         */
        this.rectSelectorVar = makeVar(null);

        /**
         * Saving timeStamp for scroll delay
         */
        this.scrollTimeStamp = 0;

        /**
         * Ids of selected vertices by scrolling.
         * Need for saving history of ids of vertices
         * to continue select and deselect.
         */
        this.scrollVerticesIds = [];

        /**
         * Current index in scrollVerticesIds to step
         * through the history of selection
         */
        this.scrollCurrentIndex = 0;

        /**
         * Var for storing edges UUIDs grouped by graph.
         * This var is uses for optimize dragging of selecting vertices.
         * Avoid to use this var in other cases.
         *
         * key - graph object,
         * value - array of selected edges UUIDs
         * @type {Map<FloorGraph | RouteGraph, string[]> | null}
         */
        this.tempMapOfEdgesGroupedByGraphs = null;

        /**
         * Var for storing last selected segment for
         * manipulating its properties from right bar
         * @type {LastSelectedSegment}
         */
        this.lastSelectedSegmentVar = makeVar(null);
    }

    /**
     * Method for selecting clicked edge
     * @param {String} edgeId uuid key of the edge
     * @param {FloorGraph | RouteGraph} graph
     */
    selectEdge(edgeId, graph) {
        if (this.isEdgeSelected(edgeId)) {
            this.deselectEdge(edgeId);
            // close segment properties for floor graph segment
            closeSegmentProperties();
        } else {
            this.selectedEdgesVar(
                new Map(this.selectedEdgesVar().set(edgeId, graph))
            );

            // open segment properties for floor graph segment
            if (graph === floorGraph) {
                openSegmentProperties(edgeId);
            }
        }
    }

    addLastSelectedSegment(segmentId) {
        this.lastSelectedSegmentVar(
            floorGraph.segmentsModerator.getSegment(
                ...LinkedSegment.splitKey(segmentId)
            )
        );
    }

    removeLastSelectedSegment() {
        this.lastSelectedSegmentVar(null);
    }

    updateLastSelectedSegment() {
        if (this.lastSelectedSegmentVar() !== null) {
            this.lastSelectedSegmentVar(
                new LinkedSegment(
                    this.lastSelectedSegmentVar()?.firstPointLinkWrapper,
                    this.lastSelectedSegmentVar()?.secondPointLinkWrapper,
                    this.lastSelectedSegmentVar()?.segmentInfo
                )
            );
        }
    }

    /**
     * Method for selecting clicked vertices
     * @param {string} vertexId uuid key of the edge
     * @param {FloorGraph | RouteGraph} graph FloorGraph or its heir
     */
    selectVertex(vertexId, graph) {
        if (this.isVertexSelected(vertexId)) {
            // deselect vertex
            this.selectedVerticesVar().delete(vertexId);
            this.selectedVerticesVar(new Map(this.selectedVerticesVar()));
        } else {
            const connectedEdgesFromVertexToSelected =
                this.getConnectedToVertexEdges(vertexId, graph);

            if (connectedEdgesFromVertexToSelected.length > 0) {
                for (const connectedEdgeId of connectedEdgesFromVertexToSelected) {
                    if (this.isEdgeSelected(connectedEdgeId)) {
                        continue;
                    }
                    this.selectEdge(connectedEdgeId, graph);
                }
                this.selectedVerticesVar(new Map());
                return;
            }

            this.selectedVerticesVar(
                new Map(this.selectedVerticesVar().set(vertexId, graph))
            );
        }

        if (this.selectedVerticesVar().size === 2) {
            this.addSelectedEdgeByVertices();
            this.selectedVerticesVar(new Map());
        }
    }

    /**
     * Method for getting all connected to vertex edges
     * @param {String} vertexId uuid
     * @param {FloorGraph | RouteGraph} graph FloorGraph or its heir
     * @returns {Array<String>} edge keys
     */
    getConnectedToVertexEdges(vertexId, graph) {
        const edgesArr = Array.from(
            this.selectedEdgesVar(),
            ([edgeId]) => edgeId
        );
        return [
            ...new Set(
                edgesArr
                    .map((edgeId) => {
                        const formedKey = LinkedSegment.splitKey(edgeId);
                        const adjacentEdges = [];
                        if (
                            graph
                                .getVertex(formedKey[0])
                                ?.adjacentPoints.includes(vertexId)
                        ) {
                            adjacentEdges.push(
                                [vertexId, formedKey[0]].join(',')
                            );
                        }

                        if (
                            graph
                                .getVertex(formedKey[1])
                                ?.adjacentPoints.includes(vertexId)
                        ) {
                            adjacentEdges.push(
                                [vertexId, formedKey[1]].join(',')
                            );
                        }

                        return adjacentEdges;
                    })
                    .flat()
            ),
        ];
    }

    /**
     * Method to select edge by two points
     */
    addSelectedEdgeByVertices() {
        const vertices = Array.from(
            this.selectedVerticesVar(),
            ([vertexId, graph]) => ({ vertexId: vertexId, graph: graph })
        );

        if (
            vertices[0].graph === vertices[1].graph &&
            vertices[0].graph.areVerticesAdjacent(
                vertices[0].vertexId,
                vertices[1].vertexId
            )
        ) {
            const edgeId = vertices[0].vertexId + ',' + vertices[1].vertexId;
            this.selectEdge(edgeId, vertices[0].graph);
        }
    }

    /**
     * Method with associative check if edge is selected
     * @param {String} edgeId uuid
     * @returns {Boolean}
     */
    isEdgeSelected(edgeId) {
        if (!edgeId) {
            return false;
        }

        return (
            this.selectedEdgesVar().has(edgeId) ||
            this.selectedEdgesVar().has(this.reverseVerticesIdsInEdgeId(edgeId))
        );
    }

    /**
     * Method with check if vertex is selected
     * @param {string} vertexId vertex uuid
     * @returns {bool} true if selected, false if not
     */
    isVertexSelected(vertexId) {
        return this.selectedVerticesVar().has(vertexId);
    }

    /**
     * Method to deselect edge if associative check
     * @param {String} edgeId uuid
     */
    deselectEdge(edgeId) {
        if (!edgeId) {
            return false;
        }

        if (this.selectedEdgesVar().has(edgeId)) {
            this.selectedEdgesVar().delete(edgeId);
            this.selectedEdgesVar(new Map(this.selectedEdgesVar()));
        } else {
            const reverseEdgeId = this.reverseVerticesIdsInEdgeId(edgeId);
            if (this.selectedEdgesVar().has(reverseEdgeId)) {
                this.selectedEdgesVar().delete(reverseEdgeId);
                this.selectedEdgesVar(new Map(this.selectedEdgesVar()));
            }
        }
    }

    /**
     * Method for selecting from rect area all edges
     */
    selectFromRectArea() {
        const selectionBox = this.rectSelectorVar()?.getClientRect();
        if (selectionBox) {
            const selectedShapes = layerVar().children.filter(
                (shape) =>
                    (shape.attrs.name === WALL_EDGE_NAME ||
                        shape.attrs.name === ROUTE_EDGE_NAME ||
                        shape.attrs.name === EMBEDDED_OBJECT_NAME) &&
                    this.isRectInsideSelectRectArea(
                        selectionBox,
                        shape.getClientRect()
                    )
            );
            this.selectedEdgesVar(
                new Map(
                    selectedShapes.map((shape) => [
                        shape.id(),
                        shape.attrs.graph,
                    ])
                )
            );
        }

        this.rectSelectorPointsVar([]);
    }

    /**
     *
     * @param {Konva.Shape} selectRectArea rect bound
     * @param {Konva.Shape} shapeBoundRect rect bound
     * @returns
     */
    isRectInsideSelectRectArea = (selectRectArea, shapeBoundRect) => {
        if (
            shapeBoundRect.x > selectRectArea.x &&
            shapeBoundRect.x + shapeBoundRect.width <
                selectRectArea.x + selectRectArea.width &&
            shapeBoundRect.y > selectRectArea.y &&
            shapeBoundRect.y + shapeBoundRect.height <
                selectRectArea.y + selectRectArea.height
        ) {
            return true;
        }
        return false;
    };

    /**
     *
     * @param {String} edgeId edge key
     * @returns {String} reversed edgeId
     */
    reverseVerticesIdsInEdgeId = (edgeId) => {
        return LinkedSegment.splitKey(edgeId).reverse().join(',');
    };

    /***
     * Method for selecting edges by scrolling
     * @param {Object} e MouseWheel event
     * @param {FloorGraph | RouteGraph} graph FloorGraph or its heir
     */
    selectByKeyboardKeyAndScroll(e, graph) {
        if (!e.target || e.evt.timeStamp - this.scrollTimeStamp < 200) {
            return;
        }

        this.scrollTimeStamp = e.evt.timeStamp;

        if (e.evt.deltaY > 0) {
            if (
                this.selectedEdgesVar().size === graph.drawSegmentsVar().length
            ) {
                return;
            }

            if (this.selectFirstEdgeOrVertexOnScroll(e, graph)) return;
            let adjacentPoints;

            if (this.scrollCurrentIndex < this.scrollVerticesIds.length) {
                adjacentPoints = [
                    ...graph.getVertex(
                        this.scrollVerticesIds[this.scrollCurrentIndex]
                    ).adjacentPoints,
                ].filter(
                    (vertexId) => !this.scrollVerticesIds.includes(vertexId)
                );

                if (
                    adjacentPoints.length > 2 &&
                    this.scrollVerticesIds.length !== 1 &&
                    this.scrollCurrentIndex < this.scrollVerticesIds.length
                ) {
                    this.scrollCurrentIndex++;
                    return;
                }
            } else {
                return;
            }

            this.scrollVerticesIds.push(...adjacentPoints);

            this.selectVertex(
                this.scrollVerticesIds[
                    this.scrollVerticesIds.indexOf(adjacentPoints[0])
                ],
                graph
            );

            this.scrollCurrentIndex++;
        } else {
            if (
                this.scrollCurrentIndex < 0 &&
                this.scrollVerticesIds.length === 0
            ) {
                return;
            }

            this.deselectEdge([...this.selectedEdgesVar().keys()].pop());
            this.scrollVerticesIds.pop();
            this.scrollCurrentIndex--;

            if (this.scrollCurrentIndex < 0) {
                this.scrollCurrentIndex = 0;
            }
        }
    }

    selectFirstEdgeOrVertexOnScroll(e, graph) {
        if (
            e.target.attrs.name === WALL_CIRCLE_NAME &&
            !this.scrollVerticesIds.includes(e.target.id())
        ) {
            this.scrollVerticesIds.push(e.target.id());
            this.selectVertex(e.target.id(), graph);
            this.scrollCurrentIndex = this.scrollVerticesIds.indexOf(
                e.target.id()
            );

            return true;
        }
        if (
            e.target.attrs.name === WALL_EDGE_NAME &&
            !this.isEdgeSelected(e.target.id())
        ) {
            const [vertexId1, vertexId2] = LinkedSegment.splitKey(
                e.target.id()
            );
            this.scrollVerticesIds.push(vertexId1, vertexId2);
            this.selectEdge(e.target.id(), graph);
            this.scrollCurrentIndex = this.scrollVerticesIds.indexOf(vertexId1);

            return true;
        }

        return false;
    }

    /**
     * Method group all selected edges by graph and
     * place them into tempMapOfEdgesGroupedByGraphs.
     *
     * Creates map where:
     * key - graph object,
     * value - array of selected edges UUIDs
     * @returns {Map<FloorGraph | RouteGraph, string[]>}
     */
    groupEdgesByGraphs() {
        this.tempMapOfEdgesGroupedByGraphs = new Map();
        [...this.selectedEdgesVar()].forEach(([edgeId, graph]) => {
            if (!this.tempMapOfEdgesGroupedByGraphs.has(graph)) {
                this.tempMapOfEdgesGroupedByGraphs.set(graph, []);
            }
            this.tempMapOfEdgesGroupedByGraphs.get(graph).push(edgeId);
        });
        return this.tempMapOfEdgesGroupedByGraphs;
    }

    clear() {
        this.selectedVerticesVar(new Map());
        this.selectedEdgesVar(new Map());

        this.rectSelectorPointsVar([]);

        this.isHoveredVar(null);

        this.scrollTimeStamp = 0;
        this.scrollVerticesIds = [];
        this.scrollCurrentIndex = 0;

        this.tempMapOfEdgesGroupedByGraphs = null;

        // close segment properties for floor graph segment
        closeSegmentProperties();
    }
}

const selector = new Selector();
export default selector;

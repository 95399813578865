import jwtDecode from 'jwt-decode';
import { currentUserVar } from '../../../model/cache/Cache';
import { REFRESH_TOKEN } from '../../LocalStorageConstants';

/**
 * Function to set current user after login or reloading page (being authorized)
 */
export const setCurrentUser = () => {
    currentUserVar({
        ...currentUserVar(),
        id: jwtDecode(localStorage.getItem(REFRESH_TOKEN)).id,
        isAuth: true,
    });
};

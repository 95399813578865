import { makeVar } from '@apollo/client';

/**
 * @typedef {import('../../../shared/typedefs/typedefs').StringReactiveVar} StringReactiveVar
 * @typedef {import('../api/AccessGroupsAPI').AccessGroupDraft} AccessGroupDraft
 */

/**
 * @callback ChildAccessGroups
 * @param {string[]}
 * @return {string[]}
 */

/**
 * Describes access group available property
 * @typedef {{
 *     id: string?,
 *     name: string?,
 *     description: string?,
 *     parent: string?,
 *     childGroups: string[]?,
 *     descendants: string[]?,
 *     members: Array<UserMember|RobotMember>?,
 *     floorCreationAvailable: boolean?,
 *     parentMirror: boolean?,
 * }} AccessGroupData
 */

/**
 * Describes user as member of access group
 * @typedef {{
 *     id: string?,
 *     name: string?,
 *     surname: string?,
 *     email: string?,
 * }} UserMember
 */

/**
 * Describes robot as member of access group
 * @typedef {{
 *     id: string?,
 *     name: string?,
 *     description: string?,
 * }} RobotMember
 */

/**
 * @class
 * Class describes concrete access group
 */
export class AccessGroup {
    /**
     * @constructor
     * @param {string} routeId - Access group UUID
     * @param {AccessGroupData} extra - Extra properties
     * @param {Array<UserMember|RobotMember>} [extra.members=[]] - Users who are in this group
     */
    constructor(
        groupId,
        {
            name = '',
            description = '',
            parentMirror = false,
            floorCreationAvailable = false,
            notificationCreationAvailable = false,
            parent = null,
            childGroups = [],
            descendants = [],
            members = [],
        }
    ) {
        /** Access group UUID */
        this.groupId = groupId;

        /**
         * Access group name
         * @type {StringReactiveVar}
         */
        this.name = makeVar(name);

        /**
         * Access group description
         * @type {StringReactiveVar}
         */
        this.description = makeVar(description);

        /**
         * Is group parent mirror
         * @type {boolean}
         */
        this.parentMirror = parentMirror;

        /**
         * Can this group users create floors or not
         * @type {boolean}
         */
        this.floorCreationAvailable = floorCreationAvailable;

        /**
         * Can this group users create notifications on map
         * @type {boolean}
         */
        this.notificationCreationAvailable = notificationCreationAvailable;

        /**
         * UUID of parent access group
         * @type {string}
         */
        this.parent = parent;

        /**
         * Children groups UUIDs.
         * Include only direct children
         * @type {ChildAccessGroups}
         */
        this.childGroups = makeVar([...childGroups]);

        /**
         * Descendants groups UUIDs.
         * Descendants includes all child groups, and child groups of child groups and so on
         * @type {ChildAccessGroups}
         */
        this.descendants = makeVar([...descendants]);

        /**
         * Group members
         * @type {Array<UserMember|RobotMember>}
         */
        this.members = makeVar([...members]);
    }

    /**
     * Method to update group property
     * @param {AccessGroupDraft} groupData
     */
    update({
        name = this.name(),
        description = this.description(),
        parentMirror = this.parentMirror,
        floorCreationAvailable = this.floorCreationAvailable,
        notificationCreationAvailable = this.notificationCreationAvailable,
        parent = this.parent,
        childGroups = this.childGroups(),
        descendants = this.descendants(),
        members = this.members(),
    }) {
        this.name(name);
        this.description(description);
        this.parentMirror = parentMirror;
        this.floorCreationAvailable = floorCreationAvailable;
        this.notificationCreationAvailable = notificationCreationAvailable;
        this.parent = parent;
        this.childGroups([...childGroups]);
        this.descendants([...descendants]);
        this.replaceMembers(members);
    }

    /**
     * Method to fully change access group members
     * @param {Array<UserMember|RobotMember>} members
     */
    replaceMembers(members) {
        this.members([...members]);
    }

    /**
     * Method for adding child group
     * @param {string} groupId UUID of new child group
     */
    addChildGroup(groupId) {
        this.childGroups([...this.childGroups(), groupId]);
    }

    /**
     * Method for deleting child group
     * @param {string} groupId UUID of child group to delete
     */
    removeChildGroup(groupId) {
        this.childGroups(this.childGroups().filter((grId) => grId !== groupId));
    }

    /**
     * Method for adding descendant group
     * @param {string} groupId UUID of new descendant group
     */
    addDescendant(groupId) {
        this.descendants([...this.descendants(), groupId]);
    }

    /**
     * Method for deleting descendant group
     * @param {string} groupId UUID of descendant group to delete
     */
    removeDescendant(groupId) {
        this.descendants(this.descendants().filter((grId) => grId !== groupId));
    }
}

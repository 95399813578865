import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useReactiveVar } from '@apollo/client';
import floorGraph from '../../../../../../features/Wall/model/FloorGraph';
import {
    findAngleBetweenVectorAndXAxis,
    radToDeg,
} from '../../../../../../shared/lib/modules/math/Math';
import scalesConformer from '../../../../../../features/ScalesConformer/model/ScalesConformer';
import { SegmentPropertiesUI } from '../../../../../../shared/ui/RightBarComponents/SegmentProperties/SegmentPropertiesUI';
import { calcCoordinateForSecondPoint } from '../../../../../../features/Wall/lib/helpers/CalculateCoordinateForSecondPoint';
import { updateSegmentsAfterVertexDrag } from '../../../../../../features/Wall/lib/utils/WallPointHandlers/HandlePointDragEnd';
import selector from '../../../../../../features/Selector/model/Selector';

/**
 * @typedef {import('../../../../../../features/Wall/model/SegmentsModerator').LinkedSegment} LinkedSegment
 * @typedef {import('../../../../../../features/Wall/lib/helpers/CalculateCoordinateForSecondPoint').TmpSegmentProps} TmpSegmentProps
 */

const Container = styled.div`
    margin: 0 20px 15px;
    padding: 0;
`;

/**
 * Component represents custom right bar (RB) to show properties for segment
 * @component
 */
export const RBSegmentProperties = () => {
    /** @type {[TmpSegmentProps, function]} */
    const [segmentProps, setSegmentProps] = useState({
        ...floorGraph.tmpEdgeProps(),
    });

    const segment = useReactiveVar(selector.lastSelectedSegmentVar);

    /** Segment first point */
    const p1 = segment.firstPointLinkWrapper.getCoordinates();
    /** Segment Second point */
    const p2 = segment.secondPointLinkWrapper.getCoordinates();

    useEffect(() => {
        const length = scalesConformer.toMetres(
            floorGraph.getEdgeLength(segment.getKey())
        );

        const vector = { x: p2.x - p1.x, y: p2.y - p1.y };
        const angle = radToDeg(findAngleBetweenVectorAndXAxis(vector));

        setSegmentProps({
            fixLength: true,
            length: length.toFixed(2),
            fixAngle: true,
            angle: angle.toFixed(2),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [segment, p1.x, p1.y, p2.x, p2.y]);

    const handleChange = (e) => {
        // handle only inputs, ignore checkboxes
        if (e.target.type !== 'checkbox') {
            setSegmentProps({
                ...segmentProps,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleBlur = () => {
        const newP2Coordinates = calcCoordinateForSecondPoint(
            p1,
            p2,
            segmentProps
        );
        const p2Coordinates = segment.secondPointLinkWrapper.getCoordinates();
        const p2Id = segment.secondPointLinkWrapper.getId();
        floorGraph.updateVertex(p2Id, newP2Coordinates);
        floorGraph.rerender();
        floorGraph.setDragStartPos(p2Coordinates);
        updateSegmentsAfterVertexDrag(p2Id, newP2Coordinates, floorGraph);
    };

    const handleEnterPress = (e) => {
        if (e.key === 'Enter') {
            e.stopPropagation();
            handleBlur();
        }
    };

    return (
        <Container>
            <SegmentPropertiesUI
                segmentProps={segmentProps}
                onChange={handleChange}
                onBlur={handleBlur}
                showCheckboxes={false}
                onEnterPress={handleEnterPress}
            />
        </Container>
    );
};

import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useMutation } from '@apollo/client';

import {
    isSendEmail,
    repeatSubmitHandlerVar,
} from '../../../shared/model/cache/Cache';
import { recoverAllowPage } from '../../../shared/model/routes/staticRoutes';
import { RECOVER_USER } from '../../../shared/graphql/queries/users/RecoverPassword';

import { Input } from '../../../shared/ui/FormComponents/Input';
import { Button } from '../../../shared/ui/FormComponents/Button';

/**
 * Recover form component.
 * @returns { form } with input to send email
 */
export const RecoveryForm = () => {
    const [inputValues, setInputValues] = useState({ login: '' });
    const [formValid, setFormValid] = useState(false);

    const navigate = useNavigate();

    /**
     * Use lazy query return tuple: [queryFunction, {data, isLoading, error}]
     * Three last vars were deleted because of warnings
     * Add them here if needed
     */
    const [recoverUser] = useMutation(RECOVER_USER);

    /**
     * Function for validation login, email, password and second password
     * Сan also add here validations for their validity
     * @param { email } param User email
     */
    const validate = ({ login }) => {
        const isValid = login !== '';
        if (isValid !== formValid) {
            setFormValid(isValid);
        }
    };

    /**
     * Inputs change handler
     * @param { currentTarget } param Element in which the event is handled
     */
    const handleChange = ({ currentTarget }) => {
        const { value, name } = currentTarget;
        setInputValues((prev) => {
            const values = { ...prev, [name]: value };
            validate(values);
            return values;
        });
    };
    /**
     * Run when sending the form
     * @param {*} e Needed for prevent default
     */
    const handleSubmit = (e) => {
        e.preventDefault();

        const variables = {
            userIdentifier: inputValues.login,
        };

        recoverUser({ variables })
            .then((response) => {
                if (response.data.recoverUser) {
                    isSendEmail(true);
                    repeatSubmitHandlerVar({
                        variables,
                        query: RECOVER_USER,
                    });
                    navigate(recoverAllowPage);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <form onSubmit={handleSubmit}>
            <Input
                name='login'
                value={inputValues.login}
                onChange={handleChange}
                placeholder='Адрес эл. почты'
                autoComplete='on'
            ></Input>
            <Button disabled={!formValid}>Восстановить пароль</Button>
        </form>
    );
};

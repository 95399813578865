import { useEffect } from 'react';

/**
 * Hook for handling clicks
 * @param {() => void} callback
 * @param {String[]} keyCodes
 */
export const useKeyDown = (callback, keyCodes) => {
    const handler = ({ code }) => {
        if (keyCodes.includes(code)) {
            callback();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handler);
        return () => {
            window.removeEventListener('keydown', handler);
        };
    });
};

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useReactiveVar } from '@apollo/client';
import { HexAlphaColorPicker } from 'react-colorful';
import { Modal } from '../../ModalWindow/Modal';
import { StyledButton, StyledInput } from '../../ContextMenu/styles/UIKit';
import { ROUTE_DEFAULT_COLOR } from '../lib/RouteDefaultValues';
import routesModerator from '../model/RoutesModerator';
import {
    H3OpenSansRegular,
    POpenSansRegular,
} from '../../../shared/ui/TextStyles';
import { TextArea } from '../../../pages/Constructor/TopBar/TopBarEntires/ProjectSettingsModal/MapSettingsTab';
import { SCROLLBAR_STYLED } from '../../../shared/ui/ScrollBar';
import routesAPI from '../api/RoutesAPI';
import { QUERY_STATES } from '../../AccessGroup/api/AccessGroupsAPI';
import { Preloader } from '../../../shared/ui/Preloader';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    padding: 10px 30px 18px;
    box-sizing: border-box;
    width: calc(100vw - 30px);
    max-width: 840px;
    max-height: calc(100dvh - 60px);
`;

const ContentBody = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    padding-bottom: 31px;
    margin-bottom: 10px;

    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    ${SCROLLBAR_STYLED}

    & .react-colorful {
        min-height: 160px;
    }

    & .react-colorful__pointer {
        width: 24px;
        height: 24px;
    }
`;

const Header = styled(H3OpenSansRegular)`
    margin-bottom: 27px;
`;

const StyledLabel = styled.label`
    margin: 0;
    padding: 0;

    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
`;

const SecondaryButton = styled(StyledButton)`
    margin: 0;
    padding: 0;
`;

const PrimaryButton = styled(SecondaryButton)`
    background: #0239ff;
    color: white;
    :hover {
        background-color: #5076fd;
    }
`;

const Footer = styled.footer`
    justify-self: center;
    justify-items: center;
    align-self: flex-end;
    display: flex;
    gap: 10px 15px;
    flex-wrap: wrap;
`;

/**
 * Converter int number in range {0 - 255} to hex string
 * @param {number} number
 * @returns {string} hex string with length = 2
 * @example
 * intToHex(0) => '00'
 * intToHex(255) => 'ff'
 */
const intToHex = (number) => {
    let result = number < 0 ? 0 : number > 255 ? 255 : number;
    result = Math.trunc(result).toString(16);
    return result.length === 2 ? result : '0' + result;
};

/**
 * Modal to change route properties
 * @component
 */
export const RouteEditModal = () => {
    const UUID = useReactiveVar(routesModerator.editModal);
    const queryState = useReactiveVar(routesAPI.routeUpdatingState);

    const route = routesModerator.getRoute(UUID);
    const [form, setForm] = useState({});

    useEffect(() => {
        if (queryState !== QUERY_STATES.IDLE) {
            routesAPI.routeUpdatingState(QUERY_STATES.IDLE);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (UUID !== null) {
            setForm({
                name: route.name() || '',
                description: route.description() || '',
                color:
                    route.color() +
                        intToHex((1 - route.transparency()) * 255) ||
                    ROUTE_DEFAULT_COLOR,
            });
        }
        // eslint-disable-next-line
    }, [UUID]);

    /**
     * Function for change form state
     * @param {string} key form property name
     * @param {string} value
     */
    const changeForm = (key, value) => {
        setForm({
            ...form,
            [key]: value,
        });
    };

    /**
     * Handler for inputs change
     * @param {React.ChangeEvent<HTMLInputElement>} e input event
     */
    const handleChangeInput = (e) => {
        changeForm(e.target.name, e.target.value);
    };

    /**
     * Handler for color picker change
     * @param {string} newColor color in hex format
     */
    const handleChangeColor = (newColor) => {
        changeForm('color', newColor);
    };

    /** Handler for save button */
    const handleSave = async () => {
        await route.updateRoute({
            ...form,
            color: form.color.substring(0, 7),
            transparency:
                1 - parseInt(form.color.substring(7) || 'ff', 16) / 255,
        });
        if (routesAPI.routeUpdatingState() === QUERY_STATES.SUCCESS) {
            routesModerator.closeEditModal();
        }
    };

    return (
        <Modal
            isOpened={UUID !== null}
            isCloseIconShow={true}
            onClose={() => routesModerator.closeEditModal()}
        >
            <Wrapper>
                <Header fontSize='24px'>Настройка маршрута</Header>

                <ContentBody>
                    <StyledLabel>
                        Название
                        <StyledInput
                            type='text'
                            name='name'
                            placeholder='Маршрут №1'
                            value={form.name}
                            onChange={handleChangeInput}
                        />
                    </StyledLabel>

                    <StyledLabel>
                        Описание
                        <TextArea
                            type='text'
                            name='description'
                            placeholder='Введите описание'
                            value={form.description}
                            onChange={handleChangeInput}
                        />
                    </StyledLabel>

                    <StyledLabel>
                        Текущий цвет
                        <StyledInput
                            type='text'
                            name='color'
                            readOnly
                            value={form.color}
                            placeholder='#ffffffff'
                        />
                    </StyledLabel>

                    <HexAlphaColorPicker
                        onChange={handleChangeColor}
                        color={form.color}
                    />

                    {queryState === QUERY_STATES.ERROR && (
                        <POpenSansRegular style={{ color: 'red' }}>
                            Ошибка: не удалось обновить маршрут
                        </POpenSansRegular>
                    )}
                </ContentBody>

                <Footer>
                    {queryState === QUERY_STATES.LOADING && (
                        <Preloader loaderSize={27} />
                    )}
                    <SecondaryButton
                        onClick={() => routesModerator.closeEditModal()}
                    >
                        Отмена
                    </SecondaryButton>
                    <PrimaryButton onClick={handleSave}>
                        Сохранить
                    </PrimaryButton>
                </Footer>
            </Wrapper>
        </Modal>
    );
};

import { makeVar } from '@apollo/client';
import constraintGroupsAPI from '../api/ConstraintGroupsAPI';
import notifications from '../../HintSystem/model/Notifications';
import { constraintGroupToCGData } from './ConstraintGroupAdapter';

/**
 * @typedef {import('../../../shared/typedefs/typedefs').StringReactiveVar} StringReactiveVar
 * @typedef {import('../../../shared/typedefs/typedefs').NumberReactiveVar} NumberReactiveVar
 * @typedef {import('./ConstraintGroupAdapter').ConstraintGroupData} ConstraintGroupData
 */

/**
 * @class
 * Class describes constraint group.
 * Use this class if you need to modify concrete constraint group
 */
export class ConstraintGroup {
    /**
     * @constructor
     * @param {string} groupId - Constraint group UUID
     * @param {ConstraintGroupData} extra - Extra properties
     */
    constructor(groupId, { name = '', description = '', version = 0 }) {
        /**
         * Constraint group UUID
         * @type {string}
         */
        this.groupId = groupId;

        /**
         * Constraint group name
         * @type {StringReactiveVar}
         */
        this.name = makeVar(name);

        /**
         * Constraint group description
         * @type {StringReactiveVar}
         */
        this.description = makeVar(description);

        /**
         * Constraint group version
         * @type {NumberReactiveVar}
         */
        this.version = makeVar(version);
    }

    /**
     * Method to get all stored constraint group properties
     * @returns {ConstraintGroupData}
     */
    getConstraintGroupData() {
        return constraintGroupToCGData(this);
    }

    /**
     * Update constraint group data properties.
     * Note: this method updates properties only locally.
     * To update data on server - use  updateGroup()
     * @param {ConstraintGroupData} constrGrData
     */
    updateProperties(constrGrData) {
        const properties = ['name', 'description', 'version'];
        properties.forEach((prop) => {
            if (constrGrData[prop] !== undefined) {
                this[prop](constrGrData[prop]);
            }
        });
    }

    /**
     * Method that updates constraint group properties
     * @param {ConstraintGroupData} groupData group properties: name, description, etc.
     */
    updateGroup(groupData) {
        const data = {
            ...this.getConstraintGroupData(),
            ...groupData,
            id: this.groupId,
        };
        constraintGroupsAPI
            .updateGroup(data)
            .then((response) => {
                if (response) {
                    this.updateProperties(response);
                }
            })
            .catch((error) => {
                notifications.setError('Не удалось обновить группу', error);
            });
    }
}

import React from 'react';
import styled from 'styled-components';
import { FormComponent } from './FormComponentWithHint';
import { inputStatus } from './FormConstants';

/**
 * Styled button
 */
export const ButtonStyled = styled.button`
    min-width: 322px;

    margin: 5px 0 4px;
    padding: 11px 75px;

    background: #023aff;
    border-radius: 13px;
    border: 0;

    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;

    &:enabled:hover {
        cursor: pointer;
        background: #5076fd;
    }

    &:disabled {
        background: #d9d9d9;
        color: #000000;
    }
`;

/**
 * Button with validator message
 * @returns { JSX.Element } Button
 */
export const Button = ({
    children,
    hintMsgType = inputStatus.hint,
    hintMsg,
    className,
    buttonClassName,
    ...props
}) => {
    return( 
        <FormComponent hintMsg={hintMsg} hintMsgType={hintMsgType} className={className}>
            <ButtonStyled
                className={buttonClassName}
                {...props}
            >{children}</ButtonStyled>
        </FormComponent>
    );
};

import { DocumentNode, gql } from '@apollo/client';

export const DELETE_ROUTE: DocumentNode = gql`
    mutation deleteRoute($id: String!, $version: Int!) {
        deleteRoute(id: $id, version: $version) {
            id
            version
        }
    }
`;

import { gql } from '@apollo/client';
import { MAP_OBJECT_FRAGMENT } from '../../fragments/mapObjects/MapObjectFragment';

/**
 * Mutation for new map object creation (e.g. table, chair and so on)
 */
export const CREATE_MAP_OBJECT = gql`
    ${MAP_OBJECT_FRAGMENT}
    mutation createMapObject($mapObject: MapObjectDraft!) {
        createMapObject(mapObjectDraft: $mapObject) {
            ... on MapObject {
                ...MapObjectFragment
            }
        }
    }
`;

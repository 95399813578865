import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import accessGroupsModerator from '../../../../../../../features/AccessGroup/model/AccessGroupsModerator';
import { useReactiveVar } from '@apollo/client';
import accessGroupsAPI, {
    QUERY_STATES,
} from '../../../../../../../features/AccessGroup/api/AccessGroupsAPI';
import { AccessGroup } from '../../../../../../../features/AccessGroup/model/AccessGroup';
import { AccessGroupModal } from './AccessGroupModal';

/**
 * Component represents UI for search and adding new user
 * @component
 */
export const EditAccessGroupModal = ({ group, onClose }) => {
    const parentGroup = accessGroupsModerator.getGroup(group.parent);
    const queryState = useReactiveVar(accessGroupsAPI.groupUpdatingState);

    useEffect(() => {
        accessGroupsAPI.groupCreatingState(QUERY_STATES.IDLE);
    }, []);

    useEffect(() => {
        if (queryState === QUERY_STATES.SUCCESS) {
            accessGroupsAPI.groupUpdatingState(QUERY_STATES.IDLE);
            onClose();
        }
        // eslint-disable-next-line
    }, [queryState]);

    /** Handler for create button click */
    const handleUpdate = (form) => {
        accessGroupsModerator.updateGroup(group.groupId, form);
    };

    const formInitial = {
        name: group.name(),
        description: group.description(),
        parentMirror: group.parentMirror,
        floorCreationAvailable: group.floorCreationAvailable,
        notificationCreationAvailable:  group.notificationCreationAvailable,
    };

    return (
        <AccessGroupModal
            formInitial={formInitial}
            parentGroup={parentGroup}
            title={'Редактировать группу «' + group.name() + '»'}
            submitButtonText='Сохранить'
            isError={queryState === QUERY_STATES.ERROR}
            isLoading={queryState === QUERY_STATES.LOADING}
            onClose={onClose}
            onSubmit={handleUpdate}
        />
    );
};

EditAccessGroupModal.propTypes = {
    /** Parent access group */
    parentGroup: PropTypes.instanceOf(AccessGroup),

    /** Modal close callback */
    onClose: PropTypes.func,
};

import { closeContextMenu } from '../../../../ContextMenu/model/CloseContextMenu';

/**
 * @typedef {import('../../../model/FloorGraph').FloorGraph} FloorGraph
 */

/**
 * Handler for point drag start.
 * @param {MouseEvent} e target event
 * @param {FloorGraph} graph FloorGraph or its heir
 */
export const handlePointDragStart = (e, graph, handleStartDrag) => {
    closeContextMenu();

    // TODO: restore dragging non-scalable segments corners
    // const fixedAdjacentIds = graph.findFixedEdgesByVertex(e.target.id());

    // if (fixedAdjacentIds.length > 1) {
    //     e.target.stopDrag();
    // }

    handleStartDrag();
    graph.dragStartPos = { ...e.target.position() };
};

import { OPERATION } from '../../Subscriptions/api/SubscriptionsModerator';
import { alertDataFromServerToAlertData } from './AlertsAdapter';
import alertsModerator from './AlertsModerator';
import { AlertDataFromServer } from './Types';

/**
 * Method to update alerts via subscription
 * @param {string} operation operation type (e.g. Create/Delete)
 * @param {object} alert alert object from server
 */
export const updateAlertBySubscription = (
    operation: string,
    alert: AlertDataFromServer
) => {
    if (operation === OPERATION.DELETE) {
        alertsModerator.removeAlertFromModerator(alert.id);
    } else if (operation === OPERATION.CREATE) {
        alertsModerator.addAlert(
            alertDataFromServerToAlertData(alert)
        );
    }
};

import React from 'react';

import { Routes, Route, Navigate } from 'react-router-dom';

import {
    registrationPage,
    loginPage,
    projectPage,
    recoverPage,
    recoverAllowPage,
    registrationAllowPage,
    mainPage,
    userSettingsPage,
    requestRecoverPage,
} from './staticRoutes';

import { Authorization } from '../../../pages/LogIn/Authorization';
import { Registration } from '../../../pages/Registration/Registration.jsx';
import { MainMenu } from '../../../pages/MainMenu/MainMenu';
import { ConstructorPage } from '../../../pages/Constructor/ConstructorPage';
import { RecoveryPage } from '../../../pages/Recovery/RecoveryPage';
import { EmailSender } from '../../../pages/Recovery/EmailSender';

import { RequireSendEmail } from '../hoc/RequireSendEmail';
import { UserSettings } from '../../../pages/UserSettings/UserSettings';

import { RecoveryForm } from '../../../pages/Recovery/ui/RecoveryForm';
import { ExtraLinks } from '../../ui/ExtraLinks';
import { ChangeForm } from '../../../pages/Recovery/ui/ChangeForm';
import { currentUserVar } from '../cache/Cache';
import { REFRESH_TOKEN, SESSION_TOKEN } from '../../lib/LocalStorageConstants';

import { CREATE_SESSION } from '../../graphql/mutations/users/CreateSession';
import { client } from '../../graphql/ApolloClient';
import { setCurrentUser } from '../../lib/utils/auth/SetCurrentUser';

const ProtectedRoute = ({ children }) => {
    if (!currentUserVar().isAuth) {
        return <Navigate to={loginPage} replace />;
    }

    return children;
};

export const useRoutes = () => {
    const token = window.location.hash.slice(1);
    if (token) {
        localStorage.setItem(REFRESH_TOKEN, token);
        client
            .mutate({ mutation: CREATE_SESSION, fetchPolicy: 'network-only' })
            .then((sessionResponse) => {
                if (sessionResponse) {
                    localStorage.setItem(
                        SESSION_TOKEN,
                        sessionResponse.data.createSession.token
                    );

                    setCurrentUser();
                }
            });
    }

    try {
        if (localStorage.getItem(REFRESH_TOKEN)) {
            setCurrentUser();
        }
    } catch (error) {
        console.log(error);
    }

    return (
        <Routes>
            <Route path={loginPage} element={<Authorization />} />

            <Route path={registrationPage} element={<Registration />} />

            <Route
                path={registrationAllowPage}
                element={
                    <RequireSendEmail to={registrationPage}>
                        <EmailSender
                            message={
                                'Вам на почту отправлено письмо с подтверждением регистрации'
                            }
                        />
                    </RequireSendEmail>
                }
            />

            <Route
                path={requestRecoverPage}
                element={
                    <RecoveryPage>
                        <RecoveryForm />
                        <ExtraLinks
                            items={[
                                {
                                    text: 'Еще нет аккаунта?',
                                    name: 'Регистрация',
                                    to: registrationPage,
                                },
                            ]}
                        />
                    </RecoveryPage>
                }
            />
            <Route
                path={recoverAllowPage}
                element={
                    <RequireSendEmail to={requestRecoverPage}>
                        <EmailSender
                            message={
                                'Вам на почту отправлено письмо для сброса старого пароля'
                            }
                        />
                    </RequireSendEmail>
                }
            />
            <Route
                path={recoverPage}
                element={
                    <RequireSendEmail to={requestRecoverPage}>
                        <RecoveryPage>
                            <ChangeForm />
                        </RecoveryPage>
                    </RequireSendEmail>
                }
            />

            <Route
                path={`${projectPage}/:id`}
                element={
                    <ProtectedRoute>
                        <ConstructorPage />
                    </ProtectedRoute>
                }
            />

            <Route
                path={mainPage}
                element={
                    <ProtectedRoute>
                        <MainMenu />
                    </ProtectedRoute>
                }
            />

            <Route
                path={userSettingsPage}
                element={
                    <ProtectedRoute>
                        <UserSettings />
                    </ProtectedRoute>
                }
            />

            <Route path='*' element={<Navigate to={loginPage} replace />} />
        </Routes>
    );
};

import React from 'react';
import { ReactiveVar, makeVar } from '@apollo/client';
import { DefaultRBTabs, RightBarOperation } from './RightBarOperations';

export interface RightBarOperationProps {
    /** Operation name */
    operationName: RightBarOperation;

    /** Need to create custom right bar or not */
    customRightBar: boolean;

    /** If customRightBar=true, set this component to show */
    component: React.ReactNode;

    /**
     * Handler for right bar close.
     * Put here function, that need to run on cancelling operation
     */
    onClose?: () => void;
}

/**
 * Class to manage data related to right bar
 * @class
 */
class RightBarModerator {
    /** Is right bar opened */
    isOpened: ReactiveVar<boolean>;

    /** Opened tab for default right bar */
    selectedTab: ReactiveVar<DefaultRBTabs>;

    /** Set of opened folders name */
    openedFolders: ReactiveVar<Set<string>>;

    /** Selected object id or null */
    selectedObject: ReactiveVar<string | null>;

    /** Active operation related to right bar */
    operation: ReactiveVar<RightBarOperationProps | null>;

    constructor() {
        this.isOpened = makeVar(false);
        this.openedFolders = makeVar(new Set());
        this.selectedObject = makeVar<string | null>(null);
        this.operation = makeVar<RightBarOperationProps | null>(null);
        this.selectedTab = makeVar<DefaultRBTabs>(DefaultRBTabs.WALL_SEGMENTS);
    }

    /** Method to open right bar */
    open() {
        this.isOpened(true);
    }

    /** Method to close right bar */
    close() {
        this.isOpened(false);
    }

    /** Method set tab for default right bar */
    selectTab(tabName: DefaultRBTabs) {
        if(!this.isOpened()) {
            this.open();
        }
        if (tabName !== this.selectedTab()) {
            this.selectedTab(tabName);
        }
    }

    /** Method to open folder in right bar */
    openFolders(...folders: string[]) {
        if(!this.isOpened()) {
            this.open();
        }
        folders.forEach((folder) => {
            this.openedFolders().add(folder);
        });
        this.openedFolders(new Set(this.openedFolders()));
    }

    /** Method to close folder in right bar */
    closeFolder(folderName: string) {
        if (this.openedFolders().delete(folderName)) {
            this.openedFolders(new Set(this.openedFolders()));
        }
    }

    /** Method to set operation */
    setOperation(operation: RightBarOperationProps) {
        if(!this.isOpened()) {
            this.open();
        }
        this.selectedObject(null);
        if (this.operation()) {
            this.clearOperation();
        }
        this.operation({ ...operation });
    }

    /** Method to call onClose operation handler and clear operation */
    clearOperation() {
        const onClose = this.operation()?.onClose;
        if (onClose) {
            onClose();
        }
        this.operation(null);
    }

    /** Method to clear stored data */
    clear() {
        this.clearOperation();
        this.isOpened(false);
        this.openedFolders(new Set());
        this.selectedObject(null);
        this.selectedTab(DefaultRBTabs.WALL_SEGMENTS);
    }
}

const rightBarModerator = new RightBarModerator();
export default rightBarModerator;

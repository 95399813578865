import React from 'react';
import { closeContextMenu } from '../../model/CloseContextMenu';
import floorGraph from '../../../Wall/model/FloorGraph';
import { PointsForContextMenu } from '../styles/PointsForContextMenu';
import { finishWallCreation } from '../../../../pages/Constructor/RightBar/ui/DefaultRightBar/Tabs/ItemsFolder/FolderContent/Item';

/**
 * Component that represents a context menu for wall tool while creation
 * @component
 */
export const WallCreationContextMenu = () => {
    const handleCancelWallCreation = () => {
        finishWallCreation();
        closeContextMenu();
    };

    const handleResetBeginningOfSegment = () => {
        floorGraph.tmpEdgeVar(new Map());
        closeContextMenu();
    };

    const menu = [
        {
            text: 'Сбросить начало сегмента',
            onClick: handleResetBeginningOfSegment,
        },
        {
            text: 'Завершить построение',
            onClick: handleCancelWallCreation,
        },
        {
            text: 'Закрыть меню',
            onClick: closeContextMenu,
        },
    ];

    return <PointsForContextMenu menu={menu} />;
};

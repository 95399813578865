import {
    arePointsEqual,
    getDistanceBetweenPoints,
    subtractTwoPoints,
} from '../../../../../shared/lib/modules/math/Math';
import { currentFloorVar } from '../../../../../shared/model/cache/Cache';
import notifications from '../../../../HintSystem/model/Notifications';
import routesAPI from '../../../../Route/api/RoutesAPI';
import wallSegmentsApi from '../../../api/WallSegmentsAPI';
import floorGraph from '../../../model/FloorGraph';

/**
 * @typedef {import('../../../model/FloorGraph').FloorGraph} FloorGraph
 * @typedef {import('../../../../../shared/typedefs/Types').Point} Point
 */

const MINIMAL_DISTANCE_FOR_MERGING_POINTS = 40;

/**
 * Function to update segments
 * @param {string} draggedPointId id of dragged vertex; this vertex will be deleted
 * @param {Point} coordinates new coordinates for dragged point
 * @param {FloorGraph} [graph=floorGraph] floor graph or its heir
 */
export const updateSegmentsAfterVertexDrag = (
    draggedPointId,
    coordinates,
    graph = floorGraph
) => {
    // variables for searching and remembering the nearest vertex
    let minimalDistance = null;
    let destVertex = null;

    // for each vertex in graph
    // needed to check if vertexes could be merged
    for (const {
        id,
        pointInfo,
    } of graph.segmentsModerator.getAllPointsLinks()) {
        if (pointInfo.adjacentPoints?.length === 0) {
            continue; // for ignoring global zone
        }

        if (arePointsEqual(coordinates, pointInfo.coordinates)) {
            continue; // for ignoring self
        }

        const distance = getDistanceBetweenPoints(
            coordinates,
            pointInfo.coordinates
        );
        if (distance < MINIMAL_DISTANCE_FOR_MERGING_POINTS) {
            if (minimalDistance === null || distance < minimalDistance) {
                minimalDistance = distance;
                destVertex = id;
            }
        }
    }

    if (minimalDistance && destVertex) {
        graph.mergePoints(draggedPointId, destVertex).then(() => {
            graph.rerender();
        });
        return;
    }

    /**
     * @type {import('../../../api/WallSegmentsAPI').MoveWallItemsOperation}
     */
    const moveWallItems = {
        moveVector: { x: null, y: null },
        wallPointsIds: [],
    };

    const moveVector = subtractTwoPoints(
        { ...graph.dragStartPos },
        { ...coordinates }
    );

    graph.dragStartPos = null;

    moveWallItems.moveVector = moveVector;

    moveWallItems.wallPointsIds.push(draggedPointId);

    const loadFloor =
        require('../../../../../pages/Constructor/model/LoadFloor').loadFloor;

    // TODO encapsulate logic to FloorGraph and override it in RouteGraph
    // -> same refactor need in handleDragEnd
    if (graph === floorGraph) {
        wallSegmentsApi
            .updateSegmentsPositions(moveWallItems)
            .then((res) => {
                res?.forEach((segment) => {
                    graph.segmentsModerator.updateVersion(
                        segment.startPoint.id,
                        segment.endPoint.id,
                        segment.version
                    );
                });
            })
            .catch(async (error) => {
                notifications.setError(
                    'Не удалось сохранить изменения',
                    error.message
                );
                await loadFloor(currentFloorVar().id);
                console.log(error);
            });
    } else {
        routesAPI
            .updateSegmentsPositions(moveWallItems)
            .then((res) => {
                res?.forEach((segment) => {
                    graph.segmentsModerator.updateVersion(
                        segment.startPoint.id,
                        segment.endPoint.id,
                        segment.version
                    );
                });
            })
            .catch(async (error) => {
                notifications.setError(
                    'Не удалось сохранить изменения',
                    error.message
                );
                await loadFloor(currentFloorVar().id);
                console.log(error);
            });
    }
};

/**
 * Handler for point drag end.
 * Merge dragged point to another, if it's possible
 * @param {MouseEvent} e target event
 * @param {FloorGraph} graph floor graph or its heir
 */
export const handlePointDragEnd = async (
    e,
    graph = floorGraph,
    handleSetDragEnd
) => {
    const draggedPointId = e.target.id(); // id of dragged vertex, this vertex will be deleted
    const coordinates = { ...e.target.position() }; // coordinates of dragged vertex

    updateSegmentsAfterVertexDrag(draggedPointId, coordinates, graph);
    handleSetDragEnd();
};

import { gql } from '@apollo/client';

export const FLOOR_OBJECT_UPDATES = gql`
    subscription subscribeToFloorObjectUpdates(
        $subscriptionItem: SubscriptionItemInput!
    ) {
        subscribeToFloorObjectUpdates(subscriptionItem: $subscriptionItem) {
            additionalInformation
            angle
            centerPoint {
                x
                y
            }
            editors {
                creatorId
                description
                id
                name
                projectId
                roleIssuer
            }
            height
            id
            owner
            type {
                creatorId
                id
                information
                name
                pictureId
                projectId
                scalable
            }
            watchers {
                creatorId
                description
                id
                name
                projectId
                roleIssuer
            }
            width
            zoneId
        }
    }
`;

import React, { useEffect, useState } from 'react';
import { Modal } from '../../../features/ModalWindow/Modal';
import { Disclaimer } from './Disclaimer';
import { IS_DISCLAIMER_SHOWN } from '../../../shared/lib/LocalStorageConstants';

/**
 * Component with disclaimer modal.
 * Disclaimer to notify user, that he/she uses dev page.
 * @component
 */
export const DisclaimerModal = () => {
    const [isShow, setIsShow] = useState(false);
    const closeModal = () => setIsShow(false);

    useEffect(() => {
        // disclaimer need to show only once
        if (!+localStorage.getItem(IS_DISCLAIMER_SHOWN)) {
            setIsShow(true);
            localStorage.setItem(IS_DISCLAIMER_SHOWN, 1);
        }
    }, []);

    return (
        <Modal isOpened={isShow} onClose={closeModal} isCloseIconShow={true}>
            <Disclaimer onClose={closeModal} />
        </Modal>
    );
};

import React from 'react';
import PropTypes from 'prop-types';
import { Input } from './Input';

/**
 * Form component - input for number
 * @component
 */
export const InputNumber = ({
    min = 0,
    max = 100,
    precision = 2,
    step = 1,
    onBlur,
    onChange,
    ...props
}) => {
    /**
     * Blur handler
     * @param {React.FocusEvent<HTMLInputElement>} e
     */
    const handleBlur = (e) => {
        // Checks if input number is in range [min, max].
        // If input value not in range, handler changes value to min or max.
        e.target.value = Math.max(min, Math.min(max, Number(e.target.value)));

        // Set correct precision
        const pres = Number(precision) || 0;
        e.target.value = parseFloat(e.target.value).toFixed(pres);

        if (onBlur) {
            onBlur(e);
        }

        if (onChange) {
            onChange(e);
        }
    };

    /**
     * Change handler
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const handleChange = (e) => {
        if (onChange) {
            onChange(e);
        }
    };

    return (
        <Input
            type='number'
            min={min}
            max={max}
            step={step}
            onBlur={handleBlur}
            onChange={handleChange}
            {...props}
        />
    );
};

InputNumber.propTypes = {
    /** min value of input number */
    min: PropTypes.number,

    /** max value of input number */
    max: PropTypes.number,

    /** precision of input number */
    precision: PropTypes.number,

    /** step for number */
    step: PropTypes.number,

    /** onBlur handler */
    onBlur: PropTypes.func,

    /** onChange handler */
    onChange: PropTypes.func,
};

import React, { useState } from 'react';
import {
    Header2,
    SettingsBlockStyled,
    LabelWithInputStyled,
    Label,
    ErrorP,
    OneRowElements,
} from './Styles';
import { PhotoBlock } from './PhotoBlock';
import { Input } from '../../../shared/ui/FormComponents/Input';
import { Button } from '../../../shared/ui/FormComponents/Button';
import { useMutation, useQuery } from '@apollo/client';
import { currentUserVar } from '../../../shared/model/cache/Cache';
import { validateGeneralInformationForm } from '../lib/utils/SettingsValidation';
import { UPDATE_USER } from '../../../shared/graphql/mutations/users/UpdateUser';
import { GET_USER_SETTINGS_INFO } from '../../../shared/graphql/queries/users/GetUser';
import { inputStatus } from '../../../shared/ui/FormComponents/FormConstants';
import Loader from '../../../shared/ui/Loader';
import notifications from '../../../features/HintSystem/model/Notifications';
import { PreloaderWithText } from '../../../shared/ui/PreloaderWithText';

/** hints for different fields, needed for onFocus handler */
const hintsForFields = {
    name: 'Введите имя пользователя',
    login: 'Введите логин',
    email: 'Введите электронную почту',
    phone: 'Пожалуйста, введите номер телефона из 11 цифр',
};

/**
 * Block with general information settings
 * @component
 */
export const GeneralInformation = () => {
    const [formValid, setFormValid] = useState(false);

    const [inputValues, setInputValues] = useState({
        name: '',
        login: '',
        email: '',
        phone: '',
    });

    // for setting hint for input
    const [inputHints, setInputValuesErrors] = useState({
        name: null,
        login: null,
        email: null,
        phone: null,
    });

    // for setting color of input hints
    const [inputHintsStatus, setInputValuesStatus] = useState({
        name: inputStatus.hint,
        login: inputStatus.hint,
        email: inputStatus.hint,
        phone: inputStatus.hint,
    });

    // get user data
    const { error: getUserInfoError, loading } = useQuery(
        GET_USER_SETTINGS_INFO,
        {
            variables: { selector: { id: currentUserVar().id } },
            onCompleted: (userInfo) => {
                setInputValues({
                    name: userInfo.user.name || '',
                    login: userInfo.user.login || '',
                    email: userInfo.user.email || '',
                    phone: userInfo.user.phone || '',
                });

                currentUserVar({
                    ...currentUserVar(),
                    name: userInfo.user.name,
                    login: userInfo.user.login,
                    email: userInfo.user.email,
                    phone: userInfo.user.phone,
                });
            },
            onError: (err) => console.error(err),
        }
    );

    const [updateUserError, setUpdateUserError] = useState(false);
    const [updateUserQuery, { loading: updateUserLoading }] =
        useMutation(UPDATE_USER);

    if (loading) {
        return (
            <div style={{ height: '70vh' }}>
                <Loader />
            </div>
        );
    }

    if (getUserInfoError) {
        return (
            <ErrorP style={{ marginTop: '10px' }}>
                Ошибка при загрузке информации, попробуйте перезагрузить
                страницу
            </ErrorP>
        );
    }

    /**
     * Input focus handler
     * Set hint message
     * @param { currentTarget } param Element in which the event is handled
     * @see hintsForFields
     */
    const onFocusInputHandler = ({ currentTarget }) => {
        const { value, name } = currentTarget;
        if (value === '') {
            setInputValuesErrors((prev) => ({
                ...prev,
                [name]: hintsForFields[name],
            }));
            setInputValuesStatus((prev) => ({ ...prev, [name]: 'hint' }));
        }
    };

    /**
     * Function for validation input data
     * @param {Object} param Input data
     * @param {String} param.name User name
     * @param {String} param.login User login
     * @param {String} param.email User email
     * @param {String} param.phone User phone
     */
    const validate = ({
        name,
        login,
        email = 'undefined',
        phone = 'undefined',
    }) => {
        validateGeneralInformationForm(
            { name, login, email, phone },
            formValid,
            setFormValid,
            setInputValuesErrors,
            setInputValuesStatus
        );
    };

    /**
     * Inputs change handler
     * @param {Object} param Destructured object param
     * @param {HTMLInputElement} param.currentTarget Element in which the event is handled
     */
    const handleChange = ({ currentTarget }) => {
        const { value, name } = currentTarget;
        setInputValues((prev) => {
            const values = { ...prev, [name]: value };
            validate(values);
            return values;
        });

        if (updateUserError) {
            setUpdateUserError(false);
        }
    };

    /**
     * Handler for form submission
     * @param {React.FormEvent} e Form event, needed for prevent default
     */
    const submitHandler = (e) => {
        e.preventDefault();

        if (formValid) {
            updateUserQuery({
                variables: {
                    updateUserData: {
                        id: currentUserVar().id,
                        login: inputValues.login,
                        name: inputValues.name,
                        surname: '',
                        patronymic: '',
                        email: inputValues.email,
                        phone: inputValues.phone,
                    },
                },
                onCompleted: () => {
                    notifications.setSuccess(
                        'Оповещение',
                        'Ваши изменения успешно сохранены'
                    );
                },
                onError: (err) => {
                    console.error(err);
                    setUpdateUserError(true);
                },
            });
        }
    };

    return (
        <form onSubmit={submitHandler}>
            <SettingsBlockStyled>
                <Header2>Основная информация</Header2>
                <PhotoBlock />
                <LabelWithInputStyled>
                    <Label>Имя</Label>
                    <Input
                        name='name'
                        value={inputValues.name}
                        onChange={handleChange}
                        hintMsgType={inputHintsStatus.name}
                        hintMsg={inputHints.name}
                        onFocus={onFocusInputHandler}
                        autoComplete='given-name'
                    />
                </LabelWithInputStyled>
                <LabelWithInputStyled>
                    <Label>Логин</Label>
                    <Input
                        name='login'
                        value={inputValues.login}
                        onChange={handleChange}
                        hintMsgType={inputHintsStatus.login}
                        hintMsg={inputHints.login}
                        onFocus={onFocusInputHandler}
                        autoComplete='on'
                    />
                </LabelWithInputStyled>
            </SettingsBlockStyled>

            <SettingsBlockStyled>
                <Header2>Контактная информация</Header2>
                <LabelWithInputStyled>
                    <Label>Электронная почта</Label>
                    <Input
                        name='email'
                        value={inputValues.email}
                        onChange={handleChange}
                        hintMsgType={inputHintsStatus.email}
                        hintMsg={inputHints.email}
                        onFocus={onFocusInputHandler}
                        autoComplete='email'
                    />
                </LabelWithInputStyled>
                <LabelWithInputStyled>
                    <Label>Номер телефона</Label>
                    <Input
                        name='phone'
                        value={inputValues.phone}
                        placeholder='8XXXXXXXXXX'
                        onChange={handleChange}
                        hintMsgType={inputHintsStatus.phone}
                        hintMsg={inputHints.phone}
                        onFocus={onFocusInputHandler}
                        autoComplete='tel'
                    />
                </LabelWithInputStyled>
                {updateUserError && (
                    <ErrorP>
                        Ошибка при сохранении. <br />
                        Попробуйте ещё раз, если проблема останется - обратитесь
                        в тех. поддержку
                    </ErrorP>
                )}
                <OneRowElements>
                    <Button type='submit' disabled={!formValid}>
                        Сохранить
                    </Button>
                    {updateUserLoading && (
                        <div style={{ marginBottom: '17px' }}>
                            <PreloaderWithText
                                loaderSize={40}
                                text='Обновляем данные'
                                fullSpace={false}
                            />
                        </div>
                    )}
                </OneRowElements>
            </SettingsBlockStyled>
        </form>
    );
};

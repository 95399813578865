import styled from 'styled-components';

import {
    SVG_CLOUD_PADDING_SIZE,
    SVG_CLOUD_PROTRUSION_HEIGHT,
    SVG_CLOUD_SHADOW_SIZE,
} from '../CloudSvg';
import { SCROLLBAR_STYLED } from '../../../../shared/ui/ScrollBar';
import { H3OpenSansRegular } from '../../../../shared/ui/TextStyles';

/** Padding size between content and scrollbar */
export const PADDING_FOR_SCROLL = 20;

/**
 * @param {number} scale - stage scale
 * @param {object} position - container position on canvas
 * @param {number} position.x
 * @param {number} position.y
 */
export const UIFormContainer = styled.div`
    position: absolute;
    top: ${(props) => `${props.position.y}px`};
    left: ${(props) => `${props.position.x}px`};

    transform-origin: bottom center;
    transform: scale(${(props) => `${1 / props.scale}`});

    transition: top 0.5s;
`;

export const ContentWrapper = styled.div`
    margin: ${SVG_CLOUD_PADDING_SIZE + SVG_CLOUD_SHADOW_SIZE}px
        ${SVG_CLOUD_PADDING_SIZE + SVG_CLOUD_SHADOW_SIZE - PADDING_FOR_SCROLL}px
        ${SVG_CLOUD_PADDING_SIZE +
        SVG_CLOUD_SHADOW_SIZE +
        SVG_CLOUD_PROTRUSION_HEIGHT}px
        ${SVG_CLOUD_PADDING_SIZE + SVG_CLOUD_SHADOW_SIZE}px;
    padding-right: ${PADDING_FOR_SCROLL}px;
    box-sizing: border-box;
    min-width: 400px;
    max-width: 460px;
    max-height: calc(100vh - 300px);
    z-index: 99;
`;

export const FieldsWrapper = styled.div`
    padding-right: ${PADDING_FOR_SCROLL}px;
    min-height: fit-content;
    max-height: max(calc(100vh - 600px), calc(100vh - 100px));
    ${SCROLLBAR_STYLED}
`;

export const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    top: -1px;
    background-color: white;
    z-index: 1;
`;

export const ButtonBar = styled.div`
    display: flex;
    gap: 10px;
`;

export const CloudSvgContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
`;

export const ObjectName = H3OpenSansRegular;

import { stagePositionVar } from '../../../../shared/model/cache/Cache';

const STAGE_MARGIN = { x: 0, y: 99 }; // Offset for topbar
/**
 * Gives stage coordinates by viewport coordinates
 * @param {{x: number, y: number}} viewportPosition position of pointer in window coordinate system
 * @param {number} scale
 * @returns {{x: number, y: number}} position of pointer in stage coordinate system
 */
export const viewportToStageCoordinates = (viewportPosition, scale) => {
    return {
        x: (viewportPosition.x - STAGE_MARGIN.x - stagePositionVar().x) / scale,
        y: (viewportPosition.y - STAGE_MARGIN.y - stagePositionVar().y) / scale,
    };
};

import React, { useContext } from 'react';
import styled from 'styled-components';
import { EditablePropertiesGrid } from './EditablePropertiesGrid';
import { ExtendablePropertiesContext } from '../GraphicSchema';

const HrStyled = styled.hr`
    margin: 20px 0 0;
`;

/**
 * @param {boolean} isEditMode
 */
const ExtendablePropertiesContainer = styled.div`
    margin-top: 15px;
    margin-right: ${(props) => (props.isEditMode ? '10px' : '0')};
    display: grid;
    grid-template-columns: ${(props) =>
        props.isEditMode ? '1fr 1fr auto' : '1fr 1fr'};
    row-gap: 5px;
    align-items: center;
`;

/**
 * Component represents extended properties for graphic schema
 * with the ability to add new custom properties
 * @component
 */
export const ExtendedProperties = () => {
    const { fields, isEditMode } = useContext(ExtendablePropertiesContext);
    return (
        fields.length > 0 && (
            <>
                <HrStyled />
                <ExtendablePropertiesContainer isEditMode={isEditMode}>
                    <EditablePropertiesGrid />
                </ExtendablePropertiesContainer>
            </>
        )
    );
};

import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import rhombIcon from '../../../../../../shared/assets/images/icons/ConstructorPage/LeftBar/item-rhomb.svg';
import optionsIcon from '../../../../../../shared/assets/images/icons/ConstructorPage/LeftBar/options-three-dots.svg';
import { HeaderEditInput } from '../Header/HeaderEditInput';

const ItemContainer = styled.li`
    display: flex;
    align-items: center;
    height: 31px;
    width: 100%;
    border-radius: 9px;
    border: 0.5px solid transparent;
    :hover {
        border-color: #023aff;
    }
`;

const ItemRhombIcon = styled.img`
    margin-left: 5px;
    margin-right: 10px;
    cursor: pointer;
`;

const ItemName = styled.div`
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.8);
    cursor: pointer;
`;

const ItemOptionsIcon = styled.img`
    width: 3px;
    padding-left: 10px;
    padding-right: 5px;
    cursor: pointer;
    cursor: pointer;
`;

/**
 * Component that represents base list element for OpeningGroup
 * @component
 */
export const Item = ({
    title = 'Нет названия',
    isEditable = true,
    onTitleEditFinish = () => {},
    optionsRef,
    onOptionsClick = () => {},
}) => {
    const [isTitleEditing, setIsTitleEditing] = useState(false);

    return (
        <ItemContainer>
            <ItemRhombIcon src={rhombIcon} />
            {isEditable && isTitleEditing ? (
                <HeaderEditInput
                    title={title}
                    setIsTitleEditing={setIsTitleEditing}
                    onTitleEditFinish={onTitleEditFinish}
                />
            ) : (
                <ItemName
                    title={title}
                    onDoubleClick={() => setIsTitleEditing(true)}
                >
                    {title}
                </ItemName>
            )}
            <ItemOptionsIcon
                src={optionsIcon}
                alt={'параметры'}
                onClick={onOptionsClick}
                ref={optionsRef}
            />
        </ItemContainer>
    );
};

Item.propTypes = {
    /** Item title */
    title: PropTypes.string,

    /** Is title available to edit */
    isEditable: PropTypes.bool,

    /**
     * Callback function for finish editing title
     * @type {(newTitle: string) => void}
     */
    onTitleEditFinish: PropTypes.func,

    /** Ref for options img */
    optionsRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),

    /**
     * Callback function for click on context menu
     * @type {() => void}
     */
    onOptionsClick: PropTypes.func,
};

import { stagePositionVar, stageBordersSizeVar } from '../../../../shared/model/cache/Cache';

/**
 * Function that updates zone size for global zone
 */
export const updateStageSize = () => {
    if (stagePositionVar().x < 0) {
        stageBordersSizeVar({
            ...stageBordersSizeVar(),
            right: Math.max(
                stageBordersSizeVar().right,
                Math.abs(stagePositionVar().x)
            ),
        });
    } else {
        stageBordersSizeVar({
            ...stageBordersSizeVar(),
            left: Math.max(
                stageBordersSizeVar().left,
                Math.abs(stagePositionVar().x)
            ),
        });
    }

    if (stagePositionVar().y < 0) {
        stageBordersSizeVar({
            ...stageBordersSizeVar(),
            bottom: Math.max(
                stageBordersSizeVar().bottom,
                Math.abs(stagePositionVar().y)
            ),
        });
    } else {
        stageBordersSizeVar({
            ...stageBordersSizeVar(),
            top: Math.max(
                stageBordersSizeVar().top,
                Math.abs(stagePositionVar().y)
            ),
        });
    }
};

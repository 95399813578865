import zones from '../../../../Zone/model/Zones';
import { deepCopy } from '../../../../../shared/lib/utils/common/DeepCopy';

/**
 * Command to change zone properties
 *
 * @param {string} zoneId
 * @param {import('../../../../Zone/model/GlobalZone').ZoneProps} zoneProps some of canvas zone properties
 */
export const changeZonePropertiesCommand = (zoneId, zoneProps) => {
    const cashedZoneProps = deepCopy(zoneProps);
    return {
        execute() {
            zones.update(zoneId, cashedZoneProps);
        },

        undo() {
            zones.update(zoneId, cashedZoneProps);
        },
    };
};

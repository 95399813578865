import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router';

import { Input } from '../../../shared/ui/FormComponents/Input';
import { PasswordInput } from '../../../shared/ui/FormComponents/PasswordInput';
import { Button } from '../../../shared/ui/FormComponents/Button';

import { MAPINS_LOG_IN } from '../../../shared/graphql/mutations/users/LogIn';
import { CREATE_SESSION } from '../../../shared/graphql/mutations/users/CreateSession';
import {
    IS_DISCLAIMER_SHOWN,
    REFRESH_TOKEN,
    SESSION_TOKEN,
} from '../../../shared/lib/LocalStorageConstants';

import { mainPage } from '../../../shared/model/routes/staticRoutes';

import { handleResponseError } from '../../../shared/lib/utils/auth/HandleResponseError';
import { setCurrentUser } from '../../../shared/lib/utils/auth/SetCurrentUser';
import { currentUserVar } from '../../../shared/model/cache/Cache';

/**
 * Authorization form component with eye img component
 * @component
 * @returns { } Empty component with authorization form and eye img
 */
export const AuthorizationForm = () => {
    const [inputValues, setInputValues] = useState({ login: '', password: '' });
    const [formValid, setFormValid] = useState(false);
    const [forceHint, setForceHint] = useState(false);

    // for setting messages for input
    const [inputValuesErrors, setInputValuesErrors] = useState({
        login: null,
        password: null,
    });

    // for setting color of inputs
    const [inputValuesStatus, setInputValuesStatus] = useState({
        login: 'hint',
        password: 'hint',
    });

    /**
     * Mutation for logging in and getting refresh token
     * Use mutation return tuple: [mutateFunction, {data, isLoading, error}]
     * Three last vars were deleted because of warnings
     * Add them here if needed
     */
    const [authUser] = useMutation(MAPINS_LOG_IN);

    /**
     * Mutation for getting session token
     * Use mutation return tuple: [mutateFunction, {data, isLoading, error}]
     * Three last vars were deleted because of warnings
     * Add them here if needed
     */
    const [createSession] = useMutation(CREATE_SESSION);

    const navigate = useNavigate();

    /**
     * Function for validation login and password
     * Сan also add here validations for their validity
     * @param {Object} param Destructured object param
     * @param {string} param.login User login
     * @param {string} param.password User password
     */
    const validate = ({ login, password }) => {
        const isValid = login && password;
        if (isValid !== formValid) {
            setFormValid(isValid);
        }
    };

    /**
     * Run when sending the form
     * Now just show login and password
     * @param {React.ChangeEvent<HTMLInputElement>} e Needed for prevent default
     */
    const handleSubmit = (e) => {
        e.preventDefault();

        validate({ ...inputValues });
        setForceHint(true);

        if (formValid) {
            authUser({
                variables: {
                    login: inputValues.login,
                    password: inputValues.password,
                },
            })
                .then((authResponse) => {
                    if (authResponse.data.login) {
                        const refreshToken = authResponse.data.login.token;

                        /**
                         * Temporary in local storage
                         */
                        localStorage.setItem(REFRESH_TOKEN, refreshToken);

                        createSession({ fetchPolicy: 'network-only' }).then(
                            (sessionResponse) => {
                                if (sessionResponse.data.createSession.token) {
                                    localStorage.setItem(
                                        SESSION_TOKEN,
                                        sessionResponse.data.createSession.token
                                    );

                                    setCurrentUser();
                                    localStorage.setItem(
                                        IS_DISCLAIMER_SHOWN,
                                        0
                                    );
                                    navigate(mainPage);
                                }
                            }
                        );
                    }
                })
                .catch((error) => {
                    // TODO change or remove for correct showing of errors in actual version
                    console.log(error);
                    handleResponseError(
                        error.graphQLErrors[0].extensions.code,
                        setInputValuesErrors,
                        setInputValuesStatus
                    );
                });
        }
    };

    /**
     * Inputs change handler
     * @param {object} param Destructured object param
     * @param {HTMLInputElement} param.currentTarget Element in which the event is handled
     */
    const handleChange = ({ currentTarget }) => {
        const { value, name } = currentTarget;
        setInputValues((prev) => {
            const values = { ...prev, [name]: value };
            validate(values);
            return values;
        });
        setForceHint(false);
        setInputValuesErrors({
            login: null,
            password: null,
        });
    };

    useEffect(() => {
        if (currentUserVar().isAuth) {
            navigate(mainPage);
        }
    }, [navigate]);

    return (
        <form onSubmit={handleSubmit}>
            <Input
                name='login'
                value={inputValues.login}
                onChange={handleChange}
                placeholder='Адрес эл. почты или логин'
                hintMsgType={inputValuesStatus.login}
                hintMsg={inputValuesErrors.login}
                forceHint={forceHint}
                autoComplete='on'
            />
            <PasswordInput
                name='password'
                value={inputValues.password}
                onChange={handleChange}
                placeholder='Пароль'
                hintMsgType={inputValuesStatus.password}
                hintMsg={inputValuesErrors.password}
                forceHint={forceHint}
                autoComplete='current-password'
            />
            <Button disabled={!formValid}>Войти</Button>
        </form>
    );
};

import React, { useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import linkIcon from '../../../../../../shared/assets/images/icons/link.svg';
import { SCROLLBAR_STYLED } from '../../../../../../shared/ui/ScrollBar';
import { SearchBar } from '../../../../../../shared/ui/RolesSettingsComponents/SearchBar';
import {
    Button,
    TextButton,
} from '../../../../../../shared/ui/RolesSettingsComponents/Styles';
import accessGroupsModerator from '../../../../../../features/AccessGroup/model/AccessGroupsModerator';
import { POpenSansRegular } from '../../../../../../shared/ui/TextStyles';
import { ChildGroups } from './ChildGroups';

const TabContainer = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
`;

const GroupContainer = styled.div`
    margin: 20px 0 0;
    width: calc(100% - 26px);
    padding: 0 13px 0 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    ${SCROLLBAR_STYLED}
`;

const ButtonIcon = styled.img`
    height: 13px;
    width: auto;
`;

const FooterButtons = styled.div`
    margin: 20px 0 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 5px;
`;

/**
 * Component represents tab with roles settings
 * @component
 */
export const RolesTab = ({ onClose }) => {
    const groups = useReactiveVar(accessGroupsModerator.groupsMapVar);
    const groupsContainerRef = useRef(); // ref need for context menus

    return (
        <TabContainer>
            <SearchBar placeholder='Найти пользователя или группу' />
            <GroupContainer ref={groupsContainerRef}>
                {groups.size === 0 ? (
                    <POpenSansRegular style={{ alignSelf: 'center' }}>
                        Группы не найдены
                    </POpenSansRegular>
                ) : (
                    <ChildGroups
                        containerRef={groupsContainerRef}
                        rootGroupId={accessGroupsModerator.currentAccessGroupId}
                    />
                )}
            </GroupContainer>
            <FooterButtons>
                <TextButton>
                    <ButtonIcon
                        src={linkIcon}
                        alt=''
                        style={{ marginBottom: '2px' }}
                    />
                    Копировать ссылку
                </TextButton>
                <Button onClick={onClose}>Готово</Button>
            </FooterButtons>
        </TabContainer>
    );
};

RolesTab.propTypes = {
    /** Close handler */
    onClose: PropTypes.func,
};

import React from 'react';
import PropTypes from 'prop-types';
import { useOutsideClick } from '../../../../shared/model/hooks/UseOutsideClick';
import { useOutsideWheel } from '../../../../shared/model/hooks/UseOutsideWheel';
import { useRefCallback } from '../../../../shared/model/hooks/UseRefCallback';
import { StyledContextMenu } from '../../styles/UIKit';
import { PointsForContextMenu } from './PointsForContextMenu';
import { useKeyDown } from '../../../../shared/model/hooks/UseKeyDown';
import { ESC_KEY } from '../../../../widgets/Canvas/lib/CanvasDefaultValues';

/** Directions for context menu position */
export const CM_DIRECTIONS = {
    LEFT: 'left',
    RIGHT: 'right',
};

/**
 * Component that represents a context menu as html element.
 * You could pass context menu elements by two ways:
 * 1. Use menu prop (priority)
 * 2. Use react children
 * @component
 */
export const ContextMenu = ({
    position,
    menu,
    children,
    isOpened,
    directionX = CM_DIRECTIONS.LEFT,
    onClose = () => {},
}) => {
    const [wrapperComponentValue, wrapperComponentCallbackRef] =
        useRefCallback();

    // it is object with wrapperComponent value for imitating ref object
    const refImitation = { current: wrapperComponentValue };
    const handleOutsideEvent = () => {
        if (onClose) {
            onClose();
        }
    };
    useOutsideClick(refImitation, handleOutsideEvent);
    useOutsideWheel(refImitation, handleOutsideEvent);
    useKeyDown(handleOutsideEvent, [ESC_KEY]);

    if (!isOpened) {
        return;
    }

    let positionCopy = { ...position };
    if (wrapperComponentValue && directionX === CM_DIRECTIONS.RIGHT) {
        positionCopy = {
            x: position.x - wrapperComponentValue.getBoundingClientRect().width,
            y: position.y,
        };
    }

    return (
        <StyledContextMenu
            position={positionCopy}
            scale={1}
            ref={wrapperComponentCallbackRef}
            onClick={(e) => e.stopPropagation()}
        >
            {menu ? <PointsForContextMenu menu={menu} /> : children}
        </StyledContextMenu>
    );
};

ContextMenu.propTypes = {
    /** Context menu position */
    position: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
    }),

    /** Context menu points */
    menu: PropTypes.array,

    /** React children */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),

    /** Is context menu opened */
    isOpened: PropTypes.bool,

    /** Context menu direction (right or left) */
    directionX: PropTypes.oneOf([CM_DIRECTIONS.LEFT, CM_DIRECTIONS.RIGHT]),

    /** Handler for context menu close */
    onClose: PropTypes.func,
};

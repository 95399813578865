import { gql } from '@apollo/client';

/** Get user info by user selector */
export const GET_USER_MAIN_MENU_INFO = gql`
    query user($selector: UserSelector) {
        user(selector: $selector) {
            login
            name
            email
            projects {
                id
                name
                description
            }
            otherProjects {
                id
                name
                description
            }
        }
    }
`;

/** Get user info by user selector */
export const GET_USER_SETTINGS_INFO = gql`
    query user($selector: UserSelector) {
        user(selector: $selector) {
            icon {
                src
            }
            id
            login
            email
            name
            patronymic
            shortName
            surname
            email
            phone
        }
    }
`;

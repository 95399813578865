import mapObjectsAPI from '../api/MapObjectsAPI';
import mapObjects from './MapObjects';

export const loadMapObjects = async (floorId) => {
    return mapObjectsAPI
        .get(floorId)
        .then((mapObjectsData) => {
            if (mapObjectsData) {
                mapObjects.setMapObjects(mapObjects.adaptData(mapObjectsData));
            }
        })
        .catch((error) => {
            console.log(error);
        });
};

/**
 * Regex for email
 * Example: mail@mail.ru
 */ /* eslint-disable-next-line */
export const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/g;

/**
 * Regex for login
 * Only latin symbols, numbers and several symbols
 */
export const loginRegex = /^[A-Za-z0-9!#&$_]*$/;

/**
 * Regex for password
 * At least one number
 * At least one symbol
 * At least one lower case symbol
 * At least one capital case symbol
 */
export const passwordRegex = /^(?=.*[0-9])(?=.*[-!?+=_@#$%^&*])(?=.*[a-zа-я])(?=.*[A-ZА-Я])[-0-9a-zа-яA-ZА-Я!?+=_@#$%^&*]*$/;

/** Only cyrillic or latin symbols */
export const onlyCyrillicAndLatinRegex = /^[A-Za-zА-Яа-я]*$/;

/** Only numbers */
export const onlyNumbersRegex = /^[0-9]*$/;

/** Regex for required capital letter */
export const requiredCapitalLetterRegex = /.*[A-ZА-Я].*/;

/** Regex for required lower case letter */
export const requiredLowerCaseLetterRegex = /.*[a-zа-я].*/;

/** Regex for required number */
export const requiredNumberRegex = /.*[0-9].*/;

/** Regex for required symbols for password */
export const requiredPasswordSymbolRegex = /.*[-!?+=_@#$%^&*].*/;
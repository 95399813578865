import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { Group } from 'react-konva';

import {
    mapObjectsVar,
    currentMapObjectVar,
    currentToolVar,
} from '../../../shared/model/cache/Cache';
import { URLImage } from './URLImage';
import mapObjects from '../model/MapObjects';
import { Tools } from '../../../pages/Constructor/TopBar/TopBarEntires/Tools/Constants/Tools';
import {
    TELEPORT_AVAILABLE_TO_SELECT_FILL_COLOR,
    TELEPORT_DESTINATION_FILL_COLOR,
    TELEPORT_START_FILL_COLOR,
} from '../lib/Constants';

/**
 * @typedef {import('../api/MapObjectsAPI').MapObject} MapObject
 */

/**
 * Component represents all items on canvas
 *
 * @component
 * @example
 * <MapObjects />
 */
export const MapObjects = () => {
    /**
     * Reactive variable for selected item id
     * @type {string}
     */
    const currentMapObjectId = useReactiveVar(currentMapObjectVar);

    /**
     * Reactive variable for all map objects
     * @type {MapObject[]}
     */
    const mapObjectsArr = useReactiveVar(mapObjectsVar);

    const { startMapObject, destinationMapObject } = useReactiveVar(
        mapObjects.teleportCreationVar
    );

    /**
     * Function to choose fill for map object if 'add teleport' tool is active
     * @param {MapObject} mapObject
     * @returns {string} color to fill map object
     */
    const chooseFill = (mapObject) => {
        if (mapObject.id === startMapObject?.id) {
            return TELEPORT_START_FILL_COLOR;
        }
        // TODO add color for unavailable to select (for teleports that already connected)
        if (currentToolVar() === Tools.addTeleport && mapObject.teleport) {
            return mapObject.id === destinationMapObject?.id
                ? TELEPORT_DESTINATION_FILL_COLOR
                : TELEPORT_AVAILABLE_TO_SELECT_FILL_COLOR;
        }
        return 'transparent';
    };

    // Add filter to display MapObjectStub, when they're appear in project
    // <MapObjectStub mapObject={mapObject} />

    return (
        <Group>
            {mapObjectsArr.map((mapObject) => (
                <URLImage
                    mapObject={mapObject}
                    key={mapObject.id}
                    isSelected={mapObject.id === currentMapObjectId}
                    fill={chooseFill(mapObject)}
                />
            ))}
        </Group>
    );
};

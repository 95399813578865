/**
 * Wall corner points constants.
 */
export const CIRCLE_NAME = 'Circle';
export const WALL_CIRCLE_RADIUS = 10;
export const WALL_CIRCLE_FILL = 'white';
export const WALL_CIRCLE_COLOR = 'black';
export const WALL_CIRCLE_STROKE_WIDTH = 2;
export const WALL_CIRCLE_NAME = 'wall_circle';

export const WALL_CIRCLE_ENTER_COLOR = '#65D696';

/**
 *  Wall line constants.
 */
export const WALL_LINE_JOIN = 'miter';
export const WALL_LINE_CAP = 'butt';
export const WALL_COLOR = 'black';
export const WALL_UNAVAILABLE_COLOR = '#7D7D7D'; // color if user have only VIEW access rule
export const WALL_EDGE_NAME = 'wall_edge';

/**
 * Virtual wall constants.
 */
/**@type {[length_of_line_segments: number, gap: number]}*/
export const DASH_VALUE = [15, 10];
export const VIRTUAL_WALL_STROKE_WIDTH = 3;

/**
 * Temporary wall constants.
 */
export const TEMPORARY_WALL_ID = 'tmp_wall';
export const TEMPORARY_RECT_WALL_ID = 'tmp_rect_wall';
export const TEMPORARY_WALL_COLOR = 'grey';
export const TEMPORARY_WALL_INVALID_COLOR = 'red';
export const TEMPORARY_WALL_STROKE_WIDTH = 3;
export const TEMPORARY_EMBEDDED_OBJECT_CIRCLE_COLOR = 'green';
export const TEMPORARY_EMBEDDED_OBJECT_LINE_COLOR = 'blue';

/* Embedded objects */
export const EMBEDDED_OBJECT_NAME = 'embedded_object';

/**
 * Segment
 */
export const SELECTED_COLOR = '#5076FD';

export const MIN_HIT_STROKE_WIDTH = 10;

import styled from 'styled-components';

export const CONSTRUCTOR_MODE_HINT_ANIMATION_DURATION: number = 500;

export const HintContainer = styled.div`
    display: flex;
    align-items: center;
    background-color: #d9d9d9b2;
    border-radius: 39px;
    padding: 6px 17px 5px;
    pointer-events: none;
    user-select: none;
    gap: 6px;

    &.ConstructorModeHint-anim-exit {
        opacity: 1;
    }
    &.ConstructorModeHint-anim-exit-active {
        opacity: 0;
        transition: opacity ${CONSTRUCTOR_MODE_HINT_ANIMATION_DURATION}ms linear;
    }
    &.ConstructorModeHint-anim-exit-done {
        opacity: 0;
    }
`;

import styled from 'styled-components';
import { POpenSansRegular } from '../../../shared/ui/TextStyles';

/**
 * Container for content of user settings page
 */
export const Main = styled.main`
    max-width: 900px;
    margin: 10px auto;
    padding: 0 20px;
`;

/**
 * h1 styled
 */
export const Header1 = styled.h1`
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 54px;
    margin: 0 0 8px;
`;

/**
 * h2 styled
 */
export const Header2 = styled.h2`
    margin: 0 0 14px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 44px;
`;

/**
 * Subtitle for Header1
 */
export const Subtitle = styled.p`
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 33px;
    opacity: 0.7;
    margin: 0;
`;

/** Paragraph with error */
export const ErrorP = styled(POpenSansRegular)`
    color: #ff0000;
`;

/**
 * Settings group block of user settings page
 */
export const SettingsBlockStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 26px;
    margin: 44px 0 28px;
`;

/**
 * Container for label with input styled
 */
export const LabelWithInputStyled = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    row-gap: 10px;

    & div:has(input) {
        flex-grow: 1;
        min-width: 283px; // 250px for input and 33px buffer for eye img
    }

    & input {
        width: 100%;
        min-width: 250px;

        font-weight: 400;
        font-size: 24px;
        line-height: 33px;
        opacity: 100;
    }
`;

/**
 * Label for settings param
 */
export const Label = styled.p`
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    width: clamp(148px, 23vw, 210px);
`;

/**
 * Block with photo settings
 */
export const PhotoBlockStyled = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
`;

/**
 * Second column of PhotoBlock
 */
export const CommentWithPhoto = styled.div`
    flex-grow: 1;
    max-width: 690px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;

    & p {
        max-width: 454px;
        font-family: 'Open Sans';
        font-weight: 300;
        font-size: 16px;
        line-height: 22px;
        opacity: 0.9;
    }

    & .photo-circle {
        position: relative;
    }

    & .edit {
        opacity: 0;
        cursor: pointer;
    }

    & .photo-circle:hover .edit {
        opacity: 100%;
    }
`;

/**
 * User photo
 */
export const Avatar = styled.img`
    width: 79px;
    height: 79px;
    border-radius: 50%;
`;

/**
 * Edit button for user photo
 */
export const EditIcon = styled.div`
    height: 0;
    width: 0;
    position: absolute;
    top: 49.98px;
    left: calc(50% - 7px);
`;

/**
 * Square for user photo
 */
export const Square = styled.div`
    margin: 0;
    padding: 0;
    height: 39px;
    width: 100%;
    position: absolute;
    top: 39.5px;
    left: 0;
    background: rgba(131, 131, 131, 0.49);

    border-bottom-left-radius: 39.5px;
    border-bottom-right-radius: 39.5px;
`;

/** Label for password recovery */
export const ForgetPswdLabel = styled.p`
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    text-decoration: underline;
    margin: 0;

    a {
        color: #023aff;
        cursor: pointer;
    }
`;

/** Container for several elements, that should be set in one row */
export const OneRowElements = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px 15px;
`;

import {
    stageScaleVar,
    stagePositionVar,
    stageVar,
} from '../../../../shared/model/cache/Cache';
import { MAX_SCALE, MIN_SCALE, SCALE_RATIO } from '../CanvasDefaultValues';
import { updateStageSize } from './UpdateStageSize';

/**
 * Handler for canvas scroll
 * @param {object} e event object
 * @param {number} currentScale 
 * @returns 
 */
export const handleMouseScroll = (e, currentScale) => {
    // scroll stage
    if (!(e.evt.ctrlKey || e.evt.metaKey)) {
        stageScroll(e);
        return;
    }

    // zoom stage
    e.evt.preventDefault();

    /**
     * Mouse position on canvas
     * @type {object}
     */
    const pointer = stageVar().getPointerPosition();

    /**
     * The point to scale to
     * @type {object}
     */
    const mousePointTo = {
        x: (pointer.x - stageVar().x()) / currentScale,
        y: (pointer.y - stageVar().y()) / currentScale,
    };

    /**
     * Scale direction
     * @type {number}
     */
    const direction = Math.sign(e.evt.wheelDeltaY);

    let newScale =
        direction > 0 ? currentScale * SCALE_RATIO : currentScale / SCALE_RATIO;
    if (newScale > MAX_SCALE) newScale = MAX_SCALE;
    else if (newScale < MIN_SCALE) newScale = MIN_SCALE;

    stageScaleVar(newScale);

    /**
     * New in-canvas position
     * @type {object}
     */
    const newPosition = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale,
    };

    stagePositionVar(newPosition);
    updateStageSize();
};

const stageScroll = (e) => {
    const delta = e.evt.deltaY;

    // Ensure the new positions stay
    if (e.evt.shiftKey) {
        const newPositionX = stagePositionVar().x - delta;
        stagePositionVar({ ...stagePositionVar(), x: newPositionX });
    } else {
        const newPositionY = stagePositionVar().y - delta;
        stagePositionVar({ ...stagePositionVar(), y: newPositionY });
    }

    updateStageSize();
};

import React from 'react';
import { Line } from 'react-konva';
import {
    DASH_VALUE,
    MIN_HIT_STROKE_WIDTH,
    SELECTED_COLOR,
    WALL_EDGE_NAME,
    WALL_LINE_CAP,
    WALL_LINE_JOIN,
} from '../../lib/helpers/WallDefaultValues';
import { currentToolVar } from '../../../../shared/model/cache/Cache';
import { Tools } from '../../../../pages/Constructor/TopBar/TopBarEntires/Tools/Constants/Tools';
import selector from '../../../Selector/model/Selector';
import { handleScrollSelect } from '../../../Selector/lib/utils/HandleScrollSelect';
import {
    handleDragEnd,
    handleDragMove,
    handleDragStart,
    handleMouseEnter,
    handleMouseLeave,
    handleContextMenu,
} from '../../lib/utils/WallHandlers';
import { RULE_EDIT, RULE_VIEW } from '../../../AccessGroup/model/AGConstants';
import { CURSORS } from '../../../../shared/lib/utils/common/CursorModerator';

/**
 * @component Transparent line that need for managing moves of wall segments
 */
export const TransparentDraggableSegment = ({
    wallId,
    tmpPoints,
    strokeWidth,
    isVirtual,
    clickEdge,
    accessRule = RULE_VIEW,
    ...props
}) => {
    const openContextMenu = (e) => {
        e.target.preventDefault();
        const edgeId = e.target.attrs.id.substring(
            0,
            e.target.attrs.id.indexOf(':')
        );
        handleContextMenu(edgeId, isVirtual);
    };

    return (
        <Line
            id={wallId + ':selected'}
            listening={accessRule === RULE_EDIT}
            closed
            points={tmpPoints}
            name={WALL_EDGE_NAME}
            strokeWidth={0}
            stroke={SELECTED_COLOR}
            opacity={0}
            lineJoin={WALL_LINE_JOIN}
            lineCap={WALL_LINE_CAP}
            dash={DASH_VALUE}
            dashEnabled={isVirtual}
            draggable={currentToolVar() === Tools.none}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragMove={handleDragMove}
            onClick={clickEdge}
            onMouseEnter={() => {
                handleMouseEnter(
                    accessRule === RULE_EDIT
                        ? CURSORS.POINTER
                        : CURSORS.NOT_ALLOWED
                );
            }}
            onMouseLeave={(e) => {
                handleMouseLeave(e);
                selector.isHoveredVar(null);
            }}
            perfectDrawEnabled={false}
            shadowForStrokeEnabled={false}
            onContextMenu={openContextMenu}
            onWheel={handleScrollSelect}
            accessRule={accessRule}
            hitStrokeWidth={MIN_HIT_STROKE_WIDTH}
            {...props}
        />
    );
};

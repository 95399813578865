/** @class Handles commands (actions), their undo/redo and stores history of actions. */
class CommandManager {
    constructor() {
        this.history = [null];
        this.position = 0;
    }
    /**
     * Perform concrete command.
     * @param {function} commands function that represents command; available commands are in `cache/CommandManager/commands`.
     * @example
     * CommandManager.do(createItemCommand(position))
     */
    do(...commands) {
        if (this.position < this.history.length - 1) {
            this.history = this.history.slice(0, this.position + 1);
        }
        if (commands) {
            this.history.push(commands);
            this.position += 1;

            commands.forEach((command) => command.execute());
        }
    }

    /**
     * @example
     * CommandManager.undo.bind(CommandManager) - use to pass in onClick
     */
    undo() {
        if (this.position > 0) {
            this.history[this.position].forEach((command) => command.undo());
            this.position -= 1;
        }
    }

    /**
     * @example
     * CommandManager.redo.bind(CommandManager) - use to pass in onClick
     */
    redo() {
        if (this.position < this.history.length - 1) {
            this.position += 1;
            this.history[this.position].forEach((command) => command.execute());
        }
    }
}

const commandManager = new CommandManager()
export default commandManager;

import { FloorGraph } from '../../Wall/model/FloorGraph';
import routesAPI from '../api/RoutesAPI';
import {
    currentFloorVar,
    currentToolVar,
} from '../../../shared/model/cache/Cache';
import notifications from '../../HintSystem/model/Notifications';
import { Tools } from '../../../pages/Constructor/TopBar/TopBarEntires/Tools/Constants/Tools';

/**
 * @typedef {import('../../Wall/api/WallSegmentsAPI').WallSegmentDraft} WallSegmentDraft
 * @typedef {import('../../Wall/api/WallSegmentsAPI').VirtualPoint} VirtualPoint
 */

/**
 * @class
 * RouteGraph is a class for storing and interact with routes: {graph, types}.
 * Use this class when you need to modify route vertices or segments
 */
export class RouteGraph extends FloorGraph {
    constructor(routeId) {
        super();

        /** Route UUID */
        this.routeId = routeId;
    }

    /**
     * Method calls route edges (segments) creation and replace old vertices with new
     * @param {WallSegmentDraft[]} segments
     * @param {VirtualPoint[]} points
     */
    createSegmentsAndUpdateOld(segments, points) {
        // TODO handle intersections

        segments = segments.map((segment) => ({
            startPointId: segment.startPointId,
            endPointId: segment.endPointId,
            routeId: this.routeId,
        }));

        // Send query for saving segments
        routesAPI
            .createRouteSegments(segments, points)
            .then((response) => {
                if (response) {
                    const [walls, pointsTranslateMap] = response;
                    this.updateAfterFetch(segments, walls, pointsTranslateMap);
                }
            })
            .catch(async (error) => {
                // TODO: Get rid of require as soon as possible
                const loadFloor =
                    require('../../../pages/Constructor/model/LoadFloor').loadFloor;

                notifications.setError(
                    'Часть маршрута не создана',
                    error.message
                );
                currentToolVar(Tools.none);
                this.cancelCreating();
                await loadFloor(currentFloorVar().id);
            })
            .finally(() => {
                this.rerender();
            });
    }
}

const routeGraph = new RouteGraph();
export default routeGraph;

import React from 'react';
import styled from 'styled-components';
import arrowImg from '../../../../../../shared/assets/images/icons/ConstructorPage/RightBar/TeleportOperation/arrow-right.svg';
import {
    POpenSansLight,
    POpenSansRegular,
} from '../../../../../../shared/ui/TextStyles';
import { MapObjectData } from '../../../../../../features/MapObject/model/Types';

const Container = styled.div<{
    /** Need to add background on hover or not */
    needHover: boolean;
}>`
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: space-between;
    padding: 10px 8px;
    border-radius: 10px;

    :hover {
        background-color: ${({ needHover }) =>
            needHover ? '#f6f6f6;' : 'transparent'};
    }
`;

const TeleportItemColumn = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
`;

const TeleportImg = styled.img`
    width: 45px;
    max-height: 45px;
    margin: 0;
`;

const MoveButton = styled.button`
    display: flex;
    align-items: center;
    padding: 9px 6px;

    background-color: #e1e8ff;
    border-radius: 7px;
    border: none;
    outline: none;

    cursor: pointer;

    justify-self: flex-end;

    &:hover {
        background-color: #b7c7fe;
    }

    & img {
        width: 12px;
        height: auto;
    }
`;

interface TeleportItemProps {
    /** Map object as teleport */
    mapObject: MapObjectData;

    /** Floor name on which teleport placed */
    floorName?: string;

    /** Need to show button or not */
    showMoveButton?: false;
}

/** Component represents one teleport item */
export const TeleportItem = ({
    mapObject,
    floorName = '',
    showMoveButton = false,
}: TeleportItemProps) => {
    const imgSrc = 'data:image/svg+xml;utf8,' + mapObject.image.src;
    return (
        <Container needHover={showMoveButton}>
            <TeleportItemColumn>
                <TeleportImg src={imgSrc} alt={mapObject.name} />
                <POpenSansRegular>{floorName}:</POpenSansRegular>
                <POpenSansLight>{mapObject.name}</POpenSansLight>
            </TeleportItemColumn>
            {showMoveButton && (
                <TeleportItemColumn>
                    <MoveButton title='Перейти'>
                        <img src={arrowImg} alt='→' />
                    </MoveButton>
                </TeleportItemColumn>
            )}
        </Container>
    );
};

import { alertDataFromServerToAlertData } from '../../model/AlertsAdapter';
import alertsModerator from '../../model/AlertsModerator';
import { AlertData, AlertDataFromServer } from '../../model/Types';

/**
 * Function for initialize alerts on floor load.
 * Set alerts to alerts moderator
 * @param dataFromServer received data
 */
export const initializeAlerts = (dataFromServer: AlertDataFromServer[]) => {
    const alerts: AlertData[] = [];
    dataFromServer.forEach((alert) => {
        alerts.push(alertDataFromServerToAlertData(alert));
    });
    alertsModerator.addAlerts(alerts);
};

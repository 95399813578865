import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { Item } from '../../ui/OpeningGroup/Items/Item';
import { RouteGraphWrapper } from '../../../../../features/Route/model/RouteGraphWrapper';
import {
    CM_DIRECTIONS,
    ContextMenu,
} from '../../../../../features/ContextMenu/ui/styles/ContextMenu';
import { RouteContextMenu } from '../../../../../features/ContextMenu/ui/ContextMenus/RouteContextMenu';
import { modeVar } from '../../../../../shared/model/cache/Cache';
import { CREATING_MODE } from '../../../../../shared/lib/utils/ModeDefaultValues';

/**
 * Component that represents one concrete route in LeftBar
 * @component
 */
export const RouteAsItem = ({ route, containerRef }) => {
    const name = useReactiveVar(route.name);

    const optionsRef = useRef(); // ref for options button (need to open context menu)
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const contextMenuPos = {
        x: optionsRef.current?.offsetLeft + 25,
        y: optionsRef.current?.offsetTop - containerRef.current?.scrollTop - 13,
    };

    /**
     * Handler for route name change
     * @param {string} newName
     */
    const handleRouteNameEdit = (newName) => {
        route.updateRoute({ name: newName });
    };

    /**
     * Function to open context menu.
     * Context menu should open only in creating mode
     */
    const openContextMenu = () => {
        if (modeVar() === CREATING_MODE) {
            setIsMenuOpened(true);
        }
    };

    /** Function to close context menu */
    const closeContextMenu = () => {
        setIsMenuOpened(false);
    };

    return (
        <>
            <Item
                title={name || route.routeId}
                onTitleEditFinish={handleRouteNameEdit}
                onOptionsClick={openContextMenu}
                optionsRef={optionsRef}
            />
            <ContextMenu
                position={contextMenuPos}
                isOpened={isMenuOpened}
                onClose={closeContextMenu}
                directionX={CM_DIRECTIONS.LEFT}
            >
                <RouteContextMenu
                    routeId={route.routeId}
                    onClose={closeContextMenu}
                />
            </ContextMenu>
        </>
    );
};

RouteAsItem.propTypes = {
    /** Route */
    route: PropTypes.instanceOf(RouteGraphWrapper),

    /** Reference to scroll container */
    containerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
};

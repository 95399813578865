import {
    ApolloClient,
    createHttpLink,
    ApolloLink,
    Observable,
    split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLError } from 'graphql';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { cache, currentUserVar } from '../model/cache/Cache';
import {
    CREATE_SESSION_OPERATION,
    INVALID_REFRESH_TOKEN_CODE,
    INVALID_SESSION_TOKEN_CODE,
} from './APIConstants';
import { REFRESH_TOKEN, SESSION_TOKEN } from '../lib/LocalStorageConstants';
import { getMainDefinition } from '@apollo/client/utilities';
import { loginPage } from '../model/routes/staticRoutes';
import { CREATE_SESSION } from './mutations/users/CreateSession';

/**
 * Returns true if request is for refreshing session token and false otherwise
 * @param {import('@apollo/client').GraphQLRequest} operation
 * @returns {Boolean}
 */
const isRefreshSessionRequest = (operation) =>
    operation.operationName === CREATE_SESSION_OPERATION;

/**
 * Returns token for operation (refresh or session)
 * @param {import('@apollo/client').GraphQLRequest} operation
 * @returns {string} token type
 */
const getTokenTypeForOperation = (operation) =>
    isRefreshSessionRequest(operation) ? REFRESH_TOKEN : SESSION_TOKEN;

let isRefreshing = false;

/**
 * Refreshes session token on some request if session token expired
 * @returns {string} session token
 */
const refreshSessionToken = async () => {
    if (!isRefreshing) {
        try {
            isRefreshing = true;
            const response = await client.mutate({
                mutation: CREATE_SESSION,
                context: {
                    headers: {
                        RefreshToken: localStorage.getItem(REFRESH_TOKEN) || '',
                    },
                },
                fetchPolicy: 'network-only',
            });

            if (response.data) {
                const sessionToken = response.data.createSession.token;
                localStorage.setItem(SESSION_TOKEN, sessionToken);

                isRefreshing = false;
                return sessionToken;
            }
        } catch (error) {
            throw new Error('Failed to refresh session token', error);
        }
    } else {
        isRefreshing = false;
        return null;
    }
};

// link to ws connection
const wsLink = new GraphQLWsLink(
    createClient({
        url: 'wss://dev.mapins.cloud/api/graphql',
    })
);

// link to development server
const httpLink = createHttpLink({
    uri: 'https://dev.mapins.cloud/api/graphql',
});

// link to mock server
const httpLink2 = createHttpLink({
    // TODO: change on correct link
    uri: 'https://dev.mapins.cloud/graphqlMock/frontend',
});

// split links for using many links
const splitLink = split(
    (operation) => {
        const definition = getMainDefinition(operation.query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLink,
    httpLink
);

/**
 * split link 2 used to redirect queries to mock
 * if operation for mock server
 */
const splitLink2 = split(
    (operation) => operation.getContext().clientName === 'second',
    httpLink2,
    splitLink
);

/**
 * Authorization link
 * Sets context for any request with the right token (refresh or session)
 */
const authLink = setContext((operation, previousContext) => {
    const tokenType = getTokenTypeForOperation(operation);
    return {
        ...previousContext,
        headers: {
            ...previousContext.headers,
            [tokenType]:
                localStorage.getItem(tokenType) ||
                window.location.hash.slice(1),
        },
    };
});

/**
 * Error link
 * This is a link for handling errors occurred in other links
 */
const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
        if (!graphQLErrors) {
            return;
        }
        for (const error of graphQLErrors) {
            // TODO: make exact definition for socket errors and handle them in general way
            if (error.extensions.code === 'back-pressure-failure') {
                console.error(`[Subscription Error]: ${error.extensions.code}`);
                return new Observable(() => {});
            }
            if (error.message === 'Internal server error') {
                console.error(`[Subscription Error]: ${error.message}`);
                return new Observable(() => {});
            }

            if (error.extensions.code === INVALID_SESSION_TOKEN_CODE) {
                const observable = new Observable((subscriber) => {
                    (async () => {
                        try {
                            const newSessionToken = await refreshSessionToken();

                            if (!newSessionToken) {
                                throw new GraphQLError(
                                    'Invalid refresh token',
                                    {
                                        code: INVALID_REFRESH_TOKEN_CODE,
                                    }
                                );
                            }

                            const newSubscriber = {
                                next: subscriber.next.bind(subscriber),
                                error: subscriber.error.bind(subscriber),
                                complete: subscriber.complete.bind(subscriber),
                            };

                            forward(operation).subscribe(newSubscriber);
                        } catch (error) {
                            localStorage.removeItem(REFRESH_TOKEN);
                            localStorage.removeItem(SESSION_TOKEN);
                            currentUserVar({});
                            window.location.replace(loginPage);

                            subscriber.error(error);
                        }
                    })();
                });

                return observable;
            }
            if (
                error.extensions.classification === 'ValidationError' &&
                operation.getContext().clientName !== 'second'
            ) {
                const observable = new Observable((subscriber) => {
                    operation.setContext((previousContext) => ({
                        ...previousContext,
                        clientName: 'second',
                    }));

                    const newSubscriber = {
                        next: subscriber.next.bind(subscriber),
                        error: subscriber.error.bind(subscriber),
                        complete: subscriber.complete.bind(subscriber),
                    };

                    forward(operation).subscribe(newSubscriber);
                });

                return observable;
            }

            if (networkError) {
                console.log(`[Network error]: ${networkError}`);
                return;
            }
        }
    }
);

export const client = new ApolloClient({
    cache: cache,
    link: ApolloLink.from([errorLink, authLink, splitLink2]),
});

import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { useReactiveVar } from '@apollo/client';

import { Canvas } from '../../widgets/Canvas/ui/Canvas';
import { LeftBar } from './LeftBar/LeftBar';
import { RightBar } from './RightBar/RightBar';
import { TopBar } from './TopBar/TopBar';
import {
    currentProjectVar,
    currentToolVar,
    graphicSchemaVar,
    isGridEnabledVar,
    modeVar,
} from '../../shared/model/cache/Cache';
import {
    CREATING_MODE,
    VIEW_MODE,
} from '../../shared/lib/utils/ModeDefaultValues';
import editIcon from '../../shared/assets/images/icons/pencil.svg';
import selector from '../../features/Selector/model/Selector';
import { Tools } from './TopBar/TopBarEntires/Tools/Constants/Tools';

import { loadProject } from './model/LoadProject';
import floorGraph from '../../features/Wall/model/FloorGraph';
import constructorPageHints from '../../features/HintSystem/model/ConstructorPageHints';
import { HintsProvider } from '../../features/HintSystem/ui/HintsProvider';
import { ObjectSettingsModal } from '../../features/ObjectSettingsModal/ObjectSettingsModal';
import Loader from '../../shared/ui/Loader';
import ErrorMessage from '../../shared/ui/ErrorMessage';
import zones from '../../features/Zone/model/Zones';
import routesModerator from '../../features/Route/model/RoutesModerator';
import { projectPageTitle } from '../../shared/model/routes/staticRoutes';
import { BottomBar } from './BottomBar/BottomBar';
import cursorModerator from '../../shared/lib/utils/common/CursorModerator';
import constraintGroupsModerator from '../../features/Constraints/model/ConstraintGroupsModerator';
import botsModerator from '../../features/Bots/model/BotsModerator';
import { RouteEditModal } from '../../features/Route/ui/RouteEditModal';
import mapObjects from '../../features/MapObject/model/MapObjects';
import subscriptionsModerator from '../../features/Subscriptions/api/SubscriptionsModerator';
import alertsModerator from '../../features/Alert/model/AlertsModerator';
import rightBarModerator from './RightBar/model/RightBarModerator';

const EditPageContainer = styled.div`
    display: grid;
    grid-template-rows: 99px 1fr;
    grid-template-columns: 277px 1fr 306px;
    overflow: hidden;
    max-height: 100vh;
    height: 100vh;
    user-select: none;
`;

const CanvasContainer = styled.div`
    grid-row: 2 / 3;
    grid-column: 1 / -1;
    z-index: 1;
`;

const TopBarContainer = styled.div`
    grid-row: 1;
    grid-column: 1 / -1;
    z-index: 3;
`;

const LeftBarContainer = styled.div`
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    z-index: 2;
    height: fit-content;
    max-height: calc(100vh - 99px); // 99px for top bar
`;

const RightBarContainer = styled.div`
    grid-row: 2 / 3;
    grid-column: 3 / 4;
    z-index: 2;
    height: fit-content;
    max-height: calc(100vh - 99px); // 99px for top bar
`;

const EditButton = styled.button`
    position: absolute;
    bottom: 28px;
    right: 40px;
    display: flex;
    align-items: center;
    padding: 11px 14px;
    gap: 12px;
    border-radius: 13px;
    border-color: transparent;
    font-style: normal;
    cursor: pointer;
    background: #b7c7fe;
    color: black;
    z-index: 1;

    &:hover {
        background: #e1e8ff;
    }

    & > img {
        width: 14px;
        height: auto;
    }
`;

/**
 * Component that represents edit mode page.
 *
 * @component
 */
export const ConstructorPage = () => {
    const [windowSize, setWindowSize] = useState({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
    });

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(undefined);

    const leftBarContainerRef = useRef();
    const rightBarContainerRef = useRef();
    const params = useParams();

    const mode = useReactiveVar(modeVar);
    const currentProject = useReactiveVar(currentProjectVar);

    useEffect(() => {
        document.title =
            (currentProject.name === ''
                ? '<Без названия>'
                : currentProject.name) + projectPageTitle;
    }, [currentProject.name]);

    // useEffect for changing mode
    useEffect(() => {
        if (mode === VIEW_MODE) {
            isGridEnabledVar(false); // disable grid
            selector.clear(); // clear selected objects
            currentToolVar(Tools.none); // disable tool
            constructorPageHints.setEnterViewMode();
        } else {
            isGridEnabledVar(true); // enable grid
            constructorPageHints.setEnterEditMode();
        }
    }, [mode]);

    /**
     * Window size handler
     */
    const handleWindowResize = () => {
        setWindowSize({
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
        });
    };

    /**
     * Set current window size when it changes
     */
    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        loadProject(params.id, setLoading, setError);
        graphicSchemaVar(null); // close mapins schema

        return () => {
            rightBarModerator.clear();
            zones.clear();
            floorGraph.clear();
            mapObjects.clear();
            routesModerator.clear();
            constraintGroupsModerator.clear();
            alertsModerator.clear();
            botsModerator.clear();
            subscriptionsModerator.clear();
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [params.id]);

    /** Handler for edit button in view mode */
    const handleEditClick = () => {
        modeVar(CREATING_MODE);
        rightBarModerator.open();
        cursorModerator.autoCalcAndChangeDefaultCursor(); // set cursor
    };

    if (loading) {
        return (
            <div style={{ height: '100vh' }}>
                <Loader />
            </div>
        );
    }

    if (error) {
        return (
            <div style={{ height: '100vh' }}>
                <ErrorMessage errorMessage={error.message} />
            </div>
        );
    }

    return (
        <>
            <HintsProvider hintsInstance={constructorPageHints} />
            <BottomBar />
            <EditPageContainer>
                <TopBarContainer id='topBarContainer'>
                    <TopBar mode={mode} />
                </TopBarContainer>

                <LeftBarContainer ref={leftBarContainerRef}>
                    <LeftBar leftBarContainerRef={leftBarContainerRef} />
                </LeftBarContainer>

                <CanvasContainer>
                    <Canvas
                        width={windowSize.windowWidth}
                        height={windowSize.windowHeight}
                    />
                </CanvasContainer>

                {mode === CREATING_MODE && (
                    <RightBarContainer ref={rightBarContainerRef}>
                        <RightBar rightBarContainerRef={rightBarContainerRef} />
                    </RightBarContainer>
                )}
                {mode === VIEW_MODE && (
                    <EditButton onClick={handleEditClick}>
                        <img src={editIcon} alt='edit' />
                        Редактировать
                    </EditButton>
                )}
                <ObjectSettingsModal />
                <RouteEditModal />
            </EditPageContainer>
        </>
    );
};

import { deepCopy } from '../../../shared/lib/utils/common/DeepCopy';

/*
 * This file contains functions to work with access rules.
 * Use this functions to manage access rules: initialize, add, update, delete
 */

/** Describe access rule data structure received from server */
interface AccessRuleFromServer {
    group: {
        id: string;
        name: string | undefined;
    };
    rule: string;
}

/**
 * Function to set access rules firstly
 * This function could become an adapter in future
 */
export const initializeAccessRules = (
    accessRules: AccessRuleFromServer[]
): AccessRuleFromServer[] => {
    return [...accessRules];
};

/**
 * Function that adds/updates several access rules to current access rules array
 */
export const updateAccessRules = (
    currentAccessRules: AccessRuleFromServer[],
    newAccessRules: AccessRuleFromServer[]
): AccessRuleFromServer[] => {
    let resultRules: AccessRuleFromServer[] = deepCopy(currentAccessRules);
    newAccessRules.forEach(
        (rule) => (resultRules = updateAccessRule(resultRules, rule))
    );
    return resultRules;
};

/**
 * This function update rule if its exists for group
 * or add as new access rule
 */
export const updateAccessRule = (
    currentAccessRules: AccessRuleFromServer[],
    accessRule: AccessRuleFromServer
): AccessRuleFromServer[] => {
    const toUpdateId = accessRule.group.id;
    const toUpdateIndex = currentAccessRules.findIndex(
        (elem) => elem.group.id === toUpdateId
    );
    if (toUpdateIndex >= 0) {
        currentAccessRules[toUpdateIndex] = deepCopy(accessRule);
        return [...currentAccessRules];
    }
    return [...currentAccessRules, accessRule];
};

/**
 * Function that delete several access rules from current access rules
 */
export const deleteAccessRules = (
    currentAccessRules: AccessRuleFromServer[],
    deleteAccessRules: AccessRuleFromServer[]
): AccessRuleFromServer[] => {
    let resultRules = deepCopy(currentAccessRules);
    deleteAccessRules.forEach(
        (rule) => (resultRules = deleteAccessRule(resultRules, rule))
    );
    return resultRules;
};

/**
 * This function delete access rule from current access rules
 */
export const deleteAccessRule = (
    currentAccessRules: AccessRuleFromServer[],
    accessRule: AccessRuleFromServer
): AccessRuleFromServer[] => {
    const toDeleteId = accessRule.group.id;
    return currentAccessRules.filter((rule) => rule.group.id !== toDeleteId);
};

import { useReactiveVar } from '@apollo/client';
import React from 'react';

import floorGraph from '../../model/FloorGraph';
import { WallSegment } from './WallSegment';
import { EmbeddedSegment } from './EmbeddedSegment';
import scalesConformer from '../../../ScalesConformer/model/ScalesConformer';
import {
    TEMPORARY_WALL_COLOR,
    WALL_UNAVAILABLE_COLOR,
} from '../../lib/helpers/WallDefaultValues';
import { RULE_VIEW } from '../../../AccessGroup/model/AGConstants';

/**
 * @component Component that represent all the wall segments on canvas
 * @example <CanvasWallSegments />
 */
export const CanvasWallSegments = () => {
    const wallSegments = useReactiveVar(floorGraph.drawSegmentsVar);

    return wallSegments.map((segmentLink) => {
        const key = segmentLink.getKey();
        const value = segmentLink.getInfo();

        if (segmentLink.isSegmentEmbedded()) {
            return (
                <EmbeddedSegment
                    key={key}
                    edgeId={key}
                    edgeProps={value.type}
                    accessRule={value.accessRule}
                />
            );
        }

        let color = value.isFetched
            ? value.type.image.src
            : TEMPORARY_WALL_COLOR;
        if (value.accessRule === RULE_VIEW) {
            color = WALL_UNAVAILABLE_COLOR;
        }

        const drawablePolygon =
            floorGraph.formDrawableSegmentPolygon(segmentLink);

        return (
            drawablePolygon && (
                <WallSegment
                    key={key}
                    wallId={key}
                    points={drawablePolygon}
                    color={color}
                    strokeWidth={scalesConformer.toPixels(value.type.width)}
                    isVirtual={value.type.virtual}
                    accessRule={value.accessRule}
                />
            )
        );
    });
};

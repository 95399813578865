import { makeVar } from '@apollo/client';
import { RouteGraph } from './RouteGraph';
import routesAPI from '../api/RoutesAPI';
import notifications from '../../HintSystem/model/Notifications';
import { RULE_VIEW } from '../../AccessGroup/model/AGConstants';
import { routeGraphWrapperToRouteData } from './RouteAdapter';

/**
 * @typedef {import('./RouteGraph').RouteGraph} RouteGraph
 * @typedef {import('../../../shared/typedefs/typedefs').StringReactiveVar} StringReactiveVar
 * @typedef {import('../../../shared/typedefs/typedefs').NumberReactiveVar} NumberReactiveVar
 * @typedef {import('./RouteAdapter').RouteData} RouteData
 */

/**
 * @class
 * RouteGraphWrapper is a class for interaction with one concrete route.
 * Use this class to modify route properties such as: name, description, color, etc.
 *
 * All the logic of working with points and segments is encapsulated
 * in a RouteGraph class. Use field 'graph' to get access to RouteGraph features
 */
export class RouteGraphWrapper {
    /**
     * @constructor
     * @param {string} constraintGroupId - Constraint group UUID
     * @param {string} routeId - Route UUID
     * @param {RouteData} extra - Extra properties
     * @param {string} [extra.name=''] - Route name
     * @param {string} [extra.description=''] - Route description
     * @param {number} [extra.version=0] - Route version
     * @param {string} [extra.color=''] - Color for route drawing
     * @param {number} [extra.transparency=0] - Transparency for route drawing
     * @param {string} [extra.accessRule='VIEW'] - Route access rule for current user: EDIT/VIEW
     */
    constructor(
        constraintGroupId,
        routeId,
        {
            name = '',
            description = '',
            version = 0,
            color = '',
            transparency = 0,
            accessRule = RULE_VIEW,
        }
    ) {
        /**
         * UUID of constraint group where route located
         * @type {string}
         */
        this.constraintGroupId = constraintGroupId;

        /**
         * Route UUID
         * @type {string}
         */
        this.routeId = routeId;

        /**
         * Route name
         * @type {StringReactiveVar}
         */
        this.name = makeVar(name);

        /**
         * Route description
         * @type {StringReactiveVar}
         */
        this.description = makeVar(description);

        /**
         * Route version
         * @type {NumberReactiveVar}
         */
        this.version = makeVar(version);

        /**
         * Route color
         * @type {StringReactiveVar}
         */
        this.color = makeVar(color);

        /**
         * Route transparency
         * @type {NumberReactiveVar}
         */
        this.transparency = makeVar(transparency);

        /**
         * Access rule for route for current user
         * @type {string}
         */
        this.accessRule = accessRule;

        /**
         * Route graph
         * @type {RouteGraph}
         */
        this.graph = new RouteGraph(routeId);
    }

    /**
     * Method to get all stored route properties
     * @returns {RouteData}
     */
    getRouteData() {
        return routeGraphWrapperToRouteData(this);
    }

    /**
     * Update route properties.
     * Note: this method updates properties only locally.
     * To update on server - use updateRoute().
     * @param {RouteData} routeData
     */
    updateRouteProperties(routeData) {
        const properties = [
            'name',
            'description',
            'version',
            'color',
            'transparency',
        ];
        properties.forEach((prop) => {
            if (routeData[prop] !== undefined) {
                this[prop](routeData[prop]);
            }
        });

        if (routeData.accessRule !== undefined) {
            this.accessRule = routeData.accessRule;
        }
    }

    /**
     * Method to send query that updates route properties
     * @param {RouteData} routeData new route properties: name, color, etc.
     */
    async updateRoute(routeData) {
        const data = {
            ...this.getRouteData(),
            ...routeData,
            id: this.routeId,
        };
        try {
            const response = await routesAPI.updateRoute(data);
            if (response) {
                this.updateRouteProperties(response);
            }
        } catch (error) {
            notifications.setError('Маршрут не обновлён', error.message);
        }
    }
}

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { OpeningGroup } from '../ui/OpeningGroup/OpeningGroup';
import { RoutesList } from './Items/RoutesList';
import { ConstraintGroup } from '../../../../features/Constraints/model/ConstraintGroup';
import {
    CM_DIRECTIONS,
    ContextMenu,
} from '../../../../features/ContextMenu/ui/styles/ContextMenu';
import { modeVar } from '../../../../shared/model/cache/Cache';
import { CREATING_MODE } from '../../../../shared/lib/utils/ModeDefaultValues';
import { ConstraintGroupContextMenu } from '../../../../features/ContextMenu/ui/ContextMenus/ConstraintGroupContextMenu';

/**
 * Component that represents concrete constraint group in left bar
 * @component
 */
export const ConstraintGroupFolder = ({ constrGroup, containerRef }) => {
    const groupName = useReactiveVar(constrGroup.name);

    const optionsRef = useRef(); // ref for options button (need to open context menu)
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const contextMenuPos = {
        x: optionsRef.current?.offsetLeft + 25,
        y: optionsRef.current?.offsetTop - containerRef.current?.scrollTop - 13,
    };

    /**
     * Function to open context menu.
     * Context menu should open only in creating mode
     */
    const openContextMenu = () => {
        if (modeVar() === CREATING_MODE) {
            setIsMenuOpened(true);
        }
    };

    /** Function to close context menu */
    const closeContextMenu = () => {
        setIsMenuOpened(false);
    };

    /**
     * Handler for constraint group name change
     * @param {string} newName
     */
    const changeGroupName = (newName) => {
        constrGroup.updateGroup({ name: newName });
    };

    return (
        <>
            <OpeningGroup
                title={groupName || constrGroup.groupId}
                onTitleEditFinish={changeGroupName}
                optionsRef={optionsRef}
                onOptionsClick={openContextMenu}
            >
                <RoutesList
                    groupId={constrGroup.groupId}
                    containerRef={containerRef}
                />
            </OpeningGroup>
            <ContextMenu
                position={contextMenuPos}
                isOpened={isMenuOpened}
                onClose={closeContextMenu}
                directionX={CM_DIRECTIONS.LEFT}
            >
                <ConstraintGroupContextMenu
                    constrGroupId={constrGroup.groupId}
                    onClose={closeContextMenu}
                />
            </ContextMenu>
        </>
    );
};

ConstraintGroupFolder.propTypes = {
    /** Constraint group */
    constrGroup: PropTypes.instanceOf(ConstraintGroup),

    /** Reference to scroll container */
    containerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
};

import { gql } from '@apollo/client';

/**
 * Query to get project by id
 */
export const GET_PROJECT = gql`
    query project($id: String!, $userId: String!) {
        project(id: $id) {
            id
            accuracy
            owner {
                id
            }
            name
            description
            floors {
                id
                num
            }
            accessGroups {
                id
                name
                description
                parentMirror
                floorCreationAvailable
                notificationCreationAvailable
                parent {
                    id
                }
                childGroups {
                    id
                }
                descendants {
                    id
                }
                members {
                    ... on User {
                        id
                        name
                        surname
                        email
                    }
                    ... on Bot {
                        id
                        name
                        description
                    }
                }
            }
            globalTypesGroups {
                description
                id
                name
                owner {
                    id
                }
                isGlobal
                wallSegmentTypes {
                    id
                    name
                    description
                    length
                    width
                    scalable
                    through
                    virtual
                    imageOffsetCoefficient
                    image {
                        id
                        src
                        type
                    }
                }
            }
            bots {
                id
                name
                description
            }
        }
        accessGroup(
            userProjectGroupSelector: { userId: $userId, projectId: $id }
        ) {
            id
        }
    }
`;

import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_USER_PASSWORD } from '../../../shared/graphql/mutations/users/UpdateUserPassword';
import {
    Header2,
    SettingsBlockStyled,
    LabelWithInputStyled,
    Label,
    ForgetPswdLabel,
    OneRowElements,
    ErrorP,
} from './Styles';
import { PasswordInput } from '../../../shared/ui/FormComponents/PasswordInput';
import { Button } from '../../../shared/ui/FormComponents/Button';
import { validatePasswordChangeForm } from '../lib/utils/SettingsValidation';
import { currentUserVar } from '../../../shared/model/cache/Cache';
import { inputStatus } from '../../../shared/ui/FormComponents/FormConstants';
import notifications from '../../../features/HintSystem/model/Notifications';
import { PreloaderWithText } from '../../../shared/ui/PreloaderWithText';
import { NavLink } from 'react-router-dom';
import { requestRecoverPage } from '../../../shared/model/routes/staticRoutes';

/**
 * Function that represents map logic for objects
 * @param {Object} object
 * @param {(value) => newValue} mapFn
 * @returns {Object} New mapped object
 */
const obJectMap = (object, mapFn) => {
    return Object.keys(object).reduce((result, key) => {
        result[key] = mapFn(object[key]);
        return result;
    }, {});
};

/** hints for different fields, needed for focus handler */
const hintsForFields = {
    password: 'Введите текущий пароль',
    newPassword: 'Введите новый пароль',
    newPasswordControl: 'Повторите пароль',
};

/**
 * Block with password change
 * @component
 */
export const PasswordChangeBlock = () => {
    const [formValid, setFormValid] = useState(false);

    const [inputValues, setInputValues] = useState({
        password: '',
        newPassword: '',
        newPasswordControl: '',
    });

    // for setting hint for input
    const [inputHints, setInputValuesErrors] = useState({
        password: null,
        newPassword: null,
        newPasswordControl: null,
    });

    // for setting color of input hints
    const [inputHintsStatus, setInputValuesStatus] = useState({
        password: inputStatus.hint,
        newPassword: inputStatus.hint,
        newPasswordControl: inputStatus.hint,
    });

    const [updatePasswordError, setPasswordError] = useState(false);
    const [updatePasswordQuery, { loading: updatePasswordLoading }] =
        useMutation(UPDATE_USER_PASSWORD);

    /**
     * Input focus handler
     * Set hint message
     * @param { currentTarget } param Element in which the event is handled
     */
    const onFocusInputHandler = ({ currentTarget }) => {
        const { value, name } = currentTarget;
        if (value === '') {
            setInputValuesErrors((prev) => ({
                ...prev,
                [name]: hintsForFields[name],
            }));
            setInputValuesStatus((prev) => ({
                ...prev,
                [name]: inputStatus.hint,
            }));
        }
    };

    /**
     * Function for validation input data
     * @param {Object} param Input data
     * @param {String} param.password Current password
     * @param {String} param.newPassword New password
     * @param {String} param.newPasswordControl Control password
     */
    const validate = ({ password, newPassword, newPasswordControl }) => {
        validatePasswordChangeForm(
            { password, newPassword, newPasswordControl },
            formValid,
            setFormValid,
            setInputValuesErrors,
            setInputValuesStatus
        );
    };

    /** Function that clears all inputs fields and hints */
    const clearInputFields = () => {
        setInputValues(obJectMap(inputValues, () => ''));
        setInputValuesErrors(obJectMap(inputHints, () => null));
        setInputValuesStatus(
            obJectMap(inputHintsStatus, () => inputStatus.hint)
        );
    };

    /** Handler for success info update */
    const onSuccessUpdateHandler = () => {
        clearInputFields();
        setFormValid(false);
        notifications.setSuccess('Оповещение', 'Пароль успешно изменён');
    };

    /**
     * Handler for form submission
     * @param {React.FormEvent} e Form event, needed for prevent default
     */
    const submitHandler = (e) => {
        e.preventDefault();

        if (formValid) {
            // password update request
            updatePasswordQuery({
                variables: {
                    updateUserData: {
                        id: currentUserVar().id,
                        password: inputValues.newPassword,
                    },
                },
                onCompleted: onSuccessUpdateHandler,
                onError: (err) => {
                    console.error(err);
                    setPasswordError(true);
                },
            });
        }
    };

    /**
     * Inputs change handler
     * @param {Object} param Destructured object param
     * @param {HTMLInputElement} param.currentTarget Element in which the event is handled
     */
    const handleChange = ({ currentTarget }) => {
        const { value, name } = currentTarget;
        setInputValues((prev) => {
            const values = { ...prev, [name]: value };
            validate(values);
            return values;
        });

        if (updatePasswordError) {
            setPasswordError(false);
        }
    };

    return (
        <form onSubmit={submitHandler}>
            <SettingsBlockStyled>
                <Header2>Пароль</Header2>
                <LabelWithInputStyled>
                    <Label>Текущий пароль</Label>
                    <PasswordInput
                        name='password'
                        value={inputValues.password}
                        onChange={handleChange}
                        hintMsgType={inputHintsStatus.password}
                        hintMsg={inputHints.password}
                        onFocus={onFocusInputHandler}
                        autoComplete='current-password'
                    />
                </LabelWithInputStyled>
                <LabelWithInputStyled>
                    <Label>Новый пароль</Label>
                    <PasswordInput
                        name='newPassword'
                        value={inputValues.newPassword}
                        onChange={handleChange}
                        hintMsgType={inputHintsStatus.newPassword}
                        hintMsg={inputHints.newPassword}
                        onFocus={onFocusInputHandler}
                        autoComplete='new-password'
                    />
                </LabelWithInputStyled>
                <LabelWithInputStyled>
                    <Label>Повторите пароль</Label>
                    <PasswordInput
                        name='newPasswordControl'
                        value={inputValues.newPasswordControl}
                        onChange={handleChange}
                        hintMsgType={inputHintsStatus.newPasswordControl}
                        hintMsg={inputHints.newPasswordControl}
                        onFocus={onFocusInputHandler}
                        autoComplete='new-password'
                    />
                </LabelWithInputStyled>
                {updatePasswordError && (
                    <ErrorP>
                        Ошибка при обновлении пароля. <br />
                        Попробуйте ещё раз, если проблема останется - обратитесь
                        в тех. поддержку
                    </ErrorP>
                )}
                <OneRowElements>
                    <Button type='submit' disabled={!formValid}>
                        Обновить пароль
                    </Button>
                    {updatePasswordLoading && (
                        <div style={{ marginBottom: '17px' }}>
                            <PreloaderWithText
                                loaderSize={40}
                                text='Обновляем данные'
                                fullSpace={false}
                            />
                        </div>
                    )}
                </OneRowElements>
                <ForgetPswdLabel>
                    <NavLink to={requestRecoverPage}>Забыли пароль?</NavLink>
                </ForgetPswdLabel>
            </SettingsBlockStyled>
        </form>
    );
};

import { HINT_POSITION } from '../../model/Constants';

/**
 * Calc position styles for notification container
 * @param {HINT_POSITION} position
 * @param {number} offsetX
 * @param {number} offsetY
 * @returns {string} string with css styles
 */
export const getStylesForPosition = (
    position: HINT_POSITION,
    offsetX: number,
    offsetY: number
): string => {
    switch (position) {
        case HINT_POSITION.topRight:
            return `
                top: ${offsetY}px;
                right: ${offsetX}px;
            `;
        case HINT_POSITION.topLeft:
            return `
                top: ${offsetY}px;
                left: ${offsetX}px;
            `;
        case HINT_POSITION.bottomMiddle:
            return `
                bottom: ${offsetY}px;
                left: calc(50% + ${offsetX}px);
                transform: translate(-50%, 0);
            `;
        case HINT_POSITION.bottomRight:
            return `
                bottom: ${offsetY}px;
                right: ${offsetX}px;
            `;
        case HINT_POSITION.bottomLeft:
            return `
                bottom: ${offsetY}px;
                left: ${offsetX}px;
            `;
        case HINT_POSITION.topMiddle:
        default:
            return `
                top: ${offsetY}px;
                left: calc(50% + ${offsetX}px);
                transform: translate(-50%, 0);
            `;
    }
};

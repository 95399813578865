import React from 'react';
import styled from 'styled-components';
import { useReactiveVar } from '@apollo/client';
import { CREATING_MODE } from '../../../shared/lib/utils/ModeDefaultValues';
import { ZoomRegulator } from './BottomBarEntries/ZoomRegulator';
import { BottomTools } from './BottomBarEntries/BottomTools';
import { GridSwitcher } from './BottomBarEntries/GridSwitcher';
import { modeVar } from '../../../shared/model/cache/Cache';
import { DragToolSwitcher } from './BottomBarEntries/DragToolSwitcher';

const BottomContainer = styled.div`
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 28px;
    left: 0;
    right: 0;
`;

/**
 * Component that represents bottom bar on Constructor page
 * @component
 */
export const BottomBar = () => {
    const mode = useReactiveVar(modeVar);
    const isCreatingMode = mode === CREATING_MODE;
    return (
        <BottomContainer>
            {isCreatingMode && <BottomTools />}
            <ZoomRegulator />
            {isCreatingMode && <GridSwitcher />}
            {isCreatingMode && <DragToolSwitcher />}
        </BottomContainer>
    );
};

import { objectWithSchemaTypes } from '../../../shared/model/mapinsForm/activateMapinsForm';
import notifications from '../../HintSystem/model/Notifications';
import mapObjectsAPI from '../../MapObject/api/MapObjectsAPI';
import mapObjects from '../../MapObject/model/MapObjects';
import floorGraph from '../../Wall/model/FloorGraph';
import zones from '../../Zone/model/Zones';
import schemaImplAPI from '../api/SchemaImplAPI';
import schemaSpecifier from './SchemaSpecifier';
import { updateSchemaOnObject } from './UpdateSchemaOnObject';

/**
 * @typedef {import('../../MapObject/api/MapObjectsAPI').MapObject} MapObject
 * @typedef {import('../../Zone/model/Zone').ZoneProps} ZoneProps
 * @typedef {import('../../Wall/model/SegmentsModerator').WallSegmentType} WallSegmentType
 * @typedef {import('../../../shared/model/cache/Cache').GraphicSchema} GraphicSchema
 */

/**
 * @param {GraphicSchema} schemaObject
 */
export const schemaSaveDefiner = (schemaObject) => {
    const [schema, schemaImpl] = schemaSpecifier.serializeSchemaAndSchemaImpl(
        schemaObject.schema.objectExtendablePropsSchema
    );

    const objectsToFunctions = {
        [objectWithSchemaTypes.mapObject]: () =>
            saveUpdatesMapObject(schema, schemaImpl, schemaObject),
        [objectWithSchemaTypes.zone]: () => saveUpdatesZones(schemaObject),
        [objectWithSchemaTypes.wallSegment]: () =>
            saveUpdatesWallSegments(schemaObject),
    };

    objectsToFunctions[schemaObject.objectTypeName]();
};

const saveUpdatesMapObject = (schema, schemaImpl, schemaObject) => {
    const object = mapObjects.get(schemaObject.objectId);

    mapObjectsAPI
        .update({
            id: schemaObject?.objectId,
            version: +object?.version,
            data: {
                data: schemaImpl,
                schema: schema,
            },
        })
        .then((response) => {
            if (response.data) {
                mapObjects.updateMapObjectSchema(
                    response.id,
                    response.data,
                    response.schemaImpls
                );
            }
        })
        .catch((error) => {
            notifications.setError('Не удалось установить схему', error);
        });

    saveSchemaImplsUpdates(object, schemaObject);
};

const saveSchemaImplsUpdates = (object, schemaObject) => {
    object.schemaImpls.forEach((schemaImpl) => {
        /**
         * @type {import('./SchemaSpecifier').SchemaForm}
         */
        const formedSchema = schemaObject?.schema?.objectBotPropsSchemas?.find(
            (item) => item.schemaImplId === schemaImpl.id
        );
        const [, iSchemaImpl] =
            schemaSpecifier.serializeSchemaAndSchemaImpl(formedSchema);

        schemaImplAPI
            .update(schemaObject.objectTypeName, {
                data: iSchemaImpl,
                id: formedSchema?.schemaImplId,
                version: formedSchema?.version,
            })
            .then((res) => {
                updateSchemaOnObject(res.item);
            })
            .catch((error) => {
                notifications.setError('Не удалось сохранить схему', error);
            });
    });
};

const saveUpdatesZones = (schemaObject) => {
    const object = zones.get(schemaObject.objectId).props;

    saveSchemaImplsUpdates(object, schemaObject);
};

const saveUpdatesWallSegments = (schemaObject) => {
    const object = floorGraph.segmentsModerator
        .getSegmentById(schemaObject.objectId)
        ?.getInfo();

    saveSchemaImplsUpdates(object, schemaObject);
};

import { gql } from '@apollo/client';
import { ROUTE_FRAGMENT } from '../fragments/routes/RouteFragment';
import { CONSTRAINT_GROUP_FRAGMENT } from '../fragments/constraintGroups/ConstraintGroupFragment';
import { ATTENTION_MARKER_FRAGMENT } from '../fragments/attentionMarkers/AttentionMarkerFragment';

export const PROJECT_CHANGES_UPDATES = gql`
    ${CONSTRAINT_GROUP_FRAGMENT}
    ${ROUTE_FRAGMENT}
    ${ATTENTION_MARKER_FRAGMENT}
    subscription projectChanges(
        $token: String
        $projectId: String
        $floorId: String
    ) {
        projectChanges(
            token: $token
            projectId: $projectId
            floorId: $floorId
        ) {
            item {
                __typename
                ... on Item {
                    id
                    typename
                }
                ... on VersionedItem {
                    id
                    typename
                }
                ... on AttentionMarker {
                    ...AttentionMarkerFragment
                }
                ... on MapObject {
                    id
                    data {
                        data
                        version
                        schema {
                            schema
                        }
                    }
                    schemaImpls {
                        data
                        id
                        version
                        schema {
                            id
                            schema
                            version
                        }
                    }
                }
                ... on ConstraintGroup {
                    ...ConstraintGroupFragment
                }
                ... on Route {
                    ...RouteFragment
                    constraintGroup {
                        id
                    }
                    segments {
                        id
                        version
                        startPoint {
                            id
                            coordinate {
                                x
                                y
                            }
                        }
                        endPoint {
                            id
                            coordinate {
                                x
                                y
                            }
                        }
                        accessRules {
                            ...AccessRuleFragment
                        }
                    }
                }
                ... on RouteSegment {
                    id
                    version
                    startPoint {
                        id
                        coordinate {
                            x
                            y
                        }
                    }
                    endPoint {
                        id
                        coordinate {
                            x
                            y
                        }
                    }
                    route {
                        id
                    }
                    accessRules {
                        ...AccessRuleFragment
                    }
                }
                ... on MapinsSchemaImpl {
                    id
                    item {
                        ... on MapObject {
                            id
                            data {
                                data
                                version
                                schema {
                                    schema
                                }
                            }
                            schemaImpls {
                                data
                                id
                                version
                                schema {
                                    id
                                    schema
                                    version
                                }
                            }
                        }
                        ... on WallSegment {
                            id
                            startPoint {
                                id
                            }
                            endPoint {
                                id
                            }
                            data {
                                data
                                version
                                schema {
                                    schema
                                }
                            }
                            schemaImpls {
                                data
                                id
                                version
                                schema {
                                    id
                                    schema
                                    version
                                }
                            }
                        }
                        ... on Zone {
                            id
                            schemaImpls {
                                data
                                id
                                version
                                schema {
                                    id
                                    schema
                                    version
                                }
                            }
                        }
                    }
                }
            }
            operation
        }
    }
`;

import { makeVar } from '@apollo/client';
import { v4 as uuid } from 'uuid';

import * as WallLogic from '../lib/helpers';
import { CustomMap } from '../../../shared/lib/modules/CustomMap';
import { deepCopy } from '../../../shared/lib/utils/common/DeepCopy';
import {
    LinesIntersectionType,
    arePointsEqual,
    findLinesIntersection,
    isLineVertical,
    isPointLowerThanAnother,
    isPointOnLineEnd,
    isPointToTheLeftFromAnother,
    subtractTwoPoints,
    getDistanceBetweenPoints,
} from '../../../shared/lib/modules/math/Math';
import {
    currentFloorVar,
    currentToolVar,
} from '../../../shared/model/cache/Cache';

import wallSegmentsAPI from '../api/WallSegmentsAPI';
import notifications from '../../HintSystem/model/Notifications';
import { Tools } from '../../../pages/Constructor/TopBar/TopBarEntires/Tools/Constants/Tools';
import {
    sortVertexIdsByX,
    sortVertexIdsByY,
} from '../../../shared/lib/utils/vertices/SortVerticesArray';
import { LinkedSegment, SegmentsModerator } from './SegmentsModerator';
import zones from '../../Zone/model/Zones';
import { RULE_EDIT } from '../../AccessGroup/model/AGConstants';
import { getRuleForCurrentUser } from '../../AccessGroup/lib/GetRuleForCurrentUser';
import rightBarModerator from '../../../pages/Constructor/RightBar/model/RightBarModerator';

/**
 * @typedef {import('../../../shared/lib/modules/math/Math').Point} Point
 * @typedef {import('../api/WallSegmentsAPI').WallSegmentDraft} WallSegmentDraft
 * @typedef {import('../api/WallSegmentsAPI').VirtualPoint} VirtualPoint
 * @typedef {import('../api/WallSegmentsAPI').WallSegmentType} WallSegmentType
 * @typedef {import('../api/WallSegmentsAPI').WallSegment} WallSegment
 * @typedef {import('../api/WallSegmentsAPI').ImageSource} ImageSource
 * @typedef {import('./SegmentsModerator').PointLinkWrapper} PointLinkWrapper
 * @typedef {import('../lib/helpers/CalculateCoordinateForSecondPoint').TmpSegmentProps} TmpSegmentProps
 */

/**
 * @typedef {{coordinates: Point, adjacentPoints: String[], accessRule: {string}}} GraphPointValue
 * @typedef {{id: String, type: WallSegmentType}} WallTypeValue
 */

/**
 * @callback GraphVar
 * @param {Map<String, GraphPointValue>} argument
 * @return {Map<String, GraphPointValue>} result
 */

/**
 * @callback TypesVar
 * @param {CustomMap<[String, String], WallTypeValue>} argument
 * @return {CustomMap<[String, String], WallTypeValue>} result
 */

/**
 * @callback TmpEdgeVar
 * @param {Map<String, GraphPointValue>} argument
 * @return {Map<String, GraphPointValue>} result
 */

/**
 * @callback CurrentWallSegmentTypeVar
 * @param {WallSegmentType} argument
 * @return {WallSegmentType}
 */

/**
 * @callback WallSegmentsModeratorVar
 * @param {SegmentsModerator}
 * @return {SegmentsModerator}
 */

/**
 * @callback DrawPointsVar
 * @param {PointLinkWrapper[]}
 * @return {PointLinkWrapper[]}
 */

/**
 * @callback DrawSegmentsVar
 * @param {LinkedSegment[]} segments
 * @return {LinkedSegment[]}
 */

/**
 * @class FloorGraph is a class for storing and interact with walls: {graph, types}
 */
export class FloorGraph {
    constructor() {
        /**********************************************************
         * WallSegmentsModerator
         * @type {SegmentsModerator}
         */
        this.segmentsModerator = new SegmentsModerator();

        /**
         * @type {DrawPointsVar}
         */
        this.drawPointsVar = makeVar([]);
        /**
         * @type {DrawSegmentsVar}
         */
        this.drawSegmentsVar = makeVar([]);
        /*********************************************************/

        /**
         * Properties for temporary edge
         * @type {TmpSegmentProps}
         */
        this.tmpEdgeProps = makeVar({
            fixAngle: false,
            angle: 0,
            fixLength: false,
            length: 5,
        });

        /**
         * @type {TmpEdgeVar}
         */
        this.tmpEdgeVar = makeVar(new Map());

        /**
         * @type {GraphVar}
         */
        this.tmpRectGraphVar = makeVar(new Map());
        /**
         * @type {TypesVar}
         */
        this.tmpRectTypesVar = makeVar(new Map());

        /**
         * Variable for placing wall availability
         *
         * @type {Function}
         * @param {Boolean}
         * @returns {Boolean}
         */
        this.isWallCreationAvailableVar = makeVar(true);

        /**
         * Variable for placing embedded objects
         *
         * @type {Function}
         * @param {Point[]}
         * @returns {Point[]}
         */
        this.tmpEmbeddedObjectEdgeVar = makeVar([]);

        /**
         * Variable for current wall type
         *
         * @type {CurrentWallSegmentTypeVar}
         */
        this.currentWallSegmentTypeVar = makeVar(null);

        /**
         * @type {Point}
         */
        this.edgePrevPos = { x: null, y: null };
        /**
         * @type {Point}
         */
        this.dragStartPos = { x: null, y: null };

        /**
         * @type {String}
         */
        this.currentWallIdForEmbeddingWallSegment = null;

        /**
         * @type {Map<String, Point>} <edgeId, Point>
         */
        this.notToFindIntersectionForEdge = new Map();
    }

    /**
     *
     * @param {{graph: Map<key, GraphPointValue>, types: Map<key, WallTypeValue>}} wall
     */
    initialize(wall) {
        this.clear();
        wall.graph.forEach(({ key, value }) => {
            this.addVertexToGraph(key, {
                coordinates: {
                    x: value.point.coordinate.x,
                    y: value.point.coordinate.y,
                },
                adjacentPoints: [...value.adjacentPoints],
                accessRule: getRuleForCurrentUser(value.point.accessRules),
            });
        });
        wall.types.forEach(({ _, value }) => {
            const wallSegment = {
                ...value,
                isFetched: true,
            };

            delete wallSegment.accessRules;
            wallSegment.accessRule = getRuleForCurrentUser(value.accessRules);

            this.addSegment(
                { key: value.startPoint.id },
                { key: value.endPoint.id },
                wallSegment,
                true
            );
        });
    }

    /**
     * @param {String} key edge key [pointId1, pointId2]
     * @returns {Number}
     */
    getEdgeLength(key) {
        return getDistanceBetweenPoints(...this.getLineStringOfEdge(key));
    }

    /**
     * Method to get array of coordinates [x,y]
     * @param {String} key uuid key of the vertex
     * @returns {Number[] | undefined} [x1,y1]
     */
    getFlatCoordinatesOfPoint(key) {
        return this.segmentsModerator
            .getPointLinkWrapper(key)
            ?.getFlatCoordinates();
    }

    /**
     * Method to get array of coordinates [x1,y1,x2,y2...]
     * @param {Array<String>} keys uuid keys of vertices
     * @returns {Number[]} [x1,y1,x2,y2...]
     */
    getArrayOfPointsFlatCoordinates(keys) {
        if (!keys || !keys.length) return null;
        return keys.reduce((arrayOfPoints, currentKey) => {
            arrayOfPoints.push(...this.getFlatCoordinatesOfPoint(currentKey));
            return arrayOfPoints;
        }, []);
    }

    /**
     * Method to get flat array of coordinates of an edge
     * @param {String} key uuid key of the edge [pointId1, pointId2]
     * @returns {Number[]} [x1,y1,x2,y2...]
     */
    getFlatCoordinatesOfEdge(key) {
        const [vertexId1, vertexId2] = LinkedSegment.splitKey(key);
        const segmentLink = this.segmentsModerator.getSegment(
            vertexId1,
            vertexId2
        );

        return segmentLink
            ? [
                  ...segmentLink.firstPointLinkWrapper?.getFlatCoordinates(),
                  ...segmentLink.secondPointLinkWrapper?.getFlatCoordinates(),
              ]
            : [];
    }

    /**
     * Function to form array of points (lineString) for old functions
     * @param {String} key uuid key of the edge
     * @returns {Point[]}
     */
    getLineStringOfEdge(key) {
        const [vertexId1, vertexId2] = LinkedSegment.splitKey(key);

        return [
            this.segmentsModerator
                .getPointLinkWrapper(vertexId1)
                .getCoordinates(),
            this.segmentsModerator
                .getPointLinkWrapper(vertexId2)
                .getCoordinates(),
        ];
    }

    /**
     * Method for getting vertex object
     * @param {String} vertexId
     * @returns {GraphPointValue | undefined} vertex value
     */
    getVertex(vertexId) {
        return deepCopy(
            this.segmentsModerator.getPointLinkWrapper(vertexId)?.getInfo()
        );
    }

    /**
     * Method for adding vertex to graph storage
     * @param {String} key of the vertex
     * @param {GraphPointValue} value
     * @returns {PointLinkWrapper}
     */
    addVertexToGraph(key, value, isFetched = false, isVisible = true) {
        return this.segmentsModerator.createPointLinkWrapper(
            key,
            value,
            isFetched,
            isVisible
        );
    }

    /**
     * Method to check adjacent vertices or not
     * @param {String} vertexId1 uuid
     * @param {String} vertexId2 uuid
     * @returns {Boolean}
     */
    areVerticesAdjacent(vertexId1, vertexId2) {
        return this.getVertex(vertexId1).adjacentPoints.includes(vertexId2);
    }

    rerenderPoints() {
        this.drawPointsVar([...this.segmentsModerator.getAllPointsLinks()]);
    }

    rerenderSegments() {
        this.drawSegmentsVar([
            ...this.segmentsModerator.getAllLinkedSegments(),
        ]);
    }

    /**
     * Method for rerender both points and segments(need to redraw)
     */
    rerender() {
        this.rerenderPoints();
        this.rerenderSegments();
    }

    /**
     * Method for adding vertexId to adjacent of vertex with id destinationVertexId
     * @param {String} vertexId
     * @param {String} destinationVertexId
     * @returns {Boolean} true if destinationVertexId exists and vertexId added to adjacent, false otherwise
     */
    addVertexToVertexAdjacent(vertexId, destinationVertexId) {
        if (
            !this.segmentsModerator.getPointLinkWrapper(vertexId) ||
            !this.segmentsModerator.getPointLinkWrapper(destinationVertexId)
        ) {
            return false;
        }

        this.segmentsModerator
            .getPointLinkWrapper(destinationVertexId)
            .getAdjacentPoints()
            .push(vertexId);

        return true;
    }

    /**
     * Method for adding wall segment and according to it points to graph
     * @param {{key: String, value: GraphPointValue}} startVertex
     * @param {{key: String, value: GraphPointValue}} endVertex
     * @param {{id: String, isFetched: Boolean, accessRule: string, type: WallSegmentType}} value
     * @param {Boolean} [isFetched=false] isFetched
     * @returns {LinkedSegment} added segment
     */
    addSegment(startVertex, endVertex, value, isFetched = false) {
        return this.segmentsModerator.createSegment(
            { id: startVertex.key, pointInfo: startVertex.value },
            { id: endVertex.key, pointInfo: endVertex.value },
            value,
            isFetched
        );
    }

    /**
     * Method for changing type of the edge
     * @param {String} key of the wall segment
     * @param {WallTypeValue} type type of the edge
     */
    changeSegmentType(key, type) {
        const [pointId1, pointId2] = LinkedSegment.splitKey(key);
        return this.segmentsModerator.updateSegmentType(
            pointId1,
            pointId2,
            type
        );
    }

    /***************************EDGE CREATION******************************/

    /**
     * Method for creating embedded object segments
     * @param {{id:String, type: WallSegmentType, isFetched: Boolean}} segmentInfo
     * @returns {[WallSegmentDraft[], VirtualPoint[]]}
     */
    async createEmbeddedSegment(segmentInfo) {
        if (this.tmpEmbeddedObjectEdgeVar().length !== 2) {
            return;
        }

        const loadFloor =
            require('../../../pages/Constructor/model/LoadFloor').loadFloor;

        const [point1, point2] = this.tmpEmbeddedObjectEdgeVar();
        const [donorWallPointId1, donorWallPointId2] = LinkedSegment.splitKey(
            this.currentWallIdForEmbeddingWallSegment
        );

        const donorSegmentVersion = this.segmentsModerator
            .getSegment(donorWallPointId1, donorWallPointId2)
            .getVersion();

        const [segments, points, insertedWallSegment] = this.insertWallSegment(
            point1,
            point2,
            this.currentWallIdForEmbeddingWallSegment,
            segmentInfo
        );

        this.tmpEmbeddedObjectEdgeVar([]);

        wallSegmentsAPI
            .insert(
                {
                    points: {
                        firstPointId: donorWallPointId1,
                        secondPointId: donorWallPointId2,
                    },
                    version: donorSegmentVersion,
                },
                {
                    endPoint: {
                        coordinate:
                            insertedWallSegment.secondPointLinkWrapper.getCoordinates(),
                    },
                    startPoint: {
                        coordinate:
                            insertedWallSegment.firstPointLinkWrapper.getCoordinates(),
                    },
                    typeId: segmentInfo.type.id,
                }
            )
            .then((response) => {
                if (response) {
                    const [walls, resZones] = response;
                    this.replaceAfterFetch(
                        [
                            insertedWallSegment.firstPointLinkWrapper,
                            insertedWallSegment.secondPointLinkWrapper,
                        ],
                        walls
                    );
                    resZones.forEach((zone) => {
                        zones.updateVersion(zone.id, zone.version);
                    });
                }
            })
            .catch(async (error) => {
                notifications.setError(
                    'Не удалось вставить сегмент',
                    error.message
                );
                currentToolVar(Tools.none);
                this.cancelCreating();
                await loadFloor(currentFloorVar().id);
            })
            .finally(() => {
                this.rerender();
            });

        return [segments, points];
    }

    /**
     * Method that cut segment from existing, change cut segment type on type that we need to insert
     * and return segments and points formed for mutation
     * @param {Point} point1
     * @param {Point} point2
     * @param {String} donorSegmentKey
     * @param {{id:String, type: WallSegmentType, isFetched: Boolean}} segmentInfo
     * @returns {[WallSegmentDraft[], VirtualPoint[]]}
     */
    insertWallSegment(point1, point2, donorSegmentKey, segmentInfo) {
        const insertSegment = this.segmentsModerator.cutSegment(
            point1,
            point2,
            donorSegmentKey
        );

        const [pointId1, pointId2] = LinkedSegment.splitKey(donorSegmentKey);

        this.segmentsModerator.updateSegmentInfo(
            insertSegment.firstPointLinkWrapper.getId(),
            insertSegment.secondPointLinkWrapper.getId(),
            {
                ...segmentInfo,
            }
        );

        const segments = [];
        const points = [];

        let pointsLinks = [
            ...new Set([
                this.segmentsModerator.getPointLinkWrapper(pointId1),
                insertSegment.firstPointLinkWrapper,
                insertSegment.secondPointLinkWrapper,
                this.segmentsModerator.getPointLinkWrapper(pointId2),
            ]),
        ];

        if (
            isLineVertical([
                insertSegment.firstPointLinkWrapper.getCoordinates(),
                insertSegment.secondPointLinkWrapper.getCoordinates(),
            ])
        ) {
            pointsLinks = sortVertexIdsByY(
                pointsLinks,
                isPointLowerThanAnother(
                    insertSegment.firstPointLinkWrapper.getCoordinates(),
                    insertSegment.secondPointLinkWrapper.getCoordinates()
                )
            );
        } else {
            pointsLinks = sortVertexIdsByX(
                pointsLinks,
                isPointToTheLeftFromAnother(
                    insertSegment.firstPointLinkWrapper.getCoordinates(),
                    insertSegment.secondPointLinkWrapper.getCoordinates()
                )
            );
        }

        for (let i = 0; i < pointsLinks.length - 1; i++) {
            const [iSegments, iPoints] = this.formatVerticesToSegmentsAndPoints(
                {
                    key: pointsLinks[i].getId(),
                    value: pointsLinks[i].getInfo(),
                },
                {
                    key: pointsLinks[i + 1].getId(),
                    value: pointsLinks[i + 1].getInfo(),
                },
                false
            );
            segments.push(...iSegments);
            points.push(...iPoints);
        }

        return [segments, points, insertSegment];
    }

    /**
     * @param {{key:String, value: Point}} firstClickPoint
     * @param {{key:String, value: Point}} secondClickPoint
     * @param {{
     *  isIntersected: Boolean,
     *  intersectionPointLinks: [PointLinkWrapper],
     *  createdSegments: [LinkedSegment]
     *  updatedSegments: [LinkedSegment]
     * }} intersections
     */
    addSegmentsBetweenIntersectsInDirOfCreation(
        firstClickPoint,
        secondClickPoint,
        intersections
    ) {
        let isClickPoint1OnWall = false;
        let isClickPoint2OnWall = false;
        const intersectionsPointLinksCopy = [
            ...intersections.intersectionPointLinks,
        ];

        intersectionsPointLinksCopy.forEach((intersectPointLink) => {
            if (
                arePointsEqual(
                    firstClickPoint.value.coordinates,
                    intersectPointLink.getCoordinates()
                )
            ) {
                isClickPoint1OnWall = true;
            }
            if (
                arePointsEqual(
                    secondClickPoint.value.coordinates,
                    intersectPointLink.getCoordinates()
                )
            ) {
                isClickPoint2OnWall = true;
            }
        });

        if (!isClickPoint1OnWall) {
            let newPoint = this.addVertexToGraph(
                firstClickPoint.key,
                firstClickPoint.value
            );
            if (!newPoint) {
                newPoint = this.segmentsModerator.getPointLinkWrapper(
                    firstClickPoint.key
                );
            }
            if (newPoint) {
                intersectionsPointLinksCopy.unshift(newPoint);
            }
        }
        if (!isClickPoint2OnWall) {
            let newPoint = this.addVertexToGraph(
                secondClickPoint.key,
                secondClickPoint.value
            );
            if (!newPoint) {
                newPoint = this.segmentsModerator.getPointLinkWrapper(
                    secondClickPoint.key
                );
            }
            if (newPoint) {
                intersectionsPointLinksCopy.push(newPoint);
            }
        }

        for (let i = 0; i < intersectionsPointLinksCopy.length - 1; i++) {
            const [iStartVertex, iEndVertex] =
                this.formAdjacentPointsForPairOfPoints([
                    [
                        intersectionsPointLinksCopy[i].getId(),
                        intersectionsPointLinksCopy[i].getInfo(),
                    ],
                    [
                        intersectionsPointLinksCopy[i + 1].getId(),
                        intersectionsPointLinksCopy[i + 1].getInfo(),
                    ],
                ]);

            this.addSegment(iStartVertex, iEndVertex, {
                type: this.currentWallSegmentTypeVar(),
                isFetched: false,
                accessRule: RULE_EDIT,
            });
        }
    }

    /**
     * Method for adding vertexes to tmp structure for showing tmp wall while edge creation
     * and creating segment when two vertexes added
     * @param {String} key of the vertex
     * @param {GraphPointValue} value of the vertex
     */
    async addPointToTmpEdge(key, value) {
        this.tmpEdgeVar(new Map(this.tmpEdgeVar().set(key, value)));

        if (this.tmpEdgeVar().size === 2) {
            const tmpEdgeVarArr = Array.from(deepCopy(this.tmpEdgeVar()));

            const firstPoint = {
                key: tmpEdgeVarArr[0][0],
                value: tmpEdgeVarArr[0][1],
            };

            const secondPoint = {
                key: tmpEdgeVarArr[1][0],
                value: tmpEdgeVarArr[1][1],
            };

            /**
             * @type {WallSegmentDraft[]}
             */
            let segments = [];
            /**
             * @type {VirtualPoint[]}
             */
            let points = [];

            const [startVertex, endVertex] =
                this.formAdjacentPointsForPairOfPoints(tmpEdgeVarArr);

            [segments, points] = this.formatVerticesToSegmentsAndPoints(
                startVertex,
                endVertex,
                Object.getPrototypeOf(this) === FloorGraph.prototype
            );

            const intersections = this.divideOnIntersectionsAndSort(
                firstPoint.value.coordinates,
                secondPoint.value.coordinates
            );

            if (intersections.isIntersected) {
                this.addSegmentsBetweenIntersectsInDirOfCreation(
                    firstPoint,
                    secondPoint,
                    intersections
                );
            } else {
                // Add wall to local storage for immediate showing in canvas
                this.addSegment(startVertex, endVertex, {
                    type: this.currentWallSegmentTypeVar(),
                    isFetched: false,
                    accessRule: RULE_EDIT,
                });

                [segments, points] = this.formatVerticesToSegmentsAndPoints(
                    startVertex,
                    endVertex,
                    Object.getPrototypeOf(this) === FloorGraph.prototype
                );
            }
            this.notToFindIntersectionForEdge.clear();

            this.tmpEdgeVar().clear();

            // this method called to continue creating edges from last point
            this.addPointToTmpEdge(secondPoint.key, secondPoint.value);

            this.rerender();

            this.createSegmentsAndUpdateOld(
                segments,
                points,
                intersections.isIntersected,
                intersections.intersectionPointLinks
            );

            // update embedded object var
            if (currentToolVar() === Tools.embeddedObject) {
                this.tmpEmbeddedObjectEdgeVar([
                    { ...secondPoint.value.coordinates },
                ]);
            }
        }
    }

    /**
     * Method for replacing local walls with fetched from back actual walls
     * @param {PointLinkWrapper[]} splitPointLinks
     * @param {WallSegment[]} fetchedSegments
     */
    replaceAfterFetch(
        splitPointLinks,
        fetchedSegments,
        pointsTranslateMap = null
    ) {
        splitPointLinks.forEach((point) => {
            this.segmentsModerator.deletePoint(point.getId());
        });
        fetchedSegments.forEach((segment) => {
            const startPointInfo = this.getVertex(segment.startPoint.id);
            const startPointAdjacentPoints = [];

            const endPointInfo = this.getVertex(segment.endPoint.id);
            const endPointAdjacentPoints = [];

            if (startPointInfo?.adjacentPoints) {
                startPointAdjacentPoints.push(
                    ...new Set(startPointInfo.adjacentPoints).add(
                        segment.endPoint.id
                    )
                );
                this.segmentsModerator.updatePointInfo(segment.startPoint.id, {
                    ...startPointInfo,
                    adjacentPoints: startPointAdjacentPoints,
                });
            } else {
                startPointAdjacentPoints.push(segment.endPoint.id);
            }

            if (endPointInfo?.adjacentPoints) {
                endPointAdjacentPoints.push(
                    ...new Set(endPointInfo.adjacentPoints).add(
                        segment.startPoint.id
                    )
                );
                this.segmentsModerator.updatePointInfo(segment.endPoint.id, {
                    ...endPointInfo,
                    adjacentPoints: endPointAdjacentPoints,
                });
            } else {
                endPointAdjacentPoints.push(segment.startPoint.id);
            }

            this.addSegment(
                {
                    key: segment.startPoint.id,
                    value: {
                        coordinates: {
                            x: segment.startPoint.coordinate.x,
                            y: segment.startPoint.coordinate.y,
                        },
                        adjacentPoints: startPointAdjacentPoints,
                        accessRule: getRuleForCurrentUser(
                            segment.startPoint.accessRules
                        ),
                    },
                },
                {
                    key: segment.endPoint.id,
                    value: {
                        coordinates: {
                            x: segment.endPoint.coordinate.x,
                            y: segment.endPoint.coordinate.y,
                        },
                        adjacentPoints: endPointAdjacentPoints,
                        accessRule: getRuleForCurrentUser(
                            segment.startPoint.accessRules
                        ),
                    },
                },
                {
                    id: segment.id,
                    isFetched: true,
                    type: segment.type,
                    name: segment.name,
                    version: segment.version,
                    accessRule: getRuleForCurrentUser(segment.accessRules),
                },
                true
            );
        });

        // check if after fetch there is point in tmpEdgeVar() while creating next walls
        // it should be replaced with new id of point from fetch data for consistent
        const pointIdInTmpEdge = this.tmpEdgeVar().keys().next().value;
        if (pointsTranslateMap && pointIdInTmpEdge) {
            for (const [id, newId] of pointsTranslateMap.entries()) {
                if (id === pointIdInTmpEdge) {
                    this.tmpEdgeVar().delete(id);
                    this.tmpEdgeVar().set(newId, this.getVertex(newId));
                }
            }
        }
    }

    /**
     * Method calls wall creation and replace old vertices with new
     * @param {WallSegmentDraft[]} segments
     * @param {VirtualPoint[]} points
     * @param {Boolean} cutOnIntersect
     * @param {PointLinkWrapper} splitPointLinks
     */
    createSegmentsAndUpdateOld(
        segments,
        points,
        cutOnIntersect,
        splitPointLinks
    ) {
        // TODO: Get rid of require as soon as possible
        const loadFloor =
            require('../../../pages/Constructor/model/LoadFloor').loadFloor;
        // Send query for saving segments
        return wallSegmentsAPI
            .create(segments, points, cutOnIntersect)
            .then((response) => {
                const [walls, pointsTranslateMap, updatedZones] = response;
                if (cutOnIntersect) {
                    this.replaceAfterFetch(
                        splitPointLinks,
                        walls,
                        pointsTranslateMap
                    );
                    zones.updateZoneBorder(updatedZones);
                } else {
                    this.updateAfterFetch(segments, walls, pointsTranslateMap);
                }
            })
            .catch(async (error) => {
                notifications.setError('Стены не созданы', error.message);
                rightBarModerator.selectedObject(null);
                currentToolVar(Tools.none);
                this.cancelCreating();
                this.finishCreatingRect();
                await loadFloor(currentFloorVar().id);
            })
            .finally(() => {
                this.rerender();
                zones.rerender();
            });
    }

    /**
     * @param {String} oldVertexId
     * @param {Map<String,GraphPointValue>} newVertex
     * @param {Map<String,String>} pointsTranslateMap
     */
    updatePointsIds(oldVertexId, newVertex, pointsTranslateMap) {
        const newAdjacentPoints = newVertex.value.adjacentPoints.map(
            (pointId) => {
                return pointsTranslateMap.get(pointId) ?? pointId;
            }
        );

        newVertex.value.adjacentPoints = newAdjacentPoints;
        if (this.tmpEdgeVar().keys().next().value === oldVertexId) {
            this.tmpEdgeVar().delete(oldVertexId);
            this.tmpEdgeVar().set(newVertex.key, newVertex.value);
        }
        this.segmentsModerator.updatePointId(oldVertexId, newVertex.key, true);
        this.segmentsModerator.updatePointInfo(newVertex.key, newVertex.value);
    }

    /**
     * @param {WallSegmentDraft[]} oldWallSegments
     * @param {WallSegment[]} newWallSegments
     */
    updateAfterFetch(oldWallSegments, newWallSegments, pointsTranslateMap) {
        const getAdjacentPoints = (oldPointId) => {
            return [
                ...(this.segmentsModerator
                    .getPointLinkWrapper(oldPointId)
                    ?.getAdjacentPoints() ??
                    this.segmentsModerator
                        .getPointLinkWrapper(pointsTranslateMap.get(oldPointId))
                        ?.getAdjacentPoints()),
            ];
        };
        for (let i = 0; i < oldWallSegments.length; i++) {
            const newStartPoint = {
                key: newWallSegments[i].startPoint.id,
                value: {
                    coordinates: {
                        x: newWallSegments[i].startPoint.coordinate.x,
                        y: newWallSegments[i].startPoint.coordinate.y,
                    },
                    adjacentPoints: getAdjacentPoints(
                        oldWallSegments[i].startPointId
                    ),
                    accessRule: getRuleForCurrentUser(
                        newWallSegments[i].startPoint.accessRules
                    ),
                },
            };

            const newEndPoint = {
                key: newWallSegments[i].endPoint.id,
                value: {
                    coordinates: {
                        x: newWallSegments[i].endPoint.coordinate.x,
                        y: newWallSegments[i].endPoint.coordinate.y,
                    },
                    adjacentPoints: getAdjacentPoints(
                        oldWallSegments[i].endPointId
                    ),
                    accessRule: getRuleForCurrentUser(
                        newWallSegments[i].endPoint.accessRules
                    ),
                },
            };

            this.updatePointsIds(
                oldWallSegments[i].startPointId,
                newStartPoint,
                pointsTranslateMap
            );
            this.updatePointsIds(
                oldWallSegments[i].endPointId,
                newEndPoint,
                pointsTranslateMap
            );

            const newSegmentInfo = wallSegmentsAPI.adaptSegment(
                newWallSegments[i]
            );

            this.segmentsModerator.updateSegmentInfo(
                newStartPoint.key,
                newEndPoint.key,
                newSegmentInfo
            );
        }
    }

    /********************************************************************************************************** */

    /**
     * Method formatting GraphPoints to segments,points
     * @param {{key: String, value: GraphPointValue}} startVertex
     * @param {{key: String, value: GraphPointValue}} endVertex
     * @returns {[WallSegmentDraft[], VirtualPoint[]]}
     */
    formatVerticesToSegmentsAndPoints(
        startVertex,
        endVertex,
        isWallSegmentsCreation = true
    ) {
        const segments = [
            {
                startPointId: startVertex.key,
                endPointId: endVertex.key,
                typeId: isWallSegmentsCreation
                    ? this.currentWallSegmentTypeVar()?.id
                    : this.segmentsModerator
                          .getSegment(startVertex.key, endVertex.key)
                          ?.getType()?.id,
            },
        ];

        const points = [
            {
                coordinate: {
                    x: startVertex.value.coordinates.x,
                    y: startVertex.value.coordinates.y,
                },
                id: startVertex.key,
                accessRule: startVertex.value.accessRule,
            },
            {
                coordinate: {
                    x: endVertex.value.coordinates.x,
                    y: endVertex.value.coordinates.y,
                },
                id: endVertex.key,
                accessRule: startVertex.value.accessRule,
            },
        ];
        return [segments, points];
    }

    /**
     * Method for setting adjacent points for pair of points that should be connected
     * @param {[[string, GraphPointValue],[string, GraphPointValue]]} pointsPair
     * @returns {[{string, GraphPointValue}, {string, GraphPointValue}]}
     */
    formAdjacentPointsForPairOfPoints(pointsPair) {
        const [pointId1, pointValue1] = pointsPair[0];
        const [pointId2, pointValue2] = pointsPair[1];

        const startVertexAdjacentPoints = [
            ...new Set([...pointValue1.adjacentPoints, pointId2]),
        ];
        const endVertexAdjacentPoints = [
            ...new Set([...pointValue2.adjacentPoints, pointId1]),
        ];

        const startVertex = {
            key: pointsPair[0][0],
            value: {
                ...pointValue1,
                adjacentPoints: startVertexAdjacentPoints,
            },
        };
        const endVertex = {
            key: pointsPair[1][0],
            value: {
                ...pointValue2,
                adjacentPoints: endVertexAdjacentPoints,
            },
        };

        return [startVertex, endVertex];
    }

    /**
     * @param {{
     *  isIntersected: Boolean,
     *  intersectionPointLinks: [PointLinkWrapper],
     *  createdSegments: [LinkedSegment]
     *  updatedSegments: [LinkedSegment]
     * }} intersections
     * @returns {[WallSegmentDraft[], VirtualPoint[]]}
     */
    formAllSegmentsAndPointsAfterIntersection(intersections) {
        let segments = [];
        let points = [];

        // iterating pairs of points
        for (
            let i = 0;
            i < intersections.intersectionPointLinks.length - 1;
            i++
        ) {
            const [iStartVertex, iEndVertex] =
                this.formAdjacentPointsForPairOfPoints([
                    [
                        intersections.intersectionPointLinks[i].getId(),
                        intersections.intersectionPointLinks[i].getInfo(),
                    ],
                    [
                        intersections.intersectionPointLinks[i + 1].getId(),
                        intersections.intersectionPointLinks[i + 1].getInfo(),
                    ],
                ]);

            this.addSegment(
                iStartVertex,
                iEndVertex,
                {
                    type: this.currentWallSegmentTypeVar(),
                    isFetched: false,
                    accessRule: RULE_EDIT,
                },
                false
            );

            const [iSegments, iPoints] = this.formatVerticesToSegmentsAndPoints(
                iStartVertex,
                iEndVertex
            );

            segments.push(...iSegments);
            points.push(...iPoints);
        }

        intersections.createdSegments.forEach((segmentLink) => {
            const [iStartVertex, iEndVertex] =
                this.formAdjacentPointsForPairOfPoints([
                    [
                        segmentLink.firstPointLinkWrapper.getId(),
                        segmentLink.firstPointLinkWrapper.getInfo(),
                    ],
                    [
                        segmentLink.secondPointLinkWrapper.getId(),
                        segmentLink.secondPointLinkWrapper.getInfo(),
                    ],
                ]);

            const [iSegments, iPoints] = this.formatVerticesToSegmentsAndPoints(
                iStartVertex,
                iEndVertex
            );

            segments.push(...iSegments);
            points.push(...iPoints);
        });

        return [segments, points];
    }

    /**
     * Method for clearing all stores working with walls
     */
    clear() {
        this.tmpEdgeVar(new Map());
        this.tmpRectGraphVar(new Map());
        this.tmpRectTypesVar(new Map());

        this.segmentsModerator.clearAll();
        this.drawPointsVar([]);
        this.drawSegmentsVar([]);
    }

    /**
     * Method for clearing tmpEdgeVar that is currently creating
     */
    cancelCreating() {
        this.tmpEdgeVar(new Map());
        this.tmpEmbeddedObjectEdgeVar([]);
    }

    finishCreatingRect() {
        this.tmpRectGraphVar(new Map());
        this.tmpRectTypesVar(new Map());
    }

    /***********************RECT***************************/

    /**
     * Methods for creating tmp rect wall while mouse button is bottom
     * @param {Point} firstClickPoint point object
     * @param {Point} secondClickPoint point object
     * @param {String} type
     */
    createTmpRectWall(firstClickPoint, secondClickPoint, type) {
        this.tmpRectGraphVar().clear();
        this.tmpRectTypesVar().clear();

        let points;
        if (type === 'Rect') {
            points = WallLogic.createRectangleWall(
                firstClickPoint,
                secondClickPoint
            );
        } else {
            points = WallLogic.createSquareWall(
                firstClickPoint,
                secondClickPoint
            );
        }

        const pointsIds = points.map(() => uuid());
        const tmpRectGraphCopy = new Map();
        const tmpRectTypesCopy = new CustomMap();

        points.forEach((point, index) => {
            const adjacentPoints = [
                pointsIds[index === 0 ? pointsIds.length - 1 : index - 1],
                pointsIds[index === pointsIds.length - 1 ? 0 : index + 1],
            ];

            /**
             * add vertices and connections(adjacentPoints) to graph copy
             */
            tmpRectGraphCopy.set(pointsIds[index], {
                coordinates: point,
                adjacentPoints: adjacentPoints,
                accessRule: RULE_EDIT,
            });

            /**
             * add edges to types copy
             */
            const edgeKey = [
                pointsIds[index],
                pointsIds[index < pointsIds.length - 1 ? index + 1 : 0],
            ];

            tmpRectTypesCopy.set(edgeKey, {
                type: this.currentWallSegmentTypeVar(),
                isFetched: false,
            });
        });

        this.tmpRectGraphVar(tmpRectGraphCopy);
        this.tmpRectTypesVar(tmpRectTypesCopy);
    }

    /**
     * Method for adding rectangle wall to store
     * @returns {{segments: WallSegmentDraft[], points: VirtualPoint[], intersectionPointsLinks: PointLinkWrapper[]}} newLinkedSegments
     */
    createRectWall() {
        const intersectionPointsLinks = [];
        Array.from(this.tmpRectTypesVar()).forEach(([key, value]) => {
            const [pointId1, pointId2] = LinkedSegment.splitKey(key);

            const intersections = this.divideOnIntersectionsAndSort(
                this.tmpRectGraphVar().get(pointId1).coordinates,
                this.tmpRectGraphVar().get(pointId2).coordinates
            );

            if (intersections.isIntersected) {
                intersectionPointsLinks.push(
                    ...intersections.intersectionPointLinks
                );
            }
        });

        this.rerender();

        const segments = [];
        const points = [];

        Array.from(this.tmpRectTypesVar()).forEach(([key, _]) => {
            const [pointId1, pointId2] = LinkedSegment.splitKey(key);

            const [iSegments, iPoints] = this.formatVerticesToSegmentsAndPoints(
                { key: pointId1, value: this.tmpRectGraphVar().get(pointId1) },
                { key: pointId2, value: this.tmpRectGraphVar().get(pointId2) },
                Object.getPrototypeOf(this) === FloorGraph.prototype
            );

            segments.push(...iSegments);
            points.push(...iPoints);
        });

        return { segments, points, intersectionPointsLinks };
    }

    /***********************UPDATE***************************/

    /**
     * Handler to update vertex position on drag
     * @param {String} pointId vertex key
     * @param {Point} newPoint point
     */
    updateVertex(pointId, newPoint) {
        const value = this.getVertex(pointId);

        // TODO: restore dragging of corners of non-scalable segments
        // const fixedAdjacentIds = this.findFixedEdgesByVertex(pointId, this);

        // if (fixedAdjacentIds.length > 1) {
        //     return;
        // } else if (fixedAdjacentIds.length === 1) {
        //     newPoint = expandLine(
        //         [this.getVertex(fixedAdjacentIds[0]).coordinates, newPoint],
        //         this.segmentsModerator
        //             .getSegment(pointId, fixedAdjacentIds[0])
        //             .getType().length
        //     )[1];
        // }

        this.segmentsModerator.updatePointInfo(pointId, {
            ...value,
            coordinates: { ...newPoint },
        });
    }

    /**
     * Handler to update vertices position
     * @param {{pointLink: PointLinkWrapper, newPoint: Point}[]} newPointObjArr
     */
    updateMultipleVertices(newPointObjArr) {
        newPointObjArr.forEach(({ pointLink, newPoint }) => {
            this.segmentsModerator.updatePointInfo(pointLink.getId(), {
                ...pointLink.getInfo(),
                coordinates: { ...newPoint },
            });
        });
    }

    /**
     * Method for updating edges positions on drag move
     * @param {Point} newPosition
     * @param {string[]} edgesIds edges UUIDs that need to be updated
     */
    updateWallSegments(newPosition, edgesIds) {
        const difference = subtractTwoPoints(this.edgePrevPos, newPosition);
        this.edgePrevPos = newPosition;
        this.segmentsModerator.updateSegmentsOnMoveVector(difference, edgesIds);
    }

    /***********************INTERSECTING***************************/

    /**
     * Divide walls when tmp edge intersects them
     * @param {Point} point1 first clicked point of tmp edge
     * @param {Point} point2 second clicked point
     * @returns {{intersectionPointLinks: PointLinkWrapper[], createdSegments: LinkedSegment[], updatedSegments:LinkedSegment}}
     */
    divideWallsOnIntersection = (point1, point2) => {
        const edgeKeys = this.segmentsModerator.getSegmentsKeys();

        const intersectionPointLinks = new Set();
        const createdSegments = [];
        const updatedSegments = [];

        for (const edgeKey of edgeKeys) {
            let intersection;
            if (this.notToFindIntersectionForEdge.has(edgeKey)) {
                intersection = {
                    type: LinesIntersectionType.ONE_POINT,
                    point: this.notToFindIntersectionForEdge.get(edgeKey),
                };
            } else {
                intersection = findLinesIntersection(
                    this.getLineStringOfEdge(edgeKey),
                    [point1, point2]
                );
            }

            if (intersection.type === LinesIntersectionType.ONE_POINT) {
                const verticesIds = LinkedSegment.splitKey(edgeKey);

                const vertices = [
                    this.getVertex(verticesIds[0]),
                    this.getVertex(verticesIds[1]),
                ];
                const isLineEnd = isPointOnLineEnd(
                    intersection.point,
                    vertices.map((vertex) => vertex.coordinates)
                );

                if (isLineEnd.result) {
                    continue;
                }

                const {
                    newPoint,
                    createdSegments: createdSegmentsAfterDivision,
                    updatedSegments: updatedSegmentsAfterDivision,
                } = this.segmentsModerator.divideSegment(
                    intersection.point,
                    edgeKey
                );

                intersectionPointLinks.add(newPoint);
                createdSegments.push(...createdSegmentsAfterDivision);
                updatedSegments.push(...updatedSegmentsAfterDivision);
            }
        }

        return {
            intersectionPointLinks: [...intersectionPointLinks],
            createdSegments,
            updatedSegments,
        };
    };

    /**
     * Method that finds intersections of edges with line with corners point1, point2
     * @param {Point} startPoint
     * @param {Point} endPoint
     * @returns {{
     *  isIntersected: Boolean,
     *  intersectionPointLinks: [PointLinkWrapper],
     *  createdSegments: [LinkedSegment]
     *  updatedSegments: [LinkedSegment]
     * }}
     */
    divideOnIntersectionsAndSort(startPoint, endPoint) {
        let { intersectionPointLinks, createdSegments, updatedSegments } =
            this.divideWallsOnIntersection(startPoint, endPoint);

        const isIntersected =
            intersectionPointLinks.length > 0 && updatedSegments.length > 0;

        if (isIntersected) {
            if (isLineVertical([startPoint, endPoint])) {
                intersectionPointLinks = sortVertexIdsByY(
                    intersectionPointLinks,
                    isPointLowerThanAnother(startPoint, endPoint)
                );
            } else {
                intersectionPointLinks = sortVertexIdsByX(
                    intersectionPointLinks,
                    isPointToTheLeftFromAnother(startPoint, endPoint)
                );
            }
        }
        return {
            isIntersected,
            intersectionPointLinks,
            createdSegments,
            updatedSegments,
        };
    }

    /**
     * Method finds all fixed edges one of the ends of which is a given vertex
     * @param {String} vertexId vertex uuid
     * @returns {String[]} array of id of adjacent of given vertex, where [vertexId, adjacentId] is a fixed edge
     */
    findFixedEdgesByVertex = (vertexId) => {
        const adjacentIds = this.getVertex(vertexId)?.adjacentPoints;
        const fixedAdjacentIds = [];

        if (adjacentIds) {
            for (const adjacentId of adjacentIds) {
                let scalable = this.segmentsModerator
                    .getSegment(vertexId, adjacentId)
                    .getInfo().type?.scalable;
                if (scalable === undefined) {
                    // FIXME
                    // temporary decision for route points, which are not synchronized with server
                    scalable = true;
                }
                if (!scalable) {
                    fixedAdjacentIds.push(adjacentId);
                }
            }
        }

        return fixedAdjacentIds;
    };

    /**
     * Method for getting shape of segment to draw in canvas
     * @param {LinkedSegment} segment
     * @returns {Number[]} flat array of points
     */
    formDrawableSegmentPolygon(segment) {
        return this.segmentsModerator
            .getShape(segment)
            ?.map((point) => {
                return [...Object.values(point)];
            })
            ?.flat();
    }

    async removeSegments(segments) {
        const loadFloor =
            require('../../../pages/Constructor/model/LoadFloor').loadFloor;

        const toDeleteSegmentsArr = [];
        segments.forEach((segment) => {
            const id1 = segment.firstPointLinkWrapper.getId();
            const id2 = segment.secondPointLinkWrapper.getId();
            this.segmentsModerator.deleteSegment(id1, id2);
            toDeleteSegmentsArr.push(
                wallSegmentsAPI.delete({
                    points: {
                        firstPointId: id1,
                        secondPointId: id2,
                    },
                    version: segment.getVersion(),
                })
            );
        });

        try {
            await Promise.all(toDeleteSegmentsArr).then((res) => {
                return res;
            });
        } catch (error) {
            notifications.setError('Ошибка удаления сегментов', error.message);
            await loadFloor(currentFloorVar().id);
        }
    }

    /**
     * Method for merge two vertices. SrcVertex will be merged into destVertex.
     * Merging includes: deleting edge (wall) between vertices, reconnect edges (walls) and update zone.
     * After executing this function, srcVertex will be deleted
     *
     * @param {String} srcVertexId uuid of src vertex
     * @param {String} destVertexId uuid of destination vertex
     */
    async mergePoints(srcVertexId, destVertexId) {
        const loadFloor =
            require('../../../pages/Constructor/model/LoadFloor').loadFloor;

        const overlappingSegment =
            this.segmentsModerator.getOverlappingSegments(
                srcVertexId,
                destVertexId
            );

        if (overlappingSegment) {
            await this.removeSegments(overlappingSegment);
        }

        // check if there is segment between points
        // it's need to collapse it
        const segmentBetweenPoints = this.segmentsModerator.getSegment(
            srcVertexId,
            destVertexId
        );
        if (segmentBetweenPoints) {
            const segmentToCollapse = LinkedSegment.createByLinkedSegment(
                this.segmentsModerator.getSegment(srcVertexId, destVertexId)
            );
            this.segmentsModerator.collapseSegment(srcVertexId, destVertexId);

            wallSegmentsAPI
                .collapse(
                    {
                        points: {
                            firstPointId: srcVertexId,
                            secondPointId: destVertexId,
                        },
                        version: segmentToCollapse.getVersion(),
                    },
                    {
                        id: destVertexId,
                    }
                )
                .then((res) => {
                    res.walls.forEach((segment) => {
                        this.segmentsModerator.updateVersion(
                            segment.startPoint.id,
                            segment.endPoint.id,
                            segment.version
                        );
                    });
                    res.zones.forEach((zone) => {
                        zones.updateVersion(zone.id, zone.version);
                    });
                })
                .catch(async (error) => {
                    notifications.setError(
                        'Ошибка объединения точек',
                        error.message
                    );
                    await loadFloor(currentFloorVar().id);
                });

            return true;
        } else {
            // merge points that haven't got segment between
            const segmentsToUpdate =
                this.segmentsModerator.mergePointsOfUnconnectedSegments(
                    srcVertexId,
                    destVertexId
                );

            if (segmentsToUpdate) {
                wallSegmentsAPI
                    .mergeUnconnectedPoints(srcVertexId, destVertexId)
                    .then((segments) => {
                        segments.forEach((segment) => {
                            this.segmentsModerator.updateVersion(
                                segment.startPoint.id,
                                segment.endPoint.id,
                                segment.version
                            );
                        });
                    })
                    .catch(async (error) => {
                        notifications.setError(
                            'Ошибка объединения точек',
                            error.message
                        );
                        await loadFloor(currentFloorVar().id);
                    });
            }

            return true;
        }
    }

    /**
     *
     * @param {Point} pos
     */
    setDragStartPos(pos) {
        this.dragStartPos = pos;
    }
}

const floorGraph = new FloorGraph();
export default floorGraph;

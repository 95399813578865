import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { FormContainer } from '../../shared/ui/FormContainer';

import { Message } from './ui/Message';
import { Additional, Timer } from './ui/Styles';
import { repeatSubmitHandlerVar } from '../../shared/model/cache/Cache';
import { repeatSubmit } from '../../shared/lib/utils/auth/RepeatSubmit';

/**
 * Email Sender component, needed to resend the message to the mail
 * @param { String } message text for Message component
 * @returns
 */
export const EmailSender = ({ message }) => {
    const [time, setTime] = useState(30);

    const [attempt, setAttempt] = useState(1);

    const timerRef = useRef();
    const repeatRef = useRef();
    const supportRef = useRef();

    /**
     * timer logic
     * When time updates, hide Additional and start new timer
     */
    useEffect(() => {
        repeatRef.current.style.display = 'none';
        if (attempt === 3) supportRef.current.style.display = 'none';
        timerRef.current.style.display = 'block';

        const timer = setInterval(() => {
            setTime((time) => (time >= 1 ? time - 1 : 0));
        }, 1000);

        if (time === 0) {
            timerRef.current.style.display = 'none';
            repeatRef.current.style.display = 'block';
            if (attempt === 3) supportRef.current.style.display = 'block';
        }

        return () => clearInterval(timer);
    }, [time, attempt]);

    /**
     * repeats sending the email
     */
    const handleRepeat = () => {
        repeatSubmit(
            repeatSubmitHandlerVar().variables,
            repeatSubmitHandlerVar().query
        );

        setTime(30);
        setAttempt((attempt) => (attempt !== 3 ? attempt + 1 : attempt));
    };

    return (
        <FormContainer>
            <Message message={message} />
            <Timer ref={timerRef}>Отправить повторно через {time} секунд</Timer>
            <Additional ref={repeatRef} onClick={handleRepeat}>
                Отправить повторно
            </Additional>
            <Additional ref={supportRef}>
                Обратиться в службу поддержки
            </Additional>
        </FormContainer>
    );
};

EmailSender.propTypes = {
    message: PropTypes.string,
};

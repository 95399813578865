import { gql } from '@apollo/client';

export const ACCESS_RULE_FRAGMENT = gql`
    fragment AccessRuleFragment on AccessRule {
        group {
            id
        }
        rule
    }
`;

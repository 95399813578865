import { gql } from '@apollo/client';

/**
 * @typedef {import('../../../../features/Wall/api/WallSegmentsAPI').MoveWallItemsOperation} MoveWallItemsOperation
 * @typedef {import('../../../../features/Wall/api/WallSegmentsAPI').WallSegmentDiff} WallSegmentDiff
 */
export const UPDATE_WALL_SEGMENTS_POS = gql`
    mutation updateWallSegments($moveWallItems: MoveWallItemsOperation) {
        updateWallSegments(moveWallItems: $moveWallItems) {
            updatedSegments {
                version
                endPoint {
                    coordinate {
                        x
                        y
                    }
                    id
                    accessRules {
                        group {
                            id
                        }
                        rule
                    }
                }
                id
                lengthRestriction
                startPoint {
                    coordinate {
                        x
                        y
                    }
                    id
                    accessRules {
                        group {
                            id
                        }
                        rule
                    }
                }
                width
                through
                secondZone {
                    id
                }
                firstZone {
                    id
                }
                type {
                    id
                    length
                    width
                    scalable
                    through
                    virtual
                    imageOffsetCoefficient
                    image {
                        id
                        src
                    }
                    techImage {
                        id
                        src
                    }
                }
                accessRules {
                    group {
                        id
                    }
                    rule
                }
            }
            zones {
                id
                version
                shape {
                    outer {
                        id
                    }
                }
            }
        }
    }
`;

export const UPDATE_WALL_SEGMENTS_INFO = gql`
    mutation updateWallSegments($wallSegmentDiffs: [WallSegmentDiff]) {
        updateWallSegments(wallSegmentDiffs: $wallSegmentDiffs) {
            updatedSegments {
                name
                version
                endPoint {
                    coordinate {
                        x
                        y
                    }
                    id
                    accessRules {
                        group {
                            id
                        }
                        rule
                    }
                }
                id
                lengthRestriction
                startPoint {
                    coordinate {
                        x
                        y
                    }
                    id
                    accessRules {
                        group {
                            id
                        }
                        rule
                    }
                }
                width
                through
                secondZone {
                    id
                }
                firstZone {
                    id
                }
                schemaImpls {
                    data
                    id
                    version
                    schema {
                        id
                        schema
                        version
                    }
                }
                type {
                    id
                    length
                    width
                    scalable
                    through
                    virtual
                    imageOffsetCoefficient
                    image {
                        id
                        src
                        type
                    }
                    techImage {
                        id
                        src
                        type
                    }
                }
                accessRules {
                    group {
                        id
                    }
                    rule
                }
            }
            zones {
                id
                version
                shape {
                    outer {
                        id
                    }
                }
            }
        }
    }
`;

export const MERGE_WALL_POINTS = gql`
    mutation mergeWallPoints($basePointId: String!, $mergingPointId: String!) {
        mergeWallPoints(
            basePointId: $basePointId
            mergingPointId: $mergingPointId
        ) {
            updatedSegments {
                name
                version
                endPoint {
                    coordinate {
                        x
                        y
                    }
                    id
                    accessRules {
                        group {
                            id
                        }
                        rule
                    }
                }
                id
                lengthRestriction
                startPoint {
                    coordinate {
                        x
                        y
                    }
                    id
                    accessRules {
                        group {
                            id
                        }
                        rule
                    }
                }
                width
                through
                secondZone {
                    id
                }
                firstZone {
                    id
                }
                schemaImpls {
                    data
                    id
                    version
                    schema {
                        id
                        schema
                        version
                    }
                }
                type {
                    id
                    length
                    width
                    scalable
                    through
                    virtual
                    imageOffsetCoefficient
                    image {
                        id
                        src
                        type
                    }
                    techImage {
                        id
                        src
                        type
                    }
                }
                accessRules {
                    group {
                        id
                    }
                    rule
                }
            }
            zones {
                id
                version
                shape {
                    outer {
                        id
                    }
                }
            }
        }
    }
`;

import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import {
    HINT_COLOR,
    HINT_POSITION,
    HINT_TEXT_ALIGNMENT,
} from '../../model/Constants';
import closeIcon from '../../../../shared/assets/images/icons/close.svg';
import {
    DEVICE_TYPES,
    CURRENT_DEVICE_TYPE,
} from '../../../../shared/lib/utils/deviceInfo/deviceType';
import {
    BASE_NOTIFICATION_ANIMATION_DURATION,
    NotificationContainer,
} from './Styles/NotificationContainer';
import { CloseImg, Text, Title } from './Styles/Styles';
import { BaseNotificationProps } from './Types';

/**
 * Default notification
 * @component
 */
export const BaseNotification: React.FC<BaseNotificationProps> = ({
    title = '',
    titleColor = HINT_COLOR.INFO,
    text = '',
    textColor = HINT_COLOR.BLACK,
    backgroundColor = HINT_COLOR.WHITE,
    textAlignment = HINT_TEXT_ALIGNMENT.CENTER,
    containerPosition = HINT_POSITION.topMiddle,
    isClosable = true,
    onClose = () => {},
    startExitAnimation = false,
}: BaseNotificationProps) => {
    /** Need for open/close animation event */
    const [isEnter, setIsEnter] = useState<boolean>(true);

    /** Need to prevent warning and help CSSTransition to find node */
    const notificationRef = useRef(null);

    /** Close icon need to hide for PCs and always show for mobiles */
    const isCloseIconHidable = CURRENT_DEVICE_TYPE === DEVICE_TYPES.PC;

    /** Close handler */
    const handleClose = () => {
        setIsEnter(false); // run close animation
        setTimeout(onClose, BASE_NOTIFICATION_ANIMATION_DURATION); // call onClose handler
    };

    // effect to start exit animation
    useEffect(() => {
        if(startExitAnimation) {
            setIsEnter(false);
        }
    }, [startExitAnimation]);

    return (
        <CSSTransition
            in={isEnter}
            timeout={BASE_NOTIFICATION_ANIMATION_DURATION}
            appear={true}
            classNames='BaseNotification-anim'
            nodeRef={notificationRef}
        >
            <NotificationContainer
                textAlignment={textAlignment}
                bgColor={backgroundColor}
                position={containerPosition}
                className='BaseNotification-anim'
                ref={notificationRef}
            >
                {isClosable && (
                    <CloseImg
                        className={'close'}
                        src={closeIcon}
                        alt='×'
                        onClick={handleClose}
                        isHidable={isCloseIconHidable}
                        textAlignment={textAlignment}
                    />
                )}
                <Title titleColor={titleColor} textAlignment={textAlignment}>
                    {String(title)}
                </Title>
                <Text textColor={textColor} textAlignment={textAlignment}>
                    {String(text)}
                </Text>
            </NotificationContainer>
        </CSSTransition>
    );
};

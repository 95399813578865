import { DocumentNode, gql } from '@apollo/client';
import { ACCESS_GROUP_FRAGMENT } from '../../fragments/accessGroups/AccessGroupFragment';

export const CREATE_ACCESS_GROUP: DocumentNode = gql`
    ${ACCESS_GROUP_FRAGMENT}
    mutation createAccessGroup($accessGroupDraft: AccessGroupDraft!) {
        createAccessGroup(accessGroupDraft: $accessGroupDraft) {
            ...AccessGroupFragment
        }
    }
`;

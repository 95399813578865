import { deepCopy } from '../../../shared/lib/utils/common/DeepCopy';
import floorGraph from '../../Wall/model/FloorGraph';

/**
 * @typedef {import('../../Wall/api/WallSegmentsAPI').AccessRuleFromServer} AccessRuleFromServer
 * @typedef {import('../../Wall/model/SegmentsModerator').PointLinkWrapper} PointLinkWrapper
 */

/**
 * @typedef {{
 *  color: String!,
 *  id: String!,
 *  name: String!,
 *  global: Boolean!,
 *  isStub: Boolean!,
 *  accessRule: string!,
 *  wallSegments: [WallSegment]!
 *  parentZone: Zone
 *  mapObjects: [MapObject]!
 *  floor: Floor!
 *  wall: Wall!
 *  schemaImpls: [MapinsSchemaImpl]!
 *  borders: [PointLinkWrapper],
 *  version: Number
 * }} ZoneProps
 */

/**
 * @class
 */
class Zone {
    /**
     * @constructor
     * @param {ZoneProps} zoneProps
     */
    constructor(zoneProps) {
        /**
         * @type {ZoneProps}
         */
        this.props = { ...deepCopy(zoneProps), borders: zoneProps.borders };
    }

    copy() {
        return new Zone(this.props);
    }

    rerender() {
        this.props.borders = [...this.props.borders];
    }

    getVersion() {
        return this.props.version;
    }

    /**
     * @param {String} zoneId
     * @param {Number} newVersion
     */
    updateVersion(newVersion) {
        this.props.version = newVersion;
    }

    /**
     * Method to get TemporaryZone points ids
     * @returns {String[]} Array<String> tmpZone ids array
     */
    getArrayOfPointsIds() {
        return this.props.borders.map((pointLink) => pointLink.getId());
    }

    /**
     * Method to get TemporaryZone points ids
     * @param {PointLinkWrapper[]} pointLinks
     * @returns {String[]} Array<String> tmpZone ids array
     */
    static getArrayOfPointsIds(pointLinks) {
        return pointLinks.map((pointLink) => pointLink.getId());
    }

    /**
     * 
     * @param {PointLinkWrapper[]} graphPoints 
     */
    static sortZoneBorders(graphPoints) {
        if (graphPoints.length === 0) {
            return [];
        }
        const pointsIds = graphPoints.map((pointLink) => pointLink.getId());
        const visited = new Set(); 
        const startPointId = graphPoints[0].getId();
        Zone.sortPolygonePoints(startPointId, visited, pointsIds);
        return [...visited].map((pointId) => floorGraph.segmentsModerator.getPointLinkWrapper(pointId));
    }

    static sortPolygonePoints(startPointId, visited, pointsIds) {
        visited.add(startPointId);
        for (const neighbor of floorGraph.getVertex(startPointId).adjacentPoints) {
            if (!pointsIds.includes(neighbor)) {
                continue;
            }

            if (!visited.has(neighbor)) {
                this.sortPolygonePoints(neighbor, visited, pointsIds);
            }
        }
    } 

    /**
     * @returns {[x1,y1,x2,y2...]} array of borders coordinates flattened
     */
    getBordersCoordinatesFlat() {
        return floorGraph.getArrayOfPointsFlatCoordinates(
            this.getArrayOfPointsIds()
        );
    }

    /**
     * @param {String[]} pointsIds
     * @returns {[x1,y1,x2,y2...]} array of borders coordinates flattened
     */
    static getBordersCoordinatesFlat(pointsIds) {
        return floorGraph.getArrayOfPointsFlatCoordinates(pointsIds);
    }

    /**
     *
     * @param {PointLinkWrapper[]} borders
     */
    static getBordersCoordinatesLineString(borders) {
        return borders.map((borderLink) => {
            return borderLink.pointInfo.coordinates;
        });
    }

    /**
     * @returns {{x: Number, y: Number}[]} array of borders coordinates
     */
    getBordersCoordinates() {
        const arrayOfVerticesCoordinates = this.getBordersCoordinatesFlat();

        const bordersCoordinates = [];

        for (let i = 0; i < arrayOfVerticesCoordinates.length; i += 2) {
            bordersCoordinates.push({
                x: arrayOfVerticesCoordinates[i],
                y: arrayOfVerticesCoordinates[i + 1],
            });
        }
        return bordersCoordinates;
    }
}
export default Zone;

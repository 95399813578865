import { gql } from '@apollo/client';

/**
 * Mutation for updating schema impl
 */
export const UPDATE_SCHEMA_IMPL = gql`
    mutation updateMapinsSchemaImpl(
        $objType: ObjectKind!
        $schemaImplDiff: SchemaImplDiff!
    ) {
        updateMapinsSchemaImpl(
            objType: $objType
            schemaImplDiff: $schemaImplDiff
        ) {
            id
            schema {
                id
                schema
                version
            }
            data
            itemId
            version
            item {
                ... on MapObject {
                    id
                    data {
                        data
                        version
                        schema {
                            schema
                        }
                    }
                    schemaImpls {
                        data
                        id
                        version
                        schema {
                            id
                            schema
                            version
                        }
                    }
                }
                ... on WallSegment {
                    id
                    startPoint {
                        id
                    }
                    endPoint {
                        id
                    }
                    data {
                        data
                        version
                        schema {
                            schema
                        }
                    }
                    schemaImpls {
                        data
                        id
                        version
                        schema {
                            id
                            schema
                            version
                        }
                    }
                }
                ... on Zone {
                    id
                    schemaImpls {
                        data
                        id
                        version
                        schema {
                            id
                            schema
                            version
                        }
                    }
                }
            }
        }
    }
`;

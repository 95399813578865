import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Header } from './ui/Header';
import { Content } from './ui/Content';
import { Footer } from '../../shared/ui/Footer';
import { userSettingsPageTitle } from '../../shared/model/routes/staticRoutes';

/**
 * Container for user settings page
 */
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    min-height: 100vh;

    & > header,
    & > footer {
        flex-grow: 0;
    }

    & > main {
        flex-grow: 1;
    }
`;

/**
 * User settings page component
 * @component
 */
export const UserSettings = () => {
    useEffect(() => {
        document.title = userSettingsPageTitle;
    }, []);

    return (
        <Wrapper>
            <Header />
            <Content />
            <Footer />
        </Wrapper>
    );
};

import notifications from '../../HintSystem/model/Notifications';
import floorGraph from '../../Wall/model/FloorGraph';
import temporaryZone from './TemporaryZone';
import zone from './Zone';

class ZoneValidator {
    /**
     * Graph is Eulerian if it is connected and every vertex has even number of adjacent vertices
     * Eulerian graph is graph that has a closed trail which includes every edge of the graph
     */
    validateZone() {
        if (!(this.isEven() && this.isConnected())) {
            notifications.setError('Ошибка', 'Ошибка создания зоны');
            return false;
        }
        return true;
    }

    /**
     * Depth First Search algorithm
     * @param {string} vertex point id
     * @param {Set} visited set of visited vertices
     */
    dfs(vertex, visited) {
        visited.add(vertex);
        for (const neighbor of floorGraph.getVertex(vertex).adjacentPoints) {
            if (
                !zone.getArrayOfPointsIds([
                    ...temporaryZone.tmpZonePointLinksVar(),
                ]).includes(neighbor)
            ) {
                continue;
            }

            if (!visited.has(neighbor)) {
                this.dfs(neighbor, visited);
            }
        }
    }

    /**
     * Check if picked points create connected graph
     */
    isConnected() {
        const visited = new Set();
        const TmpZoneArrayOfPointsIds = zone.getArrayOfPointsIds([
            ...temporaryZone.tmpZonePointLinksVar(),
        ]);
        this.dfs(TmpZoneArrayOfPointsIds[0], visited);
        for (const pointId of TmpZoneArrayOfPointsIds) {
            if (!visited.has(pointId)) {
                return false;
            }
        }
        return true;
    }

    /**
     * Check if every picked point has even number of adjacent vertices
     */
    isEven() {
        const TmpZoneArrayOfPointsIds = zone.getArrayOfPointsIds([
            ...temporaryZone.tmpZonePointLinksVar(),
        ]);
        const getAdjVerticesNum = (vertex) => {
            return floorGraph.getVertex(vertex).adjacentPoints.filter(
                (vertex) => TmpZoneArrayOfPointsIds.includes(vertex)
            );
        };
        for (const vertex of TmpZoneArrayOfPointsIds) {
            if (getAdjVerticesNum(vertex).length % 2 !== 0) {
                return false;
            }
        }
        return true;
    }
}

const zoneValidator = new ZoneValidator();
export default zoneValidator;

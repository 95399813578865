import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/client';
import { SCROLLBAR_STYLED } from '../../../../../../../shared/ui/ScrollBar';
import {
    H3OpenSansRegular,
    POpenSansRegular,
} from '../../../../../../../shared/ui/TextStyles';
import { SearchBar } from '../../../../../../../shared/ui/RolesSettingsComponents/SearchBar';
import { Button } from '../../../../../../../shared/ui/RolesSettingsComponents/Styles';
import { SEARCH_USERS } from '../../../../../../../shared/graphql/queries/users/SearchUsers';
import { PreloaderWithText } from '../../../../../../../shared/ui/PreloaderWithText';
import { UserCard } from './UserCard';

const Wrapper = styled.div`
    padding: 5px 20px 10px;
    width: min(536px, calc(100vw - 30px));
    height: fit-content;
    max-height: min(700px, calc(100vh - 80px));

    display: flex;
    flex-direction: column;

    overflow: hidden;

    position: relative;
`;

const WindowTitle = styled(H3OpenSansRegular)`
    font-weight: 600;
    align-self: center;
    margin-bottom: 15px;
`;

const PersonsContainer = styled.div`
    margin: 10px 0 10px;
    padding-right: 10px;

    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 7px;

    overflow: auto;
    ${SCROLLBAR_STYLED}
`;

const Footer = styled.div`
    margin: 0;
    padding: 15px 10px 0 5px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 0.5px solid #d9d9d9;
`;

const SecondaryText = styled(POpenSansRegular)`
    font-size: 14px;
    color: #696969;
`;

/**
 * Component represents UI for search and adding new user
 * @component
 */
export const AddUserModal = ({ groupId, groupName = '', onClose }) => {
    const [users, setUsers] = useState([]);
    const [searchStr, setSearchStr] = useState('');

    /**
     * Handler that sets users after successful found
     * @param {object} data data with users info
     * @param {object} data.users
     */
    const handleUsersFound = (data) => {
        setUsers(
            data.users.map((user) => ({
                userId: user.id,
                name: user.name,
                email: user.email,
            }))
        );
    };

    /** Query for searching users */
    const [searchQuery, { loading: searchLoading }] = useLazyQuery(
        SEARCH_USERS,
        {
            variables: { searchStr: searchStr },
            onCompleted: handleUsersFound,
        }
    );

    /**
     * Handler for search bar change
     * @param {React.ChangeEvent} e
     */
    const handleSearchStrChange = (e) => {
        const search = e.target.value;
        setSearchStr(search);
        if (search.length >= 3) {
            searchQuery();
        } else if (users.length > 0) {
            setUsers([]);
        }
    };

    return (
        <Wrapper>
            <WindowTitle>Добавить в «{groupName}»</WindowTitle>
            <SearchBar
                placeholder='Найти пользователя'
                onChange={handleSearchStrChange}
                value={searchStr}
                autoFocus
            />
            <PersonsContainer>
                {searchLoading ? (
                    <PreloaderWithText
                        loaderSize={60}
                        text='ищем пользователей'
                    />
                ) : (
                    users.length === 0 && (
                        <SecondaryText
                            style={{
                                alignSelf: 'center',
                                margin: '20px 0',
                            }}
                        >
                            {searchStr.length >= 3
                                ? 'Пользователи не найдены'
                                : 'Для поиска введите хотя бы 3 символа'}
                        </SecondaryText>
                    )
                )}
                {users.map((user) => (
                    <UserCard key={user.userId} user={user} groupId={groupId} />
                ))}
            </PersonsContainer>
            <Footer>
                <Button onClick={onClose}>Готово</Button>
            </Footer>
        </Wrapper>
    );
};

AddUserModal.propTypes = {
    groupId: PropTypes.string,
    groupName: PropTypes.string,
    onClose: PropTypes.func,
};

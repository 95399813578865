import React from 'react';
import { MapObjectContextMenu } from '../ui/ContextMenus/MapObjectContextMenu';
import { openContextMenu } from './OpenContextMenu';

/**
 * Function that call opening context menu function for the current item
 *
 * @param {object} currentItem canvas item
 * 
 * @param {object} position position of mouse click
 * @param {number} position.x
 * @param {number} position.y
 */
export const openMapObjectsContextMenu = (currentItem, position) => {
    openContextMenu(position, <MapObjectContextMenu currentItem={currentItem} />);
};

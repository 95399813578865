import { stageVar } from '../../../../shared/model/cache/Cache';
import CommandManager from '../../../../features/CommandManager/model/CommandManager';
import { createMapObjectCommand } from '../../../../features/CommandManager/lib/commands/mapObject/CreateMapObject';
import Zones from '../../../../features/Zone/model/Zones';

/**
 * Handler to drop item on Canvas
 * @param {object} e Drop Event
 */
export const handleItemDrop = (e) => {
    e.preventDefault();
    stageVar().setPointersPositions(e);

    const zoneId = Zones.getZoneIdUnderPoint({
        ...stageVar().getRelativePointerPosition(),
    });

    CommandManager.do(
        createMapObjectCommand(
            {
                ...stageVar().getRelativePointerPosition(),
            },
            zoneId
        )
    );
};

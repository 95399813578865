import styled, { css } from 'styled-components';
import { getFlexAlignment } from './StylesHelpers';
import { HINT_POSITION, HINT_TEXT_ALIGNMENT } from '../../../model/Constants';

export const BASE_NOTIFICATION_ANIMATION_DURATION: number = 300;

/** Transition rules for notification animation */
const transitionRules = css`
    transition: transform ${BASE_NOTIFICATION_ANIMATION_DURATION}ms ease-in-out,
        opacity ${BASE_NOTIFICATION_ANIMATION_DURATION}ms ease-in-out;
`;

/**
 * Function to calculate translate.
 * Need to set initial position for notification (before enter animation and after exit animation)
 * @param {HINT_POSITION} position notifications container position
 * @returns {string} translate property
 */
const getInitialTranslate = (position: HINT_POSITION): string => {
    switch (position) {
        case HINT_POSITION.bottomLeft:
        case HINT_POSITION.topLeft:
            return 'translateX(-50%)';
        case HINT_POSITION.topRight:
        case HINT_POSITION.bottomRight:
            return 'translateX(50%)';
        case HINT_POSITION.topMiddle:
        case HINT_POSITION.bottomMiddle:
        default:
            return 'translateX(0)';
    }
};

interface NotificationContainerProps {
    textAlignment: HINT_TEXT_ALIGNMENT;
    bgColor: string;

    /** Position for container with notifications (need for animation) */
    position: HINT_POSITION;
}

export const NotificationContainer = styled.div<NotificationContainerProps>`
    position: relative;
    padding: 13px 17px;
    max-width: 367px;
    max-height: 250px;
    overflow-y: hidden;

    display: flex;
    flex-direction: column;
    align-items: ${(props) => getFlexAlignment(props.textAlignment)};
    gap: 6px;

    background-color: ${(props) => props.bgColor};
    border-radius: 11px;
    border: 1px solid #dedede;
    box-shadow: 0 4px 17px rgba(0, 0, 0, 0.07);
    user-select: none;

    // visibility for close icon
    &:hover .close {
        visibility: visible;
    }

    /* Animation */
    &.BaseNotification-anim-appear,
    &.BaseNotification-anim-enter,
    &.BaseNotification-anim-exit-done {
        opacity: 0;
        transform: ${(props) => getInitialTranslate(props.position)};
    }
    &.BaseNotification-anim-enter-done,
    &.BaseNotification-anim-exit {
        opacity: 1;
        transform: translateX(0);
    }
    &.BaseNotification-anim-appear-active,
    &.BaseNotification-anim-enter-active {
        opacity: 1;
        transform: translateX(0);
        ${transitionRules}
    }
    &.BaseNotification-anim-exit-active {
        opacity: 0;
        transform: ${(props) => getInitialTranslate(props.position)};
        ${transitionRules}
    }
`;

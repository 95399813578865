import { useMutation } from '@apollo/client';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router';

import EyeClosed from '../../../shared/assets/images/icons/eye-closed.svg';
import EyeOpened from '../../../shared/assets/images/icons/eye-opened.svg';

import { UPDATE_USER_PASSWORD } from '../../../shared/graphql/mutations/users/UpdateUserPassword';
import { loginPage } from '../../../shared/model/routes/staticRoutes';

import { Button, EyeImg, Input, PasswordInput } from './Styles';

/**
 * Change form component.
 * @returns { form } with two inputs to change the password
 */
export const ChangeForm = () => {
    const [inputValues, setInputValues] = useState({
        password: '',
        controlPassword: '',
    });
    const [formValid, setFormValid] = useState(false);

    const [firstType, setFirstType] = useState('password');
    const [firstIcon, setFirstIcon] = useState(EyeClosed);

    const [secondType, setSecondType] = useState('password');
    const [secondIcon, setSecondIcon] = useState(EyeClosed);

    const passwordInputRef = useRef();
    const controlPasswordInputRef = useRef();
    
    const navigate = useNavigate();
    
    /**
     * Use mutation return tuple: [queryFunction, {data, isLoading, error}]
     * Three last vars were deleted because of warnings
     * Add them here if needed
     */
    const [updateUserPassword] = useMutation(UPDATE_USER_PASSWORD);

    /**
     * Function for validation password and control password
     * @param { password } param User password
     * @param { controlPassword } param User control password
     */
    const validate = ({ password, controlPassword }) => {
        const passRegex =
            /(?=.*[0-9])(?=.*[!?+-=_@#$%^&*])(?=.*[a-zа-я])(?=.*[A-ZА-Я])[0-9a-zа-яA-ZА-Я!?+-=_@#$%^&*]{8,128}/gm;

        const isPassValid = password.match(passRegex);

        const isValid = isPassValid && password === controlPassword;

        if (isValid !== formValid) {
            setFormValid(isValid);
        }
    };

    /**
     * Run when sending the form
     * @param {*} e Needed for prevent default
     */
    const handleSubmit = (e) => {
        e.preventDefault();

        updateUserPassword({
            variables: {
                password: inputValues.password,
            },
        })
            .then((response) => {
                if (response.data.updateUserPassword) {
                    navigate(loginPage);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    /**
     * Inputs change handler
     * @param { currentTarget } param Element in which the event is handled
     */
    const handleChange = ({ currentTarget }) => {
        const { value, name } = currentTarget;
        setInputValues((prev) => {
            const values = { ...prev, [name]: value };
            validate(values);
            return values;
        });
    };

    /**
     * Change password type and eye img handler
     */
    const changePasswordTypeHandler = (type, setType, setIcon) => {
        if (type === 'password') {
            setType('text');
            setIcon(EyeOpened);
        } else {
            setType('password');
            setIcon(EyeClosed);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <PasswordInput ref={passwordInputRef}>
                <Input
                    name="password"
                    value={inputValues.password}
                    type={firstType}
                    onChange={handleChange}
                    onFocus={(e) => {
                        e.target.parentElement.style.borderBottom =
                            '0.5px solid #023aff';
                    }}
                    onBlur={(e) => {
                        e.target.parentElement.style.borderBottom =
                            '0.5px solid rgba(0, 0, 0, 0.7)';
                    }}
                    placeholder="Придумайте пароль"
                    style={{
                        border: 0,
                        margin: 0,
                    }}
                ></Input>
                {inputValues.password.length > 0 && (
                    <EyeImg
                        src={firstIcon}
                        onClick={() =>
                            changePasswordTypeHandler(
                                firstType,
                                setFirstType,
                                setFirstIcon
                            )
                        }
                        alt="change password type"
                    />
                )}
            </PasswordInput>

            <PasswordInput ref={controlPasswordInputRef}>
                <Input
                    name="controlPassword"
                    value={inputValues.controlPassword}
                    type={secondType}
                    onChange={handleChange}
                    onFocus={(e) => {
                        e.target.parentElement.style.borderBottom =
                            '0.5px solid #023aff';
                    }}
                    onBlur={(e) => {
                        e.target.parentElement.style.borderBottom =
                            '0.5px solid rgba(0, 0, 0, 0.7)';
                    }}
                    placeholder="Повторите пароль"
                    style={{
                        border: 0,
                        margin: 0,
                    }}
                ></Input>
                {inputValues.controlPassword.length > 0 && (
                    <EyeImg
                        src={secondIcon}
                        onClick={() =>
                            changePasswordTypeHandler(
                                secondType,
                                setSecondType,
                                setSecondIcon
                            )
                        }
                        alt="change password type"
                    />
                )}
            </PasswordInput>

            <Button disabled={!formValid}>Сохранить изменения</Button>
        </form>
    );
};

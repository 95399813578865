import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import dotsIcon from '../../../shared/assets/images/icons/dots-vertical.svg';
import binIcon from '../../../shared/assets/images/icons/MainPage/bin.svg';
import {
    POpenSansLight,
    POpenSansRegular,
} from '../../../shared/ui/TextStyles';
import { AccessGroup } from '../../AccessGroup/model/AccessGroup';
import { Breadcrumbs } from './Breadcrumbs';
import { ComboBox } from '../../../shared/ui/FormComponentsSmall/ComboBox';
import accessGroupsModerator from '../../AccessGroup/model/AccessGroupsModerator';
import {
    RULE_EDIT,
    RULE_VIEW,
    ruleConverter,
    ruleConverterReversed,
} from '../../AccessGroup/model/AGConstants';
import { REVOKE_ACCESS } from '../../../shared/graphql/mutations/accessRules/RevokeAccess';
import { UPDATE_ACCESS } from '../../../shared/graphql/mutations/accessRules/UpdateAccess';
import { objectSettingsModalVar } from '../../../shared/model/cache/Cache';
import { Preloader } from '../../../shared/ui/Preloader';
import notifications from '../../HintSystem/model/Notifications';

const GroupRow = styled.div`
    padding: 8px 15px;
    width: 100%;

    border: 0.5px solid white;
    border-radius: 9px;
    box-sizing: border-box;

    &:hover {
        border-color: #d7d7d7;
        box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.07);
    }
`;

const ColumnsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
`;

const Column = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    box-sizing: border-box;
`;

const ShrinkColumn = styled(Column)`
    min-width: 200px;
    max-width: calc(100% - 205px);
`;

const GroupName = styled(POpenSansRegular)`
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const OptionsImg = styled.img`
    height: 13px;
    width: auto;
    cursor: pointer;
`;

const GroupRoleContainer = styled.div`
    margin-right: 10px;
`;

/**
 * Function to find hierarchy path from user group to current
 * @param {AccessGroup} rootGroup
 * @returns {string[]} path array
 */
const findGroupPath = (lastGroup) => {
    const pathArr = [];
    let currentGroup = lastGroup;
    while (
        currentGroup.parent !== accessGroupsModerator.currentAccessGroupId &&
        currentGroup.parent !== null
    ) {
        currentGroup = accessGroupsModerator.getGroup(currentGroup.parent);
        pathArr.push(currentGroup.name());
    }
    return pathArr.reverse();
};

/* Constants for user loading state */
const DEFAULT_STATE = 0;
const LOADING_STATE = 1;

/**
 * Component represents one group of users
 * @component
 */
export const Group = ({
    group,
    rule,
    onUpdateAccessRules,
    onDeleteAccessRules,
}) => {
    // eslint-disable-next-line
    const pathArr = useMemo(() => findGroupPath(group), [group.groupId]);

    const [state, setState] = useState(DEFAULT_STATE);

    /** Mutation to delete access rule for group */
    const [removeAccessRule] = useMutation(REVOKE_ACCESS);

    /** Mutation to update access rule */
    const [updateAccessRule] = useMutation(UPDATE_ACCESS);

    /** Handler for updating access rule */
    const handleUpdate = (newRule) => {
        setState(LOADING_STATE);
        updateAccessRule({
            variables: {
                type: 'Zone',
                ruleSelector: {
                    groupId: group.groupId,
                    itemId: objectSettingsModalVar().objectUUID,
                },
                access: ruleConverterReversed[newRule],
            },
            onCompleted: (data) => {
                onUpdateAccessRules(data.updateAccess);
                setState(DEFAULT_STATE);
            },
            onError: (error) => {
                setState(DEFAULT_STATE);
                console.log(error);
                notifications.setError(
                    'Не удалось обновить права',
                    error.message
                );
            },
        });
    };

    /** Handler for deleting access rule */
    const handleDelete = () => {
        setState(LOADING_STATE);
        removeAccessRule({
            variables: {
                type: 'Zone',
                ruleSelector: {
                    groupId: group.groupId,
                    itemId: objectSettingsModalVar().objectUUID,
                },
            },
            onCompleted: (data) => {
                onDeleteAccessRules(data.revokeAccess);
                setState(DEFAULT_STATE);
            },
            onError: (error) => {
                setState(DEFAULT_STATE);
                console.log(error);
                notifications.setError(
                    'Не удалось отозвать права',
                    error.message
                );
            },
        });
    };

    return (
        <GroupRow>
            <Breadcrumbs pathArr={pathArr} />
            <ColumnsContainer>
                <ShrinkColumn>
                    <GroupName>{group.name()}</GroupName>
                </ShrinkColumn>
                <Column>
                    {group.parentMirror ? (
                        <POpenSansLight title='Эта группа копирует права родительской'>
                            {ruleConverter[rule]}
                        </POpenSansLight>
                    ) : (
                        <>
                            {state === LOADING_STATE && (
                                <Preloader loaderSize={25} />
                            )}
                            <GroupRoleContainer>
                                <ComboBox
                                    items={Object.values(ruleConverter)}
                                    selectedElem={ruleConverter[rule]}
                                    handleSelect={handleUpdate}
                                />
                            </GroupRoleContainer>
                            <OptionsImg
                                src={binIcon}
                                alt='delete'
                                onClick={handleDelete}
                                title='Отозвать права'
                            />
                            <OptionsImg src={dotsIcon} alt='⋮' />
                        </>
                    )}
                </Column>
            </ColumnsContainer>
        </GroupRow>
    );
};

Group.propTypes = {
    /** Access group */
    group: PropTypes.instanceOf(AccessGroup),

    /** Access group */
    rule: PropTypes.oneOf([RULE_EDIT, RULE_VIEW]),

    /**
     * Handler for result of updating access rule
     * @type {(rules: AccessRuleFromServer[]) => void}
     */
    onUpdateAccessRules: PropTypes.func,

    /**
     * Handler for result of deleting access rule
     * @type {(rules: AccessRuleFromServer[]) => void}
     */
    onDeleteAccessRules: PropTypes.func,
};

import React from 'react';
import { ConstructorModeHint } from '../ui/ConstructorModeHint/ConstructorModeHint';
import { HintsModerator } from './HintsModerator';
import eyeImg from '../../../shared/assets/images/icons/ConstructorPage/ModeEnterPopup/eye.svg';
import penImg from '../../../shared/assets/images/icons/ConstructorPage/ModeEnterPopup/pen.svg';
import { BaseNotification } from '../ui/BaseNotification/BaseNotification';
import { HINT_DEFAULT_SHOW_TIME, HINT_POSITION } from './Constants';
import { BASE_NOTIFICATION_ANIMATION_DURATION } from '../ui/BaseNotification/Styles/NotificationContainer';
import { CONSTRUCTOR_MODE_HINT_ANIMATION_DURATION } from '../ui/ConstructorModeHint/Styles/HintContainer';

/** Properties for constructor mode hints */
const propsForConstructorModeHint = {
    showTime: HINT_DEFAULT_SHOW_TIME - CONSTRUCTOR_MODE_HINT_ANIMATION_DURATION,
    delayBeforeClose: CONSTRUCTOR_MODE_HINT_ANIMATION_DURATION,
};

/**
 * @class Class to set hints for constructor page
 */
class ConstructorPageHints extends HintsModerator {
    constructor() {
        super({
            position: HINT_POSITION.topMiddle,
            offsetY: 114,
        });
    }

    /**
     * Method to set notification that you are entering the view mode of the constructor page
     */
    setEnterViewMode() {
        const text = 'Просмотр';
        this.setHint(
            <ConstructorModeHint hintText={text} iconSrc={eyeImg} />,
            propsForConstructorModeHint
        );
    }

    /**
     * Method to set notification that you are entering the edit mode of the constructor page
     */
    setEnterEditMode() {
        const text = 'Редактирование';
        this.setHint(
            <ConstructorModeHint hintText={text} iconSrc={penImg} />,
            propsForConstructorModeHint
        );
    }

    /**
     * Method to set help hint
     * @param {string} title
     * @param {string} text
     */
    setInfoHint(title: string, text: string) {
        this.setHint(
            <BaseNotification
                text={text}
                title={title}
                containerPosition={this.hintsPosition}
                onClose={() => this.clearHints()}
            />,
            { delayBeforeClose: BASE_NOTIFICATION_ANIMATION_DURATION }
        );
    }
}

const constructorPageHints = new ConstructorPageHints();
export default constructorPageHints;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { FormContainer } from '../../shared/ui/FormContainer';
import { requestRecoverPageTitle } from '../../shared/model/routes/staticRoutes';

/**
 * Recover component.
 * Depending on the recoverPasswordAccessVar show RecoveryPages with different form
 * first - recovery form (with email input)
 * second - change form (with password and control password form)
 * @returns { ModalContent }  Centralized recovery component
 */
export const RecoveryPage = ({ children }) => {
    useEffect(() => {
        document.title = requestRecoverPageTitle;
    }, []);

    return (
        <FormContainer
            title='Восстановление пароля'
            style={{ margin: '29px 0 51px' }}
        >
            {children}
        </FormContainer>
    );
};

RecoveryPage.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.arrayOf(PropTypes.object),
    ]),
};

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { FolderHeader } from './FolderHeader';
import { FolderContent } from './FolderContent/FolderContent';
import rightBarModerator from '../../../../model/RightBarModerator';

const FolderContainer = styled.li`
    padding: 0;
    display: flex;
    flex-direction: column;
    user-select: none;
`;

/**
 * Component that provides container for folder with items in right bar
 *
 * @component
 */
export const ItemsFolder = ({ folderName, items, folder }) => {
    const isFolderOpened = rightBarModerator.openedFolders().has(folderName);

    const handleCollapseClick = () => {
        if(isFolderOpened) {
            rightBarModerator.closeFolder(folderName);
        } else {
            rightBarModerator.openFolders(folderName);
        }
    };

    return (
        <FolderContainer>
            <FolderHeader
                folderName={folderName}
                isFolderOpened={isFolderOpened}
                handleCollapseClick={handleCollapseClick}
            />
            {isFolderOpened && <FolderContent items={items} folder={folder} />}
        </FolderContainer>
    );
};
ItemsFolder.propTypes = {
    folderName: PropTypes.string,
    /**
     * array of canvas items
     */
    items: PropTypes.arrayOf(
        PropTypes.shape({
            zoneId: PropTypes.string,
            angle: PropTypes.number,
            height: PropTypes.number,
            width: PropTypes.number,
            itemName: PropTypes.string,
            pictureLink: PropTypes.string,
            position: PropTypes.shape({
                x: PropTypes.number,
                y: PropTypes.number,
            }),
        })
    ),
};

/**
 * @file Store data structure descriptions for bots
 * and methods to convert one bot data structure to another
 */

/**
 * @typedef {import('./Bot').Bot} Bot
 */

/**
 * Describes bot data structure, used in project.
 * Store only necessary data
 * @typedef {{
 *  id: string?,
 *  name: string?,
 *  description: string?
 * }} BotData
 */

/**
 * Bot data received from server
 * @typedef {{
 *  id: string?,
 *  name: string?,
 *  description: string?,
 * }} BotDataFromServer
 */

/**
 * Method to convert instance of Bot to bot project structure
 * @param {Bot} bot
 * @returns {BotData}
 */
export const botToBotData = (bot) => {
    return {
        id: bot.routeId,
        name: bot.name(),
        description: bot.description(),
    };
};

/**
 * Method to convert bot data from server to bot project structure
 * @param {BotDataFromServer} dataFromServer
 * @returns {BotData}
 */
export const botDataFromServerToBotData = (dataFromServer) => {
    return {
        id: dataFromServer.id,
        name: dataFromServer.name,
        description: dataFromServer.description,
    };
};

import React from 'react';
import styled from 'styled-components';
import { Tabs } from '../../../../../shared/ui/TabSystem/Tabs';
import { WallsTab } from './Tabs/WallsTab';
import { MapObjectsTab } from './Tabs/MapObjectsTab';
import { useReactiveVar } from '@apollo/client';
import rightBarModerator from '../../model/RightBarModerator';
import { DefaultRBTabsName } from '../../model/RightBarOperations';

const Wrapper = styled.div`
    padding: 0 23px 23px;
`;

const tabList = ['Сегменты', 'Объекты'];

/**
 * Component that represents body for default right bar
 * @component
 */
export const DefaultRightBar = () => {
    const selectedTab = useReactiveVar(rightBarModerator.selectedTab);
    return (
        <Wrapper>
            <Tabs
                tabList={DefaultRBTabsName}
                selectedTab={selectedTab}
                groupName='defaultRightBarTabs'
                onSelect={(tab) => rightBarModerator.selectTab(tab)}
                containerStyle={{ margin: '10px 0' }}
            />
            {selectedTab === tabList[0] ? <WallsTab /> : <MapObjectsTab />}
        </Wrapper>
    );
};

import React from 'react';
import { ReactiveVar, makeVar } from '@apollo/client';
import { v4 as uuid } from 'uuid';
import { HINT_DEFAULT_SHOW_TIME, HINT_POSITION } from './Constants';

/**
 * Interface describe one hint element.
 *
 * Total time for showing hint calculates as showTime + delayBeforeClose
 */
export interface HintItem {
    /** Hint ID */
    id: string;

    /** Element to render */
    element: React.ReactNode;

    /**
     * Time to show hint in ms.
     * After the time expires, the hint will close
     */
    showTime: number;

    /**
     * Delay in ms before hint close (need for animation).
     * Specify only if you need exit animation.
     * This extra time before close
     */
    delayBeforeClose: number;

    /**
     * Close handler.
     * With this handler hint should be closed
     */
    onClose: () => void;
}

/** Props for methods that creates new hint */
type CreateHintProps = Partial<
    Pick<HintItem, 'id' | 'showTime' | 'delayBeforeClose'>
>;

/**
 * @class HintsModerator is an abstract class for moderate hints
 */
export class HintsModerator {
    /** Var for storing hints */
    hintsVar: ReactiveVar<HintItem[]>;

    /** Hints container position */
    hintsPosition: HINT_POSITION;

    /** Horizontal offset of hint container */
    offsetX: number;

    /** Vertical offset of hint container */
    offsetY: number;

    /**
     * @constructor
     * @param props
     * @param props.position hints container position
     * @param props.offsetX hints container horizontal offset (auto calculation based on position)
     * @param props.offsetY hints container vertical offset
     */
    constructor({
        position = HINT_POSITION.topMiddle,
        offsetX = undefined,
        offsetY = 20,
    }: {
        position?: HINT_POSITION;
        offsetX?: number;
        offsetY?: number;
    } = {}) {
        if (offsetX === undefined) {
            switch (position) {
                case HINT_POSITION.topRight:
                case HINT_POSITION.topLeft:
                case HINT_POSITION.bottomRight:
                case HINT_POSITION.bottomLeft:
                    offsetX = 20;
                    break;
                case HINT_POSITION.topMiddle:
                case HINT_POSITION.bottomMiddle:
                default:
                    offsetX = 0;
            }
        }

        this.hintsVar = makeVar<HintItem[]>([]);
        this.hintsPosition = position;
        this.offsetX = offsetX;
        this.offsetY = offsetY;
    }

    /**
     * Method that sets hint element (replace other hints)
     * @param element that will be rendered as hint
     * @param props extra properties
     * @return created hint id
     */
    setHint(element: React.ReactNode, props: CreateHintProps = {}): string {
        this.clearHints();
        const id = this.addHint(element, props);
        return id;
    }

    /**
     * Method that add hint
     * @param element that will be rendered as hint
     * @param props extra properties
     * @return created hint id
     */
    addHint(
        element: React.ReactNode,
        {
            id = uuid(),
            showTime = HINT_DEFAULT_SHOW_TIME,
            delayBeforeClose = 0,
        }: CreateHintProps = {}
    ): string {
        this.hintsVar([
            ...this.hintsVar(),
            {
                id,
                element,
                onClose: () => this.deleteHint(id),
                showTime,
                delayBeforeClose,
            },
        ]);
        return id;
    }

    /**
     * Delete concrete hint
     * @param hintId
     */
    deleteHint(hintId: string) {
        this.hintsVar(
            this.hintsVar().filter((hint: HintItem) => hint.id !== hintId)
        );
    }

    /** Method that fully clears hints var */
    clearHints() {
        this.hintsVar([]);
    }
}

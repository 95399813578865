import { constraintGroupDataFromServerToCGData } from '../../../features/Constraints/model/ConstraintGroupAdapter';
import constraintGroupsModerator from '../../../features/Constraints/model/ConstraintGroupsModerator';
import { routeDataFromServerToRouteData } from '../../../features/Route/model/RouteAdapter';
import routesModerator from '../../../features/Route/model/RoutesModerator';

/**
 * @typedef {import('../../../features/Constraints/model/ConstraintGroupAdapter').ConstraintGroupDataFromServer} ConstraintGroupDataFromServer
 */

/**
 * Method for initialize constraint groups.
 * This method receives constraintGroups object from server and fill:
 *
 * 1. ConstraintGroupsModerator with new constraint groups.
 *
 * 2. RoutesModerator with new route objects.
 * Creating new route object includes filling routeGraph for each route
 *
 * @param {ConstraintGroupDataFromServer[]} constraintGroups
 */
export const initializeConstraints = (constraintGroups) => {
    constraintGroupsModerator.clear();
    routesModerator.clear();

    if (!constraintGroups || !constraintGroups[0]) {
        return;
    }

    constraintGroups.forEach((constrGroup) => {
        // add constraint group to manager
        constraintGroupsModerator.addConstraintGroup(
            constrGroup.id,
            constraintGroupDataFromServerToCGData(constrGroup)
        );

        constrGroup.routes.forEach((route) => {
            const routeData = routeDataFromServerToRouteData(route);
            const accessRule = routeData.accessRule;

            // add route to routes moderator
            const newRoute = routesModerator.addRoute(
                constrGroup.id,
                route.id,
                routeData
            );

            const routeGraph = newRoute.graph;

            // add vertices to RouteGraph
            route.graph.points.forEach(({ key, value }) => {
                routeGraph.addVertexToGraph(key, {
                    coordinates: {
                        x: value.coordinate.x,
                        y: value.coordinate.y,
                    },
                    adjacentPoints: [],
                    accessRule: accessRule,
                });
            });

            // add segments to RouteGraph
            route.segments.forEach((segment) => {
                routeGraph.addSegment(
                    { key: segment.startPoint.id },
                    { key: segment.endPoint.id },
                    {
                        isFetched: true,
                        accessRule: accessRule,
                        version: segment.version,
                    },
                    true
                );
            });

            routeGraph.rerender();
        });
    });

    // TODO remake choosing default constraint group
    // now takes the first constraint group
    constraintGroupsModerator.setDefaultGroup();
};

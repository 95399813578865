import { client } from '../../../shared/graphql/ApolloClient';
import { CREATE_ALERT } from '../../../shared/graphql/mutations/alerts/CreateAlert';
import { DELETE_ALERT } from '../../../shared/graphql/mutations/alerts/DeleteAlert';
import { AlertData, AlertDraft } from '../model/Types';
import { alertDataFromServerToAlertData } from '../model/AlertsAdapter';
import notifications from '../../HintSystem/model/Notifications';

/**
 * Class that realizes api methods for alerts
 * @class
 */
class AlertsAPI {
    /**
     * Method for creating new alert
     * @returns newAlert | null
     */
    async create(draft: AlertDraft): Promise<AlertData | null> {
        const options = {
            mutation: CREATE_ALERT,
            variables: {
                attentionMarkerDraft: draft,
            },
        };

        try {
            const data = (await client.mutate(options)).data
                .createAttentionMarker;
            return alertDataFromServerToAlertData(data);
        } catch (error: any) {
            console.error(error);
            notifications.setError(
                'Не удалось создать комментарий',
                error.message
            );
        }

        return null;
    }

    /**
     * Method for deleting alert
     * @param alertId alert UUID
     * @returns id of deleted object | null
     */
    async delete(alertId: string): Promise<string | null> {
        const options = {
            mutation: DELETE_ALERT,
            variables: {
                id: alertId,
            },
        };

        try {
            return (await client.mutate(options)).data.deleteAttentionMarker.id;
        } catch (error: any) {
            console.error(error);
            notifications.setError(
                'Не удалось удалить комментарий',
                error.message
            );
        }

        return null;
    }
}

const alertsAPI = new AlertsAPI();
export default alertsAPI;

import Konva from 'konva';
import { stageVar } from '../../../shared/model/cache/Cache';
import { areLineAndRectIntersected } from '../../../shared/lib/modules/math/Math';

import FloorGraph from '../../Wall/model/FloorGraph';

/**
 * Function to get item's box LineString from transformer box
 * @param {{
 *  x: Number,
 *  y: Number,
 *  width: Number,
 *  height: Number
 *  rotation: Number<degrees>
 * }} transformingShape konva box in transformer
 * @returns {{
 *  x: Number,
 *  y: Number
 * }[][]}
 */
export const transformingShapeToBoxLineStrings = (transformingShape) => {
    /**
     * We need rectShape because it has getAbsoluteTransform function that
     * calculates coordinates of rect points after transform
     */
    const rectShape = new Konva.Rect({
        x: transformingShape.x,
        y: transformingShape.y,
        width: transformingShape.width,
        height: transformingShape.height,
        rotation: (transformingShape.rotation * 180) / Math.PI,
    });

    const corners = getShapeCorners(rectShape);

    /**
     * rectShape is not added to any layer, so getTransform doesn't calculate transforms of canvas.
     * That's why  we need absoluteToRelative function
     */
    for (let i = 0; i < 4; ++i) {
        corners[i] = applyStageTransformToPoint(
            rectShape.getAbsoluteTransform().point(corners[i])
        );
    }

    rectShape.destroy();

    return [
        [corners[0], corners[1]],
        [corners[1], corners[2]],
        [corners[2], corners[3]],
        [corners[3], corners[0]],
    ];
};

/**
 * Function to get item's box LineString from shape
 * @param {Konva.shape} shape
 * @returns {{
 *  x: Number,
 *  y: Number
 * }[][]}
 */
export const konvaShapeToBoxLineStrings = (shape) => {
    const corners = getShapeCorners(shape);
    for (let i = 0; i < 4; ++i) {
        corners[i] = shape.getTransform().point(corners[i]);
    }

    return [
        [corners[0], corners[1]],
        [corners[1], corners[2]],
        [corners[2], corners[3]],
        [corners[3], corners[0]],
    ];
};

/**
 * Function to check if rect intersects any wall
 * @param {{
 *  x: Number,
 *   y: Number
 * }[][]} rect
 * @returns {Boolean}
 */
export const isRectIntersectsAnyWall = (rect) => {
    const hasIntersection = FloorGraph.drawSegmentsVar().find((segment) => {
        const lineString = FloorGraph.getLineStringOfEdge(segment.getKey());
        return areLineAndRectIntersected(lineString, rect);
    });

    return !!hasIntersection;
};

/**
 * Function to get untransformed shape corners
 * @param {Konva.Shape} shape
 * @returns {point[]} shape corner points
 */
const getShapeCorners = (shape) => {
    const size = { ...shape.size() };

    const corners = [];
    corners[0] = { x: 0, y: 0 }; // top left
    corners[1] = { x: size.width, y: 0 }; // top right
    corners[2] = { x: size.width, y: size.height }; // bottom right
    corners[3] = { x: 0, y: size.height }; // bottom left

    return corners;
};

/**
 * @param {{x: Number, y: Number}} absPointCoordinates point in absolute coordinates.
 * @returns {{x: Number, y: Number}} point in relative coordinates
 */
const applyStageTransformToPoint = (absPointCoordinates) => {
    return {
        x:
            (absPointCoordinates.x - stageVar().position().x) /
            stageVar().scaleX(),
        y:
            (absPointCoordinates.y - stageVar().position().y) /
            stageVar().scaleY(),
    };
};

import styled from 'styled-components';
import { SCROLLBAR_STYLED } from '../../../shared/ui/ScrollBar';

export const StyledButton = styled.button`
    width: 101px;
    height: 27px;
    background: #e1e8ff;
    border: none;
    border-radius: 9px;
    margin: 12px 0 0;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    :hover {
        background-color: gray;
    }

    &[disabled] {
        cursor: not-allowed;
        background: #e1e8ff;
    }
`;

export const StyledLabel = styled.label`
    margin: 0;
    padding: 0;
    width: 100%;
`;

export const StyledInput = styled.input`
    margin: 0;
    padding: 0;
    width: 100%;
    height: 25px;
    outline: none;

    border: 0.5px solid transparent;
    border-radius: 4px;

    font-family: Open Sans;
    font-size: 14.65px;
    font-weight: 300;

    :focus {
        border: 0.5px solid #023aff;
        border-radius: 4px;
    }
`;

/**
 * @param {{x: number, y: number}} position
 * @param {number} scale
 */
export const StyledContextMenu = styled.div`
    position: absolute;
    top: ${(props) => `${props.position.y}px`};
    left: ${(props) => `${props.position.x}px`};

    max-width: 80vw;
    max-height: min(508px, 80dvh);

    background: #ffffff;
    border: 1px solid #dedede;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

    padding: 20px 0;

    overflow: auto;
    ${SCROLLBAR_STYLED}

    transform-origin: top left;
    transform: scale(${(props) => `${1/props.scale}`});
`;

/** Styled container for zone context menu */
export const StyleZoneContextContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    row-gap: 15px;
    padding: 10px 30px;
`;

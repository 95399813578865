import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PersonCard } from './PersonCard';
import { OptionsImg } from '../../../../../../../shared/ui/RolesSettingsComponents/Styles';
import dotsIcon from '../../../../../../../shared/assets/images/icons/dots-vertical.svg';
import {
    CM_DIRECTIONS,
    ContextMenu,
} from '../../../../../../../features/ContextMenu/ui/styles/ContextMenu';
import { useMutationDeleteMember } from '../../../../../../../features/AccessGroup/lib/hooks/useMutationDeleteMember';
import preloaderImg from '../../../../../../../shared/assets/images/icons/preloader.svg';

const MemberContainer = styled.div`
    width: calc(100% - 18px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    margin: 4px 0 4px 20px;
    box-sizing: border-box;
`;

const Column = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`;

/**
 * Component represents member of access group for roles project settings
 * @component
 */
export const Member = ({ member, groupId, containerRef }) => {
    const optionsRef = useRef(); // ref for options button (need to open context menu)
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const contextMenuPos = {
        x: optionsRef.current?.offsetLeft + 16,
        y: optionsRef.current?.offsetTop - containerRef.current?.scrollTop - 5,
    };

    const [isDeleting, setIsDeleting] = useState(false);

    /** Mutation to delete user from group */
    const deleteUser = useMutationDeleteMember({
        userId: member.id,
        groupId: groupId,
        onCompleted: () => {
            setIsMenuOpened(false);
            setIsDeleting(false);
        },
    });

    const contextMenu = [
        {
            text: 'Удалить',
            icon: isDeleting ? preloaderImg : null,
            onClick: () => {
                setIsDeleting(true);
                deleteUser();
            },
        },
    ];

    return (
        <MemberContainer key={member.id}>
            <PersonCard
                title={member.name}
                description={member.email || member.description}
            />
            <Column>
                <OptionsImg
                    src={dotsIcon}
                    alt='⋮'
                    ref={optionsRef}
                    onClick={() => setIsMenuOpened(true)}
                />
                <ContextMenu
                    position={contextMenuPos}
                    isOpened={isMenuOpened}
                    menu={contextMenu}
                    onClose={() => setIsMenuOpened(false)}
                    directionX={CM_DIRECTIONS.RIGHT}
                />
            </Column>
        </MemberContainer>
    );
};

Member.propTypes = {
    /** Member object */
    member: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        email: PropTypes.string,
        description: PropTypes.string,
    }),

    /** Member access group UUID */
    groupId: PropTypes.string,

    /** Reference to scroll container */
    containerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
};

import { Validate } from './SchemaSpecifier';

class ValidatorFactory {
    createNumberValidator() {}

    createStringValidator(limit: number = 500) {
        const changeValidate: Validate<string> = (value: string) => {
            if (value.length > limit) {
                return false;
            }

            return true;
        };

        const saveValidate: Validate<string> = (value: string) => {
            if (value.length < 1 || value.length > limit) {
                return false;
            }

            return true;
        };

        return [changeValidate, saveValidate];
    }
}

const validatorFactory = new ValidatorFactory();
export default validatorFactory;

import React from 'react';
import styled from 'styled-components';
import { InputNumber } from '../../FormComponentsSmall/InputNumber';
import { Checkbox } from '../../FormComponentsSmall/Checkbox';
import { TmpSegmentProps } from '../../../../features/Wall/lib/helpers/CalculateCoordinateForSecondPoint';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 5px 10px 20px;
`;

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
`;

interface SegmentPropertiesUIProps {
    /** Properties for segment */
    segmentProps: TmpSegmentProps;

    /** Need to show checkboxes or not */
    showCheckboxes?: boolean;

    /** Change handler for all inputs, including checkboxes */
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;

    /** Blur handler for text/number inputs */
    onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;

    onEnterPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

/**
 * Component that represents settings for creating new segment
 * @component
 */
export const SegmentPropertiesUI = ({
    segmentProps,
    showCheckboxes = true,
    onChange,
    onBlur,
    onEnterPress,
}: SegmentPropertiesUIProps) => {
    const checkboxStyle = showCheckboxes ? undefined : { display: 'none' };

    return (
        <Wrapper>
            <Row>
                <Checkbox
                    checked={segmentProps.fixAngle}
                    id='fixAngle'
                    name='fixAngle'
                    onChange={onChange}
                    style={checkboxStyle}
                >
                    Угол
                </Checkbox>
                <InputNumber
                    id='angle'
                    name='angle'
                    precision={0}
                    min={-360}
                    max={360}
                    placeholder={'от -360° до 360°'}
                    style={{ width: '130px' }}
                    disabled={!segmentProps.fixAngle}
                    value={segmentProps.fixAngle ? segmentProps.angle : '-'}
                    onChange={onChange}
                    onBlur={onBlur}
                    onKeyDown={onEnterPress}
                    type={segmentProps.fixAngle ? undefined : 'text'} // trick to show '-' on disabled
                />
            </Row>
            <Row>
                <Checkbox
                    checked={segmentProps.fixLength}
                    id='fixLength'
                    name='fixLength'
                    onChange={onChange}
                    style={checkboxStyle}
                >
                    Длина
                </Checkbox>
                <InputNumber
                    id='length'
                    name='length'
                    precision={2}
                    min={0}
                    max={Number.MAX_VALUE}
                    placeholder={'> 0'}
                    style={{ width: '130px' }}
                    disabled={!segmentProps.fixLength}
                    value={segmentProps.fixLength ? segmentProps.length : '-'}
                    onChange={onChange}
                    onBlur={onBlur}
                    onKeyDown={onEnterPress}
                    type={segmentProps.fixLength ? undefined : 'text'} // trick to show '-' on disabled
                />
            </Row>
        </Wrapper>
    );
};

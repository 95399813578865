const registrationErrorsMessages = {
    existingLoginError: 'User with this login already exists!',
    invalidCharactersLoginError: 'Login contains invalid characters!',
    existingEmailError: 'This email address is already registered!',
};

const registrationErrorsMessagesRus = {
    [registrationErrorsMessages.existingLoginError]:
        'Данный логин уже зарегистрирован!',
    [registrationErrorsMessages.existingEmailError]:
        'Данная почта уже зарегистрирована!',
    [registrationErrorsMessages.invalidCharactersLoginError]:
        'Некорректные символы в логине!',
};

// hints for different fields, needed for handler below
const hintsForFields = {
    email: 'Введите адрес эл. почты',
    login: 'Придумайте логин длиной от 3 до 30 символов',
    password: 'Придумайте пароль длиной от 8 до 128 символов',
    controlPassword: 'Повторите пароль',
};

export {
    registrationErrorsMessages,
    registrationErrorsMessagesRus,
    hintsForFields,
};

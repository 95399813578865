import { DocumentNode, gql } from '@apollo/client';
import { ROUTE_SEGMENT_FRAGMENT } from '../../fragments/routes/RouteSegmentFragment';

/** Mutation for updating several route segments */
export const UPDATE_ROUTE_SEGMENTS_POS: DocumentNode = gql`
    ${ROUTE_SEGMENT_FRAGMENT}
    mutation updateRouteSegments(
        $moveVector: CoordinateDraft!
        $routePointsIds: [String]
        $routeSegmentSelectors: [VersionedSegmentSelector]
    ) {
        updateRouteSegments(
            moveVector: $moveVector
            routePointsIds: $routePointsIds
            routeSegmentSelectors: $routeSegmentSelectors
        ) {
            ...RouteSegmentFragment
        }
    }
`;

import React from "react";
import { Main, Header1, Subtitle } from "./Styles"
import { GeneralInformation } from "./GeneralInformation";
import { PasswordChangeBlock } from "./PasswordChangeBlock";

/** 
 * Content block of User settings page
 * @component
 */
export const Content = () => {
    return (
        <Main>
            <Header1>Управление аккаунтом</Header1>
            <Subtitle>Здесь можно посмотреть или изменить личную информацию.</Subtitle>
            <GeneralInformation/>
            <PasswordChangeBlock/>
        </Main>
    );
};
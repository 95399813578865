import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';

import { RightBarHeader } from './ui/RightBarHeader';
import rightBarModerator from './model/RightBarModerator';
import { useKeyDown } from '../../../shared/model/hooks/UseKeyDown';
import { ESC_KEY } from '../../../widgets/Canvas/lib/CanvasDefaultValues';
import { RightBarBody } from './ui/RightBarBody';

const RightBarContainer = styled.div`
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-areas: 'map-structure' 'zones-info';
    height: inherit;
    border: ${(props) => (props.isOpened ? '0.5px solid #d7d7d7' : 'none')};
    width: ${(props) => (props.isOpened ? '306px' : '')};
    background-color: white;
`;

/**
 * Component that represents right bar of edit mode page. It contains folders with canvas items
 *
 * @component
 */
export const RightBar = ({ rightBarContainerRef }) => {
    const operation = useReactiveVar(rightBarModerator.operation);
    const isOpened = useReactiveVar(rightBarModerator.isOpened);
    const open = isOpened || operation !== null;

    useEffect(() => {
        if (rightBarContainerRef.current) {
            if (isOpened || operation) {
                rightBarContainerRef.current.style.height = '100%';
            } else {
                rightBarContainerRef.current.style.height = 'fit-content';
            }
        }
    }, [rightBarContainerRef, isOpened, operation]);

    useKeyDown(() => {
        if (operation) {
            rightBarModerator.clearOperation();
        }
    }, [ESC_KEY]);

    return (
        <RightBarContainer isOpened={open}>
            <RightBarHeader isOpened={open} text={operation?.operationName} />
            <RightBarBody isOpened={open} operation={operation} />
        </RightBarContainer>
    );
};

RightBar.propTypes = {
    /**
     * ref to parent container
     */
    rightBarContainerRef: PropTypes.shape({ current: PropTypes.object }),
};

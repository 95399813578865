import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { PointsForContextMenu } from '../styles/PointsForContextMenu';
import routesModerator from '../../../Route/model/RoutesModerator';
import { closeContextMenu } from '../../model/CloseContextMenu';
import routesAPI from '../../../Route/api/RoutesAPI';
import { QUERY_STATES } from '../../../AccessGroup/api/AccessGroupsAPI';
import loaderImg from '../../../../shared/assets/images/icons/preloader.svg';
import { currentToolVar } from '../../../../shared/model/cache/Cache';
import { Constraints } from '../../../../pages/Constructor/TopBar/TopBarEntires/Tools/Constants/Tools';

/**
 * Route context menu
 * @component
 */
export const RouteContextMenu = ({ routeId, onClose = () => {} }) => {
    const deleteState = useReactiveVar(routesAPI.routeDeletingState);

    useState(() => {
        if (deleteState !== QUERY_STATES.IDLE) {
            routesAPI.routeDeletingState(QUERY_STATES.IDLE);
        }
    }, []);

    /** Function to close context menu */
    const closeCM = () => {
        onClose();
        closeContextMenu();
    };

    /** Handler for 'fix route' click */
    const handleFixRoute = () => {
        currentToolVar(Constraints.route);
        routesModerator.currentRouteIdVar(routeId);
        closeCM();
    };

    /** Handler for 'edit route' click */
    const handleEdit = () => {
        routesModerator.openEditModal(routeId);
        closeCM();
    };

    /** Handler for 'delete route' click */
    const handleDelete = async () => {
        await routesModerator.deleteRoute(routeId);
        closeCM();
    };

    const menu = [
        {
            text: 'Исправить маршрут',
            onClick: handleFixRoute,
        },
        {
            text: 'Редактировать описание',
            onClick: handleEdit,
        },
        {
            text: 'Удалить',
            onClick: handleDelete,
            icon: deleteState === QUERY_STATES.LOADING ? loaderImg : undefined,
        },
    ];

    return <PointsForContextMenu menu={menu} />;
};

RouteContextMenu.propTypes = {
    /** Route UUID */
    routeId: PropTypes.string,

    /**
     * Custom close handler
     * @type {() => void}
     */
    onClose: PropTypes.func,
};

import { CURSORS } from '../../../../../shared/lib/utils/common/CursorModerator';
import { RULE_EDIT } from '../../../../AccessGroup/model/AGConstants';
import { SELECTED_COLOR } from '../../helpers/WallDefaultValues';
import { handleMouseEnter } from '../WallHandlers/HandleMouseEnter';

export const handlePointEnter = (e) => {
    handleMouseEnter(
        e.target.attrs.accessRule === RULE_EDIT
            ? CURSORS.POINTER
            : CURSORS.NOT_ALLOWED
    );
    if (e.target.attrs.accessRule === RULE_EDIT) {
        e.target.fill(SELECTED_COLOR);
    }
};

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import folderIcon from '../../../../../../../shared/assets/images/icons/ConstructorPage/RightBar/items-folder.svg';
import collapseRightArrowIcon from '../../../../../../../shared/assets/images/icons/ConstructorPage/RightBar/collapse-right-arrow.svg';
import collapseDownArrowIcon from '../../../../../../../shared/assets/images/icons/ConstructorPage/RightBar/collapse-down-arrow.svg';

const ZoneInfoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 31px;
    background-color: transparent;
    border-radius: 9px;
    border: 0.5px solid transparent;
    :hover {
        border: 0.5px solid #023aff;
        cursor: pointer;
    }
`;

const FolderName = styled.span`
    font-family: 'Open Sans';
    font-style: normal;
    color: rgba(0, 0, 0, 0.8);
    font-size: 16px;
    margin-left: 8px;
    margin-right: auto;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const FolderIcon = styled.img`
    width: 18px;
    height: 16px;
`;

const CollapseIcon = styled.img`
    width: 7px;
    padding-left: 5px;
    padding-right: 10px;
    opacity: 0;
`;

/**
 * Component that represents header of folder. It contains folder name and collapse icon.
 *
 * @component
 */
export const FolderHeader = ({
    folderName,
    isFolderOpened,
    handleCollapseClick,
}) => {
    return (
        <ZoneInfoContainer onClick={handleCollapseClick}>
            <CollapseIcon
                className='collapseIcon'
                src={
                    isFolderOpened
                        ? collapseDownArrowIcon
                        : collapseRightArrowIcon
                }
                alt='развернуть'
            ></CollapseIcon>

            <FolderIcon src={folderIcon} alt='' />

            <FolderName title={folderName}>{folderName || ''}</FolderName>
        </ZoneInfoContainer>
    );
};
FolderHeader.propTypes = {
    folderName: PropTypes.string,
    isFolderOpened: PropTypes.bool,
    handleCollapseClick: PropTypes.func,
};

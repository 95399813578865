import React, { Fragment, useContext } from 'react';
import {
    POpenSansLight,
    POpenSansRegular,
} from '../../../shared/ui/TextStyles';
import { PropertyInEditMode } from './PropertyInEditMode';
import { BotPropertiesContext } from '../GraphicSchema';

const PropertyName = POpenSansLight;
const NonEditablePropertyValue = POpenSansRegular;

/**
 * @typedef {import('../lib/SchemaSpecifier').SchemaFormField} SchemaFormField
 */

/**
 * @typedef {import('../lib/SchemaSpecifier').SchemaFormField} SchemaFormField
 */

const PropertyValue = ({ field, isEditMode }) => {
    return (
        <>
            {isEditMode ? (
                <>
                    <PropertyInEditMode
                        field={field}
                        context={BotPropertiesContext}
                    />
                </>
            ) : (
                <NonEditablePropertyValue>
                    {field.currentValue.toSchemaFormat()}
                </NonEditablePropertyValue>
            )}
        </>
    );
};

/**
 * Component that represents grid with editable properties
 * @component
 * @param {{
 *  fields: SchemaFormField[],
 *  isEditMode: Boolean,
 *  handleChange: Function,
 *  isDeleteIconNeed: Boolean,
 *  handleDelete: Function,
 * }}
 */
export const PropertiesGrid = ({ fields }) => {
    const { isEditMode } = useContext(BotPropertiesContext);
    return fields?.map((field) => (
        <Fragment key={field.key}>
            <PropertyName>{field.name}:</PropertyName>
            <PropertyValue field={field} isEditMode={isEditMode} />
        </Fragment>
    ));
};

import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useRef } from 'react';
import { Group, Line } from 'react-konva';
import {
    gridVar,
    stagePositionVar,
    stageScaleVar,
    stageVar,
} from '../../../shared/model/cache/Cache';
import { useGrid } from '../../../shared/model/hooks/UseGrid';

/** Grid stroke color */
const stroke = '#bababa';

/**
 * Component to provide canvas grid
 *
 * @component
 * @example
 * <CanvasGrid  />
 */
export const CanvasGrid = () => {
    const gridRef = useRef(null);
    const stage = useReactiveVar(stageVar);
    const scale = useReactiveVar(stageScaleVar);
    useReactiveVar(stagePositionVar);

    useEffect(() => {
        gridVar(gridRef.current);

        return () => {
            gridVar(null);
        };
    }, []);

    /** Grid stroke width */
    let strokeWidth = 1;

    /** Grid step */
    let stepSize = 40;
    if (scale > 1.9) {
        stepSize = stepSize / 2;
    } else {
        if (scale < 0.6) {
            stepSize = stepSize * 2;
            strokeWidth++;
        }
        if (scale < 0.4) {
            stepSize = stepSize * 2;
            strokeWidth++;
        }
    }

    const [gridWidth, gridHeight, grid] = useGrid(stepSize, gridRef);

    if (!stage) {
        return null;
    }

    /**
     * Points to define lines directions [x1, y1, x2, y2]
     */
    const verticalPoints = [0, 0, 0, gridHeight];
    const horizontalPoints = [0, 0, gridWidth, 0];

    return (
        <Group ref={gridRef}>
            {grid.vertical.map((item, index) => (
                <Line
                    key={index}
                    x={item.x}
                    y={item.y}
                    points={verticalPoints}
                    stroke={stroke}
                    strokeWidth={strokeWidth}
                />
            ))}
            {grid.horizontal.map((item, index) => (
                <Line
                    key={index}
                    x={item.x}
                    y={item.y}
                    points={horizontalPoints}
                    stroke={stroke}
                    strokeWidth={strokeWidth}
                />
            ))}
        </Group>
    );
};

/**
 * Function for getting unique number for element name
 * (for not repeatable element names)
 * if 0 -- it means no elements with DEFAULT_NAME
 * if > 0 -- new element number for it's name
 * 
 * @param {{name: String}[]} elements
 * @param {String} DEFAULT_NAME
 * @returns {Number} uniqueNumber
 */
export const getUniqueNumber = (elements, DEFAULT_NAME) => {
    return (
        elements.reduce((acc, element) => {
            if (element.name.includes(DEFAULT_NAME)) {
                const uniqueNumber = +element.name.slice(
                    element.name.indexOf('(') + 1,
                    element.name.indexOf(')')
                );
                if (acc < uniqueNumber) {
                    acc = uniqueNumber;
                    return acc;
                }
            }
            return acc;
        }, 0) + 1
    );
};

/**
 * Class for conformity of metres to pixels.
 * For showing in canvas needs pixels,
 * for saving in back needs metres and correct transform
 * from one to another.
 */
class ScalesConformer {
    /**
     * pixels / metres -- 80 CSS-pixels = 1 meter
     */
    ratio = 50;

    constructor() {
        /**
         * number of real device pixels of the screen / CSS-pixels
         */
        this.devicePixelRatio = window.devicePixelRatio;
    }

    /**
     *
     * @param {number} numberOfPixels
     * @returns {number} numberOfMetres
     */
    toMetres(numberOfPixels) {
        return numberOfPixels / this.ratio;
    }

    /**
     *
     * @param {number} numberOfMetres
     * @returns {number} numberOfPixels
     */
    toPixels(numberOfMetres) {
        return numberOfMetres * this.ratio;
    }
}

const scalesConformer = new ScalesConformer();
export default scalesConformer;

import { useReactiveVar } from '@apollo/client';
import React from 'react';
import PropTypes, { string } from 'prop-types';
import {
    currentToolVar,
    projectWallTypesVar,
} from '../../../../../shared/model/cache/Cache';
import { Constraints, ToolImage, Tools } from './Constants/Tools';
import floorGraph from '../../../../../features/Wall/model/FloorGraph';
import { BASIC_WALLS_FOLDER_NAME } from '../../../RightBar/ui/DefaultRightBar/Tabs/Constants';
import RulerObject, {
    MEASURE_TYPE,
} from '../../../../../features/Ruler/model/Ruler';
import { setPointerDragTool } from '../../../BottomBar/BottomBarEntries/DragToolSwitcher';
import constructorPageHints from '../../../../../features/HintSystem/model/ConstructorPageHints';
import zones from '../../../../../features/Zone/model/Zones';
import rightBarModerator from '../../../RightBar/model/RightBarModerator';
import { DefaultRBTabs, RightBarOperation } from '../../../RightBar/model/RightBarOperations';
import { RBZoneCreation } from '../../../RightBar/ui/Operation/ZoneCreation/RBZoneCreation';

/**
 * Component that represent canvas tool
 *
 * @component
 */
export const Tool = ({ toolProps }) => {
    const currentTool = useReactiveVar(currentToolVar);

    const handleClick = (e) => {
        e.preventDefault();
        currentToolVar(
            currentTool === toolProps.name ? Tools.none : toolProps.name
        );

        const activateWallSegments = () => {
            if (currentToolVar() === Tools.none) {
                rightBarModerator.selectedObject(null);
                floorGraph.cancelCreating();
                floorGraph.finishCreatingRect();
                return;
            }
            setPointerDragTool();
            zones.donorZoneVar(null);
            floorGraph.currentWallSegmentTypeVar(
                projectWallTypesVar().find((walSegmentType) =>
                    walSegmentType.name.includes('Несущая стена')
                )
            );
            constructorPageHints.setInfoHint(
                'Построение стен',
                'Нажмите левой кнопкой мыши, чтобы начать построение. Нажмите esc, чтобы завершить построение.'
            );
            rightBarModerator.openFolders(BASIC_WALLS_FOLDER_NAME);
            rightBarModerator.selectedObject(
                floorGraph.currentWallSegmentTypeVar().id
            );
            rightBarModerator.selectTab(DefaultRBTabs.WALL_SEGMENTS);
            rightBarModerator.clearOperation();
        };

        const activateMapObject = () => {
            constructorPageHints.setInfoHint(
                'Добавление объекта',
                'Перенесите объект из правого меню на схему'
            );
            rightBarModerator.clearOperation();
            rightBarModerator.selectTab(DefaultRBTabs.MAP_OBJECTS);
            currentToolVar(Tools.none); // deactivate tool, as it doesn't needed for dragging map object
        };

        const activateRoutes = () => {
            if (currentTool !== toolProps.name) {
                import(
                    '../../../../../features/Route/model/RoutesModerator'
                ).then(({ default: routesModerator }) => {
                    routesModerator.createRoute();
                });
            }
        };

        const activateRuler = (measureType) => {
            RulerObject.setMeasureType(measureType);
        };

        const activateZone = () => {
            // deactivate zone tool
            if (currentToolVar() === Tools.none) {
                rightBarModerator.clearOperation();
                return;
            }
            zones.donorZoneVar(null);
            constructorPageHints.setInfoHint('Отделение зоны', 'Выберите зону');
            rightBarModerator.setOperation({
                customRightBar: true,
                operationName: RightBarOperation.ZONE_CREATION,
                component: <RBZoneCreation />,
                onClose: () => {
                    if (currentToolVar() === Tools.zone) {
                        currentToolVar(Tools.none);
                    }
                },
            });
        };

        const defaultActivator = () => {};

        const activateTool = {
            [Tools.rulerAny]: () => activateRuler(MEASURE_TYPE.any),
            [Tools.rulerHorizontal]: () =>
                activateRuler(MEASURE_TYPE.horizontal),
            [Tools.rulerVertical]: () => activateRuler(MEASURE_TYPE.vertical),
            [Tools.wall]: () => activateWallSegments(),
            [Tools.rectangleRoom]: () => activateWallSegments(),
            [Constraints.route]: () => activateRoutes(),
            [Tools.zone]: () => activateZone(),
            [Tools.addMapObject]: () => activateMapObject(),
        };

        return (activateTool[toolProps.name] || defaultActivator)();
    };

    return (
        <ToolImage
            width={toolProps.width ? toolProps.width : ''}
            src={toolProps.src}
            draggable={false}
            active={currentTool === toolProps.name}
            onClick={handleClick}
            alt={toolProps.alt}
            title={toolProps.title}
        />
    );
};

Tool.propTypes = {
    /**
     * Props for tool
     */
    toolProps: PropTypes.shape({
        src: string,
        name: string,
        alt: string,
        title: string,
    }),
};

import { Tools } from '../../../../../pages/Constructor/TopBar/TopBarEntires/Tools/Constants/Tools';
import { currentToolVar } from '../../../../../shared/model/cache/Cache';
import { ROUTE_EDGE_NAME } from '../../../../Route/lib/RouteDefaultValues';
import {
    EMBEDDED_OBJECT_NAME,
    WALL_EDGE_NAME,
} from '../../helpers/WallDefaultValues';
import selector from '../../../../Selector/model/Selector';
import { RULE_EDIT } from '../../../../AccessGroup/model/AGConstants';
import { LEFT_MOUSE_BUTTON } from '../../../../../widgets/Canvas/lib/CanvasDefaultValues';

/**
 * @typedef {import('../../../model/FloorGraph').FloorGraph} FloorGraph
 * @typedef {import('../../../../Route/model/RouteGraph').RouteGraph} RouteGraph
 */

/**
 * Handler for click on edge (segment)
 * @param {object} e click event
 * @param {string} edgeId edge UUID
 * @param {FloorGraph | RouteGraph} graph FloorGraph or its heir
 */
export const handleClickEdge = (e, edgeId, graph) => {
    if (
        e.evt.button === LEFT_MOUSE_BUTTON &&
        e.target.attrs.accessRule === RULE_EDIT &&
        (currentToolVar() === Tools.none || currentToolVar() === Tools.zone) &&
        (e.target.attrs.name === WALL_EDGE_NAME ||
            e.target.attrs.name === EMBEDDED_OBJECT_NAME ||
            e.target.attrs.name === ROUTE_EDGE_NAME)
    ) {
        selector.selectEdge(edgeId, graph); // select clicked edge (segment)
    }
};
